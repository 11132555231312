import React from 'react';

import { PropTypes } from 'prop-types';

import { MetricsContainer, MetricItem } from '../../Styles';

import { E2E } from '../../../../../constants/general';
import DropTable from '../DropTable';

import IngestWidgets from './IngestWidgets';
import IngestWidgetsRaw from './IngestWidgetsRaw';

import {
   INGEST_AUDIO_BITRATE,
   INGEST_VIDEO_BITRATE,
   INGEST_STREAM_TIME_RATIO,
   MAX_RAW_MODE_TIME_RANGE_IN_SECS,
   INGEST_VIDEO_FRAME_RATE_REALTIME,
   // INGEST_AUDIO_FRAME_LENGTH_DEVIATION,
   // INGEST_VIDEO_FRAME_LENGTH_DEVIATION,
   // INGEST_AUDIO_MAX_TIME_OFFSET,
   // INGEST_VIDEO_MAX_TIME_OFFSET,
   // INGEST_AUDIO_SAMPLE_RATE_RATIO,
   // INGEST_VIDEO_FRAME_RATE_RATIO,
} from '../../constants';

import {
   FontStyle,
   FormattedLink,
   InfoContainer,
   SymbolContainer,
   TextColumn,
   TextRow,
} from '../../../../../styledComponents/styledComponents';

const METRIC_TYPE_CONFIG = {
   [INGEST_VIDEO_BITRATE]: {
      title: 'Video bitrate [kBits/s]',
      urlSegment: 'video/bitrate',
      resParseFn: (item) => item.bitrate.kbps,
      csvFilename: 'Ingest_VideoBitrate',
   },
   [INGEST_AUDIO_BITRATE]: {
      title: 'Audio bitrate [kBits/s]',
      urlSegment: 'audio/bitrate',
      resParseFn: (item) => item.bitrate.kbps,
      csvFilename: 'Ingest_AudioBitrate',
   },
   [INGEST_STREAM_TIME_RATIO]: {
      title: 'Stream Time Ratio',
      urlSegment: 'streamTimeRatio',
      resParseFn: (item) => item.streamTimeRatio,
      csvFilename: 'Ingest_StreamTimeRatio',
      explaination: [
         'The stream time ratio represents the relation',
         'between the elapsed real time and the stream time',
         'within a window of 15 seconds. A constant ratio',
         'value of 1 would be ideal - but a small fluctuation',
         'is expected. A big fluctuation or deviation from the',
         'value 1 indicates issues on the ingest side, for',
         'instance caused by a limited bandwidth or high CPU load.',
         'The higher the deviation from the value 1, the higher',
         'the probability for an increased latency on the',
         'playback/viewer side.',
      ],
   },
   // [INGEST_VIDEO_MAX_TIME_OFFSET]: {
   //    title: 'Video Max Time Offset',
   //    urlSegment: 'video/maxTimeOffset',
   //    resParseFn: (item) => item.maxTimeOffset,
   //    csvFilename: 'Ingest_VideoMaxTimeOffset',
   // },
   // [INGEST_AUDIO_MAX_TIME_OFFSET]: {
   //    title: 'Audio Max Time Offset',
   //    urlSegment: 'audio/maxTimeOffset',
   //    resParseFn: (item) => item.maxTimeOffset,
   //    csvFilename: 'Ingest_AudioMaxTimeOffset',
   // },
   // [INGEST_VIDEO_FRAME_LENGTH_DEVIATION]: {
   //    title: 'Video Framelength Deviation',
   //    urlSegment: 'video/frameLengthDeviation',
   //    resParseFn: (item) => item.frameLengthDeviation,
   //    csvFilename: 'Ingest_VideoFrameLengthDeviation',
   // },
   // [INGEST_AUDIO_FRAME_LENGTH_DEVIATION]: {
   //    title: 'Audio Framelength Deviation',
   //    urlSegment: 'audio/frameLengthDeviation',
   //    resParseFn: (item) => item.frameLengthDeviation,
   //    csvFilename: 'Ingest_AudioFrameLengthDeviation',
   // },
   // [INGEST_VIDEO_FRAME_RATE_RATIO]: {
   //    title: 'Video Frame Rate Ratio',
   //    urlSegment: 'video/frameRate/ratio',
   //    resParseFn: (item) => item.frameRateRatio,
   //    csvFilename: 'Ingest_VideoFrameRateRatio',
   // },
   [INGEST_VIDEO_FRAME_RATE_REALTIME]: {
      title: 'Real-time framerate [fps]',
      urlSegment: 'video/frameRate/realtime',
      resParseFn: (item) => item.frameRateRealtime.fps,
      csvFilename: 'Ingest_VideoFrameRateRealtime',
      explaination: [
         'The real-time video frame rate is the measured',
         'video frame rate after the stream has been transmitted',
         'to the CDN (Content Delivery Network). It is compared',
         'to the frame rate at which the stream was encoded.',
         'This metric helps determine whether the encoded',
         'frame rate was actually received in real-time.',
         'Deviations can indicate issues on the ingest side, for',
         'instance caused by a limited bandwidth or high CPU load.',
      ],
   },
   // [INGEST_AUDIO_SAMPLE_RATE_RATIO]: {
   //    title: 'Audio Sample Rate Ratio',
   //    urlSegment: 'audio/sampleRate/ratio',
   //    resParseFn: (item) => item.sampleRateRatio,
   //    csvFilename: 'Ingest_AudioSampleRateRatio',
   // },
};

function RtmpMetrics({ start, end, mode, streamName, drops }) {
   const isRawModeActive = end.unix() - start.unix() <= MAX_RAW_MODE_TIME_RANGE_IN_SECS;

   return (
      <>
         <InfoContainer>
            <SymbolContainer>
               <i className='bi bi-info-square'></i>
            </SymbolContainer>
            <TextColumn>
               <TextRow marginBottomPx={4}>
                  To learn more about typical{' '}
                  <FontStyle $fontWeight={750} $textDecoration={'underline'}>
                     ingest issues
                  </FontStyle>{' '}
                  that can be derived from RTMP metrics, click
                  <FormattedLink
                     href='https://docs.nanocosmos.de/docs/analytics/troubleshooting#other-samples'
                     target='_blank'
                     rel='noopener noreferrer'
                     // style={linkStyle}
                  >
                     <i className='bi bi-arrow-right-square ml-1'></i>
                  </FormattedLink>
               </TextRow>
               <TextRow>
                  To find out when an{' '}
                  <FontStyle $fontWeight={750} $textDecoration={'underline'}>
                     alert
                  </FontStyle>{' '}
                  is detected and how they are defined, click
                  <FormattedLink
                     href='https://docs.nanocosmos.de/docs/analytics/alerting#alert-definitions'
                     target='_blank'
                     rel='noopener noreferrer'
                     // style={linkStyle}
                  >
                     <i className='bi bi-arrow-right-square ml-1'></i>
                  </FormattedLink>
               </TextRow>
            </TextColumn>
         </InfoContainer>
         <MetricsContainer>
            {Object.keys(METRIC_TYPE_CONFIG).map((metric, index) => (
               <MetricItem key={index} isFullScreen={mode !== E2E}>
                  {isRawModeActive ? (
                     <IngestWidgetsRaw
                        start={start}
                        end={end}
                        streamName={streamName}
                        protocol='rtmp'
                        drops={drops.data}
                        title={METRIC_TYPE_CONFIG[metric].title}
                        urlSegment={METRIC_TYPE_CONFIG[metric].urlSegment}
                        resParseFn={METRIC_TYPE_CONFIG[metric].resParseFn}
                        explanation={METRIC_TYPE_CONFIG[metric].explaination}
                        csvFilename={METRIC_TYPE_CONFIG[metric].csvFilename}
                     />
                  ) : (
                     <IngestWidgets
                        start={start}
                        end={end}
                        streamName={streamName}
                        protocol='rtmp'
                        drops={drops.data}
                        title={METRIC_TYPE_CONFIG[metric].title}
                        urlSegment={METRIC_TYPE_CONFIG[metric].urlSegment}
                        resParseFn={METRIC_TYPE_CONFIG[metric].resParseFn}
                        explanation={METRIC_TYPE_CONFIG[metric].explaination}
                        csvFilename={METRIC_TYPE_CONFIG[metric].csvFilename}
                     />
                  )}
               </MetricItem>
            ))}
            {drops.data && drops.data.length > 0 && (
               <MetricItem isFullScreen={mode !== E2E}>
                  <DropTable drops={drops.data} start={start} end={end} />
               </MetricItem>
            )}
         </MetricsContainer>
      </>
   );
}

RtmpMetrics.propTypes = {
   start: PropTypes.object,
   end: PropTypes.object,
   streamName: PropTypes.string,
   mode: PropTypes.string,
   drops: PropTypes.exact({
      data: PropTypes.array,
      status: PropTypes.bool,
   }),
};

export default RtmpMetrics;
