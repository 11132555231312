import React from 'react';
import { PropTypes } from 'prop-types';

import { CheckButton } from './StreamNameStyles';
import { CHECKED, CHECKING } from './StreamNameConstants';

function MultiStateButton({
   checkerState,
   inputValue,
   validStreamName,
   error,
   onCheckButtonClick,
   onClearButtonClick,
   onAddButtonClick,
   widthPx,
   marginRightPx,
}) {
   const showCheckButton = checkerState !== CHECKED || error;
   const checkButtonActive = inputValue !== '' && checkerState !== CHECKING;
   const showClearButton = checkerState === CHECKED && !validStreamName && !error;
   const showAddButton = checkerState === CHECKED && validStreamName;

   const checkButtonHandler = checkButtonActive ? onCheckButtonClick : () => {};

   return (
      <>
         {showCheckButton && (
            <CheckButton
               active={checkButtonActive}
               onClick={checkButtonHandler}
               widthPx={widthPx}
               marginRightPx={marginRightPx}
            >
               {checkerState === CHECKING ? '. . .' : 'Check'}
            </CheckButton>
         )}
         {showClearButton && (
            <CheckButton
               active={true}
               onClick={onClearButtonClick}
               disabled={true}
               widthPx={widthPx}
               marginRightPx={marginRightPx}
            >
               Clear
            </CheckButton>
         )}
         {showAddButton && (
            <CheckButton
               onClick={() => onAddButtonClick(inputValue)}
               active={true}
               widthPx={widthPx}
               marginRightPx={marginRightPx}
            >
               Add
            </CheckButton>
         )}
      </>
   );
}

MultiStateButton.propTypes = {
   checkerState: PropTypes.string,
   inputValue: PropTypes.string,
   validStreamName: PropTypes.bool,
   error: PropTypes.string,
   onCheckButtonClick: PropTypes.func,
   onClearButtonClick: PropTypes.func,
   onAddButtonClick: PropTypes.func,
   widthPx: PropTypes.number,
   marginRightPx: PropTypes.number,
};

MultiStateButton.defaultProps = {
   widthPx: 70,
   marginRightPx: 20,
};

export default MultiStateButton;
