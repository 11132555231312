import React from 'react';

import { PropTypes } from 'prop-types';

import { saveAs } from 'file-saver';
import moment from 'moment';
import CsvButtonTemplate from './CsvButtonTemplate';
import { CHRONOLOGICAL_DATE_TIME_FORMAT } from '../../widgets/troubleshooting/constants';

function CsvExportButton({ jsonData, filename, options, heightInPx }) {
   function getOptionsHeader(options, outputFilename) {
      const { start, end, usageMetric, countries, tags, streamNames, hash } = options;

      const headerRows = [];
      headerRows.push(`file name,${outputFilename}`);
      headerRows.push(`created at,${moment.utc().format(CHRONOLOGICAL_DATE_TIME_FORMAT)}`);
      if (start) headerRows.push(`start,${moment.utc(start * 1000).format(CHRONOLOGICAL_DATE_TIME_FORMAT)}`);
      if (end) headerRows.push(`end,${moment.utc(end * 1000).format(CHRONOLOGICAL_DATE_TIME_FORMAT)}`);
      if (usageMetric) headerRows.push(`usage metric,${usageMetric}`);
      if (countries && countries.length > 0) headerRows.push(`filtered countries,${countries.join(' / ')}`);
      if (tags && tags.length > 0) headerRows.push(`filtered tags,${tags.join(' / ')}`);
      if (streamNames && streamNames.length > 0) headerRows.push(`filtered streamFilter,${streamNames.join(' / ')}`);
      if (hash) headerRows.push(`filtered organization,${hash}`);
      const header = headerRows.join('\n');

      return header + '\n\n\n';
   }
   function provideJsonAsCsv() {
      const csvOptionHeader = getOptionsHeader(options, filename);
      const csvHeader = Object.keys(jsonData[0]).join(',');
      const csvData = jsonData.map((obj) => Object.values(obj).join(','));
      const csvContent = `${csvOptionHeader}${csvHeader}\n${csvData.join('\n')}`;

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, `${filename}_${moment.utc().format().replace(':', '_')}.csv`);
   }

   return <CsvButtonTemplate clickHandler={provideJsonAsCsv} heightInPx={heightInPx} />;
}

CsvExportButton.propTypes = {
   jsonData: PropTypes.arrayOf(PropTypes.object),
   filename: PropTypes.string,
   options: PropTypes.object,
   heightInPx: PropTypes.number,
};

export default CsvExportButton;
