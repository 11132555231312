import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAlarm } from '../../../../redux/actions/general';

import useDataFetch from '../../../../hooks/useDataFetch';
import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';

import {
   hashSelector,
   countriesSelector,
   tagsSelector,
   cancelTokenSelector,
   startTimespanSelector,
   endTimespanSelector,
   usageSwitchSelector,
   streamNamesSelector,
   titleTimespanSelector,
} from '../../../../redux/selectors/selectors';

import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   RemoveBottomMargin,
   ShowOnRightSide,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetFooterContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import roundTo from 'round-to';

import { CardTitle, Col, Row } from 'reactstrap';
import { addSeparatorAndThreeDecimalPlaces, getIntegerWithDecimalSeparator } from '../../../../util/NumberFormatter';
import { CsvExportFromApiButton, Spinner } from '../../../common';
import { BREAKDOWN_VIEW, PLAYOUT, USAGE_AGG_TYPE } from '../../../../constants/general';

import { showOverflowTooltip } from '../../../common/OverflowTooltip/OverflowTooltip';
import { TOKEN } from '../../../../constants/dataGroup';
import TrafficHint from '../../../common/Hints/TrafficHint';
import AggLimitHint from '../../../common/Hints/AggLimitHint';

const { SearchBar } = Search;

const USAGE_SETTINGS = {
   bytes: {
      title: 'GBytes per Token',
      unit: 'GB',
      unitConversion: (value) => roundTo(value / 1e9, 3),
      resUnitPropNames: 'bytes',
   },
   playtime: {
      title: 'Hours per Token',
      unit: 'h',
      unitConversion: (value) => roundTo(value / 3600, 3),
      resUnitPropNames: 'seconds',
   },
};

const getColumnsOptions = (unit) => [
   {
      text: 'Orga',
      dataField: 'orgaHash',
      sort: true,
      style: { verticalAlign: 'middle', textAlign: 'left' },
      headerAlign: 'left',
      headerStyle: { width: '75px' },
   },
   {
      text: 'Token',
      dataField: 'token',
      sort: true,
      style: {
         verticalAlign: 'middle',
         textAlign: 'left',
      },
      headerAlign: 'left',
      formatter: showOverflowTooltip,
   },
   {
      text: `Playout [${unit}]`,
      dataField: 'playout',
      sort: true,
      style: { verticalAlign: 'middle', textAlign: 'right' },
      headerAlign: 'right',
      headerStyle: { width: '125px' },
      formatter: addSeparatorAndThreeDecimalPlaces,
      searchable: false,
   },
];

function UsageByToken() {
   const [tableData, setTableData] = useState([]);
   const [rowCount, setRowCount] = useState(0);
   const [total, setTotal] = useState(0);

   const hash = useSelector(hashSelector);
   const countries = useSelector(countriesSelector);
   const tags = useSelector(tagsSelector);
   const streamNames = useSelector(streamNamesSelector);

   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);
   const timespanTitle = useSelector(titleTimespanSelector);

   const usageSwitch = useSelector(usageSwitchSelector);
   const usageSwitchRef = useRef(usageSwitch);
   usageSwitchRef.current = usageSwitch;

   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, [], streamNames, BREAKDOWN_VIEW);
   const url = `/api/v2/usage/${TOKEN}/playout/${usageSwitch}?from=${start.format()}&to=${end.format()}${urlFilterQuery}`;
   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            const { tableData, total, count } = getData(data.data, usageSwitch, USAGE_AGG_TYPE.playout);
            setTableData(tableData);
            setTotal(total);
            setRowCount(count);
         }
      }
      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, dispatch, usageSwitch]);

   const afterSearch = (newResult) => calculateTotalsAfterSearch(newResult, total, setTotal, setRowCount);

   const { title, unit } = USAGE_SETTINGS[usageSwitch];

   return (
      <WidgetContainer>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow>
                     <CsvExportFromApiButton
                        url={url}
                        header={header}
                        cancelToken={cancelToken}
                        filename={'Usage_ByToken'}
                     />
                  </SimpleFlexRow>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <TrafficHint term={'playout'} hasTimeAxis={true} sizePx={16} />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer $minHeight={'680px'}>
            <Row>
               <Col>
                  <CardTitle className='mb-0'>{title}</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </Col>
            </Row>
            <ChartWrapper className='chart-wrapper' marginTopPx={15}>
               <Spinner loading={isLoading} parentTopMarginPx={-250}>
                  {errorMessage ? (
                     <CenteredMsgShowing height={602} marginTopPx={0}>
                        {errorMessage.message}
                     </CenteredMsgShowing>
                  ) : (
                     <RemoveBottomMargin>
                        <ToolkitProvider
                           keyField='token'
                           data={tableData}
                           columns={getColumnsOptions(unit)}
                           search={{ afterSearch }}
                           bootstrap4
                           id={11}
                        >
                           {(props) => (
                              <>
                                 <ShowOnRightSide>
                                    <SearchBar {...props.searchProps} tableId={'token'} />
                                 </ShowOnRightSide>
                                 <BootstrapTable
                                    {...props.baseProps}
                                    classes='fixed-table'
                                    pagination={paginationFactory({
                                       sizePerPage: 10,
                                       hideSizePerPage: true,
                                    })}
                                    striped
                                    hover
                                 />
                              </>
                           )}
                        </ToolkitProvider>
                     </RemoveBottomMargin>
                  )}
               </Spinner>
            </ChartWrapper>
         </WidgetBodyContainer>
         <WidgetFooterContainer>
            <Row>
               <Col className='text-right'>Found Tokens:</Col>
               <Col>
                  {isLoading || errorMessage ? '0' : getIntegerWithDecimalSeparator(rowCount)}
                  <AggLimitHint usage={usageSwitchRef.current} term={'tokens'} />
               </Col>
            </Row>
         </WidgetFooterContainer>
      </WidgetContainer>
   );
}

function getData(data, usageSwitch, pi = PLAYOUT) {
   const unitPropName = USAGE_SETTINGS[usageSwitch].resUnitPropNames;
   const unitConvertFn = USAGE_SETTINGS[usageSwitch].unitConversion;

   let total = 0;
   const tableData = [];

   data.forEach((tokenBucket) => {
      const usage = tokenBucket[pi][unitPropName];
      total += usage;

      tableData.push({
         token: tokenBucket[TOKEN],
         playout: unitConvertFn(usage),
         orgaHash: tokenBucket.orgaHash,
      });
   });

   total = unitConvertFn(total);
   const count = tableData.length;
   return { tableData, total, count };
}

function calculateTotalsAfterSearch(result, total, setTotal, setRowCount) {
   const delta = 0.001;

   let currSumPi = 0;

   for (let i = 0; i < result.length; i++) {
      currSumPi += result[i]['playout'];
   }

   if (total < currSumPi - delta || total > currSumPi + delta) {
      const searchResultTotal = currSumPi;
      const searchResultRowCount = result.length;
      setTotal(searchResultTotal);
      setRowCount(searchResultRowCount);
   }
}

export default UsageByToken;
