import React from 'react';
import { PropTypes } from 'prop-types';

import Carousel from 'nuka-carousel';

function CustomCarousel({ children }) {
   return (
      <Carousel
         renderCenterLeftControls={({ previousSlide }) => (
            <button onClick={previousSlide}>
               <i className='bi bi-caret-left text-white' />
            </button>
         )}
         renderCenterRightControls={({ nextSlide }) => (
            <button onClick={nextSlide}>
               <i className='bi bi-caret-right text-white' />
            </button>
         )}
         wrapAround={true}
      >
         {children}
      </Carousel>
   );
}

CustomCarousel.propTypes = {
   children: PropTypes.any,
};

export default CustomCarousel;
