import React, { useState } from 'react';

import { PropTypes } from 'prop-types';
import Legend from './Legend';
import { Col, Collapse, Row } from 'reactstrap';
import HeadLineSeparator from '../Common/HeadLineSeparator';
import styled from 'styled-components';
import { getMobileBreakPoint } from '../../../../util/BrowserTools';

const breakPointPx = getMobileBreakPoint(1150, 1);

const CollapsibleLegend = styled.div`
   display: none;
   @media (max-width: ${breakPointPx}px) {
      display: block;
   }
`;
CollapsibleLegend.displayName = 'CollapsibleLegend';

const InMapLegend = styled.div`
   display: block;
   @media (max-width: ${breakPointPx}px) {
      display: none;
   }
`;
CollapsibleLegend.displayName = 'CollapsibleLegend';

function LegendContainer({
   metric,
   mapHeight,
   maxHeatValue,
   total,
   subSwitch,
   onSubSwitch,
   view,
   zoomedRegion,
   showCompactLegend,
}) {
   const [isLegendOpen, setIsLegendOpen] = useState(true);
   return (
      <>
         <CollapsibleLegend>
            <Row className='mt-3'>
               <Col>
                  <HeadLineSeparator
                     text={isLegendOpen ? 'Legend - Click to hide' : 'Legend - Click to show'}
                     fontsize={13}
                     onClickHandler={(e) => {
                        setIsLegendOpen((currentState) => !currentState);
                     }}
                  />
               </Col>
            </Row>
            <Collapse isOpen={isLegendOpen}>
               <Row className='mt-3 mb-3'>
                  <Col>
                     <Legend
                        metric={metric}
                        mapHeight={mapHeight}
                        maxHeatValue={maxHeatValue}
                        total={total}
                        subSwitch={subSwitch}
                        onSubSwitch={onSubSwitch}
                        view={view}
                        zoomedRegion={zoomedRegion}
                        showCompactLegend={showCompactLegend}
                     />
                  </Col>
               </Row>
            </Collapse>
         </CollapsibleLegend>

         <InMapLegend>
            <Legend
               metric={metric}
               mapHeight={mapHeight}
               maxHeatValue={maxHeatValue}
               total={total}
               subSwitch={subSwitch}
               onSubSwitch={onSubSwitch}
               view={view}
               zoomedRegion={zoomedRegion}
               showCompactLegend={showCompactLegend}
            />
         </InMapLegend>
      </>
   );
}

LegendContainer.propTypes = {
   mapHeight: PropTypes.number,
   maxHeatValue: PropTypes.number,
   subSwitch: PropTypes.string,
   onSubSwitch: PropTypes.func,
   total: PropTypes.number,
   metric: PropTypes.string,
   view: PropTypes.string,
   zoomedRegion: PropTypes.string,
   showCompactLegend: PropTypes.bool,
};

export default LegendContainer;
