import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

import Top10Table from './Top10Table';
import HeadLineSeparator from '../Common/HeadLineSeparator';

import { worldmapRegionZoomSelector, worldmapViewSelector } from '../../../../redux/selectors/selectors';

import { PropTypes } from 'prop-types';
import { TOP10 } from './Top10Constants';
import { VIEW_MODE } from './../WorldMapConstants';
import BarChart from './Chart/BarChart';
import { MinHeightContainer, WorldmapErrorShowing } from '../../../../styledComponents/styledComponents';
import { TransparentSpinner } from '../../../common';

function Top10({ countriesData, metric, isLoading, hasError, issueMsg, mapWidth }) {
   const viewSwitch = useSelector(worldmapViewSelector);
   const zoomedRegion = useSelector(worldmapRegionZoomSelector);
   const { metricProp } = TOP10[metric];
   const top10 = isLoading ? [] : getTop10(metric, countriesData);
   const secondLine = zoomedRegion !== '' ? zoomedRegion : undefined;
   const displayRegionsInfo = viewSwitch === VIEW_MODE.REGION && zoomedRegion === '';

   return (
      metricProp !== undefined && (
         <MinHeightContainer $minHeightInPx={200} $position={'relative'}>
            <Row className='mt-3'>
               <Col>
                  <HeadLineSeparator
                     text={displayRegionsInfo ? 'World Region Comparison' : 'Top 10'}
                     secondLine={secondLine}
                     fontsize={13}
                  />
               </Col>
            </Row>

            <Row className='mt-3 mb-3'>
               <Col>
                  {displayRegionsInfo ? (
                     <BarChart data={top10} metric={metric} />
                  ) : (
                     <Top10Table data={top10} metric={metric} areRegions={displayRegionsInfo} />
                  )}
               </Col>
            </Row>
            {isLoading ? (
               <TransparentSpinner height={'220px'} width={`${mapWidth}px`} />
            ) : hasError ? (
               <WorldmapErrorShowing height={220} width={mapWidth} $alpha={0.8}>
                  {issueMsg}
               </WorldmapErrorShowing>
            ) : (
               <></>
            )}
         </MinHeightContainer>
      )
   );
}

function getTop10(metric, countries) {
   const { metricProp, countProp } = TOP10[metric];

   if (!metricProp) {
      return [];
   }

   return countries.slice(0, 10).map((countryData, idx) => {
      // legacy means, that some backend API routes do not provide the field regionName anymore
      const legacyCountryName = countryData.countryName ? countryData.countryName : countryData.regionName;
      const rankedCountry = {
         rank: idx + 1,
         name: countryData.name ? countryData.name : legacyCountryName,
         figure: countryData[metricProp],
      };
      if (countProp) {
         rankedCountry.count = countryData[countProp];
      }
      return rankedCountry;
   });
}

Top10.propTypes = {
   countriesData: PropTypes.arrayOf(
      PropTypes.shape({
         alpha: PropTypes.string,
         id: PropTypes.string,
         coor: PropTypes.arrayOf(PropTypes.number),
         // country of the world
         countryName: PropTypes.string,
         // US region
         regionName: PropTypes.string,
         // Buffering ratio
         ratio: PropTypes.number,
         totalTime: PropTypes.number,
         // Latency
         latency: PropTypes.number,
         // Playtime
         playtime: PropTypes.number,
         // Usage
         sent: PropTypes.number,
         received: PropTypes.number,
         total: PropTypes.number,
         // ABR
         compare: PropTypes.shape({
            labels: PropTypes.array,
            mobileSet: PropTypes.array,
            desktopSet: PropTypes.array,
            maximumValue: PropTypes.number,
         }),
         profileData: PropTypes.object,
         tableRow: PropTypes.arrayOf(
            PropTypes.shape({
               mobile: PropTypes.number,
               desktop: PropTypes.number,
               mobilePercent: PropTypes.number,
               desktopPercent: PropTypes.number,
               profile: PropTypes.string,
               totalCount: PropTypes.number,
               maximumValue: PropTypes.number,
            }),
         ),
         // several metrics
         count: PropTypes.any,
      }),
   ),
   metric: PropTypes.string,
   isLoading: PropTypes.bool,
   hasError: PropTypes.bool,
   issueMsg: PropTypes.string,
   mapWidth: PropTypes.number,
};

export default Top10;
