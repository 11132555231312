import React from 'react';
import { useSelector } from 'react-redux';

import { usageSwitchSelector } from '../../../redux/selectors/selectors';

import { INITIAL_PROTOCOL_INDEX } from '../ZoomConstants';
import { PropTypes } from 'prop-types';
import GeneralTemplate from '../GeneralTemplate';
import { WIDGET_SETTINGS } from '../widgetSettings/HomeView';
import Modal from '../../common/Modal/Modal';

function UsageTemplate({ timespan, closeModal, metric, selectedStackSubBar }) {
   const { title, categoryLabels, pathParams } = WIDGET_SETTINGS[metric];

   const usageSwitch = useSelector(usageSwitchSelector);

   const initialProtocol = selectedStackSubBar
      ? categoryLabels.findIndex((label) => label.toUpperCase() === selectedStackSubBar.toUpperCase())
      : INITIAL_PROTOCOL_INDEX;

   return (
      <Modal closeModal={closeModal}>
         <GeneralTemplate
            title={title[usageSwitch]}
            pathParams={pathParams(usageSwitch)}
            urlAddon={''}
            timespan={timespan}
            metric={metric}
            categoryLabels={categoryLabels}
            usageSwitch={usageSwitch}
            initialCategoryIdx={initialProtocol}
            settings={WIDGET_SETTINGS[metric]}
         />
      </Modal>
   );
}

UsageTemplate.propTypes = {
   timespan: PropTypes.object,
   closeModal: PropTypes.func,
   metric: PropTypes.string,
   selectedStackSubBar: PropTypes.string,
};

export default UsageTemplate;
