import React from 'react';

import { REQUIRED_LEVELS } from '../WorldMapConstants';

function AccessController({ children }) {
   const metricComponent = React.Children.toArray(children)[0];
   const { metricName } = metricComponent.props;

   return <>{accessGranted(metricName) && children}</>;
}

function accessGranted(metric) {
   const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
   const organizationLevel = userInfo.accessLevel.code;
   return organizationLevel >= REQUIRED_LEVELS[metric];
}

export default AccessController;
