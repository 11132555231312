import React from 'react';

import { PropTypes } from 'prop-types';

import styled from 'styled-components';

import { ArrowDownSvg } from '../../../assets/worldmap/ArrowDownSvg';
import { capitalizeFirstLetter } from '../../../util/UtilFunctions';
import { AccessLevel, mapLevelCodeToName } from '../../../constants/AccessLevels';

const SwitchContent = styled.div`
   right: 103px;
   top: 26px;
   position: absolute;
   display: ${(props) => (props.show ? 'block' : 'none')};
`;
SwitchContent.displayName = 'SwitchContent';

const LevelMenu = styled.div`
   min-width: 80px;
   display: ${(props) => (props.show ? 'block' : 'none')};
`;
LevelMenu.displayName = 'LevelMenu';

const LevelOption = styled.div`
   padding: 4px 15px;
`;
LevelOption.displayName = 'LevelOption';

const AccessLevelNames = Object.keys(AccessLevel).filter((lvl) => lvl !== 'NONE');

function AccessLevelDropDown({ isDroppedDown, selectedLevel, onDropDown, onLevelSelection }) {
   return (
      <DropDownButton onDropDown={onDropDown} isDroppedDown={isDroppedDown}>
         {capitalizeFirstLetter(mapLevelCodeToName[selectedLevel])}
         <ArrowDownSvg fill={!isDroppedDown ? '#777777' : '#20a8d8'} height={'18px'} width={'18px'} />
         <SwitchContent show={isDroppedDown}>
            <LevelMenu className={'dropdown-menu'} show={isDroppedDown}>
               {AccessLevelNames.map((levelName) => (
                  <LevelOption
                     id='dropDownOpts'
                     key={levelName}
                     href='#'
                     className='dropdown-item'
                     onClick={() => onLevelSelection(AccessLevel[levelName])}
                  >
                     {capitalizeFirstLetter(levelName)}
                  </LevelOption>
               ))}
            </LevelMenu>
         </SwitchContent>
      </DropDownButton>
   );
}

const StyledButton = styled.button`
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: none !important;
`;

function DropDownButton({ children, onDropDown, isDroppedDown }) {
   return (
      <StyledButton
         id='dropDownButton'
         aria-haspopup='true'
         aria-expanded={isDroppedDown}
         onClick={() => onDropDown(!isDroppedDown)}
         onBlur={() => onDropDown(false)}
      >
         {children}
      </StyledButton>
   );
}

AccessLevelDropDown.propTypes = {
   isDroppedDown: PropTypes.bool,
   selectedLevel: PropTypes.number,
   onDropDown: PropTypes.func,
   onLevelSelection: PropTypes.func,
};

export default AccessLevelDropDown;
