import { parseString, parseObjectArray, parseIntValue, parseObject, parseSimpleObjectArray } from './urlToStore';
import { getSingleUrlParam, getArrayUrlParam, getObjectUrlParam, getSimpleArrayUrlParam } from './storeToUrl';
import { WORLDMAP_METRIC } from '../components/widgets/world/WorldMapConstants';
import { BREAKDOWN_VIEW, HOME_VIEW } from '../constants/general';
import { eventViews } from '../constants/events';

export const urlSyncSettings = {
   orgHash: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'filter',
      storeName: 'hash',
   },
   orgName: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'filter',
      storeName: 'name',
   },
   originHash: {
      // originHash must be under orgaHash
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      adminTargetProp: 'hash',
   },
   originName: {
      // originName must be under orgaName
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      adminTargetProp: 'name',
   },
   countries: {
      parseFn: parseObjectArray,
      urlFn: getArrayUrlParam,
      group: 'filter',
      storeName: 'countries',
   },
   tags: {
      parseFn: parseObjectArray,
      urlFn: getArrayUrlParam,
      group: 'filter',
      storeName: 'tags',
   },
   events: {
      parseFn: parseObjectArray,
      urlFn: getArrayUrlParam,
      group: 'filter',
      storeName: 'events',
      views: eventViews,
   },
   streamNames: {
      parseFn: parseObjectArray,
      urlFn: getArrayUrlParam,
      group: 'filter',
      storeName: 'streamNames',
   },
   usageSwitch: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: '',
      storeName: 'usageSwitch',
      views: [HOME_VIEW, BREAKDOWN_VIEW],
   },
   timeShortcutId: {
      parseFn: parseIntValue,
      urlFn: getSingleUrlParam,
      group: 'timespan',
      storeName: 'id',
   },
   timeInterval: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'timespan',
      storeName: 'interval',
   },
   timeStart: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'timespan',
      storeName: 'gte',
      postProcessing: 'format',
   },
   timeEnd: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'timespan',
      storeName: 'lt',
      postProcessing: 'format',
   },
   worldmapMetric: {
      parseFn: parseObject,
      urlFn: getObjectUrlParam,
      group: '',
      storeName: 'worldmapMetric',
   },
   piWorldSubSwitch: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'worldmapSubSwitch',
      storeName: WORLDMAP_METRIC.PI,
   },
   abrWorldSubSwitch: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'worldmapSubSwitch',
      storeName: WORLDMAP_METRIC.ABR_PLAYTIME,
   },
   cdnWorldSwitch: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'worldmapOptions',
      storeName: 'cdn',
   },
   worldView: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: '',
      storeName: 'worldmapView',
   },
   worldRegionZoom: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: '',
      storeName: 'worldmapRegionZoom',
   },
   troubleStart: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'troubleTimespan',
      storeName: 'start',
      postProcessing: 'format',
   },
   troubleEnd: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'troubleTimespan',
      storeName: 'end',
      postProcessing: 'format',
   },
   troubleInterval: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'troubleTimespan',
      storeName: 'troubleInterval',
      postProcessing: 'selectInterval',
   },
   troubleMode: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: '',
      storeName: 'troubleMode',
   },
   troubleIngestInput: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'troubleIngestInput',
      storeName: 'ingestInput',
   },
   troubleIngestStream: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'troubleIngestStream',
      storeName: 'ingestStream',
   },
   troublePublishStart: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'troubleSelectedPublish',
      storeName: 'publishStart',
   },
   troublePublishEnd: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'troubleSelectedPublish',
      storeName: 'publishEnd',
   },
   troublePublishFineStart: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'troubleSelectedPublish',
      storeName: 'publishFineStart',
   },
   troublePublishFineEnd: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'troubleSelectedPublish',
      storeName: 'publishFineEnd',
   },
   troublePlayoutInput: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'troublePlayoutInput',
      storeName: 'userId',
   },
   troublePlayoutStream: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: '',
      storeName: 'troublePlayoutStream',
   },
   troublePlayerId: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'troubleSelectedPlayout',
      storeName: 'playerId',
   },
   troublePlayoutFineStart: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'troubleSelectedPlayout',
      storeName: 'playoutFineStart',
   },
   troublePlayoutFineEnd: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'troubleSelectedPlayout',
      storeName: 'playoutFineEnd',
   },
   ipFilteringStart: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'ipFilteringTimespan',
      storeName: 'start',
      postProcessing: 'format',
   },
   ipFilteringEnd: {
      parseFn: parseString,
      urlFn: getSingleUrlParam,
      group: 'ipFilteringTimespan',
      storeName: 'end',
      postProcessing: 'format',
   },
   ipFilteringStreams: {
      parseFn: parseSimpleObjectArray,
      urlFn: getSimpleArrayUrlParam,
      group: 'ipFilteringFilter',
      storeName: 'streamNames',
   },
   ipFilteringTags: {
      parseFn: parseSimpleObjectArray,
      urlFn: getSimpleArrayUrlParam,
      group: 'ipFilteringFilter',
      storeName: 'tags',
   },
};
