import React from 'react';

import { DetailTile, TileContainer, CarouselContentContainer } from '../Styles';
import {
   BottomMargin,
   Description,
   DescriptionSpan,
   StandardContainer,
   ValueText,
} from '../../../../styledComponents/styledComponents';
import { E2E } from '../../../../constants/general';
import TextWithToolTip from '../TextWithToolTip';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import moment from 'moment';
import { CHRONOLOGICAL_DATE_TIME_FORMAT } from '../constants';
import { showOverflowTooltip } from '../../../common/OverflowTooltip/OverflowTooltip';
import { CustomCarousel } from '../../../common';

function addHiddenKeyColumn(rows) {
   return rows.map((row, idx) => ({ ...row, hiddenKey: idx }));
}

const DETAILS_BASIC = [
   { name: 'Protocol', prop: 'protocol' },
   { name: 'Data center', prop: 'dataCenter' },
   { name: 'Server', prop: 'server' },
   { name: 'IP', prop: 'ip' },
];

const DETAILS_RTMP = [
   { name: 'Country', prop: 'country' },
   { name: 'City', prop: 'city' },
   { name: 'Resolution width', prop: 'width' },
   { name: 'Resolution height', prop: 'height' },
];

const DETAILS_WebRTC_1 = [
   { name: 'Protocol', prop: 'protocol' },
   { name: 'Webcaster ID', prop: 'webcasterId' },
   { name: 'WebRTC Client version', prop: 'clientVersion' },
   { name: 'IP', prop: 'ip' },
];

const DETAILS_WebRTC_2 = [
   { name: 'Device', prop: 'deviceType' },
   { name: 'Camera', prop: 'videoDevice' },
   { name: 'Resolution width', prop: 'width' },
   { name: 'Resolution height', prop: 'height' },
];

const DETAILS_WebRTC_3 = [
   { name: 'OS', prop: 'os' },
   { name: 'OS version', prop: 'osVersion' },
   { name: 'Browser', prop: 'browser' },
   { name: 'Browser version', prop: 'browserVersion' },
];

const DETAILS_WebRTC_4 = [
   { name: 'Country', prop: 'country' },
   { name: 'City', prop: 'city' },
   { name: 'Server', prop: 'server' },
   { name: 'Data center', prop: 'dataCenter' },
];

const WebRtcDetails = [DETAILS_WebRTC_1, DETAILS_WebRTC_2, DETAILS_WebRTC_3, DETAILS_WebRTC_4];
const RtmpDetails = [DETAILS_BASIC, DETAILS_RTMP];

const columns = [
   {
      text: 'hiddenKey',
      dataField: 'hiddenKey',
      hidden: 'true',
   },
   {
      text: 'Event',
      dataField: 'eventName',
      style: { verticalAlign: 'middle', textAlign: 'center' },
      headerAlign: 'center',
   },
   {
      text: 'Error code',
      dataField: 'code',
      style: { verticalAlign: 'middle', textAlign: 'center' },
      headerAlign: 'center',
      headerStyle: { width: '90px' },
      formatter: (cell) => (cell ? cell : '-'),
   },
   {
      text: 'Timestamp',
      dataField: 'timestamp',
      style: { verticalAlign: 'middle', textAlign: 'center' },
      formatter: (cell) => moment.utc(cell).format(CHRONOLOGICAL_DATE_TIME_FORMAT),
      headerAlign: 'center',
   },
   {
      text: 'Message',
      dataField: 'message',
      style: { verticalAlign: 'middle', textAlign: 'center' },
      formatter: (cell) => (cell ? showOverflowTooltip(cell) : '-'),
      headerAlign: 'center',
   },
];

function ExpandedPublishCell(props, mode, showRtmpDetails, showWebrtcDetails) {
   const infoProps = props;
   const hasErrorLogs = props.logs;

   return (
      <>
         {!showRtmpDetails && !showWebrtcDetails && (
            <CarouselContentContainer pBottomPx={0}>
               <TileContainer gapPx={20} isFullScreen={mode !== E2E}>
                  {DETAILS_BASIC.map(({ name, prop }) => (
                     <DetailTile key={prop} gapPx={20}>
                        <Description marginLeftPx={0}>{name}</Description>
                        <ValueText>
                           {infoProps[prop] ? <TextWithToolTip text={infoProps[prop]} idName={prop} /> : '-'}
                        </ValueText>
                     </DetailTile>
                  ))}
               </TileContainer>
            </CarouselContentContainer>
         )}
         {showWebrtcDetails && (
            <>
               {hasErrorLogs ? (
                  <CustomCarousel>
                     <CarouselContentContainer pTopPx={0}>
                        <StandardContainer $paddingPx={20}>
                           <BottomMargin pxSize={10}>
                              <DescriptionSpan fontSizePX={14} marginLeftPx={0}>
                                 {'Recorded log messages in conjunction with the error'}
                              </DescriptionSpan>
                           </BottomMargin>
                           <div>
                              <BootstrapTable
                                 keyField='hiddenKey'
                                 data={addHiddenKeyColumn(props.logs)}
                                 columns={columns}
                                 classes='fixed-table'
                                 id='ipFilteringTable'
                                 striped
                                 hover
                                 bootstrap4
                              />
                           </div>
                        </StandardContainer>
                     </CarouselContentContainer>
                     {WebRtcDetails.map((details) => (
                        <CarouselContentContainer key={details[0].name}>
                           <TileContainer gapPx={20} isFullScreen={mode !== E2E}>
                              {details.map(({ name, prop }) => (
                                 <DetailTile key={prop} gapPx={20}>
                                    <Description marginLeftPx={0}>{name}</Description>
                                    <ValueText>
                                       {infoProps[prop] ? (
                                          <TextWithToolTip text={infoProps[prop]} idName={prop} />
                                       ) : (
                                          '-'
                                       )}
                                    </ValueText>
                                 </DetailTile>
                              ))}
                           </TileContainer>
                        </CarouselContentContainer>
                     ))}
                  </CustomCarousel>
               ) : (
                  <CustomCarousel>
                     {WebRtcDetails.map((details) => (
                        <CarouselContentContainer key={details[0].name}>
                           <TileContainer gapPx={20} isFullScreen={mode !== E2E}>
                              {details.map(({ name, prop }) => (
                                 <DetailTile key={prop} gapPx={20}>
                                    <Description marginLeftPx={0}>{name}</Description>
                                    <ValueText>
                                       {infoProps[prop] ? (
                                          <TextWithToolTip text={infoProps[prop]} idName={prop} />
                                       ) : (
                                          '-'
                                       )}
                                    </ValueText>
                                 </DetailTile>
                              ))}
                           </TileContainer>
                        </CarouselContentContainer>
                     ))}
                  </CustomCarousel>
               )}
            </>
         )}
         {showRtmpDetails && (
            <>
               <CustomCarousel>
                  {RtmpDetails.map((details) => (
                     <CarouselContentContainer key={details[0].name}>
                        <TileContainer gapPx={20} isFullScreen={mode !== E2E}>
                           {details.map(({ name, prop }) => (
                              <DetailTile key={prop} gapPx={20}>
                                 <Description marginLeftPx={0}>{name}</Description>
                                 <ValueText>
                                    {infoProps[prop] ? <TextWithToolTip text={infoProps[prop]} idName={prop} /> : '-'}
                                 </ValueText>
                              </DetailTile>
                           ))}
                        </TileContainer>
                     </CarouselContentContainer>
                  ))}
               </CustomCarousel>
            </>
         )}
      </>
   );
}

export default ExpandedPublishCell;
