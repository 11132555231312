import React from 'react';
import { PropTypes } from 'prop-types';

import { getTrafficLightScale } from '../LegendScales';
import { HeadLine, Scale, ScaleField, ColoredValue } from '../LegendStyles';

export function TrafficLight({
   headline,
   greenValue,
   yellowValue,
   valueManipulation,
   scaleFieldWidth,
   show,
   total,
   scaleFieldsVisible,
}) {
   return (
      <>
         {scaleFieldsVisible ? (
            <>
               <HeadLine>{headline}</HeadLine>
               <Scale>
                  {getTrafficLightScale({ greenValue, yellowValue, valueManipulation, show }).map((field, idx) => (
                     <ScaleField key={`trafficScaleField-${idx}`} bgColor={field.color} width={scaleFieldWidth}>
                        {field.value}
                     </ScaleField>
                  ))}
               </Scale>
               <HeadLine>
                  {'Total: '}
                  <ColoredValue color={'rgba(0, 0, 0, 1)'}>{total[1]}</ColoredValue>
               </HeadLine>
            </>
         ) : (
            <>
               <HeadLine>{total[0]}</HeadLine>
               <HeadLine>
                  <ColoredValue color={'rgba(0, 0, 0, 1)'}>{total[1]}</ColoredValue>
               </HeadLine>
            </>
         )}
      </>
   );
}

TrafficLight.propTypes = {
   headline: PropTypes.string,
   valueManipulation: PropTypes.func,
   scaleFieldWidth: PropTypes.number,
   greenValue: PropTypes.number,
   yellowValue: PropTypes.number,
   show: PropTypes.bool,
   total: PropTypes.array,
   scaleFieldsVisible: PropTypes.bool,
};
