import styled from 'styled-components';

export const Arrow = styled.div`
   font-size: ${(props) => (props.size === undefined ? '0.875rem' : `${props.size}px`)};
   color: #fff;
   padding-left: 8px;
   padding-right: 8px;
   border-radius: 5px;
   border: 1px solid transparent;
   background-color: rgb(255, 147, 63);
   cursor: pointer;
   margin-right: 20px;
   width: 70px;
   text-align: center;
   display: flex;
`;
Arrow.displayName = 'Arrow';

export const CheckButton = styled.span`
   font-size: ${(props) => (props.size === undefined ? '0.875rem' : `${props.size}px`)};
   color: #fff;
   border-radius: 4px;
   border: 1px solid transparent;
   background-color: ${(props) => (props.active ? 'rgb(255, 147, 63)' : 'rgba(255, 147, 63, 0.5)')};
   cursor: ${(props) => (props.active ? 'pointer' : 'not-allowed')};
   margin-right: ${(props) => (props.marginRightPx !== undefined ? props.marginRightPx : 20)}px;
   padding: 7px 12px;
   font-size: 14px;
   line-height: 1.5;
   width: ${(props) => (props.widthPx !== undefined ? props.widthPx : 70)}px;
   display: inline-block;
   text-align: center;
`;
CheckButton.displayName = 'CheckButton';

export const AddText = styled.span`
   font-size: 14px;
   padding-top: 8px;
   margin: 0px 3px;
`;
AddText.displayName = 'AddText';

export const CustomCol = styled.div`
   flex-grow: ${(props) => (props.grow === undefined ? '1' : `${props.grow}`)};
   flex-shrink: ${(props) => (props.shrink === undefined ? '1' : `${props.shrink}`)};
   flex-basis: ${(props) => (props.basis === undefined ? '134px' : `${props.basis}px`)};
   margin-right: ${(props) => (props.marginRight === undefined ? '0px' : `${props.marginRight}px`)};
   margin-left: ${(props) => (props.marginLeft === undefined ? '0px' : `${props.marginLeft}px`)};
`;
CustomCol.displayName = 'CustomCol';
