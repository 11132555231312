import PropTypes from 'prop-types';

import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export function OverflowTooltip({ text, isAlwaysShown }) {
   const textRef = useRef(null);
   const [title, setTitle] = useState(undefined);
   const width = useWindowDimensions();

   useEffect(() => {
      if (textRef.current !== null) {
         const defaultText = textRef.current.clientWidth < textRef.current.scrollWidth ? text : undefined;
         const tableHeaderText = textRef.current.clientWidth <= textRef.current.scrollWidth ? text : undefined;
         const toolTipText = isAlwaysShown ? tableHeaderText : defaultText;
         setTitle(toolTipText);
      }
   }, [text, width, isAlwaysShown]);

   return (
      <div title={title} ref={textRef} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
         {text}
      </div>
   );
}

OverflowTooltip.propTypes = {
   text: PropTypes.any,
   isAlwaysShown: PropTypes.bool,
};

export function showOverflowTooltip(text) {
   return <OverflowTooltip text={text} isAlwaysShown={false} />;
}

export function showAlwaysOverflowTooltip(text) {
   return <OverflowTooltip text={text} isAlwaysShown={true} />;
}
