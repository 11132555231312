import roundTo from 'round-to';
import { getWorldRegionsAggObject } from '../WorldMapConstants';
import { getOutput } from './General';

const country_alpha_id_mapper = require('../../../../assets/worldmap/compact_country_alpha_id_mapper.json');

export function processAbrViewersAdapter(countries) {
   const adaptedCountries = countries.map((countryBucket) => {
      const { country, alpha2, averagePlaybackTime, uniqueViewer, abrProfiles } = countryBucket;
      const { coordinates, worldRegion, code } = country_alpha_id_mapper[alpha2];
      const coor = [coordinates.longitude, coordinates.latitude];
      const countryResult = {
         alpha: alpha2,
         coor,
         id: code.numeric,
         name: country,
         worldRegion,
         avgPlaytime: averagePlaybackTime.seconds,
         total: uniqueViewer.count,
         profilesMetrics: [],
      };

      const sumUniqueViewersOfAbrProfiles = abrProfiles.reduce((sum, profile) => {
         return sum + profile.uniqueViewer.count;
      }, 0);

      // sumUniqueViewersOfAbrProfiles = sum of unique player_ids for each profile separately
      // country.total (viewer) = sum of unique player_ids per US region / country
      // sumUniqueViewersOfAbrProfiles !== total
      // => (sumUniqueViewersOfAbrProfiles > total) because of profile switching for some viewer

      abrProfiles.forEach((profile) => {
         const { averagePlaybackTime, uniqueViewer, abrProfile } = profile;
         const profileResult = {
            name: abrProfile,
            viewer: uniqueViewer.count,
            avgPlaytime: averagePlaybackTime.seconds,
            viewerPercentage: roundTo((uniqueViewer.count * 100) / sumUniqueViewersOfAbrProfiles, 1),
         };
         countryResult.profilesMetrics.push(profileResult);
      });

      return countryResult;
   });

   const worldRegions = createWorldRegionsAndTopData(adaptedCountries);

   return getOutput(adaptedCountries, worldRegions);
}

function createWorldRegionsAndTopData(countries) {
   const worldRegions = getWorldRegionsAggObject({
      viewerWeightedAvgPlaytimeSum: 0,
      viewerSum: 0,
   });

   countries.forEach((country) => {
      const { worldRegion, avgPlaytime, total } = country;
      worldRegions[worldRegion].viewerSum += total;
      worldRegions[worldRegion].viewerWeightedAvgPlaytimeSum += total * avgPlaytime;
      worldRegions[worldRegion].countries.push(country);
   });

   Object.keys(worldRegions).forEach((worldRegion) => {
      worldRegions[worldRegion].avgPlaytime = roundTo(
         worldRegions[worldRegion].viewerWeightedAvgPlaytimeSum / worldRegions[worldRegion].viewerSum,
         1,
      );
      worldRegions[worldRegion].total = worldRegions[worldRegion].viewerSum;
      delete worldRegions[worldRegion].viewerWeightedAvgPlaytimeSum;
      delete worldRegions[worldRegion].viewerSum;
   });

   return worldRegions;
}
