import { BadgeListItem } from '../../styledComponents/styledComponents';
import React, { useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip/dist/react-tooltip.umd';
import ReactDOMServer from 'react-dom/server';
import { uniqueIdFactory } from '../../util/UtilFunctions';

function ZoomHint() {
   const [uid] = useState(uniqueIdFactory('_'));

   const id = `zoom-badge${uid}`;

   const tipText = ReactDOMServer.renderToStaticMarkup(
      <>
         <div>The Zoom feature provides more insight</div>
         <div>into interesting datapoints.</div>
         <div>To open the Zoom view just click</div>
         <div>on a datapoint in the chart.</div>
      </>,
   );
   return (
      <>
         <BadgeListItem
            $fontSizePx={10.5}
            $fontWeight={700}
            $marginTopPx={1}
            $marginBottomPx={0}
            $marginRightPx={0}
            $bgColor={'white'}
            $color={'#ff923fc2'}
            $cursor={'default'}
            $border={'2px solid #ff923fc2'}
            data-tooltip-id={id}
            data-tooltip-html={tipText}
            $heightPx={19}
         >
            Zoom
         </BadgeListItem>
         <ReactTooltip
            id={id}
            place={'bottom'}
            className={'reduced-line-height bring-on-top'}
            backgroundColor={'rgba(0,0,0,0.9)'}
            textColor={'rgb(240,240,240)'}
            style={{ textAlign: 'center' }}
         />
      </>
   );
}

export default ZoomHint;
