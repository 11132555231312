import { TRAFFIC, PLAYTIME } from '../../../constants/general';

import { generateZoomInitialTables } from '../../../util/TableTools';

import {
   BINTU_ACCOUNTING,
   CONCURRENT_VIEWER,
   CONCURRENT_VIEWER_IDX,
   INGEST,
   MONTH2DATE,
   PI_USAGE,
   PLAYOUT,
} from '../ZoomConstants';
import {
   format_time_Number,
   getIntegerWithDecimalSeparator,
   roundUsageWith5Digits,
} from '../../../util/NumberFormatter';

function formatter(usageSwitch) {
   const roundFn = usageSwitch === TRAFFIC ? roundUsageWith5Digits : format_time_Number;
   return function (cell) {
      if (cell === '-') return cell;
      const rounded = roundFn(cell);
      return rounded === 0 ? '< 0.001' : rounded;
   };
}

// NOTE: order of props e.g. in columnNames must be aligned with order of props in API response object array!
export const WIDGET_SETTINGS = {
   // scheme:
   //    title = [object] traffic or playtime -> names
   //    exIndexName = [string] used ES index (break down option availability)
   //    path = [string] metric specific API route path
   //    pathParams = [function] params for path to added selected usage type (traffic or playtime)
   //    categoryLabels = [Array<string>] name of the categories (upper button bar)
   //    columnNames = [object] or [function]
   //             [object] key is metric prop of response, value is displayed column name in table
   //             [function] for ABR dynamic columns, fn for transforming propname to displayed column name
   //    formatters = [function] one format fn is used for all columns/metrics dependent on usage type
   [PI_USAGE]: {
      title: {
         [TRAFFIC]: 'traffic',
         [PLAYTIME]: 'playtime',
      },
      exIndexName: BINTU_ACCOUNTING,
      path: 'usage',
      pathParams: (usageSwitch) => `/${usageSwitch}`,
      categoryLabels: ['Playout', 'Ingest'],
      columnNames: { playouts: 'Usage', ingests: 'Usage' },
      formatters: formatter,
   },
   [MONTH2DATE]: {
      title: {
         [TRAFFIC]: 'traffic',
         [PLAYTIME]: 'playtime',
      },
      exIndexName: BINTU_ACCOUNTING,
      path: 'usage',
      pathParams: (usageSwitch) => `/${usageSwitch}`,
      categoryLabels: ['Playout', 'Ingest'],
      columnNames: { playouts: 'Playout', ingests: 'Ingest' },
      formatters: formatter,
   },
   [CONCURRENT_VIEWER]: {
      title: () => 'maximum concurrent viewers',
      exIndexName: CONCURRENT_VIEWER_IDX,
      path: 'viewers/concurrent',
      categoryLabels: ['Maximum concurrent viewers'],
      dataFieldNames: ['maximum', 'average'],
      columnNames: { maximum: 'Maximum', average: 'Average' },
      formatters: (cell) => getIntegerWithDecimalSeparator(cell),
   },
   [PLAYOUT]: {
      title: {
         [TRAFFIC]: 'traffic',
         [PLAYTIME]: 'playtime',
      },
      exIndexName: BINTU_ACCOUNTING,
      path: 'usage/protocol',
      pathParams: (usageSwitch) => `/playout/${usageSwitch}`,
      categoryLabels: ['H5Live', 'RTMP', 'HLS', 'H5Live Token'],
      columnNames: () => 'Protocol usage',
      formatters: formatter,
   },
   [INGEST]: {
      title: {
         [TRAFFIC]: 'traffic',
         [PLAYTIME]: 'playtime',
      },
      exIndexName: BINTU_ACCOUNTING,
      initialTable: generateZoomInitialTables(['rtmpUsage', 'webrtcUsage']),
      path: 'usage/protocol',
      pathParams: (usageSwitch) => `/ingest/${usageSwitch}`,
      categoryLabels: ['RTMP', 'Webcaster'],
      columnNames: () => 'Protocol usage',
      formatters: formatter,
   },
};
