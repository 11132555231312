import moment from 'moment';
import { ABSOLUTE_FORMAT } from '../../../constants/general';
import { getShortCutSettings, idShortcutMap } from './ShortCutSettings';

export function getShortcutTimespan(id, isLongTermShortcuts) {
   let start = moment.utc();
   const option = idShortcutMap.get(id);
   const { settings, dateFns } = getShortCutSettings(isLongTermShortcuts)[option];

   const { span, startOf, interval, labelFormat, titleFormat } = settings;
   const { getStart, getEnd } = dateFns;

   const gte = getStart(start, startOf);
   const lt = getEnd(gte, start, span, startOf);

   return {
      id,
      format: labelFormat,
      gte,
      lt,
      interval,
      title: gte.format(titleFormat),
   };
}

export function getAbsoluteDateTimespan(start, end, interval) {
   const title = `From ${start.format(ABSOLUTE_FORMAT)} to ${end.format(ABSOLUTE_FORMAT)} by ${interval.value}`;
   return {
      id: 0,
      format: interval.format,
      gte: start,
      lt: end,
      interval: interval.value,
      title,
   };
}

export function getUrlSyncProps(timespan) {
   const { id, interval, gte, lt } = timespan;
   return { id, interval, gte, lt };
}
