import React from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { EXT_DATE_TIME_MILLISECS_FORMAT, TITLE_DATE_TIME_FORMAT } from '../constants';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import { ChartWrapper, WidgetContainer } from '../../../../styledComponents/styledComponents';
import { CardBody, CardTitle, Col, Row } from 'reactstrap';
import { showAlwaysOverflowTooltip } from '../../../common/OverflowTooltip/OverflowTooltip';

const columns = [
   {
      text: 'hiddenKey',
      dataField: 'hiddenKey',
      hidden: 'true',
   },
   {
      text: 'Drop',
      dataField: 'type',
      headerStyle: { fontSize: '13px' },
      formatter: (cell) => showAlwaysOverflowTooltip(cell),
   },
   {
      text: 'Date',
      dataField: 'date',
      headerStyle: { fontSize: '13px' },
      formatter: (cell) => showAlwaysOverflowTooltip(cell),
   },
];

function addHiddenKeyColumn(rows) {
   return rows.map((row, idx) => ({ ...row, hiddenKey: idx }));
}

function DropTable({ height, drops, start, end }) {
   const startFormatted = start.format(TITLE_DATE_TIME_FORMAT);
   const endFormatted = end.format(TITLE_DATE_TIME_FORMAT);

   const cutDrops = drops.filter((drop) => {
      const dropMoment = moment.utc(drop.date, EXT_DATE_TIME_MILLISECS_FORMAT);
      return dropMoment.diff(start) >= 0 && end.diff(dropMoment) >= 0;
   });

   return (
      <WidgetContainer>
         <CardBody>
            <Row>
               <Col>
                  <CardTitle className='mb-0'>Drops</CardTitle>
                  <div className='small text-muted'>{`From ${startFormatted} to ${endFormatted} \u00A0\u00A0( UTC time )`}</div>
               </Col>
            </Row>
            <ChartWrapper
               className='chart-wrapper'
               height={height}
               marginTopPx={45}
               autoScroll={true}
               paddingRightPx={4}
            >
               <ToolkitProvider keyField='hiddenKey' data={addHiddenKeyColumn(cutDrops)} columns={columns} bootstrap4>
                  {(props) => (
                     <>
                        <BootstrapTable
                           {...props.baseProps}
                           pagination={paginationFactory({
                              sizePerPage: 5,
                              hideSizePerPage: true,
                           })}
                           striped
                           hover
                           id={'CustomTable-drops'}
                        />
                     </>
                  )}
               </ToolkitProvider>
            </ChartWrapper>
         </CardBody>
      </WidgetContainer>
   );
}

DropTable.propTypes = {
   drops: PropTypes.array,
   height: PropTypes.number,
   start: PropTypes.object,
   end: PropTypes.object,
};

DropTable.defaultProps = {
   height: 295,
};
export default DropTable;
