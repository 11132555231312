import React from 'react';

import QoeDonut from './QoeSubComponents/QoeDonut';

function QoeContent({ countryData, isHovered, isUnhoverTransition }) {
   return (
      <>
         {(isHovered || isUnhoverTransition) && (
            <div className='mt-1 mb-1'>
               <QoeDonut
                  width={150}
                  height={150}
                  qoeData={countryData.qoeRatingCountOverRanges}
                  score={countryData.score}
               />
            </div>
         )}
      </>
   );
}

export default QoeContent;
