import React from 'react';

import { PropTypes } from 'prop-types';
import GeneralTemplate from '../GeneralTemplate';
import Modal from '../../common/Modal/Modal';

function DefaultTemplate({ group, term, timespan, closeModal, metric, settings }) {
   const { title, urlAddon, initialCategoryIdx, categoryLabels, categoryLabelTransformFn } = settings[metric];
   const determinedInitialCategory = initialCategoryIdx ? initialCategoryIdx : 0;
   const determinedCategoryLabels = categoryLabelTransformFn
      ? categoryLabels.map((label) => categoryLabelTransformFn(label, term))
      : categoryLabels;

   return (
      <Modal closeModal={closeModal}>
         <GeneralTemplate
            title={title(term)}
            urlAddon={term ? urlAddon(term, group) : ''}
            timespan={timespan}
            metric={metric}
            categoryLabels={determinedCategoryLabels}
            initialCategoryIdx={determinedInitialCategory}
            settings={settings[metric]}
         />
      </Modal>
   );
}

DefaultTemplate.propTypes = {
   group: PropTypes.string,
   term: PropTypes.any,
   timespan: PropTypes.object,
   closeModal: PropTypes.func,
   metric: PropTypes.string,
   settings: PropTypes.object,
};

export default DefaultTemplate;
