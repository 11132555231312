import React, { useState, useCallback } from 'react';
import Select from 'react-select';
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { getSelectOption, uniqueIdFactory } from '../../../util/UtilFunctions';
import { MatchSelectComponentColorStyles } from '../../../constants/styles';
import StreamNameChecker from './StreamNameChecker';
import { CenteredFlexContainer, StaticCol, Description, ExtButton } from '../../../styledComponents/styledComponents';

import PropTypes from 'prop-types';
import storePropTypes from '../../../redux/store/propTypes';
import styled from 'styled-components';

export const VariableWidthPopover = styled(UncontrolledPopover)`
   max-width: 500px !important;
`;
VariableWidthPopover.displayName = 'VariableWidthPopover';

function PopoverStreamNameFilter({ streamNames, setStreamNames }) {
   const [uid] = useState(uniqueIdFactory('_'));

   // handle remove selected stream / all streams (return null if no stream left over)
   function handleSelectChange(input) {
      const streamNames = input === null ? [] : input;
      setStreamNames(streamNames);
   }
   return (
      <>
         <CenteredFlexContainer vertical={'flex-end'}>
            <StaticCol width={270}>
               <Description>Filter for streams</Description>
               <Select
                  components={{
                     DropdownIndicator: null,
                  }}
                  styles={MatchSelectComponentColorStyles}
                  value={streamNames}
                  inputValue={''}
                  onChange={handleSelectChange}
                  menuIsOpen={false}
                  isClearable
                  isMulti
                  placeholder={'Click Add to open a dialog'}
                  isDisabled={streamNames === null || streamNames.length === 0}
               />
            </StaticCol>
            <ExtButton id={`Popover${uid}`} type='button' color='primary' className='ml-3' $paddingPx={'7.3px 0.75rem'}>
               Add
            </ExtButton>
            <VariableWidthPopover trigger='legacy' placement='bottom' target={`Popover${uid}`}>
               <PopoverHeader>{'Check & add streams'}</PopoverHeader>
               <PopoverBody>
                  <StaticCol width={285}>
                     <StreamNameChecker
                        streamNames={streamNames}
                        onStreamAdd={(streamName) => setStreamNames([...streamNames, getSelectOption(streamName)])}
                        clearSelection={useCallback(() => setStreamNames([]), [setStreamNames])}
                        SelectContainer={({ children }) => (
                           <StaticCol width={285} $bottomMarginPx={10}>
                              {children}
                           </StaticCol>
                        )}
                        ButtonContainer={({ children }) => <CenteredFlexContainer>{children}</CenteredFlexContainer>}
                        ButtonMarginRightPx={0}
                        ButtonWidthPx={150}
                     />
                  </StaticCol>
               </PopoverBody>
            </VariableWidthPopover>
         </CenteredFlexContainer>
      </>
   );
}

PopoverStreamNameFilter.propTypes = {
   streamNames: PropTypes.arrayOf(storePropTypes.stdSelectOption),
   setStreamNames: PropTypes.func,
};

export default PopoverStreamNameFilter;
