import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, InputGroupText } from 'reactstrap';
import styled from 'styled-components';
import { DefaultGrey } from '../constants/colors';

export const SetChartHeight = styled.div`
   height: ${(props) => props.height}px;
`;
SetChartHeight.displayName = 'SetChartHeight';

export const CenteredMsgShowing = styled.div`
   display: flex;
   position: relative;
   justify-content: center;
   align-items: center;
   height: ${(props) => props.height}px;
   cursor: default;
   font-size: ${(props) => (props.$fontSizePx !== undefined ? props.$fontSizePx : 14)}px;
   font-weight: bold;
   color: ${(props) => (props.$fontColor !== undefined ? props.$fontColor : 'rgb(80, 80, 80)')};
   background-color: ${(props) => (props.$bgColor !== undefined ? props.$bgColor : 'rgb(250, 250, 250)')};
   padding: 0px 20px 0px 20px;
   text-align: center;
   ${(props) => (props.marginTopPx !== undefined ? `margin-top: ${props.marginTopPx}px;` : '')}
   ${(props) => (props.marginBottomPx !== undefined ? `margin-bottom: ${props.marginBottomPx}px;` : '')}
`;
CenteredMsgShowing.displayName = 'CenteredMsgShowing';

export const ChartWrapper = styled.div`
   margin-top: ${(props) => (props.marginTopPx !== undefined ? props.marginTopPx : 40)}px;
   ${(props) => (props.paddingRightPx !== undefined ? `padding-right: ${props.paddingRightPx}px;` : '')}
   ${(props) => (props.height !== undefined ? `height: ${props.height}px;` : '')}
   ${(props) => (props.autoScroll === true ? 'overflow-y: auto; overflow-x: hidden;' : '')}
   position: relative;
   ${(props) => (props.$boxShadow !== undefined ? `box-shadow: ${props.$boxShadow};` : '')}
`;
ChartWrapper.displayName = 'ChartWrapper';

export const CenteredFlexContainer = styled.div`
   display: flex;
   flex-direction: ${(props) => (props.$direction !== undefined ? props.$direction : 'row')};
   justify-content: ${(props) => (props.horizontal !== undefined ? props.horizontal : 'center')};
   align-items: ${(props) => (props.vertical !== undefined ? props.vertical : 'center')};
   ${(props) => props.$width !== undefined && `width:${props.$width};`}
`;
CenteredFlexContainer.displayName = 'CenteredFlexContainer';

export const WorldmapErrorShowing = styled(CenteredMsgShowing)`
   position: absolute;
   top: 0px;
   left: 0px;
   background-color: ${(props) =>
      props.$alpha !== undefined ? `rgba(255, 255, 255, ${props.$alpha})` : 'rgba(255, 255, 255, 0.7)'};
   height: ${(props) => props.height}px;
   width: ${(props) => props.width}px;
`;
WorldmapErrorShowing.displayName = 'WorldmapErrorShowing';

export const TransparentMessage = styled(CenteredMsgShowing)`
   position: absolute;
   z-index: 1;
   top: 0px;
   left: 0px;
   background-color: rgba(255, 255, 255, ${(props) => props.opacity});
   height: ${(props) => props.height};
   width: ${(props) => props.width};
`;
TransparentMessage.displayName = 'TransparentMessage';

export const StyledButton = styled.button`
	outline: inherit !important;
   &:focus {
      outline: none !important;
      box-shadow: none !important;
   }
   ${(props) => props.$position !== undefined && `position: ${props.$position};`}
   font-size: ${(props) => props.fontSizePx}px;
   cursor: ${(props) => (props.$cursor !== undefined ? `${props.$cursor} !important` : 'pointer  !important')};
   text-align: center;
   width: ${(props) => props.widthPx}px;
   margin: ${(props) => (props.marginPx !== undefined ? props.marginPx : '0px')};
   background-color: ${(props) => (props.bgColor !== undefined ? props.bgColor : 'rgba(32, 168, 216, 1)')};
   ${(props) => (props.showClick === true && props.clickBgColor ? `background-color: ${props.clickBgColor};` : '')}
   color: ${(props) => (props.fontColor !== undefined ? props.fontColor : '#000')};
   ${(props) => props.border !== undefined && `border: ${props.border};`}
   ${(props) => props.fontWeight !== undefined && `font-weight: ${props.fontWeight};`}
   ${(props) => props.heightPx !== undefined && `line-height: ${props.heightPx}px;`}
   ${(props) => props.heightPx !== undefined && 'padding: 0px;'}
   ${(props) => props.$buttonHeightPx !== undefined && `height: ${props.$buttonHeightPx}px;`}
   ${(props) => props.$verticalAlign !== undefined && `vertical-align: ${props.$verticalAlign};`}
   &:hover {
      background-color: ${(props) =>
         props.bgColorHover !== undefined ? props.bgColorHover : 'rgba(32, 168, 216, 0.6)'};
      ${(props) => (props.showClick === true && props.clickBgColor ? `background-color: ${props.clickBgColor};` : '')}
      color: ${(props) => (props.fontColorHover !== undefined ? props.fontColorHover : '#000')};
   }
   transition: background-color 0.2s ease;
   transition: color 0.3s;
   ${(props) => props.$borderRadiusInPx !== undefined && `border-radius: ${props.$borderRadiusInPx}px;`}
`;
StyledButton.displayName = 'StyledButton';

export const ExtButton = styled(Button)`
   ${(props) => (props.$widthPx !== undefined ? `width: ${props.$widthPx}px;` : '')}
   ${(props) => props.$fontSizePx !== undefined && `font-size: ${props.$fontSizePx}px;`}
   ${(props) => (props.$bgColor !== undefined ? `background-color: ${props.$bgColor};` : '')}
   ${(props) => (props.$paddingPx !== undefined ? `padding: ${props.$paddingPx};` : '')}
   ${(props) => (props.$color !== undefined ? `color: ${props.$color};` : '')}
   ${(props) => (props.$border !== undefined ? `border: ${props.$border};` : '')}
   ${(props) => props.$lineHeight !== undefined && `line-height: ${props.$lineHeight};`}
   ${(props) => props.$xCentered !== undefined && 'margin-left: auto;margin-right: auto;display: block;'}
   &:hover {
      ${(props) =>
         props.$bgColorHover !== undefined && !props.disabled ? `background-color: ${props.$bgColorHover}` : ''};
      ${(props) => (props.$fontColorHover !== undefined && !props.disabled ? `color: ${props.$fontColorHover}` : '')};
   }
   
   transition: background-color 0.2s ease-in-out;
`;
ExtButton.displayName = 'ExtButton';

export const DroppedDownList = styled.div`
   position: absolute;
   display: ${(props) => (props.$isShown ? 'block' : 'none')};
   min-width: ${(props) => (props.$minWidthInPx ? props.$minWidthInPx : 80)}px;
   ${(props) => props.$leftInPx !== undefined && `left: ${props.$leftInPx}px;`}
   ${(props) => props.$topInPx !== undefined && `top: ${props.$topInPx}px;`}
`;
DroppedDownList.displayName = 'DroppedDownList';

export const DroppedDownListItem = styled.div`
   padding: 3px 12px;
   font-size: 11px;
   text-align: center;
   cursor: pointer;
`;
DroppedDownListItem.displayName = 'DroppedDownListItem';

export const CenterXY = styled.div`
   display: flex;
   height: 100%;
`;
CenterXY.displayName = 'CenterXY';

export const Caret = styled.div`
   /* width: 0px;
   height: 0px; */
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-top: 5px solid ${(props) => (props.$color !== undefined ? `${props.$color}` : '#000')};
   margin: auto auto;
`;
Caret.displayName = 'Caret';

export const RelativePositionDiv = styled.div`
   position: relative;
`;
RelativePositionDiv.displayName = 'RelativePositionDiv';

export const TwoSideRow = styled.div`
   display: flex;
   justify-content: space-between;   
   ${(props) => (!props.setNoWidth ? 'width: 100%;' : '')}
   margin-left: ${(props) => (props.marginLeftPx !== undefined ? props.marginLeftPx : 0)}px;
   margin-right: ${(props) => (props.marginRightPx !== undefined ? props.marginRightPx : 0)}px;
`;
TwoSideRow.displayName = 'TwoSideRow';

export const TooNarrowMessage = styled.div`
   margin-top: 20px;
`;
TooNarrowMessage.displayName = 'TooNarrowMessage';

export const InactiveGreyCard = styled.div`
   ${(props) => (props.$isActive === false ? 'background-color: rgba(255,255,255,0.75);' : '')}
   ${(props) => (props.$isActive === false ? 'z-index: 2;' : '')}
   ${(props) => (props.$isActive === false ? 'height: 100%;' : '')}
   ${(props) => (props.$isActive === false ? 'width: 100%;' : '')}
   ${(props) => (props.$isActive === false ? 'position: absolute;' : '')}
   ${(props) => (props.$isActive === false ? 'border-radius: 0.25rem;' : '')}
   
`;
InactiveGreyCard.displayName = 'InactiveGreyCard';

export const BottomMargin = styled.div`
   margin-bottom: ${(props) => (props.pxSize !== undefined ? props.pxSize : 5)}px;
`;
BottomMargin.displayName = 'BottomMargin';

export const SimpleHorizontalSeparator = styled.div`
   margin-bottom: ${(props) => props.spacePx - 1}px;
   height: ${(props) => props.spacePx}px;
   border-bottom: ${(props) => props.border};
`;
SimpleHorizontalSeparator.displayName = 'SimpleHorizontalSeparator';

export const VerticalSpacer = styled.div`
   margin-bottom: ${(props) => (props.mbPX !== undefined ? props.mbPX : 5)}px;
   margin-top: ${(props) => (props.mtPX !== undefined ? props.mtPX : 5)}px;
`;
VerticalSpacer.displayName = 'VerticalSpacer';

export const CustomCard = styled(Card)`
   margin: -6px -15px 10px -15px;
   border-radius: 7px;
   /* border-color: transparent; */
   ${(props) => (props.$pxBottomMargin !== undefined ? `margin-bottom: ${props.$pxBottomMargin}px;` : '')}
   ${(props) => (props.$bgColor ? `background-color: ${props.$bgColor};` : '')}
   ${(props) => (props.$minHeightPx ? `min-height: ${props.$minHeightPx}px;` : '')}
`;
CustomCard.displayName = 'CustomCard';

export const CustomCardBody = styled(CardBody)`
   position: relative;
   padding-left: 20px;
   padding-right: 20px;
   ${(props) => (props.$borderBottom ? `border-bottom: ${props.$borderBottom};` : '')}
`;
CustomCard.displayName = 'CustomCard';

export const WidgetContainer = styled(Card)`
   margin: ${(props) => (props.$margin !== undefined ? props.$margin : '0px -7px 15px -7px')};
   border-radius: 7px;
   ${(props) => (props.$pxBottomMargin !== undefined ? `margin-bottom: ${props.$pxBottomMargin}px;` : '')}
   ${(props) => (props.$bgColor ? `background-color: ${props.$bgColor};` : '')}
   ${(props) => (props.$minHeightPx ? `min-height: ${props.$minHeightPx}px;` : '')}
   box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;
`;
WidgetContainer.displayName = 'WidgetContainer';

export const WidgetHeaderContainer = styled(CardHeader)`
   background-color: ${(props) => (props.$bgColor !== undefined ? props.$bgColor : '#fff')};
   padding: 5px 20px;
   &:first-child {
      border-radius: 7px 7px 0 0;
   }
   z-index: 11;
`;
WidgetHeaderContainer.displayName = 'WidgetHeaderContainer';

export const HeightSetContainer = styled.div`
   min-height: ${(props) => props.$heightInPx}px;
   display: flex;
   flex-direction: column;
   justify-content: center;
`;
HeightSetContainer.displayName = 'HeightSetContainer';

export const WidgetBodyContainer = styled(CardBody)`
   position: relative;
   padding-left: 20px;
   padding-right: 20px;
   padding-top: 12px;
   padding-bottom: 10px;
   border-radius: 7px;
   ${(props) => (props.$borderBottom ? `border-bottom: ${props.$borderBottom};` : '')}
   ${(props) => (props.$minHeight ? `min-height: ${props.$minHeight};` : '')}
`;
WidgetBodyContainer.displayName = 'WidgetBodyContainer';

export const WidgetFooterContainer = styled(CardFooter)`
   background-color: ${(props) => (props.$bgColor !== undefined ? props.$bgColor : DefaultGrey(1, 247))};
   padding: ${(props) => (props.$padding !== undefined ? props.$padding : '5px 20px')};
`;
WidgetFooterContainer.displayName = 'WidgetFooterContainer';

export const InfoContainer = styled(Card)`
   width: 50%;
   min-width: 520px;
   max-width: 650px;
   margin: 30px auto 15px auto;
   padding: 10px 20px;
   display: flex;
   flex-direction: row;
`;
InfoContainer.displayName = 'InfoContainer';

export const SymbolContainer = styled.div`
   /* width: 200px; */
   margin-right: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 20px;
   color: #ff933f;
`;
SymbolContainer.displayName = 'SymbolContainer';

export const TextColumn = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   font-size: 13px;
   font-weight: 600;
`;
TextColumn.displayName = 'TextColumn';

export const TextRow = styled.div`
   /* width: 200px; */
   margin-bottom: ${(props) => (props.marginBottomPx !== undefined ? props.marginBottomPx : 0)}px;
   text-align: left;
`;
TextRow.displayName = 'TextRow';

export const FormattedLink = styled.a`
   font-size: 13px;
   font-weight: 600;
   color: #000;
   &:hover {
      color: #ff933f;
   }
`;
FormattedLink.displayName = 'FormattedLink';

export const FontStyle = styled.span`
   ${(props) => props.$fontWeight !== undefined && `font-weight: ${props.$fontWeight}`};
   ${(props) => props.$fontSizePx !== undefined && `font-size: ${props.$fontSizePx}px;`}
   ${(props) => props.$color !== undefined && `color: ${props.$color};`}
   ${(props) => props.$textDecoration !== undefined && `text-decoration: ${props.$textDecoration};`}
`;
FontStyle.displayName = 'FontStyle';

export const ButtonBarContainer = styled.div`
   text-align: center;
`;
ButtonBarContainer.displayName = 'ButtonBarContainer';

export const TitleBar = styled.div`
   font-size: 16px;
   font-weight: 500;
   margin-bottom: 14px;
   color: ${(props) => (props.$alpha !== undefined ? `rgba(0, 0, 0, ${props.$alpha})` : 'rgba(0, 0, 0, 1)')};
`;
TitleBar.displayName = 'TitleBar';

export const Description = styled.div`
   font-weight: ${(props) => (props.fontWeight !== undefined ? props.fontWeight : 500)};
   font-size: ${(props) => (props.fontSizePX !== undefined ? props.fontSizePX : 12)}px;
   margin-top: ${(props) => (props.marginTopPx !== undefined ? props.marginTopPx : 0)}px;
   margin-bottom: ${(props) => (props.marginBottomPx !== undefined ? props.marginBottomPx : 2)}px;
   margin-left: ${(props) => (props.marginLeftPx !== undefined ? props.marginLeftPx : 3)}px;
   color: ${(props) => (props.alpha !== undefined ? `rgba(0, 0, 0, ${props.alpha})` : 'rgba(0, 0, 0, 0.6)')};
   cursor: ${(props) => (props.cursor !== undefined ? props.cursor : 'auto')};
   ${(props) => props.$xCentered !== undefined && 'margin-left: 0px;text-align: center;'}
`;
Description.displayName = 'Description';

export const DescriptionSpan = styled.span`
   font-weight: ${(props) => (props.fontWeight !== undefined ? props.fontWeight : 500)};
   font-size: ${(props) => (props.fontSizePX !== undefined ? props.fontSizePX : 12)}px;
   margin-top: ${(props) => (props.marginTopPx !== undefined ? props.marginTopPx : 0)}px;
   margin-bottom: ${(props) => (props.marginBottomPx !== undefined ? props.marginBottomPx : 2)}px;
   margin-left: ${(props) => (props.marginLeftPx !== undefined ? props.marginLeftPx : 3)}px;
   color: ${(props) => (props.alpha !== undefined ? `rgba(0, 0, 0, ${props.alpha})` : 'rgba(0, 0, 0, 0.6)')};
   cursor: ${(props) => (props.cursor !== undefined ? props.cursor : 'auto')};
`;
DescriptionSpan.displayName = 'DescriptionSpan';

export const SwitchDescription = styled.span`
   font-weight: ${(props) => (props.fontWeight !== undefined ? props.fontWeight : 500)};
   font-size: ${(props) => (props.fontSizePX !== undefined ? props.fontSizePX : 12)}px;
   margin-top: ${(props) => (props.marginTopPx !== undefined ? props.marginTopPx : 0)}px;
   margin-bottom: ${(props) => (props.marginBottomPx !== undefined ? props.marginBottomPx : 0)}px;
   margin-left: ${(props) => (props.marginLeftPx !== undefined ? props.marginLeftPx : 0)}px;
   color: ${(props) => (props.greyShade !== undefined ? DefaultGrey(1, props.greyShade) : DefaultGrey(1, 160))};
   cursor: ${(props) => (props.cursor !== undefined ? props.cursor : 'auto')};
`;
SwitchDescription.displayName = 'SwitchDescription';

export const ValueText = styled.div`
   color: black;
`;
ValueText.displayName = 'ValueText';

export const VerticalSeparator = styled.span`
   margin: 0 8px;
   color: #999;
`;
VerticalSeparator.displayName = 'VerticalSeparator';

export const SimpleFlexRow = styled.div`
   display: flex;
   flex-wrap: wrap;
   align-items: ${(props) => (props.$contentVerticalAlign !== undefined ? props.$contentVerticalAlign : 'center')};
`;
SimpleFlexRow.displayName = 'SimpleFlexRow';

export const SimpleFlexItem = styled.div`
   display: flex;
   ${(props) => props.$position !== undefined && `position: ${props.$position};`}
   ${(props) => props.$marginLeftInPx !== undefined && `margin-left: ${props.$marginLeftInPx}px;`}
`;
SimpleFlexItem.displayName = 'SimpleFlexItem';

export const HorizontalSeparatorPart = styled.div`
   height: ${(props) => (props.$height !== undefined ? props.$height : 20)}px;
   width: ${(props) => (props.$marginX_InPx !== undefined ? props.$marginX_InPx : 3)}px;
   border: none;
   border-left: ${(props) => (props.$leftBorder !== undefined ? props.$leftBorder : '0px solid #FFF')};
   border-right: ${(props) => (props.$rightBorder !== undefined ? props.$rightBorder : '0px solid #FFF')};
   margin-top: ${(props) => (props.$marginTopInPx !== undefined ? props.$marginTopInPx : 0)}px;
`;
HorizontalSeparatorPart.displayName = 'HorizontalSeparatorPart';

export const FlexRow = styled.div`
   display: flex;
   align-items: center;
`;
FlexRow.displayName = 'FlexRow';

export const StaticCol = styled.div`
   width: ${(props) => props.width}px;
   ${(props) => (props.$bottomMarginPx !== undefined ? `margin-bottom: ${props.$bottomMarginPx}px;` : '')}
`;
StaticCol.displayName = 'StaticCol';

export const Notification = styled.div`
   margin-top: ${(props) => (props.topMarginPx !== undefined ? props.topMarginPx : 4)}px;
   margin-left: ${(props) => (props.indentPx !== undefined ? props.indentPx : 3)}px;
   color: ${(props) => (props.$color !== undefined ? props.$color : 'rgba(115, 0, 0, 0.9)')};
   font-size: ${(props) => (props.$fontSizePx !== undefined ? props.$fontSizePx : 12)}px;
   font-weight: ${(props) => (props.$fontWeight !== undefined ? props.$fontWeight : 400)};
   ${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : '')}
`;
Notification.displayName = 'Notification';

export const ShowValueElement = styled.span`
   font-weight: 600;
   cursor: default;
`;
ShowValueElement.displayName = 'ShowValueElement';

export const CenterContainer = styled.div`
   text-align: center;
   margin-top: ${(props) => (props.marginTopPx !== undefined ? props.marginTopPx : 0)}px;
`;
CenterContainer.displayName = 'CenterContainer';

export const HintInfo = styled.div`
   font-family: sans-serif;
   font-size: 12px;
   margin-top: 5px;
   margin-bottom: 5px;
   font-weight: lighter;
   color: #606367;
`;
HintInfo.displayName = 'HintInfo';

export const DefaultInfo = styled.div`
   font-family: sans-serif;
   margin-top: 10px;
   margin-bottom: 10px;
   color: #555;
   font-weight: 500;
`;
DefaultInfo.displayName = 'DefaultInfo';

export const FontWeightedText = styled.div`
   font-weight: ${(props) => (props.$fontWeight !== undefined ? props.$fontWeight : 400)};
`;
FontWeightedText.displayName = 'FontWeightedText';

export const BadgeListItem = styled(Badge)`
   display: inline-block;

   cursor: ${(props) => (props.$cursor !== undefined ? props.$cursor : 'pointer')};
   font-weight: ${(props) => (props.$fontWeight !== undefined ? props.$fontWeight : 450)};
   font-size: ${(props) => (props.$fontSizePx !== undefined ? props.$fontSizePx : 12)}px;
   margin-top: ${(props) => (props.$marginTopPx !== undefined ? props.$marginTopPx : 0)}px;
   margin-bottom: ${(props) => (props.$marginBottomPx !== undefined ? props.$marginBottomPx : 2)}px;
   margin-right: ${(props) => (props.$marginRightPx !== undefined ? props.$marginRightPx : 10)}px;
   margin-left: ${(props) => (props.$marginLeftPx !== undefined ? props.$marginLeftPx : 0)}px;
   color: ${(props) => (props.$color !== undefined ? props.$color : 'rgba(0, 0, 0, 0.9)')};
   background-color: ${(props) => (props.$bgColor !== undefined ? props.$bgColor : 'rgba(225, 225, 225, 1)')};
   ${(props) => (props.$verticalAlign !== undefined ? `vertical-align: ${props.$verticalAlign};` : '')}
   ${(props) => (props.$lineHeight !== undefined ? `line-height: ${props.$lineHeight};` : '')}
   ${(props) => (props.$heightPx !== undefined ? `height: ${props.$heightPx}px;` : '')}
   ${(props) => props.$border !== undefined && `border: ${props.$border};`}

`;
BadgeListItem.displayName = 'BadgeListItem';

export const CertainWidthContainer = styled.div`
   width: ${(props) => (props.$width !== undefined ? props.$width : '100%')};
`;
CertainWidthContainer.displayName = 'CertainWidthContainer';

export const MaxWidthContainer = styled.div`
   width: 100%;
   max-width: ${(props) => (props.$maxWidthPx !== undefined ? props.$maxWidthPx : 500)}px;
`;
MaxWidthContainer.displayName = 'MaxWidthContainer';

export const BorderedCol = styled(Col)`
   ${(props) => (props.$rightBorder !== undefined ? 'border-right: 1px solid rgb(220,220,220);' : '')}
   ${(props) => (props.$leftBorder !== undefined ? 'border-left: 1px solid rgb(220,220,220);' : '')}
   ${(props) => (props.$topBorder !== undefined ? 'border-top: 1px solid rgb(220,220,220);' : '')}
   ${(props) => (props.$bottomBorder !== undefined ? 'border-bottom: 1px solid rgb(220,220,220);' : '')}
   ${(props) => (props.$removeLeftPadding !== undefined ? 'padding-left: 0px;' : '')}
   ${(props) => (props.$removeRightPadding !== undefined ? 'padding-right: 0px;' : '')}
   ${(props) => (props.$minWidthPx !== undefined ? `min-height: ${props.$minWidthPx}px;` : '')}
`;
BorderedCol.displayName = 'BorderedCol';

export const AbsoluteContainer = styled.div`
   position: absolute;
   width: ${(props) => (props.$width !== undefined ? props.$width : '100%')};
   ${(props) => (props.$rightPx !== undefined ? `right: ${props.$rightPx}px;` : '')}
      ${(props) => (props.$leftPx !== undefined ? `left: ${props.$leftPx}px;` : '')}
      ${(props) => (props.$topPx !== undefined ? `top: ${props.$topPx}px;` : '')}
      ${(props) => (props.$bottomPx !== undefined ? `bottom: ${props.$bottomPx}px;` : '')}
`;
AbsoluteContainer.displayName = 'AbsoluteContainer';

export const StandardContainer = styled.div`
   padding: ${(props) => (props.$paddingPx !== undefined ? props.$paddingPx : '15')}px;
   ${(props) => (props.$paddingRightPx !== undefined ? `padding-right: ${props.$paddingRightPx}px;` : '')}
   ${(props) => (props.$paddingLeftPx !== undefined ? `padding-left: ${props.$paddingLeftPx}px;` : '')}
   ${(props) => (props.$paddingTopPx !== undefined ? `padding-top: ${props.$paddingTopPx}px;` : '')}
   ${(props) => (props.$paddingBottomPx !== undefined ? `padding-bottom: ${props.$paddingBottomPx}px;` : '')}
   border-bottom-left-radius: ${(props) => (props.$borderRadiusBLpx !== undefined ? props.$borderRadiusBLpx : '0')}px;
   border-bottom-right-radius: ${(props) => (props.$borderRadiusBRpx !== undefined ? props.$borderRadiusBRpx : '0')}px;
   border-top-left-radius: ${(props) => (props.$borderRadiusTLpx !== undefined ? props.$borderRadiusTLpx : '0')}px;
   border-top-right-radius: ${(props) => (props.$borderRadiusTRpx !== undefined ? props.$borderRadiusTRpx : '0')}px;
   background-color: #fff;
   position: relative;
`;
StandardContainer.displayName = 'StandardContainer';

export const ShowOnRightSide = styled.div`
   padding-left: 70%;
`;
ShowOnRightSide.displayName = 'ShowOnRightSide';

export const ShowOnRightSideWithStaticWidth = styled.div`
   float: right;
   width: ${(props) => (props.$widthPx !== undefined ? props.$widthPx : '100')}px;
`;
ShowOnRightSideWithStaticWidth.displayName = 'ShowOnRightSideWithStaticWidth';

export const LightHeadline = styled.div`
   color: rgba(0, 0, 0, 0.6);
   font-weight: 500;
   ${(props) => (props.$fontSizePx ? `font-size: ${props.$fontSizePx}px;` : '')}
   margin-bottom: ${(props) => (props.marginBottomPx !== undefined ? props.marginBottomPx : 15)}px;
`;
LightHeadline.displayName = 'LightHeadline';

export const StartHeadline = styled.span`
   color: rgba(0, 0, 0, 0.6);
   font-weight: 500;
   margin-bottom: ${(props) => (props.marginBottomPx !== undefined ? props.marginBottomPx : 15)}px;
   margin-right: ${(props) => (props.marginRightPx !== undefined ? props.marginRightPx : 15)}px;
   font-size: ${(props) => (props.$fontSizePx !== undefined ? props.$fontSizePx : 14)}px;
`;
StartHeadline.displayName = 'StartHeadline';

export const DescriptionStart = styled.span`
   width: ${(props) => (props.$widthPx !== undefined ? props.$widthPx : '100')}px;
   font-weight: 600;
   display: inline-block;
`;
DescriptionStart.displayName = 'DescriptionStart';

export const CustomInputGroupText = styled(InputGroupText)`
   margin-right: -1px;
   border-top-right-radius: 0px;
   border-bottom-right-radius: 0px;
`;
CustomInputGroupText.displayName = 'CustomInputGroupText';

export const RightSide = styled.div`
   text-align: right;
   min-width: ${(props) => props.minWidthPx}px;
`;
RightSide.displayName = 'RightSide';

export const ColoredText = styled.div`
   color: ${(props) => props.color};
`;
ColoredText.displayName = 'ColoredText';

export const DescriptionLink = styled.a`
   font-weight: ${(props) => (props.fontWeight !== undefined ? props.fontWeight : 500)};
   font-size: ${(props) => (props.fontSizePX !== undefined ? props.fontSizePX : 12)}px;
   margin-top: ${(props) => (props.marginTopPx !== undefined ? props.marginTopPx : 0)}px;
   margin-bottom: ${(props) => (props.marginBottomPx !== undefined ? props.marginBottomPx : 2)}px;
   margin-left: ${(props) => (props.marginLeftPx !== undefined ? props.marginLeftPx : 3)}px;
   color: ${(props) => (props.$color !== undefined ? props.$color : 'rgba(0, 0, 0, 0.6)')};
   cursor: ${(props) => (props.cursor !== undefined ? props.cursor : 'auto')};
   &:hover {
      color: ${(props) => (props.$color !== undefined ? props.$color : 'rgba(0, 0, 0, 0.6)')};
      text-decoration: none;
   }
`;
DescriptionLink.displayName = 'DescriptionLink';

export const RemoveBottomMargin = styled.div`
   margin-bottom: ${(props) => (props.$marginBottomPx !== undefined ? props.$marginBottomPx : -15)}px;
`;
RemoveBottomMargin.displayName = 'RemoveBottomMargin';

export const BorderedCardBody = styled(CardBody)`
   ${(props) => (props.$hasBorder ? 'box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.42);' : '')}
   ${(props) =>
      props.$showBackground ? 'background-color: rgba(0,0,0,0.02);' : 'background-color: rgba(255,255,255);'}
   ${(props) => (props.$minHeightPx ? `min-height: ${props.$minHeightPx}px;` : '')}
   ${(props) => (props.$swapPaddingToMargin ? 'padding: 0rem 0rem;' : '')}
   ${(props) => (props.$swapPaddingToMargin ? 'margin: 3rem 1.25rem 0rem 1.25rem;' : '')}
   ${(props) => (props.$position !== undefined ? `position: ${props.$position};` : '')}
`;
BorderedCardBody.displayName = 'BorderedCardBody';

export const MinHeightContainer = styled.div`
   min-height: ${(props) => (props.$minHeightInPx !== undefined ? props.$minHeightInPx : 200)}px;
   ${(props) => (props.$position !== undefined ? `position: ${props.$position};` : '')}
`;
MinHeightContainer.displayName = 'MinHeightContainer';

export const TwoSideFlexContainer = styled.div`
   display: flex;
   justify-content: space-between;
`;
TwoSideFlexContainer.displayName = 'TwoSideFlexContainer';

export const AlignedIconContainer = styled.div`
   font-size: ${(props) => (props.$fontSizePx !== undefined ? props.$fontSizePx : 16)}px;
   margin-top: ${(props) => (props.$marginTopPx !== undefined ? props.$marginTopPx : -8)}px;
   height: ${(props) => (props.$heightPx !== undefined ? props.$heightPx : 16)}px;
`;
TwoSideFlexContainer.displayName = 'TwoSideFlexContainer';

export const PaddingContainer = styled.div`
   padding: ${(props) => (props.$padding !== undefined ? props.$padding : '0px 0px')};
`;
PaddingContainer.displayName = 'PaddingContainer';

export const FixedWidthContainer = styled.div`
   width: ${(props) => (props.$width !== undefined ? props.$width : '100px')};
`;
FixedWidthContainer.displayName = 'FixedWidthContainer';
