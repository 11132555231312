import styled from 'styled-components';

export const TileContainer = styled.div`
   display: flex;
   justify-content: space-around;
   flex-wrap: wrap;
   padding: ${(props) => (props.gapPx !== undefined ? `0px 0px 0px ${props.gapPx}px` : '0px 0px 0px 25px')};
   ${(props) => (props.isFullScreen ? '@media (min-width: 1500px) { width: 1150px; margin: 0px auto;}' : '')}
`;
TileContainer.displayName = 'TileContainer';

export const CarouselContentContainer = styled.div`
   padding-top: ${(props) => (props.pTopPx !== undefined ? `${props.pTopPx}px` : '20px')};
   padding-bottom: ${(props) => (props.pBottomPx !== undefined ? `${props.pBottomPx}px` : '20px')};
   padding-left: ${(props) => (props.pLeftPx !== undefined ? `${props.pLeftPx}px` : '20px')};
   padding-right: ${(props) => (props.pRightPx !== undefined ? `${props.pRightPx}px` : '20px')};
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
`;
CarouselContentContainer.displayName = 'CarouselContentContainer';

export const DetailTile = styled.div`
   background-color: rgba(0, 0, 0, 0.05);
   border-radius: 5px;
   width: 200px;
   height: 50px;
   padding: 0px 10px;
   display: flex;
   justify-content: center;
   flex-direction: column;
   margin: ${(props) =>
      props.gapPx !== undefined ? `0px ${props.gapPx}px ${props.gapPx}px 0px` : '0px 25px 25px 0px'};
`;
DetailTile.displayName = 'DetailTile';

export const Panel = styled.div`
   background-color: white;
   flex: 1 1 0;
`;
Panel.displayName = 'Panel';

export const IngestPlayoutContainer = styled.div`
   background-color: white;
   display: flex;
   padding-top: 5px;
   padding-bottom: 5px;
`;
IngestPlayoutContainer.displayName = 'IngestPlayoutContainer';

export const PanelContainer = styled.div`
   background-color: white;
   display: inline-block;
   width: 100%;
`;
PanelContainer.displayName = 'PanelContainer';

export const PlayoutContainer = styled(PanelContainer)`
   width: calc(50% - 2px);
   border-right: 1px solid rgb(220, 220, 220);
   margin-right: 1px;
`;
PlayoutContainer.displayName = 'PlayoutContainer';

export const IngestContainer = styled(PanelContainer)`
   width: calc(50% - 2px);
   border-left: 1px solid rgb(220, 220, 220);
   margin-left: 1px;
`;
IngestContainer.displayName = 'IngestContainer';

export const TimeSliderContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100%;
   margin: 5px 0px;
`;
TimeSliderContainer.displayName = 'TimeSliderContainer';

export const IntervalInfo = styled.div`
   display: inline-block;
   font-family: sans-serif;
   color: rgb(100, 100, 100);
   font-size: 12px;
   margin-bottom: 12px;
   font-weight: 600;

   & span:first-child {
      margin-right: 3px;
      font-weight: lighter;
      color: #606367;
   }
   & span:last-child {
      &::before {
         content: '–';
         margin: 0 4px;
      }
   }
`;
IntervalInfo.displayName = 'IntervalInfo';

export const DateInput = styled.input`
   display: inline-block;
   font-family: sans-serif;
   font-weight: 600;
   font-size: 12px;
   color: #606367;
   width: 145px;
   height: 25px;
   padding: 5px 6px;
   ${(props) =>
      props.hasError
         ? 'color: rgba(255, 0, 0, 0.7); border-color: rgba(255, 0, 0, 0.7); box-shadow: 0px 0px 0px 3px rgba(255, 0, 0, 0.3);transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;'
         : ''}
`;
DateInput.displayName = 'DateInput';

export const MetricsContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
   padding-top: 25px;
   margin-right: -2px;
   margin-left: -2px;
`;
MetricsContainer.displayName = 'MetricsContainer';

const twoMetricsPerLine = '{max-width: 50%; flex: 0 0 50%;}';
const threeMetricsPerLine = '{max-width: 33.33%; flex: 0 0 33.33%;}';

export const MetricItem = styled.div`
   max-width: 100%;
   flex: 0 0 100%;
   padding-right: 2px;
   padding-left: 2px;
   ${(props) =>
      props.isFullScreen
         ? `@media (min-width: 1250px) ${twoMetricsPerLine}`
         : `@media (min-width: 1800px) ${twoMetricsPerLine}`}
   ${(props) => (props.isFullScreen ? `@media (min-width: 2000px) ${threeMetricsPerLine}` : '')}
`;
MetricItem.displayName = 'MetricItem';
