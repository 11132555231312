import React from 'react';
import { DescriptionStart, StandardContainer, TitleBar } from '../../../styledComponents/styledComponents';

const descriptionIndentPx = 150;

function TableDescription() {
   return (
      <StandardContainer $paddingTopPx={0}>
         <TitleBar $alpha={0.6}>Table columns descriptions</TitleBar>
         <div>
            <DescriptionStart $widthPx={descriptionIndentPx}>Duration/Sum</DescriptionStart>
            <span>Total playback duration in seconds related to the IP/stream</span>
         </div>
         <div>
            <DescriptionStart $widthPx={descriptionIndentPx}>Duration/Avg</DescriptionStart>
            <span>Average playback duration in seconds related to the IP/stream</span>
         </div>
         <div>
            <DescriptionStart $widthPx={descriptionIndentPx}>Sessions</DescriptionStart>
            <span>Total session count related to the IP/stream</span>
         </div>
         <div>
            <DescriptionStart $widthPx={descriptionIndentPx}>Streams</DescriptionStart>
            <span>Total stream count related to the IP/stream</span>
         </div>
         <div>
            <DescriptionStart $widthPx={descriptionIndentPx}>Tokens</DescriptionStart>
            <span>Total token count related to the IP/stream</span>
         </div>
         <div>
            <DescriptionStart $widthPx={descriptionIndentPx}>Provider</DescriptionStart>
            <span>Internet service provider</span>
         </div>
         <div>
            <DescriptionStart $widthPx={descriptionIndentPx}>Country</DescriptionStart>
            <span>Country the IP is used from</span>
         </div>
         <div>
            <DescriptionStart $widthPx={descriptionIndentPx}>First stream start</DescriptionStart>
            <span>Start of the first stream related to the IP/stream</span>
         </div>
         <div>
            <DescriptionStart $widthPx={descriptionIndentPx}>Last stream end</DescriptionStart>
            <span>
               {
                  'End of the last stream related to the IP/stream (maybe it is the same as "First stream start", in this case only the start or end event of a stream was found)'
               }
            </span>
         </div>
         <div>
            <DescriptionStart $widthPx={descriptionIndentPx}>Tags</DescriptionStart>
            <span>Total STS (Secure Token Service) tag count related to the IP/stream</span>
         </div>
         <div>
            <DescriptionStart $widthPx={descriptionIndentPx}>Concurrency</DescriptionStart>
            <span>
               Metric to measure the concurrency of playback (Calculation: sum of total playback duration / (end of the
               last playback - start of the first playback))
            </span>
         </div>
         <div>
            <DescriptionStart $widthPx={descriptionIndentPx}>Blocked</DescriptionStart>
            <span>Is IP blocked</span>
         </div>
      </StandardContainer>
   );
}

export default TableDescription;
