import { H5LIVE_VIEW } from '../constants/general';

function getHttpHeader(filter) {
   const { hash } = filter;
   let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
   let token = userInfo.token;
   let headerObj = {
      'x-access-token': token,
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
   };

   if (hash !== '') {
      headerObj['x-orga-hash'] = hash;
   }

   return headerObj;
}

function getFilterQueryParams(filter, viewPage) {
   const { countries, tags, events, streamNames } = filter;
   let queryParamUrlStr = '';

   if (countries.length > 0) {
      queryParamUrlStr += countries.reduce((str, option) => (str += `&countries=${option.value}`), '');
   }
   if (tags.length > 0) {
      queryParamUrlStr += tags.reduce((str, option) => (str += `&tags=${option.value}`), '');
   }
   if (events.length > 0 && viewPage !== undefined && viewPage === H5LIVE_VIEW) {
      queryParamUrlStr += events.reduce((str, option) => (str += `&events=${option.value}`), '');
   }
   if (streamNames.length > 0) {
      queryParamUrlStr += streamNames.reduce((str, option) => (str += `&streams=${option.value}`), '');
   }

   return queryParamUrlStr;
}

function setBarChartLabels(updatedChartObj, labels, timespan, weekType = 'isoWeek', timespansPerIndex = []) {
   const startOf = timespan.interval === 'week' ? weekType : timespan.interval;
   const lower = timespan.gte.clone().startOf(startOf);
   const upper = timespan.lt.clone().startOf(startOf);

   // isLastTimeIntervalFull answers whether the last interval is full
   // e.g. for full interval: end datetime is 12/24/2020 00:00 at time interval 'day'
   const isLastTimeIntervalFull = timespan.lt.isSame(upper, 'minute');
   const size = isLastTimeIntervalFull
      ? upper.diff(lower, timespan.interval)
      : upper.diff(lower, timespan.interval) + 1;

   for (let i = 0; i < size; i++) {
      updatedChartObj.labels[i] = lower.format(timespan.format);
      const start = lower.clone();
      const end = lower.add(1, timespan.interval).clone();
      labels[i] = {
         start: start.valueOf(),
         end: end.valueOf(),
      };
      timespansPerIndex.push({ start, end });
   }
}

function getBarChartLabels(timespan) {
   const chartLabels = [];
   const processingLabels = [];
   const timespansPerIndex = [];

   const startOf = timespan.interval === 'week' ? 'isoWeek' : timespan.interval;
   const lower = timespan.gte.clone().startOf(startOf);
   const upper = timespan.lt.clone().startOf(startOf);

   // isLastTimeIntervalFull answers whether the last interval is full
   // e.g. for full interval: end datetime is 12/24/2020 00:00 at time interval 'day'
   const isLastTimeIntervalFull = timespan.lt.isSame(upper, 'minute');
   const size = isLastTimeIntervalFull
      ? upper.diff(lower, timespan.interval)
      : upper.diff(lower, timespan.interval) + 1;

   for (let i = 0; i < size; i++) {
      chartLabels[i] = lower.format(timespan.format);
      const start = lower.clone();
      const end = lower.add(1, timespan.interval).clone();
      processingLabels[i] = {
         start: start.valueOf(),
         end: end.valueOf(),
      };
      timespansPerIndex.push({ start, end });
   }

   return { chartLabels, processingLabels, timespansPerIndex };
}

function setLineChartLabels(updatedChartObj, labels, timespan, weekType = 'isoWeek', timespansPerIndex = []) {
   const startOf = timespan.interval === 'week' ? weekType : timespan.interval;
   const lower = timespan.gte.clone().startOf(startOf);
   const upper = timespan.lt.clone().startOf(startOf);

   // isLastTimeIntervalFull answers whether the last interval is full
   // e.g. for full interval: end datetime is 12/24/2020 00:00 at time interval 'day'
   const isLastTimeIntervalFull = timespan.lt.isSame(upper, 'minute');
   const size = isLastTimeIntervalFull
      ? upper.diff(lower, timespan.interval)
      : upper.diff(lower, timespan.interval) + 1;

   if (size === 1) {
      updatedChartObj.labels[0] = lower.format(timespan.format);
      updatedChartObj.labels[1] = lower.format(timespan.format);
      const startMoment = lower.clone();
      const endMoment = lower.clone().add(1, timespan.interval);
      labels[0] = { start: startMoment.valueOf(), end: endMoment.valueOf() };
      timespansPerIndex.push({ start: startMoment, end: endMoment });
      labels[1] = { start: startMoment.valueOf(), end: endMoment.valueOf() };
      timespansPerIndex.push({ start: startMoment, end: endMoment });
   } else {
      for (let i = 0; i < size; i++) {
         updatedChartObj.labels[i] = lower.format(timespan.format);
         const start = lower.clone();
         const end = lower.add(1, timespan.interval).clone();
         labels[i] = {
            start: start.valueOf(),
            end: end.valueOf(),
         };
         timespansPerIndex.push({ start, end });
      }
   }
}

function setBytesPlaytimeChartLabels(updatedChartObj, labels, timespansPerIndex, timespan, weekType = 'isoWeek') {
   const startOf = timespan.interval === 'week' ? weekType : timespan.interval;
   const lower = timespan.gte.clone().startOf(startOf);
   const upper = timespan.lt.clone().startOf(startOf);

   // isLastTimeIntervalFull answers whether the last interval is full
   // e.g. for full interval: end datetime is 12/24/2020 00:00 at time interval 'day'
   const isLastTimeIntervalFull = timespan.lt.isSame(upper, 'minute');
   let size = isLastTimeIntervalFull ? upper.diff(lower, timespan.interval) : upper.diff(lower, timespan.interval) + 1;

   for (let i = 0; i < size; i++) {
      updatedChartObj.bytes.chart.labels[i] = lower.format(timespan.format);
      updatedChartObj.playtime.chart.labels[i] = lower.format(timespan.format);
      const start = lower.clone();
      const end = lower.add(1, timespan.interval).clone();
      labels[i] = {
         start: start.unix(),
         end: end.unix(),
      };
      timespansPerIndex.push({ start, end });
   }
}

export default {
   getHttpHeader,
   getFilterQueryParams,
   setBarChartLabels,
   getBarChartLabels,
   setLineChartLabels,
   setBytesPlaytimeChartLabels,
};
