import React, { useState } from 'react';
import { CustomInput, Row } from 'reactstrap';

import {
   CustomCardBody,
   BorderedCol,
   CenterContainer,
   ExtButton,
   AbsoluteContainer,
} from '../../../styledComponents/styledComponents';

import BlockSpinner from '../../common/BlockSpinner/BlockSpinner';
import StsTagFilter from '../../common/StsTagFilter/StsTagFilter';

import { PopoverStreamNameFilter } from '../../common';

import { PropTypes } from 'prop-types';
import storePropTypes from '../../../redux/store/propTypes';
import momentPropTypes from 'react-moment-proptypes';
import DateTimePicker from '../../common/DateTimePicker/DateTimePicker';
import {
   WidthSensitiveBorderedCol,
   WidthSensitiveBottomMargin,
   WidthSensitiveDisplayed,
   WidthSensitiveRow,
} from './IpFilteringStyles';
import { MAX_DURATION_PER_VIEW } from '../../../constants/timeConstraints';
import { GUARDIAN_VIEW } from '../../../constants/general';

const VIEW_WIDTH_BREAKPOINT_IN_PX = 1610;

function MainInputs({
   streamNames,
   setStreamNames,
   start,
   setStart,
   end,
   setEnd,
   tags,
   setTags,
   requestTrigger,
   activateTriggerRequest,
   isLoading,
   setConcurrencyState,
}) {
   const [concurrencyIsDesiredLocal, setLocalConcurrencyState] = useState(true);
   const [datePickerHasError, setHasError] = useState(false);

   return (
      <CustomCardBody $borderBottom={'1px solid rgb(220,220,220)'}>
         <WidthSensitiveDisplayed $breakPointWidthPx={VIEW_WIDTH_BREAKPOINT_IN_PX}>
            <AbsoluteContainer $topPx={10} $leftPx={0}>
               <Row>
                  <BorderedCol $minWidthPx={88}></BorderedCol>
                  <BorderedCol $minWidthPx={88} $rightBorder={true} $leftBorder={true}></BorderedCol>
                  <BorderedCol $minWidthPx={88}></BorderedCol>
               </Row>
            </AbsoluteContainer>
         </WidthSensitiveDisplayed>
         <WidthSensitiveRow $breakPointWidthPx={VIEW_WIDTH_BREAKPOINT_IN_PX}>
            <WidthSensitiveBorderedCol $removeLeftPadding={true} $breakPointWidthPx={VIEW_WIDTH_BREAKPOINT_IN_PX}>
               <WidthSensitiveBottomMargin pxSize={3} $breakPointWidthPx={VIEW_WIDTH_BREAKPOINT_IN_PX}>
                  <DateTimePicker
                     startDate={start}
                     endDate={end}
                     onValidDateChange={({ gte, lt }) => {
                        setStart(gte);
                        setEnd(lt);
                     }}
                     maxInputTimeRange={MAX_DURATION_PER_VIEW[GUARDIAN_VIEW]}
                     setHasError={setHasError}
                  />
               </WidthSensitiveBottomMargin>
            </WidthSensitiveBorderedCol>
            <WidthSensitiveBorderedCol $breakPointWidthPx={VIEW_WIDTH_BREAKPOINT_IN_PX} $order={-1}>
               <WidthSensitiveBottomMargin pxSize={10} $breakPointWidthPx={VIEW_WIDTH_BREAKPOINT_IN_PX}>
                  <PopoverStreamNameFilter setStreamNames={setStreamNames} streamNames={streamNames} />
               </WidthSensitiveBottomMargin>
            </WidthSensitiveBorderedCol>
            <WidthSensitiveBorderedCol $removeRightPadding={true} $breakPointWidthPx={VIEW_WIDTH_BREAKPOINT_IN_PX}>
               <WidthSensitiveBottomMargin pxSize={10} $breakPointWidthPx={VIEW_WIDTH_BREAKPOINT_IN_PX}>
                  <StsTagFilter tags={tags} setTags={setTags} />
               </WidthSensitiveBottomMargin>
            </WidthSensitiveBorderedCol>
         </WidthSensitiveRow>
         <WidthSensitiveBottomMargin
            pxSize={20}
            $wideDisplayBottomMarginPx={10}
            $breakPointWidthPx={VIEW_WIDTH_BREAKPOINT_IN_PX}
         />
         <BorderedCol $topBorder={true} className='pt-3'>
            <CenterContainer>
               <CustomInput
                  type='checkbox'
                  id='concurrency'
                  name='concurrency'
                  label='Calculate concurrency metric (takes more time)'
                  checked={concurrencyIsDesiredLocal}
                  onChange={() => setLocalConcurrencyState(!concurrencyIsDesiredLocal)}
                  className='checkBoxLabel'
                  disabled={requestTrigger}
               />
            </CenterContainer>
            <CenterContainer marginTopPx={15}>
               <ExtButton
                  $widthPx={120}
                  color='primary'
                  onClick={() => {
                     activateTriggerRequest(true);
                     setConcurrencyState(concurrencyIsDesiredLocal);
                  }}
                  disabled={requestTrigger || datePickerHasError}
               >
                  {isLoading ? (
                     <BlockSpinner
                        ballSizePx={10}
                        ballColor={'rgb(230, 230, 230)'}
                        paddingTopBottomPx={0}
                        widthPx={41.15}
                     />
                  ) : (
                     <>{'Start request'}</>
                  )}
               </ExtButton>
            </CenterContainer>
         </BorderedCol>
      </CustomCardBody>
   );
}

MainInputs.propTypes = {
   streamNames: PropTypes.arrayOf(storePropTypes.stdSelectOption),
   setStreamNames: PropTypes.func,
   start: momentPropTypes.momentObj,
   setStart: PropTypes.func,
   end: momentPropTypes.momentObj,
   setEnd: PropTypes.func,
   tags: PropTypes.arrayOf(storePropTypes.stdSelectOption),
   setTags: PropTypes.func,
   requestTrigger: PropTypes.bool,
   activateTriggerRequest: PropTypes.func,
   isLoading: PropTypes.bool,
   setConcurrencyState: PropTypes.func,
};

export default MainInputs;
