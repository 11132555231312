import React, { useState, useRef, useCallback, useEffect } from 'react';
import TimeRange from 'react-timeline-range-slider';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { TimeSliderContainer, IntervalInfo, DateInput } from '../Styles';
import { getFull30SecondsStart, getFull30SecondsEnd } from '../general/DataProcessing';
import {
   CHRONOLOGICAL_DATE_TIME_FORMAT,
   FINE_INPUT_FORMAT,
   MAX_DURATION_IN_MS,
   MAX_RAW_MODE_TIME_RANGE_IN_SECS,
} from '../constants';
import { HintInfo } from '../../../../styledComponents/styledComponents';

function DateTimeSlider({ startDateString, endDateString, fineStartString, fineEndString, setFineInterval, interval }) {
   const [ref] = useHookWithRefCallback();

   const startInputRef = useRef();
   const endInputRef = useRef();

   const [timeLine, setTimeLine] = useState(setStartEnd(startDateString, endDateString));
   const [slideInterval, setSlideInterval] = useState(timeLine);
   const [slideStart, slideEnd] = slideInterval;

   const slideStartRef = useRef(slideStart);
   const slideEndRef = useRef(slideEnd);
   slideStartRef.current = slideStart;
   slideEndRef.current = slideEnd;

   const setTimingsBySlider = useRef();
   const restoreSettings = useRef();

   const [inputStart, setInputStart] = useState(sliderJsDateToUtcTime(timeLine[0]).format(FINE_INPUT_FORMAT));
   const [inputEnd, setInputEnd] = useState(sliderJsDateToUtcTime(timeLine[1]).format(FINE_INPUT_FORMAT));
   const [isError, setError] = useState('');

   const timeoutRef = useRef();

   const fineStartStrRef = useRef();
   fineStartStrRef.current = fineStartString;
   const fineEndStrRef = useRef();
   fineEndStrRef.current = fineEndString;

   // new timeline (= new selected publish)
   useEffect(() => {
      const adjustedEndDateString = getLimitedFinePublishEnd(startDateString, endDateString);
      const timeLine = setStartEnd(startDateString, endDateString);
      const sliderInterval = setStartEnd(startDateString, adjustedEndDateString);
      setTimeLine(timeLine);
      setSlideInterval(sliderInterval);
      restoreSettings.current = true;
      const fineStartMoment = sliderJsDateToUtcTime(sliderInterval[0]);
      const fineEndMoment = sliderJsDateToUtcTime(sliderInterval[1]);
      setInputStart(fineStartMoment.format(FINE_INPUT_FORMAT));
      setInputEnd(fineEndMoment.format(FINE_INPUT_FORMAT));

      if (
         !isPreselectedFineStartEndValid(fineStartStrRef.current, fineEndStrRef.current, startDateString, endDateString)
      ) {
         setFineInterval({
            fineStart: fineStartMoment.format(CHRONOLOGICAL_DATE_TIME_FORMAT),
            fineEnd: fineEndMoment.format(CHRONOLOGICAL_DATE_TIME_FORMAT),
         });
      }
   }, [startDateString, endDateString, setFineInterval]);

   useEffect(() => {
      restoreSettings.current = true;
   }, [fineStartString, fineEndString]);

   // new slider movement
   useEffect(() => {
      const [newSlideStart, newSlideEnd] = setStartEnd(fineStartString, fineEndString);
      if (
         slideStartRef.current.getTime() !== newSlideStart.getTime() ||
         slideEndRef.current.getTime() !== newSlideEnd.getTime()
      ) {
         setSlideInterval([newSlideStart, newSlideEnd]);
         setInputStart(sliderJsDateToUtcTime(newSlideStart).format(FINE_INPUT_FORMAT));
         setInputEnd(sliderJsDateToUtcTime(newSlideEnd).format(FINE_INPUT_FORMAT));
      }
   }, [fineStartString, fineEndString]);

   useEffect(() => {
      return () => {
         if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
         }
      };
   }, []);

   function handleOnChange() {
      const systemIsSettingTimings = restoreSettings.current && !setTimingsBySlider.current;

      if (systemIsSettingTimings) {
         restoreSettings.current = false;
         setFineInterval({
            fineStart: fineStartString,
            fineEnd: fineEndString,
         });
      } else {
         setTimingsBySlider.current = false;
         setFineInterval({
            fineStart: sliderJsDateToUtcTime(slideStart).format(CHRONOLOGICAL_DATE_TIME_FORMAT),
            fineEnd: sliderJsDateToUtcTime(slideEnd).format(CHRONOLOGICAL_DATE_TIME_FORMAT),
         });
      }
   }

   function handleSlide(current, update) {
      const [{ val: currentStart }, { val: currentEnd }] = current;
      const [{ val: updateStart }, { val: updateEnd }] = update;
      if (currentStart >= updateEnd || currentEnd <= updateStart) {
         return current;
      }
      const hasStartChanged = currentStart !== updateStart;
      const hasEndChanged = currentEnd !== updateEnd;
      const updateDuration = updateEnd - updateStart;
      const isDurationTooLong = updateDuration > MAX_DURATION_IN_MS;
      const slideStart =
         hasEndChanged && isDurationTooLong ? new Date(updateEnd - MAX_DURATION_IN_MS) : new Date(updateStart);
      const slideEnd =
         hasStartChanged && isDurationTooLong ? new Date(updateStart + MAX_DURATION_IN_MS) : new Date(updateEnd);
      setSlideInterval([slideStart, slideEnd]);
      setInputStart(sliderJsDateToUtcTime(slideStart).format(FINE_INPUT_FORMAT));
      setInputEnd(sliderJsDateToUtcTime(slideEnd).format(FINE_INPUT_FORMAT));
      setTimingsBySlider.current = true;
      return update;
   }

   function handleBlur(startEnd, dateTimeString) {
      const dateTimeUtc = moment.utc(dateTimeString, FINE_INPUT_FORMAT);
      const dateTimeLocal = utcToSliderJsDateTime(dateTimeUtc);
      const oldSlideStart = sliderJsDateToUtcTime(slideStart);
      const oldSlideEnd = sliderJsDateToUtcTime(slideEnd);

      const invalidDateTime = !dateTimeUtc.isValid();
      const beyondTimeLine =
         timeLine[0].getTime() > dateTimeLocal.getTime() || timeLine[1].getTime() < dateTimeLocal.getTime();
      const startGtEnd =
         startEnd === 'start'
            ? dateTimeUtc.diff(oldSlideEnd, 'seconds') >= 0
            : dateTimeUtc.diff(oldSlideStart, 'seconds') <= 0;
      const resetInput = invalidDateTime || beyondTimeLine || startGtEnd;

      if (resetInput) {
         startEnd === 'start'
            ? setInputStart(oldSlideStart.format(FINE_INPUT_FORMAT))
            : setInputEnd(oldSlideEnd.format(FINE_INPUT_FORMAT));
         setError(startEnd === 'start' ? 'startError' : 'endError');
         timeoutRef.current = setTimeout(() => setError(''), 150);
         return;
      }

      const roundDownDate =
         interval.value === 'second'
            ? getFull30SecondsStart(dateTimeUtc.clone())
            : dateTimeUtc.clone().startOf(interval.value);

      const startOfEnd = dateTimeUtc.clone().startOf(interval.value);
      const newEnd = dateTimeUtc.isSame(startOfEnd) ? dateTimeUtc : startOfEnd.add(1, interval.value);
      const roundUpDate = interval.value === 'second' ? getFull30SecondsEnd(dateTimeUtc.clone()) : newEnd;

      const roundedNewDateTime = startEnd === 'start' ? roundDownDate : roundUpDate;
      const isSameStart = roundedNewDateTime.isSame(oldSlideStart);
      const isSameEnd = roundedNewDateTime.isSame(oldSlideEnd);
      if (startEnd === 'start' && isSameStart) {
         setInputStart(oldSlideStart.format(FINE_INPUT_FORMAT));
         return;
      }
      if (startEnd === 'end' && isSameEnd) {
         setInputEnd(oldSlideEnd.format(FINE_INPUT_FORMAT));
         return;
      }
      setFineInterval({
         fineStart:
            startEnd === 'start'
               ? roundedNewDateTime.format(CHRONOLOGICAL_DATE_TIME_FORMAT)
               : oldSlideStart.format(CHRONOLOGICAL_DATE_TIME_FORMAT),
         fineEnd:
            startEnd === 'start'
               ? oldSlideEnd.format(CHRONOLOGICAL_DATE_TIME_FORMAT)
               : roundedNewDateTime.format(CHRONOLOGICAL_DATE_TIME_FORMAT),
      });
   }

   return (
      <>
         <TimeSliderContainer ref={ref}>
            <IntervalInfo>
               <span>Fine interval: </span>
               <span>
                  <DateInput
                     type='text'
                     className='form-control'
                     ref={startInputRef}
                     value={inputStart}
                     onChange={(e) => setInputStart(e.target.value)}
                     onBlur={(e) => handleBlur('start', e.target.value)}
                     onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                           startInputRef.current.blur();
                        }
                     }}
                     hasError={isError === 'startError'}
                  />
               </span>
               <span>
                  <DateInput
                     type='text'
                     className='form-control'
                     ref={endInputRef}
                     value={inputEnd}
                     onChange={(e) => setInputEnd(e.target.value)}
                     onBlur={(e) => handleBlur('end', e.target.value)}
                     onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                           endInputRef.current.blur();
                        }
                     }}
                     hasError={isError === 'endError'}
                  />
               </span>
            </IntervalInfo>

            <TimeRange
               step={interval.stepsInMilliSec}
               ticksNumber={8}
               selectedInterval={slideInterval}
               timelineInterval={timeLine}
               onUpdateCallback={() => {}}
               onChangeCallback={handleOnChange}
               disabledIntervals={[]}
               mode={handleSlide}
            />
            <HintInfo>
               <span>{`Select a time range less than or equal ${
                  MAX_RAW_MODE_TIME_RANGE_IN_SECS / 60
               } minutes to switch to 'Raw Data Mode'`}</span>
            </HintInfo>
         </TimeSliderContainer>
      </>
   );
}

function setStartEnd(startDateString, endDateString) {
   const start = moment.utc(startDateString, CHRONOLOGICAL_DATE_TIME_FORMAT);
   const end = moment.utc(endDateString, CHRONOLOGICAL_DATE_TIME_FORMAT);
   return [utcToSliderJsDateTime(start), utcToSliderJsDateTime(end)];
}

// source: https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
function useHookWithRefCallback() {
   const ref = useRef(null);
   const setRef = useCallback((node) => {
      if (ref.current) {
         // Make sure to cleanup any events/references added to the last instance
      }

      if (node) {
         if (node) {
            const slideBody = node.querySelector('.react_time_range__track');
            slideBody.style.backgroundColor = 'rgba(255,147,63, 0.3)';
            slideBody.style.borderLeft = 'rgba(32, 168, 216)';
            slideBody.style.borderRight = 'rgba(32, 168, 216)';
            const handleMarkerList = node.querySelectorAll('.react_time_range__handle_marker');
            const handleMarkerArray = Array.from(handleMarkerList);
            handleMarkerArray.forEach((handleMarker) => (handleMarker.style.backgroundColor = 'rgba(32, 168, 216, 1)'));
            const handleMarkerBodyList = node.querySelectorAll('.react_time_range__handle_container');
            const handleMarkerBodyArray = Array.from(handleMarkerBodyList);
            const leftHandle = handleMarkerBodyArray[0];
            leftHandle.style.transform = 'translate(-100%, -50%)';
            leftHandle.style.borderRadius = '4px 0px 0px 4px';
            leftHandle.style.boxShadow = '-1px 0px 3px 0px rgba(0,0,0,0.4)';
            const rightHandle = handleMarkerBodyArray[1];
            rightHandle.style.transform = 'translate(-5%, -50%)';
            rightHandle.style.borderRadius = '0px 4px 4px 0px';
            rightHandle.style.boxShadow = '1px 0px 3px 0px rgba(0,0,0,0.4)';
            const handleWrapperList = node.querySelectorAll('.react_time_range__handle_wrapper');
            const handleWrapperArray = Array.from(handleWrapperList);
            const leftWrapper = handleWrapperArray[0];
            leftWrapper.style.transform = 'translate(-10px, -50%)';
            leftWrapper.style.width = '10px';
            const rightWrapper = handleWrapperArray[1];
            rightWrapper.style.transform = 'translate(0px, -50%)';
            rightWrapper.style.width = '10px';
         }

         // Check if a node is actually passed. Otherwise node would be null.
         // You can now do what you need to, addEventListeners, measure, etc.
      }

      // Save a reference to the node
      ref.current = node;
   }, []);

   return [setRef];
}

function utcToSliderJsDateTime(utcTime) {
   const localMoment = moment(utcTime.format());
   const utcOffsetInMinutes = localMoment.utcOffset();
   let localDate = localMoment.clone();
   if (utcOffsetInMinutes !== 0) localDate = localMoment.clone().subtract(utcOffsetInMinutes, 'minutes');
   return new Date(localDate.format());
}

function sliderJsDateToUtcTime(sliderJsDate) {
   const localMoment = moment(sliderJsDate);
   const utcOffsetInMinutes = localMoment.utcOffset();
   let utcTime = localMoment.clone();
   if (utcOffsetInMinutes !== 0) utcTime = localMoment.clone().add(utcOffsetInMinutes, 'minutes');
   return utcTime.utc();
}

function getLimitedFinePublishEnd(publishFineStart, publishFineEnd) {
   const fineStart = moment.utc(publishFineStart, CHRONOLOGICAL_DATE_TIME_FORMAT);
   const fineEnd = moment.utc(publishFineEnd, CHRONOLOGICAL_DATE_TIME_FORMAT);
   const durationInSecs = fineEnd.diff(fineStart, 'seconds');
   const isDurationTooLong = durationInSecs > MAX_DURATION_IN_MS / 1000;
   return isDurationTooLong
      ? fineStart
           .clone()
           .add(MAX_DURATION_IN_MS / 1000, 'seconds')
           .format(CHRONOLOGICAL_DATE_TIME_FORMAT)
      : publishFineEnd;
}

function isPreselectedFineStartEndValid(publishFineStart, publishFineEnd, publishStart, publishEnd) {
   const fineStartMoment = moment.utc(publishFineStart, CHRONOLOGICAL_DATE_TIME_FORMAT);
   const fineEndMoment = moment.utc(publishFineEnd, CHRONOLOGICAL_DATE_TIME_FORMAT);
   const publishStartMoment = moment.utc(publishStart, CHRONOLOGICAL_DATE_TIME_FORMAT);
   const publishEndMoment = moment.utc(publishEnd, CHRONOLOGICAL_DATE_TIME_FORMAT);
   const isStartValid = fineStartMoment.isSameOrAfter(publishStartMoment);
   const isEndValid = fineEndMoment.isSameOrBefore(publishEndMoment);
   const durationInSecs = fineEndMoment.diff(fineStartMoment, 'seconds');
   const isDurationValid = durationInSecs <= MAX_DURATION_IN_MS / 1000;
   return isStartValid && isEndValid && isDurationValid;
}

DateTimeSlider.propTypes = {
   startDateString: PropTypes.string,
   endDateString: PropTypes.string,
   fineStartString: PropTypes.string,
   fineEndString: PropTypes.string,
   setFineInterval: PropTypes.func,
   interval: PropTypes.shape({
      stepsInMilliSec: PropTypes.number,
      value: PropTypes.string,
   }),
};

export default DateTimeSlider;
