import React from 'react';

import {
   WORLDMAP_METRIC,
   BUFFER_RATIO_VALUE_GREEN,
   BUFFER_RATIO_VALUE_YELLOW,
   LATENCY_VALUE_GREEN,
   LATENCY_VALUE_YELLOW,
   VIEW_MODE,
} from '../WorldMapConstants';

import { LEGEND_SETTINGS } from './LegendConstants';

import { PropTypes } from 'prop-types';
import { StyledLegend } from './LegendStyles';
import { AbrPlaytime } from './LegendTypes/AbrPlaytime';
import { PlayoutIngest } from './LegendTypes/PlayoutIngest';
import { TrafficLight } from './LegendTypes/TrafficLight';
import { HeatScale } from './LegendTypes/HeatScale';
import { GreenRedScale } from './LegendTypes/GreenRedScale';

function Legend({
   metric,
   mapHeight,
   maxHeatValue,
   total,
   subSwitch,
   onSubSwitch,
   view,
   zoomedRegion,
   showCompactLegend,
}) {
   const handleSwitch = (e) => {
      const switchOption = e.target.getAttribute('name');
      if (subSwitch === switchOption) {
         return;
      }
      onSubSwitch({ metric, switchOption }, { [metric]: switchOption });
   };

   const shrinkLegendVertically = (view === VIEW_MODE.REGION && zoomedRegion === '') || showCompactLegend;
   const showScaleFields = !shrinkLegendVertically;
   const hiddenUsageWorldRegionLegend =
      metric === WORLDMAP_METRIC.USAGE && view === VIEW_MODE.REGION && zoomedRegion === '';

   const {
      scaleFieldWidth,
      numberFormat,
      headline,
      valueManipulation,
      totalText,
      totalValueFormat,
      min,
      max,
   } = LEGEND_SETTINGS[metric];

   return (
      <StyledLegend
         metric={metric}
         mapHeight={mapHeight}
         perCountryView={shrinkLegendVertically ? 1 : 0}
         isHidden={hiddenUsageWorldRegionLegend}
      >
         {WORLDMAP_METRIC.PI === metric && (
            <PlayoutIngest
               numberFormat={numberFormat}
               valueManipulation={valueManipulation}
               scaleFieldWidth={scaleFieldWidth}
               total={[totalText, totalValueFormat(total)]}
               subSwitch={subSwitch}
               onSwitch={handleSwitch}
               maxHeatValue={maxHeatValue}
               scaleFieldsVisible={showScaleFields}
            />
         )}
         {WORLDMAP_METRIC.BUFFER_RATIO === metric && (
            <TrafficLight
               headline={headline}
               greenValue={BUFFER_RATIO_VALUE_GREEN}
               yellowValue={BUFFER_RATIO_VALUE_YELLOW}
               total={[totalText, totalValueFormat(total)]}
               valueManipulation={valueManipulation}
               scaleFieldWidth={scaleFieldWidth}
               show={maxHeatValue !== 0}
               scaleFieldsVisible={showScaleFields}
            />
         )}
         {WORLDMAP_METRIC.LATENCY === metric && (
            <TrafficLight
               headline={headline}
               greenValue={LATENCY_VALUE_GREEN}
               yellowValue={LATENCY_VALUE_YELLOW}
               total={[totalText, totalValueFormat(total)]}
               valueManipulation={valueManipulation}
               scaleFieldWidth={scaleFieldWidth}
               show={maxHeatValue !== 0}
               scaleFieldsVisible={showScaleFields}
            />
         )}
         {WORLDMAP_METRIC.PLAYTIME === metric && (
            <HeatScale
               headline={headline}
               numberFormat={numberFormat}
               valueManipulation={valueManipulation}
               scaleFieldWidth={scaleFieldWidth}
               maxHeatValue={maxHeatValue}
               total={[totalText, totalValueFormat(total)]}
               scaleFieldsVisible={showScaleFields}
            />
         )}
         {WORLDMAP_METRIC.USAGE === metric && (
            <HeatScale
               headline={headline}
               numberFormat={(value, maxValue) => numberFormat(value, maxValue, false)}
               valueManipulation={valueManipulation}
               scaleFieldWidth={scaleFieldWidth}
               maxHeatValue={maxHeatValue}
               total={[totalText, totalValueFormat(total)]}
               scaleFieldsVisible={showScaleFields}
            />
         )}
         {WORLDMAP_METRIC.ABR_PLAYTIME === metric && (
            <AbrPlaytime
               headline={headline}
               numberFormat={numberFormat}
               valueManipulation={valueManipulation}
               scaleFieldWidth={scaleFieldWidth}
               subSwitch={subSwitch}
               onSwitch={handleSwitch}
               maxHeatValue={maxHeatValue}
               total={[totalText, totalValueFormat(total)]}
               scaleFieldsVisible={showScaleFields}
            />
         )}
         {WORLDMAP_METRIC.ABR_VIEWER === metric && (
            <HeatScale
               headline={headline}
               numberFormat={numberFormat}
               valueManipulation={valueManipulation}
               scaleFieldWidth={scaleFieldWidth}
               maxHeatValue={maxHeatValue}
               total={[totalText, totalValueFormat(total)]}
               scaleFieldsVisible={showScaleFields}
            />
         )}
         {WORLDMAP_METRIC.ABR_SWITCHES === metric && (
            <HeatScale
               headline={headline}
               numberFormat={numberFormat}
               valueManipulation={valueManipulation}
               scaleFieldWidth={scaleFieldWidth}
               maxHeatValue={maxHeatValue}
               total={[totalText, totalValueFormat(total)]}
               scaleFieldsVisible={showScaleFields}
            />
         )}
         {WORLDMAP_METRIC.QOE === metric && (
            <GreenRedScale
               headline={headline}
               numberFormat={numberFormat}
               valueManipulation={valueManipulation}
               scaleFieldWidth={scaleFieldWidth}
               min={min}
               max={max}
               total={[totalText, totalValueFormat(total)]}
               show={maxHeatValue !== 0}
               scaleFieldsVisible={showScaleFields}
            />
         )}
      </StyledLegend>
   );
}

Legend.propTypes = {
   mapHeight: PropTypes.number,
   maxHeatValue: PropTypes.number,
   subSwitch: PropTypes.string,
   onSubSwitch: PropTypes.func,
   total: PropTypes.number,
   metric: PropTypes.string,
   view: PropTypes.string,
   zoomedRegion: PropTypes.string,
   showCompactLegend: PropTypes.bool,
};

Legend.defaultProps = {
   showCompactLegend: false,
};

export default Legend;
