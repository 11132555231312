import { useEffect, useReducer, useRef } from 'react';
import axios from 'axios';
import { getOptions } from '../util/UtilFunctions';
import { getUnifiedErrorObject } from '../util/ErrorUnifier';

const dataFetchReducer = (state, action) => {
   const concurrentFetches = state.useCounter > 1;
   switch (action.type) {
      case 'FETCH_INIT':
         return {
            ...state,
            isLoading: true,
            errorMessage: undefined,
            successful: false,
            useCounter: state.useCounter + 1,
         };
      case 'FETCH_SUCCESS':
         return {
            ...state,
            isLoading: false,
            errorMessage: undefined,
            successful: true,
            data: action.payload.data,
            httpStatus: action.payload.status,
            useCounter: state.useCounter - 1,
         };
      case 'FETCH_FAILURE':
         return {
            ...state,
            isLoading: false,
            errorMessage: action.payload,
            successful: false,
            useCounter: state.useCounter - 1,
         };
      case 'FETCH_CANCEL':
         return {
            ...state,
            isLoading: concurrentFetches ? true : false,
            errorMessage: undefined,
            successful: false,
            useCounter: state.useCounter - 1,
         };

      default:
         throw new Error();
   }
};

const defaultOptions = {
   method: 'GET',
   body: {},
   header: undefined,
   cancelToken: undefined,
   onRequestCompleted: () => {},
};

// data fetcher is triggered by URL (includes time range), request method, body, header or cancel token change

function useDataFetch(url, fetchOptions = defaultOptions) {
   const options = getOptions(defaultOptions, fetchOptions);
   const { header, body, method, cancelToken, onRequestCompleted } = options;

   let isMounted = useRef(true);

   useEffect(() => {
      return () => {
         isMounted.current = false;
      };
   }, []);

   const [state, dispatch] = useReducer(dataFetchReducer, {
      isLoading: false,
      errorMessage: undefined,
      successful: false,
      data: {},
      httpStatus: -1,
      useCounter: 0,
   });

   useEffect(() => {
      // console.log('----- >>>>> fetch :>> ', url);
      // console.log('----- >>>>> fetch Method :>> ', method);

      let didCancel = false;
      const fetchData = async () => {
         dispatch({ type: 'FETCH_INIT' });
         // console.log('+++++++++++++ >>>>> FETCH_INIT :>> ');

         try {
            let result = null;

            const config = {};
            if (header !== undefined) {
               config.headers = header;
            }
            if (cancelToken !== undefined) {
               config.cancelToken = cancelToken.token;
            }

            result = await axios({ url, method, data: body, ...config });

            if (!didCancel) {
               // console.log('FETCH_SUCCESS :>> ', result);
               const payload = { data: result.data, status: result.status };
               dispatch({ type: 'FETCH_SUCCESS', payload });
               onRequestCompleted(payload); // function parameter interface: 1. data 2. error
            }
         } catch (error) {
            if (error instanceof axios.Cancel) {
               if (isMounted.current) {
                  dispatch({ type: 'FETCH_CANCEL' });
                  onRequestCompleted(undefined, { message: `CANCELLED - ${url}` });
               }
               return;
            }
            if (!didCancel) {
               // console.log('FETCH_FAILURE :>> ', error);
               // console.log('url :>> ', url);
               const errorObj = getUnifiedErrorObject(error);
               dispatch({
                  type: 'FETCH_FAILURE',
                  payload: errorObj,
               });
               onRequestCompleted(undefined, errorObj); // function parameter interface: 1. data 2. error
            }
            if (process.env.NODE_ENV === 'development') {
               console.error(error);
            }
         }
      };
      fetchData();
      return () => {
         didCancel = true;
      };
   }, [url, header, body, method, cancelToken, onRequestCompleted]);

   return { ...state };
}

// axios interceptors for debugging

// axios.interceptors.request.use(
//    (config) => {
//       console.log('Request was sent', config);
//       return config;
//    },
//    (error) => {
//       console.log('Request error :>> ', error);
//       return Promise.reject(error);
//    },
// );

// axios.interceptors.response.use(
//    (res) => {
//       console.log('Response interceptor: ', res);
//       return res;
//    },
//    (error) => {
//       console.log('Response error :>> ', error);
//       return Promise.reject(error);
//    },
// );

export default useDataFetch;
