import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

const ButtonContainer = styled.div`
   margin-bottom: ${(props) => props.marginBottomPx}px;
   margin-top: ${(props) => props.marginTopPx}px;
   margin-left: ${(props) => props.marginLeftPx}px;
   margin-right: ${(props) => props.marginRightPx}px;
   ${(props) => props.height !== undefined && `height: ${props.height}px;`}
`;

const CoreButton = styled.div`
   width: ${(props) => props.width}px;
   -webkit-appearance: none;
   -moz-appearance: none;
   font-size: ${(props) => props.fontSizePx}px;
   text-align: ${(props) => props.textAlign};
   padding: 0px ${(props) => props.textPaddingRightPx}px 0px ${(props) => props.textPaddingLeftPx}px;
`;

const ButtonLabel = styled.label`
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   ${(props) => props.height !== undefined && `line-height: ${props.height}px;`}
   ${(props) => props.height !== undefined && 'padding: 0px;'}
   background-color: ${(props) => props.bgColor};
   color: ${(props) => props.fontColor};
   font-weight: ${(props) => props.fontWeight};
   border: 1px solid ${(props) => props.borderColor};
   ${ButtonContainer}:hover & {
      background-color: ${(props) => props.hoverBgColor};
      color: ${(props) => props.hoverFontColor};
      font-weight: ${(props) => props.hoverFontWeight};
      border: 1px solid ${(props) => props.hoverBorderColor};
  }
`;

function CustomButton({
   label,
   name,
   active,
   handleClick,
   buttonWidthPx,
   marginBottomPx,
   marginTopPx,
   marginRightPx,
   marginLeftPx,
   buttonHeightPx,
   fontSizePx,
   backgroundColor,
   activeBackgroundColor,
   hoverBackgroundColor,
   fontColor,
   activeFontColor,
   hoverFontColor,
   borderColor,
   activeBorderColor,
   hoverBorderColor,
   fontWeight,
   textAlign,
   textPaddingRightPx,
   textPaddingLeftPx,
}) {
   return (
      <ButtonContainer
         className='btn-group btn-group-toggle'
         data-toggle='buttons'
         marginBottomPx={marginBottomPx}
         marginTopPx={marginTopPx}
         marginRightPx={marginRightPx}
         marginLeftPx={marginLeftPx}
         height={buttonHeightPx}
         key={label}
         name={name}
      >
         <ButtonLabel
            className={'btn'}
            name={name}
            id={label}
            height={buttonHeightPx}
            onClick={handleClick}
            bgColor={active ? activeBackgroundColor : backgroundColor}
            hoverBgColor={hoverBackgroundColor}
            fontColor={active ? activeFontColor : fontColor}
            hoverFontColor={hoverFontColor}
            borderColor={active ? activeBorderColor : borderColor}
            hoverBorderColor={hoverBorderColor}
            fontWeight={fontWeight}
         >
            <CoreButton
               type='button'
               width={buttonWidthPx}
               fontSizePx={fontSizePx}
               name={name}
               id={label}
               textAlign={textAlign}
               textPaddingRightPx={textPaddingRightPx}
               textPaddingLeftPx={textPaddingLeftPx}
            >
               {label}
            </CoreButton>
         </ButtonLabel>
      </ButtonContainer>
   );
}

CustomButton.propTypes = {
   label: PropTypes.any,
   name: PropTypes.any,
   active: PropTypes.bool,
   handleClick: PropTypes.func,
   buttonWidthPx: PropTypes.number,
   buttonHeightPx: PropTypes.number,
   marginBottomPx: PropTypes.number,
   marginTopPx: PropTypes.number,
   marginRightPx: PropTypes.number,
   marginLeftPx: PropTypes.number,
   fontSizePx: PropTypes.number,
   backgroundColor: PropTypes.string,
   activeBackgroundColor: PropTypes.string,
   hoverBackgroundColor: PropTypes.string,
   borderColor: PropTypes.string,
   activeBorderColor: PropTypes.string,
   hoverBorderColor: PropTypes.string,
   fontColor: PropTypes.string,
   activeFontColor: PropTypes.string,
   hoverFontColor: PropTypes.string,
   fontWeight: PropTypes.any,
   textAlign: PropTypes.string,
   textPaddingRightPx: PropTypes.number,
   textPaddingLeftPx: PropTypes.number,
};

CustomButton.defaultProps = {
   active: false,
   name: '',
   buttonWidthPx: 100,
   marginBottomPx: 0,
   marginTopPx: 0,
   marginRightPx: 0,
   marginLeftPx: 0,
   fontSizePx: 14,
   fontColor: 'rgb(35, 40, 44)',
   backgroundColor: 'rgb(233, 240, 245)',
   borderColor: 'rgb(200, 206, 211)',
   activeFontColor: 'rgb(35, 40, 44)',
   activeBackgroundColor: 'rgb(250, 250, 250)',
   activeBorderColor: 'rgb(200, 200, 200)',
   hoverFontColor: 'rgb(35, 40, 44)',
   hoverBackgroundColor: 'rgb(210, 210, 210)',
   hoverBorderColor: 'rgb(160, 160, 160)',
   fontWeight: 500,
   textAlign: 'center',
   textPaddingRightPx: 0,
   textPaddingLeftPx: 0,
};

export default CustomButton;
