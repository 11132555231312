import Datetime from 'react-datetime';

// removes console logs from Datetime module (overrides setViewDate method with loggings)
class SilentDatePicker extends Datetime {
   setViewDate(date) {
      if (!date) return;

      let viewDate;
      if (typeof date === 'string') {
         viewDate = this.localMoment(date, this.getFormat('datetime'));
      } else {
         viewDate = this.localMoment(date);
      }

      if (!viewDate || !viewDate.isValid()) return;
      this.setState({ viewDate: viewDate });
   }
}

export default SilentDatePicker;
