import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';

import { setAlarm, setFilter, setEvents } from '../../../redux/actions/general';

import { Button, Col, Row } from 'reactstrap';

import storePropTypes from '../../../redux/store/propTypes';
import { PropTypes } from 'prop-types';

import {
   timespanChanged,
   orgaHashChanged,
   filterDataNotYetFetched,
   filterChanged,
} from '../../../redux/selectors/comparing';
import {
   InactiveGreyCard,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../styledComponents/styledComponents';
import { DefaultGrey } from '../../../constants/colors';

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setEvents, setFilter, setAlarm };

let eventsIsBeingFetched = false;

class EventFilter extends Component {
   constructor(props) {
      super(props);

      this.state = {
         filter: {
            events: props.filter.events,
         },
      };
   }

   // collect user selection(s), but don't trigger a dispatch
   handleChange = (selectedOptions) => {
      const selectedEvents = selectedOptions === null ? [] : selectedOptions;
      this.setState({ filter: { events: selectedEvents } });
   };

   applySelection = () => {
      this.props.setFilter(
         {
            ...this.props.filter,
            events: this.state.filter.events,
         },
         { events: this.state.filter.events },
      );
   };

   getEvents(timespan, filter, cancelToken) {
      const self = this;

      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      let token = userInfo.token;

      let headerObj = {
         'x-access-token': token,
         Accept: 'application/json',
         'Content-Type': 'application/json;charset=UTF-8',
      };

      if (filter.hash !== '') {
         headerObj['x-orga-hash'] = filter.hash;
      }

      axios
         .get(`/api/v2/filters/events?from=${timespan.gte.format()}&to=${timespan.lt.format()}`, {
            headers: headerObj,
            cancelToken: cancelToken.token,
         })
         .then(function (response) {
            const events = response.data.data.map((event) => ({ label: event, value: event }));
            self.props.setEvents(events);
         })
         .catch((error) => {
            if (error instanceof axios.Cancel) {
               return;
            }
            if (process.env.NODE_ENV === 'development') {
               console.error(error);
            }
         })
         .finally(function () {
            eventsIsBeingFetched = false;
         });
   }

   componentWillReceiveProps(nextProps) {
      if (
         timespanChanged(this.props, nextProps) ||
         orgaHashChanged(this.props, nextProps) ||
         filterDataNotYetFetched(nextProps, 'events', eventsIsBeingFetched)
      ) {
         eventsIsBeingFetched = true;
         this.getEvents(nextProps.timespan, nextProps.filter, nextProps.cancelToken);
      }

      // required for URL sync: if events filter is set by URL (with no hash/timespan change)

      if (filterChanged(this.state.filter.events, nextProps.filter.events)) {
         this.setState({ filter: { events: nextProps.filter.events } });
      }
   }

   render() {
      return (
         <div style={{ position: 'relative', zIndex: 13 }} className='animated fadeIn'>
            <Row>
               <Col>
                  <WidgetContainer>
                     <InactiveGreyCard $isActive={this.props.isActive}></InactiveGreyCard>
                     <WidgetHeaderContainer $bgColor={DefaultGrey(1, 247)}>
                        <strong>Event filter</strong>
                     </WidgetHeaderContainer>
                     <WidgetBodyContainer>
                        <div className='customRow'>
                           <div className='selectCol'>
                              <Select
                                 value={this.state.filter.events}
                                 onChange={this.handleChange}
                                 options={this.props.events}
                                 isMulti={true}
                                 closeMenuOnSelect={false}
                              />
                           </div>
                           <div className='buttonCol'>
                              <Button color='primary' onClick={this.applySelection}>
                                 Go
                              </Button>
                           </div>
                        </div>
                     </WidgetBodyContainer>
                  </WidgetContainer>
               </Col>
            </Row>
         </div>
      );
   }
}

EventFilter.propTypes = {
   filter: storePropTypes.filter,
   timespan: storePropTypes.timespan,
   events: storePropTypes.events,
   cancelToken: storePropTypes.cancelToken,
   isActive: PropTypes.bool,
   setEvents: PropTypes.func,
   setFilter: PropTypes.func,
   setAlarm: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventFilter);
