import React, { useState, useEffect, useRef, useCallback } from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';

import {
   hashSelector,
   cancelTokenSelector,
   startTimespanSelector,
   endTimespanSelector,
} from '../../../redux/selectors/selectors';

import { PropTypes } from 'prop-types';
import MultiStateButton from './MultiStateButton';
import { START, CHECKING, CHECKED } from './StreamNameConstants';
import { useNonInitialEffect } from '../../../hooks/useNonInitialEffect';
import StreamNameService from './StreamNameService';
import { MatchSelectComponentColorStyles } from '../../../constants/styles';

const invalidIndicationStyle = {
   input: (defaultStyles) => {
      return {
         ...defaultStyles,
         color: '#8a1e16',
      };
   },
};

const defaultStyle = {
   input: (defaultStyles) => {
      return {
         ...defaultStyles,
      };
   },
};

function StreamNameChecker({
   streamNames,
   onStreamAdd,
   clearSelection,
   urlChange,
   SelectContainer,
   ButtonContainer,
   ButtonWidthPx,
   ButtonMarginRightPx,
}) {
   const orgaHash = useSelector(hashSelector);
   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);

   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const [streamNameObj, setStreamName] = useState({ streamName: '' });
   const { streamName } = streamNameObj;
   const [inputStyle, setInputStyle] = useState(defaultStyle);

   const [inputValue, setInputValue] = useState('');
   const [checkerState, setCheckerState] = useState(START); // CHECKING, CHECKED

   const [valid, setValidState] = useState();
   const [info, setInfo] = useState();
   const [isLoading, setLoadingState] = useState(false);
   const [error, setError] = useState();
   const [untouched, setUnTouchedState] = useState(true);

   const inputValueRef = useRef();
   inputValueRef.current = inputValue;

   const url = `/api/v2/streamname?from=${start.format()}&to=${end.format()}`;

   const resetInput = useCallback(() => {
      setStreamName({ streamName: '' });
      setInputValue('');
      setCheckerState(START);
      setInputStyle(defaultStyle);
   }, []);

   // orga hash changed
   useNonInitialEffect(() => {
      resetInput();
      clearSelection();
   }, [orgaHash, clearSelection, resetInput]);

   // stream name changed by URL
   useEffect(() => {
      resetInput();
   }, [urlChange, resetInput]);

   // reset states
   useEffect(() => {
      if (streamName === '') {
         setValidState(undefined);
         setLoadingState(false);
         setError(undefined);
         setInputStyle(defaultStyle);
      }
   }, [streamName]);

   useEffect(() => {
      if (untouched) {
         return;
      }

      if (isLoading) {
         setInputStyle(defaultStyle);
         setInputValue(`${inputValueRef.current} Checking...`);
         return;
      }

      if (valid === true) {
         // console.log('valid :>> ');
         setInputValue(`${inputValueRef.current} \u2705 (ready to add)`);
         setCheckerState(CHECKED);
         return;
      }

      if (valid === false) {
         // console.log('!valid :>> ');
         setInputValue(`${inputValueRef.current} \u26D4 (${info})`);
         setInputStyle(invalidIndicationStyle);
         setCheckerState(CHECKED);
         return;
      }

      if (error) {
         setInputValue(`${inputValueRef.current} \u26D4 (${error.message})`);
         setInputStyle(invalidIndicationStyle);
         setCheckerState(CHECKED);
         return;
      }
   }, [valid, info, isLoading, error, dispatch, untouched]);

   // press ENTER handler
   function handleKeyDown(event) {
      if (event.key === 'Enter') {
         checkStreamName();
      }
   }

   // GO button handler
   function checkStreamName() {
      const trimmedInputValue = inputValue.trim().split(' ')[0];

      if (trimmedInputValue !== '') {
         if (streamNames.map((stream) => stream.value).includes(trimmedInputValue)) {
            setInputValue(`${trimmedInputValue} \u26D4 (already selected)`);
            setInputStyle(invalidIndicationStyle);
            setCheckerState(CHECKED);
            return;
         }
         setStreamName({ streamName: trimmedInputValue });
         setInputValue(trimmedInputValue);
         setInputStyle(defaultStyle);
         setCheckerState(CHECKING);
      }
   }

   // called while typing
   function handleInputChange(inputValue, metaAction) {
      if (metaAction.action !== 'input-change') return;
      if (checkerState === CHECKED) {
         setCheckerState(START);
      }
      setInputValue(inputValue);
   }

   function inputGetFocus() {
      if (inputValue.length > 5 && inputValue[inputValue.length - 1] === ')') {
         setInputValue(inputValue.split(' ')[0]);
         setInputStyle(defaultStyle);
      }
   }

   function handleResult(res) {
      const { valid, info, isLoading, error, untouched } = res;
      setValidState(valid);
      setInfo(info);
      setLoadingState(isLoading);
      setError(error);
      setUnTouchedState(untouched);
   }

   return (
      <>
         <SelectContainer>
            <Select
               components={{
                  DropdownIndicator: null,
               }}
               inputValue={inputValue}
               onInputChange={handleInputChange}
               onKeyDown={handleKeyDown}
               onFocus={inputGetFocus}
               menuIsOpen={false}
               placeholder={'Type stream name to be checked'}
               styles={{ ...inputStyle, ...MatchSelectComponentColorStyles }}
               isDisabled={checkerState === CHECKING}
            />
            {streamName !== '' && (
               <StreamNameService
                  streamName={streamNameObj}
                  url={url}
                  orgaHash={orgaHash}
                  cancelToken={cancelToken}
                  onResult={handleResult}
               />
            )}
         </SelectContainer>
         <ButtonContainer>
            <MultiStateButton
               inputValue={inputValue}
               validStreamName={valid}
               checkerState={checkerState}
               error={error}
               onCheckButtonClick={checkStreamName}
               onClearButtonClick={() => resetInput()}
               onAddButtonClick={(streamName) => {
                  onStreamAdd(streamName.split(' ')[0]);
                  resetInput();
               }}
               widthPx={ButtonWidthPx}
               marginRightPx={ButtonMarginRightPx}
            />
         </ButtonContainer>
      </>
   );
}

StreamNameChecker.propTypes = {
   streamNames: PropTypes.arrayOf(
      PropTypes.exact({
         value: PropTypes.string,
         label: PropTypes.string,
      }),
   ),
   onStreamAdd: PropTypes.func,
   clearSelection: PropTypes.func,
   urlChange: PropTypes.array,
   SelectContainer: PropTypes.func,
   ButtonContainer: PropTypes.func,
   ButtonWidthPx: PropTypes.number,
   ButtonMarginRightPx: PropTypes.number,
};

export default StreamNameChecker;
