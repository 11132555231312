import roundTo from 'round-to';
import { getWorldRegionsAggObject } from '../WorldMapConstants';
import { getOutput } from './General';

const country_alpha_id_mapper = require('../../../../assets/worldmap/compact_country_alpha_id_mapper.json');

export function processQoeAdapter(countries) {
   const adaptedCountries = countries.map((countryBucket) => {
      const { country, alpha2, qoeRanges, uniquePlaybacks, totalAvgScore, totalQoeRatings } = countryBucket;
      const { coordinates, worldRegion, code } = country_alpha_id_mapper[alpha2];
      const coor = [coordinates.longitude, coordinates.latitude];
      const countryResult = {
         alpha: alpha2,
         coor,
         id: code.numeric,
         name: country,
         worldRegion,
         qoeRatingCountOverRanges: qoeRanges.map((r) => r.qoeRatings.count),
         total: uniquePlaybacks.count,
         score: totalAvgScore.points,
         qoeRatingCount: totalQoeRatings.count,
      };

      return countryResult;
   });

   const worldRegions = createWorldRegionsAndTopData(adaptedCountries);

   return getOutput(adaptedCountries, worldRegions);
}

function createWorldRegionsAndTopData(countries) {
   const worldRegions = getWorldRegionsAggObject({
      countWeightedScoreSum: 0,
      qoeRatingsSum: 0,
   });

   let sumTotal = 0;

   countries.forEach((country) => {
      const { worldRegion, score, qoeRatingCount, total } = country;
      sumTotal += total;
      worldRegions[worldRegion].qoeRatingsSum += qoeRatingCount;
      worldRegions[worldRegion].countWeightedScoreSum += qoeRatingCount * score;
      worldRegions[worldRegion].countries.push(country);
   });

   Object.keys(worldRegions).forEach((worldRegion) => {
      worldRegions[worldRegion].score = roundTo(
         worldRegions[worldRegion].countWeightedScoreSum / worldRegions[worldRegion].qoeRatingsSum,
         1,
      );
      worldRegions[worldRegion].total = sumTotal;
      delete worldRegions[worldRegion].countWeightedScoreSum;
      delete worldRegions[worldRegion].qoeRatingsSum;
   });

   return worldRegions;
}
