import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { UncontrolledDropdown } from 'reactstrap';
import { DefaultBlue, DefaultGrey } from '../../../../constants/colors';
import { cancelTokenSelector, worldmapViewSelector } from '../../../../redux/selectors/selectors';
import {
   Caret,
   CenterXY,
   DroppedDownList,
   DroppedDownListItem,
   SimpleFlexItem,
   SimpleFlexRow,
   StyledButton,
   SwitchDescription,
   TwoSideFlexContainer,
} from '../../../../styledComponents/styledComponents';
import { accessGranted } from '../../../../util/UserAccessManagement';
import { CsvExportFromApiButton, HorizontalSeparator } from '../../../common';
import TrafficHint from '../../../common/Hints/TrafficHint';
import { DROP_DOWN_METRICS, WorldMetricCsvNameMap } from '../Metrics/MetricsConstants';
import { WORLDMAP_METRIC, VIEW_MODE } from '../WorldMapConstants';
import CountryRegionDisplay from './CountryRegionDisplay';
import CountryRegionSwitch from './CountryRegionSwitch';

function MenuControlBar({ selectedMetric, onMetricSelection, queryParams }) {
   const [metricDropDownState, setMetricDropDownState] = useState(false);
   const cancelToken = useSelector(cancelTokenSelector);
   const viewSwitch = useSelector(worldmapViewSelector);

   return (
      <TwoSideFlexContainer>
         <SimpleFlexRow>
            <SwitchDescription>{'Selected metric'}</SwitchDescription>
            <SimpleFlexItem $position={'relative'} $marginLeftInPx={7}>
               <UncontrolledDropdown group>
                  <StyledButton
                     fontSizePx={11}
                     className={'btn'}
                     fixMargin
                     name={'metricDisplayBtn'}
                     id={'metricDisplayBtn'}
                     fontWeight={500}
                     heightPx={22}
                     widthPx={150}
                     bgColor={'#FFF'}
                     fontColor={DefaultGrey(1, 100)}
                     bgColorHover={'#FFF'}
                     fontColorHover={'black'}
                     border={'1px solid rgba(0,0,0,0.2)'}
                     $buttonHeightPx={22}
                     onClick={() => setMetricDropDownState(!metricDropDownState)}
                  >
                     {selectedMetric.label}
                  </StyledButton>
                  <StyledButton
                     fontSizePx={11}
                     className={'btn'}
                     fixMargin
                     name={'metricDropDownBtn'}
                     id={'metricDropDownBtn'}
                     heightPx={22}
                     widthPx={30}
                     bgColor={DefaultGrey()}
                     fontColor={'black'}
                     bgColorHover={DefaultGrey()}
                     fontColorHover={'black'}
                     border={'1px solid rgba(0,0,0,0.2)'}
                     title={'Selected metric'}
                     onClick={() => setMetricDropDownState(!metricDropDownState)}
                     onBlur={() => {
                        setMetricDropDownState(false);
                     }}
                     active={false}
                     $position={'relative'}
                     $buttonHeightPx={22}
                     clickBgColor={DefaultBlue()}
                     showClick={metricDropDownState}
                  >
                     <CenterXY>
                        <Caret $color={metricDropDownState ? '#FFF' : DefaultGrey(1, 100)} />
                     </CenterXY>
                     <DroppedDownList
                        $isShown={metricDropDownState}
                        $leftInPx={-150}
                        $topInPx={23}
                        $minWidthInPx={180}
                        className={'dropdown-menu'}
                     >
                        {DROP_DOWN_METRICS.filter((metric) => accessGranted(metric.value)).map((metric) => (
                           <DroppedDownListItem
                              key={metric.value}
                              onClick={() => onMetricSelection(metric, { worldmapMetric: metric })}
                              className={'dropdown-item'}
                           >
                              {metric.label}
                           </DroppedDownListItem>
                        ))}
                     </DroppedDownList>
                  </StyledButton>
               </UncontrolledDropdown>
            </SimpleFlexItem>
            <HorizontalSeparator
               color={DefaultGrey(1, 180)}
               widthInPx={1}
               heightInPx={20}
               marginX_InPx={12}
               marginTopInPx={0}
            />
            <SwitchDescription>{'Selected mode'}</SwitchDescription>
            <SimpleFlexItem>
               <CountryRegionSwitch />
            </SimpleFlexItem>
            <HorizontalSeparator
               color={DefaultGrey(1, 180)}
               widthInPx={1}
               heightInPx={20}
               marginX_InPx={12}
               marginTopInPx={0}
            />
            <SimpleFlexItem>
               <CsvExportFromApiButton
                  url={queryParams.url}
                  header={queryParams.header}
                  cancelToken={cancelToken}
                  filename={`World_${WorldMetricCsvNameMap[selectedMetric.value]}`}
                  heightInPx={22}
               />
            </SimpleFlexItem>
         </SimpleFlexRow>
         <SimpleFlexRow>
            {viewSwitch === VIEW_MODE.REGION && (
               <SimpleFlexItem>
                  <CountryRegionDisplay />
               </SimpleFlexItem>
            )}
            {viewSwitch === VIEW_MODE.REGION && selectedMetric.value === WORLDMAP_METRIC.USAGE && (
               <HorizontalSeparator
                  color={DefaultGrey(1, 180)}
                  widthInPx={1}
                  heightInPx={20}
                  marginX_InPx={12}
                  marginTopInPx={0}
               />
            )}
            {selectedMetric.value === WORLDMAP_METRIC.USAGE && (
               <SimpleFlexItem>
                  <TrafficHint
                     term={'playout or stream'}
                     hasTimeAxis={true}
                     sizePx={16}
                     hasMarginLeft={false}
                     place={'bottom'}
                  />
               </SimpleFlexItem>
            )}
         </SimpleFlexRow>
      </TwoSideFlexContainer>
   );
}

MenuControlBar.propTypes = {
   selectedMetric: PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.string,
      csv: PropTypes.string,
   }),
   queryParams: PropTypes.exact({
      url: PropTypes.string,
      header: PropTypes.object,
   }),
   onMetricSelection: PropTypes.func,
};

export default MenuControlBar;
