export const DROP_DATE_FORMAT = 'MM/DD/YYYY HH:mm:ss';

export const TROUBLE_INTERVALS = [
   { name: '30-seconds', value: 'second', urlParamName: '30seconds', format: 'HH:mm:ss', stepsInMilliSec: 30 * 1000 },
   { name: 'Minute', value: 'minute', urlParamName: 'minute', format: 'HH:mm', stepsInMilliSec: 60 * 1000 },
   // { name: 'Hour', value: 'hour', format: 'HH', stepsInMilliSec: 3600 * 1000 },
];

export const TROUBLE_INTERVALS_MAP = {
   second: TROUBLE_INTERVALS[0],
   minute: TROUBLE_INTERVALS[1],
   // hour: TROUBLE_INTERVALS[2],
};

export const MAX_RAW_MODE_TIME_RANGE_IN_SECS = 5 * 60;

export const SIMPLE_TIME_FORMAT = 'HH:mm:ss';
export const DEFAULT_DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm:ss';
export const CHRONOLOGICAL_DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const CHRONOLOGICAL_DATE_FORMAT = 'YYYY/MM/DD';
export const EXT_DATE_TIME_MILLISECS_FORMAT = 'MMM DD, YYYY HH:mm:ss:SSS';
export const TITLE_DATE_TIME_FORMAT = 'MMM DD, YYYY [at] HH:mm:ss';
export const FINE_INPUT_FORMAT = 'MMM DD, YYYY HH:mm:ss';

// RTMP/webRTC stats
export const INGEST_AUDIO_BITRATE = 'INGEST_AUDIO_BITRATE';
export const INGEST_VIDEO_BITRATE = 'INGEST_VIDEO_BITRATE';

// RTMP stats
export const INGEST_AUDIO_FRAME_LENGTH_DEVIATION = 'INGEST_AUDIO_FRAME_LENGTH_DEVIATION';
export const INGEST_VIDEO_FRAME_LENGTH_DEVIATION = 'INGEST_VIDEO_FRAME_LENGTH_DEVIATION';
export const INGEST_AUDIO_MAX_TIME_OFFSET = 'INGEST_AUDIO_MAX_TIME_OFFSET';
export const INGEST_VIDEO_MAX_TIME_OFFSET = 'INGEST_VIDEO_MAX_TIME_OFFSET';
export const INGEST_STREAM_TIME_RATIO = 'INGEST_STREAM_TIME_RATIO';
export const INGEST_AUDIO_SAMPLE_RATE_RATIO = 'INGEST_AUDIO_SAMPLE_RATE_RATIO';
export const INGEST_VIDEO_FRAME_RATE_RATIO = 'INGEST_VIDEO_FRAME_RATE_RATIO';
export const INGEST_VIDEO_FRAME_RATE_REALTIME = 'INGEST_VIDEO_FRAME_RATE_REALTIME';

// webRTC stats
export const INGEST_VIDEO_FRAME_RATE = 'INGEST_VIDEO_FRAME_RATE';
export const INGEST_VIDEO_FRAME_ENCODE_TIME = 'INGEST_VIDEO_FRAME_ENCODE_TIME';
export const INGEST_VIDEO_NACK = 'INGEST_VIDEO_NACK';

export const MAX_DURATION_IN_MS = 12 * 3600 * 1000;
