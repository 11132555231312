import { STREAM, COUNTRY } from '../../../constants/dataGroup';

export const INITIAL_TERM = STREAM;

export const DISPLAYED_NAME_OF_TERM = {
   [STREAM]: 'Stream name',
   [COUNTRY]: 'Country',
};

export const TERMS = () => [STREAM, COUNTRY];

export default {
   INITIAL_TERM,
   DISPLAYED_NAME_OF_TERM,
   TERMS,
};
