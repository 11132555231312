import moment from 'moment';

export function generateTimeAxisBasedBarChartData(data, reqStart, reqEnd, interval, isFullInterval = false) {
   const chartData = [];
   const startOfInterval = interval === 'week' ? 'isoWeek' : interval;

   const start = reqStart.clone().startOf(startOfInterval);
   const end = reqEnd.clone().startOf(startOfInterval);
   // isLastTimeIntervalFull answers whether the last interval is full
   // e.g. for full interval: end datetime is 12/24/2020 00:00 at time interval 'day'
   const isLastTimeIntervalFull = interval !== 'second' ? reqEnd.isSame(end, 'minute') : false;
   const size = isLastTimeIntervalFull || isFullInterval ? end.diff(start, interval) : end.diff(start, interval) + 1;

   let intervalStart = start.clone();
   let intervalEnd = intervalStart.clone().add(1, interval);

   for (let i = 0; i < size; i++) {
      const intervalStartUnix = intervalStart.unix();
      const intervalEndUnix = intervalEnd.unix();
      const intervalData = data.find(({ timestamp: dataUnix }) => {
         return dataUnix >= intervalStartUnix && dataUnix < intervalEndUnix;
      });
      chartData.push({
         t: intervalStartUnix * 1000,
         y: intervalData !== undefined ? intervalData.count : 0,
      });

      intervalStart = intervalEnd;
      intervalEnd = intervalStart.clone().add(1, interval);
   }

   return chartData;
}

export function generateStackedBarChartData(
   data,
   timespanObj,
   propNames,
   unitName = '',
   processingFn = (v) => v,
   isFullInterval = false,
) {
   const { gte: reqStart, lt: reqEnd, format, interval } = timespanObj;

   const startOfInterval = interval === 'week' ? 'isoWeek' : interval;

   const start = reqStart.clone().startOf(startOfInterval);
   const end = reqEnd.clone().startOf(startOfInterval);
   // isLastTimeIntervalFull answers whether the last interval is full
   // e.g. for full interval: end datetime is 12/24/2020 00:00 at time interval 'day'
   const isLastTimeIntervalFull = interval !== 'second' ? reqEnd.isSame(end, 'minute') : false;
   const size = isLastTimeIntervalFull || isFullInterval ? end.diff(start, interval) : end.diff(start, interval) + 1;

   let intervalStart = start.clone();
   let intervalEnd = intervalStart.clone().add(1, interval);

   const datasetsDatas = propNames.map(() => []);
   const timespansPerIndex = [];
   const labels = [];
   const totals = Array(propNames.length).fill(0);

   for (let i = 0; i < size; i++) {
      // labels[i] = {
      //    start: start.unix(),
      //    end: end.unix(),
      // };

      const intervalStartUnix = intervalStart.unix();
      const intervalEndUnix = intervalEnd.unix();
      const intervalData = data.find(({ timestamp: dataUnix }) => {
         return dataUnix >= intervalStartUnix && dataUnix < intervalEndUnix;
      });

      labels.push(intervalStart.format(format));
      timespansPerIndex.push({ start: intervalStart, end: intervalEnd });

      propNames.forEach((propName, idx) => {
         if (!intervalData || !intervalData[propName]) {
            datasetsDatas[idx].push(0);
         } else {
            const intervalValue = unitName ? intervalData[propName][unitName] : intervalData[propName];
            const processedValue = processingFn(intervalValue);
            datasetsDatas[idx].push(processedValue);
            totals[idx] += processedValue;
         }
      });

      intervalStart = intervalEnd;
      intervalEnd = intervalStart.clone().add(1, interval);
   }

   return { datasetsDatas, labels, totals, timespansPerIndex };
}

export function setLineChartLabels(timeseries, intervalName, format) {
   const labels = [];
   const timespansPerIndex = [];
   if (timeseries.length === 1) {
      const startAndEndMoment = moment.utc(timeseries[0].timestamp * 1000);
      labels[0] = startAndEndMoment.format(format);
      labels[1] = startAndEndMoment.format(format);
      timespansPerIndex.push({ start: startAndEndMoment, end: startAndEndMoment.clone().add(1, intervalName) });
      timespansPerIndex.push({ start: startAndEndMoment, end: startAndEndMoment.clone().add(1, intervalName) });
   } else {
      timeseries.forEach(({ timestamp }) => {
         const startMoment = moment.utc(timestamp * 1000);
         labels.push(startMoment.format(format));
         timespansPerIndex.push({ start: startMoment, end: startMoment.clone().add(1, intervalName) });
      });
   }
   return { labels, timespansPerIndex };
}
