import { IP, COUNTRY, CITY, STREAM, REFERRER } from '../../../constants/dataGroup';

export const INITIAL_TERM = STREAM;

export const DISPLAYED_NAME_OF_TERM = {
   [IP]: 'IP',
   [COUNTRY]: 'Country',
   [CITY]: 'City',
   [STREAM]: 'Stream name',
   [REFERRER]: 'Referrer',
};

export const TERMS = (isWorldmap) =>
   isWorldmap ? [STREAM, CITY, IP, REFERRER] : [STREAM, COUNTRY, CITY, IP, REFERRER];

export default {
   INITIAL_TERM,
   DISPLAYED_NAME_OF_TERM,
   TERMS,
};
