import React from 'react';
import moment from 'moment';

import { PropTypes } from 'prop-types';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import {
   SimpleHorizontalSeparator,
   RemoveBottomMargin,
   TwoSideFlexContainer,
   Description,
   SimpleFlexRow,
   SimpleFlexItem,
} from '../../../styledComponents/styledComponents';
import { IP_BLOCKLIST, SIMPLE_DATE_FORMAT, SIMPLE_TIME_FORMAT } from '../../../constants/general';
import { formatTimeNumberInt } from '../../../util/NumberFormatter';

import TableDescription from './TableDescription';

import storePropTypes from '../../../redux/store/propTypes';
import BlockingIndicator from '../common/BlockingIndicator';
import { BlockButton, CsvExportFromApiButton } from '../../common';
import { useState } from 'react';
import RowModal from './RowModal';

import { Tooltip as ReactTooltip } from 'react-tooltip/dist/react-tooltip.umd';
import { JwtAudienceOptions } from '../../../constants/AccessLevels';

const { SearchBar } = Search;

const getColumns = (concurrencyIsDesired, blockedMap, triggerBlockListRefreshFn, hasGuardianAccess) => {
   const columns = [
      {
         text: 'IP',
         dataField: 'ip',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         headerAlign: 'right',
      },
      {
         text: 'Duration/Sum',
         dataField: 'duration_sum',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         headerAlign: 'right',
         searchable: false,
         formatter: formatTimeNumberInt,
      },
      {
         text: 'Duration/Avg',
         dataField: 'duration_avg',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         headerAlign: 'right',
         searchable: false,
         formatter: formatTimeNumberInt,
      },
      {
         text: 'Sessions',
         dataField: 'session_count',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         searchable: false,
         headerAlign: 'right',
      },
      {
         text: 'Streams',
         dataField: 'stream_count',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         searchable: false,
         headerAlign: 'right',
      },
      {
         text: 'Tokens',
         dataField: 'token_count',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         searchable: false,
         headerAlign: 'right',
      },
      {
         text: 'Tags',
         dataField: 'tag_count',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         headerAlign: 'right',
         searchable: false,
      },
      {
         text: 'Provider',
         dataField: 'provider',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         headerAlign: 'right',
      },
      {
         text: 'Country',
         dataField: 'country',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         headerAlign: 'right',
      },
      {
         text: 'First stream start',
         dataField: 'stream_start',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         headerAlign: 'right',
         searchable: false,
         formatter: (value) => moment.utc(value).format(`${SIMPLE_DATE_FORMAT} ${SIMPLE_TIME_FORMAT}`),
      },
      {
         text: 'Last stream end',
         dataField: 'stream_end',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         headerAlign: 'right',
         searchable: false,
         formatter: (value) => moment.utc(value).format(`${SIMPLE_DATE_FORMAT} ${SIMPLE_TIME_FORMAT}`),
      },
      {
         text: 'Concurrency',
         dataField: 'concurrency',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         headerAlign: 'right',
         searchable: false,
         hidden: concurrencyIsDesired ? false : true,
      },
   ];

   if (hasGuardianAccess) {
      columns.push({
         text: 'blocked',
         dataField: 'blocked',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'center' },
         formatter: (cell) => (cell === 'N/A' ? 'N/A' : <BlockingIndicator isBlocked={cell} />),
         headerAlign: 'center',
         headerStyle: { width: '80px' },
         searchable: false,
      });
      columns.push({
         text: '',
         dataField: 'action',
         sort: false,
         style: { verticalAlign: 'middle', textAlign: 'center' },
         formatter: (cell) =>
            cell === '' ? (
               'N/A'
            ) : (
               <BlockButton
                  valueToBeBlocked={cell}
                  type={IP_BLOCKLIST}
                  blockedMap={blockedMap}
                  triggerBlockListRefreshFn={triggerBlockListRefreshFn}
               />
            ),
         headerAlign: 'center',
         headerStyle: { width: '60px' },
         searchable: false,
      });
   }
   return columns;
};

const TABLE_ROW_COUNT = 10;
const TOOLTIP_SHOW_DURATION_IN_MS = 750;

const tableRows = new Array(TABLE_ROW_COUNT).fill().map((_, idx) => idx);

function changeArrayAtIdx(array, idx, value) {
   const newArray = [...array];
   newArray[idx] = value;
   return newArray;
}

function ResultTable({
   data,
   concurrencyIsDesired,
   blockedMap,
   triggerBlockListRefreshFn,
   url,
   httpHeader,
   cancelToken,
}) {
   const [modal, setModal] = useState({
      toggleShow: false,
      rowData: {},
   });

   const [isOpenArray, setIsOpenArray] = useState(new Array(10).fill(false));
   const [timeoutIdArray, setTimeoutIdArray] = useState(new Array(10).fill(null));

   const rowEvents = {
      onClick: (e, rowData) => {
         setModal({ toggleShow: true, rowData });
      },
      onMouseEnter: (e, row, rowIndex) => {
         setIsOpenArray((old) => changeArrayAtIdx(old, rowIndex, true));
      },
      onMouseLeave: () => {
         setIsOpenArray(new Array(10).fill(false));
         timeoutIdArray.map((id) => clearTimeout(id));
         setTimeoutIdArray(new Array(10).fill(null));
      },
   };

   const afterShow = (idx) => () => {
      const newTimeoutId = setTimeout(() => {
         setIsOpenArray((old) => changeArrayAtIdx(old, idx, false));
      }, TOOLTIP_SHOW_DURATION_IN_MS);
      setTimeoutIdArray((old) => changeArrayAtIdx(old, idx, newTimeoutId));
   };

   const { toggleShow, rowData } = modal;

   const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
   const hasGuardianAccess = userInfo.aud.includes(JwtAudienceOptions.GUARDIAN);

   return (
      <>
         {data.length > 0 && (
            <>
               <RemoveBottomMargin>
                  <ToolkitProvider
                     keyField='ip'
                     data={data}
                     columns={getColumns(
                        concurrencyIsDesired,
                        blockedMap,
                        triggerBlockListRefreshFn,
                        hasGuardianAccess,
                     )}
                     bootstrap4
                     search
                  >
                     {(props) => (
                        <>
                           <TwoSideFlexContainer>
                              <SimpleFlexRow>
                                 <SimpleFlexItem>
                                    <Description alpha={0.5} fontSizePX={14} marginTopPx={6}>
                                       Click on a table row to open a stream break down for the respective IP.
                                    </Description>
                                 </SimpleFlexItem>
                                 <SimpleFlexItem $marginLeftInPx={10}>
                                    <CsvExportFromApiButton
                                       url={url}
                                       header={httpHeader}
                                       cancelToken={cancelToken}
                                       filename={'IP_Details'}
                                    />
                                 </SimpleFlexItem>
                              </SimpleFlexRow>

                              <SearchBar {...props.searchProps} tableId={'token'} />
                           </TwoSideFlexContainer>
                           <BootstrapTable
                              {...props.baseProps}
                              classes='fixed-table'
                              id='ipFilteringTable'
                              pagination={paginationFactory({
                                 sizePerPage: 10,
                                 hideSizePerPage: true,
                              })}
                              striped
                              hover
                              // expandRow={expandRow}
                              rowEvents={rowEvents}
                              condensed={true}
                           />
                        </>
                     )}
                  </ToolkitProvider>
                  {tableRows.map((idx) => (
                     <ReactTooltip
                        key={`tip${idx}`}
                        anchorSelect={`#ipFilteringTable tbody tr:nth-child(${idx + 1})`}
                        content='Click to show more details'
                        backgroundColor={'rgba(0,0,0,0.9)'}
                        textColor={'rgb(240,240,240)'}
                        style={{ textAlign: 'center' }}
                        isOpen={isOpenArray[idx]}
                        afterShow={afterShow(idx)}
                     />
                  ))}
               </RemoveBottomMargin>
               {!hasGuardianAccess && (
                  <Description alpha={0.5} fontSizePX={14} marginTopPx={6}>
                     NOTE: Since you do not have Guardian feature access, the option to block individual IPs is
                     disabled.
                  </Description>
               )}
               <SimpleHorizontalSeparator spacePx={20} border={'1px dotted  rgb(180,180,180)'} />
               <TableDescription />
               {toggleShow && (
                  <RowModal
                     rowData={rowData}
                     closeModal={() =>
                        setModal({
                           ...modal,
                           toggleShow: false,
                        })
                     }
                  />
               )}
            </>
         )}
      </>
   );
}

ResultTable.propTypes = {
   data: PropTypes.arrayOf(storePropTypes.ipFilteringResultRow),
   concurrencyIsDesired: PropTypes.bool,
   blockedMap: PropTypes.object,
   triggerBlockListRefreshFn: PropTypes.func,
   url: PropTypes.string,
   httpHeader: PropTypes.object,
   cancelToken: PropTypes.object,
};

export default ResultTable;
