import React from 'react';
import { Pie } from 'react-chartjs-2';

import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import roundTo from 'round-to';

import { PropTypes } from 'prop-types';
import { AbrPieBarChartOptions, chartjsColors } from '../../../../../../constants/chartjs';
import { formatTimeNumberInt } from '../../../../../../util/NumberFormatter';

const MINIMUM_SHARE_IN_PERCENT = 1;

function createChartData(data) {
   const chartData = {
      labels: [],
      datasets: [
         {
            borderWidth: 1,
            hidden: false,
            backgroundColor: [],
            borderColor: [],
            data: [],
            labels: [],
         },
      ],
   };

   const updatedChart = chartData.datasets[0];

   const { total, profiles } = data;
   const sortedProfiles = Object.keys(profiles).sort((a, b) => a - b);

   sortedProfiles
      .filter((profile) => (profiles[profile] * 100) / total > MINIMUM_SHARE_IN_PERCENT)
      .forEach((profile, idx) => {
         const selectedColor = chartjsColors[idx % chartjsColors.length];
         const playtime = profiles[profile];
         const precent = roundTo((playtime * 100) / total, 2);

         updatedChart.backgroundColor.push(hexToRgba(selectedColor, 50));
         updatedChart.borderColor.push('#EEE');
         updatedChart.data.push(playtime);
         updatedChart.labels.push(`${profile}: ${formatTimeNumberInt(playtime)} (${precent}%)`);
      });

   chartData.labels = updatedChart.labels;

   return chartData;
}

function AbrPieChart({ data, height }) {
   return <Pie options={AbrPieBarChartOptions} data={createChartData(data)} />;
}

AbrPieChart.propTypes = {
   height: PropTypes.number,
   data: PropTypes.shape({
      alpha: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      geometry: PropTypes.object,
      coor: PropTypes.arrayOf(PropTypes.number),
      regionName: PropTypes.string,
      rid: PropTypes.number,
      count: PropTypes.any,
   }),
};

AbrPieChart.defaultProps = { height: 300, data: {} };

export default AbrPieChart;
