import styled from 'styled-components';
import { LEGEND_SETTINGS, LEGEND_MARGIN } from './LegendConstants';
import { getMobileBreakPoint } from '../../../../util/BrowserTools';

export const SMALL_BUTTON_WIDTH_IN_PX = 60;
export const BIG_BUTTON_WIDTH_IN_PX = 125;

const breakPointPx = getMobileBreakPoint(1150, 1);

export const StyledLegend = styled.div`
   position: relative;
   margin: auto;
   background-color: rgba(0, 0, 0, 0.005);
   border: 0.6px solid rgba(0, 0, 0, 0.1);
   border-radius: 4px;
   padding-bottom: 10px;

   width: ${(props) => LEGEND_SETTINGS[props.metric].legendWidth}px;
   height: ${(props) =>
      LEGEND_SETTINGS[props.metric].legendHeight -
      LEGEND_SETTINGS[props.metric].scaleFieldsDeduction * props.perCountryView}px;
   background-color: white;
   display: ${(props) => (props.isHidden ? 'none' : 'block')};
   @media (min-width: ${breakPointPx}px) {
      position: absolute;
      margin-left: 3px;
      top: ${(props) =>
         props.mapHeight -
         (LEGEND_MARGIN +
            (LEGEND_SETTINGS[props.metric].legendHeight -
               LEGEND_SETTINGS[props.metric].scaleFieldsDeduction * props.perCountryView))}px;
   }
`;
StyledLegend.displayName = 'StyledLegend';

export const ButtonGroup = styled.div`
   text-align: center;
   margin: auto;
   width: ${(props) => props.widthInPx}px;
`;
ButtonGroup.displayName = 'ButtonGroup';

export const HeadLine = styled.div`
   text-align: center;
   margin-top: 10px;
   margin-bottom: 10px;
   font: 13px sans-serif;
   color: rgba(155, 155, 155, 1);
`;
HeadLine.displayName = 'HeadLine';

export const ButtonLine = styled.div`
   margin-top: 5px;
   height: 20px;
   display: flex;
   justify-content: space-between;
`;
ButtonLine.displayName = 'ButtonLine';

export const SelectButton = styled.button`
   font: 11px sans-serif;
   text-anchor: middle;
   border-radius: 4px;
   outline: none !important;
   width: ${(props) => (props.size === 'sm' ? SMALL_BUTTON_WIDTH_IN_PX : BIG_BUTTON_WIDTH_IN_PX)}px;
   border: ${(props) =>
      props.switchValue === props.name ? '0.7px solid rgba(0, 0, 0, 0.8)' : '0.6px solid rgba(0, 0, 0, 0.2)'};
   background-color: ${(props) => (props.switchValue === props.name ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0)')};
   color: ${(props) => (props.switchValue === props.name ? 'rgba(255, 255, 255, 0.95)' : 'rgba(110, 110, 110, 1)')};
   padding-inline: 5px;
`;
SelectButton.displayName = 'SelectButton';

export const Scale = styled.div`
   margin-top: 10px;
`;
Scale.displayName = 'Scale';

export const ScaleField = styled.div`
   height: 18px;
   margin: 0px auto 3px auto;
   padding-top: 1px;
   font: 14px sans-serif;
   color: rgba(35, 35, 35, 0.8);
   text-align: center;
   outline: none !important;
   width: ${(props) => props.width}px;
   background-color: ${(props) => props.bgColor};
`;
ScaleField.displayName = 'ScaleField';

export const ColoredValue = styled.span`
   color: ${(props) => props.color};
`;
ColoredValue.displayName = 'ColoredValue';
