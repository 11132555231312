import bintuAccountingConstants from './indices/BintuAccounting';
import playerMetricsConstants from './indices/MetricsPlayer';
import concurrentViewerConstants from './indices/ConcurrentViewer';
import webcasterConstants from './indices/Webcaster';

export const BINTU_ACCOUNTING = 'BintuAccounting';
export const METRICS_PLAYER = 'MetricsPlayer';
export const CONCURRENT_VIEWER_IDX = 'CONCURRENT_VIEWER_IDX';
export const WEBRTC_IDX = 'WEBRTC_IDX';

export const PI_USAGE = 'pi_usage';
export const PLAYOUT = 'playout';
export const INGEST = 'ingest';
export const MONTH2DATE = 'MONTH2DATE';
export const CONCURRENT_VIEWER = 'CONCURRENT_VIEWER';

export const PLAY_COUNT = 'PLAY_COUNT';
export const LATENCY = 'LATENCY';
export const PLAYTIME = 'PLAYTIME';
export const PLAYTIME_BREAKDOWN = 'PLAYTIME_BREAKDOWN';
export const BUFFERING_RATIO = 'BUFFERING_RATIO';
export const PLAYER_LOADING = 'PLAYER_LOADING';
export const H5LIVE_PLAYER_VERSIONS = 'H5LIVE_PLAYER_VERSIONS';
export const STARTUP_TIME = 'STARTUP_TIME';
export const ERROR_CODES = 'ERROR_CODES';
export const STOP_REASONS = 'STOP_REASONS';

export const ABR_PLAYTIME = 'abrProfilePlaytime';
export const SWITCH_COUNT = 'SWITCH_COUNT';
export const SWITCH_SUCCESS = 'SWITCH_SUCCESS';

export const PUBLISH_COUNT = 'PUBLISH_COUNT';
export const STATUS_ERROR_CODES = 'STATUS_ERROR_CODES';
export const PUBLISH_AUDIO_BITRATE = 'PUBLISH_AUDIO_BITRATE';
export const PUBLISH_VIDEO_BITRATE = 'PUBLISH_VIDEO_BITRATE';
export const INGEST_TIME = 'INGEST_TIME';
export const WEBCASTER_VERSIONS = 'WEBCASTER_VERSIONS';
export const WEBRTC_STOP_REASONS = 'WEBRTC_STOP_REASONS';

export const indexConstants = {
   [BINTU_ACCOUNTING]: bintuAccountingConstants,
   [METRICS_PLAYER]: playerMetricsConstants,
   [CONCURRENT_VIEWER_IDX]: concurrentViewerConstants,
   [WEBRTC_IDX]: webcasterConstants,
};

export const INITIAL_PROTOCOL_INDEX = 0;
