import React, { useContext } from 'react';
import { HoveredRegionContext } from './../WorldContainer';

import styled from 'styled-components';

import { TOP10 } from './Top10Constants';

import { PropTypes } from 'prop-types';
import { WORLD_REGIONS_SETTINGS } from '../WorldMapConstants';
import { getMobileBreakPoint } from '../../../../util/BrowserTools';

const breakPointPx = getMobileBreakPoint(1150, 1);

const rankColWidth = 25;
const countryNameColWidth = 140;
const halfCountryNameColWidth = countryNameColWidth / 2;
const countColWidth = 110;
const metricColWidth = 110;

const onlyOneMetricLineWidth = rankColWidth + countryNameColWidth + metricColWidth;
const twoMetricLineWidth = onlyOneMetricLineWidth + countColWidth;

const CenteredContainer = styled.div`
   display: flex;
   justify-content: space-between;
`;
CenteredContainer.displayName = 'CenteredContainer';

const ItemCenteredContainer = styled(CenteredContainer)`
   &:before {
      content: '';
   }
   &:after {
      content: '';
   }
   align-items: center;
   flex-direction: row;
   @media (max-width: ${breakPointPx}px) {
      justify-content: center;
      flex-direction: column;
   }
`;
ItemCenteredContainer.displayName = 'ItemCenteredContainer';

const LineContainer = styled(CenteredContainer)`
   width: ${(props) => (props.widthSwitch ? twoMetricLineWidth : onlyOneMetricLineWidth)}px;
   position: relative;
   ${(props) =>
      props.breakPointPx !== undefined
         ? ` @media (max-width: ${props.breakPointPx}px) {
      width: ${
         props.widthSwitch
            ? twoMetricLineWidth - halfCountryNameColWidth
            : onlyOneMetricLineWidth - halfCountryNameColWidth
      }px;
   }`
         : ''}
`;
LineContainer.displayName = 'LineContainer';

const HeadLineContainer = styled(LineContainer)`
   font-weight: 600;
   margin-bottom: 0.25rem !important;
   ${(props) =>
      props.breakPointPx !== undefined
         ? ` @media (max-width: ${props.breakPointPx}px) {
      width: ${
         props.widthSwitch
            ? twoMetricLineWidth - halfCountryNameColWidth
            : onlyOneMetricLineWidth - halfCountryNameColWidth
      }px;
   }`
         : ''}
`;

HeadLineContainer.displayName = 'HeadLineContainer';

const TableSideContainer = styled.div`
   width: ${(props) => (props.widthSwitch ? twoMetricLineWidth : onlyOneMetricLineWidth)}px;
   text-align: center;
   @media (max-width: ${breakPointPx}px) {
      &:nth-of-type(2) {
         padding-top: 7px;
      }
      &:nth-of-type(2) > ${HeadLineContainer} {
         display: none;
      }
      width: ${(props) =>
         props.widthSwitch
            ? twoMetricLineWidth - halfCountryNameColWidth
            : onlyOneMetricLineWidth - halfCountryNameColWidth}px;
   }
`;
TableSideContainer.displayName = 'TableSideContainer';

const greyTextcolor = 'rgba(0, 0, 0, 0.5)';

const Column = styled.div`
   color: ${(props) => (props.color ? props.color : greyTextcolor)};
   text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
   width: ${(props) => props.width}px;
   font-size: 13px;
   ${(props) =>
      props.breakPointPx !== undefined
         ? ` @media (max-width: ${props.breakPointPx}px) {
      width: ${props.width / 2}px;
   }`
         : ''}
`;
Column.displayName = 'Column';

const darkGreyTextcolor = 'rgba(0, 0, 0, 0.8)';

const HeadColumn = styled(Column)`
   color: ${(props) => (props.color ? props.color : darkGreyTextcolor)};
   font-size: 13px;
`;
HeadColumn.displayName = 'HeadColumn';

const LineHighlighter = styled(Column)`
   position: absolute;
   top: -2px;
   left: -5px;
   min-width: calc(100% + 10px);
   min-height: calc(100% + 4px);
   background-color: ${(props) => (props.color ? props.color : '#FFF')};
   border-radius: 5px;
`;
LineHighlighter.displayName = 'LineHighlighter';

function TableRow({ rank, country, count, figure, metric }) {
   const [hoveredWorldRegion] = useContext(HoveredRegionContext);
   const regionIsHovered = hoveredWorldRegion && hoveredWorldRegion === country;
   const highlightColor = regionIsHovered ? WORLD_REGIONS_SETTINGS[hoveredWorldRegion].backgroundColor(0.15) : '#FFF';
   const { metricUnit, countUnit } = TOP10[metric];
   const isCountAvailable = count !== undefined;

   return (
      <LineContainer
         widthSwitch={isCountAvailable}
         hoveredRegion={hoveredWorldRegion && hoveredWorldRegion === country}
         breakPointPx={breakPointPx}
      >
         {hoveredWorldRegion && hoveredWorldRegion === country && <LineHighlighter color={highlightColor} />}
         <Column width={rankColWidth} color={'rgba(0,0,0,0.7)'}>{`${rank}`}</Column>
         <Column width={countryNameColWidth} breakPointPx={breakPointPx}>{`${country}`}</Column>
         <Column width={metricColWidth} textAlign={'right'}>{`${metricUnit(figure)}`}</Column>
         {isCountAvailable && <Column width={countColWidth} textAlign={'right'}>{`${countUnit(count)}`}</Column>}
      </LineContainer>
   );
}

function TableHead({ rank, country, count, figure }) {
   const isCountAvailable = count !== undefined;

   return (
      <HeadLineContainer widthSwitch={isCountAvailable} breakPointPx={breakPointPx}>
         <HeadColumn width={rankColWidth} color={'rgba(0,0,0,0.7)'}>{`${rank}`}</HeadColumn>
         <HeadColumn width={countryNameColWidth} breakPointPx={breakPointPx}>{`${country}`}</HeadColumn>
         <HeadColumn width={metricColWidth} textAlign={'right'}>{`${figure}`}</HeadColumn>
         {isCountAvailable && <HeadColumn width={countColWidth} textAlign={'right'}>{`${count}`}</HeadColumn>}
      </HeadLineContainer>
   );
}

function TableSide({ data, metricDesc, countDesc, sideNumber, metric, areRegions }) {
   const isCountAvailable = countDesc !== undefined;
   return (
      <TableSideContainer widthSwitch={isCountAvailable}>
         <TableHead
            key={`headline-${sideNumber}`}
            rank={''}
            country={areRegions ? 'Region' : 'Country'}
            count={countDesc}
            figure={metricDesc}
         />
         {data.map((countryData) => (
            <TableRow
               key={countryData.name}
               rank={`${countryData.rank}.`}
               country={countryData.name}
               count={countryData.count}
               figure={countryData.figure}
               metric={metric}
            />
         ))}
      </TableSideContainer>
   );
}

function Top10Table({ data, metric, areRegions }) {
   const { metricDesc, countDesc } = TOP10[metric];
   const rank1To5 = data.slice(0, 5);
   const rank6to10 = data.slice(5, 10);

   return (
      <ItemCenteredContainer>
         <TableSide
            key='top1-5'
            data={rank1To5}
            metricDesc={metricDesc}
            countDesc={countDesc}
            sideNumber={1}
            metric={metric}
            areRegions={areRegions}
         />
         <TableSide
            key='top6-10'
            data={rank6to10}
            metricDesc={metricDesc}
            countDesc={countDesc}
            sideNumber={2}
            metric={metric}
            areRegions={areRegions}
         />
      </ItemCenteredContainer>
   );
}

Top10Table.propTypes = {
   data: PropTypes.arrayOf(
      PropTypes.shape({
         rank: PropTypes.number,
         name: PropTypes.string,
         figure: PropTypes.number,
         count: PropTypes.number,
      }),
   ),
   metric: PropTypes.string,
   areRegions: PropTypes.bool,
};

export default Top10Table;
