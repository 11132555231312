import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import roundTo from 'round-to';
import moment from 'moment';

import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';

import useDataFetch from '../../../../hooks/useDataFetch';

import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   RemoveBottomMargin,
   RightSide,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   TwoSideRow,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';

import { historicalUsageBarChartOptions } from '../../../../constants/chartjs';

import { Bar } from 'react-chartjs-2';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, Spinner } from '../../../common';

import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';

import { CardTitle, Col, Row } from 'reactstrap';

import numeral from 'numeral';
import BootstrapTable from 'react-bootstrap-table-next';
import { showOverflowTooltip } from '../../../common/OverflowTooltip/OverflowTooltip';

import {
   cancelTokenSelector,
   countriesSelector,
   endTimespanSelector,
   hashSelector,
   intervalTimespanSelector,
   settingsSelector,
   startTimespanSelector,
   timespanSelector,
   titleTimespanSelector,
} from '../../../../redux/selectors/selectors';

import { PropTypes } from 'prop-types';
import styled from 'styled-components';

import 'spinkit/css/spinkit.css';

import { HISTORY_VIEW } from '../../../../constants/general';
import ComponentTools from '../../../../util/ComponentTools';
import { addSeparatorAndThreeDecimalPlaces } from '../../../../util/NumberFormatter';
import ErrorDisplay from '../../ipFiltering/ErrorDisplay';

const VariantHeightRow = styled(Row)`
   margin-bottom: 30px;
   @media (min-width: 1200px) {
      margin-bottom: 0px;
   }
`;

const VariantTopMargin = styled.div`
   margin-top: -32px;
   @media (min-width: 1200px) {
      margin-top: 0px;
   }
`;

const chartOptions = historicalUsageBarChartOptions();

const blue = getStyle('--info');
const red = getStyle('--danger');

const protocolNameLengthPx = 50;

const USAGE_SETTINGS = {
   bytes: {
      unit: 'GB',
      chartLabelPlayout: 'Playout [GB]',
      chartLabelIngest: 'Ingest [GB]',
      unitConversion: (value) => value / 1e9,
      resUnitPropNames: 'bytes',
   },
   playtime: {
      unit: 'h',
      chartLabelPlayout: 'Playout [h]',
      chartLabelIngest: 'Ingest [h]',
      unitConversion: (value) => roundTo(value / 3600, 0),
      resUnitPropNames: 'seconds',
   },
};

function getTableColumns(usageSwitch) {
   return [
      {
         text: 'Month',
         dataField: 'label',
         style: { verticalAlign: 'middle', textAlign: 'center' },
         headerAlign: 'center',
         formatter: (cell) => showOverflowTooltip(cell),
      },
      {
         text: `Playout [${USAGE_SETTINGS[usageSwitch].unit}]`,
         dataField: 'playout',
         style: { verticalAlign: 'middle', textAlign: 'center' },
         headerAlign: 'center',
         formatter: (cell) => numeral(USAGE_SETTINGS[usageSwitch].unitConversion(cell)).format('0,0.000'),
      },
      {
         text: `Ingest [${USAGE_SETTINGS[usageSwitch].unit}]`,
         dataField: 'ingest',
         style: { verticalAlign: 'middle', textAlign: 'center' },
         headerAlign: 'center',
         formatter: (cell) => numeral(USAGE_SETTINGS[usageSwitch].unitConversion(cell)).format('0,0.000'),
      },
      {
         text: `Total [${USAGE_SETTINGS[usageSwitch].unit}]`,
         dataField: 'total',
         style: { verticalAlign: 'middle', textAlign: 'center' },
         headerAlign: 'center',
         formatter: (cell) => numeral(USAGE_SETTINGS[usageSwitch].unitConversion(cell)).format('0,0.000'),
      },
   ];
}

function getNewDataSets() {
   return [
      {
         label: 'Playout [GB]',
         backgroundColor: hexToRgba(red, 10),
         borderColor: red,
         borderWidth: 1,
         data: [],
         total: 0,
      },
      {
         label: 'Ingest [GB]',
         backgroundColor: hexToRgba(blue, 10),
         borderColor: blue,
         borderWidth: 1,
         data: [],
         total: 0,
      },
   ];
}

function createChartData(data, timespan, settings) {
   const usage = data.result;

   const updatedChartData = {
      labels: [],
      datasets: getNewDataSets(),
   };

   const labels = [];

   ComponentTools.setBarChartLabels(updatedChartData, labels, timespan, settings.weekType);

   const now = Date.now();

   labels.forEach((label) => {
      const start = label.start;
      const end = label.end;
      if (start < now) {
         const usageInterval = usage.find((bucket) => bucket.key >= start && bucket.key < end);

         if (usageInterval !== undefined) {
            const playoutGB = roundTo(usageInterval.playout / 1e9, 3);
            updatedChartData.datasets[0].data.push(playoutGB);
            updatedChartData.datasets[0].total += playoutGB;

            const ingestGB = roundTo(usageInterval.ingest / 1e9, 3);
            updatedChartData.datasets[1].data.push(ingestGB);
            updatedChartData.datasets[1].total += ingestGB;
         } else {
            updatedChartData.datasets[0].data.push(0);
            updatedChartData.datasets[1].data.push(0);
         }
      }
   });

   return updatedChartData;
}

function createTableData(data) {
   const usage = data.result;
   const tableData = [];

   for (let index = 0; index < usage.length; index++) {
      const dataObj = usage[index];
      const label = moment.utc(dataObj.key).format('MMMM YYYY');
      const playout = dataObj.playout;
      const ingest = dataObj.ingest;
      const total = playout + ingest;
      tableData.push({ label, playout, ingest, total });
   }

   return tableData;
}
function Usage({ height }) {
   const [chart, setChart] = useState({
      labels: [],
      datasets: getNewDataSets(),
   });

   const [tableData, setTableData] = useState([]);

   const [noData, setNoData] = useState(false);

   const hash = useSelector(hashSelector);
   const countries = useSelector(countriesSelector);

   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);
   const interval = useSelector(intervalTimespanSelector);
   const timespanTitle = useSelector(titleTimespanSelector);

   const timespan = useSelector(timespanSelector);
   const cancelToken = useSelector(cancelTokenSelector);
   const settings = useSelector(settingsSelector);
   const dispatch = useDispatch();

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, [], [], [], HISTORY_VIEW);
   const url = `/api/v2/usage/history/timeseries?from=${start.format()}&to=${end.format()}&interval=${interval}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });
   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            setChart(createChartData(data, timespan, settings));
            setTableData(createTableData(data, timespan, settings));
            setNoData(data.result.reduce((sum, dataPoint) => (sum += dataPoint.playout + dataPoint.ingest), 0) === 0);
         }
      }

      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, dispatch, timespan, settings]);

   const playoutGB = chart.datasets[0].total;
   const ingestGB = chart.datasets[1].total;
   const bothGB = playoutGB + ingestGB;

   const errorOrNoData = errorMessage || noData;

   return (
      <WidgetContainer>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <CsvExportFromApiButton
                           url={url}
                           header={header}
                           cancelToken={cancelToken}
                           filename={'Historical_Usage'}
                        />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
                  <SimpleFlexRow></SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer>
            <TwoSideRow marginRightPx={15}>
               <div>
                  <CardTitle className='mb-0'>Historical usage Playout/Ingest</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </div>
               <RightSide minWidthPx={210}>
                  <TwoSideRow setNoWidth={true} marginLeftPx={15}>
                     <RightSide minWidthPx={protocolNameLengthPx}>Playout:</RightSide>
                     <div className='text-danger'>
                        {errorMessage || isLoading ? '0 GB' : `${addSeparatorAndThreeDecimalPlaces(playoutGB)} GB`}
                     </div>
                  </TwoSideRow>
                  <TwoSideRow setNoWidth={true} marginLeftPx={15}>
                     <RightSide minWidthPx={protocolNameLengthPx}>Ingest:</RightSide>
                     <div className='text-info'>
                        {errorMessage || isLoading ? '0 GB' : `${addSeparatorAndThreeDecimalPlaces(ingestGB)} GB`}
                     </div>
                  </TwoSideRow>
                  <TwoSideRow setNoWidth={true} marginLeftPx={15}>
                     <RightSide minWidthPx={protocolNameLengthPx}>Total:</RightSide>
                     <div>{errorMessage || isLoading ? '0 GB' : `${addSeparatorAndThreeDecimalPlaces(bothGB)} GB`}</div>
                  </TwoSideRow>
               </RightSide>
            </TwoSideRow>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='12' xl='6'>
                  <VariantHeightRow>
                     <Col>
                        <ChartWrapper className='chart-wrapper' marginTopPx={14} height={height}>
                           <Spinner loading={isLoading} parentTopMarginPx={50}>
                              {errorOrNoData ? (
                                 <CenteredMsgShowing height={height}>
                                    {errorMessage ? (
                                       <ErrorDisplay error={errorMessage} msgColor={'rgb(80,80,80)'}></ErrorDisplay>
                                    ) : (
                                       'No data available'
                                    )}
                                 </CenteredMsgShowing>
                              ) : (
                                 <SetChartHeight height={height}>
                                    <Bar data={chart} options={chartOptions} />
                                 </SetChartHeight>
                              )}
                           </Spinner>
                        </ChartWrapper>
                     </Col>
                  </VariantHeightRow>
               </Col>
               <Col xs='12' sm='12' md='12' lg='12' xl='6'>
                  <Row className='pt-3'>
                     <Col>
                        <VariantTopMargin>
                           <ChartWrapper
                              className='chart-wrapper'
                              marginTopPx={5}
                              height={isLoading ? height - 10 : undefined}
                           >
                              <Spinner loading={isLoading} parentTopMarginPx={52}>
                                 {errorOrNoData ? (
                                    <CenteredMsgShowing height={height} marginTopPx={-7}>
                                       {errorMessage ? (
                                          <ErrorDisplay error={errorMessage} msgColor={'rgb(80,80,80)'}></ErrorDisplay>
                                       ) : (
                                          'No data available'
                                       )}
                                    </CenteredMsgShowing>
                                 ) : (
                                    <RemoveBottomMargin>
                                       <BootstrapTable
                                          id={'usage'}
                                          keyField='label'
                                          data={tableData}
                                          columns={getTableColumns('bytes')}
                                          bootstrap4
                                          bordered={true}
                                          classes='fixed-table'
                                          striped
                                          hover
                                       />
                                    </RemoveBottomMargin>
                                 )}
                              </Spinner>
                           </ChartWrapper>
                        </VariantTopMargin>
                     </Col>
                  </Row>
               </Col>
            </Row>
         </WidgetBodyContainer>
      </WidgetContainer>
   );
}

Usage.propTypes = {
   height: PropTypes.number,
};

Usage.defaultProps = {
   height: 300,
};

export default Usage;
