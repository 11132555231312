import {
   METRICS_PLAYER,
   LATENCY,
   PLAY_COUNT,
   PLAYTIME,
   BUFFERING_RATIO,
   PLAYER_LOADING,
   H5LIVE_PLAYER_VERSIONS,
   STARTUP_TIME,
   ERROR_CODES,
   STOP_REASONS,
   PLAYTIME_BREAKDOWN,
} from '../ZoomConstants';
import { formatTimeNumberInt, format_time_Number, getIntegerWithDecimalSeparator } from '../../../util/NumberFormatter';

const regexForAlphaCode = /\((.*?)\)/;

// NOTE: order of props e.g. in columnNames must be aligned with order of props in API response object array!
export const H5LIVE_SETTINGS = {
   // scheme:
   //    title = [function] title of the metric
   //    exIndexName = [string] used ES index (break down option availability)
   //    path = [string] metric specific API route path
   //    pathAddon = [string] additional params for path
   //    categoryLabels = [Array<string>] name of the categories (upper button bar)
   //    categoryLabelTransformFn = [function] transform category label (term)
   //    dataFieldNames = [Array<string>] props of the response
   //    columnNames = [object] key is metric prop of response, value is displayed column name in table
   //    formatters = [object] or [function]
   //             [object] key is metric prop of response, value is used formatter
   //             [function] one format fn is used for all columns/metrics
   [PLAY_COUNT]: {
      title: (term) => `Playbacks for ${term}`,
      exIndexName: METRICS_PLAYER,
      path: 'h5live/views',
      urlAddon: (term, group) => `&zoomWidget=${group}&zoomMetric=${term}`,
      categoryLabels: ['Playbacks'],
      categoryLabelTransformFn: (label, term) => `${label} for '${term}'`,
      dataFieldNames: ['views', 'uniquePlayouts'],
      columnNames: { views: 'Total count', uniquePlayouts: 'Playback session count' },
      formatters: getIntegerWithDecimalSeparator,
   },
   [PLAYTIME_BREAKDOWN]: {
      title: (term) => `Total playtime for ${term}`,
      exIndexName: METRICS_PLAYER,
      path: 'h5live/playtime',
      urlAddon: (term, group) => `&zoomWidget=${group}&zoomMetric=${term}`,
      categoryLabels: ['Playtime'],
      categoryLabelTransformFn: (label, term) => `${label} for '${term}'`,
      dataFieldNames: ['medianPlaytime', 'averagePlaytime', 'totalPlaytime', 'uniquePlayouts'],
      columnNames: {
         medianPlaytime: 'Median',
         averagePlaytime: 'Average',
         totalPlaytime: 'Total',
         uniquePlayouts: 'Playback session count',
      },
      formatters: {
         medianPlaytime: format_time_Number,
         averagePlaytime: format_time_Number,
         totalPlaytime: format_time_Number,
         uniquePlayouts: getIntegerWithDecimalSeparator,
      },
   },
   [LATENCY]: {
      title: () => 'Latency',
      exIndexName: METRICS_PLAYER,
      path: 'h5live/latency',
      categoryLabels: ['Latency'],
      dataFieldNames: ['medianLatency', 'averageLatency', 'totalPlaytime', 'uniquePlayouts'],
      columnNames: {
         medianLatency: 'Median',
         averageLatency: 'Average',
         totalPlaytime: 'Total Playtime',
         uniquePlayouts: 'Playback session count',
      },
      formatters: {
         medianLatency: format_time_Number,
         averageLatency: format_time_Number,
         totalPlaytime: formatTimeNumberInt,
         uniquePlayouts: getIntegerWithDecimalSeparator,
      },
   },
   [PLAYTIME]: {
      title: () => 'Playtime',
      exIndexName: METRICS_PLAYER,
      path: 'h5live/playtime',
      categoryLabels: ['Playtime'],
      dataFieldNames: ['medianPlaytime', 'averagePlaytime', 'totalPlaytime', 'uniquePlayouts'],
      columnNames: {
         medianPlaytime: 'Median',
         averagePlaytime: 'Average',
         totalPlaytime: 'Total',
         uniquePlayouts: 'Playback session count',
      },
      formatters: {
         medianPlaytime: format_time_Number,
         averagePlaytime: format_time_Number,
         totalPlaytime: format_time_Number,
         uniquePlayouts: getIntegerWithDecimalSeparator,
      },
   },
   [BUFFERING_RATIO]: {
      title: () => 'Buffering play ratio',
      exIndexName: METRICS_PLAYER,
      path: 'h5live/bufferingratio',
      categoryLabels: ['Playtime'],
      dataFieldNames: ['bufferingRatio', 'totalBufferingTime', 'totalPlaytime', 'uniquePlayouts'],
      columnNames: {
         bufferingRatio: 'Buffering ratio',
         totalBufferingTime: 'Total buffering time',
         totalPlaytime: 'Total playtime',
         uniquePlayouts: 'Playback session count',
      },
      formatters: {
         bufferingRatio: (cell) => cell,
         totalBufferingTime: formatTimeNumberInt,
         totalPlaytime: formatTimeNumberInt,
         uniquePlayouts: getIntegerWithDecimalSeparator,
      },
   },
   [PLAYER_LOADING]: {
      title: (term) => `Player loadings for ${term}`,
      exIndexName: METRICS_PLAYER,
      path: 'h5live/playerloadings',
      urlAddon: (term) => `&zoomMetric=${term.match(regexForAlphaCode)[1]}`,
      categoryLabels: ['Player loadings'],
      categoryLabelTransformFn: (label, term) => `${label} for '${term.split(' (')[0]}'`,
      dataFieldNames: ['playerLoadings', 'uniquePlayouts'],
      columnNames: { playerLoadings: 'Count', uniquePlayouts: 'Playback session count' },
      formatters: getIntegerWithDecimalSeparator,
   },
   [H5LIVE_PLAYER_VERSIONS]: {
      title: (term) => `Player version '${term}'`,
      exIndexName: METRICS_PLAYER,
      path: 'h5live/usedVersions',
      urlAddon: (term) => `&zoomMetric=${term}`,
      categoryLabels: ['Player version'],
      categoryLabelTransformFn: (label, term) => `${label} '${term}'`,
      dataFieldNames: ['playouts', 'totalPlaytime', 'totalBufferingTime'],
      columnNames: { playouts: 'Count', totalPlaytime: 'Total playtime', totalBufferingTime: 'Total buffering time' },
      formatters: {
         playouts: getIntegerWithDecimalSeparator,
         totalPlaytime: formatTimeNumberInt,
         totalBufferingTime: formatTimeNumberInt,
      },
   },
   [STARTUP_TIME]: {
      title: () => 'Startup time',
      exIndexName: METRICS_PLAYER,
      path: 'h5live/startuptime',
      categoryLabels: ['Startup time'],
      dataFieldNames: ['medianStartupTime', 'averageStartupTime', 'uniquePlayouts'],
      columnNames: {
         medianStartupTime: 'Median',
         averageStartupTime: 'Average',
         uniquePlayouts: 'Playback session count',
      },
      formatters: {
         medianStartupTime: (cell) => format_time_Number(cell, true),
         averageStartupTime: (cell) => format_time_Number(cell, true),
         uniquePlayouts: getIntegerWithDecimalSeparator,
      },
   },
   [ERROR_CODES]: {
      title: (term) => `Error code '${term}'`,
      exIndexName: METRICS_PLAYER,
      path: 'h5live/errorcodes',
      urlAddon: (term) => `&zoomMetric=${term}`,
      categoryLabels: ['Error code'],
      dataFieldNames: ['occurrence', 'uniquePlayouts'],
      columnNames: {
         occurrence: 'Count',
         uniquePlayouts: 'Playback session count',
      },
      formatters: getIntegerWithDecimalSeparator,
   },
   [STOP_REASONS]: {
      title: (term) => `'${term}' as the stop reason`,
      exIndexName: METRICS_PLAYER,
      path: 'h5live/stopReasons',
      urlAddon: (term) => `&zoomMetric=${term}`,
      categoryLabels: ['Stop reason'],
      categoryLabelTransformFn: (label, term) => `${label} '${term}'`,
      dataFieldNames: ['occurrence', 'totalPlaytime', 'totalBufferingTime', 'uniquePlayouts'],
      columnNames: {
         occurrence: 'Count',
         totalPlaytime: 'Total playtime',
         totalBufferingTime: 'Total buffering time',
         uniquePlayouts: 'Playback session count',
      },
      formatters: {
         occurrence: getIntegerWithDecimalSeparator,
         totalPlaytime: formatTimeNumberInt,
         totalBufferingTime: formatTimeNumberInt,
         uniquePlayouts: getIntegerWithDecimalSeparator,
      },
   },
};
