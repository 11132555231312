import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { setAlarm } from '../../../redux/actions/general';
import { cancelTokenSelector, hashSelector } from '../../../redux/selectors/selectors';

import { getHttpHeader } from '../../../util/FetchTools';
import { getSelectOptionValues } from '../../../util/UtilFunctions';
import SingleDataFetcher from '../world/Metrics/MetricTypes/DataFetcher/SingleDataFetcher';
import ResultTable from './ResultTable';

import { PropTypes } from 'prop-types';
import storePropTypes from '../../../redux/store/propTypes';
import momentPropTypes from 'react-moment-proptypes';

import { CenteredMsgShowing, StandardContainer } from '../../../styledComponents/styledComponents';
import useComponentFetch from '../../../hooks/useComponentFetch';
import ErrorDisplay from './ErrorDisplay';
import BlockListFetcher from '../../../dataFetching/BlockListFetcher';
import { IP_BLOCKLIST } from '../../../constants/general';
import useSignal from '../../../hooks/useSignal';

function TableView({
   start,
   end,
   streamNames,
   tags,
   hasRequestStarted,
   handleRequestCompleted,
   isLoading,
   setLoadingState,
   concurrencyIsDesired,
}) {
   const [blockingListTriggerFlag, triggerBlockListRefreshFn] = useSignal();
   const [blockedMap, setBlockedMap] = useState();

   const dispatch = useDispatch();

   const [result, setResult] = useState([]);
   const [tableData, setTableData] = useState([]);

   const hash = useSelector(hashSelector);
   const cancelToken = useSelector(cancelTokenSelector);

   const httpHeader = useMemo(() => getHttpHeader(hash), [hash]);

   const url = useMemo(
      () =>
         `/api/v2/usage/ip/details?from=${start.format()}&to=${end.format()}${getQueryFilter(
            getSelectOptionValues(streamNames),
            getSelectOptionValues(tags),
            concurrencyIsDesired,
         )}`,
      [streamNames, tags, concurrencyIsDesired, start, end],
   );

   const { handleResult, successful, errorMessage, data, httpStatus } = useComponentFetch({
      parentSetLoading: setLoadingState,
   });

   useEffect(() => {
      if (successful) {
         setResult(data.data);
      }

      if (errorMessage) {
         if (errorMessage instanceof axios.Cancel) {
            return;
         }
         setResult([]);
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [successful, isLoading, errorMessage, data, httpStatus, dispatch]);

   useEffect(() => {
      triggerBlockListRefreshFn();
   }, [url, httpHeader, cancelToken, triggerBlockListRefreshFn]);

   useEffect(() => {
      setTableData(addBlockState(result, blockedMap));
   }, [result, blockedMap]);

   const isPristine = errorMessage === undefined && data === undefined;
   const noData = result.length === 0;
   const errorOrNoDataOrPristine = errorMessage || noData || isPristine || isLoading;

   return (
      <>
         {hasRequestStarted && (
            <SingleDataFetcher
               url={url}
               httpHeader={httpHeader}
               cancelToken={cancelToken}
               onResult={handleResult}
               onRequestCompleted={handleRequestCompleted}
            />
         )}
         <StandardContainer $borderRadiusBLpx={7} $borderRadiusBRpx={7}>
            {errorOrNoDataOrPristine ? (
               <CenteredMsgShowing
                  height={200}
                  marginTopPx={0}
                  $fontColor={'rgb(150, 150, 150)'}
                  $bgColor={'rgb(255,255,255)'}
                  $fontSizePx={16}
               >
                  {isPristine ? (
                     <>{'Please start a request'}</>
                  ) : errorMessage ? (
                     <ErrorDisplay error={errorMessage} msgColor={'rgb(80,80,80)'} />
                  ) : data && data.data && data.data.length === 0 ? (
                     <>{'No data available'}</>
                  ) : (
                     <>{'Fetching data ...'}</>
                  )}
               </CenteredMsgShowing>
            ) : (
               <ResultTable
                  data={tableData}
                  concurrencyIsDesired={concurrencyIsDesired}
                  blockedMap={blockedMap}
                  triggerBlockListRefreshFn={triggerBlockListRefreshFn}
                  url={url}
                  httpHeader={httpHeader}
                  cancelToken={cancelToken}
               />
            )}
         </StandardContainer>
         <BlockListFetcher
            type={IP_BLOCKLIST}
            refreshTriggerFlag={blockingListTriggerFlag}
            setBlockedMap={setBlockedMap}
         />
      </>
   );
}

export function getQueryFilter(streams, stsTags, concurrency) {
   let queryFilterPart = '';
   if (streams !== undefined) {
      streams.forEach((stream) => {
         queryFilterPart += `&streams=${stream}`;
      });
   }
   if (stsTags !== undefined) {
      stsTags.forEach((tag) => {
         queryFilterPart += `&stsTags=${tag}`;
      });
   }
   if (concurrency !== undefined) {
      queryFilterPart += `&concurrency=${concurrency}`;
   }
   return queryFilterPart;
}

TableView.propTypes = {
   streamNames: PropTypes.arrayOf(storePropTypes.stdSelectOption),
   start: momentPropTypes.momentObj,
   end: momentPropTypes.momentObj,
   tags: PropTypes.arrayOf(storePropTypes.stdSelectOption),
   hasRequestStarted: PropTypes.bool,
   handleRequestCompleted: PropTypes.func,
   isLoading: PropTypes.bool,
   setLoadingState: PropTypes.func,
   concurrencyIsDesired: PropTypes.bool,
};

function addBlockState(resultList, blockedMap) {
   return blockedMap !== undefined
      ? resultList.map((row) =>
           blockedMap[row.ip] ? { ...row, blocked: true, action: row.ip } : { ...row, blocked: false, action: row.ip },
        )
      : resultList.map((row) => ({ ...row, blocked: 'N/A', action: '' }));
}

export default TableView;
