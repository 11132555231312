import React, { useState, useEffect } from 'react';
import ContainerDimensions from 'react-container-dimensions';
import styled from 'styled-components';

import SubSwitchMetric from './MetricTypes/SubSwitchMetric';
import DefaultMetric from './MetricTypes/DefaultMetric';

import WorldMapSVG from '../Map/WorldMapSVG';
import PanelSizeManager from './PanelSizeManager';

import AccessController from './AccessController';

import { WORLDMAP_METRIC, MAP_HEIGHT_WIDTH_RATIO } from '../WorldMapConstants';

import { PropTypes } from 'prop-types';

const DelayedShowContainer = styled.div`
   opacity: ${(props) => (props.delayed ? 0 : 1)};
   transition: opacity 1s ease-out;
`;
DelayedShowContainer.displayName = 'DelayedShowContainer';

function MetricsContainer({ selectedMetric }) {
   const [delayed, setDelayed] = useState(true);

   useEffect(() => {
      const timeout = setTimeout(() => setDelayed(false), 1000);
      return () => clearTimeout(timeout);
   }, []);

   return (
      <ContainerDimensions>
         <PanelSizeManager>
            {(mapWidth) => (
               <DelayedShowContainer delayed={delayed}>
                  <WorldMapSVG mapHeight={mapWidth * MAP_HEIGHT_WIDTH_RATIO} mapWidth={mapWidth} />
                  <AccessController>
                     <SubSwitchMetric
                        metricName={WORLDMAP_METRIC.PI}
                        mapWidth={mapWidth}
                        isActive={WORLDMAP_METRIC.PI === selectedMetric}
                     />
                  </AccessController>
                  <AccessController>
                     <DefaultMetric
                        metricName={WORLDMAP_METRIC.BUFFER_RATIO}
                        mapWidth={mapWidth}
                        isActive={WORLDMAP_METRIC.BUFFER_RATIO === selectedMetric}
                     />
                  </AccessController>
                  <AccessController>
                     <DefaultMetric
                        metricName={WORLDMAP_METRIC.LATENCY}
                        mapWidth={mapWidth}
                        isActive={WORLDMAP_METRIC.LATENCY === selectedMetric}
                     />
                  </AccessController>
                  <AccessController>
                     <DefaultMetric
                        metricName={WORLDMAP_METRIC.PLAYTIME}
                        mapWidth={mapWidth}
                        isActive={WORLDMAP_METRIC.PLAYTIME === selectedMetric}
                     />
                  </AccessController>
                  <AccessController>
                     <DefaultMetric
                        metricName={WORLDMAP_METRIC.USAGE}
                        mapWidth={mapWidth}
                        isActive={WORLDMAP_METRIC.USAGE === selectedMetric}
                     />
                  </AccessController>
                  <AccessController>
                     <SubSwitchMetric
                        metricName={WORLDMAP_METRIC.ABR_PLAYTIME}
                        mapWidth={mapWidth}
                        isActive={WORLDMAP_METRIC.ABR_PLAYTIME === selectedMetric}
                     />
                  </AccessController>
                  <AccessController>
                     <DefaultMetric
                        metricName={WORLDMAP_METRIC.ABR_VIEWER}
                        mapWidth={mapWidth}
                        isActive={WORLDMAP_METRIC.ABR_VIEWER === selectedMetric}
                     />
                  </AccessController>
                  <AccessController>
                     <DefaultMetric
                        metricName={WORLDMAP_METRIC.ABR_SWITCHES}
                        mapWidth={mapWidth}
                        isActive={WORLDMAP_METRIC.ABR_SWITCHES === selectedMetric}
                     />
                  </AccessController>
                  <AccessController>
                     <DefaultMetric
                        metricName={WORLDMAP_METRIC.QOE}
                        mapWidth={mapWidth}
                        isActive={WORLDMAP_METRIC.QOE === selectedMetric}
                     />
                  </AccessController>
               </DelayedShowContainer>
            )}
         </PanelSizeManager>
      </ContainerDimensions>
   );
}

MetricsContainer.propTypes = {
   selectedMetric: PropTypes.string,
};

export default MetricsContainer;
