import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button } from 'reactstrap';

import { Description, FlexRow, Notification, StaticCol } from '../../../../styledComponents/styledComponents';

import {
   hashSelector,
   cancelTokenSelector,
   troubleEndSelector,
   troubleStartSelector,
   troublePlayoutInputSelector,
   troubleModeSelector,
   troublePlayoutStreamSelector,
   troubleSelectedPlayoutSelector,
} from '../../../../redux/selectors/selectors';

import BlockSpinner from '../../../common/BlockSpinner/BlockSpinner';

import {
   setTroubleshootingPlayoutInput,
   resetTroubleshooting,
   setTroubleshootingPlayoutStream,
} from '../../../../redux/actions/general';

import { useNonInitialEffect } from '../../../../hooks/useNonInitialEffect';
import PlayoutService from './PlayoutService';

import { setAlarm } from '../../../../redux/actions/general';
import Axios from 'axios';
import { PLAYOUT } from '../../../../constants/general';
import PlayoutsTable from './PlayoutsTable';
import StreamSelection from '../StreamSelection';
import { useResetSignal } from './../general/useResetSignal';

function PlayoutSearch() {
   const dispatch = useDispatch();

   const orgaHash = useSelector(hashSelector);
   const cancelToken = useSelector(cancelTokenSelector);

   const start = useSelector(troubleStartSelector);
   const end = useSelector(troubleEndSelector);
   const mode = useSelector(troubleModeSelector);
   const modeRef = useRef();
   modeRef.current = mode;

   const [inputValue, setInputValue] = useState('');
   const userObj = useSelector(troublePlayoutInputSelector);
   const { userId } = userObj;

   const selectedStream = useSelector(troublePlayoutStreamSelector);
   const { playerId } = useSelector(troubleSelectedPlayoutSelector);
   if (playerId !== '') {
      userObj.playerId = playerId;
   }

   const [isStreamSelectionOpen, setOpenState] = useState(true);
   const [userData, setUserData] = useState();

   const [httpStatus, setHttpStatus] = useState();
   const [isLoading, setLoadingState] = useState(false);
   const [error, setError] = useState();

   const [url, setUrl] = useState(`/api/v2/troubleshooting/playout?from=${start.format()}&to=${end.format()}`);

   const [isMounting, setMountingState] = useState(true);

   const componenthasBeenMounted = useRef(false);
   const dataIsOutdated = useRef(false);
   const invalidUrlState = useRef(false);

   function resetState() {
      setLoadingState(false);
      setHttpStatus(undefined);
      setError(undefined);
      setUserData(undefined);
   }

   // changed date / interval / orga => reset
   useNonInitialEffect(() => {
      if (modeRef.current === PLAYOUT) {
         dispatch(
            resetTroubleshooting({
               ingestInput: '',
               troubleIngestStream: '',
               publishStart: '',
               publishEnd: '',
               publishFineStart: '',
               publishFineEnd: '',
               userId: '',
               troublePlayoutStream: '',
               playerId: '',
               playoutFineStart: '',
               playoutFineEnd: '',
            }),
         );
      }
      resetState();
   }, [start, end, orgaHash]);

   const resetIsInProgress = useResetSignal(start, end, orgaHash, userId, isMounting);

   // set input with store streamname (if available) at mounting
   if (isMounting) {
      if (userId !== '' && inputValue !== userId) {
         setInputValue(userId);
      }
   }

   useEffect(() => {
      setMountingState(false);
      componenthasBeenMounted.current = true;
   }, []);

   useEffect(() => {
      if (!dataIsOutdated.current && userData !== undefined) {
         // selected stream name is not contained in fetched userData
         if (invalidUrlState.current) {
            dispatch(setTroubleshootingPlayoutStream({ troublePlayoutStream: '' }, { troublePlayoutStream: '' }));
            return;
         }
         // just one stream -> no user selection needed
         if (userData.length === 1) {
            const streamName = userData[0].name;
            if (selectedStream !== streamName) {
               dispatch(
                  setTroubleshootingPlayoutStream(
                     { troublePlayoutStream: streamName },
                     { troublePlayoutStream: streamName },
                  ),
               );
            }

            setOpenState(false);
         }
         // more than one stream available
         if (userData.length > 1) {
            setOpenState(selectedStream === '');
         }
      }
   }, [userData, dispatch, selectedStream]);

   // press ENTER handler
   function handleKeyDown(event) {
      if (event.key === 'Enter') {
         handleSearchClick();
      }
   }

   // GO button handler
   function handleSearchClick() {
      const trimmedInputValue = inputValue.trim();

      if (trimmedInputValue !== '') {
         setInputValue(trimmedInputValue);
         resetState();
         dispatch(
            setTroubleshootingPlayoutInput(
               { userId: trimmedInputValue },
               {
                  userId: trimmedInputValue,
                  troublePlayoutStream: '',
                  playerId: '',
                  playoutFineStart: '',
                  playoutFineEnd: '',
               },
            ),
         );
         setUrl(`/api/v2/troubleshooting/playout?from=${start.format()}&to=${end.format()}`);
      }
   }

   useEffect(() => {
      if (error) {
         if (error instanceof Axios.Cancel) {
            return;
         }
         dispatch(setAlarm('danger', error));
         // set initialState
      }
   }, [error, dispatch]);

   function handleStreamNameResult(res) {
      const { data, httpStatus, isLoading, error } = res;
      // console.log('playout res :>> ', res);

      if (isLoading) {
         dataIsOutdated.current = true;
      } else {
         dataIsOutdated.current = false;
      }

      // check if selected stream name is contained in fetched userData
      if (
         selectedStream !== '' &&
         data !== undefined &&
         data.length > 0 &&
         data.find((stream) => stream.name === selectedStream) === undefined
      ) {
         invalidUrlState.current = true;
      }

      setUserData(data);
      setLoadingState(isLoading);
      setHttpStatus(httpStatus);
      setError(error);
   }

   // after invalid URL has been fixed
   useEffect(() => {
      if (invalidUrlState.current && selectedStream === '') {
         invalidUrlState.current = false;
      }
   }, [selectedStream]);

   const mountPlayoutsTable = userData !== undefined && userData.length > 0 && selectedStream !== '';

   return (
      <>
         <Description>IP address | User ID</Description>
         <FlexRow>
            <StaticCol width={150}>
               <Input
                  value={inputValue}
                  placeholder={'Insert an IP'}
                  onChange={(event) => setInputValue(event.target.value)}
                  onKeyDown={handleKeyDown}
               />
            </StaticCol>
            <StaticCol>
               <Button className='ml-2' color='primary' onClick={handleSearchClick} disabled={inputValue === ''}>
                  {isLoading ? (
                     <BlockSpinner
                        ballSizePx={10}
                        ballColor={'rgb(230, 230, 230)'}
                        paddingTopBottomPx={0}
                        widthPx={41.15}
                     />
                  ) : (
                     <>{'Search'}</>
                  )}
               </Button>
            </StaticCol>
         </FlexRow>
         {userId !== '' && userData && userData.length > 0 && (
            <StreamSelection
               streamIsDetermined={userData === undefined || userData.length === 1}
               isStreamSelectionOpen={isStreamSelectionOpen}
               setOpenState={setOpenState}
               selectedStream={selectedStream}
               userData={userData}
               setStream={(streamName) =>
                  dispatch(setTroubleshootingPlayoutStream(getStreamPayload(streamName), getStreamPayload(streamName)))
               }
            />
         )}
         {error || (userData && userData.length === 0) ? (
            <Notification>
               {error ? `Error occurred: ${error.message ? error.message : error}` : 'No player metrics available'}
            </Notification>
         ) : (
            mountPlayoutsTable &&
            !invalidUrlState.current && (
               <PlayoutsTable
                  httpStatus={httpStatus}
                  streamResponse={userData.find((stream) => stream.name === selectedStream)}
               />
            )
         )}
         {componenthasBeenMounted.current && userId !== '' && !resetIsInProgress && (
            <PlayoutService
               userId={userObj}
               url={url}
               orgaHash={orgaHash}
               cancelToken={cancelToken}
               onResult={handleStreamNameResult}
            />
         )}
      </>
   );
}

function getStreamPayload(streamName) {
   return {
      troublePlayoutStream: streamName,
      troubleSelectedPlayout: {
         playerId: '',
         playoutFineStart: '',
         playoutFineEnd: '',
      },
   };
}

export default PlayoutSearch;
