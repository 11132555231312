import React, { useEffect, useState } from 'react';
import moment from 'moment';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { requestSettings } from './RequestConstants';
import { progressSettings } from './ProgressConstants';
import { filterSelector, hashSelector } from '../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '../../../redux/actions/general';
import { AppSwitch } from '@coreui/react';
import { TwoSideFlexContainer } from '../../../styledComponents/styledComponents';
import { Col, Row } from 'reactstrap';
import { format_2_digits_zero_sensitive, roundToGb } from '../../../util/NumberFormatter';
import { TableHeaderHint } from '../Hints/TableHeaderHint';
import { PropTypes } from 'prop-types';
import DataTable from './DataTable';
import { showOverflowTooltip, showAlwaysOverflowTooltip } from '../OverflowTooltip/OverflowTooltip';
import { Tooltip as ReactTooltip } from 'react-tooltip/dist/react-tooltip.umd';
import ReactDOMServer from 'react-dom/server';

const { SearchBar } = Search;

const progressHintText = ReactDOMServer.renderToStaticMarkup(
   <>
      <div>The traffic of the current month is compared to the previous month at the same day and time.</div>
      <div>This leads to a more precise guess of the current performance.</div>
   </>,
);

const getColumnsOptions = (totals, showCourses, byteFormatter) => {
   const colSettings = [
      {
         text: 'hiddenKey',
         dataField: 'hiddenKey',
         hidden: 'true',
      },
      {
         text: 'Name',
         dataField: 'name',
         sort: true,
         align: 'right',
         headerAlign: 'right',
      },
      {
         text: 'Hash',
         dataField: 'hash',
         sort: true,
      },
   ];

   progressSettings.forEach((progressCol, idx) => {
      colSettings.push({
         text: progressCol.tableHeaderText(moment.utc()),
         dataField: progressCol.shortDesc,
         sort: true,
         onSort: sortByField(progressCol.shortDesc),
         align: 'center',
         headerAlign: 'center',
         style: formatCellStyle,
         formatter: progressFormatter(totals[progressCol.currentRefValues]),
         headerFormatter: (col) =>
            idx > 0 ? col.text : <TableHeaderHint originColText={col.text} hintText={progressHintText} />,
         hidden: !showCourses,
      });
   });

   requestSettings.forEach((col) => {
      if (col.tableHeaderText) {
         colSettings.push({
            text: col.tableHeaderText,
            dataField: col.shortDesc,
            sort: true,
            onSort: sortByField(col.shortDesc),
            align: 'center',
            headerAlign: 'center',
            formatter: byteFormatter(totals[col.shortDesc]),
            headerFormatter: (col) => showAlwaysOverflowTooltip(col.text),
            hidden: showCourses,
         });
      }
   });

   colSettings.push({
      text: 'Ratio player/ server metrics current month',
      dataField: 'ratios',
      sort: true,
      onSort: sortByField('ratios'),
      align: 'center',
      headerAlign: 'center',
      formatter: (col) => (col === undefined ? '-' : showOverflowTooltip(format_2_digits_zero_sensitive(col))),
      headerFormatter: (col) => showAlwaysOverflowTooltip(col.text),
      hidden: showCourses,
   });

   colSettings.push({
      text: 'Created at',
      dataField: 'createdAt',
      align: 'center',
      headerAlign: 'center',
      sort: true,
      onSort: sortByField('createdAt', 'date'),
      headerFormatter: (col) => showAlwaysOverflowTooltip(col.text),
   });

   colSettings.push({
      text: 'Access level',
      dataField: 'access',
      align: 'center',
      headerAlign: 'center',
      sort: true,
      headerFormatter: (col) => showAlwaysOverflowTooltip(col.text),
   });

   colSettings.push({
      dataField: 'emails',
      hidden: true,
   });

   return colSettings;
};

function progressFormatter(total) {
   return (cell) => {
      if (cell === undefined) return '-';
      let newCell = cell.toFixed(2);
      newCell = newCell > 0 ? '+' + newCell : +newCell === 0 ? 0 : newCell;
      return showOverflowTooltip(`${newCell} % (${roundToGb((cell * total) / 100)} GB)`);
   };
}

function byteFormatter(totalValue) {
   return (cell, row) => {
      if (cell === undefined) return '-';
      const bytes = +cell;
      const percentage = (100 * bytes) / totalValue;
      const processedPercentage = bytes === 0 ? 0 : percentage < 0.01 ? '< 0.01' : percentage.toFixed(2);
      return showOverflowTooltip(`${roundToGb(bytes)} ${row.hash === '*' ? '' : ` (${processedPercentage}%)`}`);
   };
}

function formatCellStyle(cell) {
   return cell === undefined
      ? {}
      : {
           backgroundColor: cell >= 0 ? `rgba(0, 210, 0, ${cell / 100})` : `rgba(200, 0, 0, ${Math.abs(cell) / 100})`,
        };
}

function sortByField(field, selectedType = 'number') {
   return (a, b, order) => {
      if (a === undefined || b === undefined) return 0;
      if (selectedType === 'date') {
         let aDate = moment(a[field], 'MMMM Do YYYY');
         let bDate = moment(b[field], 'MMMM Do YYYY');
         if (!aDate.isValid() || !bDate.isValid()) {
            if (process.env.NODE_ENV === 'development') {
               console.error('Something wrong with date ' + a[field] + ' or ' + b[field]);
            }
         } else {
            if (order === 'asc') {
               return aDate.isAfter(bDate) ? 1 : -1;
            } else {
               return aDate.isAfter(bDate) ? -1 : 1;
            }
         }
      } else if (selectedType === 'number') {
         if (order === 'asc') {
            return a[field] - b[field];
         } else {
            return b[field] - a[field];
         }
      }

      return 0;
   };
}

function FilterTable({ onOrgaSelection }) {
   const filteredHash = useSelector(hashSelector);
   const filter = useSelector(filterSelector);
   const dispatch = useDispatch();

   const [tableData, setTableData] = useState([]);
   const [trialTableData, setTrialTableData] = useState([]);
   const [totalUsageData, setTotalUsageData] = useState({});

   const [onlyTrial, setOnlyTrialState] = useState(false);
   const [showCourses, setShowCoursesState] = useState(false);

   useEffect(() => {
      if (tableData.length > 0) {
         const translatedHash = filteredHash === '' ? '*' : filteredHash;
         const selectedRow = tableData.filter((company) => company.hash === translatedHash);
         onOrgaSelection(selectedRow);
      }
   }, [tableData, filteredHash, onOrgaSelection]);

   function selectHash(e, row) {
      const translatedHash = filteredHash === '' ? '*' : filteredHash;
      const hash = row.hash === '*' ? '' : row.hash;
      const name = row.hash === '*' ? '' : row.name;
      if (translatedHash !== row.hash) {
         dispatch(setFilter({ ...filter, hash, name }, { hash, name, streamNames: [] }));
      }
   }

   function highlightSelected(row) {
      const translatedHash = filteredHash === '' ? '*' : filteredHash;
      // Give a tr bg-primary as class-name if either a hash is selected and the hash of the current row equals the selected one or not hash is selected and the hash of the current row is "*" (All organizations)
      return row.hash === translatedHash ? 'bg-primary' : '';
   }

   return (
      <Row>
         <Col>
            <ToolkitProvider
               keyField='hiddenKey'
               data={onlyTrial ? trialTableData : tableData}
               columns={getColumnsOptions(totalUsageData, showCourses, byteFormatter)}
               search
               bootstrap4
            >
               {(props) => (
                  <>
                     <TwoSideFlexContainer>
                        <div>
                           <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              <div className='mt-1 mr-2'>{'Show only trial organizations'}</div>
                              <div style={{ paddingRight: '50px' }}>
                                 <AppSwitch
                                    className={'mx-1'}
                                    color={'primary'}
                                    label
                                    checked={onlyTrial}
                                    onChange={() => setOnlyTrialState(!onlyTrial)}
                                 />
                              </div>

                              <div className='mt-1 mr-2'>
                                 {'Show progress'}
                                 <i
                                    data-tooltip-id='calculation'
                                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                       <>
                                          <div>
                                             Progress is calculated by subtracting the previous month&apos;s traffic
                                             data of a certain month&apos;s traffic data.
                                          </div>
                                          <div>
                                             Its result is the difference in gigabytes, which is compared to the certain
                                             month&apos;s total amount of traffic data.
                                          </div>
                                          <div>
                                             That share is shown in percentage. This way larger customers are better
                                             represented.
                                          </div>
                                       </>,
                                    )}
                                    className='bi bi-info-circle ml-1'
                                 ></i>
                                 <ReactTooltip
                                    id='calculation'
                                    place={'top'}
                                    className={'reduced-line-height bring-on-top'}
                                    backgroundColor={'rgba(0,0,0,0.9)'}
                                    textColor={'rgb(240,240,240)'}
                                    style={{ textAlign: 'center' }}
                                 />
                              </div>
                              <AppSwitch
                                 className={'mx-1'}
                                 color={'primary'}
                                 label
                                 checked={showCourses}
                                 onChange={() => setShowCoursesState(!showCourses)}
                              />
                           </div>
                        </div>
                        <SearchBar {...props.searchProps} tableId={'filterTable'} />
                     </TwoSideFlexContainer>
                     <BootstrapTable
                        {...props.baseProps}
                        // classes='fixed-table'
                        pagination={paginationFactory({
                           sizePerPage: 5,
                           sizePerPageList: [
                              { text: '5', value: 5 },
                              { text: '10', value: 10 },
                              { text: '15', value: 15 },
                              { text: '20', value: 20 },
                           ],
                        })}
                        striped
                        hover
                        rowEvents={{ onClick: selectHash }}
                        defaultSortDirection={'desc'}
                        rowClasses={highlightSelected}
                        id={'filterTable'}
                        noDataIndication={() => 'There is no data to display'}
                     />
                  </>
               )}
            </ToolkitProvider>
            <DataTable
               setTableData={setTableData}
               setTrialTableData={setTrialTableData}
               setTotalUsageData={setTotalUsageData}
            />
         </Col>
      </Row>
   );
}

FilterTable.propTypes = {
   onOrgaSelection: PropTypes.func,
};

export default FilterTable;
