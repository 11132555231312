import React from 'react';
import { INFO_BOX_SETTINGS } from '../DataCircleConstants';
import { QuantityDisplayLine } from './ContentSubComponents';

import { format_0dot00_Number } from '../../../../../util/NumberFormatter';
import { PI_OPTIONS } from '../../WorldMapConstants';

function PiContent({ metric, countryData, subSwitch, total, maxHeatValue }) {
   const primarySettingIdx = 0;
   const { indent, colorFn, valueFormatFn } = INFO_BOX_SETTINGS[metric][primarySettingIdx];
   const numberFormatFn = (value) => valueFormatFn(value, 1e3);

   const widthInfo =
      subSwitch === PI_OPTIONS.BOTH
         ? getBothValueWidth(countryData, numberFormatFn)
         : getSingleValueWidth(countryData, numberFormatFn);

   return (
      <>
         {subSwitch === PI_OPTIONS.BOTH && (
            <>
               {countryData.playout > 0 && (
                  <PlayoutLine
                     playouts={countryData.playout}
                     widthInfo={widthInfo}
                     total={total}
                     maxHeatValue={maxHeatValue}
                     indent={indent}
                     colorFn={colorFn}
                     numberFormatFn={numberFormatFn}
                  />
               )}
               {countryData.ingest > 0 && (
                  <IngestLine
                     ingests={countryData.ingest}
                     widthInfo={widthInfo}
                     total={total}
                     maxHeatValue={maxHeatValue}
                     indent={indent}
                     colorFn={colorFn}
                     numberFormatFn={numberFormatFn}
                  />
               )}
            </>
         )}
         {subSwitch === PI_OPTIONS.INGESTS && (
            <IngestLine
               ingests={countryData.total}
               widthInfo={widthInfo}
               total={total}
               maxHeatValue={maxHeatValue}
               indent={indent}
               colorFn={colorFn}
               numberFormatFn={numberFormatFn}
            />
         )}
         {subSwitch === PI_OPTIONS.PLAYOUTS && (
            <PlayoutLine
               playouts={countryData.total}
               widthInfo={widthInfo}
               total={total}
               maxHeatValue={maxHeatValue}
               indent={indent}
               colorFn={colorFn}
               numberFormatFn={numberFormatFn}
            />
         )}
      </>
   );
}

function IngestLine({ ingests, widthInfo, total, maxHeatValue, indent, colorFn, numberFormatFn }) {
   const valueProps = {
      color: colorFn(ingests, maxHeatValue, 1, true),
      figure: numberFormatFn(ingests),
      figureWidth: widthInfo,
      percentValue: `(${format_0dot00_Number((100 * ingests) / total)} %)`,
   };

   return <QuantityDisplayLine key={'ingest'} indent={indent} description={'Ingest'} valueProps={valueProps} />;
}

function PlayoutLine({ playouts, widthInfo, total, maxHeatValue, indent, colorFn, numberFormatFn }) {
   const valueProps = {
      color: colorFn(playouts, maxHeatValue, 1, true),
      figure: numberFormatFn(playouts),
      figureWidth: widthInfo,
      percentValue: `(${format_0dot00_Number((100 * playouts) / total)} %)`,
   };

   return <QuantityDisplayLine key={'playout'} indent={indent} description={'Playout'} valueProps={valueProps} />;
}

function getBothValueWidth(data, numberFormatFn) {
   const CHAR_WIDTH = 10;
   const playoutValueWidth = data.playout ? numberFormatFn(data.playout).toString() : '';
   const ingestValueWidth = data.ingest ? numberFormatFn(data.ingest).toString() : '';
   return Math.max(playoutValueWidth.length, ingestValueWidth.length, 3) * CHAR_WIDTH;
}

function getSingleValueWidth(data, numberFormatFn) {
   const CHAR_WIDTH = 10;
   const numberString = numberFormatFn(data.total).toString();
   return Math.max(numberString.length, 3) * CHAR_WIDTH;
}

export default PiContent;
