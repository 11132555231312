import React, { useMemo } from 'react';

import useDataFetch from '../../../hooks/useDataFetch';
import { getHttpHeader } from '../../../util/FetchTools';

import storePropTypes from '../../../redux/store/propTypes';
import { PropTypes } from 'prop-types';

function StreamNameService({ streamName, url, orgaHash, cancelToken, onResult }) {
   const body = streamName;
   const header = useMemo(() => getHttpHeader(orgaHash), [orgaHash]);

   const { successful, isLoading, errorMessage, data } = useDataFetch(url, {
      method: 'POST',
      body,
      header,
      cancelToken,
   });

   if (successful) {
      onResult({ valid: data.valid, isLoading, info: data.info, created: data.created, untouched: false });
   } else if (errorMessage) {
      onResult({ isLoading, error: errorMessage, untouched: false });
   } else {
      onResult({ untouched: true, isLoading });
   }

   return <></>;
}

StreamNameService.propTypes = {
   streamName: PropTypes.object,
   url: PropTypes.string,
   orgaHash: PropTypes.string,
   cancelToken: storePropTypes.cancelToken,
   onResult: PropTypes.func,
};

export default StreamNameService;
