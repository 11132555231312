import { ABR_PLAYTIME, METRICS_PLAYER, SWITCH_COUNT, SWITCH_SUCCESS } from '../ZoomConstants';
import {
   formatTimeNumberInt,
   format_0dot00_Number,
   format_time_Number,
   getIntegerWithDecimalSeparator,
} from '../../../util/NumberFormatter';
import { capitalizeFirstLetter } from '../../../util/UtilFunctions';

// NOTE: order of props e.g. in columnNames must be aligned with order of props in API response object array!
export const ABR_SETTINGS = {
   // scheme:
   //    title = [function] title of the metric
   //    exIndexName = [string] used ES index (break down option availability)
   //    path = [string] metric specific API route path
   //    pathAddon = [string] additional params for path
   //    categoryLabels = [Array<string>] name of the categories (upper button bar)
   //    categoryLabelTransformFn = [function] transform category label (term)
   //    dataFieldNames = [Array<string>] props of the response
   //    columnNames = [object] key is metric prop of response, value is displayed column name in table
   //    formatters = [object] or [function]
   //             [object] key is metric prop of response, value is used formatter
   //             [function] one format fn is used for all columns/metrics
   [ABR_PLAYTIME]: {
      title: (profile) => `ABR Profile '${profile}'`,
      exIndexName: METRICS_PLAYER,
      path: 'abr/profiles/use',
      urlAddon: (profile) => `&zoomMetric=${profile}`,
      categoryLabels: ['Profile'],
      categoryLabelTransformFn: (label, term) => `${label} '${term}'`,
      dataFieldNames: ['totalPlaytime', 'averageLatency', 'bufferingRatio'],
      columnNames: {
         totalPlaytime: 'Total playtime',
         averageLatency: 'Average latency',
         bufferingRatio: 'Buffering ratio',
      },
      formatters: {
         totalPlaytime: formatTimeNumberInt,
         averageLatency: format_time_Number,
         bufferingRatio: format_0dot00_Number,
      },
   },
   [SWITCH_COUNT]: {
      title: (term) => `${term === 'total' ? 'up/down' : term} switches`,
      exIndexName: METRICS_PLAYER,
      path: 'abr/switches/count',
      urlAddon: (term) => `&zoomMetric=${term}`,
      categoryLabels: ['switches'],
      categoryLabelTransformFn: (label, term) =>
         `${term === 'total' ? 'up/down' : capitalizeFirstLetter(term)} ${label}`,
      dataFieldNames: ['switches', 'uniquePlayouts'],
      columnNames: { switches: 'Total count', uniquePlayouts: 'Playback session count' },
      formatters: getIntegerWithDecimalSeparator,
   },
   [SWITCH_SUCCESS]: {
      title: (term) => `${term === 'success' ? 'successful' : 'failed'} switches`,
      exIndexName: METRICS_PLAYER,
      path: 'abr/switches/success',
      urlAddon: (term) => `&zoomMetric=${term}`,
      categoryLabels: ['switches'],
      categoryLabelTransformFn: (label, term) => `${term === 'success' ? 'Successful' : 'Failed'} ${label}`,
      dataFieldNames: ['switches', 'uniquePlayouts'],
      columnNames: { switches: 'Total count', uniquePlayouts: 'Playback session count' },
      formatters: getIntegerWithDecimalSeparator,
   },
};
