import { WORLDMAP_METRIC } from '../WorldMapConstants';

import {
   formatCountNumber,
   roundToIntWithPrecision,
   roundUsage,
   formatTimeLegend,
   formatTimeNumberInt,
} from '../../../../util/NumberFormatter';

const ROUNDING_THRESHOLD = 1e7;

export const SELECT_3BUTTON_LEGEND_HEIGHT = 255;
export const SELECT_LEGEND_HEIGHT = 205;
export const SELECT_LEGEND_WIDTH = 135;

export const TRAFFIC_LEGEND_1HEADLINE_HEIGHT = 105;
export const TRAFFIC_LEGEND_1HEADLINE_WITH_TOTAL_HEIGHT = 130;
export const TRAFFIC_LEGEND_3HEADLINES_HEIGHT = 130;

export const DEFAULT_LEGEND_HEIGHT = 130;
export const DEFAULT_WITH_TOTAL_LEGEND_HEIGHT = 152;
export const TRAFFIC_LEGEND_WIDTH = 156;

export const GREEN_RED_LEGEND_HEIGHT = 217;

export const SCALE_MARGIN = 40;

export const LEGEND_MARGIN = 5;

export const GREEN_RED_SCALEFIEDLS_DEDUCTION = 157;
export const TRAFFIC_SCALEFIEDLS_DEDUCTION = 70;
export const HEAT_SCALEFIEDLS_DEDUCTION = 92;

export const LEGEND_SETTINGS = {
   [WORLDMAP_METRIC.PI]: {
      legendHeight: SELECT_LEGEND_HEIGHT,
      scaleFieldsDeduction: HEAT_SCALEFIEDLS_DEDUCTION - 2,
      legendWidth: SELECT_LEGEND_WIDTH,
      scaleFieldWidth: SELECT_LEGEND_WIDTH - SCALE_MARGIN,
      numberFormat: (value) => formatCountNumber(value),
      valueManipulation: (value) => `${value}`,
      totalText: 'Total: ',
      totalValueFormat: (value) => formatCountNumber(value, ROUNDING_THRESHOLD),
   },
   [WORLDMAP_METRIC.BUFFER_RATIO]: {
      legendHeight: TRAFFIC_LEGEND_1HEADLINE_WITH_TOTAL_HEIGHT,
      scaleFieldsDeduction: TRAFFIC_SCALEFIEDLS_DEDUCTION,
      legendWidth: TRAFFIC_LEGEND_WIDTH,
      scaleFieldWidth: TRAFFIC_LEGEND_WIDTH - SCALE_MARGIN,
      headline: 'Buffering Play Ratio',
      valueManipulation: (value) => `${value} %`,
      totalText: 'Total playtime: ',
      totalValueFormat: formatTimeNumberInt,
   },
   [WORLDMAP_METRIC.LATENCY]: {
      legendHeight: TRAFFIC_LEGEND_1HEADLINE_WITH_TOTAL_HEIGHT,
      scaleFieldsDeduction: TRAFFIC_SCALEFIEDLS_DEDUCTION,
      legendWidth: TRAFFIC_LEGEND_WIDTH,
      scaleFieldWidth: TRAFFIC_LEGEND_WIDTH - SCALE_MARGIN,
      headline: 'Latency',
      valueManipulation: (value) => `${value} s`,
      totalText: 'Total unique playbacks: ',
      totalValueFormat: (value) => formatCountNumber(value, ROUNDING_THRESHOLD),
   },
   [WORLDMAP_METRIC.PLAYTIME]: {
      legendHeight: DEFAULT_WITH_TOTAL_LEGEND_HEIGHT,
      scaleFieldsDeduction: HEAT_SCALEFIEDLS_DEDUCTION,
      legendWidth: TRAFFIC_LEGEND_WIDTH,
      scaleFieldWidth: TRAFFIC_LEGEND_WIDTH - SCALE_MARGIN,
      numberFormat: (value) => roundToIntWithPrecision(value, -2),
      headline: 'Average playtime',
      valueManipulation: (value) => `${value} s`,
      totalText: 'Total unique playbacks: ',
      totalValueFormat: (value) => formatCountNumber(value, ROUNDING_THRESHOLD),
   },
   [WORLDMAP_METRIC.USAGE]: {
      legendHeight: DEFAULT_WITH_TOTAL_LEGEND_HEIGHT,
      scaleFieldsDeduction: HEAT_SCALEFIEDLS_DEDUCTION,
      legendWidth: TRAFFIC_LEGEND_WIDTH,
      scaleFieldWidth: TRAFFIC_LEGEND_WIDTH - SCALE_MARGIN,
      numberFormat: roundUsage,
      headline: 'Playout + Ingest',
      valueManipulation: (value) => `${value}`,
      totalText: 'Total usage: ',
      totalValueFormat: roundUsage,
   },
   [WORLDMAP_METRIC.ABR_PLAYTIME]: {
      legendHeight: SELECT_3BUTTON_LEGEND_HEIGHT + 3,
      scaleFieldsDeduction: HEAT_SCALEFIEDLS_DEDUCTION,
      legendWidth: SELECT_LEGEND_WIDTH,
      scaleFieldWidth: TRAFFIC_LEGEND_WIDTH - SCALE_MARGIN,
      numberFormat: formatTimeLegend,
      headline: 'Playtime',
      valueManipulation: (value) => `${value}`,
      totalText: 'Total playtime: ',
      totalValueFormat: formatTimeNumberInt,
   },
   [WORLDMAP_METRIC.ABR_VIEWER]: {
      legendHeight: DEFAULT_WITH_TOTAL_LEGEND_HEIGHT,
      scaleFieldsDeduction: HEAT_SCALEFIEDLS_DEDUCTION,
      legendWidth: TRAFFIC_LEGEND_WIDTH,
      scaleFieldWidth: TRAFFIC_LEGEND_WIDTH - SCALE_MARGIN,
      numberFormat: (value) => formatCountNumber(value),
      headline: 'Viewer',
      valueManipulation: (value) => `${value}`,
      totalText: 'Total viewers: ',
      totalValueFormat: (value) => formatCountNumber(value, ROUNDING_THRESHOLD),
   },
   [WORLDMAP_METRIC.ABR_SWITCHES]: {
      legendHeight: DEFAULT_WITH_TOTAL_LEGEND_HEIGHT,
      scaleFieldsDeduction: HEAT_SCALEFIEDLS_DEDUCTION,
      legendWidth: TRAFFIC_LEGEND_WIDTH,
      scaleFieldWidth: TRAFFIC_LEGEND_WIDTH - SCALE_MARGIN,
      numberFormat: (value) => formatCountNumber(value),
      headline: 'Switches',
      valueManipulation: (value) => `${value}`,
      totalText: 'Total switches: ',
      totalValueFormat: (value) => formatCountNumber(value, ROUNDING_THRESHOLD),
   },
   [WORLDMAP_METRIC.QOE]: {
      legendHeight: GREEN_RED_LEGEND_HEIGHT,
      scaleFieldsDeduction: GREEN_RED_SCALEFIEDLS_DEDUCTION,
      legendWidth: TRAFFIC_LEGEND_WIDTH,
      scaleFieldWidth: TRAFFIC_LEGEND_WIDTH - SCALE_MARGIN,
      numberFormat: (value) => roundToIntWithPrecision(value, 0),
      headline: 'QoE Score',
      valueManipulation: (value) => `${value}`,
      min: 0,
      max: 100,
      totalText: 'Total unique playbacks: ',
      totalValueFormat: (value) => formatCountNumber(value, ROUNDING_THRESHOLD),
   },
};
