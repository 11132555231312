import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useDataFetch from '../../../../hooks/useDataFetch';
import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';

import ContainerDimensions from 'react-container-dimensions';
import { setAlarm } from '../../../../redux/actions/general';
import { Spinner } from '../../../common';

import { CardFooter, CardTitle, Col, Row } from 'reactstrap';

import QoeHistograph from './QoeHistograph';

import { PropTypes } from 'prop-types';

import {
   cancelTokenSelector,
   countriesSelector,
   endTimespanSelector,
   eventsSelector,
   hashSelector,
   startTimespanSelector,
   streamNamesSelector,
   tagsSelector,
   titleTimespanSelector,
} from './../../../../redux/selectors/selectors';

import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';

import { QOE_VIEW } from '../../../../constants/general';

function StreamQoE({ height }) {
   const [chartData, setChartData] = useState([]);
   const [resultCount, setResultCount] = useState(0);

   const hash = useSelector(hashSelector);
   const countries = useSelector(countriesSelector);
   const tags = useSelector(tagsSelector);
   const events = useSelector(eventsSelector);
   const streamNames = useSelector(streamNamesSelector);

   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);
   const timespanTitle = useSelector(titleTimespanSelector);

   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const handleStreamSelection = () => {
      // >>>> just a showcase implementation:
      // setFilter({
      //     ...filter,
      //     countries: [{value: "DE", label: "Germany"}]
      // });
   };

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, events, streamNames, QOE_VIEW);
   const url = `/api/v2/qoe/streams?from=${start.format()}&to=${end.format()}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            setChartData(data);
            setResultCount(data.length);
         }
      }

      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, dispatch]);

   const errorOrNoData = errorMessage || resultCount === 0;
   const issueMsg = errorMessage ? errorMessage.message : 'No data available';

   return (
      <WidgetContainer $minHeightPx={height}>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow></SimpleFlexRow>
                  <SimpleFlexRow></SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer>
            <Row>
               <Col>
                  <CardTitle className='mb-0'>Quality of Experience histogram</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </Col>
            </Row>
            <ChartWrapper className='chart-wrapper' height={isLoading ? height + 7 : undefined} marginTopPx={0}>
               <Spinner loading={isLoading} parentTopMarginPx={40}>
                  {errorOrNoData ? (
                     <CenteredMsgShowing height={height - 8} marginTopPx={15}>
                        {issueMsg}
                     </CenteredMsgShowing>
                  ) : (
                     <ContainerDimensions>
                        <QoeHistograph
                           chartHeight={407}
                           qoeData={chartData}
                           dataProvided={!isLoading}
                           onStreamSelect={handleStreamSelection}
                        />
                     </ContainerDimensions>
                  )}
               </Spinner>
            </ChartWrapper>
         </WidgetBodyContainer>
         <CardFooter>
            <Row>
               <Col className='text-right'>Found streams:</Col>
               <Col>{isLoading || errorMessage ? '0' : resultCount}</Col>
            </Row>
         </CardFooter>
      </WidgetContainer>
   );
}

StreamQoE.propTypes = {
   height: PropTypes.number,
};

StreamQoE.defaultProps = {
   height: 300,
};

export default StreamQoE;
