import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DefaultFooter extends Component {
   render() {
      // eslint-disable-next-line
      const { children, ...attributes } = this.props;

      return (
         <React.Fragment>
            <span>v2.25.1 - &copy; 2019-2024 Nanocosmos GmbH</span>
         </React.Fragment>
      );
   }
}

DefaultFooter.propTypes = {
   children: PropTypes.node,
};

DefaultFooter.defaultProps = {};

export default DefaultFooter;
