import React, { useEffect, useMemo } from 'react';
import useDataFetch from '../../../hooks/useDataFetch';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { setAlarm } from '../../../redux/actions/general';
import axios from 'axios';

function DataColumn({ baseUrl, cancelToken, start, end, refresh, notifyCompletedRequest, dataArray, accessIdx }) {
   const dispatch = useDispatch();

   const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
   const accessToken = userInfo.token;

   const header = useMemo(
      () => ({
         'x-access-token': accessToken,
         Accept: 'application/json',
         'Content-Type': 'application/json;charset=UTF-8',
      }),
      [accessToken],
   );

   const url = start
      ? `${baseUrl}?from=${start}&to=${end}${refresh !== undefined ? `&refresh=${refresh}` : ''}`
      : baseUrl;

   const { successful, errorMessage, data } = useDataFetch(url, {
      header,
      cancelToken,
      onRequestCompleted: notifyCompletedRequest,
   });

   useEffect(() => {
      if (successful) {
         dataArray[accessIdx] = data;
      }

      if (errorMessage) {
         if (errorMessage instanceof axios.Cancel) {
            return;
         }
         dispatch(setAlarm('danger', errorMessage));
         dataArray[accessIdx] = undefined;
      }
   }, [successful, errorMessage, data, dispatch, dataArray, accessIdx]);

   return <></>;
}

DataColumn.propTypes = {
   baseUrl: PropTypes.string,
   cancelToken: PropTypes.object,
   start: PropTypes.string,
   end: PropTypes.string,
   refresh: PropTypes.string,
   notifyCompletedRequest: PropTypes.func,
   dataArray: PropTypes.array,
   accessIdx: PropTypes.number,
};

export default DataColumn;
