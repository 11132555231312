import React, { Component } from 'react';
import { AccessLevel } from '../../../constants/AccessLevels';
import { PropTypes } from 'prop-types';
import { getOrganizationLevel } from '../../../util/UserAccessManagement';

class Widget extends Component {
   render() {
      const Type = this.props.type;
      const attributes = this.props.widgetAttributes;

      let requiredLevel = this.props.requiredLevel;

      if (requiredLevel === undefined) {
         requiredLevel = AccessLevel.BASIC;
      }

      if (requiredLevel > getOrganizationLevel()) {
         return <span />;
      }

      return <Type {...attributes} />;
   }
}

Widget.propTypes = {
   requiredLevel: PropTypes.number,
   type: PropTypes.any,
   widgetAttributes: PropTypes.any,
};

export default Widget;
