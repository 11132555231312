import React from 'react';
import styled from 'styled-components';

const indentSizes = {
   xs: 25,
   sm: 65,
   md: 70,
   lg: 100,
   xl: 150,
};

const Line = styled.div`
   margin: 0px;
`;
Line.displayName = 'Line';

const PropertyWithTab = styled.div`
   display: inline-block;
   width: ${(props) => indentSizes[props.indent]}px;
`;
PropertyWithTab.displayName = 'PropertyWithTab';

const FigureWithTab = styled.div`
   display: inline-block;
   width: ${(props) => props.figureWidth}px;
   color: ${(props) => props.color};
`;
FigureWithTab.displayName = 'FigureWithTab';

const ColoredValue = styled.span`
   color: ${(props) => props.color};
`;
ColoredValue.displayName = 'ColoredValue';

function Figure({ color, figure, figureWidth, percentValue }) {
   if (!percentValue) {
      return <ColoredValue color={color}>{figure}</ColoredValue>;
   }

   return (
      <>
         <FigureWithTab color={color} figureWidth={figureWidth}>
            {figure}
         </FigureWithTab>
         <ColoredValue color={color}>{percentValue}</ColoredValue>
      </>
   );
}

export function QuantityDisplayLine({ indent, description, valueProps }) {
   return (
      <Line>
         <PropertyWithTab indent={indent}>{`${description}:`}</PropertyWithTab>
         <Figure
            color={valueProps.color}
            figure={valueProps.figure}
            figureWidth={valueProps.figureWidth}
            percentValue={valueProps.percentValue}
         />
      </Line>
   );
}
