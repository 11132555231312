import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AccessLevel } from '../../../../constants/AccessLevels';

import { Dashboard } from '../../../common';
import Widget from '../../Widget/Widget';

import { ProfileUse, SwitchCount, SwitchSuccess, SwitchCountRatio, AbrUseRatio } from '../../../widgets/abr';
import { Col, Row } from 'reactstrap';

import storePropTypes from './../../../../redux/store/propTypes';

import axios from 'axios';
import { setNewCancelToken, setViewTimespan } from './../../../../redux/actions/general';

import { PropTypes } from 'prop-types';
import { ABR_VIEW } from '../../../../constants/general';

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setNewCancelToken, setViewTimespan };

class ABR extends Component {
   componentWillUnmount() {
      // cancel token, added to old requests, will be activated --> old requests will be cancelled
      this.props.cancelToken.cancel('Operation canceled by the user.');
      this.props.setViewTimespan(ABR_VIEW);
      this.props.setNewCancelToken(axios.CancelToken.source());
   }

   render() {
      return (
         <Dashboard location={this.props.location} history={this.props.history} view={ABR_VIEW}>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget
                     type={ProfileUse}
                     widgetAttributes={{
                        height: 300,
                        usedMetric: 'playtime',
                     }}
                     requiredLevel={AccessLevel.PREMIUM}
                  />
               </Col>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget
                     type={ProfileUse}
                     widgetAttributes={{
                        height: 300,
                        usedMetric: 'viewers',
                     }}
                     requiredLevel={AccessLevel.PREMIUM}
                  />
               </Col>
            </Row>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget type={SwitchSuccess} requiredLevel={AccessLevel.PREMIUM} />
               </Col>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget type={SwitchCount} requiredLevel={AccessLevel.PREMIUM} />
               </Col>
            </Row>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget type={SwitchCountRatio} requiredLevel={AccessLevel.ADMIN} />
               </Col>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget type={AbrUseRatio} requiredLevel={AccessLevel.ADMIN} />
               </Col>
            </Row>
         </Dashboard>
      );
   }
}

ABR.propTypes = {
   cancelToken: storePropTypes.cancelToken,
   setNewCancelToken: PropTypes.func,
   setViewTimespan: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ABR);
