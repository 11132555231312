import { useState, useEffect, useRef } from 'react';
import useChangeDetection from '../../../../../../hooks/useChangeDetection';

function useSkipDataFetch(httpHeader, url, isActive) {
   const doFetch = useRef(false);
   const [hasBeenActive, setHasBeenActiveState] = useState(false);

   const httpHeaderChanged = useChangeDetection(httpHeader);
   const urlChanged = useChangeDetection(url);

   useEffect(() => {
      if (isActive) {
         setHasBeenActiveState(true);
      }
      if (httpHeaderChanged || urlChanged) {
         setHasBeenActiveState(false);
      }
   }, [isActive, httpHeaderChanged, urlChanged]);

   doFetch.current = isActive || (hasBeenActive && !httpHeaderChanged && !urlChanged);

   return !doFetch.current;
}

export default useSkipDataFetch;
