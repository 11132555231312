import { HOME_VIEW } from '../../../constants/general';

export const TODAY = 'Today';
export const THIS_WEEK = 'This week';
export const THIS_MONTH = 'This month';
export const THIS_YEAR = 'This year';
export const YESTERDAY = 'Yesterday';
export const LAST_WEEK = 'Last week';
export const LAST_TWO_WEEK = 'Last two weeks';
export const LAST_MONTH = 'Last month';
export const LAST_YEAR = 'Last year';
export const LAST_15_MINUTES = 'Last 15 minutes';
export const LAST_60_MINUTES = 'Last 60 minutes';
export const LAST_12_HOURS = 'Last 12 hours';
export const LAST_24_HOURS = 'Last 24 hours';

export const QUARTER_1ST = '1st quarter';
export const QUARTER_2nd = '2nd quarter';
export const QUARTER_3rd = '3rd quarter';
export const QUARTER_4th = '4th quarter';
export const LAST_TWO_MONTH = 'Last two months';
export const LAST_THREE_MONTH = 'Last three months';

export const idShortcutMap = new Map([
   [10, TODAY],
   [11, YESTERDAY],
   [12, THIS_WEEK],
   [13, THIS_MONTH],
   [20, LAST_WEEK],
   [21, LAST_TWO_WEEK],
   [22, LAST_MONTH],
   [23, LAST_TWO_MONTH],
   [30, LAST_15_MINUTES],
   [31, LAST_60_MINUTES],
   [32, LAST_12_HOURS],
   [33, LAST_24_HOURS],
   [40, QUARTER_1ST],
   [41, QUARTER_2nd],
   [42, QUARTER_3rd],
   [43, QUARTER_4th],
   [50, LAST_MONTH],
   [51, LAST_TWO_MONTH],
   [52, LAST_THREE_MONTH],
   [60, THIS_YEAR],
   [61, LAST_YEAR],
]);

export const shortcuts = (view) => {
   const shortcuts = [
      [
         { id: 10, name: TODAY },
         { id: 11, name: YESTERDAY },
         { id: 12, name: THIS_WEEK },
         { id: 13, name: THIS_MONTH },
      ],
      [
         { id: 20, name: LAST_WEEK },
         { id: 21, name: LAST_TWO_WEEK },
         { id: 22, name: LAST_MONTH },
      ],
      [
         { id: 30, name: LAST_15_MINUTES },
         { id: 31, name: LAST_60_MINUTES },
         { id: 32, name: LAST_12_HOURS },
         { id: 33, name: LAST_24_HOURS },
      ],
   ];
   if (view === HOME_VIEW) {
      shortcuts[1].push({ id: 23, name: LAST_TWO_MONTH });
   }
   return shortcuts;
};

export const longTermShortcuts = [
   [
      { id: 40, name: QUARTER_1ST },
      { id: 41, name: QUARTER_2nd },
      { id: 42, name: QUARTER_3rd },
      { id: 43, name: QUARTER_4th },
   ],
   [
      { id: 50, name: LAST_MONTH },
      { id: 51, name: LAST_TWO_MONTH },
      { id: 52, name: LAST_THREE_MONTH },
   ],
   [
      { id: 60, name: THIS_YEAR },
      { id: 61, name: LAST_YEAR },
   ],
];

export const getShortCutSettings = (isLongTerm = false) => ({
   [TODAY]: {
      settings: {
         span: 'day',
         startOf: 'day',
         interval: 'hour',
         labelFormat: 'HH',
         titleFormat: 'll',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().startOf(startOf),
         getEnd: (gte, start, span) => gte.clone().add(1, span),
      },
   },
   [YESTERDAY]: {
      settings: {
         span: 'day',
         startOf: 'day',
         interval: 'hour',
         labelFormat: 'HH',
         titleFormat: 'll',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().subtract('1', 'days').startOf(startOf),
         getEnd: (gte, start, span) => gte.clone().add(1, span),
      },
   },
   [THIS_WEEK]: {
      settings: {
         span: 'week',
         startOf: 'isoWeek',
         interval: 'day',
         labelFormat: 'ddd',
         titleFormat: '[Week] W, YYYY',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().startOf(startOf),
         getEnd: (gte, start, span) => gte.clone().add(1, span),
      },
   },
   [LAST_WEEK]: {
      settings: {
         span: 'week',
         startOf: 'isoWeek',
         interval: 'day',
         labelFormat: 'ddd',
         titleFormat: '[Week] W, YYYY',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().subtract('1', 'weeks').startOf(startOf),
         getEnd: (gte, start, span) => gte.clone().add(1, span),
      },
   },
   [LAST_TWO_WEEK]: {
      settings: {
         span: 'week',
         startOf: 'isoWeek',
         interval: 'day',
         labelFormat: 'ddd',
         titleFormat: '[Week] W, YYYY',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().subtract('2', 'weeks').startOf(startOf),
         getEnd: (gte, start, span) => gte.clone().add(2, span),
      },
   },
   [THIS_MONTH]: {
      settings: {
         span: 'month',
         startOf: 'month',
         interval: 'day',
         labelFormat: 'DD',
         titleFormat: 'MMMM YYYY',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().startOf(startOf),
         getEnd: (gte, start, span) => gte.clone().add(1, span),
      },
   },
   [LAST_MONTH]: {
      settings: {
         span: 'month',
         startOf: 'month',
         interval: isLongTerm ? 'month' : 'day',
         labelFormat: isLongTerm ? 'MMM' : 'DD',
         titleFormat: 'MMMM YYYY',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().subtract('1', 'months').startOf(startOf),
         getEnd: (gte, start, span) => gte.clone().add(1, span),
      },
   },
   [THIS_YEAR]: {
      settings: {
         span: 'year',
         startOf: 'year',
         interval: 'month',
         labelFormat: 'MMM',
         titleFormat: 'YYYY',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().startOf(startOf),
         getEnd: (gte, start, span) => gte.clone().add(1, span),
      },
   },
   [LAST_YEAR]: {
      settings: {
         span: 'year',
         startOf: 'year',
         interval: 'month',
         labelFormat: 'MMM',
         titleFormat: 'YYYY',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().subtract('1', 'year').startOf(startOf),
         getEnd: (gte, start, span) => gte.clone().add(1, span),
      },
   },
   [LAST_15_MINUTES]: {
      settings: {
         span: 'minute',
         startOf: 'minute',
         interval: 'minute',
         labelFormat: 'HH:mm',
         titleFormat: '[Last 15 minutes]',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().subtract(15, 'minutes').startOf(startOf),
         getEnd: (gte, start, span, startOf) => start.clone().add(1, 'minutes').startOf(startOf),
      },
   },
   [LAST_60_MINUTES]: {
      settings: {
         span: 'minute',
         startOf: 'minute',
         interval: 'minute',
         labelFormat: 'HH:mm',
         titleFormat: '[Last 60 minutes]',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().subtract(60, 'minutes').startOf(startOf),
         getEnd: (gte, start, span, startOf) => start.clone().add(1, 'minutes').startOf(startOf),
      },
   },
   [LAST_12_HOURS]: {
      settings: {
         span: 'hour',
         startOf: 'minute',
         interval: 'hour',
         labelFormat: 'HH:[00]',
         titleFormat: '[Last 12 hours]',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().subtract(12, 'hours').startOf(startOf),
         getEnd: (gte, start, span, startOf) => start.clone().add(1, 'hours').startOf(startOf),
      },
   },
   [LAST_24_HOURS]: {
      settings: {
         span: 'hour',
         startOf: 'minute',
         interval: 'hour',
         labelFormat: 'HH:[00]',
         titleFormat: '[Last 24 hours]',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().subtract(24, 'hours').startOf(startOf),
         getEnd: (gte, start, span, startOf) => start.clone().add(1, 'hours').startOf(startOf),
      },
   },
   [QUARTER_1ST]: {
      settings: {
         span: 'month',
         startOf: 'year',
         interval: 'month',
         labelFormat: 'MMM',
         titleFormat: '[1st quarter - starts with: ] MMMM YYYY',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().startOf(startOf),
         getEnd: (gte) => gte.clone().add(3, 'months'),
      },
   },
   [QUARTER_2nd]: {
      settings: {
         span: 'month',
         startOf: 'year',
         interval: 'month',
         labelFormat: 'MMM',
         titleFormat: '[2nd quarter - starts with: ] MMMM YYYY',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().startOf(startOf).add(3, 'months'),
         getEnd: (gte) => gte.clone().add(3, 'months'),
      },
   },
   [QUARTER_3rd]: {
      settings: {
         span: 'month',
         startOf: 'year',
         interval: 'month',
         labelFormat: 'MMM',
         titleFormat: '[3rd quarter - starts with: ] MMMM YYYY',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().startOf(startOf).add(6, 'months'),
         getEnd: (gte) => gte.clone().add(3, 'months'),
      },
   },
   [QUARTER_4th]: {
      settings: {
         span: 'month',
         startOf: 'year',
         interval: 'month',
         labelFormat: 'MMM ',
         titleFormat: '[4th quarter - starts with: ] MMMM YYYY',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().startOf(startOf).add(9, 'months'),
         getEnd: (gte) => gte.clone().add(3, 'months'),
      },
   },
   [LAST_TWO_MONTH]: {
      settings: {
         span: 'month',
         startOf: 'month',
         interval: 'month',
         labelFormat: 'MMM',
         titleFormat: '[Last two month - starts with: ] MMMM YYYY',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().subtract(2, 'months').startOf(startOf),
         getEnd: (gte, start, span) => gte.clone().add(2, span),
      },
   },
   [LAST_THREE_MONTH]: {
      settings: {
         span: 'month',
         startOf: 'month',
         interval: 'month',
         labelFormat: 'MMM',
         titleFormat: '[Last three month - starts with: ] MMMM YYYY',
      },
      dateFns: {
         getStart: (start, startOf) => start.clone().subtract(3, 'months').startOf(startOf),
         getEnd: (gte, start, span) => gte.clone().add(3, span),
      },
   },
});
