import { useState } from 'react';
import { useNonInitialEffect } from './useNonInitialEffect';

export default function useComponentFetch({
   parentSetLoading,
   parentSetSuccessfulState,
   parentSetErrorMessage,
   parentSetData,
   parentSetHttpStatus,
   reset,
} = {}) {
   const [successful, setSuccessfulState] = useState();
   const [isLoading, setLoadingState] = useState();
   const [errorMessage, setErrorMessage] = useState();
   const [data, setData] = useState();
   const [httpStatus, setHttpStatus] = useState();

   function handleResult(res) {
      const { successful, isLoading, errorMessage, data, httpStatus } = res;
      setState(parentSetLoading, setLoadingState, isLoading);
      setState(parentSetSuccessfulState, setSuccessfulState, successful);
      setState(parentSetErrorMessage, setErrorMessage, errorMessage);
      setState(parentSetData, setData, data);
      setState(parentSetHttpStatus, setHttpStatus, httpStatus);
   }

   useNonInitialEffect(() => {
      setSuccessfulState(true);
      setLoadingState(false);
      setErrorMessage(undefined);
      setData({});
      setHttpStatus(-1);
   }, [reset]);

   const output = { handleResult };
   if (parentSetLoading === undefined) output.isLoading = isLoading;
   if (parentSetSuccessfulState === undefined) output.successful = successful;
   if (parentSetErrorMessage === undefined) output.errorMessage = errorMessage;
   if (parentSetData === undefined) output.data = data;
   if (parentSetHttpStatus === undefined) output.httpStatus = httpStatus;

   return output;
}

function setState(parent, local, value) {
   if (parent !== undefined) {
      parent(value);
   } else {
      local(value);
   }
}
