import { geoProjection, geoPath } from 'd3-geo';
import { WorldZoomProjection } from '../WorldMapConstants';

// Based on Java implementation by Bojan Savric.
// https://github.com/OSUCartography/JMapProjLib/blob/master/src/com/jhlabs/map/proj/PattersonProjection.java

var pattersonK1 = 1.0148,
   pattersonK2 = 0.23185,
   pattersonK3 = -0.14499,
   pattersonK4 = 0.02406,
   pattersonC1 = pattersonK1,
   pattersonC2 = 5 * pattersonK2,
   pattersonC3 = 7 * pattersonK3,
   pattersonC4 = 9 * pattersonK4,
   pattersonYmax = 1.790857183,
   epsilon = 1e-6;

function pattersonRaw(lambda, phi) {
   var phi2 = phi * phi;
   return [lambda, phi * (pattersonK1 + phi2 * phi2 * (pattersonK2 + phi2 * (pattersonK3 + pattersonK4 * phi2)))];
}

pattersonRaw.invert = function (x, y) {
   if (y > pattersonYmax) y = pattersonYmax;
   else if (y < -pattersonYmax) y = -pattersonYmax;
   var yc = y,
      delta;

   do {
      // Newton-Raphson
      var y2 = yc * yc;
      yc -= delta =
         (yc * (pattersonK1 + y2 * y2 * (pattersonK2 + y2 * (pattersonK3 + pattersonK4 * y2))) - y) /
         (pattersonC1 + y2 * y2 * (pattersonC2 + y2 * (pattersonC3 + pattersonC4 * y2)));
   } while (Math.abs(delta) > epsilon);

   return [x, yc];
};

function geoPatterson() {
   return geoProjection(pattersonRaw).scale(139.319);
}

export function worldMapProjection(width, zoomProjection = WorldZoomProjection) {
   const { xTrans, yTrans, scaleFactor } = zoomProjection;
   const Xoffset = width / 2;
   const Yoffset = Xoffset / 1.8;
   return geoPatterson()
      .translate([Xoffset + Xoffset * xTrans, Yoffset + Yoffset * yTrans])
      .scale((width / 6.37) * scaleFactor);
}

export function getPath(d, width, zoomProjection) {
   const path = geoPath();
   path.projection(worldMapProjection(width, zoomProjection));
   return path(d);
}
