import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { setInitialState, setFilter } from '../../../../redux/actions/general';

import {
   Button,
   Card,
   CardBody,
   CardGroup,
   Col,
   Container,
   Form,
   Input,
   InputGroup,
   InputGroupAddon,
   InputGroupText,
   Row,
} from 'reactstrap';

import storePropTypes from './../../../../redux/store/propTypes';
import { PropTypes } from 'prop-types';
import moment from 'moment';

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setFilter, setInitialState };

class Login extends Component {
   isAuthenticated() {
      return (
         this.state.userInfo != null &&
         this.state.userInfo.token != null &&
         moment.utc().isSameOrBefore(moment.utc(this.state.userInfo.expires))
      );
   }

   constructor(props) {
      super(props);

      this.isAuthenticated = this.isAuthenticated.bind(this);
      this.state = {
         email: '',
         password: '',
         message: '',
      };
   }

   componentDidMount() {
      this.setState({
         ...this.state,

         userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      });
   }

   handleChange = (event) => {
      this.setState({
         [event.target.id]: event.target.value,
      });
   };

   handleLogout = (event) => {
      sessionStorage.clear();
      this.setState({});
      this.props.setInitialState();
      document.location.reload();
   };

   handleSubmit = async (event) => {
      event.preventDefault();

      if (!this.validate()) {
         return;
      }

      let self = this;
      let body = {
         email: this.state.email,
         password: this.state.password,
      };

      await axios
         .post('/api/authenticate', body, {
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then(function (response) {
            // reset orga filter in case of non orga was logged in
            // reason: orga filter could be set before at URL-store sync
            if (!response.data.isAdmin) {
               const hash = '';
               const name = '';
               self.props.setFilter({ ...self.props.filter, hash, name }, { hash, name });
            }

            let userInfo = JSON.stringify(response.data);
            sessionStorage.setItem('userInfo', userInfo);

            const pathname = sessionStorage.getItem('pathname');
            const search = sessionStorage.getItem('search');
            if (pathname !== null && search !== null) {
               // console.log('pathname + search => ', pathname + search);
               self.props.history.push(pathname + search);
            } else {
               self.props.history.push('/');
            }
            sessionStorage.removeItem('pathname');
            sessionStorage.removeItem('search');
         })
         .catch(function (error) {
            if (error.response && error.response.data && error.response.data.message) {
               self.setState({
                  ...self.state,
                  message: error.response.data.message,
               });

               return;
            }

            self.setState({
               ...self.state,
               message: error.message,
            });
         });
   };

   validate() {
      return this.state.email.length > 0 && this.state.password.length > 0;
   }

   render() {
      return (
         <div className='app flex-row align-items-center'>
            <Container>
               <Row className='justify-content-center'>
                  <Col md='8'>
                     <CardGroup>
                        <Card className='p-4'>
                           <CardBody>
                              {this.isAuthenticated() ? (
                                 <Form onSubmit={this.handleLogout}>
                                    <h1>Logout</h1>
                                    <p className='text-muted'>
                                       You are currently logged in as {this.state.userInfo.organization.name}
                                    </p>
                                    <Row>
                                       <Col xs='6'>
                                          <Button color='primary' className='px-4'>
                                             Logout
                                          </Button>
                                       </Col>
                                    </Row>
                                 </Form>
                              ) : (
                                 <Form onSubmit={this.handleSubmit}>
                                    <h1>Login</h1>
                                    <p className='text-muted'>Sign In with your Bintu account</p>
                                    <InputGroup className='mb-3'>
                                       <InputGroupAddon addonType='prepend'>
                                          <InputGroupText>
                                             <i className='bi bi-person adjust-i-size' />
                                          </InputGroupText>
                                       </InputGroupAddon>
                                       <Input
                                          type='text'
                                          placeholder='Email'
                                          value={this.state.email}
                                          id='email'
                                          onChange={this.handleChange}
                                          autoComplete='email-address'
                                       />
                                    </InputGroup>
                                    <InputGroup className='mb-4'>
                                       <InputGroupAddon addonType='prepend'>
                                          <InputGroupText>
                                             <i className='bi bi-lock adjust-i-size' />
                                          </InputGroupText>
                                       </InputGroupAddon>
                                       <Input
                                          type='password'
                                          placeholder='Password'
                                          value={this.state.password}
                                          id='password'
                                          onChange={this.handleChange}
                                          autoComplete='current-password'
                                       />
                                    </InputGroup>
                                    <Row>
                                       <Col xs='6'>
                                          <Button color='primary' className='px-4' disabled={!this.validate()}>
                                             Login
                                          </Button>
                                       </Col>
                                    </Row>
                                 </Form>
                              )}
                           </CardBody>
                        </Card>
                        {this.state.message === '' ? (
                           <Card className='text-white bg-primary py-5 d-md-down-none' style={{ width: 44 + '%' }}>
                              <CardBody className='text-center'>
                                 <div>
                                    <h2>Nanocosmos</h2>
                                    <p>
                                       Create your own live streaming application for your brand with high quality
                                       nanoStream software products - or integrate them into your existing live
                                       streaming infrastructure.
                                    </p>
                                    <a href='https://nanocosmos.de' target='_blank' rel='noopener noreferrer'>
                                       <Button color='primary' className='mt-3' active>
                                          Read more
                                       </Button>
                                    </a>
                                 </div>
                              </CardBody>
                           </Card>
                        ) : (
                           <Card className='text-white bg-danger py-5 d-md-down-none' style={{ width: 44 + '%' }}>
                              <CardBody className='text-center'>
                                 <div>
                                    <h2>Error</h2>
                                    <p>{this.state.message}</p>
                                    <a href='https://bintu.nanocosmos.de/' target='_blank' rel='noopener noreferrer'>
                                       <Button color='danger' className='mt-3' active>
                                          Reset password
                                       </Button>
                                    </a>
                                 </div>
                              </CardBody>
                           </Card>
                        )}
                     </CardGroup>
                  </Col>
               </Row>
            </Container>
         </div>
      );
   }
}

Login.propTypes = {
   filter: storePropTypes.filter,
   setInitialState: PropTypes.func,
   setFilter: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
