export const ORGA_HASH = 'orgahash';
export const STREAM = 'stream';
export const REFERRER = 'referrer';
export const IP = 'ip';
export const TOKEN = 'token';
export const CITY = 'city';
export const COUNTRY = 'country';
export const BROWSER = 'browser';
export const PLAYER_VERSION = 'playerversion';
export const OS = 'os';
