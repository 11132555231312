import React from 'react';

import { Pie } from 'react-chartjs-2';
import styled from 'styled-components';
import { QoePieBarChartOptions } from '../../../../../../constants/chartjs';

import { GREEN_TO_RED_10_STEPS } from '../../../../../../constants/colors';
import { isMobileDevice } from '../../../../../../util/BrowserTools';

const CenteredDescription = styled.div`
   text-align: center;
   font-size: 12px;
`;

const CenteredFlexContainer = styled.div`
   display: flex;
   position: relative;
   justify-content: center;
   align-items: center;
   cursor: default;
`;

const FixedHeightCenteredContainer = styled.div`
   height: ${(props) => props.height}px;
   width: ${(props) => props.width}px;
   margin: auto;
`;

function createChartData(qoeData) {
   const chartData = {
      labels: [],
      datasets: [
         {
            borderWidth: 1,
            hidden: false,
            backgroundColor: [],
            borderColor: [],
            data: [],
            labels: [],
         },
      ],
   };

   const updatedChart = chartData.datasets[0];

   qoeData.forEach((ratingsCount, idx) => {
      updatedChart.backgroundColor.push(GREEN_TO_RED_10_STEPS[idx]);
      updatedChart.borderColor.push('#EEE');
      updatedChart.data.push(ratingsCount);
   });

   return chartData;
}

function QoeDonut({ qoeData, score, height, width }) {
   return (
      <>
         <CenteredDescription>{'country score and distribution'}</CenteredDescription>
         <FixedHeightCenteredContainer height={height} width={width}>
            <Pie options={QoePieBarChartOptions} data={createChartData(qoeData)} />
            <ScoreInMiddle score={score} />
         </FixedHeightCenteredContainer>
      </>
   );
}

const isMobileDeviceUsed = isMobileDevice();

const OverlayScoreContainer = styled(CenteredFlexContainer)`
   position: absolute;
   top: ${isMobileDeviceUsed ? '48%' : '45%'};
   left: 0;
   right: 0;
   text-align: center;
   color: #fff;
   font-size: 1.4em;
   font-weight: 600;
`;

const FixedWidth = styled.div`
   width: 40px;
`;

const SeparatedMaxScore = styled.div`
   border-top: 1px groove rgba(255, 255, 255, 1);
   font-size: 0.7em;
   font-weight: 300;
`;

function ScoreInMiddle({ score }) {
   return (
      <OverlayScoreContainer>
         <FixedWidth>
            <div>{`${score}`}</div>
            <SeparatedMaxScore>100</SeparatedMaxScore>
         </FixedWidth>
      </OverlayScoreContainer>
   );
}

QoeDonut.defaultProps = {
   qoeData: [],
   score: 'N/A',
   height: 150,
   width: 150,
};

export default QoeDonut;
