import React from 'react';

import { PropTypes } from 'prop-types';

import { StyledButton } from '../../../styledComponents/styledComponents';
import { DefaultGrey } from '../../../constants/colors';
import BlockSpinner from '../BlockSpinner/BlockSpinner';

function CsvButtonTemplate({ clickHandler, heightInPx, isLoading }) {
   return (
      <StyledButton
         type='button'
         fontSizePx={10.5}
         className={'btn'}
         fixMargin
         name={'csvBtn'}
         id={'csvBtn'}
         heightPx={heightInPx}
         widthPx={60}
         bgColor={DefaultGrey()}
         fontColor={'black'}
         bgColorHover={isLoading ? DefaultGrey() : DefaultGrey(1, 210)}
         fontColorHover={'black'}
         border={'1px solid rgba(0,0,0,0.2)'}
         onClick={isLoading ? undefined : clickHandler}
         $buttonHeightPx={heightInPx}
         disabled={isLoading}
         $cursor={isLoading ? 'not-allowed' : undefined}
      >
         {isLoading ? (
            <BlockSpinner
               ballSizePx={9}
               ballColor={DefaultGrey(1, 50)}
               paddingTopBottomPx={1}
               widthPx={40}
               heightPx={heightInPx}
            />
         ) : (
            <>{'Get CSV'}</>
         )}
      </StyledButton>
   );
}

CsvButtonTemplate.propTypes = {
   clickHandler: PropTypes.func,
   heightInPx: PropTypes.number,
   isLoading: PropTypes.bool,
};

CsvButtonTemplate.defaultProps = {
   heightInPx: 19,
};

export default CsvButtonTemplate;
