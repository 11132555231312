import React from 'react';
import { INFO_BOX_SETTINGS } from '../DataCircleConstants';
import { QuantityDisplayLine } from './ContentSubComponents';

function DefaultContent({ metric, countryData, maxHeatValue }) {
   const settings = INFO_BOX_SETTINGS[metric];
   const infoLinesSettings = getInfoLineSettings(settings, countryData, maxHeatValue);
   return (
      <>
         {infoLinesSettings.map((infoLineSetting, idx) => (
            <QuantityDisplayLine
               key={`${metric}_${infoLineSetting.description}_${idx}`}
               indent={infoLineSetting.indent}
               description={infoLineSetting.description}
               valueProps={infoLineSetting.valueProps}
            />
         ))}
      </>
   );
}

function getInfoLineSettings(settings, countryData, maxHeatValue) {
   return settings.map((setting) => {
      const { fontColor, indent, description, colorFn, green, yellow, valueFormatFn, propName } = setting;

      const data = countryData[propName];

      const staticFontColor = fontColor ? true : false;
      const isTrafficColor = green ? true : false;

      const color = staticFontColor
         ? fontColor
         : isTrafficColor
         ? colorFn(data, green, yellow)
         : colorFn(data, maxHeatValue, 1, true);

      const formattedFigure = isTrafficColor ? valueFormatFn(data) : valueFormatFn(data, 3);

      return {
         indent,
         description,
         valueProps: {
            color,
            figure: formattedFigure,
         },
      };
   });
}

export default DefaultContent;
