import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip/dist/react-tooltip.umd';
import { useState } from 'react';
import { uniqueIdFactory } from '../../../util/UtilFunctions';

export function TableHeaderHint({ originColText, hintText }) {
   const [uid] = useState(uniqueIdFactory('_'));

   const id = `comparison${uid}`;
   return (
      <>
         {originColText} <i data-tooltip-id={id} data-tooltip-html={hintText} className='bi bi-info-circle ml-1'></i>
         <ReactTooltip
            id={id}
            place={'top'}
            className={'reduced-line-height bring-on-top'}
            backgroundColor={'rgba(0,0,0,0.9)'}
            textColor={'rgb(240,240,240)'}
            style={{ textAlign: 'center' }}
         />
      </>
   );
}

TableHeaderHint.propTypes = {
   originColText: PropTypes.string,
   hintText: PropTypes.string,
};
