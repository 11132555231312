export const intervalMap = new Map([
   ['minute', { name: 'Minute', value: 'minute', format: 'HH:mm' }],
   ['hour', { name: 'Hour', value: 'hour', format: 'HH' }],
   ['day', { name: 'Day', value: 'day', format: 'DD MMM' }],
   ['week', { name: 'Week', value: 'week', format: 'WW' }],
   ['month', { name: 'Month', value: 'month', format: 'MMM' }],
   ['year', { name: 'Year', value: 'year', format: 'YYYY' }],
]);

export const intervals = [];
intervalMap.forEach((value) => intervals.push(value));

export const monthlyModeintervals = [];
intervalMap.forEach((i) => {
   if (i.value === 'month' || i.value === 'year') {
      monthlyModeintervals.push(i);
   }
});
