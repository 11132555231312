import { IP, COUNTRY, CITY, STREAM, REFERRER, OS, PLAYER_VERSION, BROWSER } from '../../../constants/dataGroup';

export const INITIAL_TERM = STREAM;

export const DISPLAYED_NAME_OF_TERM = {
   [IP]: 'IP',
   [OS]: 'OS',
   [BROWSER]: 'Browser',
   [COUNTRY]: 'Country',
   [CITY]: 'City',
   [STREAM]: 'Stream name',
   [PLAYER_VERSION]: 'Webcaster version',
   [REFERRER]: 'Referrer',
};

export const TERMS = () => [STREAM, PLAYER_VERSION, BROWSER, COUNTRY, CITY, IP, OS, REFERRER];

export default {
   INITIAL_TERM,
   DISPLAYED_NAME_OF_TERM,
   TERMS,
};
