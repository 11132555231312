import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFilterQueryParams, getHttpHeader } from '../../../../../util/FetchTools';

import useDataFetch from '../../../../../hooks/useDataFetch';

import {
   BadgeListItem,
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   PaddingContainer,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../../styledComponents/styledComponents';

import { Bar } from 'react-chartjs-2';
import { CsvExportFromApiButton, Spinner } from '../../../../common';

import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';

import { CardTitle, Col, Row } from 'reactstrap';

import { cancelTokenSelector, hashSelector, troubleIntervalSelector } from '../../../../../redux/selectors/selectors';

import { PropTypes } from 'prop-types';

import 'spinkit/css/spinkit.css';

import { getAnnotation, getOptionsWithAnnotations } from '../../../../../constants/chartjs';
import { TROUBLESHOOTING_VIEW } from '../../../../../constants/general';
import { setAlarm } from '../../../../../redux/actions/general';
import { TITLE_DATE_TIME_FORMAT } from '../../constants';
import { getUrlEndString, getUrlStartString } from '../../general/DataProcessing';

const red = getStyle('--danger');

function createChartData(chartData, title, events) {
   const annotations = [];

   events.forEach((event, idx) => {
      const unixMilli = event.date;
      const annotation = getAnnotation(unixMilli, event.chartBadge, '#fc4103', idx, 'x-axis-0');
      annotations.push(annotation);
   });

   return {
      chartDatasets: {
         datasets: [
            {
               label: title,
               backgroundColor: hexToRgba(red, 10),
               borderColor: red,
               borderWidth: 1,
               data: chartData,
               fill: 'origin',
               showLine: true,
               maxBarThickness: 6,
               minBarLength: 2,
            },
         ],
      },
      annotations,
   };
}

function DefaultPlayoutRawMetric({
   height,
   start,
   end,
   playerId,
   playoutStart,
   playoutEnd,
   urlName,
   title,
   streamName,
   events,
   chartOptionFn,
   csvFilename,
}) {
   const startUnixMilli = start.valueOf();
   const endUnixMilli = end.valueOf();

   const hash = useSelector(hashSelector);
   const interval = useSelector(troubleIntervalSelector);
   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const [chart, setChart] = useState({
      labels: [],
      datasets: [],
   });

   const [chartOptions, setChartOptions] = useState(
      chartOptionFn(startUnixMilli, endUnixMilli, title, interval.stepsInMilliSec / 1000),
   );

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const streamFilterArray = useMemo(() => [streamName], [streamName]);
   const urlFilterQuery = getFilterQueryParams([], [], [], streamFilterArray, TROUBLESHOOTING_VIEW);

   const urlFrom = getUrlStartString(playoutStart, start);
   const urlEnd = getUrlEndString(playoutEnd, end);

   const url = `/api/v2/troubleshooting/${urlName}/raw/timeseries?from=${urlFrom}&to=${urlEnd}&interval=${interval.urlParamName}&playerId=${playerId}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            const { chartData } = data.result;
            if (chartData && chartData.length > 0) {
               const { chartDatasets, annotations } = createChartData(chartData, title, events);
               setChart(chartDatasets);
               setChartOptions(
                  getOptionsWithAnnotations(
                     chartOptionFn(startUnixMilli, endUnixMilli, title, interval.stepsInMilliSec / 1000),
                     annotations,
                  ),
               );
            }
         }
      }

      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [
      data,
      interval,
      startUnixMilli,
      endUnixMilli,
      events,
      title,
      chartOptionFn,
      successful,
      errorMessage,
      httpStatus,
      dispatch,
   ]);

   const startFormatted = start.format(TITLE_DATE_TIME_FORMAT);
   const endFormatted = end.format(TITLE_DATE_TIME_FORMAT);

   return (
      <PaddingContainer $padding={'0px 8px'}>
         <WidgetContainer $margin={'0px -7px 6px -7px'}>
            <WidgetHeaderContainer>
               <HeightSetContainer $heightInPx={23}>
                  <TwoSideFlexContainer>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <CsvExportFromApiButton
                              url={url}
                              header={header}
                              cancelToken={cancelToken}
                              filename={csvFilename}
                           />
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <BadgeListItem
                              $fontSizePx={10.5}
                              $fontWeight={700}
                              $marginTopPx={1}
                              $marginBottomPx={0}
                              $marginRightPx={0}
                              $bgColor={'white'}
                              $color={'#ff923fc2'}
                              $cursor={'default'}
                              $border={'2px solid #ff923fc2'}
                              $heightPx={19}
                           >
                              Raw Data Mode
                           </BadgeListItem>
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                  </TwoSideFlexContainer>
               </HeightSetContainer>
            </WidgetHeaderContainer>
            <WidgetBodyContainer>
               <Row>
                  <Col>
                     <CardTitle className='mb-0'>{title}</CardTitle>
                     <div className='small text-muted'>{`From ${startFormatted} to ${endFormatted} \u00A0\u00A0( UTC time )`}</div>
                  </Col>
               </Row>
               <ChartWrapper className='chart-wrapper' height={height} marginTopPx={20}>
                  {errorMessage ? (
                     <CenteredMsgShowing height={height} marginTopPx={20}>
                        {errorMessage.message}
                     </CenteredMsgShowing>
                  ) : (
                     <Spinner loading={isLoading} parentTopMarginPx={60}>
                        <SetChartHeight height={height}>
                           <Bar data={chart} options={chartOptions} />
                        </SetChartHeight>
                     </Spinner>
                  )}
               </ChartWrapper>
            </WidgetBodyContainer>
         </WidgetContainer>
      </PaddingContainer>
   );
}

DefaultPlayoutRawMetric.propTypes = {
   height: PropTypes.number,
   start: PropTypes.object,
   end: PropTypes.object,
   playerId: PropTypes.string,
   playoutStart: PropTypes.string,
   playoutEnd: PropTypes.string,
   urlName: PropTypes.string,
   title: PropTypes.string,
   streamName: PropTypes.string,
   events: PropTypes.array,
   chartOptionFn: PropTypes.func,
   csvFilename: PropTypes.string,
};

DefaultPlayoutRawMetric.defaultProps = {
   height: 300,
};

export default DefaultPlayoutRawMetric;
