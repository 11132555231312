import React from 'react';
import { DetailTile, TileContainer, CarouselContentContainer } from '../Styles';

import { CustomCarousel } from '../../../common';
import { E2E } from '../../../../constants/general';
import TextWithToolTip from '../TextWithToolTip';
import { Description, ValueText } from '../../../../styledComponents/styledComponents';

const DETAILS_1 = [
   { name: 'User ID', prop: 'userId' },
   { name: 'IP', prop: 'ip' },
   { name: 'Event ID', prop: 'eventId' },
   { name: 'Custom field 1', prop: 'customField1' },
];

const DETAILS_2 = [
   { name: 'Playout ID', prop: 'playerId' },
   { name: 'H5Live player version', prop: 'playerVersion' },
   { name: 'Device', prop: 'deviceType' },
   { name: 'Referrer', prop: 'referrer' },
];

const DETAILS_3 = [
   { name: 'Country', prop: 'country' },
   { name: 'City', prop: 'city' },
   { name: 'Resolution width', prop: 'width' },
   { name: 'Resolution height', prop: 'height' },
];

const DETAILS_4 = [
   { name: 'OS', prop: 'os' },
   { name: 'OS version', prop: 'osVersion' },
   { name: 'Browser', prop: 'browser' },
   { name: 'Browser version', prop: 'browserVersion' },
];

const MetricsDetails = [DETAILS_1, DETAILS_2, DETAILS_3, DETAILS_4];

function ExpandedPlayoutCell(props, mode) {
   return (
      <CustomCarousel>
         {MetricsDetails.map((details) => (
            <CarouselContentContainer key={details[0].name}>
               <TileContainer gapPx={20} isFullScreen={mode !== E2E}>
                  {details.map(({ name, prop }) => (
                     <DetailTile key={prop} gapPx={20}>
                        <Description marginLeftPx={0}>{name}</Description>
                        <ValueText>
                           {props[prop] ? <TextWithToolTip text={props[prop]} idName={prop} /> : '-'}
                        </ValueText>
                     </DetailTile>
                  ))}
               </TileContainer>
            </CarouselContentContainer>
         ))}
      </CustomCarousel>
   );
}

export default ExpandedPlayoutCell;
