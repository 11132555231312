import { REQUIRED_LEVELS } from '../components/widgets/world/WorldMapConstants';

export function getOrganizationLevel() {
   const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
   return userInfo.accessLevel.code;
}

export function accessGranted(metric) {
   return getOrganizationLevel() >= REQUIRED_LEVELS[metric];
}
