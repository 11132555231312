import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Tooltip } from 'reactstrap';

const MAX_TEXT_LENGTH = 26;

function TextWithToolTip({ text, idName }) {
   const [tooltipOpen, setTooltipOpen] = useState(false);
   const strText = text.toString();

   const toggle = () => setTooltipOpen(!tooltipOpen);

   return (
      <>
         {strText.length <= MAX_TEXT_LENGTH ? (
            strText
         ) : (
            <>
               <div id={idName}>{`${strText.substr(0, MAX_TEXT_LENGTH - 3)}...`}</div>
               <Tooltip placement='top' isOpen={tooltipOpen} target={idName} toggle={toggle}>
                  {strText}
               </Tooltip>
            </>
         )}
      </>
   );
}

TextWithToolTip.propTypes = {
   text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
   idName: PropTypes.string,
};

export default TextWithToolTip;
