import React from 'react';

import styled from 'styled-components';

const Arrow = styled.span`
   font-size: ${(props) => (props.size === undefined ? '0.875rem' : `${props.size}px`)};
   color: ${(props) => props.color};
   width: 15px;
   display: inline-block;
`;
Arrow.displayName = 'Arrow';

const Direction = styled.span`
   color: ${(props) => props.color};
   width: 45px;
   display: inline-block;
`;
Direction.displayName = 'Direction';

const LowMidHighText = styled.span`
   color: ${(props) => props.color};
   width: ${(props) => props.width}px;
   display: inline-block;
`;
LowMidHighText.displayName = 'LowMidHighText';

const downIndicator = {
   arrowColor: '#f82e2e',
   arrowIcon: 'bi-arrow-down-circle-fill arrow-icon',
   directionColor: '#fa9494',
   directionText: 'DOWN: ',
};

const upIndicator = {
   arrowColor: '#1cd325',
   arrowIcon: 'bi-arrow-up-circle-fill arrow-icon',
   directionColor: '#67ff6f',
   directionText: 'UP: ',
};

const labels = {
   'high->mid': {
      indicator: downIndicator,
      switchText: {
         from: 'HIGH',
         to: 'MID',
         arrowColor: '#f82e2e',
         arrowIcon: 'bi-arrow-down-circle-fill arrow-icon',
      },
   },
   'mid->low': {
      indicator: downIndicator,
      switchText: {
         from: 'MID',
         to: 'LOW',
         arrowColor: '#f82e2e',
         arrowIcon: 'bi-arrow-down-circle-fill arrow-icon',
      },
   },
   'low->mid': {
      indicator: upIndicator,
      switchText: {
         from: 'LOW',
         to: 'MID',
         arrowColor: '#1cd325',
         arrowIcon: 'bi-arrow-up-circle-fill arrow-icon',
      },
   },
   'mid->high': {
      indicator: upIndicator,
      switchText: {
         from: 'MID',
         to: 'HIGH',
         arrowColor: '#1cd325',
         arrowIcon: 'bi-arrow-up-circle-fill arrow-icon',
      },
   },
};

export function getIndicatorLabel(switchCase) {
   const { arrowColor, arrowIcon, directionColor, directionText } = labels[switchCase].indicator;
   return (
      <>
         <Arrow color={arrowColor} className={arrowIcon} />
         <Direction color={directionColor}>{directionText}</Direction>
      </>
   );
}

export function getSwitchLabel(switchCase, adjustedIndents) {
   const { arrowColor, arrowIcon, from, to } = labels[switchCase].switchText;
   return (
      <>
         <LowMidHighText width={getIndentWidth(from, adjustedIndents)}>{from}</LowMidHighText>{' '}
         <Arrow color={arrowColor} className={arrowIcon} />
         <LowMidHighText>{to}</LowMidHighText>
      </>
   );
}

function getIndentWidth(name, adjustedIndents) {
   if (!adjustedIndents) return 30;
   switch (name) {
      case 'HIGH':
         return 30;
      case 'MID':
         return 24;
      default:
         return 26;
   }
}
