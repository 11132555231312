import { TOP_COUNTRIES_COUNT, RealWorldRegions } from '../WorldMapConstants';

export function sortAndCut(result, sortFn, maxResLength) {
   result.sort(sortFn);
   return result.length > maxResLength ? result.slice(0, maxResLength) : result;
}

export function sortWorldRegions(worldRegions, sortFn) {
   Object.values(RealWorldRegions).forEach((worldRegion) => {
      worldRegions[worldRegion].countries.sort(sortFn);
   });
   return worldRegions;
}

export function getOutput(topCountries, worldRegions, customSortFn = null) {
   const sortFn = customSortFn ? customSortFn : (a, b) => b.total - a.total;
   const processedTopCountries = sortAndCut(topCountries, sortFn, TOP_COUNTRIES_COUNT);
   const total = processedTopCountries.reduce((total, country) => total + country.total, 0);

   return {
      worldRegions: sortWorldRegions(worldRegions, sortFn),
      topCountries: {
         countries: processedTopCountries,
         total,
      },
   };
}
