import React from 'react';
import DataCircle from './DataCircle';
import { worldMapProjection } from '../Map/GeoProjection';

import { PropTypes } from 'prop-types';
import { WORLD_REGIONS_SETTINGS } from '../WorldMapConstants';

function WorldMapCountriesData({
   countriesData,
   mapWidth,
   mapHeight,
   maxHeatValue,
   total,
   metric,
   subSwitch,
   zoomedRegion,
}) {
   const ratio = mapWidth / 200;
   const bigCircle = 2 + ratio;
   const smallCircle = 2 + ratio / 5;

   const zoomProjection = zoomedRegion !== '' ? WORLD_REGIONS_SETTINGS[zoomedRegion].zoomProjection : undefined;

   return (
      <>
         {countriesData
            // .filter((x) => x.countryName === 'Germany')
            .map((country, idx) => (
               <DataCircle
                  key={country.name}
                  mapHeight={mapHeight}
                  mapWidth={mapWidth}
                  position={getPosition(country, mapWidth, zoomProjection)}
                  countryData={country}
                  maxHeatValue={maxHeatValue}
                  total={total}
                  rank={idx < 10 ? idx + 1 : 0}
                  circleTextDistance={idx < 10 ? bigCircle * 2 + 10 : smallCircle * 2 + 10}
                  radius={idx < 10 ? bigCircle : smallCircle}
                  metric={metric}
                  subSwitch={subSwitch}
                  isRegionZoomed={zoomedRegion !== ''}
               />
            ))}
      </>
   );
}

function getPosition(country, mapWidth, zoomProjection) {
   const coordinates = worldMapProjection(mapWidth, zoomProjection)(country.coor);
   return { x: coordinates[0], y: coordinates[1] };
}

WorldMapCountriesData.propTypes = {
   mapHeight: PropTypes.number,
   mapWidth: PropTypes.number,
   maxHeatValue: PropTypes.number,
   total: PropTypes.number,
   metric: PropTypes.string,
   subSwitch: PropTypes.string,
   zoomedRegion: PropTypes.string,
   countriesData: PropTypes.arrayOf(
      PropTypes.shape({
         alpha: PropTypes.string,
         id: PropTypes.string,
         name: PropTypes.string,
         coor: PropTypes.arrayOf(PropTypes.number),
         // US data
         regionName: PropTypes.string,
         // Buffering ratio
         ratio: PropTypes.number,
         totalTime: PropTypes.number,
         // Latency
         latency: PropTypes.number,
         // Playtime
         playtime: PropTypes.number,
         // Usage
         sent: PropTypes.number,
         received: PropTypes.number,
         total: PropTypes.number,
         // ABR
         compare: PropTypes.shape({
            labels: PropTypes.array,
            mobileSet: PropTypes.array,
            desktopSet: PropTypes.array,
            maximumValue: PropTypes.number,
         }),
         profileData: PropTypes.object,
         switchMetrics: PropTypes.array,
         tableRow: PropTypes.arrayOf(
            PropTypes.shape({
               mobile: PropTypes.number,
               desktop: PropTypes.number,
               mobilePercent: PropTypes.number,
               desktopPercent: PropTypes.number,
               profile: PropTypes.string,
               totalCount: PropTypes.number,
               maximumValue: PropTypes.number,
            }),
         ),
         // several metrics
         count: PropTypes.any,
      }),
   ),
};

WorldMapCountriesData.defaultProps = {
   zoomedRegion: '',
};

export default WorldMapCountriesData;
