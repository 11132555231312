import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { DefaultGrey } from '../../../constants/colors';
import { E2E, INGEST, PLAYOUT } from '../../../constants/general';
import { troubleModeSelector } from '../../../redux/selectors/selectors';
import { TooNarrowMessage, WidgetContainer } from '../../../styledComponents/styledComponents';
import { Filter } from '../../common';
import useWindowDimensions from './../../../hooks/useWindowDimensions';
import MainInputs from './MainInputs';
import { IngestContainer, IngestPlayoutContainer, PanelContainer, PlayoutContainer } from './Styles';
import IngestPanel from './ingest/IngestPanel';
import PlayoutPanel from './playout/PlayoutPanel';

function TroubleshootingContrainer({ location, history }) {
   const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
   const mode = useSelector(troubleModeSelector);
   const { width } = useWindowDimensions();

   return width < 630 ? (
      <TooNarrowMessage>{'Please enlarge the window to use troubleshooting'}</TooNarrowMessage>
   ) : (
      <>
         <Row>
            <Col>{userInfo.isAdmin && <Filter history={history} location={location} />}</Col>
         </Row>
         <WidgetContainer $bgColor={DefaultGrey(1, 247)}>
            <Row>
               <Col>
                  <MainInputs />
               </Col>
            </Row>

            <IngestPlayoutContainer>
               {mode === E2E && (
                  <>
                     <PlayoutContainer>
                        <PlayoutPanel />
                     </PlayoutContainer>
                     <IngestContainer>
                        <IngestPanel />
                     </IngestContainer>
                  </>
               )}
               {mode === INGEST && (
                  <PanelContainer>
                     <IngestPanel />
                  </PanelContainer>
               )}
               {mode === PLAYOUT && (
                  <PanelContainer>
                     <PlayoutPanel />
                  </PanelContainer>
               )}
            </IngestPlayoutContainer>
         </WidgetContainer>
      </>
   );
}

export default TroubleshootingContrainer;

TroubleshootingContrainer.propTypes = {
   location: PropTypes.object,
   history: PropTypes.object,
};
