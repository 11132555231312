export function getSingleUrlParam(paramName, propValue) {
   if (propValue === null || propValue === undefined) {
      return '';
   }

   return `${encodeURIComponent(paramName)}=${encodeURIComponent(propValue)}&`;
}

export function getArrayUrlParam(paramName, propValue) {
   if (propValue === null || propValue === undefined) {
      return '';
   }
   let urlParam = `${paramName}=${propValue.reduce(
      (acc, elem) => `${acc}${encodeURIComponent(elem.value)}~${encodeURIComponent(elem.label)},`,
      '',
   )}`;
   return removeCertainLastChar(urlParam, ',') + '&';
}

export function getSimpleArrayUrlParam(paramName, propValue) {
   if (propValue === null || propValue === undefined) {
      return '';
   }
   let urlParam = `${paramName}=${propValue.reduce((acc, elem) => `${acc}${encodeURIComponent(elem.value)},`, '')}`;
   return removeCertainLastChar(urlParam, ',') + '&';
}

export function getObjectUrlParam(paramName, propValue) {
   if (propValue === null || propValue === undefined) {
      return '';
   }

   let urlParam = `${paramName}=`;
   const stringifiedValue = `${encodeURIComponent(propValue.value)}~${encodeURIComponent(propValue.label)}&`;

   const res = urlParam + stringifiedValue;
   return res;
}

function removeCertainLastChar(str, lastChar) {
   const lastCharIdx = str.length - 1;
   if (str[lastCharIdx] === lastChar) {
      return str.substr(0, lastCharIdx);
   }
   return str;
}
