import React from 'react';
import styled from 'styled-components';

import { MAX_MAPWIDTH_HEIGHT_RATIO } from '../WorldMapConstants';

import { PropTypes } from 'prop-types';
import { getMobileBreakPoint, isMobileDevice } from '../../../../util/BrowserTools';

const breakPointPx = getMobileBreakPoint(386);

const TooNarrowMessage = styled.div`
   margin-top: 20px;
`;
TooNarrowMessage.displayName = 'TooNarrowMessage';

const WorldmapFrame = styled.div`
   position: relative;
   margin: auto;
   width: ${(props) => props.width}px;
`;
WorldmapFrame.displayName = 'WorldmapFrame';

const WidthMediaMinQuery = styled.div`
   display: none;
   @media (min-width: ${breakPointPx + 1}px) {
      display: block;
   }
`;
WidthMediaMinQuery.displayName = 'WidthMediaMaxQuery';

const WidthMediaMaxQuery = styled.div`
   display: none;
   @media (max-width: ${breakPointPx}px) {
      display: block;
   }
`;
WidthMediaMaxQuery.displayName = 'WidthMediaMaxQuery';

const isMobileDeviceUsed = isMobileDevice();

function PanelSizeManager({ width, children }) {
   const MAX_MAPWIDTH = MAX_MAPWIDTH_HEIGHT_RATIO * window.innerHeight;
   const mapWidth = width >= MAX_MAPWIDTH && !isMobileDeviceUsed ? Math.floor(MAX_MAPWIDTH) : width;

   return (
      <>
         <WidthMediaMinQuery>
            <WorldmapFrame width={mapWidth}>{children(mapWidth)}</WorldmapFrame>
         </WidthMediaMinQuery>
         <WidthMediaMaxQuery>
            <TooNarrowMessage>
               Please enlarge the window to see the ingests and playouts in the world map
            </TooNarrowMessage>
         </WidthMediaMaxQuery>
      </>
   );
}

PanelSizeManager.propTypes = {
   initiated: PropTypes.bool,
   top: PropTypes.number,
   right: PropTypes.number,
   bottom: PropTypes.number,
   left: PropTypes.number,
   width: PropTypes.number,
   height: PropTypes.number,
   children: PropTypes.any,
};

export default PanelSizeManager;
