import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAlarm } from '../../../../redux/actions/general';

import useDataFetch from '../../../../hooks/useDataFetch';
import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';

import {
   hashSelector,
   countriesSelector,
   tagsSelector,
   cancelTokenSelector,
   startTimespanSelector,
   endTimespanSelector,
   usageSwitchSelector,
   streamNamesSelector,
   titleTimespanSelector,
} from '../../../../redux/selectors/selectors';

import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   RemoveBottomMargin,
   SimpleFlexItem,
   SimpleFlexRow,
   SwitchDescription,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetFooterContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import roundTo from 'round-to';

import { CardTitle, Col, Row } from 'reactstrap';
import SwitchButtons from '../../../common/SwitchButtons/SwitchButtons';
import { PLAYOUT, INGEST } from '../../../zoom/ZoomConstants';
import { addSeparatorAndThreeDecimalPlaces, getIntegerWithDecimalSeparator } from '../../../../util/NumberFormatter';
import { CsvExportFromApiButton, HorizontalSeparator, Spinner } from '../../../common';
import { BREAKDOWN_VIEW } from '../../../../constants/general';
import { STREAM } from '../../../../constants/dataGroup';
import TrafficHint from '../../../common/Hints/TrafficHint';
import AggLimitHint from '../../../common/Hints/AggLimitHint';
import { DefaultBlue, DefaultGrey } from '../../../../constants/colors';

const { SearchBar } = Search;

const USAGE_SETTINGS = {
   bytes: {
      title: 'GBytes per Streamname',
      unit: 'GB',
      unitConversion: (value) => roundTo(value / 1e9, 3),
      resUnitPropNames: 'bytes',
   },
   playtime: {
      title: 'Hours per Streamname',
      unit: 'h',
      unitConversion: (value) => roundTo(value / 3600, 3),
      resUnitPropNames: 'seconds',
   },
};

const getColumnsOptions = (unit, activePI) => [
   {
      text: 'Streamname',
      dataField: 'streamName',
      sort: true,
      style: { verticalAlign: 'middle', textAlign: 'right' },
      headerStyle: { width: '50%' },
      headerAlign: 'right',
   },
   {
      text: `${activePI === PLAYOUT ? 'Playout' : 'Ingest'} [${unit}]`,
      dataField: activePI,
      sort: true,
      style: { verticalAlign: 'middle', textAlign: 'right' },
      headerAlign: 'right',
      headerStyle: { width: '125px' },
      formatter: addSeparatorAndThreeDecimalPlaces,
      searchable: false,
   },
];

function UsageByStreamName() {
   const [tableData, setTableData] = useState([]);
   const [rowCount, setRowCount] = useState(0);
   const [total, setTotal] = useState(0);

   const [activePI, setActivePI] = useState(PLAYOUT);
   const activePiRef = useRef(activePI);
   activePiRef.current = activePI;

   const hash = useSelector(hashSelector);
   const countries = useSelector(countriesSelector);
   const tags = useSelector(tagsSelector);
   const streamNames = useSelector(streamNamesSelector);

   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);
   const timespanTitle = useSelector(titleTimespanSelector);

   const usageSwitch = useSelector(usageSwitchSelector);
   const usageSwitchRef = useRef(usageSwitch);
   usageSwitchRef.current = usageSwitch;

   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, [], streamNames, BREAKDOWN_VIEW);
   const url = `/api/v2/usage/${STREAM}/${activePI}/${usageSwitch}?from=${start.format()}&to=${end.format()}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            const { tableData, total, count } = getData(data.data, usageSwitchRef.current, activePiRef.current);
            setTableData(tableData);
            setTotal(total);
            setRowCount(count);
         }
      }
      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, dispatch]);

   const afterSearch = (newResult) => calculateTotalsAfterSearch(newResult, total, setTotal, setRowCount, activePI);

   const { title, unit } = USAGE_SETTINGS[usageSwitch];

   const setPI = (e) => {
      const selectedOption = e.target.getAttribute('name');
      if (selectedOption !== activePI) {
         setActivePI(selectedOption);
      }
   };

   return (
      <WidgetContainer>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow>
                     <SwitchDescription>{'Selected CDN section'}</SwitchDescription>
                     <SimpleFlexItem>
                        <SwitchButtons
                           leftActive={activePI === PLAYOUT}
                           leftLabel={'Playout'}
                           rightLabel={'Ingest'}
                           leftName={PLAYOUT}
                           rightName={INGEST}
                           handleSwitch={setPI}
                           buttonWidth={60}
                           buttonHeight={19}
                           fontSize={11}
                           marginLeft={7}
                           activeBackgroundColor={DefaultBlue()}
                           inactiveBackgroundColor={DefaultGrey()}
                           activeFontColor={'#FFF'}
                           inactiveFontColor={'#000'}
                        />
                     </SimpleFlexItem>
                     <HorizontalSeparator
                        color={DefaultGrey(1, 180)}
                        widthInPx={1}
                        heightInPx={18}
                        marginX_InPx={12}
                        marginTopInPx={0}
                     />
                     <SimpleFlexItem>
                        <CsvExportFromApiButton
                           url={url}
                           header={header}
                           cancelToken={cancelToken}
                           filename={'Usage_ByStream'}
                        />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <TrafficHint term={'playout or stream'} hasTimeAxis={true} sizePx={16} />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer $minHeight={'680px'}>
            <Row>
               <Col>
                  <CardTitle className='mb-0'>{title}</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </Col>
            </Row>
            <ChartWrapper className='chart-wrapper' marginTopPx={15}>
               <Spinner loading={isLoading} parentTopMarginPx={-250}>
                  {errorMessage ? (
                     <CenteredMsgShowing height={602} marginTopPx={0}>
                        {errorMessage.message}
                     </CenteredMsgShowing>
                  ) : (
                     <RemoveBottomMargin>
                        <ToolkitProvider
                           keyField='streamName'
                           data={tableData}
                           columns={getColumnsOptions(unit, activePI)}
                           search={{ afterSearch }}
                           bootstrap4
                        >
                           {(props) => (
                              <>
                                 <TwoSideFlexContainer>
                                    <div></div>
                                    <SearchBar {...props.searchProps} tableId={'streamname'} />
                                 </TwoSideFlexContainer>
                                 <BootstrapTable
                                    {...props.baseProps}
                                    classes='fixed-table'
                                    pagination={paginationFactory({
                                       sizePerPage: 10,
                                       hideSizePerPage: true,
                                    })}
                                    hover
                                    striped
                                 />
                              </>
                           )}
                        </ToolkitProvider>
                     </RemoveBottomMargin>
                  )}
               </Spinner>
            </ChartWrapper>
         </WidgetBodyContainer>
         <WidgetFooterContainer>
            <Row>
               <Col className='text-right'>Found streams:</Col>
               <Col>
                  {isLoading || errorMessage ? '0' : getIntegerWithDecimalSeparator(rowCount)}
                  <AggLimitHint usage={usageSwitchRef.current} term={'stream names'} />
               </Col>
            </Row>
         </WidgetFooterContainer>
      </WidgetContainer>
   );
}

function getData(data, usageSwitch, pi) {
   const unitPropName = USAGE_SETTINGS[usageSwitch].resUnitPropNames;
   const unitConvertFn = USAGE_SETTINGS[usageSwitch].unitConversion;

   let total = 0;
   const tableData = [];
   data.forEach((streamBucket) => {
      const usage = streamBucket[pi][unitPropName];
      total += usage;

      tableData.push({
         streamName: streamBucket[STREAM],
         [pi]: unitConvertFn(usage),
      });
   });

   total = unitConvertFn(total);
   const count = tableData.length;
   return { tableData, total, count };
}

function calculateTotalsAfterSearch(result, total, setTotal, setRowCount, activePI) {
   const delta = 0.001;

   let currSumPi = 0;

   for (let i = 0; i < result.length; i++) {
      currSumPi += result[i][activePI];
   }

   if (total < currSumPi - delta || total > currSumPi + delta) {
      const searchResultTotal = currSumPi;
      const searchResultRowCount = result.length;
      setTotal(searchResultTotal);
      setRowCount(searchResultRowCount);
   }
}

export default UsageByStreamName;
