import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { scoreRatingMapping } from './constants';
import HoverBox from './HoverBox';

const initData = {
   labels: ['N/A'],
   datasets: [
      {
         data: [100],
         backgroundColor: ['#333'],
      },
   ],
};

const CenteredFlexContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: default;
`;

const CircleContainer = styled.div`
   width: ${(props) => props.width};
   height: ${(props) => props.height};
   position: relative;
`;

const CircleCenterText = styled.div`
   font-size: 11px;
   margin-top: -1px;
`;

export const ScoreCircle = ({ score, width, height, details }) => {
   const [hover, setHover] = useState(false);

   let checkedScore = score;
   if (score === undefined || isNaN(score) || score === null) {
      checkedScore = -1;
   }
   const { text, circleShare, color } = useCircle(checkedScore, initData);

   return (
      <CenteredFlexContainer>
         <CircleContainer
            width={width || '25px'}
            height={height || '25px'}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
         >
            <CircularProgressbarWithChildren
               value={circleShare}
               strokeWidth={8}
               styles={buildStyles({
                  pathColor: color,
               })}
            >
               <CircleCenterText>
                  <strong>{text}</strong>
               </CircleCenterText>
            </CircularProgressbarWithChildren>
            <HoverBox show={hover} {...details} />
         </CircleContainer>
      </CenteredFlexContainer>
   );
};

function getRating(score) {
   for (let i = 0; i < scoreRatingMapping.length; i++) {
      const rating = scoreRatingMapping[i];
      if (score >= rating.limit) {
         return rating;
      }
   }
}

function useCircle(score, initData) {
   const [data, setData] = useState(initData);

   useEffect(() => {
      const { desc, color } = getRating(score);

      const circleShare = score === 100 || score < 0 ? 100 : score;
      const text = score >= 0 ? score.toString() : desc;
      setData({ text, circleShare, color });
   }, [score]);

   return data;
}
