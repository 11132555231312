import { PropTypes } from 'prop-types';
import { WORLDMAP_METRIC } from '../../components/widgets/world/WorldMapConstants';

const alarm = PropTypes.exact({
   type: PropTypes.string,
   message: PropTypes.string,
});

const filter = PropTypes.exact({
   hash: PropTypes.string,
   name: PropTypes.string,
   countries: PropTypes.arrayOf(
      PropTypes.exact({
         value: PropTypes.string,
         label: PropTypes.string,
      }),
   ),
   tags: PropTypes.arrayOf(
      PropTypes.exact({
         value: PropTypes.string,
         label: PropTypes.string,
      }),
   ),
   events: PropTypes.arrayOf(
      PropTypes.exact({
         value: PropTypes.string,
         label: PropTypes.string,
      }),
   ),
   streamNames: PropTypes.arrayOf(
      PropTypes.exact({
         value: PropTypes.string,
         label: PropTypes.string,
      }),
   ),
});

const timespan = PropTypes.exact({
   id: PropTypes.number,
   visible: PropTypes.bool,
   type: PropTypes.string,
   label: PropTypes.string,
   span: PropTypes.string,
   interval: PropTypes.string,
   format: PropTypes.string,
   gte: PropTypes.object,
   lt: PropTypes.object,
   utcOffset: PropTypes.string,
   title: PropTypes.string,
   valid: PropTypes.bool,
});

const countries = PropTypes.arrayOf(
   PropTypes.exact({
      value: PropTypes.string,
      label: PropTypes.string,
   }),
);

const tags = PropTypes.arrayOf(
   PropTypes.exact({
      value: PropTypes.string,
      label: PropTypes.string,
   }),
);

const events = PropTypes.arrayOf(
   PropTypes.exact({
      value: PropTypes.string,
      label: PropTypes.string,
   }),
);

const settings = PropTypes.exact({
   weekType: PropTypes.string,
});

const userInfo = PropTypes.exact({
   token: PropTypes.any,
   expires: PropTypes.object,
   user: PropTypes.exact({
      email: PropTypes.string,
   }),
   organization: PropTypes.exact({
      name: PropTypes.string,
   }),
});

const currentView = PropTypes.string;

const cancelToken = PropTypes.exact({
   token: PropTypes.any,
   cancel: PropTypes.func,
});

const worldmapMetric = PropTypes.exact({
   value: PropTypes.string,
   label: PropTypes.string,
});

const worldmapSubSwitch = PropTypes.exact({
   [WORLDMAP_METRIC.PI]: PropTypes.string,
   [WORLDMAP_METRIC.ABR_PLAYTIME]: PropTypes.string,
});

const worldmapOptions = PropTypes.exact({
   cdn: PropTypes.object,
});

const troubleTimespan = PropTypes.exact({
   start: PropTypes.object,
   end: PropTypes.object,
   interval: PropTypes.object,
});

const troubleMode = PropTypes.string;

const troubleIngestInput = PropTypes.exact({
   ingestInput: PropTypes.string,
});

const troubleIngestStream = PropTypes.exact({
   ingestStream: PropTypes.string,
});

const troubleSelectedPublish = PropTypes.exact({
   publishStart: PropTypes.string,
   publishEnd: PropTypes.string,
   publishFineStart: PropTypes.string,
   publishFineEnd: PropTypes.string,
});

const troublePlayoutInput = PropTypes.exact({
   userId: PropTypes.string,
});

const troublePlayoutStream = PropTypes.string;

const troubleSelectedPlayout = PropTypes.exact({
   playerId: PropTypes.string,
   playoutFineStart: PropTypes.string,
   playoutFineEnd: PropTypes.string,
});

const stdSelectOption = PropTypes.exact({
   value: PropTypes.string,
   label: PropTypes.string,
});

const ipFilteringResultRow = PropTypes.exact({
   ip: PropTypes.string,
   duration_sum: PropTypes.number,
   duration_avg: PropTypes.number,
   session_count: PropTypes.number,
   stream_count: PropTypes.number,
   token_count: PropTypes.number,
   provider: PropTypes.string,
   country: PropTypes.string,
   stream_start: PropTypes.string,
   stream_end: PropTypes.string,
   top_tags: PropTypes.arrayOf(PropTypes.string),
   tag_count: PropTypes.number,
   concurrency: PropTypes.number,
   top_streams: PropTypes.arrayOf(
      PropTypes.exact({
         stream_name: PropTypes.string,
         duration_sum: PropTypes.number,
         duration_avg: PropTypes.number,
         session_count: PropTypes.number,
         token_count: PropTypes.number,
         stream_start: PropTypes.string,
         stream_end: PropTypes.string,
         concurrency: PropTypes.number,
      }),
   ),
   blocked: PropTypes.bool,
   action: PropTypes.string,
});

const demoMode = PropTypes.exact({
   isActive: PropTypes.bool,
   demoUser: PropTypes.string,
});

const storePropTypes = {
   alarm,
   filter,
   timespan,
   countries,
   tags,
   events,
   settings,
   userInfo,
   currentView,
   cancelToken,
   worldmapMetric,
   worldmapSubSwitch,
   worldmapOptions,
   worldmapView: PropTypes.string,
   worldmapRegionZoom: PropTypes.string,
   troubleTimespan,
   troubleMode,
   troubleIngestInput,
   troubleIngestStream,
   troubleSelectedPublish,
   troublePlayoutInput,
   troublePlayoutStream,
   troubleSelectedPlayout,
   stdSelectOption,
   ipFilteringResultRow,
   demoMode,
};

export default storePropTypes;
