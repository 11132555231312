import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useDataFetch from '../../../../hooks/useDataFetch';
import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';

import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';

import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';

import { HorizontalBar } from 'react-chartjs-2';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, Spinner } from '../../../common';

import { CardTitle, Col, Row } from 'reactstrap';

import {
   cancelTokenSelector,
   countriesSelector,
   endTimespanSelector,
   eventsSelector,
   hashSelector,
   startTimespanSelector,
   streamNamesSelector,
   tagsSelector,
   timespanSelector,
   titleTimespanSelector,
} from './../../../../redux/selectors/selectors';

import { PropTypes } from 'prop-types';

import 'spinkit/css/spinkit.css';

import { chartjsColors, horizentalBarChartZoomOptions } from '../../../../constants/chartjs';
import { ABR_VIEW } from '../../../../constants/general';
import { SWITCH_COUNT } from '../../../zoom/ZoomConstants';
import ZoomHint from '../../../zoom/ZoomHint';
import Modal from '../../../zoom/templates/DefaultTemplate';
import { ABR_SETTINGS } from '../../../zoom/widgetSettings/AbrView';

const zoomChartOptions = horizentalBarChartZoomOptions();

function addZoomClickHandler(chartRef, setModal) {
   return {
      ...zoomChartOptions,
      onClick: (event, item) => {
         if (item.length === 0) {
            return;
         }
         const activePoint = chartRef.current.chartInstance.getElementAtEvent(event)[0];
         const data = activePoint._chart.data;
         const index = item[0]._index;
         const term = data.labels[index];

         setModal({ toggleShow: true, selectedTerm: term });
      },
   };
}

function createChartData(data) {
   const updatedChartData = {
      labels: [],
      datasets: [],
   };

   const { up, down } = data.aggregations;
   const total = down.value + up.value;
   const orgaDataset = [down.value, up.value, total];

   const selectedColor = chartjsColors[0];

   const datasets = [
      {
         label: 'count',
         backgroundColor: hexToRgba(selectedColor, 10),
         borderColor: selectedColor,
         borderWidth: 1,
         data: orgaDataset,
         hidden: false,
         fill: false,
      },
   ];

   updatedChartData.labels = ['down', 'up', 'total'];
   updatedChartData.datasets = datasets;

   return updatedChartData;
}
function SwitchCount({ height }) {
   const [chart, setChart] = useState({
      labels: [],
      datasets: [],
   });

   const [noData, setNoData] = useState(false);

   const hash = useSelector(hashSelector);
   const countries = useSelector(countriesSelector);
   const tags = useSelector(tagsSelector);
   const events = useSelector(eventsSelector);
   const streamNames = useSelector(streamNamesSelector);

   const timespan = useSelector(timespanSelector);
   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);
   const timespanTitle = useSelector(titleTimespanSelector);

   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const [modal, setModal] = useState({
      toggleShow: false,
      selectedTerm: '',
   });

   const chartRef = useRef(null);
   const chartOptions = addZoomClickHandler(chartRef, setModal);

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, events, streamNames, ABR_VIEW);

   const url = `/api/v2/abr/switches/count?from=${start.format()}&to=${end.format()}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            setChart(createChartData(data));
            setNoData(data.aggregations.up.value === 0 && data.aggregations.down.value === 0);
         }
      }

      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, dispatch]);

   const { toggleShow, selectedTerm } = modal;

   const errorOrNoData = errorMessage || noData;
   const issueMsg = errorMessage ? errorMessage.message : 'No data available';

   return (
      <WidgetContainer>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <CsvExportFromApiButton
                           url={url}
                           header={header}
                           cancelToken={cancelToken}
                           filename={'Abr_SwitchCount'}
                        />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <ZoomHint />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer>
            <Row>
               <Col>
                  <CardTitle className='mb-0'>Switches between ABR profiles</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </Col>
            </Row>
            <ChartWrapper height={height}>
               <Spinner loading={isLoading} parentTopMarginPx={60}>
                  {errorOrNoData ? (
                     <CenteredMsgShowing height={height} marginTopPx={20}>
                        {issueMsg}
                     </CenteredMsgShowing>
                  ) : (
                     <SetChartHeight height={height}>
                        <HorizontalBar ref={chartRef} data={chart} options={chartOptions} />
                     </SetChartHeight>
                  )}
               </Spinner>
            </ChartWrapper>
         </WidgetBodyContainer>
         {toggleShow && (
            <Modal
               term={selectedTerm}
               timespan={{ start: timespan.gte, end: timespan.lt }}
               metric={SWITCH_COUNT}
               closeModal={() =>
                  setModal({
                     ...modal,
                     toggleShow: false,
                  })
               }
               settings={ABR_SETTINGS}
            />
         )}
      </WidgetContainer>
   );
}

SwitchCount.propTypes = {
   height: PropTypes.number,
};

SwitchCount.defaultProps = {
   height: 300,
};

export default SwitchCount;
