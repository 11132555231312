import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import Select from 'react-select';
import StreamNameChecker from './StreamNameChecker';
import { CustomCol } from './StreamNameStyles';

import { setFilter } from '../../../redux/actions/general';

import { filterSelector, streamNamesArraySelector, streamNamesSelector } from '../../../redux/selectors/selectors';

import { sortAndReduceFilterArrayToString } from '../../../redux/selectors/comparing';
import {
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../styledComponents/styledComponents';
import { DefaultGrey } from '../../../constants/colors';

function getStreamNameOption(label) {
   return { label, value: label };
}

function StreamNameFilter() {
   const dispatch = useDispatch();

   const storeStreamNames = useSelector(streamNamesArraySelector);
   const storeStreamNamesSortedString = useSelector(streamNamesSelector);
   const filter = useSelector(filterSelector);

   const [streamNames, setStreamNames] = useState(storeStreamNames);

   const [urlChange, setUrlChange] = useState([]);

   const streamNamesRef = useRef();
   streamNamesRef.current = streamNames;

   // stream name changed by URL
   useEffect(() => {
      if (storeStreamNamesSortedString !== sortAndReduceFilterArrayToString(streamNamesRef.current, '~')) {
         setStreamNames(storeStreamNames);
         setUrlChange([]);
      }
   }, [storeStreamNames, storeStreamNamesSortedString]);

   // GO button handler
   function applySelection() {
      if (streamNames.length === 0 && storeStreamNames.length === 0) {
         return;
      }
      dispatch(setFilter({ ...filter, streamNames }, { streamNames }));
   }

   // press ENTER handler
   function handleKeyDown(event) {
      if (event.key === 'Enter') {
         applySelection();
      }
   }

   // handle remove selected stream / all streams (return null if no stream left over)
   function handleSelectChange(input) {
      const streamNames = input === null ? [] : input;
      setStreamNames(streamNames);
   }

   return (
      <div style={{ position: 'relative', zIndex: 11 }} className='animated fadeIn'>
         <Row>
            <Col>
               <WidgetContainer>
                  <WidgetHeaderContainer $bgColor={DefaultGrey(1, 247)}>
                     <strong>Stream name filter</strong>
                  </WidgetHeaderContainer>
                  <WidgetBodyContainer>
                     <div className='customRow'>
                        <StreamNameChecker
                           streamNames={streamNames}
                           onStreamAdd={(streamName) =>
                              setStreamNames([...streamNames, getStreamNameOption(streamName)])
                           }
                           clearSelection={useCallback(() => setStreamNames([]), [])}
                           urlChange={urlChange}
                           SelectContainer={({ children }) => (
                              <CustomCol basis={285} grow={0} shrink={0} marginRight={20}>
                                 {children}
                              </CustomCol>
                           )}
                           ButtonContainer={({ children }) => (
                              <CustomCol basis={50} grow={0} shrink={0} marginRight={0}>
                                 {children}
                              </CustomCol>
                           )}
                        />
                        <div className='selectCol'>
                           <Select
                              components={{
                                 DropdownIndicator: null,
                              }}
                              value={streamNames}
                              inputValue={''}
                              onChange={handleSelectChange}
                              onKeyDown={handleKeyDown}
                              menuIsOpen={false}
                              isClearable
                              isMulti
                              placeholder={'Selected stream names (must be checked before)'}
                           />
                        </div>
                        <div className='buttonCol'>
                           <Button color='primary' onClick={applySelection} disabled={false}>
                              Go
                           </Button>
                        </div>
                     </div>
                  </WidgetBodyContainer>
               </WidgetContainer>
            </Col>
         </Row>
      </div>
   );
}

export default StreamNameFilter;
