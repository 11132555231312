import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers/reducers';
import urlSyncMiddleware from '../middlewares/pushToURL';

export const history = createBrowserHistory();

function configureStore(preloadedState) {
   const store = createStore(
      createRootReducer(history), // root reducer with router state
      preloadedState,
      composeWithDevTools(
         applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            urlSyncMiddleware,
         ),
      ),
   );

   return store;
}

export default configureStore;
