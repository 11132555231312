import { useRef } from 'react';
import useChangeDetection from './../../../../hooks/useChangeDetection';

export function useResetSignal(start, end, orgaHash, searchTerm, isMounting) {
    const resetInProgress = useRef(false);

    const startHasChanged = useChangeDetection(start);
    const endHasChanged = useChangeDetection(end);
    const orgaHashHasChanged = useChangeDetection(orgaHash);

    if ((startHasChanged || endHasChanged || orgaHashHasChanged) && !isMounting) {
       resetInProgress.current = true;
    }

    if (searchTerm === '' && resetInProgress.current) {
       resetInProgress.current = false;
    }

    return resetInProgress.current;
 }