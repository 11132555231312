import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { worldmapRegionZoomSelector, worldmapViewSelector } from '../../../../redux/selectors/selectors';
import { StyledButton } from '../../../../styledComponents/styledComponents';
import { HoveredRegionContext } from '../WorldContainer';
import { VIEW_MODE, WORLD_REGIONS_SETTINGS } from '../WorldMapConstants';
import { DefaultGrey } from '../../../../constants/colors';

function CountryRegionDisplay() {
   const [hoveredWorldRegion] = useContext(HoveredRegionContext);
   const viewSwitch = useSelector(worldmapViewSelector);
   const zoomedRegion = useSelector(worldmapRegionZoomSelector);

   const hoveredLabel = hoveredWorldRegion !== '' ? hoveredWorldRegion : 'World regions';
   const regionLabel = zoomedRegion !== '' ? zoomedRegion : hoveredLabel;

   const aWorldRegionIsHovered = hoveredWorldRegion !== '' && hoveredWorldRegion !== VIEW_MODE.ZOOM_OUT;
   const isInZoomMode = zoomedRegion !== '';

   const hoveredBgColor = aWorldRegionIsHovered
      ? WORLD_REGIONS_SETTINGS[hoveredWorldRegion].backgroundColor(0.3)
      : DefaultGrey(1, 230);
   const bgColor = isInZoomMode ? WORLD_REGIONS_SETTINGS[zoomedRegion].backgroundColor(0.3) : hoveredBgColor;

   const overviewLabel = hoveredWorldRegion !== '' ? hoveredWorldRegion : 'Overview';
   const zoomedLabel = hoveredWorldRegion === VIEW_MODE.ZOOM_OUT ? 'Zoom out' : regionLabel;

   return (
      <>
         {viewSwitch === VIEW_MODE.REGION && (
            <StyledButton
               type='button'
               fontSizePx={10.5}
               className={'btn'}
               fixMargin
               name={'csvBtn'}
               id={'csvBtn'}
               heightPx={22}
               widthPx={120}
               bgColor={hoveredWorldRegion === VIEW_MODE.ZOOM_OUT ? DefaultGrey(1, 230) : bgColor}
               fontColor={hoveredWorldRegion === VIEW_MODE.ZOOM_OUT ? 'black' : 'rgb(10, 10, 10)'}
               bgColorHover={hoveredWorldRegion === VIEW_MODE.ZOOM_OUT ? DefaultGrey(1, 230) : bgColor}
               fontColorHover={hoveredWorldRegion === VIEW_MODE.ZOOM_OUT ? 'black' : 'rgb(10, 10, 10)'}
               border={'0px solid rgba(0,0,0,0.2)'}
               fontWeight={500}
               $buttonHeightPx={22}
               $cursor={'default'}
               $borderRadiusInPx={7}
            >
               {isInZoomMode ? zoomedLabel : overviewLabel}
            </StyledButton>
         )}
      </>
   );
}

export default CountryRegionDisplay;
