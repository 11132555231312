import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import AccessLevelDropDown from './AccessLevelDropDown';
import { setDemoMode } from '../../../redux/actions/general';
import { useDispatch } from 'react-redux';
import SwitchButtons from '../SwitchButtons/SwitchButtons';
import { PropTypes } from 'prop-types';
import storePropTypes from '../../../redux/store/propTypes';
import { AccessLevel, mapLevelCodeToName } from '../../../constants/AccessLevels';

const DEMO_MODE = 'DEMO_MODE';
const NORMAL_MODE = 'NORMAL_MODE';

function FilterHead({ demoMode, selectedOrga, location, history }) {
   const [accessLevel, setAccessLevel] = useState(AccessLevel.ADMIN);
   const [isDroppedDown, setDropDownState] = useState(false);
   const dispatch = useDispatch();

   const currentMode = demoMode.isActive ? DEMO_MODE : NORMAL_MODE;

   useEffect(() => {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      setAccessLevel(userInfo.accessLevel.code);
   }, []);

   function changeAccessLevel(levelCode) {
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      userInfo.accessLevel.code = levelCode;
      userInfo.accessLevel.name = mapLevelCodeToName[levelCode];
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
      setAccessLevel(levelCode);
      const path = location.pathname;
      const stateTail = location.search;
      const changedStateTail = stateTail.endsWith('&refresh') ? stateTail.slice(0, -8) : stateTail + '&refresh';
      history.push(path + changedStateTail);
   }

   function startDemoMode() {
      if (selectedOrga.length === 0) return;
      const demoOrga = selectedOrga[0];
      changeAccessLevel(AccessLevel[demoOrga.access]);
      dispatch(setDemoMode({ isActive: true, demoUser: demoOrga.name }));
   }

   function endDemoMode() {
      changeAccessLevel(AccessLevel.ADMIN);
      dispatch(setDemoMode({ isActive: false, demoUser: '' }));
   }

   const switchDemoMode = (e) => {
      const selectedOption = e.target.getAttribute('name');
      if (selectedOption !== currentMode) {
         selectedOption === DEMO_MODE ? startDemoMode() : endDemoMode();
      }
   };

   return (
      <Row>
         <Col className='col-12'>
            <div style={{ float: 'left' }}>
               <strong style={{ paddingRight: '20px' }}>{demoMode.isActive ? demoMode.demoUser : 'Filter'}</strong>
               <SwitchButtons
                  leftActive={currentMode === NORMAL_MODE}
                  leftName={NORMAL_MODE}
                  rightName={DEMO_MODE}
                  leftLabel={'Demo Inactive'}
                  rightLabel={'Demo Active'}
                  handleSwitch={switchDemoMode}
                  buttonWidth={90}
                  buttonHeight={21}
                  fontSize={12}
                  marginTop={-5}
               />
            </div>
            {DEMO_MODE ? null : (
               <div style={{ float: 'right' }}>
                  <AccessLevelDropDown
                     isDroppedDown={isDroppedDown}
                     selectedLevel={accessLevel}
                     onDropDown={(state) => setDropDownState(state)}
                     onLevelSelection={(newLevel) => changeAccessLevel(newLevel)}
                  />
               </div>
            )}
         </Col>
      </Row>
   );
}

FilterHead.propTypes = {
   location: PropTypes.object,
   history: PropTypes.object,
   demoMode: storePropTypes.demoMode,
   selectedOrga: PropTypes.arrayOf(
      PropTypes.shape({
         access: PropTypes.string,
         name: PropTypes.string,
      }),
   ),
};

export default FilterHead;
