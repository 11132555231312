import React from 'react';
import { useSelector } from 'react-redux';
import { worldmapSubSwitchSelector } from '../../../redux/selectors/selectors';

import { PropTypes } from 'prop-types';
import GeneralTemplate from '../GeneralTemplate';
import { WIDGET_SETTINGS } from '../widgetSettings/WorldmapView';
import Modal from '../../common/Modal/Modal';

const country_alpha_id_mapper = require('../../../assets/worldmap/compact_country_alpha_id_mapper.json');

function WorldmapTemplate({ country, timespan, closeModal, metric }) {
   const { title, subSwitchInitialIdxMapping, categoryLabels } = WIDGET_SETTINGS[metric];

   const subSwitch = useSelector(worldmapSubSwitchSelector)[metric];
   const initialCategoryIdx = subSwitchInitialIdxMapping !== undefined ? subSwitchInitialIdxMapping[subSwitch] : 0;

   return (
      <Modal closeModal={closeModal}>
         <GeneralTemplate
            title={title(`${getCountryName(country)} - Breakdown of`)}
            urlAddon={`&zoomMetric=${country}`}
            timespan={timespan}
            metric={metric}
            categoryLabels={categoryLabels}
            initialCategoryIdx={initialCategoryIdx}
            isWorldmap={true}
            settings={WIDGET_SETTINGS[metric]}
         />
      </Modal>
   );
}

function getCountryName(country) {
   const countryObject = country_alpha_id_mapper[country];
   if (countryObject !== undefined) {
      return countryObject.name;
   } else {
      return country;
   }
}

WorldmapTemplate.propTypes = {
   country: PropTypes.string,
   timespan: PropTypes.object,
   closeModal: PropTypes.func,
   metric: PropTypes.string,
};

export default WorldmapTemplate;
