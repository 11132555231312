import { getWorldRegionsAggObject } from '../WorldMapConstants';
import { getOutput } from './General';

const country_alpha_id_mapper = require('../../../../assets/worldmap/compact_country_alpha_id_mapper.json');

export function processByteUsageAdapter(countries) {
   const adaptedCountries = countries.map((countryBucket) => {
      const { country, alpha2, playout, ingest, total } = countryBucket;
      const { coordinates, worldRegion, code } = country_alpha_id_mapper[alpha2];
      const coor = [coordinates.longitude, coordinates.latitude];
      return {
         alpha: alpha2,
         coor,
         id: code.numeric,
         name: country,
         worldRegion,
         playout: playout.bytes,
         ingest: ingest.bytes,
         total: total.bytes,
      };
   });

   const worldRegions = createWorldRegionsAndTopData(adaptedCountries);

   return getOutput(adaptedCountries, worldRegions);
}

function createWorldRegionsAndTopData(countries) {
   const worldRegions = getWorldRegionsAggObject({
      playout: 0,
      ingest: 0,
      total: 0,
   });

   countries.forEach((country) => {
      const { worldRegion, playout, ingest, total } = country;
      worldRegions[worldRegion].playout += playout;
      worldRegions[worldRegion].ingest += ingest;
      worldRegions[worldRegion].total += total;
      worldRegions[worldRegion].countries.push(country);
   });

   return worldRegions;
}
