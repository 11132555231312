import React, { useState } from 'react';

import { PropTypes } from 'prop-types';

import { saveAs } from 'file-saver';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAlarm } from '../../../redux/actions/general';
import CsvButtonTemplate from './CsvButtonTemplate';

function CsvExportButton({ url, header, cancelToken, filename, heightInPx }) {
   const [startCsvRequest, setStartCsvRequest] = useState(false);
   const dispatch = useDispatch();

   useQuery({
      queryKey: ['csvDownload', url, startCsvRequest],
      queryFn: () =>
         axios.get(`${url}&format=csv`, { headers: header, cancelToken: cancelToken.token }).then((res) => res.data),
      enabled: startCsvRequest,
      onSuccess: (data) => {
         setStartCsvRequest(false);
         const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
         saveAs(blob, `${filename}_${moment.utc().format().replace(':', '_')}.csv`);
      },
      onError: (err) => {
         setStartCsvRequest(false);
         dispatch(setAlarm('danger', err));
      },
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
      // refetchOnReconnect: false,
   });

   return (
      <CsvButtonTemplate
         clickHandler={() => setStartCsvRequest(true)}
         heightInPx={heightInPx}
         isLoading={startCsvRequest}
      />
   );
}

CsvExportButton.propTypes = {
   url: PropTypes.string,
   header: PropTypes.object,
   cancelToken: PropTypes.object,
   filename: PropTypes.string,
   heightInPx: PropTypes.number,
};

export default CsvExportButton;
