import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { PropTypes } from 'prop-types';
import {
   Notification,
   SimpleHorizontalSeparator,
   Description,
   FontWeightedText,
   LightHeadline,
} from '../../../../styledComponents/styledComponents';

import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import {
   setTroubleshootingSelectedPublish,
   completeSyncTroubleshootingIngest,
} from '../../../../redux/actions/general';
import ExpandedPublishCell from './ExpandedPublishCell';
import IngestMetrics from './IngestMetrics';
import {
   troubleSelectedPublishSelector,
   troubleModeSelector,
   troubleIntervalSelector,
   troubleSyncSettingsSelector,
   troubleStartSelector,
   troubleEndSelector,
   hashSelector,
   cancelTokenSelector,
   troubleIngestStreamSelector,
} from '../../../../redux/selectors/selectors';
import { getIntervalEnd, getIntervalStart } from '../general/DataProcessing';
import { CHRONOLOGICAL_DATE_FORMAT, CHRONOLOGICAL_DATE_TIME_FORMAT } from '../constants';
import { format_time_Number } from '../../../../util/NumberFormatter';
import IngestService from './IngestService';

import BlockSpinner from '../../../common/BlockSpinner/BlockSpinner';
import { TableHeaderHint } from '../../../common/Hints/TableHeaderHint';
import ReactDOMServer from 'react-dom/server';

const TIME_UNCERTAINTY_DELTA_MILLISECS = 1000;
const MAX_ROWS = 5;

const emptyData = { publishesData: [], publishesStatus: '' };
const dropsExplanation = ReactDOMServer.renderToStaticMarkup(
   <div>
      <div>Drops indicate the stream was interrupted</div>
      <div>at the source or at &quot;first mile&quot; between</div>
      <div>client, ISP, and the ingest endpoint.</div>
      <div>This could be due to network stability</div>
      <div>or high CPU in some cases. The ingest</div>
      <div>infrastructure was healthy at the time and</div>
      <div>no other streams experienced drops</div>
      <div>on same server.</div>
   </div>,
);

const errorExplanation = ReactDOMServer.renderToStaticMarkup(
   <div>
      <div>Publish started successfully but</div>
      <div>ingest stopped due to an error occurred.</div>
   </div>,
);

const STATUS_OK = 'OK';
const STATUS_INGEST_START_FAILED = 'Ingest start failed';
const STATUS_STOPPED_ON_ERROR = 'Stopped on error';

function getColumns(isOnlyWebRTC, isOnlyRtmp) {
   let columns = [
      {
         text: 'hiddenKey',
         dataField: 'hiddenKey',
         hidden: 'true',
      },
      {
         text: 'Start',
         dataField: 'start',
      },
      {
         text: 'End',
         dataField: 'end',
         formatter: (cell, row) => (row.state === 'running' ? `${cell} (live)` : cell),
      },
      {
         text: 'Duration',
         dataField: 'duration',
         headerStyle: { width: '100px' },
         formatter: (cell) => (cell === '-' ? cell : format_time_Number(cell)),
      },
      {
         text: 'Has drops',
         dataField: 'drops',
         headerStyle: { width: '120px' },
         style: { verticalAlign: 'middle', textAlign: 'center' },
         headerAlign: 'center',
         formatter: (cell) => showCross(cell && cell.status, cell === undefined),
         headerFormatter: (col) => <TableHeaderHint originColText={col.text} hintText={dropsExplanation} />,
      },
   ];

   if (isOnlyWebRTC || (!isOnlyWebRTC && !isOnlyRtmp)) {
      const firstColumn = {
         text: 'Status',
         dataField: 'status',
         style: (cell) => ({
            verticalAlign: 'middle',
            textAlign: 'left',
            color: cell ? STATUS_STYLE_MAP[cell].color : 'rgb(35, 40, 44)',
            fontWeight: cell ? STATUS_STYLE_MAP[cell].weight : '400',
         }),
         formatter: (cell) => (cell ? addStoppedOnErrorHint(cell) : 'N/A'),
         headerAlign: 'left',
      };

      columns = [firstColumn, ...columns];

      // columns.push({
      //    text: 'Has error',
      //    dataField: 'errors',
      //    headerStyle: { width: '120px' },
      //    style: { verticalAlign: 'middle', textAlign: 'center' },
      //    headerAlign: 'center',
      //    formatter: (cell, row) =>
      //       isOnlyWebRTC ? showCross(cell) : row.protocol === 'WebRTC' ? showCross(cell) : showCross(cell, true),
      //    headerFormatter: (col) => <TableHeaderHint originColText={col.text} hintText={errorExplanation} />,
      // });
   }

   return columns;
}

const ES_TIMEOUT_ERROR_CODE = 104021;

const STATUS_STYLE_MAP = {
   [STATUS_OK]: { color: 'rgb(35, 40, 44)', weight: 400 },
   [STATUS_INGEST_START_FAILED]: { color: 'rgb(35, 40, 44)', weight: 500 },
   [STATUS_STOPPED_ON_ERROR]: { color: 'rgb(35, 40, 44)', weight: 500 },
};

function showCross(isTrue, isNa = false) {
   return isTrue ? (
      <FontWeightedText $fontWeight={700}>Yes</FontWeightedText>
   ) : (
      <FontWeightedText $fontWeight={300}>{isNa ? 'N/A' : 'No'}</FontWeightedText>
   );
}

function addStoppedOnErrorHint(cell) {
   return cell === STATUS_STOPPED_ON_ERROR ? (
      <TableHeaderHint originColText={cell} hintText={errorExplanation} />
   ) : (
      cell
   );
}

function addHiddenKeyColumn(rows) {
   return rows.map((row, idx) => ({ ...row, hiddenKey: idx }));
}

function PublishesTable({ streamName, created }) {
   const dispatch = useDispatch();
   const [shownData, setShownData] = useState([]);
   const [tableData, setTableData] = useState([]);
   const [selectedPublish, setSelectedPublish] = useState([]);
   const tableEmpty = tableData.length === 0;

   const { publishStart, publishEnd, publishFineStart, publishFineEnd } = useSelector(troubleSelectedPublishSelector);
   const syncSettings = useSelector(troubleSyncSettingsSelector);
   const syncSettingsRef = useRef();
   syncSettingsRef.current = syncSettings;

   const interval = useSelector(troubleIntervalSelector);
   const [publishIdxArray, setPublishIdxArray] = useState([]);

   if (selectedPublishHasBeenChanged(selectedPublish, publishIdxArray)) {
      setSelectedPublish(publishIdxArray);
   }
   const isAnyPublishSelected = selectedPublish.length > 0;

   const webrtcMetricsAvailable =
      isAnyPublishSelected && !tableEmpty ? tableData[selectedPublish[0]].webrtcMetricsAvailable : true;
   const rtmpMetricsAvailable =
      isAnyPublishSelected && !tableEmpty ? tableData[selectedPublish[0]].rtmpMetricsAvailable : true;

   const showRtmpDetails = isAnyPublishSelected && !tableEmpty ? tableData[selectedPublish[0]].showRtmpDetails : false;
   const showWebrtcDetails =
      isAnyPublishSelected && !tableEmpty ? tableData[selectedPublish[0]].showWebrtcDetails : false;

   const mode = useSelector(troubleModeSelector);

   const orgaHash = useSelector(hashSelector);
   const cancelToken = useSelector(cancelTokenSelector);

   const start = useSelector(troubleStartSelector);
   const end = useSelector(troubleEndSelector);

   const selectedStreamObj = useSelector(troubleIngestStreamSelector);
   // const { selectedStream } = selectedStreamObj;

   const [publishesStatus, setPublishesStatus] = useState('');
   const [publishesData, setPublishesData] = useState([]);
   const [httpStatus, setHttpStatus] = useState();
   const [isLoading, setLoadingState] = useState(false);
   const [error, setError] = useState();

   const isOnlyWebRTC = publishesData.every((publish) => publish.protocol === 'WebRTC');
   const isOnlyRtmp = publishesData.every((publish) => publish.protocol !== 'WebRTC');

   const componenthasBeenMounted = useRef(false);

   useEffect(() => {
      componenthasBeenMounted.current = true;
   }, []);

   function handleStreamNameResult(res) {
      const { data, httpStatus, isLoading, error } = res;
      const { publishesStatus, publishesData } = data ? data : emptyData;
      // console.log('error => ', error);
      // console.log('ingest res :>> ', res);

      const publishesFetchingRanIntoTimeout = error && error.errorCode === ES_TIMEOUT_ERROR_CODE;
      if (publishesFetchingRanIntoTimeout) {
         error.message = 'Oops, fetching publishes took too long. Please try again by clicking on "Search" button.';
      }

      setPublishesStatus(publishesStatus);
      setPublishesData(publishesData);
      setLoadingState(isLoading);
      setHttpStatus(httpStatus);
      setError(error);
   }

   useEffect(() => {
      const syncSettings = {
         publishStart: syncSettingsRef.current.syncStart,
         publishEnd: syncSettingsRef.current.syncEnd,
         publishFineStart: syncSettingsRef.current.syncStart,
         publishFineEnd: syncSettingsRef.current.syncEnd,
      };
      const syncIsActive = syncSettingsRef.current.syncStart !== '';
      if (syncIsActive) {
         dispatch(completeSyncTroubleshootingIngest(syncSettings, syncSettings));
      }
      setShownData(publishesData);
   }, [publishesData, httpStatus, dispatch]);

   useEffect(() => {
      const publishIdxArray = findPublish(
         shownData,
         publishStart,
         publishEnd,
         publishFineStart,
         publishFineEnd,
         dispatch,
         setShownData,
         interval,
      );
      setPublishIdxArray(publishIdxArray);
      setTableData(addHiddenKeyColumn(shownData.slice(0, MAX_ROWS)));
   }, [shownData, dispatch, interval, publishStart, publishEnd, publishFineStart, publishFineEnd]);

   const expandRow = {
      parentClassName: () => {
         return 'bg-primary';
      },
      renderer: (row) => ExpandedPublishCell(row, mode, showRtmpDetails, showWebrtcDetails),
      onlyOneExpanding: true,
      expanded: selectedPublish,
      onExpand: (row) =>
         dispatch(
            setTroubleshootingSelectedPublish(
               {
                  publishStart: row.start,
                  publishEnd: row.end,
                  publishFineStart: getIntervalStart(row.start, interval.value),
                  publishFineEnd: getIntervalEnd(row.end, interval.value),
               },
               {
                  publishStart: row.start,
                  publishEnd: row.end,
                  publishFineStart: getIntervalStart(row.start, interval.value),
                  publishFineEnd: getIntervalEnd(row.end, interval.value),
               },
            ),
         ),
   };

   return (
      <>
         <SimpleHorizontalSeparator spacePx={20} border={'1px dotted  rgb(180,180,180)'} />
         <LightHeadline marginBottomPx={3}>{`Publishes of stream '${streamName}' (created on ${moment
            .utc(created)
            .format(CHRONOLOGICAL_DATE_FORMAT)})`}</LightHeadline>

         {isLoading ? (
            <BlockSpinner paddingTopBottomPx={50} />
         ) : (
            <>
               {error ? (
                  <Notification>{`${error.message ? error.message : error}`}</Notification>
               ) : (
                  <>
                     <Description marginBottomPx={15} marginLeftPx={0} alpha={0.5}>
                        Select a publish to get metrics
                     </Description>
                     {!tableEmpty && (
                        <BootstrapTable
                           keyField='hiddenKey'
                           classes='remove-bottom-margin'
                           hover
                           striped
                           data={tableData}
                           columns={getColumns(isOnlyWebRTC, isOnlyRtmp)}
                           expandRow={expandRow}
                        />
                     )}
                     {publishesStatus && <Notification indentPx={0}>{publishesStatus}</Notification>}
                     {isAnyPublishSelected && !tableEmpty && (
                        <IngestMetrics
                           streamName={streamName}
                           webrtcMetricsAvailable={webrtcMetricsAvailable}
                           rtmpMetricsAvailable={rtmpMetricsAvailable}
                           protocol={tableData[selectedPublish[0]].protocol}
                           drops={tableData[selectedPublish[0]].drops}
                        />
                     )}
                  </>
               )}
            </>
         )}
         {componenthasBeenMounted.current && (
            <IngestService
               ingestInput={selectedStreamObj}
               url={`/api/v2/troubleshooting/publish?from=${start.format()}&to=${end.format()}`}
               orgaHash={orgaHash}
               cancelToken={cancelToken}
               onResult={handleStreamNameResult}
            />
         )}
      </>
   );
}

function findPublish(
   data,
   selectedPublishStart,
   selectedPublishEnd,
   selectedFineStart,
   selectedFineEnd,
   dispatch,
   setShownData,
   interval,
) {
   if (data.length === 0) {
      return [];
   }

   // find publish by identical start and end
   let idx = data.findIndex((publish) => publish.start === selectedPublishStart && publish.end === selectedPublishEnd);

   const exactPublishNotFound = idx === -1;
   if (exactPublishNotFound) {
      // search publish within interval range (start|end +/- 60 secs)
      const storePublishStart = moment.utc(selectedPublishStart, CHRONOLOGICAL_DATE_TIME_FORMAT).valueOf();
      const storePublishEnd = moment.utc(selectedPublishEnd, CHRONOLOGICAL_DATE_TIME_FORMAT).valueOf();
      // console.log('storeStart :>> ', moment.utc(storePublishStart).format());
      // console.log('storeEnd :>> ', moment.utc(storePublishEnd).format());
      idx = data.findIndex((publish) => {
         const rowPublishStart = moment.utc(publish.start, CHRONOLOGICAL_DATE_TIME_FORMAT).valueOf();
         const rowPublishEnd = moment.utc(publish.end, CHRONOLOGICAL_DATE_TIME_FORMAT).valueOf();
         return (
            rowPublishStart - TIME_UNCERTAINTY_DELTA_MILLISECS < storePublishStart &&
            rowPublishStart + TIME_UNCERTAINTY_DELTA_MILLISECS > storePublishStart &&
            rowPublishEnd + TIME_UNCERTAINTY_DELTA_MILLISECS > storePublishEnd &&
            rowPublishEnd - TIME_UNCERTAINTY_DELTA_MILLISECS < storePublishEnd
         );
      });

      const publishNotFoundWithinTimeRange = idx === -1;
      if (publishNotFoundWithinTimeRange) {
         // search for publish which matches with given time range the most
         const intersectionPercentages = data.map((publish) => {
            const rowPublishStart = moment.utc(publish.start, CHRONOLOGICAL_DATE_TIME_FORMAT).valueOf();
            const rowPublishEnd = moment.utc(publish.end, CHRONOLOGICAL_DATE_TIME_FORMAT).valueOf();

            const storePublishDuration = storePublishEnd - storePublishStart;
            const startDiff = storePublishStart - rowPublishStart;
            const endDiff = storePublishEnd - rowPublishEnd;
            const intersectionStart = startDiff >= 0 ? storePublishStart : rowPublishStart;
            const intersectionEnd = endDiff >= 0 ? rowPublishEnd : storePublishEnd;

            const intersectionDuration = intersectionEnd - intersectionStart;
            return (intersectionDuration * 100) / storePublishDuration;
         });
         const highestIntersectionIdx = intersectionPercentages.indexOf(Math.max(...intersectionPercentages));
         idx = intersectionPercentages[highestIntersectionIdx] > 0 ? highestIntersectionIdx : -1;
      }
      const publishFoundWhileSynchronize = idx !== -1;
      if (publishFoundWhileSynchronize) {
         const publishStart = getIntervalStart(data[idx].start, interval.value);
         const publishEnd = getIntervalEnd(data[idx].end, interval.value);
         let fineStart = getIntervalStart(selectedFineStart, interval.value);
         let fineEnd = getIntervalEnd(selectedFineEnd, interval.value);
         fineStart = moment
            .utc(publishStart, CHRONOLOGICAL_DATE_TIME_FORMAT)
            .isAfter(moment.utc(fineStart, CHRONOLOGICAL_DATE_TIME_FORMAT))
            ? publishStart
            : fineStart;
         fineEnd = moment
            .utc(publishEnd, CHRONOLOGICAL_DATE_TIME_FORMAT)
            .isBefore(moment.utc(fineEnd, CHRONOLOGICAL_DATE_TIME_FORMAT))
            ? publishEnd
            : fineEnd;
         dispatch(
            setTroubleshootingSelectedPublish(
               {
                  publishStart: data[idx].start,
                  publishEnd: data[idx].end,
                  publishFineStart: fineStart,
                  publishFineEnd: fineEnd,
               },
               {
                  publishStart: data[idx].start,
                  publishEnd: data[idx].end,
                  publishFineStart: fineStart,
                  publishFineEnd: fineEnd,
               },
            ),
         );
      }
   }

   if (MAX_ROWS - 1 < idx) {
      const dataCopy = [...data];
      const swapTemp = dataCopy[idx];
      dataCopy[idx] = dataCopy[MAX_ROWS - 1];
      dataCopy[MAX_ROWS - 1] = swapTemp;
      setShownData(dataCopy);
      idx = MAX_ROWS - 1;
   }

   return idx === -1 ? [] : [idx];
}

function selectedPublishHasBeenChanged(selectedPublish, publishIdxArray) {
   const oldPublishUnselected = selectedPublish.length === 0;
   const newPublishUnselected = publishIdxArray.length === 0;
   if (oldPublishUnselected && newPublishUnselected) return false;
   if (selectedPublish.length !== publishIdxArray.length) return true;
   if (selectedPublish[0] === publishIdxArray[0]) return false;
   return true;
}

PublishesTable.propTypes = {
   streamName: PropTypes.string,
   created: PropTypes.string,
};

export default PublishesTable;
