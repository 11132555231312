import React from 'react';
import { TitleBar, CustomCardBody } from '../../../../styledComponents/styledComponents';
import { Panel } from '../Styles';
import PlayoutSearch from './PlayoutSearch';

function PlayoutPanel() {
   return (
      <>
         <Panel>
            <CustomCardBody>
               <TitleBar>Playout</TitleBar>
               <PlayoutSearch />
            </CustomCardBody>
         </Panel>
      </>
   );
}

export default PlayoutPanel;
