import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { USAGE_METRICS } from '../../../constants/general';
import { Tooltip as ReactTooltip } from 'react-tooltip/dist/react-tooltip.umd';
import ReactDOMServer from 'react-dom/server';
import { uniqueIdFactory } from '../../../util/UtilFunctions';

function AggLimitHint({ usage, term }) {
   const [uid] = useState(uniqueIdFactory('_'));

   const id = `traffic${uid}`;

   const tipText = ReactDOMServer.renderToStaticMarkup(
      <>{`The data has been aggregated by the ${
         usage === USAGE_METRICS.bytes ? 'byte usage' : 'playback time'
      } in descending order with a maximum size of 1,000 ${term}.`}</>,
   );

   return (
      <>
         <i data-tooltip-id={id} data-tooltip-html={tipText} className='bi bi-info-circle ml-1'></i>
         <ReactTooltip
            id={id}
            place={'top'}
            className={'reduced-line-height bring-on-top'}
            backgroundColor={'rgba(0,0,0,0.9)'}
            textColor={'rgb(240,240,240)'}
            style={{ textAlign: 'center' }}
         />
      </>
   );
}

AggLimitHint.propTypes = {
   usage: PropTypes.string,
   term: PropTypes.string,
};

export default AggLimitHint;
