import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAlarm } from '../../../../redux/actions/general';

import useDataFetch from '../../../../hooks/useDataFetch';
import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';

import {
   hashSelector,
   countriesSelector,
   tagsSelector,
   cancelTokenSelector,
   startTimespanSelector,
   endTimespanSelector,
   usageSwitchSelector,
   streamNamesSelector,
   titleTimespanSelector,
} from '../../../../redux/selectors/selectors';

import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   RemoveBottomMargin,
   ShowOnRightSide,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetFooterContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import roundTo from 'round-to';

import { CardTitle, Col, Row } from 'reactstrap';
import { addSeparatorAndThreeDecimalPlaces, getIntegerWithDecimalSeparator } from '../../../../util/NumberFormatter';
import { BlockButton, CsvExportFromApiButton, Spinner } from '../../../common';
import { BREAKDOWN_VIEW, PLAYOUT, REFERRER_BLOCKLIST, USAGE_AGG_TYPE } from '../../../../constants/general';
import { showOverflowTooltip } from '../../../common/OverflowTooltip/OverflowTooltip';
import BlockListFetcher from '../../../../dataFetching/BlockListFetcher';
import useSignal from '../../../../hooks/useSignal';
import BlockingIndicator from '../../common/BlockingIndicator';
import { getDomainFromURL } from '../../../../util/UtilFunctions';
import { REFERRER } from '../../../../constants/dataGroup';
import TrafficHint from '../../../common/Hints/TrafficHint';
import AggLimitHint from '../../../common/Hints/AggLimitHint';
import { JwtAudienceOptions } from '../../../../constants/AccessLevels';

const { SearchBar } = Search;

const USAGE_SETTINGS = {
   bytes: {
      title: 'GBytes per Referrer',
      unit: 'GB',
      unitConversion: (value) => roundTo(value / 1e9, 3),
      resUnitPropNames: 'bytes',
   },
   playtime: {
      title: 'Hours per Referrer',
      unit: 'h',
      unitConversion: (value) => roundTo(value / 3600, 3),
      resUnitPropNames: 'seconds',
   },
};

const getColumnsOptions = (unit, blockedMap, triggerBlockListRefreshFn) => {
   const cols = [
      {
         text: 'Referrer',
         dataField: 'adressName',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         // headerStyle: { width: '50%' },
         headerAlign: 'right',
         formatter: showOverflowTooltip,
      },
      {
         text: `Playout [${unit}]`,
         dataField: 'playout',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         headerStyle: { width: '145px' },
         headerAlign: 'right',
         formatter: addSeparatorAndThreeDecimalPlaces,
         searchable: false,
      },
   ];

   const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
   const hasGuardianAccess = userInfo.aud.includes(JwtAudienceOptions.GUARDIAN);

   if (hasGuardianAccess) {
      cols.push({
         text: 'Blocked',
         dataField: 'blocked',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'center' },
         formatter: (cell) => (cell === 'N/A' ? 'N/A' : <BlockingIndicator isBlocked={cell} />),
         headerAlign: 'center',
         headerStyle: { width: '95px' },
         searchable: false,
      });
      cols.push({
         text: '',
         dataField: 'action',
         sort: false,
         style: { verticalAlign: 'middle', textAlign: 'center' },
         formatter: (cell) =>
            cell === 'N/A' || !blockedMap ? (
               'N/A'
            ) : (
               <BlockButton
                  valueToBeBlocked={cell}
                  type={REFERRER_BLOCKLIST}
                  blockedMap={blockedMap}
                  triggerBlockListRefreshFn={triggerBlockListRefreshFn}
               />
            ),
         headerAlign: 'center',
         headerStyle: { width: '60px' },
         searchable: false,
      });
   }

   return cols;
};

function UsageByClient() {
   const [blockingListTriggerFlag, triggerBlockListRefreshFn] = useSignal();
   const [blockedMap, setBlockedMap] = useState();

   const [trafficData, setTrafficData] = useState([]);
   const [tableData, setTableData] = useState([]);
   const [rowCount, setRowCount] = useState(0);
   const [total, setTotal] = useState(0);

   const hash = useSelector(hashSelector);
   const countries = useSelector(countriesSelector);
   const tags = useSelector(tagsSelector);
   const streamNames = useSelector(streamNamesSelector);

   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);
   const timespanTitle = useSelector(titleTimespanSelector);

   const usageSwitch = useSelector(usageSwitchSelector);
   const usageSwitchRef = useRef(usageSwitch);
   usageSwitchRef.current = usageSwitch;

   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, [], streamNames, BREAKDOWN_VIEW);
   const url = `/api/v2/usage/${REFERRER}/${
      USAGE_AGG_TYPE.playout
   }/${usageSwitch}?from=${start.format()}&to=${end.format()}${urlFilterQuery}`;
   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      triggerBlockListRefreshFn();
   }, [url, header, cancelToken, triggerBlockListRefreshFn]);

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            const { tableData, total, count } = getData(data.data, usageSwitch, USAGE_AGG_TYPE.playout);
            setTrafficData(tableData);
            setTotal(total);
            setRowCount(count);
         }
      }
      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, dispatch, usageSwitch]);

   useEffect(() => {
      setTableData(addBlockState(trafficData, blockedMap));
   }, [trafficData, blockedMap]);

   const afterSearch = (newResult) => calculateTotalsAfterSearch(newResult, total, setTotal, setRowCount);

   const { title, unit } = USAGE_SETTINGS[usageSwitch];

   return (
      <WidgetContainer>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow>
                     <CsvExportFromApiButton
                        url={url}
                        header={header}
                        cancelToken={cancelToken}
                        filename={'Usage_ByClient'}
                     />
                  </SimpleFlexRow>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <TrafficHint term={'playout'} hasTimeAxis={true} sizePx={16} />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer $minHeight={'680px'}>
            <Row>
               <Col>
                  <CardTitle className='mb-0'>{title}</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </Col>
            </Row>
            <ChartWrapper className='chart-wrapper' marginTopPx={15}>
               <Spinner loading={isLoading} parentTopMarginPx={-250}>
                  {errorMessage ? (
                     <CenteredMsgShowing height={602} marginTopPx={0}>
                        {errorMessage.message}
                     </CenteredMsgShowing>
                  ) : (
                     <RemoveBottomMargin>
                        <ToolkitProvider
                           keyField='adressName'
                           data={tableData}
                           columns={getColumnsOptions(unit, blockedMap, triggerBlockListRefreshFn)}
                           search={{ afterSearch }}
                           bootstrap4
                        >
                           {(props) => (
                              <>
                                 <ShowOnRightSide>
                                    <SearchBar {...props.searchProps} tableId={'client'} />
                                 </ShowOnRightSide>
                                 <BootstrapTable
                                    {...props.baseProps}
                                    classes='fixed-table'
                                    pagination={paginationFactory({
                                       sizePerPage: 10,
                                       hideSizePerPage: true,
                                    })}
                                    striped
                                    hover
                                 />
                              </>
                           )}
                        </ToolkitProvider>
                     </RemoveBottomMargin>
                  )}
               </Spinner>
            </ChartWrapper>
         </WidgetBodyContainer>
         <WidgetFooterContainer>
            <Row>
               <Col className='text-right'>Found Referrers:</Col>
               <Col>
                  {isLoading || errorMessage ? '0' : getIntegerWithDecimalSeparator(rowCount)}
                  <AggLimitHint usage={usageSwitchRef.current} term={'referrers'} />
               </Col>
            </Row>
         </WidgetFooterContainer>
         <BlockListFetcher
            type={REFERRER_BLOCKLIST}
            refreshTriggerFlag={blockingListTriggerFlag}
            setBlockedMap={setBlockedMap}
         />
      </WidgetContainer>
   );
}

function getData(data, usageSwitch, pi = PLAYOUT) {
   const unitPropName = USAGE_SETTINGS[usageSwitch].resUnitPropNames;
   const unitConvertFn = USAGE_SETTINGS[usageSwitch].unitConversion;

   let total = 0;
   const tableData = [];

   data.forEach((clientBucket) => {
      const usage = clientBucket[pi][unitPropName];
      total += usage;

      tableData.push({
         adressName: clientBucket[REFERRER],
         playout: unitConvertFn(usage),
      });
   });

   total = unitConvertFn(total);
   const count = tableData.length;
   return { tableData, total, count };
}

function calculateTotalsAfterSearch(result, total, setTotal, setRowCount) {
   const delta = 0.001;

   let currSumPi = 0;

   for (let i = 0; i < result.length; i++) {
      currSumPi += result[i]['playout'];
   }

   if (total < currSumPi - delta || total > currSumPi + delta) {
      const searchResultTotal = currSumPi;
      const searchResultRowCount = result.length;
      setTotal(searchResultTotal);
      setRowCount(searchResultRowCount);
   }
}

function addBlockState(trafficList, blockedMap) {
   return blockedMap !== undefined
      ? trafficList.map((row) =>
           blockedMap[getDomainFromURL(row.adressName)]
              ? { ...row, blocked: true, action: row.adressName }
              : { ...row, blocked: false, action: row.adressName },
        )
      : trafficList.map((row) => ({ ...row, blocked: 'N/A', action: '' }));
}

export default UsageByClient;
