import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PropTypes } from 'prop-types';

import { MetricsContainer, MetricItem } from '../Styles';

import DateTimeSlider from '../general/DateTimeSlider';
import {
   troubleIntervalSelector,
   troubleModeSelector,
   troubleSelectedPlayoutSelector,
} from '../../../../redux/selectors/selectors';
import { setTroubleshootingPlayoutFineTimespan, syncTroubleshootingIngest } from '../../../../redux/actions/general';
import { E2E } from '../../../../constants/general';
import { getIntervalStart, getIntervalEnd, getStartEnd } from '../general/DataProcessing';
import DefaultPlayoutMetric from './widgets/DefaultPlayoutMetric';
import EventTable from './EventTable';
import { Button } from 'reactstrap';
import { CHRONOLOGICAL_DATE_TIME_FORMAT, MAX_RAW_MODE_TIME_RANGE_IN_SECS } from '../constants';
import DefaultPlayoutRawMetric from './widgets/DefaultPlayoutRawMetric';
import { bufferingEventTimeChartOptions, rawEventChartOptions } from '../../../../constants/chartjs';
import { CenterContainer, SimpleHorizontalSeparator } from '../../../../styledComponents/styledComponents';

function PlayoutMetrics({ playoutStart, playoutEnd, streamName, events }) {
   const dispatch = useDispatch();
   const mode = useSelector(troubleModeSelector);
   const { playerId, playoutFineStart, playoutFineEnd } = useSelector(troubleSelectedPlayoutSelector);
   const interval = useSelector(troubleIntervalSelector);
   const { start, end } = getStartEnd(playoutFineStart, playoutFineEnd, interval);
   const setFineInterval = useCallback(
      (fineInterval) => {
         const newPlayoutFineInterval = {
            playoutFineStart: fineInterval.fineStart,
            playoutFineEnd: fineInterval.fineEnd,
         };
         dispatch(setTroubleshootingPlayoutFineTimespan(newPlayoutFineInterval, newPlayoutFineInterval));
      },
      [dispatch],
   );

   const urlSyncParams = {
      streamName,
      publishStart: start.format(CHRONOLOGICAL_DATE_TIME_FORMAT),
      publishEnd: end.format(CHRONOLOGICAL_DATE_TIME_FORMAT),
      publishFineStart: start.format(CHRONOLOGICAL_DATE_TIME_FORMAT),
      publishFineEnd: end.format(CHRONOLOGICAL_DATE_TIME_FORMAT),
      troubleMode: E2E,
   };

   const isRawModeActive = end.unix() - start.unix() <= MAX_RAW_MODE_TIME_RANGE_IN_SECS;

   return (
      <>
         <SimpleHorizontalSeparator spacePx={20} border={'1px dotted  rgb(180,180,180)'} />
         <DateTimeSlider
            startDateString={getIntervalStart(playoutStart, interval.value)}
            endDateString={getIntervalEnd(playoutEnd, interval.value)}
            fineStartString={playoutFineStart}
            fineEndString={playoutFineEnd}
            setFineInterval={setFineInterval}
            interval={interval}
         />
         <CenterContainer marginTopPx={10}>
            <Button className='ml-2' color='primary' onClick={() => dispatch(syncTroubleshootingIngest(urlSyncParams))}>
               {'Synchronize ingest'}
            </Button>
         </CenterContainer>
         <MetricsContainer>
            <MetricItem isFullScreen={mode !== E2E}>
               {isRawModeActive ? (
                  <DefaultPlayoutRawMetric
                     start={start}
                     end={end}
                     playerId={playerId}
                     playoutStart={playoutStart}
                     playoutEnd={playoutEnd}
                     urlName={'bitrate'}
                     title={'Bitrate [kBits/s]'}
                     streamName={streamName}
                     events={events}
                     chartOptionFn={rawEventChartOptions}
                     csvFilename={'Playout_Bitrate'}
                  />
               ) : (
                  <DefaultPlayoutMetric
                     start={start}
                     end={end}
                     playerId={playerId}
                     playoutStart={playoutStart}
                     playoutEnd={playoutEnd}
                     urlName={'bitrate'}
                     title={'Bitrate [kBits/s]'}
                     streamName={streamName}
                     events={events}
                     csvFilename={'Playout_Bitrate'}
                  />
               )}
            </MetricItem>
            <MetricItem isFullScreen={mode !== E2E}>
               {isRawModeActive ? (
                  <DefaultPlayoutRawMetric
                     start={start}
                     end={end}
                     playerId={playerId}
                     playoutStart={playoutStart}
                     playoutEnd={playoutEnd}
                     urlName={'latency'}
                     title={'Latency [s]'}
                     streamName={streamName}
                     events={events}
                     chartOptionFn={rawEventChartOptions}
                     csvFilename={'Playout_Latency'}
                  />
               ) : (
                  <DefaultPlayoutMetric
                     start={start}
                     end={end}
                     playerId={playerId}
                     playoutStart={playoutStart}
                     playoutEnd={playoutEnd}
                     urlName={'latency'}
                     title={'Latency [s]'}
                     streamName={streamName}
                     events={events}
                     csvFilename={'Playout_Latency'}
                  />
               )}
            </MetricItem>
            <MetricItem isFullScreen={mode !== E2E}>
               {isRawModeActive ? (
                  <DefaultPlayoutRawMetric
                     start={start}
                     end={end}
                     playerId={playerId}
                     playoutStart={playoutStart}
                     playoutEnd={playoutEnd}
                     urlName={'buffering'}
                     title={'Buffering'}
                     streamName={streamName}
                     events={events}
                     chartOptionFn={bufferingEventTimeChartOptions}
                     csvFilename={'Playout_Buffering'}
                  />
               ) : (
                  <DefaultPlayoutMetric
                     start={start}
                     end={end}
                     playerId={playerId}
                     playoutStart={playoutStart}
                     playoutEnd={playoutEnd}
                     urlName={'buffering'}
                     title={'Buffering Totaltime Ratio [%]'}
                     streamName={streamName}
                     events={events}
                     csvFilename={'Playout_Buffering'}
                  />
               )}
            </MetricItem>
            {events && events.length > 0 && (
               <MetricItem isFullScreen={mode !== E2E}>
                  <EventTable events={events} start={start} end={end} streamName={streamName} />
               </MetricItem>
            )}
         </MetricsContainer>
      </>
   );
}

PlayoutMetrics.propTypes = {
   playoutStart: PropTypes.string,
   playoutEnd: PropTypes.string,
   streamName: PropTypes.string,
   events: PropTypes.array,
};

export default PlayoutMetrics;
