export const GREEN_TO_RED_10_STEPS = [
   '#3AB12F',
   '#66CB34',
   '#85CC33',
   '#A7CF30',
   '#D4C32B',
   '#D19D2E',
   '#D67729',
   '#D95F26',
   '#D13F0A',
   '#DB2427',
];

export const GREEN_TO_RED_10_STEPS_LIGHT = [
   '#00CC14',
   '#74E63B',
   '#95E639',
   '#BBE836',
   '#EDDA30',
   '#EBB134', // H41 S78 V92
   '#F0852E', // H27 S80.8 V94
   '#F26A2A', // H19 S82.5 V95
   '#EB470B', // H16 S95.2 V92
   '#F5282C', // H359 S83.6 V96
];

export const GREEN_TO_RED_10_STEPS_HSL = [
   [115, 58, 44],
   [100, 59, 50],
   [88, 60, 50],
   [75, 62, 50],
   [54, 66, 50],
   [41, 64, 50],
   [27, 68, 50],
   [19, 70, 50],
   [16, 91, 43],
   [359, 72, 40],
];

export const DefaultBlue = (alpha = 1) => `rgba(32, 168, 216, ${alpha})`;
export const DefaultGrey = (alpha = 1, value = 242) => `rgba(${value}, ${value}, ${value}, ${alpha})`;
export const LightDark = '#23282c';
export const DefaultOrange = 'rgb(255,147,63)';
