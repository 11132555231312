import roundTo from 'round-to';
import { getWorldRegionsAggObject } from '../WorldMapConstants';
import { getOutput } from './General';

const country_alpha_id_mapper = require('../../../../assets/worldmap/compact_country_alpha_id_mapper.json');

export function processBufferingRatioAdapter(countries) {
   const adaptedCountries = countries.map((countryBucket) => {
      const { country, alpha2, totalPlaybackTime, bufferingRatio } = countryBucket;
      const { coordinates, worldRegion, code } = country_alpha_id_mapper[alpha2];
      const coor = [coordinates.longitude, coordinates.latitude];
      return {
         alpha: alpha2,
         coor,
         id: code.numeric,
         name: country,
         worldRegion,
         ratio: bufferingRatio.percent,
         total: totalPlaybackTime.seconds,
      };
   });

   const worldRegions = createWorldRegionsAndTopData(adaptedCountries);

   return getOutput(adaptedCountries, worldRegions);
}

function createWorldRegionsAndTopData(countries) {
   const worldRegions = getWorldRegionsAggObject({
      timeWeightedBufferRatiosSum: 0,
      totalTimeSum: 0,
   });

   countries.forEach((country) => {
      const { worldRegion, ratio, total } = country;
      worldRegions[worldRegion].totalTimeSum += total;
      worldRegions[worldRegion].timeWeightedBufferRatiosSum += total * ratio;
      worldRegions[worldRegion].countries.push(country);
   });

   Object.keys(worldRegions).forEach((worldRegion) => {
      worldRegions[worldRegion].ratio = roundTo(
         worldRegions[worldRegion].timeWeightedBufferRatiosSum / worldRegions[worldRegion].totalTimeSum,
         1,
      );
      worldRegions[worldRegion].total = worldRegions[worldRegion].totalTimeSum;
      delete worldRegions[worldRegion].timeWeightedBufferRatiosSum;
      delete worldRegions[worldRegion].totalTimeSum;
   });

   return worldRegions;
}
