import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, HorizontalSeparator, Spinner } from '../../../common';

import { errorCodeTrafficLight } from '../../../../assets/trafficLightGrouping';

import { CardTitle, Col, Row } from 'reactstrap';

import { timespanOrIntervalChanged, filtersChanged } from '../../../../redux/selectors/comparing';

import 'spinkit/css/spinkit.css';

import { PropTypes } from 'prop-types';
import storePropTypes from '../../../../redux/store/propTypes';

import ComponentTools from '../../../../util/ComponentTools';
import { H5LIVE_VIEW } from '../../../../constants/general';
import { barChartZoomOptions } from '../../../../constants/chartjs';
import {
   CenteredMsgShowing,
   ChartWrapper,
   DescriptionLink,
   HeightSetContainer,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   StyledButton,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';

import { ERROR_CODES } from '../../../zoom/ZoomConstants';
import Modal from '../../../zoom/templates/DefaultTemplate';
import ZoomHint from '../../../zoom/ZoomHint';
import { H5LIVE_SETTINGS } from '../../../zoom/widgetSettings/H5liveView';
import { getUnifiedErrorObject } from '../../../../util/ErrorUnifier';
import { DefaultBlue, DefaultGrey } from '../../../../constants/colors';

const chartOptions = barChartZoomOptions();

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setAlarm };

function getNewDataSet(label, color) {
   return {
      total: 0,
      label: 'Code ' + label,
      backgroundColor: hexToRgba(color, 10),
      borderColor: color,
      borderWidth: 1,
      data: [],
      hidden: false,
   };
}

function getTrafficLightColor(status) {
   if (status === 'expected') return '#20c997';
   if (status === 'notCritical') return '#f8cb00';
   return '#e83e8c';
}

let requestForData = false;

class ErrorCodes extends Component {
   constructor(props) {
      super(props);

      this.state = {
         loading: false,
         chart: {
            type: 'bar',
            labels: [],
            datasets: [],
         },
         modal: {
            toggleShow: false,
            timespansPerIndex: [
               {
                  start: moment.utc(),
                  end: moment.utc(),
               },
            ],
            chosenIndex: 0,
            selectedError: '',
         },
         error: '',
         noData: false,
         url: '',
         header: {},
         cancelToken: null,
      };
      this.chartRef = React.createRef();
   }

   getData(timespan, filter, cancelToken) {
      this.setState({
         ...this.state,
         loading: true,
         noData: false,
         error: '',
      });

      const self = this;

      const headerObj = ComponentTools.getHttpHeader(filter);
      const urlFilterQuery = ComponentTools.getFilterQueryParams(filter, H5LIVE_VIEW);

      const updated = {
         type: 'bar',
         labels: [],
         datasets: [],
      };

      const labels = [];
      const timespansPerIndex = [];

      ComponentTools.setBarChartLabels(updated, labels, timespan, this.props.settings.weekType, timespansPerIndex);

      const url = `/api/v2/h5live/errorcodes/timeseries?from=${timespan.gte.format()}&to=${timespan.lt.format()}&interval=${
         timespan.interval
      }${urlFilterQuery}`;

      axios
         .get(url, {
            headers: headerObj,
            cancelToken: cancelToken.token,
         })
         .then(function (response) {
            let noData = false;
            if (response.status !== 204) {
               let buckets = response.data.aggregations.histogram.buckets;
               noData = buckets.reduce((sum, dataPoint) => (sum += dataPoint.errors.buckets.length), 0) === 0;

               // parse response data
               const errorCodes = {};
               const errors = [];

               labels.forEach((label) => {
                  const start = label.start;
                  const end = label.end;
                  const interval = buckets.find((bucket) => bucket.key >= start && bucket.key < end);
                  if (interval !== undefined && interval.errors.buckets.length !== 0) {
                     interval.values = [];
                     interval.errors.buckets.forEach((errorCode) => {
                        const error = {};
                        error.errorCode = errorCode.key;
                        error.count = errorCode.doc_count;
                        interval.values.push(error);
                        if (!errorCodes[errorCode.key]) {
                           errorCodes[errorCode.key] = true;
                        }
                     });
                     errors.push(interval);
                  } else errors.push({ values: [] });
               });

               // sort errorcode into traffic light buckets (red, yellow, green)
               const sortedErrorCodes = {
                  expected: [],
                  notCritical: [],
                  critical: [],
               };

               Object.keys(errorCodes).forEach((errorKey) => {
                  if (errorCodeTrafficLight.critical.includes(+errorKey)) sortedErrorCodes.critical.push(+errorKey);
                  else if (errorCodeTrafficLight.notCritical.includes(+errorKey))
                     sortedErrorCodes.notCritical.push(+errorKey);
                  else if (errorCodeTrafficLight.expected.includes(+errorKey))
                     sortedErrorCodes.expected.push(+errorKey);
                  else if (process.env.NODE_ENV === 'development') {
                     console.log('Error code was not found: ', errorKey);
                  }
               });
               // console.log("sortedErrorCodes", sortedErrorCodes)

               // create new datasets with traffic light
               Object.keys(sortedErrorCodes).forEach((criticalLevel) => {
                  sortedErrorCodes[criticalLevel].forEach((errorKey) => {
                     updated.datasets.push(getNewDataSet(errorKey, getTrafficLightColor(criticalLevel)));
                  });
               });
               // console.log("dataset", updated.datasets)

               // populate data in datasets
               updated.datasets.forEach((dataset) => {
                  errors.forEach((intervalObj) => {
                     const valueWasPushed = intervalObj.values.some((errorObj) => {
                        if (dataset.label === 'Code ' + errorObj.errorCode) {
                           dataset.data.push(errorObj.count);
                           dataset.total += errorObj.count;
                           return true;
                        } else return false;
                     });
                     if (!valueWasPushed) dataset.data.push(0);
                  });
               });
            }

            self.setState({
               chart: updated,
               noData,
               loading: false,
               modal: { ...self.state.modal, timespansPerIndex },
               url,
               header: headerObj,
               cancelToken,
            });
         })
         .catch(function (error) {
            if (error instanceof axios.Cancel) {
               return;
            }

            const errorObject = getUnifiedErrorObject(error);

            self.props.setAlarm('danger', errorObject);

            if (process.env.NODE_ENV === 'development') {
               console.error('error :', error);
            }

            self.setState({
               ...self.state,
               chart: updated,
               loading: false,
               error: errorObject.message,
            });
         });
   }

   componentDidMount() {
      requestForData = true;
   }

   componentWillReceiveProps(nextProps, nextContext) {
      if (timespanOrIntervalChanged(this.props, nextProps) || filtersChanged(this.props, nextProps) || requestForData) {
         requestForData = false;
         this.getData(nextProps.timespan, nextProps.filter, nextProps.cancelToken);

         chartOptions['onClick'] = (event, item) => {
            if (item.length === 0) {
               return;
            }
            const activePoint = this.chartRef.current.chartInstance.getElementAtEvent(event)[0];
            const datasetIndex = activePoint._datasetIndex;
            const data = activePoint._chart.data;
            const clickedLabel = data.datasets[datasetIndex].label;
            const index = item[0]._index;

            this.setState({
               modal: {
                  ...this.state.modal,
                  chosenIndex: index,
                  selectedError: clickedLabel.substring(5),
                  toggleShow: true,
               },
            });
         };
      }
   }

   render() {
      const errorOrNoData = this.state.error || this.state.noData;
      const issueMsg = this.state.error ? this.state.error : 'No data available';

      const { toggleShow, selectedError, timespansPerIndex, chosenIndex } = this.state.modal;
      const chosenTimespan = timespansPerIndex[chosenIndex];
      const { url, header, cancelToken } = this.state;

      return (
         <WidgetContainer>
            <WidgetHeaderContainer>
               <HeightSetContainer $heightInPx={23}>
                  <TwoSideFlexContainer>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <StyledButton
                              type='button'
                              fontSizePx={10.5}
                              className={'btn'}
                              fixMargin
                              name={'csvBtn'}
                              id={'csvBtn'}
                              heightPx={20}
                              widthPx={120}
                              bgColor={DefaultBlue(0.55)}
                              fontColor={'black'}
                              bgColorHover={DefaultBlue(0.4)}
                              fontColorHover={'black'}
                              border={'1px solid rgba(0,0,0,0.2)'}
                              $buttonHeightPx={19}
                           >
                              <DescriptionLink
                                 cursor={'pointer'}
                                 fontSizePX={10.5}
                                 marginLeftPx={0}
                                 href='https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_api#NanoPlayer..errorcode'
                                 target='_blank'
                                 rel='noopener noreferrer'
                                 $color={'black'}
                              >
                                 View error codes
                              </DescriptionLink>
                           </StyledButton>
                        </SimpleFlexItem>
                        <HorizontalSeparator
                           color={DefaultGrey(1, 180)}
                           widthInPx={1}
                           heightInPx={18}
                           marginX_InPx={12}
                           marginTopInPx={0}
                        />
                        <SimpleFlexItem>
                           <CsvExportFromApiButton
                              url={url}
                              header={header}
                              cancelToken={cancelToken}
                              filename={'H5live_ErrorCodes'}
                           />
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <ZoomHint />
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                  </TwoSideFlexContainer>
               </HeightSetContainer>
            </WidgetHeaderContainer>
            <WidgetBodyContainer>
               <Row>
                  <Col>
                     <CardTitle className='mb-0'>Status/Error Codes</CardTitle>
                     <div className='small text-muted'>
                        {`${this.props.timespan.title}\u00A0\u00A0\u00A0( UTC time )`}
                     </div>
                  </Col>
               </Row>
               <ChartWrapper className='chart-wrapper' marginTopPx={20} height={this.props.height}>
                  <Spinner loading={this.state.loading} parentTopMarginPx={60}>
                     {errorOrNoData ? (
                        <CenteredMsgShowing height={this.props.height}>{issueMsg}</CenteredMsgShowing>
                     ) : (
                        <SetChartHeight height={this.props.height}>
                           <Bar ref={this.chartRef} data={this.state.chart} options={chartOptions} height={300} />
                        </SetChartHeight>
                     )}
                  </Spinner>
               </ChartWrapper>
            </WidgetBodyContainer>
            {toggleShow && (
               <Modal
                  term={selectedError}
                  timespan={chosenTimespan}
                  metric={ERROR_CODES}
                  closeModal={() =>
                     this.setState({
                        modal: {
                           ...this.state.modal,
                           toggleShow: false,
                        },
                     })
                  }
                  settings={H5LIVE_SETTINGS}
               />
            )}
         </WidgetContainer>
      );
   }
}

ErrorCodes.propTypes = {
   filter: storePropTypes.filter,
   timespan: storePropTypes.timespan,
   cancelToken: storePropTypes.cancelToken,
   settings: storePropTypes.settings,
   setAlarm: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorCodes);
