import { WORLD_REGIONS_SETTINGS, WORLD_REGIONS, ABR_PLAYTIME_OPTIONS } from './../WorldMapConstants';

function getCountriesData(metricData, subSwitch, settings, zoomedRegion = '') {
   const propName = subSwitch ? subSwitch : settings.metricName;
   if (zoomedRegion !== '') {
      return metricData[propName]['worldRegions'][zoomedRegion]['countries'];
   } else {
      return metricData[propName]['topCountries']['countries'];
   }
}

function shouldLegendBeShown(metricData) {
   return Object.keys(metricData).some((key) => metricData[key].topCountries.countries.length > 0);
}

function getWorldRegionsData(metricData, subSwitch, settings) {
   const propName = subSwitch ? subSwitch : settings.metricName;
   const worldRegionsData = metricData[propName]['worldRegions'];
   const worldRegions = WORLD_REGIONS.map((worldRegion) => {
      const region = {
         name: worldRegion,
         coor: WORLD_REGIONS_SETTINGS[worldRegion].coor,
      };

      if (subSwitch === ABR_PLAYTIME_OPTIONS.CHART || subSwitch === ABR_PLAYTIME_OPTIONS.TABLE) {
         const mobileProp = worldRegionsData[worldRegion]['mobileTotal'];
         region['mobileTotal'] = mobileProp !== undefined ? mobileProp : 0;
         const desktopProp = worldRegionsData[worldRegion]['desktopTotal'];
         region['desktopTotal'] = desktopProp !== undefined ? desktopProp : 0;
      }

      settings.worldRegionPropNames.forEach((countProp) => {
         const propValue = worldRegionsData[worldRegion][countProp];
         region[countProp] = propValue !== undefined ? propValue : 0;
      });

      return region;
   });
   return worldRegions.sort((a, b) => b[settings.countPropName] - a[settings.countPropName]);
}

function getMaxHeatValue(countries, settings) {
   if (countries.length === 0) {
      return 0;
   }

   if (!settings.maxHeatPropName) {
      return -1;
   }

   if (settings.maxHeatPropName === settings.countPropName) {
      return countries[0][settings.maxHeatPropName];
   } else {
      return Math.max.apply(
         Math,
         countries.map((country) => country[settings.maxHeatPropName]),
      );
   }
}

function getTotalValue(metricData, subSwitch, settings, zoomedRegion = '', perCountryView = true) {
   const propName = subSwitch ? subSwitch : settings.metricName;
   if (perCountryView) {
      if (zoomedRegion !== '') {
         return metricData[propName]['worldRegions'][zoomedRegion]['total'];
      } else {
         return metricData[propName]['topCountries']['total'];
      }
   } else {
      return WORLD_REGIONS.reduce(
         (total, worldRegion) => total + metricData[propName]['worldRegions'][worldRegion]['total'],
         0,
      );
   }
}

export default {
   getCountriesData,
   shouldLegendBeShown,
   getWorldRegionsData,
   getMaxHeatValue,
   getTotalValue,
};
