import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

const SwitchButtonGroup = styled.div`
   margin-bottom: ${(props) => props.marginBottom}px;
   margin-top: ${(props) => props.marginTop}px;
   ${(props) => props.height !== undefined && `height: ${props.height}px;`}
`;
SwitchButtonGroup.displayName = 'SwitchButtonGroup';

const SwitchButton = styled.div`
   width: ${(props) => props.width}px;
   -webkit-appearance: none;
   -moz-appearance: none;
   font-size: ${(props) => props.fontSize}px;
`;
SwitchButton.displayName = 'SwitchButton';

const SwitchLabel = styled.label`
   background-color: ${(props) => (props.isActive ? props.activeColor : props.inactiveColor)};
   color: ${(props) => (props.isActive ? props.activeFontColor : props.inactiveFontColor)};
   &:hover {
      background-color: ${(props) => (props.isActive ? props.activeColor : props.hoverColor)};
      color: ${(props) => props.activeFontColor};;
   }
   box-shadow: 3px 3px 10px -8px #ccc;
   cursor: pointer;
   ${(props) => props.height !== undefined && `line-height: ${props.height}px;`}
   ${(props) => props.height !== undefined && 'padding: 0px;'}
   ${(props) => props.fixMargin && 'margin-left: 0px !important;'}
`;
SwitchLabel.displayName = 'SwitchLabel';

function SwitchButtonBar({
   activeIndex,
   activePropName,
   labels,
   labelsFn,
   propNames,
   handleSwitch,
   buttonWidth,
   marginBottom,
   marginTop,
   buttonHeight,
   fontSize,
   activeColor,
   hoverColor,
   inactiveColor,
   activeFontColor,
   inactiveFontColor,
}) {
   return (
      <SwitchButtonGroup
         className='btn-group btn-group-toggle'
         data-toggle='buttons'
         marginBottom={marginBottom}
         marginTop={marginTop}
         height={buttonHeight}
      >
         {labels.map((label, index) => (
            <SwitchLabel
               key={label}
               className={'btn'}
               fixMargin
               isActive={propNames === undefined ? activeIndex === index : propNames[index] === activePropName}
               name={label}
               id={label}
               height={buttonHeight}
               onClick={() => (propNames === undefined ? handleSwitch(index) : handleSwitch(propNames[index]))}
               activeColor={activeColor}
               hoverColor={hoverColor}
               inactiveColor={inactiveColor}
               activeFontColor={activeFontColor}
               inactiveFontColor={inactiveFontColor}
            >
               <SwitchButton
                  type='button'
                  width={labels.length === 1 ? buttonWidth + 150 : buttonWidth}
                  fontSize={fontSize}
               >
                  {labelsFn ? labelsFn(label) : label}
               </SwitchButton>
            </SwitchLabel>
         ))}
      </SwitchButtonGroup>
   );
}

SwitchButtonBar.propTypes = {
   activeIndex: PropTypes.number,
   activePropName: PropTypes.string,
   labels: PropTypes.array,
   labelsFn: PropTypes.func,
   propNames: PropTypes.array,
   handleSwitch: PropTypes.func,
   buttonWidth: PropTypes.number,
   buttonHeight: PropTypes.number,
   marginBottom: PropTypes.number,
   marginTop: PropTypes.number,
   fontSize: PropTypes.number,
   activeColor: PropTypes.string,
   hoverColor: PropTypes.string,
   inactiveColor: PropTypes.string,
   activeFontColor: PropTypes.string,
   inactiveFontColor: PropTypes.string,
};

SwitchButtonBar.defaultProps = {
   buttonWidth: 100,
   marginBottom: 0,
   marginTop: 0,
   fontSize: 14,
   activeColor: 'rgb(255, 147, 63)',
   hoverColor: 'rgba(255, 147, 63, 0.8)',
   inactiveColor: 'rgb(216, 222, 227)',
   activeFontColor: 'white',
   inactiveFontColor: 'black',
};

export default SwitchButtonBar;
