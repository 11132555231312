import React from 'react';

import { CountryFilter, TagFilter, Filter, StreamNameFilter } from '..';

import { Col, Row } from 'reactstrap';

import { PropTypes } from 'prop-types';
import EventFilter from './../EventFilter/EventFilter';
import { HISTORY_VIEW, WORLD_VIEW } from '../../../constants/general';
import DatePickerWidget from '../DateTimePicker/DatePickerWidget';
import { eventSubMetrics, eventViews } from '../../../constants/events';
import { SHOW_FOR, ViewsWithShownTimeInterval } from '../../../constants/views';
import { MAX_DURATION_PER_VIEW } from '../../../constants/timeConstraints';

function showTimeInterval(props) {
   const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
   const isAdmin = userInfo.isAdmin;
   const view = props.location.pathname.substring(1);
   const userLevel = ViewsWithShownTimeInterval[view];
   if (userLevel === undefined) return false;
   if (userLevel === SHOW_FOR.ADMIN_ONLY) return isAdmin;
   return true;
}

function FilterComponent(props) {
   const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
   if (userInfo.isAdmin) {
      return <Filter {...props} />;
   }
   return <span />;
}

function showEventFilter(props) {
   const viewName = props.location.pathname.substring(1);
   return eventViews.includes(`${viewName}`);
}

function isEventFilterActiveOnWorldmap(props) {
   return isWorldMapViewOpened(props) ? eventSubMetrics.includes(`${props.worldMetric.value}`) : true;
}

function isWorldMapViewOpened(props) {
   const viewName = props.location.pathname.substring(1);
   return viewName === WORLD_VIEW;
}

function Dashboard(props) {
   const { children, history, location, view } = props;

   return (
      <Row>
         <Col>
            <FilterComponent history={history} location={location} />
            {location.pathname !== `/${HISTORY_VIEW}` ? (
               <>
                  <Row>
                     <Col>
                        <CountryFilter />
                     </Col>
                     <Col>
                        <TagFilter />
                     </Col>
                  </Row>
                  {showEventFilter(props) && (
                     <Row>
                        <Col>
                           <EventFilter isActive={isEventFilterActiveOnWorldmap(props)} />
                        </Col>
                        <Col></Col>
                     </Row>
                  )}
                  <Row>
                     <Col>
                        <StreamNameFilter />
                     </Col>
                  </Row>
               </>
            ) : (
               <Row>
                  <Col>
                     <CountryFilter />
                  </Col>
                  <Col></Col>
               </Row>
            )}
            <DatePickerWidget
               showTimeIntervalSelect={showTimeInterval(props)}
               maxInputTimeRange={MAX_DURATION_PER_VIEW[view]}
            />
            {children}
         </Col>
      </Row>
   );
}

Dashboard.propTypes = {
   children: PropTypes.any,
   history: PropTypes.any,
   location: PropTypes.any,
   view: PropTypes.string,
};

export default Dashboard;
