import React, { useMemo } from 'react';

import { PropTypes } from 'prop-types';
import useDataFetch from '../../../../hooks/useDataFetch';
import storePropTypes from '../../../../redux/store/propTypes';
import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';
import { TROUBLESHOOTING_VIEW } from '../../../../constants/general';

function IngestService({ ingestInput, url, orgaHash, cancelToken, onResult }) {
   const body = ingestInput;
   const ingestPropName = Object.keys(ingestInput)[0];

   const header = useMemo(() => getHttpHeader(orgaHash), [orgaHash]);
   const streamFilterArray = useMemo(() => [ingestInput[ingestPropName]], [ingestInput, ingestPropName]);

   const urlFilterQuery = getFilterQueryParams([], [], [], streamFilterArray, TROUBLESHOOTING_VIEW);
   const urlWithFilters = url + urlFilterQuery;

   const { successful, isLoading, errorMessage, httpStatus, data } = useDataFetch(urlWithFilters, {
      method: 'POST',
      body,
      header,
      cancelToken,
   });

   if (successful) {
      onResult({ isLoading, httpStatus, untouched: false, data: data.result });
   } else if (errorMessage) {
      onResult({ isLoading, error: errorMessage, untouched: false });
   } else {
      onResult({ untouched: true, isLoading });
   }

   return <></>;
}

IngestService.propTypes = {
   ingestInput: PropTypes.object,
   url: PropTypes.string,
   orgaHash: PropTypes.string,
   cancelToken: storePropTypes.cancelToken,
   onResult: PropTypes.func,
};

export default IngestService;
