import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import axios from 'axios';
import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { setAlarm } from '../../../../redux/actions/general';
import { Spinner } from '../../../common';

import { CardTitle, Col, Row } from 'reactstrap';

import { filtersChanged, timespanOrIntervalChanged } from '../../../../redux/selectors/comparing';

import 'spinkit/css/spinkit.css';

import { PropTypes } from 'prop-types';
import storePropTypes from '../../../../redux/store/propTypes';

import ReactDOMServer from 'react-dom/server';
import { Tooltip as ReactTooltip } from 'react-tooltip/dist/react-tooltip.umd';
import { chartjsColors, lineChartOptions } from '../../../../constants/chartjs';
import { H5LIVE_VIEW } from '../../../../constants/general';
import {
   AlignedIconContainer,
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   SetChartHeight,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';
import ComponentTools from '../../../../util/ComponentTools';
import { getUnifiedErrorObject } from '../../../../util/ErrorUnifier';
import { catchNull } from '../../../../util/UtilFunctions';

const chartOptions = lineChartOptions();

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setAlarm };

function getNewDataSet(label, color) {
   return {
      label: label + ' %',
      backgroundColor: hexToRgba(color, 10),
      borderColor: color,
      borderWidth: 2,
      data: [],
      hidden: false,
      fill: false,
   };
}

let requestForData = false;

class LatencyPercentiles extends Component {
   constructor(props) {
      super(props);

      this.state = {
         loading: false,
         chart: {
            type: 'line',
            labels: [],
            datasets: [],
         },
         error: '',
         noData: false,
      };
   }

   getData(timespan, filter, cancelToken) {
      this.setState({
         ...this.state,
         loading: true,
         error: '',
         noData: false,
      });

      const self = this;

      const headerObj = ComponentTools.getHttpHeader(filter);
      const urlFilterQuery = ComponentTools.getFilterQueryParams(filter, H5LIVE_VIEW);

      const updated = {
         type: 'line',
         labels: [],
         datasets: [],
      };

      const labels = [];

      ComponentTools.setLineChartLabels(updated, labels, timespan, this.props.settings.weekType);

      axios
         .get(
            `/api/v2/h5live/latency/percentiles/timeseries?from=${timespan.gte.format()}&to=${timespan.lt.format()}&interval=${
               timespan.interval
            }${urlFilterQuery}`,
            {
               headers: headerObj,
               cancelToken: cancelToken.token,
            },
         )
         .then(function (response) {
            let noData = false;

            if (response.status !== 204) {
               let buckets = response.data.aggregations.histogram.buckets;
               noData =
                  buckets.reduce((sum, dataPoint) => {
                     const percentileSum = dataPoint.bufferAvgs.values.reduce(
                        (pSum, percentile) => (pSum += catchNull(percentile.value)),
                        0,
                     );
                     return (sum += percentileSum);
                  }, 0) === 0;

               // parse response data
               const percentGroups = {};
               const bufferAvgs = [];

               labels.forEach((label) => {
                  const start = label.start;
                  const end = label.end;
                  const interval = buckets.find((bucket) => bucket.key >= start && bucket.key < end);
                  if (
                     interval !== undefined &&
                     interval.bufferAvgs.values.reduce(
                        (pSum, percentile) => (pSum += catchNull(percentile.value)),
                        0,
                     ) !== 0
                  ) {
                     interval.values = [];
                     interval.bufferAvgs.values.forEach((percentEle) => {
                        const percent = {};
                        percent.group = percentEle.key;
                        percent.value = Math.round(percentEle.value * 100) / 100;
                        interval.values.push(percent);
                        if (!percentGroups[percentEle.key]) {
                           percentGroups[percentEle.key] = true;
                        }
                     });
                     bufferAvgs.push(interval);
                  } else bufferAvgs.push({ values: [] });
               });

               const lastIdxWithData = [];

               // create new datasets
               Object.keys(percentGroups).forEach((percentKey, idx) => {
                  updated.datasets.push(getNewDataSet(percentKey, chartjsColors[idx % chartjsColors.length]));
                  lastIdxWithData.push(-1);
               });

               // populate data in datasets
               updated.datasets.forEach((dataset, datasetIdx) => {
                  bufferAvgs.forEach((intervalObj, idx) => {
                     const valueWasPushed = intervalObj.values.some((percentEle) => {
                        if (dataset.label.toString() === percentEle.group + ' %') {
                           dataset.data.push(percentEle.value);
                           return true;
                        } else {
                           return false;
                        }
                     });
                     if (!valueWasPushed) {
                        if (lastIdxWithData[datasetIdx] === -1) {
                           dataset.data.push(null);
                        } else {
                           dataset.data.push(0);
                        }
                     } else {
                        lastIdxWithData[datasetIdx] = idx;
                     }
                  });
               });

               updated.datasets.forEach((dataset, datasetIdx) => {
                  for (let index = lastIdxWithData[datasetIdx] + 1; index < dataset.data.length; index++) {
                     dataset.data[index] = null;
                  }
               });
            }

            self.setState({
               chart: updated,
               noData,
               loading: false,
            });
         })
         .catch(function (error) {
            if (error instanceof axios.Cancel) {
               return;
            }
            const errorObject = getUnifiedErrorObject(error);

            self.props.setAlarm('danger', errorObject);

            if (process.env.NODE_ENV === 'development') {
               console.error('error :', error);
            }

            self.setState({
               ...self.state,
               chart: updated,
               loading: false,
               error: errorObject.message,
            });
         });
   }

   componentDidMount() {
      requestForData = true;
   }

   componentWillReceiveProps(nextProps, nextContext) {
      if (timespanOrIntervalChanged(this.props, nextProps) || filtersChanged(this.props, nextProps) || requestForData) {
         requestForData = false;
         this.getData(nextProps.timespan, nextProps.filter, nextProps.cancelToken);
      }
   }

   render() {
      const errorOrNoData = this.state.error || this.state.noData;
      const issueMsg = this.state.error ? this.state.error : 'No data available';

      return (
         <WidgetContainer>
            <WidgetHeaderContainer>
               <HeightSetContainer $heightInPx={23}>
                  <TwoSideFlexContainer>
                     <SimpleFlexRow></SimpleFlexRow>
                     <SimpleFlexRow>
                        <AlignedIconContainer
                           data-tooltip-id='percentile'
                           data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                              <>
                                 <div>What is percentile? An example:</div>
                                 <div>If the 25 % line has a y-axis value of 3.1 anywhere on the x-axis</div>
                                 <div>it means that 25 % of the elements of the whole dataset</div>
                                 <div>at that time have a value below 3.1</div>
                              </>,
                           )}
                           className='bi bi-info-circle ml-1'
                        ></AlignedIconContainer>
                        <ReactTooltip
                           id='percentile'
                           place={'top'}
                           className={'reduced-line-height bring-on-top'}
                           backgroundColor={'rgba(0,0,0,0.9)'}
                           textColor={'rgb(240,240,240)'}
                           style={{ textAlign: 'center' }}
                        />
                     </SimpleFlexRow>
                  </TwoSideFlexContainer>
               </HeightSetContainer>
            </WidgetHeaderContainer>
            <WidgetBodyContainer>
               <Row>
                  <Col>
                     <CardTitle className='mb-0'>Percentiles of average player latency in seconds</CardTitle>
                     <div className='small text-muted'>
                        {`${this.props.timespan.title}\u00A0\u00A0\u00A0( UTC time )`}
                     </div>
                  </Col>
               </Row>
               <ChartWrapper className='chart-wrapper' marginTopPx={20} height={this.props.height}>
                  <Spinner loading={this.state.loading} parentTopMarginPx={60}>
                     {errorOrNoData ? (
                        <CenteredMsgShowing height={this.props.height}>{issueMsg}</CenteredMsgShowing>
                     ) : (
                        <SetChartHeight height={this.props.height}>
                           <Line data={this.state.chart} options={chartOptions} height={300} />
                        </SetChartHeight>
                     )}
                  </Spinner>
               </ChartWrapper>
            </WidgetBodyContainer>
         </WidgetContainer>
      );
   }
}

LatencyPercentiles.propTypes = {
   filter: storePropTypes.filter,
   timespan: storePropTypes.timespan,
   cancelToken: storePropTypes.cancelToken,
   settings: storePropTypes.settings,
   setAlarm: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(LatencyPercentiles);
