import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

const SwitchButtonGroup = styled.div`
   margin-bottom: ${(props) => props.marginBottom}px;
   margin-top: ${(props) => props.marginTop}px;
   margin-right: ${(props) => props.marginRight}px;
   margin-left: ${(props) => props.marginLeft}px;
   ${(props) => props.height !== undefined && `height: ${props.height}px;`}
`;

const SwitchButton = styled.div`
   width: ${(props) => props.width}px;
   -webkit-appearance: none;
   -moz-appearance: none;
   font-size: ${(props) => props.fontSize}px;
`;

const SwitchLabel = styled.label`
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   ${(props) => props.fixMargin && 'margin-left: 0px !important;'}
   ${(props) => props.height !== undefined && `line-height: ${props.height}px;`}
   ${(props) => props.height !== undefined && 'padding: 0px;'}
   ${(props) => props.bgColor !== undefined && `background-color: ${props.bgColor};`}
   ${(props) => props.fontColor !== undefined && `color: ${props.fontColor};`}
   ${(props) => props.fontWeight !== undefined && props.fontWeight !== '' && 'font-weight: 500;'}
   ${(props) => props.borderColor !== undefined && `border: 1px solid ${props.borderColor};`}
   transition: color 0.3s;
   transition: background-color 0.3s;   
   ${(props) => props.noBorder && props.noBorder}
   ${(props) => props.noBorder && `&:hover { ${props.noBorder} }`}
   ${(props) => props.fontColor && `&:hover { color: ${props.fontColor}; }`}
`;

function SwitchButtons({
   leftActive,
   leftLabel,
   leftName,
   rightLabel,
   rightName,
   handleSwitch,
   buttonWidth,
   marginBottom,
   marginTop,
   marginLeft,
   marginRight,
   buttonHeight,
   fontSize,
   activeFontWeight,
   activeBackgroundColor,
   inactiveBackgroundColor,
   activeFontColor,
   inactiveFontColor,
   fixedBorder,
}) {
   const customColoringActive =
      activeBackgroundColor && inactiveBackgroundColor && activeFontColor && inactiveFontColor;
   const setCustomStyleProps = (customStyle) => (customColoringActive ? customStyle : undefined);

   return (
      <SwitchButtonGroup
         className='btn-group btn-group-toggle'
         data-toggle='buttons'
         marginBottom={marginBottom}
         marginTop={marginTop}
         marginLeft={marginLeft}
         marginRight={marginRight}
         height={buttonHeight}
         leftActive={leftActive}
      >
         <SwitchLabel
            className={`btn${!customColoringActive ? ` bg-${leftActive ? 'primary active' : 'secondary'}` : ''}`}
            id={leftName}
            height={buttonHeight}
            onClick={handleSwitch}
            bgColor={setCustomStyleProps(leftActive ? activeBackgroundColor : inactiveBackgroundColor)}
            fontColor={setCustomStyleProps(leftActive ? activeFontColor : inactiveFontColor)}
            borderColor={
               fixedBorder
                  ? ' rgba(0,0,0,0.2)'
                  : setCustomStyleProps(leftActive ? activeBackgroundColor : ' rgba(0,0,0,0.2)')
            }
            fontWeight={leftActive ? activeFontWeight : ''}
            position={'left'}
            noBorder={!leftActive && 'border-right: none;'}
         >
            <SwitchButton type='button' name={leftName} width={buttonWidth} fontSize={fontSize}>
               {leftLabel}
            </SwitchButton>
         </SwitchLabel>
         <SwitchLabel
            className={`btn${!customColoringActive ? ` bg-${!leftActive ? 'primary active' : 'secondary'}` : ''}`}
            fixMargin
            name={rightName}
            id={rightName}
            height={buttonHeight}
            onClick={handleSwitch}
            bgColor={setCustomStyleProps(leftActive ? inactiveBackgroundColor : activeBackgroundColor)}
            fontColor={setCustomStyleProps(leftActive ? inactiveFontColor : activeFontColor)}
            borderColor={
               fixedBorder
                  ? ' rgba(0,0,0,0.2)'
                  : setCustomStyleProps(!leftActive ? activeBackgroundColor : ' rgba(0,0,0,0.2)')
            }
            fontWeight={!leftActive ? activeFontWeight : ''}
            position={'right'}
            noBorder={leftActive && 'border-left: none;'}
         >
            <SwitchButton type='button' name={rightName} width={buttonWidth} fontSize={fontSize}>
               {rightLabel}
            </SwitchButton>
         </SwitchLabel>
      </SwitchButtonGroup>
   );
}

SwitchButtons.propTypes = {
   leftLabel: PropTypes.string,
   leftName: PropTypes.string,
   rightLabel: PropTypes.string,
   rightName: PropTypes.string,
   leftActive: PropTypes.bool,
   handleSwitch: PropTypes.func,
   buttonWidth: PropTypes.number,
   buttonHeight: PropTypes.number,
   marginBottom: PropTypes.number,
   marginTop: PropTypes.number,
   marginLeft: PropTypes.number,
   marginRight: PropTypes.number,
   fontSize: PropTypes.number,
   activeBackgroundColor: PropTypes.string,
   inactiveBackgroundColor: PropTypes.string,
   activeFontColor: PropTypes.string,
   inactiveFontColor: PropTypes.string,
   activeFontWeight: PropTypes.number,
   fixedBorder: PropTypes.bool,
};

SwitchButtons.defaultProps = {
   buttonWidth: 100,
   marginBottom: 0,
   marginTop: 0,
   marginLeft: 0,
   marginRight: 0,
   fontSize: 14,
   fixedBorder: false,
};

export default SwitchButtons;
