export const MatchSelectComponentColorStyles = {
   control: (provided, state) => {
      const style = {
         ...provided,
         borderColor: '#e4e7ea',
         '&:hover': {
            borderColor: '#e4e7ea',
         },
      };
      if (state.isFocused) {
         style.borderColor = '#8ad4ee !important';
         style.boxShadow = '0 0 0 0.2rem rgb(32 168 216 / 25%)';
      }
      return style;
   },
};
