export const progressSettings = [
   {
      desc: 'Current month progress',
      shortDesc: 'cmProgress',
      currentRefValues: 'cmValues',
      lastRefValues: 'lmotValues',
      tableHeaderText: (now) => `${now.startOf('month').format('MMMM')}`,
   },
   {
      desc: 'Last month progress',
      shortDesc: 'lmProgress',
      currentRefValues: 'lmValues',
      lastRefValues: 'twoMAValues',
      tableHeaderText: (now) => `${now.subtract(1, 'month').startOf('month').format('MMMM')}`,
   },
   {
      desc: '2 months ago progress',
      shortDesc: 'twoMAProgress',
      currentRefValues: 'twoMAValues',
      lastRefValues: 'threeMAValues',
      tableHeaderText: (now) => `${now.subtract(2, 'month').startOf('month').format('MMMM')}`,
   },
   {
      desc: '3 months ago progress',
      shortDesc: 'threeMAProgress',
      currentRefValues: 'threeMAValues',
      lastRefValues: 'fourMAValues',
      tableHeaderText: (now) => `${now.subtract(3, 'month').startOf('month').format('MMMM')}`,
   },
   {
      desc: '4 months ago progress',
      shortDesc: 'fourMAProgress',
      currentRefValues: 'fourMAValues',
      lastRefValues: 'fiveMAValues',
      tableHeaderText: (now) => `${now.subtract(4, 'month').startOf('month').format('MMMM')}`,
   },
];
