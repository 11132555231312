import React from 'react';

import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import { HorizontalBar } from 'react-chartjs-2';

import { PropTypes } from 'prop-types';
import { AbrHorizontalBarChartOptions } from '../../../../../../constants/chartjs';

const MINIMUM_SHARE_IN_PERCENT = 1;
const NotZero_SoIsShownInChart = 0.1;

function adaptValuesToChart(value, maxValue) {
   let output = value === 0 ? NotZero_SoIsShownInChart : value;
   let unit = 's';

   if (maxValue > 36000) {
      output = Math.round(output / 3600);
      unit = 'h';
   } else if (maxValue > 3600) {
      output = Math.round(output / 60);
      unit = 'min';
   }

   AbrHorizontalBarChartOptions.scales.xAxes[0].scaleLabel.labelString = `playtime [${unit}]`;

   return output;
}

function createChartData(data) {
   const { chart, total } = data;
   const { labels, mobileSet, desktopSet, max } = chart;

   const filteredMobileSet = [];
   const filteredDesktopSet = [];
   const filteredLabels = [];

   for (let index = 0; index < mobileSet.length; index++) {
      const mobileValue = mobileSet[index];
      const desktopValue = desktopSet[index];
      const label = labels[index];
      if (((mobileValue + desktopValue) * 100) / total > MINIMUM_SHARE_IN_PERCENT) {
         filteredMobileSet.push(adaptValuesToChart(mobileValue, max));
         filteredDesktopSet.push(adaptValuesToChart(desktopValue, max));
         filteredLabels.push(label);
      }
   }

   const chartData = {
      labels: filteredLabels,
      datasets: [
         {
            label: 'mobile',
            backgroundColor: hexToRgba('#20a8d8', 20),
            borderColor: '#20a8d8',
            borderWidth: 1,
            data: filteredMobileSet,
         },
         {
            label: 'desktop',
            backgroundColor: hexToRgba('#f8cb00', 20),
            borderColor: '#f8cb00',
            borderWidth: 1,
            data: filteredDesktopSet,
         },
      ],
   };

   return chartData;
}

function AbrBarChart({ data, height }) {
   return <HorizontalBar options={AbrHorizontalBarChartOptions} data={createChartData(data)} />;
}

AbrBarChart.propTypes = {
   height: PropTypes.number,
   data: PropTypes.shape({
      alpha: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      geometry: PropTypes.object,
      coor: PropTypes.arrayOf(PropTypes.number),
      regionName: PropTypes.string,
      rid: PropTypes.number,
      compare: PropTypes.shape({
         labels: PropTypes.array,
         mobileSet: PropTypes.array,
         desktopSet: PropTypes.array,
         maximumValue: PropTypes.number,
      }),
      count: PropTypes.any,
   }),
};

AbrBarChart.defaultProps = { height: 300, data: [] };

export default AbrBarChart;
