import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Util } from 'reactstrap';

import 'spinkit/css/spinkit.css';

const Spinner = (props) => {
   const {
      className,
      cssModule,
      loading,
      parentTopMarginPx,
      childColor,

      tag: Tag,

      ...attributes
   } = props;

   const classes = Util.mapToCssModules(classNames(className), cssModule);

   const style = {
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'absolute',
      top: `calc(50% - ${parentTopMarginPx}px)`,
      width: '100%',
      color: 'rgb(180, 180, 180)',
   };

   const childStyle = childColor
      ? {
           backgroundColor: childColor,
        }
      : {};

   if (loading)
      return (
         <div style={style} className='sk-three-bounce'>
            <div style={childStyle} className='sk-child sk-bounce1' />
            <div style={childStyle} className='sk-child sk-bounce2' />
            <div style={childStyle} className='sk-child sk-bounce3' />
         </div>
      );

   return <Tag {...attributes} className={classes} />;
};

Spinner.propTypes = {
   tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
   className: PropTypes.string,
   cssModule: PropTypes.object,
   loading: PropTypes.bool,
   parentTopMarginPx: PropTypes.number,
   childColor: PropTypes.string,
};

Spinner.defaultProps = {
   tag: 'div',
   parentTopMarginPx: 0,
};

export default Spinner;
