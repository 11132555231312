import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { PropTypes } from 'prop-types';

import {
   BottomMargin,
   CenteredFlexContainer,
   Description,
   ExtButton,
   Notification,
   StaticCol,
} from '../../../styledComponents/styledComponents';
import { getDomainFromURL, uniqueIdFactory } from '../../../util/UtilFunctions';
import { Collapse, Input, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { cancelTokenSelector } from '../../../redux/selectors/selectors';
import useComponentFetch from '../../../hooks/useComponentFetch';
import { setAlarm } from '../../../redux/actions/general';
import SingleDataFetcher from '../../../dataFetching/SingleDataFetcher';
import { BLOCK_LIST_PROP_NAME_MAP, IP_BLOCKLIST } from '../../../constants/general';
import axios from 'axios';
import { STREAM_GUARDIAN_API_BASE_URL } from '../../../constants/env';
import useSignal from '../../../hooks/useSignal';

export const VariableWidthPopover = styled(UncontrolledPopover)`
   max-width: 500px !important;
`;
VariableWidthPopover.displayName = 'VariableWidthPopover';

function BlockingButton({ valueToBeBlocked, type, blockedMap, triggerBlockListRefreshFn }) {
   const [uid] = useState(uniqueIdFactory('_'));

   const [startApiRequestFlag, setApiRequestSwitch] = useState(false);
   const [isBlockDialogOpen, setBlockDialogOpen] = useState(false);
   const [resetFetcherFlag, triggerFetcherResetFn] = useSignal();

   const typeDescription = type === IP_BLOCKLIST ? 'IP' : 'Referrer';

   const [tagInput, setTagInput] = useState('');

   const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
   const streamGuardToken = userInfo.token;

   const blockedValue = type === IP_BLOCKLIST ? valueToBeBlocked : getDomainFromURL(valueToBeBlocked);

   const isBlocked = blockedMap[blockedValue];

   const dispatch = useDispatch();

   const cancelToken = useSelector(cancelTokenSelector);

   const body = useMemo(() => {
      const newBody = { [BLOCK_LIST_PROP_NAME_MAP[type]]: blockedValue, type: 'deny' };
      if (!isBlocked && tagInput) {
         newBody.tag = tagInput;
      }
      return newBody;
   }, [blockedValue, type, isBlocked, tagInput]);

   const httpHeader = useMemo(
      () => ({
         'X-BINTU-TOKEN': streamGuardToken,
         'Content-Type': 'application/json',
      }),
      [streamGuardToken],
   );

   const { handleResult, successful, errorMessage, data, httpStatus, isLoading } = useComponentFetch({
      reset: resetFetcherFlag,
   });

   useEffect(() => {
      if (errorMessage) {
         if (errorMessage instanceof axios.Cancel) {
            return;
         }
         dispatch(setAlarm('danger', { message: getUserErrorMessage(errorMessage, errorMessage.message) }));
      }
   }, [successful, isLoading, errorMessage, data, httpStatus, dispatch]);

   const handleBlockActionRequestCompleted = useCallback(
      (res, error) => {
         if (!error) {
            triggerBlockListRefreshFn();
            setBlockDialogOpen(false);
         }
         setApiRequestSwitch(false);
      },
      [setApiRequestSwitch, triggerBlockListRefreshFn, setBlockDialogOpen],
   );
   const hasError = errorMessage !== undefined;

   return (
      <>
         {streamGuardToken && startApiRequestFlag && (
            <SingleDataFetcher
               url={`${STREAM_GUARDIAN_API_BASE_URL}/${type}`}
               httpHeader={httpHeader}
               method={isBlocked ? 'DELETE' : 'POST'}
               body={body}
               cancelToken={cancelToken}
               onResult={handleResult}
               onRequestCompleted={handleBlockActionRequestCompleted}
            />
         )}
         <ExtButton
            id={`Popover${uid}`}
            type='button'
            $widthPx={30}
            $bgColor={'#c8ced3'}
            $color={'#23282c'}
            $bgColorHover={'rgb(255, 147, 63)'}
            $fontColorHover={'#FFF'}
            $paddingPx={'3px'}
            $lineHeight={0.1}
            $border={'0px solid black'}
            title={'Open block dialog'}
            onClick={(event) => {
               setBlockDialogOpen(false);
               event.stopPropagation();
               triggerFetcherResetFn();
            }}
         >
            <svg
               xmlns='http://www.w3.org/2000/svg'
               width='10'
               height='10'
               fill='currentColor'
               className='bi bi-wrench'
               viewBox='0 0 16 16'
            >
               <path d='M.102 2.223A3.004 3.004 0 0 0 3.78 5.897l6.341 6.252A3.003 3.003 0 0 0 13 16a3 3 0 1 0-.851-5.878L5.897 3.781A3.004 3.004 0 0 0 2.223.1l2.141 2.142L4 4l-1.757.364L.102 2.223zm13.37 9.019.528.026.287.445.445.287.026.529L15 13l-.242.471-.026.529-.445.287-.287.445-.529.026L13 15l-.471-.242-.529-.026-.287-.445-.445-.287-.026-.529L11 13l.242-.471.026-.529.445-.287.287-.445.529-.026L13 11l.471.242z' />
            </svg>
         </ExtButton>
         <VariableWidthPopover trigger='legacy' placement='bottom' target={`Popover${uid}`}>
            <PopoverHeader>{`${isBlocked ? 'Unblock' : 'Block'} dialog`}</PopoverHeader>
            <PopoverBody className='py-3'>
               <CenteredFlexContainer $direction={'column'} vertical={'center'} $width={'220px'}>
                  <Collapse isOpen={!isBlockDialogOpen}>
                     {!isBlocked && (
                        <BottomMargin pxSize={15}>
                           <Description>
                              <div>
                                 <b>Note:</b> The block only affects new connections or the next reconnect and takes
                                 max. 6 minutes to be effected.
                              </div>
                              <div>Blocking for a running playout requires a reconnect to take effect.</div>
                              <div>Blocked IPs will be automatically unblocked after 24 hours.</div>
                           </Description>
                        </BottomMargin>
                     )}
                     <ExtButton
                        type='button'
                        $widthPx={140}
                        $bgColor={'rgb(255, 147, 63)'}
                        $color={' #FFF'}
                        $bgColorHover={'rgb(255, 147, 63)'}
                        $fontColorHover={' #FFF'}
                        $xCentered
                        onClick={(event) => {
                           if (!isBlocked) {
                              setBlockDialogOpen(true);
                           } else {
                              setApiRequestSwitch(true);
                           }
                           event.stopPropagation();
                        }}
                     >
                        {isBlocked ? `Unblock ${typeDescription}` : `Block ${typeDescription}`}
                     </ExtButton>
                     <Collapse isOpen={hasError}>
                        <Notification topMarginPx={15} textAlign={'center'}>
                           {getUserErrorMessage(errorMessage, 'Unblock failed, please try later again.')}
                        </Notification>
                     </Collapse>
                  </Collapse>
                  <Collapse isOpen={isBlockDialogOpen}>
                     <BottomMargin pxSize={15}>
                        <Description $xCentered>Optional comment</Description>
                        <StaticCol width={170}>
                           <Input value={tagInput} onChange={(event) => setTagInput(event.target.value)} />
                        </StaticCol>
                     </BottomMargin>
                     <BottomMargin>
                        <ExtButton
                           type='button'
                           $widthPx={140}
                           $bgColor={'rgb(255, 147, 63)'}
                           $color={' #FFF'}
                           $bgColorHover={'rgb(255, 147, 63)'}
                           $fontColorHover={' #FFF'}
                           $xCentered
                           onClick={(event) => {
                              setApiRequestSwitch(true);
                              event.stopPropagation();
                           }}
                        >
                           {'Confirm block'}
                        </ExtButton>
                     </BottomMargin>
                     <ExtButton
                        type='button'
                        $widthPx={140}
                        $bgColor={'rgb(100,100,100)'}
                        $color={' #FFF'}
                        $bgColorHover={'rgb(100,100,100)'}
                        $fontColorHover={' #FFF'}
                        $xCentered
                        onClick={(event) => {
                           setBlockDialogOpen(false);
                           event.stopPropagation();
                           triggerFetcherResetFn();
                        }}
                     >
                        {'Abort block'}
                     </ExtButton>
                     <Collapse isOpen={hasError}>
                        <Notification topMarginPx={15} textAlign={'center'}>
                           {getUserErrorMessage(errorMessage, 'Block failed, please try later again.')}
                        </Notification>
                     </Collapse>
                  </Collapse>
               </CenteredFlexContainer>
            </PopoverBody>
         </VariableWidthPopover>
      </>
   );
}

BlockingButton.propTypes = {
   valueToBeBlocked: PropTypes.string,
   type: PropTypes.string,
   blockedMap: PropTypes.object,
   triggerBlockListRefreshFn: PropTypes.func,
};

function getUserErrorMessage(error, userDefinedErrorMessage) {
   if (!error) return '';
   if (error.errorBody && error.errorBody.errorCode) {
      return `${error.errorBody.message} (Error code: ${error.errorBody.errorCode})`;
   } else {
      return userDefinedErrorMessage;
   }
}

export default BlockingButton;
