import { ABR_PLAYTIME } from '../components/zoom/ZoomConstants';
import { WORLDMAP_METRIC } from '../components/widgets/world/WorldMapConstants';
import { H5LIVE_VIEW, WORLD_VIEW, ABR_VIEW, ZOOM_VIEW } from './general';

export const eventViews = [H5LIVE_VIEW, WORLD_VIEW, ABR_VIEW, ZOOM_VIEW];
export const eventViewsWithSubMetrics = [WORLD_VIEW, ZOOM_VIEW];
export const eventSubMetrics = [
   ABR_PLAYTIME, // with zoom
   WORLDMAP_METRIC.BUFFER_RATIO, // with zoom and worldmap (same for the next sub metrics)
   WORLDMAP_METRIC.LATENCY,
   WORLDMAP_METRIC.PLAYTIME,
   WORLDMAP_METRIC.ABR_PLAYTIME,
   WORLDMAP_METRIC.ABR_VIEWER,
   WORLDMAP_METRIC.ABR_SWITCHES,
];
