import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';

import useDataFetch from '../../../../hooks/useDataFetch';

import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   SwitchDescription,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';

import { barChartZoomOptions } from '../../../../constants/chartjs';

import { Bar } from 'react-chartjs-2';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, HorizontalSeparator, Spinner } from '../../../common';

import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';

import { CardTitle, Col, Row } from 'reactstrap';

import {
   cancelTokenSelector,
   countriesSelector,
   endTimespanSelector,
   hashSelector,
   intervalTimespanSelector,
   startTimespanSelector,
   streamNamesSelector,
   tagsSelector,
   timespanSelector,
   titleTimespanSelector,
} from '../../../../redux/selectors/selectors';

import DefaultModalTemplate from '../../../zoom/templates/DefaultTemplate';

import { PropTypes } from 'prop-types';

import 'spinkit/css/spinkit.css';

import moment from 'moment';
import { DefaultBlue, DefaultGrey } from '../../../../constants/colors';
import { HOME_VIEW } from '../../../../constants/general';
import { generateStackedBarChartData } from '../../../../util/ChartJsProcessing';
import SwitchButtons from '../../../common/SwitchButtons/SwitchButtons';
import { CONCURRENT_VIEWER } from '../../../zoom/ZoomConstants';
import ZoomHint from '../../../zoom/ZoomHint';
import ErrorDisplay from '../../ipFiltering/ErrorDisplay';
import { WIDGET_SETTINGS } from '../../../zoom/widgetSettings/HomeView';

const barChartOptions = barChartZoomOptions();

const red = getStyle('--danger');

const VIEWERS = 'viewers';
const CHANGES = 'changes';

function getNewDataSets(data = []) {
   return [
      {
         label: 'Viewers',
         backgroundColor: hexToRgba(red, 10),
         borderColor: red,
         borderWidth: 1,
         data,
         total: 0,
      },
   ];
}

function addZoomClickHandler(chartOptions, barRef, setModal) {
   return {
      ...chartOptions,
      onClick: (event, item) => {
         if (item.length === 0) {
            return;
         }
         const index = item[0]._index;

         setModal({
            chosenIndex: index,
            toggleShow: true,
         });
      },
   };
}

function ConcurrentViewer({ height }) {
   const [viewersChart, setViewersChart] = useState({
      labels: [],
      datasets: getNewDataSets(),
   });
   const [changesChart, setChangesChart] = useState({
      labels: [],
      datasets: getNewDataSets(),
   });

   const [noData, setNoData] = useState(false);

   const [displayMode, setDisplayMode] = useState(VIEWERS);

   const hash = useSelector(hashSelector);
   const streamNames = useSelector(streamNamesSelector);
   const tags = useSelector(tagsSelector);
   const countries = useSelector(countriesSelector);

   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);

   const timespanObj = useSelector(timespanSelector);
   const interval = useSelector(intervalTimespanSelector);
   const timespanTitle = useSelector(titleTimespanSelector);

   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const [modal, setModal] = useState({
      toggleShow: false,
      chosenIndex: 0,
   });

   const [timespansPerIndex, setTimespansPerIndex] = useState([
      {
         start: moment.utc(),
         end: moment.utc(),
      },
   ]);

   const barRef = useRef(null);
   const chartOptions = addZoomClickHandler(barChartOptions, barRef, setModal);

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, [], streamNames, HOME_VIEW);
   const url = `/api/v2/viewers/concurrent/timeseries?from=${start.format()}&to=${end.format()}&interval=${interval}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            const viewer = data.data;
            setNoData(viewer.reduce((sum, dataPoint) => (sum += dataPoint.count), 0) === 0);

            const delta = viewer.map(({ timestamp, count }, idx) => ({
               timestamp,
               count: idx > 0 ? count - viewer[idx - 1].count : 0,
            }));
            const { datasetsDatas, labels, timespansPerIndex } = generateStackedBarChartData(viewer, timespanObj, [
               'count',
            ]);
            const deltaChart = generateStackedBarChartData(delta, timespanObj, ['count']);
            setViewersChart({ labels, datasets: getNewDataSets(datasetsDatas[0]) });
            setChangesChart({ labels, datasets: getNewDataSets(deltaChart.datasetsDatas[0]) });
            setTimespansPerIndex(timespansPerIndex);
         }
      }

      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, start, end, interval, timespanObj, dispatch]);

   const { toggleShow, chosenIndex } = modal;
   const chosenTimespan = timespansPerIndex[chosenIndex];

   const errorOrNoData = errorMessage || noData;

   const setMode = (e) => {
      const selectedOption = e.target.getAttribute('name');
      if (selectedOption !== displayMode) {
         setDisplayMode(selectedOption);
      }
   };

   return (
      <WidgetContainer>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow>
                     <SwitchDescription>{'Selected mode'}</SwitchDescription>
                     <SimpleFlexItem>
                        <SwitchButtons
                           leftActive={displayMode === VIEWERS}
                           leftLabel={'Absolute'}
                           rightLabel={'Delta'}
                           leftName={VIEWERS}
                           rightName={CHANGES}
                           marginLeft={7}
                           handleSwitch={setMode}
                           buttonWidth={60}
                           buttonHeight={19}
                           fontSize={11}
                           activeBackgroundColor={DefaultBlue()}
                           inactiveBackgroundColor={DefaultGrey()}
                           activeFontColor={'#FFF'}
                           inactiveFontColor={'#000'}
                        />
                     </SimpleFlexItem>
                     <HorizontalSeparator
                        color={DefaultGrey(1, 180)}
                        widthInPx={1}
                        heightInPx={18}
                        marginX_InPx={12}
                        marginTopInPx={0}
                     />
                     <SimpleFlexItem>
                        <CsvExportFromApiButton
                           url={url}
                           header={header}
                           cancelToken={cancelToken}
                           filename={'ConcurrentViewers'}
                        />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
                  <SimpleFlexItem>
                     <ZoomHint />
                  </SimpleFlexItem>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer>
            <Row>
               <Col>
                  <CardTitle className='mb-0'>{'Maximum concurrent H5Live viewers'}</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </Col>
            </Row>
            <ChartWrapper className='chart-wrapper' marginTopPx={35} height={height}>
               <Spinner loading={isLoading} parentTopMarginPx={71}>
                  {errorOrNoData ? (
                     <CenteredMsgShowing height={height}>
                        {errorMessage ? (
                           <ErrorDisplay error={errorMessage} msgColor={'rgb(80,80,80)'}></ErrorDisplay>
                        ) : (
                           'No data available'
                        )}
                     </CenteredMsgShowing>
                  ) : (
                     <SetChartHeight height={height}>
                        <Bar
                           data={displayMode === VIEWERS ? viewersChart : changesChart}
                           options={chartOptions}
                           ref={barRef}
                        />
                     </SetChartHeight>
                  )}
               </Spinner>
            </ChartWrapper>
         </WidgetBodyContainer>
         {toggleShow && (
            <DefaultModalTemplate
               timespan={chosenTimespan}
               metric={CONCURRENT_VIEWER}
               closeModal={() =>
                  setModal({
                     ...modal,
                     toggleShow: false,
                  })
               }
               settings={WIDGET_SETTINGS}
            />
         )}
      </WidgetContainer>
   );
}

ConcurrentViewer.propTypes = {
   height: PropTypes.number,
};

ConcurrentViewer.defaultProps = {
   height: 300,
};

export default ConcurrentViewer;
