import { CIRCLE_COLOR_SETTINGS } from './DataCircleConstants';
import { WORLDMAP_METRIC } from '../WorldMapConstants';

function getCircleColor(metric, countryData, maxHeatValue) {
   const settings = CIRCLE_COLOR_SETTINGS[metric];
   switch (metric) {
      case WORLDMAP_METRIC.PI:
      case WORLDMAP_METRIC.PLAYTIME:
      case WORLDMAP_METRIC.ABR_PLAYTIME:
      case WORLDMAP_METRIC.ABR_VIEWER:
      case WORLDMAP_METRIC.ABR_SWITCHES:
      case WORLDMAP_METRIC.USAGE:
         return getHeatCircleColor(countryData, maxHeatValue, settings);
      case WORLDMAP_METRIC.BUFFER_RATIO:
      case WORLDMAP_METRIC.LATENCY:
         return getTrafficLightCircleColor(countryData, settings);
      case WORLDMAP_METRIC.QOE:
         return getGreenRedCircleColor(countryData, settings);
      default:
         return '#000';
   }
}

function getHeatCircleColor(countryData, maxHeatValue, settings) {
   const { colorProp, colorFn } = settings;
   const metricValue = countryData[colorProp];
   return colorFn(metricValue, maxHeatValue);
}

function getTrafficLightCircleColor(countryData, settings) {
   const { colorProp, colorFn, green, yellow } = settings;
   const metricValue = countryData[colorProp];
   return colorFn(metricValue, green, yellow, true);
}

function getGreenRedCircleColor(countryData, settings) {
   const { colorProp, colorFn, min, max } = settings;
   const metricValue = countryData[colorProp];
   return colorFn(metricValue, min, max);
}

export default {
   getCircleColor,
};
