import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setWorldMapView, setWorldMapRegionZoom } from '../../../../redux/actions/general';
import SwitchButtons from './../../../common/SwitchButtons/SwitchButtons';
import { worldmapViewSelector } from './../../../../redux/selectors/selectors';
import { VIEW_MODE } from './../WorldMapConstants';
import { HoveredRegionContext } from '../WorldContainer';
import { DefaultBlue, DefaultGrey } from '../../../../constants/colors';

function CountryRegionSwitch() {
   const setHoveredWorldRegion = useContext(HoveredRegionContext)[1];
   const viewSwitch = useSelector(worldmapViewSelector);
   const dispatch = useDispatch();

   const setView = (e) => {
      const selectedOption = e.target.getAttribute('name');
      if (selectedOption !== viewSwitch) {
         setHoveredWorldRegion('');
         dispatch(setWorldMapView(selectedOption, { worldmapView: selectedOption }));
         dispatch(setWorldMapRegionZoom('', { worldmapRegionZoom: '' }));
      }
   };

   return (
      <SwitchButtons
         leftActive={viewSwitch === VIEW_MODE.REGION}
         leftLabel={'World regions'}
         leftName={VIEW_MODE.REGION}
         rightLabel={'TOP 50 Countries'}
         rightName={VIEW_MODE.COUNTRY}
         handleSwitch={setView}
         buttonWidth={110}
         buttonHeight={22}
         fontSize={11}
         marginLeft={7}
         activeBackgroundColor={DefaultBlue()}
         inactiveBackgroundColor={'#FFF'}
         activeFontColor={'#FFF'}
         inactiveFontColor={'#000'}
         borderColor={DefaultGrey(0.3, 0)}
         fixedBorder={true}
      />
   );
}

export default CountryRegionSwitch;
