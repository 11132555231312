import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

import Top10 from '../../Top10/Top10';
import WorldMapCountriesData from '../../DataCircle/WorldMapCountriesData';
import TransparentSpinner from '../../../../common/TransparentSpinner';
import { WorldmapErrorShowing } from '../../../../../styledComponents/styledComponents';

import { getFilterQueryParams, getHttpHeader } from '../../../../../util/FetchTools';

import { VIEW_MODE, MAP_HEIGHT_WIDTH_RATIO } from '../../WorldMapConstants';
import MetricProcessing from '../MetricProcessing';
import { METRIC_PROCESSING, METRIC_COMPONENTS } from './../MetricsConstants';

import { PropTypes } from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { setAlarm } from '../../../../../redux/actions/general';

import {
   hashSelector,
   countriesSelector,
   tagsSelector,
   eventsSelector,
   startTimespanSelector,
   endTimespanSelector,
   cancelTokenSelector,
   streamNamesSelector,
   top4CdnSelector,
   worldmapViewSelector,
   worldmapRegionZoomSelector,
} from './../../../../../redux/selectors/selectors';

import useSkipDataFetch from './hooks/useSkipDataFetch';
import SingleDataFetcher from './DataFetcher/SingleDataFetcher';
import { WORLD_VIEW } from '../../../../../constants/general';
import { useContext } from 'react';
import { QueryParamContext } from '../../WorldContainer';
import LegendContainer from '../../Legend/LegendContainer';

function DefaultMetric({ metricName, mapWidth, isActive }) {
   const setQueryParams = useContext(QueryParamContext);

   const hash = useSelector(hashSelector);
   const countries = useSelector(countriesSelector);
   const tags = useSelector(tagsSelector);
   const events = useSelector(eventsSelector);
   const streamNames = useSelector(streamNamesSelector);

   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);

   const top4Cdn = useSelector(top4CdnSelector);

   const viewSwitch = useSelector(worldmapViewSelector);
   const zoomedRegion = useSelector(worldmapRegionZoomSelector);

   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const { urlParams, responseFn, initial } = METRIC_COMPONENTS[metricName];

   const { initialState } = initial;

   const [metricData, setMetricData] = useState(initialState);

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, events, streamNames, WORLD_VIEW, metricName);
   const cdnQueryPart = top4Cdn !== undefined ? `&ct6Cdn=${top4Cdn}` : '';
   const url = `/api/v2/${urlParams.path}?from=${start.format()}&to=${end.format()}${urlFilterQuery}${cdnQueryPart}`;

   const skipFetch = useSkipDataFetch(header, url, isActive);

   const [successful, setSuccessfulState] = useState();
   const [isLoading, setLoadingState] = useState();
   const [errorMessage, setErrorMessage] = useState();
   const [data, setData] = useState();
   const [httpStatus, setHttpStatus] = useState();

   useEffect(() => {
      if (isActive) {
         setQueryParams({ url, header });
      }
   }, [url, header, setQueryParams, isActive]);

   function handleResult(res) {
      const { successful, isLoading, errorMessage, data, httpStatus } = res;
      setSuccessfulState(successful);
      setLoadingState(isLoading);
      setErrorMessage(errorMessage);
      setData(data);
      setHttpStatus(httpStatus);
   }

   useEffect(() => {
      if (successful) {
         setMetricData(responseFn({ status: httpStatus, data }));
      }

      if (errorMessage) {
         if (errorMessage instanceof axios.Cancel) {
            return;
         }
         dispatch(setAlarm('danger', errorMessage));
         setMetricData(initialState);
      }
   }, [successful, isLoading, errorMessage, data, httpStatus, metricName, initialState, responseFn, dispatch]);

   const settings = METRIC_PROCESSING[metricName];
   const countriesData = MetricProcessing.getCountriesData(metricData, null, settings, zoomedRegion);
   const worldRegionsData = MetricProcessing.getWorldRegionsData(metricData, null, settings);
   const showLegend = MetricProcessing.shouldLegendBeShown(metricData);
   const hasAvailableCountries = countriesData.length > 0;

   const perCountryView = viewSwitch === VIEW_MODE.COUNTRY || zoomedRegion !== '';
   const worldData = perCountryView ? countriesData : worldRegionsData;

   const total = MetricProcessing.getTotalValue(metricData, null, settings, zoomedRegion, perCountryView);
   const maxValue = MetricProcessing.getMaxHeatValue(worldData, settings);

   const errorOrNoData = errorMessage ? true : false || !showLegend;
   const issueMsg = errorMessage ? errorMessage.message : 'No data available';

   return (
      <>
         {isActive && (
            <>
               {isLoading ? (
                  <TransparentSpinner height={`${mapWidth * MAP_HEIGHT_WIDTH_RATIO}px`} width={`${mapWidth}px`} />
               ) : errorOrNoData ? (
                  <WorldmapErrorShowing height={mapWidth * MAP_HEIGHT_WIDTH_RATIO} width={mapWidth}>
                     {issueMsg}
                  </WorldmapErrorShowing>
               ) : (
                  <>
                     {hasAvailableCountries && (
                        <WorldMapCountriesData
                           countriesData={perCountryView ? countriesData : []}
                           mapHeight={mapWidth * MAP_HEIGHT_WIDTH_RATIO}
                           mapWidth={mapWidth}
                           maxHeatValue={maxValue}
                           metric={metricName}
                           total={total}
                           view={viewSwitch}
                           zoomedRegion={zoomedRegion}
                        />
                     )}
                     <LegendContainer
                        mapHeight={mapWidth * MAP_HEIGHT_WIDTH_RATIO}
                        maxHeatValue={maxValue}
                        total={total}
                        metric={metricName}
                        view={viewSwitch}
                        zoomedRegion={zoomedRegion}
                        showCompactLegend={showLegend && !hasAvailableCountries}
                     />
                  </>
               )}
               <Top10
                  countriesData={worldData}
                  metric={metricName}
                  isLoading={isLoading}
                  hasError={errorOrNoData}
                  issueMsg={issueMsg}
                  mapWidth={mapWidth}
               />
            </>
         )}
         {!skipFetch && (
            <SingleDataFetcher url={url} httpHeader={header} cancelToken={cancelToken} onResult={handleResult} />
         )}
      </>
   );
}

DefaultMetric.propTypes = {
   metricName: PropTypes.string,
   mapWidth: PropTypes.number,
   isActive: PropTypes.bool,
};

export default DefaultMetric;
