import React from 'react';
import { Notification } from '../../../styledComponents/styledComponents';

import { PropTypes } from 'prop-types';

function ErrorDisplay({ error, msgColor, fontSizePx, fontWeight }) {
   return (
      <Notification $fontSizePx={fontSizePx} $fontWeight={fontWeight} $color={msgColor}>
         {error.message}
      </Notification>
   );
}

ErrorDisplay.propTypes = {
   error: PropTypes.shape({
      errorBody: PropTypes.shape({
         error: PropTypes.shape({
            message: PropTypes.string,
            details: PropTypes.object,
         }),
      }),
      message: PropTypes.string,
   }),
   msgColor: PropTypes.string,
   fontSizePx: PropTypes.number,
   fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ErrorDisplay.defaultProps = {
   fontSizePx: 14,
   fontWeight: 'bold',
};

export default ErrorDisplay;
