import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { PropTypes } from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';

import 'react-datetime/css/react-datetime.css';
import SilentDatePicker from './SilentDatePicker';
import { SIMPLE_DATE_FORMAT, SIMPLE_TIME_FORMAT } from '../../../constants/general';
import {
   StyledButton,
   TwoSideRow,
   DescriptionSpan,
   Notification,
   CenteredFlexContainer,
   Description,
   TwoSideFlexContainer,
   SimpleFlexRow,
   BottomMargin,
} from '../../../styledComponents/styledComponents';

import { useNonInitialEffect } from '../../../hooks/useNonInitialEffect';

import { DatePickerWrapper, DisplayLineWrapper, PickerWrapper } from './DatePickerStyles';
import { DEFAULT_MAX_DURATIONS_FOR_INTERVALS } from '../../../constants/timeConstraints';

const ERROR_MESSAGES = {
   noError: '',
   startSameOrAfterEnd: 'From-date cannot be the same or after to-date',
   startInputInvalid: 'From-date is incorrect',
   endInputInvalid: 'To-date is incorrect',
   startSameOrAfterNow: 'From-date cannot be in the future',
   maxTimeRangeExceeded: 'The max time range duration ',
};

const MONTH_FORMAT = 'MMM YYYY';
const MONTHLY_DISPLAY_FORMAT = 'Do MMM YYYY HH:mm:ss';

function DateTimePicker({
   startDate,
   endDate,
   selectedInterval,
   instantResponse,
   onGoButtonPress,
   onIntervalChange,
   onValidDateChange,
   onStartBlur,
   onStartFocus,
   onEndBlur,
   onEndFocus,
   isValidStartDate,
   isValidEndDate,
   maxInputTimeRange,
   dateFormat,
   timeFormat,
   monthlyMode,
   intervals,
   setHasError,
   setCustomMaxTimeRangesForIntervals,
   showIntervalSwitchButtons,
}) {
   const isButtonApplyMode = onGoButtonPress !== undefined;
   const isButtonAvailable = isButtonApplyMode;

   const isIntervalSelectAvailable = intervals !== undefined;

   const hasDateAndTime = dateFormat && timeFormat;
   const fullDateTimeFormat = monthlyMode
      ? MONTH_FORMAT
      : `${dateFormat ? dateFormat : ''}${hasDateAndTime ? ' ' : ''}${timeFormat ? timeFormat : ''}`;

   const [localStartMoment, setLocalStartMoment] = useState(
      monthlyMode ? startDate.clone().startOf('month') : startDate,
   );
   const [localEndMoment, setLocalEndMoment] = useState(monthlyMode ? endDate.clone().startOf('month') : endDate);

   const [isStartValid, setStartValidState] = useState(true);
   const [isStartInputValid, setStartInputValidState] = useState(true);
   const [isEndValid, setEndValidState] = useState(true);
   const [isEndInputValid, setEndInputValidState] = useState(true);

   const [localSelectedInterval, setLocalSelectedInterval] = useState(selectedInterval);

   const [errorMessage, setErrorMessage] = useState(ERROR_MESSAGES.noError);

   const [isDateValidationActive, triggerDateValidation] = useState(false);

   const [localMaxInputTimeRange, setLocalMaxInputTimeRange] = useState(maxInputTimeRange);

   const localStartMomentRef = useRef(localStartMoment);
   const localEndMomentRef = useRef(localEndMoment);
   const localSelectedIntervalRef = useRef(localSelectedInterval);
   localStartMomentRef.current = localStartMoment;
   localEndMomentRef.current = localEndMoment;
   localSelectedIntervalRef.current = localSelectedInterval;

   const MAX_DURATIONS_FOR_INTERVALS =
      setCustomMaxTimeRangesForIntervals !== undefined
         ? setCustomMaxTimeRangesForIntervals
         : DEFAULT_MAX_DURATIONS_FOR_INTERVALS;

   const onValidDate = useCallback(
      function ({ gte, lt, interval }) {
         if (monthlyMode) {
            const newMonthlyTimespan = {
               gte: gte.clone().startOf('month'),
               lt: lt.clone().startOf('month'),
            };
            if (interval !== undefined) {
               newMonthlyTimespan.interval = interval;
            }
            onValidDateChange(newMonthlyTimespan);
         } else {
            const newTimespan = {
               gte: gte.clone(),
               lt: lt.clone(),
            };
            if (interval !== undefined) {
               newTimespan.interval = interval;
            }
            onValidDateChange(newTimespan);
         }
      },
      [onValidDateChange, monthlyMode],
   );

   const isWithinMaxInputTimeRange = useCallback(
      function (startMoment, endMoment, interval) {
         const deltaInSecs = moment.duration(endMoment.diff(startMoment)).asSeconds();
         if (deltaInSecs <= 0) return false;

         const customMaxDuration =
            maxInputTimeRange !== undefined
               ? moment.duration(maxInputTimeRange.value, maxInputTimeRange.unit).asSeconds()
               : Number.MAX_SAFE_INTEGER;
         if (deltaInSecs > customMaxDuration) {
            setLocalMaxInputTimeRange({
               value: customMaxDuration,
               isCustomMaxDuration: true,
               limitHint: { count: maxInputTimeRange.value, unit: maxInputTimeRange.unit },
            });
            return false;
         }

         if (intervals === undefined) return true;

         if (
            MAX_DURATIONS_FOR_INTERVALS[interval] !== undefined &&
            deltaInSecs > MAX_DURATIONS_FOR_INTERVALS[interval].value
         ) {
            setLocalMaxInputTimeRange(MAX_DURATIONS_FOR_INTERVALS[interval]);
            return false;
         }

         return true;
      },
      [maxInputTimeRange, intervals, MAX_DURATIONS_FOR_INTERVALS],
   );

   const isValidDateFn = useCallback(
      function (current, validationFn, maxInputTimeRange, isEnd) {
         if (typeof localStartMoment === 'string' || typeof localEndMoment === 'string') {
            return true;
         }

         if (isEnd) {
            const endIsNotBeforeStart = localStartMoment.isSameOrBefore(current, 'day');
            return maxInputTimeRange !== undefined
               ? endIsNotBeforeStart
               : endIsNotBeforeStart && validationFn(current, localStartMoment.clone(), localEndMoment.clone());
         } else {
            const isNotInTheFuture = moment.utc().startOf('day').isSameOrAfter(current, 'day');
            return maxInputTimeRange !== undefined
               ? isNotInTheFuture
               : isNotInTheFuture && validationFn(current, localStartMoment.clone(), localEndMoment.clone());
         }
      },
      [localStartMoment, localEndMoment],
   );

   const showErrorMsgWithDynamicInfo = useCallback(
      function (errorMessage) {
         if (errorMessage === ERROR_MESSAGES.startInputInvalid || errorMessage === ERROR_MESSAGES.endInputInvalid) {
            return `${errorMessage} (pattern ${fullDateTimeFormat})`;
         }
         if (errorMessage === ERROR_MESSAGES.maxTimeRangeExceeded) {
            const { isCustomMaxDuration, limitHint } = localMaxInputTimeRange;
            const intervalInfo = isCustomMaxDuration ? '' : ` for interval '${localSelectedInterval}'`;
            const maxRangeInfo = `${limitHint.count} ${limitHint.unit}`;
            return `${errorMessage}${intervalInfo} is ${maxRangeInfo}`;
         }
         return errorMessage;
      },
      [fullDateTimeFormat, localMaxInputTimeRange, localSelectedInterval],
   );

   const changeStart = useCallback(
      function (newMoment, notifyChange = true) {
         const isInvalidInput = typeof newMoment === 'string';
         if (isInvalidInput) {
            setStartInputValidState(false);
            setEndValidState(true);
            setErrorMessage(ERROR_MESSAGES.startInputInvalid);
            setLocalStartMoment(newMoment);
            return;
         }

         const now = moment.utc();

         if (newMoment.isSameOrAfter(now.startOf('minute'))) {
            setStartValidState(false);
            setStartInputValidState(true);
            setEndValidState(true);
            setErrorMessage(ERROR_MESSAGES.startSameOrAfterNow);
            setLocalStartMoment(newMoment);
            return;
         }

         if (newMoment.isSameOrAfter(localEndMoment)) {
            setStartValidState(false);
            setStartInputValidState(true);
            setEndValidState(false);
            setErrorMessage(ERROR_MESSAGES.startSameOrAfterEnd);
            setLocalStartMoment(newMoment);
            return;
         }

         if (isEndInputValid && !isWithinMaxInputTimeRange(newMoment, localEndMoment, localSelectedInterval)) {
            setStartValidState(false);
            setStartInputValidState(true);
            setEndValidState(false);
            setErrorMessage(ERROR_MESSAGES.maxTimeRangeExceeded);
            setLocalStartMoment(newMoment);
            return;
         }

         setStartValidState(true);
         setEndValidState(true);
         setStartInputValidState(true);
         if (isEndInputValid) {
            setErrorMessage(ERROR_MESSAGES.noError);
         } else {
            setErrorMessage(ERROR_MESSAGES.endInputInvalid);
         }
         setLocalStartMoment(newMoment);

         if (isEndInputValid) {
            let endMoment = localEndMoment;
            if (newMoment.isSameOrAfter(endMoment)) {
               endMoment = newMoment.clone().add(1, 'day');
               setLocalEndMoment(endMoment);
            }
            if (notifyChange && instantResponse) {
               onValidDate({ gte: newMoment, lt: endMoment, interval: localSelectedInterval });
            }
         }
      },
      [isEndInputValid, onValidDate, localEndMoment, instantResponse, isWithinMaxInputTimeRange, localSelectedInterval],
   );

   const changeEnd = useCallback(
      function (newMoment, notifyChange = true) {
         const isInvalidInput = typeof newMoment === 'string';
         if (isInvalidInput) {
            setEndInputValidState(false);
            setStartValidState(true);
            setErrorMessage(ERROR_MESSAGES.endInputInvalid);
            setLocalEndMoment(newMoment);
            return;
         }

         if (isStartInputValid && newMoment.isSameOrBefore(localStartMoment)) {
            setStartValidState(false);
            setEndInputValidState(true);
            setEndValidState(false);
            setErrorMessage(ERROR_MESSAGES.startSameOrAfterEnd);
            setLocalEndMoment(newMoment);
            return;
         }

         if (isStartInputValid && !isWithinMaxInputTimeRange(localStartMoment, newMoment, localSelectedInterval)) {
            setStartValidState(false);
            setEndInputValidState(true);
            setEndValidState(false);
            setErrorMessage(ERROR_MESSAGES.maxTimeRangeExceeded);
            setLocalEndMoment(newMoment);
            return;
         }

         setStartValidState(true);
         setEndValidState(true);
         setEndInputValidState(true);
         if (isStartInputValid) {
            setErrorMessage(ERROR_MESSAGES.noError);
         } else {
            setErrorMessage(ERROR_MESSAGES.startInputInvalid);
         }
         setLocalEndMoment(newMoment);
         if (isStartInputValid && notifyChange && instantResponse) {
            onValidDate({ gte: localStartMoment, lt: newMoment, interval: localSelectedInterval });
         }
      },
      [
         isStartInputValid,
         onValidDate,
         localStartMoment,
         instantResponse,
         isWithinMaxInputTimeRange,
         localSelectedInterval,
      ],
   );

   // initial validation trigger
   useEffect(() => {
      triggerDateValidation(true);
   }, []);

   // validation trigger after interval change
   useEffect(() => {
      triggerDateValidation(true);
   }, [localSelectedInterval]);

   // synchronize prop start/end with local
   useEffect(() => {
      const startDateStr = startDate.format(fullDateTimeFormat);
      const localStartDateStr = localStartMomentRef.current.format(fullDateTimeFormat);
      const endDateStr = endDate.format(fullDateTimeFormat);
      const localEndDateStr = localEndMomentRef.current.format(fullDateTimeFormat);

      if (startDateStr !== localStartDateStr) {
         setLocalStartMoment(moment.utc(startDateStr, fullDateTimeFormat));
         triggerDateValidation(true);
      }
      if (endDateStr !== localEndDateStr) {
         setLocalEndMoment(moment.utc(endDateStr, fullDateTimeFormat));
         triggerDateValidation(true);
      }
      if (selectedInterval !== localSelectedIntervalRef.current) {
         setLocalSelectedInterval(selectedInterval);
      }
   }, [startDate, endDate, selectedInterval, fullDateTimeFormat]);

   // validate start/end after sync
   useEffect(() => {
      if (isDateValidationActive) {
         changeEnd(localEndMoment, false);
         changeStart(localStartMoment, false);
         triggerDateValidation(false);
      }
   }, [isDateValidationActive, changeStart, changeEnd, localStartMoment, localEndMoment]);

   // ---> workaround for jumping cursor after editing: https://github.com/arqex/react-datetime/issues/755

   // start
   const inputElementStart = useRef(null);
   const [cursorPositionStart, setCursorPositionStart] = useState(0);

   useNonInitialEffect(() => {
      inputElementStart.current.setSelectionRange(cursorPositionStart, cursorPositionStart);
   }, [localStartMoment, cursorPositionStart]);

   const onInputStart = () => setCursorPositionStart(inputElementStart.current.selectionStart);

   function getStartInputProps() {
      // >>> part of workaround for this function
      const inputProps = {
         onInput: onInputStart,
         ref: inputElementStart,
      };
      // <<<
      if (!isStartValid || !isStartInputValid) {
         inputProps.className = 'form-control invalidDateTime';
      }
      if (onStartFocus !== undefined) {
         inputProps.onFocus = onStartFocus;
      }
      if (!instantResponse && isStartValid && isStartInputValid && isEndValid && isEndInputValid) {
         inputProps.onBlur = (e) => {
            onValidDate({ gte: localStartMoment.clone(), lt: localEndMoment.clone(), interval: localSelectedInterval });
            if (onStartBlur !== undefined) {
               onStartBlur(e);
            }
         };
         inputProps.onKeyDown = (e) => {
            if (e.key === 'Enter') {
               onValidDate({
                  gte: localStartMoment.clone(),
                  lt: localEndMoment.clone(),
                  interval: localSelectedInterval,
               });
            }
         };
      } else {
         if (onStartBlur !== undefined) {
            inputProps.onBlur = onStartBlur;
         }
      }
      return inputProps;
   }

   // end
   const inputElementEnd = useRef(null);
   const [cursorPositionEnd, setCursorPositionEnd] = useState(0);

   useNonInitialEffect(() => {
      inputElementEnd.current.setSelectionRange(cursorPositionEnd, cursorPositionEnd);
   }, [localEndMoment, cursorPositionEnd]);

   const onInputEnd = () => setCursorPositionEnd(inputElementEnd.current.selectionStart);

   function getEndInputProps() {
      // >>> part of workaround for this function
      const inputProps = {
         onInput: onInputEnd,
         ref: inputElementEnd,
      };
      // <<<
      if (!isEndValid || !isEndInputValid) {
         inputProps.className = 'form-control invalidDateTime';
      }
      if (onEndFocus !== undefined) {
         inputProps.onFocus = onEndFocus;
      }
      if (!instantResponse && isStartValid && isStartInputValid && isEndValid && isEndInputValid) {
         inputProps.onBlur = (e) => {
            onValidDate({ gte: localStartMoment.clone(), lt: localEndMoment.clone(), interval: localSelectedInterval });
            if (onEndBlur !== undefined) {
               onEndBlur(e);
            }
         };
         inputProps.onKeyDown = (e) => {
            if (e.key === 'Enter') {
               onValidDate({
                  gte: localStartMoment.clone(),
                  lt: localEndMoment.clone(),
                  interval: localSelectedInterval,
               });
            }
         };
      } else {
         if (onEndBlur !== undefined) {
            inputProps.onBlur = onEndBlur;
         }
      }
      return inputProps;
   }

   // <--- end workaround

   useEffect(() => {
      if (errorMessage === ERROR_MESSAGES.noError) {
         setHasError(false);
      } else {
         setHasError(true);
      }
   }, [errorMessage, setHasError]);

   function changeInterval(event) {
      const value = event.target.value;
      onIntervalChange(value);
      setLocalSelectedInterval(value);
      onValidDate({ gte: localStartMoment, lt: localEndMoment, interval: value });
   }

   const CalendarErrorDisplay = useCallback(
      (mode, renderDefault) => (
         <div className='wrapper'>
            {renderDefault()}
            <DatePickerWrapper>
               <Notification $fontSizePx={11} topMarginPx={2} textAlign={'center'}>
                  {showErrorMsgWithDynamicInfo(errorMessage)}
               </Notification>
            </DatePickerWrapper>
         </div>
      ),
      [errorMessage, showErrorMsgWithDynamicInfo],
   );

   function showStartError(errorMessage, CalendarErrorDisplayFn) {
      return errorMessage === ERROR_MESSAGES.startSameOrAfterEnd ||
         errorMessage === ERROR_MESSAGES.startInputInvalid ||
         errorMessage === ERROR_MESSAGES.startSameOrAfterNow ||
         errorMessage === ERROR_MESSAGES.maxTimeRangeExceeded
         ? CalendarErrorDisplayFn
         : undefined;
   }

   function showEndError(errorMessage, CalendarErrorDisplayFn) {
      return errorMessage === ERROR_MESSAGES.startSameOrAfterEnd ||
         errorMessage === ERROR_MESSAGES.endInputInvalid ||
         errorMessage === ERROR_MESSAGES.maxTimeRangeExceeded
         ? CalendarErrorDisplayFn
         : undefined;
   }

   function getButtonPressedOutput(customStart, customEnd) {
      const gte = customStart ? customStart : localStartMoment.clone();
      const lt = customEnd ? customEnd : localEndMoment.clone();
      const output = monthlyMode
         ? {
              gte: gte.startOf('month'),
              lt: lt.startOf('month'),
           }
         : { gte, lt };
      if (isIntervalSelectAvailable) {
         output.interval = localSelectedInterval;
      } else {
         output.interval = selectedInterval;
      }
      return output;
   }

   return (
      <CenteredFlexContainer $direction={'column'}>
         <BottomMargin pxSize={15}>
            <DatePickerWrapper>
               <PickerWrapper marginRight={4} $widthPx={170}>
                  <Description>
                     From <DescriptionSpan fontSizePX={10}>(UTC Time)</DescriptionSpan>
                  </Description>
                  <SilentDatePicker
                     utc={true}
                     dateFormat={monthlyMode ? MONTH_FORMAT : dateFormat}
                     timeFormat={monthlyMode ? false : timeFormat}
                     onChange={changeStart}
                     value={localStartMoment}
                     inputProps={getStartInputProps()}
                     renderView={showStartError(errorMessage, CalendarErrorDisplay)}
                     isValidDate={(current) => isValidDateFn(current, isValidStartDate, maxInputTimeRange, false)}
                  />
               </PickerWrapper>

               <PickerWrapper marginRight={4} marginLeft={4} $widthPx={170}>
                  <TwoSideRow>
                     <Description>
                        To <DescriptionSpan fontSizePX={10}>(UTC Time)</DescriptionSpan>
                     </Description>
                     <StyledButton
                        type='button'
                        fontSizePx={9}
                        className={'btn'}
                        name={'urlCopy'}
                        id={'urlCopy'}
                        heightPx={10}
                        $buttonHeightPx={14}
                        widthPx={60}
                        marginPx={'1px 0px 3px 0px'}
                        bgColor={'rgb(175,175,175)'}
                        fontColor={'#FFF'}
                        bgColorHover={'rgb(100,100,100)'}
                        fontColorHover={'#FFF'}
                        onClick={() => changeEnd(monthlyMode ? moment.utc().startOf('month') : moment.utc())}
                     >
                        {'Set to now'}
                     </StyledButton>
                  </TwoSideRow>
                  <SilentDatePicker
                     utc={true}
                     dateFormat={monthlyMode ? MONTH_FORMAT : dateFormat}
                     timeFormat={monthlyMode ? false : timeFormat}
                     onChange={changeEnd}
                     value={localEndMoment}
                     inputProps={getEndInputProps()}
                     renderView={showEndError(errorMessage, CalendarErrorDisplay)}
                     isValidDate={(current) => isValidDateFn(current, isValidEndDate, maxInputTimeRange, true)}
                  />
               </PickerWrapper>

               {isIntervalSelectAvailable && (
                  <PickerWrapper marginLeft={4} $widthPx={170}>
                     <Description>By</Description>
                     <Input type='select' value={localSelectedInterval} onChange={changeInterval}>
                        {intervals.map((interval) => (
                           <option key={interval.name} value={interval.value}>
                              {interval.name}
                           </option>
                        ))}
                     </Input>
                  </PickerWrapper>
               )}
               {isButtonAvailable && (
                  <Button
                     color='primary'
                     disabled={!(isStartValid && isEndValid && isStartInputValid && isEndInputValid)}
                     onClick={() => onGoButtonPress(getButtonPressedOutput())}
                     className='btn-marginLeft mt-3 ml-3'
                  >
                     Go
                  </Button>
               )}
            </DatePickerWrapper>
            <DisplayLineWrapper $heightPx={15}>
               <Notification
                  $fontSizePx={12}
                  $color={monthlyMode && errorMessage === ERROR_MESSAGES.noError ? 'rgb(150,150,150)' : undefined}
                  className='mt-1'
               >
                  {monthlyMode && errorMessage === ERROR_MESSAGES.noError
                     ? `Selected range: ${localStartMoment.format(MONTHLY_DISPLAY_FORMAT)} - ${localEndMoment.format(
                          MONTHLY_DISPLAY_FORMAT,
                       )}`
                     : `${showErrorMsgWithDynamicInfo(errorMessage)}`}
               </Notification>
            </DisplayLineWrapper>
         </BottomMargin>
         {showIntervalSwitchButtons && (
            <BottomMargin pxSize={15}>
               <CenteredFlexContainer $width={'350px'} horizontal={'stretch'}>
                  <TwoSideFlexContainer>
                     <SimpleFlexRow>Previous interval</SimpleFlexRow>
                     <SimpleFlexRow>
                        <Button
                           color='primary'
                           onClick={() => {
                              const { localStart, localEnd } = getPreciousInterval(
                                 localStartMoment,
                                 localEndMoment,
                                 localSelectedInterval,
                                 intervals !== undefined,
                                 monthlyMode,
                              );
                              setLocalStartMoment(localStart);
                              setLocalEndMoment(localEnd);
                              onGoButtonPress(getButtonPressedOutput(localStart, localEnd));
                              triggerDateValidation(true);
                           }}
                           className='btn ml-2 mr-1 btn-sm-width'
                           size='sm'
                        >
                           -
                        </Button>
                     </SimpleFlexRow>
                  </TwoSideFlexContainer>
                  <TwoSideFlexContainer>
                     <SimpleFlexRow>
                        <Button
                           color='primary'
                           onClick={() => {
                              const { localStart, localEnd } = getNextInterval(
                                 localStartMoment,
                                 localEndMoment,
                                 localSelectedInterval,
                                 intervals !== undefined,
                                 monthlyMode,
                              );
                              setLocalStartMoment(localStart);
                              setLocalEndMoment(localEnd);
                              onGoButtonPress(getButtonPressedOutput(localStart, localEnd));
                              triggerDateValidation(true);
                           }}
                           className='btn ml-1 mr-2 btn-sm-width'
                           size='sm'
                        >
                           +
                        </Button>
                     </SimpleFlexRow>
                     <SimpleFlexRow>Next interval</SimpleFlexRow>
                  </TwoSideFlexContainer>
               </CenteredFlexContainer>
            </BottomMargin>
         )}
      </CenteredFlexContainer>
   );
}

DateTimePicker.propTypes = {
   /** Description of prop "intervals".
    * Array of selectable intervals like 'hour', 'day', 'month'
    * note: if this props is not used intervals are not being displayed
    */
   intervals: PropTypes.arrayOf(
      PropTypes.shape({
         value: PropTypes.string,
         name: PropTypes.string,
      }),
   ),
   /** Description of prop "startDate".
    * global start datetime as a moment object
    */
   startDate: momentPropTypes.momentObj,
   /** Description of prop "endDate".
    * global end datetime as a moment object
    */
   endDate: momentPropTypes.momentObj,
   /** Description of prop "selectedInterval".
    * global interval like 'hour'
    */
   selectedInterval: PropTypes.string,
   /** Description of prop "instantResponse".
    * true = onValidDateChange is fired after any change of the date (date need to be valid)
    * false = onValidDateChange is fired after focus lost of start or end datepicker (date need to be valid)
    */
   instantResponse: PropTypes.bool,
   /** Description of prop "onGoButtonPress".
    * handler function which is fired when the 'GO' button has been pressed
    */
   onGoButtonPress: PropTypes.func,
   /** Description of prop "onIntervalChange".
    * handler function which is fired when the interval has been changed like 'hour' -> 'day'
    * parameters: (timespan: {gte: moment.obj, lt: moment.obj, interval?: string})
    * note: if this props is not used the GO button is not being displayed
    */
   onIntervalChange: PropTypes.func,
   /** Description of prop "onValidDateChange".
    * handler function which is fired when the date (start and/or end) has been changed
    * parameters: (timespan: {gte: moment.obj, lt: moment.obj, interval?: string})
    * note: could be the same date as before
    */
   onValidDateChange: PropTypes.func,
   /** Description of prop "onStartBlur".
    * handler function which is fired when the start datepicker lost focus
    */
   onStartBlur: PropTypes.func,
   /** Description of prop "onStartFocus".
    * handler function which is fired when the start datepicker get focus
    */
   onStartFocus: PropTypes.func,
   /** Description of prop "onEndBlur".
    * handler function which is fired when the end datepicker lost focus
    */
   onEndBlur: PropTypes.func,
   /** Description of prop "onEndFocus".
    * handler function which is fired when the end datepicker get focus
    */
   onEndFocus: PropTypes.func,
   /** Description of prop "isValidStartDate".
    * function to define the start dates those can be selected in the calender
    * parameters: (shown calendar date, current start date, current end date)
    * note: if maxInputTimeRange is given then isValidStartDate is not active!
    */
   isValidStartDate: PropTypes.func,
   /** Description of prop "isValidEndDate".
    * function to define the end dates those can be selected in the calender
    * parameters: (shown calendar date, current start date, current end date)
    * note: if maxInputTimeRange is given then isValidEndDate is not active!
    */
   isValidEndDate: PropTypes.func,
   /** Description of prop "maxInputTimeRange".
    * max duration between start and end (object is used for a moment duration object like 'moment.duration(3, 'days')')
    * for valid units see moment.js doc
    * note: don't use time intervals bigger than 'days' to avoid unexpected results
    */
   maxInputTimeRange: PropTypes.exact({ value: PropTypes.number, unit: PropTypes.string }),
   /** Description of prop "dateFormat".
    * date format (see moment.js docs) or false to just use time
    */
   dateFormat: PropTypes.string,
   /** Description of prop "timeFormat".
    * time format (see moment.js docs) or false to just use date
    */
   timeFormat: PropTypes.string,
   /** Description of prop "monthlyMode".
    * use Datepicker as month picker (only month and year)
    */
   monthlyMode: PropTypes.bool,
   /** Description of prop "setHasError".
    * set parent component error state variable to indicate if there are errors or not
    */
   setHasError: PropTypes.func,
   /** Description of prop "setCustomMaxTimeRangesForIntervals".
    * set a custom max. time ranges for a certain interval like second, minute or hour
    */
   setCustomMaxTimeRangesForIntervals: PropTypes.object,
   /** Description of prop "showIntervalSwitchButtons".
    * whether to show interval switch buttons
    */
   showIntervalSwitchButtons: PropTypes.bool,
};

DateTimePicker.defaultProps = {
   startDate: moment.utc().startOf('minute').subtract(1, 'day'),
   endDate: moment.utc().startOf('minute'),
   instantResponse: true,
   onIntervalChange: () => {},
   onValidDateChange: () => {},
   isValidStartDate: () => true,
   isValidEndDate: () => true,
   dateFormat: SIMPLE_DATE_FORMAT,
   timeFormat: SIMPLE_TIME_FORMAT,
   monthlyMode: false,
   setHasError: () => {},
   showIntervalSwitchButtons: false,
};

export default DateTimePicker;

function getPreciousInterval(localStart, localEnd, localInterval, isIntervalActive, isMonthlyMode) {
   if (isMonthlyMode) {
      let duration = moment.duration(localEnd.diff(localStart)).as('months');
      duration = Math.round(duration);
      return {
         localStart: localStart.clone().subtract(duration, 'months'),
         localEnd: localEnd.clone().subtract(duration, 'months'),
      };
   }
   if (isIntervalActive) {
      let duration = moment.duration(localEnd.diff(localStart)).as(localInterval);
      let isFullInterval = Number.isInteger(duration);
      if (localInterval === 'year') {
         isFullInterval =
            localStart.clone().startOf('year').unix() === localStart.unix() &&
            localEnd.clone().startOf('year').unix() === localEnd.unix();
         duration = Math.round(duration);
      }
      if (localInterval === 'month') {
         isFullInterval =
            localStart.clone().startOf('month').unix() === localStart.unix() &&
            localEnd.clone().startOf('month').unix() === localEnd.unix();
         duration = Math.round(duration);
      }
      if (isFullInterval) {
         return {
            localStart: localStart.clone().subtract(duration, localInterval),
            localEnd: localEnd.clone().subtract(duration, localInterval),
         };
      }
   }
   const unixSecsDuration = localEnd.unix() - localStart.unix();
   return {
      localStart: localStart.clone().subtract(unixSecsDuration, 'seconds'),
      localEnd: localEnd.clone().subtract(unixSecsDuration, 'seconds'),
   };
}

function getNextInterval(localStart, localEnd, localInterval, isIntervalActive, isMonthlyMode) {
   if (isMonthlyMode) {
      let duration = moment.duration(localEnd.diff(localStart)).as('months');
      duration = Math.round(duration);
      return {
         localStart: localStart.clone().add(duration, 'months'),
         localEnd: localEnd.clone().add(duration, 'months'),
      };
   }
   if (isIntervalActive) {
      let duration = moment.duration(localEnd.diff(localStart)).as(localInterval);
      let isFullInterval = Number.isInteger(duration);
      if (localInterval === 'year') {
         isFullInterval =
            localStart.clone().startOf('year').unix() === localStart.unix() &&
            localEnd.clone().startOf('year').unix() === localEnd.unix();
         duration = Math.round(duration);
      }
      if (localInterval === 'month') {
         isFullInterval =
            localStart.clone().startOf('month').unix() === localStart.unix() &&
            localEnd.clone().startOf('month').unix() === localEnd.unix();
         duration = Math.round(duration);
      }
      if (isFullInterval) {
         return {
            localStart: localStart.clone().add(duration, localInterval),
            localEnd: localEnd.clone().add(duration, localInterval),
         };
      }
   }
   const unixSecsDuration = localEnd.unix() - localStart.unix();
   return {
      localStart: localStart.clone().add(unixSecsDuration, 'seconds'),
      localEnd: localEnd.clone().add(unixSecsDuration, 'seconds'),
   };
}
