import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, Spinner } from '../../../common';

import { CardTitle, Col, Row } from 'reactstrap';

import { filtersChanged, timespanOrIntervalChanged } from '../../../../redux/selectors/comparing';

import 'spinkit/css/spinkit.css';

import { PropTypes } from 'prop-types';
import storePropTypes from '../../../../redux/store/propTypes';

import { lineChartRatioZoomOptions } from '../../../../constants/chartjs';
import { H5LIVE_VIEW } from '../../../../constants/general';
import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   SetChartHeight,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';
import ComponentTools from '../../../../util/ComponentTools';

import { getUnifiedErrorObject } from '../../../../util/ErrorUnifier';
import { BUFFERING_RATIO } from '../../../zoom/ZoomConstants';
import ZoomHint from '../../../zoom/ZoomHint';
import Modal from '../../../zoom/templates/DefaultTemplate';
import { H5LIVE_SETTINGS } from '../../../zoom/widgetSettings/H5liveView';

const chartOptions = lineChartRatioZoomOptions();

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setAlarm };

let requestForData = false;

class BufferingRatio extends Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         chart: {},
         modal: {
            toggleShow: false,
            timespansPerIndex: [
               {
                  start: moment.utc(),
                  end: moment.utc(),
               },
            ],
            chosenIndex: 0,
         },
         error: '',
         noData: false,
         url: '',
         header: {},
         cancelToken: null,
      };
      this.chartRef = React.createRef();
   }

   getData(timespan, filter, cancelToken) {
      this.setState({
         ...this.state,
         loading: true,
         noData: false,
         error: '',
      });

      const self = this;

      const headerObj = ComponentTools.getHttpHeader(filter);
      const urlFilterQuery = ComponentTools.getFilterQueryParams(filter, H5LIVE_VIEW);

      const updated = {
         type: 'line',
         labels: [],
         datasets: [
            {
               label: 'Play buffering ratio',
               borderColor: '#2bc435',
               borderWidth: 2,
               data: [],
               fill: false,
            },
         ],
      };

      const labels = [];
      const timespansPerIndex = [];

      ComponentTools.setLineChartLabels(updated, labels, timespan, this.props.settings.weekType, timespansPerIndex);

      const url = `/api/v2/h5live/bufferingratio/timeseries?from=${timespan.gte.format()}&to=${timespan.lt.format()}&interval=${
         timespan.interval
      }${urlFilterQuery}`;

      axios
         .get(url, {
            headers: headerObj,
            cancelToken: cancelToken.token,
         })
         .then(function (response) {
            let noData = false;
            if (response.status !== 204) {
               const buckets = response.data.data;
               noData = response.data.statusCode === 2001;

               let lastIdxWithData = -1;

               labels.forEach((label, idx) => {
                  const start = label.start / 1000;
                  const end = label.end / 1000;
                  const interval = buckets.find((bucket) => bucket.timestamp >= start && bucket.timestamp < end);
                  if (interval !== undefined && interval.percent !== null) {
                     updated.datasets[0].data.push(Math.round(interval.percent * 100) / 100);
                     lastIdxWithData = idx;
                  } else {
                     if (lastIdxWithData === -1) {
                        updated.datasets[0].data.push(null);
                     } else {
                        updated.datasets[0].data.push(0);
                     }
                  }
               });

               for (let index = lastIdxWithData + 1; index < updated.datasets[0].data.length; index++) {
                  updated.datasets[0].data[index] = null;
               }
            }

            self.setState({
               chart: updated,
               noData,
               loading: false,
               modal: { ...self.state.modal, timespansPerIndex },
               url,
               header: headerObj,
               cancelToken,
            });
         })
         .catch(function (error) {
            if (error instanceof axios.Cancel) {
               return;
            }

            const errorObject = getUnifiedErrorObject(error);

            self.props.setAlarm('danger', errorObject);

            if (process.env.NODE_ENV === 'development') {
               console.error('error :', error);
            }

            self.setState({
               ...self.state,
               chart: updated,
               loading: false,
               error: errorObject.message,
            });
         });
   }

   componentDidMount() {
      requestForData = true;
   }

   componentWillReceiveProps(nextProps, nextContext) {
      if (timespanOrIntervalChanged(this.props, nextProps) || filtersChanged(this.props, nextProps) || requestForData) {
         requestForData = false;
         this.getData(nextProps.timespan, nextProps.filter, nextProps.cancelToken);

         chartOptions['onClick'] = (event, item) => {
            if (item.length === 0) {
               return;
            }
            const index = item[0]._index;

            this.setState({
               modal: {
                  ...this.state.modal,
                  chosenIndex: index,
                  toggleShow: true,
               },
            });
         };
      }
   }

   render() {
      const errorOrNoData = this.state.error || this.state.noData;
      const issueMsg = this.state.error ? this.state.error : 'No data available';

      const { toggleShow, timespansPerIndex, chosenIndex } = this.state.modal;
      const chosenTimespan = timespansPerIndex[chosenIndex];
      const { url, header, cancelToken } = this.state;

      return (
         <WidgetContainer>
            <WidgetHeaderContainer>
               <HeightSetContainer $heightInPx={23}>
                  <TwoSideFlexContainer>
                     <SimpleFlexRow>
                        <CsvExportFromApiButton
                           url={url}
                           header={header}
                           cancelToken={cancelToken}
                           filename={'H5live_BufferingRatio'}
                        />
                     </SimpleFlexRow>
                     <SimpleFlexRow>
                        <ZoomHint />
                     </SimpleFlexRow>
                  </TwoSideFlexContainer>
               </HeightSetContainer>
            </WidgetHeaderContainer>
            <WidgetBodyContainer>
               <Row>
                  <Col>
                     <CardTitle className='mb-0'>Play buffering ratio in percent</CardTitle>
                     <div className='small text-muted'>
                        {`${this.props.timespan.title}\u00A0\u00A0\u00A0( UTC time )`}
                     </div>
                  </Col>
               </Row>
               <ChartWrapper className='chart-wrapper' marginTopPx={20} height={this.props.height}>
                  <Spinner loading={this.state.loading} parentTopMarginPx={60}>
                     {errorOrNoData ? (
                        <CenteredMsgShowing height={this.props.height}>{issueMsg}</CenteredMsgShowing>
                     ) : (
                        <SetChartHeight height={this.props.height}>
                           <Line ref={this.chartRef} data={this.state.chart} options={chartOptions} height={300} />
                        </SetChartHeight>
                     )}
                  </Spinner>
               </ChartWrapper>
            </WidgetBodyContainer>
            {toggleShow && (
               <Modal
                  timespan={chosenTimespan}
                  metric={BUFFERING_RATIO}
                  closeModal={() =>
                     this.setState({
                        modal: {
                           ...this.state.modal,
                           toggleShow: false,
                        },
                     })
                  }
                  settings={H5LIVE_SETTINGS}
               />
            )}
         </WidgetContainer>
      );
   }
}

BufferingRatio.propTypes = {
   filter: storePropTypes.filter,
   timespan: storePropTypes.timespan,
   cancelToken: storePropTypes.cancelToken,
   settings: storePropTypes.settings,
   setAlarm: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(BufferingRatio);
