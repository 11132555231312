import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import DataColumn from './DataColumn';
import { requestSettings } from './RequestConstants';
import { progressSettings } from './ProgressConstants';
import useSignal from '../../../hooks/useSignal';
import { PropTypes } from 'prop-types';

function addHiddenKeyColumn(rows) {
   return rows.map((row, idx) => ({ ...row, hiddenKey: idx }));
}

function DataTable({ setTableData, setTrialTableData, setTotalUsageData }) {
   const [newDataSignal, triggerNewDataSignal] = useSignal();
   const now = moment.utc();
   const dataArray = useRef(new Array(requestSettings.length));

   useEffect(() => {
      const orgaList = dataArray.current[0];
      if (orgaList !== undefined) {
         // metric = current month, last month ...
         const metricArray = dataArray.current.slice(1);
         const metricRequestSettings = requestSettings.slice(1);

         const rows = [];
         const trialOnlyRows = [];
         const totalUsages = {};
         orgaList.forEach((orgaInfo) => {
            const { hash, createdAt, emails, trial, access } = orgaInfo;
            const isAdminOrga = hash === '*';
            const numberOfOrgas = orgaList.length - 1;
            const newRow = {
               ...orgaInfo,
               createdAt: isAdminOrga ? '' : moment(createdAt).format('MMMM Do YYYY'),
               access: access ? access : '',
               emails: emails ? emails.join(',') : '',
            };

            if (!isAdminOrga) {
               // console.log('metricArray :>> ', metricArray);
               metricArray.forEach((metricData, idx) => {
                  if (metricData) {
                     const metricSettings = metricRequestSettings[idx];
                     newRow[metricSettings.shortDesc] = metricData[hash] !== undefined ? metricData[hash] : 0;
                  }
               });
            } else {
               metricArray.forEach((metricData, idx) => {
                  if (metricData) {
                     const isRatioMetric = idx === 0;
                     const metricSettings = metricRequestSettings[idx];
                     const allMetricValues = Object.values(metricData);
                     const cummulatedValues = allMetricValues.reduce((acc, value) => acc + value, 0);
                     newRow[metricSettings.shortDesc] = isRatioMetric
                        ? cummulatedValues / numberOfOrgas
                        : cummulatedValues;
                     if (metricSettings.total) {
                        totalUsages[metricSettings.shortDesc] = newRow[metricSettings.shortDesc];
                     }
                  }
               });
            }

            progressSettings.forEach((setting) => {
               const { shortDesc, currentRefValues, lastRefValues } = setting;
               const currentRefIndex = requestSettings.findIndex(({ shortDesc }) => shortDesc === currentRefValues);
               const lastRefIndex = requestSettings.findIndex(({ shortDesc }) => shortDesc === lastRefValues);

               if (dataArray.current[currentRefIndex] !== undefined && dataArray.current[lastRefIndex] !== undefined) {
                  newRow[shortDesc] =
                     ((newRow[currentRefValues] - newRow[lastRefValues]) * 100) / totalUsages[currentRefValues];
               }
            });
            // console.log('newRow :>> ', newRow);
            rows.push(newRow);
            if (trial) trialOnlyRows.push(newRow);
         });
         setTableData(addHiddenKeyColumn(rows));
         setTrialTableData(addHiddenKeyColumn(trialOnlyRows));
         setTotalUsageData(totalUsages);
      }
   }, [newDataSignal, setTableData, setTrialTableData, setTotalUsageData]);

   return (
      <>
         {requestSettings.map(({ desc, url, start, end, refresh, shortDesc }, idx) => (
            <DataColumn
               key={desc}
               baseUrl={url}
               start={start ? start(now).format() : start}
               end={end ? end(now).format() : end}
               refresh={refresh}
               notifyCompletedRequest={triggerNewDataSignal}
               dataArray={dataArray.current}
               accessIdx={idx}
               shortDesc={shortDesc}
            />
         ))}
      </>
   );
}

DataTable.propTypes = {
   setTableData: PropTypes.func,
   setTrialTableData: PropTypes.func,
   setTotalUsageData: PropTypes.func,
};

export default DataTable;
