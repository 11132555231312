import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AccessLevel } from '../../../../constants/AccessLevels';

import { Dashboard } from '../../../common';
import Widget from '../../Widget/Widget';

import { StreamQoE, TableQoE } from '../../../widgets/qoe';
import { Col, Row } from 'reactstrap';

import storePropTypes from '../../../../redux/store/propTypes';

import axios from 'axios';
import { setNewCancelToken, setViewTimespan } from './../../../../redux/actions/general';

import { PropTypes } from 'prop-types';
import { QOE_VIEW } from './../../../../constants/general';

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setNewCancelToken, setViewTimespan };

class QoE extends Component {
   componentWillUnmount() {
      // cancel token, added to old requests, will be activated --> old requests will be cancelled
      this.props.cancelToken.cancel('Operation canceled by the user.');
      this.props.setViewTimespan(QOE_VIEW);
      this.props.setNewCancelToken(axios.CancelToken.source());
   }

   render() {
      return (
         <Dashboard location={this.props.location} history={this.props.history} view={QOE_VIEW}>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget type={StreamQoE} requiredLevel={AccessLevel.FULL} widgetAttributes={{ height: 400 }} />
               </Col>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget type={TableQoE} requiredLevel={AccessLevel.FULL} widgetAttributes={{ height: 400 }} />
               </Col>
            </Row>
         </Dashboard>
      );
   }
}

QoE.propTypes = {
   cancelToken: storePropTypes.cancelToken,
   setNewCancelToken: PropTypes.func,
   setViewTimespan: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(QoE);
