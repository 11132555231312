import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import { motion } from 'framer-motion';

import { PropTypes } from 'prop-types';
import CircleColorManager from './CircleColorManager';

import InfoBox from './InfoBox';
import Modal from '../../../zoom/templates/WorldmapTemplate';

import { timespanSelector } from './../../../../redux/selectors/selectors';
import { isMobileDevice } from '../../../../util/BrowserTools';
import { ClearCountryContext, ClickOutsideContext, ClickOutsideStates } from '../WorldContainer';

const STROKE_WIDTH = 1;

const circleAnimationStyles = {
   hover: {
      opacity: 0.9,
      boxShadow: '0px 0px 0px 1.3px #FFFFFF',
      zIndex: 999,
      scale: 2,
   },
   press: {
      opacity: 0.7,
      boxShadow: '0px 0px 0px 1px #FFFFFF',
      zIndex: 999,
      scale: 1.2,
   },
};

const StyledCircle = styled(motion.div).attrs(({ height, width, top, left, background, isRegionZoomed }) => ({
   style: {
      height: `${height}px`,
      width: `${width}px`,
      top: `${top}px`,
      left: `${left}px`,
      background,
      boxShadow: '0px 0px 0px 1px #FFFFFF',
      opacity: isRegionZoomed ? 0.9 : 0.6,
   },
}))`
   z-index: 10;
   scale: 1;
   position: absolute;
   border-radius: 50%;
   user-select: none;
`;
StyledCircle.displayName = 'StyledCircle';

const StyledRank = styled.div`
   font-weight: bolder;
   font-size: 0.7em;
   color: rgb(255, 255, 255);
   text-align: center;
   cursor: default;
   vertical-align: middle;
   display: table-cell;
   line-height: ${(props) => props.height}px;
   height: ${(props) => props.height}px;
   width: ${(props) => props.width}px;
`;
StyledRank.displayName = 'StyledRank';

const isMobileDeviceUsed = isMobileDevice();

function DataCircle({
   mapHeight,
   position,
   maxHeatValue,
   total,
   rank,
   circleTextDistance,
   radius,
   metric,
   subSwitch,
   countryData,
   isRegionZoomed,
}) {
   const [clickOutsideState, setClickOutsideState] = useContext(ClickOutsideContext);
   const [clearCountry, setClearCountry] = useContext(ClearCountryContext);
   const [isHovered, setHoverState] = useState(false);
   const [isUnhoverTransition, setUnhoverTransitionState] = useState(false);

   const [showModal, setShowModalState] = useState(false);

   const circleColor = CircleColorManager.getCircleColor(metric, countryData, maxHeatValue);
   const borderRadius = radius + STROKE_WIDTH;
   const showRank = rank !== 0;

   const timespan = useSelector(timespanSelector);

   useEffect(() => {
      if (clickOutsideState === ClickOutsideStates.CLOSE) {
         setHoverState(false);
         setClickOutsideState(ClickOutsideStates.READY);
      }
      if (clearCountry && clearCountry !== countryData.alpha) {
         setHoverState(false);
      }
   }, [clickOutsideState, setClickOutsideState, clearCountry, countryData.alpha]);

   return (
      <>
         <StyledCircle
            height={radius * 2}
            width={radius * 2}
            top={position.y - borderRadius}
            left={position.x - borderRadius}
            background={circleColor}
            whileHover={circleAnimationStyles.hover}
            whileTap={circleAnimationStyles.press}
            isRegionZoomed={isRegionZoomed}
            onHoverStart={() => (isMobileDeviceUsed ? {} : setHoverState(true))}
            onHoverEnd={() => {
               if (!isMobileDeviceUsed) {
                  // flag that avoids dismissing of chart right away
                  // -> chart keeps displayed until infobox is disappeared
                  setUnhoverTransitionState(true);
                  setHoverState(false);
               }
            }}
            onTap={(event) => {
               if (isMobileDeviceUsed) {
                  setHoverState((currentState) => !currentState);
                  setClearCountry(countryData.alpha);
                  setClickOutsideState(ClickOutsideStates.ACTIVATING);
               } else {
                  setShowModalState(true);
                  // console.log('countryAlpha :>> ', showRank ? event.target.parentNode.title : event.target.title);
                  // console.log('countryAlpha :>> ', event);
               }
            }}
         >
            {showRank && (
               <StyledRank height={radius * 2} width={radius * 2}>
                  {rank}
               </StyledRank>
            )}
         </StyledCircle>

         <InfoBox
            mapHeight={mapHeight}
            position={position}
            countryData={countryData}
            circleTextDistance={circleTextDistance}
            isHovered={isHovered}
            isUnhoverTransition={isUnhoverTransition}
            setUnhoverTransitionState={setUnhoverTransitionState}
            total={total}
            subSwitch={subSwitch}
            maxHeatValue={maxHeatValue}
            metric={metric}
         />
         {showModal && (
            <Modal
               country={countryData.alpha}
               timespan={{ start: timespan.gte, end: timespan.lt }}
               metric={metric}
               closeModal={() => setShowModalState(false)}
            />
         )}
      </>
   );
}

DataCircle.propTypes = {
   mapHeight: PropTypes.number,
   position: PropTypes.exact({
      x: PropTypes.number,
      y: PropTypes.number,
   }),
   maxHeatValue: PropTypes.number,
   total: PropTypes.number,
   rank: PropTypes.number,
   circleTextDistance: PropTypes.number,
   radius: PropTypes.number,
   metric: PropTypes.string,
   subSwitch: PropTypes.string,
   isRegionZoomed: PropTypes.bool,
   countryData: PropTypes.shape({
      alpha: PropTypes.string,
      id: PropTypes.string,
      coor: PropTypes.arrayOf(PropTypes.number),
      // country of the world
      countryName: PropTypes.string,
      // US region
      regionName: PropTypes.string,
      // Buffering ratio
      ratio: PropTypes.number,
      totalTime: PropTypes.number,
      // Latency
      latency: PropTypes.number,
      // Playtime
      playtime: PropTypes.number,
      // Usage
      sent: PropTypes.number,
      received: PropTypes.number,
      total: PropTypes.number,
      // ABR
      compare: PropTypes.shape({
         labels: PropTypes.array,
         mobileSet: PropTypes.array,
         desktopSet: PropTypes.array,
         maximumValue: PropTypes.number,
      }),
      profileData: PropTypes.object,
      tableRow: PropTypes.arrayOf(
         PropTypes.shape({
            mobile: PropTypes.number,
            desktop: PropTypes.number,
            mobilePercent: PropTypes.number,
            desktopPercent: PropTypes.number,
            profile: PropTypes.string,
            totalCount: PropTypes.number,
            maximumValue: PropTypes.number,
         }),
      ),
      // several metrics
      count: PropTypes.any,
   }),
};

export default DataCircle;
