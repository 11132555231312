import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFilterQueryParams, getHttpHeader } from '../../../../../util/FetchTools';

import useDataFetch from '../../../../../hooks/useDataFetch';

import {
   AlignedIconContainer,
   BadgeListItem,
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   PaddingContainer,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../../styledComponents/styledComponents';

import { Bar } from 'react-chartjs-2';
import { CsvExportFromApiButton, HorizontalSeparator, Spinner } from '../../../../common';

import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';

import { CardTitle, Col, Row } from 'reactstrap';

import moment from 'moment';

import {
   cancelTokenSelector,
   hashSelector,
   troubleIntervalSelector,
   troubleSelectedPublishSelector,
} from '../../../../../redux/selectors/selectors';

import { PropTypes } from 'prop-types';

import 'spinkit/css/spinkit.css';

import 'chartjs-plugin-annotation';
import { getAnnotation, getOptionsWithAnnotations, rawEventChartOptions } from '../../../../../constants/chartjs';
import { TROUBLESHOOTING_VIEW } from '../../../../../constants/general';
import { setAlarm } from '../../../../../redux/actions/general';
import { EXT_DATE_TIME_MILLISECS_FORMAT, TITLE_DATE_TIME_FORMAT } from '../../constants';
import { getUrlEndString, getUrlStartString } from '../../general/DataProcessing';

import ReactDOMServer from 'react-dom/server';
import { Tooltip as ReactTooltip } from 'react-tooltip/dist/react-tooltip.umd';
import { DefaultGrey } from '../../../../../constants/colors';

const red = getStyle('--danger');

function createBarChartData(chartData, title, drops) {
   const annotations = [];

   drops.forEach((drop, idx) => {
      const unixMilli = moment.utc(drop.date, EXT_DATE_TIME_MILLISECS_FORMAT).valueOf();
      const annotation = getAnnotation(unixMilli, drop.type, '#fc4103', idx, 'x-axis-0');
      annotations.push(annotation);
   });

   return {
      chartDatasets: {
         datasets: [
            {
               label: title,
               backgroundColor: hexToRgba(red, 10),
               borderColor: red,
               borderWidth: 1,
               data: chartData,
               fill: 'origin',
               showLine: true,
               maxBarThickness: 6,
               minBarLength: 2,
            },
         ],
      },
      annotations,
   };
}

function IngestWidgetsRaw({
   height,
   start,
   end,
   streamName,
   title,
   urlSegment,
   resParseFn,
   protocol,
   drops,
   explanation,
   csvFilename,
}) {
   const { publishStart, publishEnd } = useSelector(troubleSelectedPublishSelector);

   const hash = useSelector(hashSelector);
   const interval = useSelector(troubleIntervalSelector);
   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const startUnixMilli = start.valueOf();
   const endUnixMilli = end.valueOf();
   const intervalWidthInSecs = interval.stepsInMilliSec / 1000;

   const [chart, setChart] = useState({
      labels: [],
      datasets: [],
   });

   const [chartOptions, setChartOptions] = useState(
      rawEventChartOptions(startUnixMilli, endUnixMilli, title, intervalWidthInSecs),
   );

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const streamFilterArray = useMemo(() => [streamName], [streamName]);
   const urlFilterQuery = getFilterQueryParams([], [], [], streamFilterArray, TROUBLESHOOTING_VIEW);

   const urlFrom = getUrlStartString(publishStart, start);
   const urlEnd = getUrlEndString(publishEnd, end);

   const url = `/api/v2/troubleshooting/${protocol}/${urlSegment}/raw/timeseries?from=${urlFrom}&to=${urlEnd}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            const chartData = data.data.map((item) => ({ x: item.timestamp * 1000, y: resParseFn(item) }));

            const { chartDatasets, annotations } = createBarChartData(chartData, title, drops);

            setChart(chartDatasets);
            setChartOptions(
               getOptionsWithAnnotations(
                  rawEventChartOptions(startUnixMilli, endUnixMilli, title, intervalWidthInSecs),
                  annotations,
               ),
            );
         }
      }

      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [
      data,
      drops,
      intervalWidthInSecs,
      successful,
      errorMessage,
      httpStatus,
      dispatch,
      title,
      startUnixMilli,
      endUnixMilli,
      resParseFn,
   ]);

   const startFormatted = start.format(TITLE_DATE_TIME_FORMAT);
   const endFormatted = end.format(TITLE_DATE_TIME_FORMAT);

   return (
      <PaddingContainer $padding={'0px 8px'}>
         <WidgetContainer $margin={'0px -7px 6px -7px'}>
            <WidgetHeaderContainer>
               <HeightSetContainer $heightInPx={23}>
                  <TwoSideFlexContainer>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <CsvExportFromApiButton
                              url={url}
                              header={header}
                              cancelToken={cancelToken}
                              filename={csvFilename}
                           />
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                     <SimpleFlexRow>
                        {explanation !== undefined && (
                           <>
                              <SimpleFlexItem>
                                 <AlignedIconContainer
                                    data-tooltip-id='sts'
                                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                       <div>
                                          {explanation.map((text, i) => (
                                             <div key={i}>{text}</div>
                                          ))}
                                          {/* <a
                                          href={ DOCUMENTATION_URL }
                                          target='_blank'
                                          rel='noopener noreferrer'
                                       >
                                          <StyledButton
                                             type='button'
                                             fontSizePx={10}
                                             className={'btn'}
                                             name={`${title}Btn`}
                                             id={`${title}Btn`}
                                             heightPx={14}
                                             widthPx={45}
                                             bgColor={'#20a8d8'}
                                             fontColor={'#FFF'}
                                             bgColorHover={'#26bcf3'}
                                             fontColorHover={'#111'}
                                             $cursor={'default'}
                                             $verticalAlign={'top'}
                                          >
                                             here
                                          </StyledButton>
                                       </a> */}
                                       </div>,
                                    )}
                                    className='bi bi-info-circle ml-1'
                                 ></AlignedIconContainer>
                                 <ReactTooltip
                                    id='sts'
                                    place={'top'}
                                    className={'reduced-line-height bring-on-top'}
                                    backgroundColor={'rgba(0,0,0,0.9)'}
                                    textColor={'rgb(240,240,240)'}
                                    style={{ textAlign: 'center' }}
                                    clickable
                                 />
                              </SimpleFlexItem>
                              <HorizontalSeparator
                                 color={DefaultGrey(1, 180)}
                                 widthInPx={1}
                                 heightInPx={18}
                                 marginX_InPx={12}
                                 marginTopInPx={0}
                              />
                           </>
                        )}
                        <SimpleFlexItem>
                           <BadgeListItem
                              $fontSizePx={10.5}
                              $fontWeight={700}
                              $marginTopPx={1}
                              $marginBottomPx={0}
                              $marginRightPx={0}
                              $bgColor={'white'}
                              $color={'#ff923fc2'}
                              $cursor={'default'}
                              $border={'2px solid #ff923fc2'}
                              $heightPx={19}
                           >
                              Raw Data Mode
                           </BadgeListItem>
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                  </TwoSideFlexContainer>
               </HeightSetContainer>
            </WidgetHeaderContainer>
            <WidgetBodyContainer>
               <Row>
                  <Col>
                     <CardTitle className='mb-0'>{title}</CardTitle>
                     <div className='small text-muted'>{`From ${startFormatted} to ${endFormatted} \u00A0\u00A0( UTC time )`}</div>
                  </Col>
               </Row>
               <ChartWrapper className='chart-wrapper' height={height} marginTopPx={20}>
                  {errorMessage ? (
                     <CenteredMsgShowing height={height} marginTopPx={20}>
                        {errorMessage.message}
                     </CenteredMsgShowing>
                  ) : (
                     <Spinner loading={isLoading} parentTopMarginPx={60}>
                        <SetChartHeight height={height}>
                           <Bar data={chart} options={chartOptions}></Bar>
                        </SetChartHeight>
                     </Spinner>
                  )}
               </ChartWrapper>
            </WidgetBodyContainer>
         </WidgetContainer>
      </PaddingContainer>
   );
}

IngestWidgetsRaw.propTypes = {
   height: PropTypes.number,
   start: PropTypes.instanceOf(moment),
   end: PropTypes.instanceOf(moment),
   streamName: PropTypes.string,
   title: PropTypes.string,
   urlSegment: PropTypes.string,
   resParseFn: PropTypes.func,
   explanation: PropTypes.array,
   protocol: PropTypes.string,
   drops: PropTypes.array,
   csvFilename: PropTypes.string,
};

IngestWidgetsRaw.defaultProps = {
   height: 300,
   start: moment(), // Current moment as default
   end: moment().add(1, 'day'), // One day ahead as default
};

export default IngestWidgetsRaw;
