import { Button } from 'reactstrap';
import styled from 'styled-components';

export const PickerTypePanel = styled.div`
   margin-right: 20px;
   cursor: pointer;
`;
PickerTypePanel.displayName = 'PickerTypePanel';

export const PickerContentPanel = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
`;
PickerContentPanel.displayName = 'PickerContentPanel';

export const StyledColumn = styled.div`
   display: flex;
   justify-content: start;
   flex-direction: column;
`;
StyledColumn.displayName = 'StyledColumn';

export const Content = styled.div`
   @media (min-width: 686px) {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
   }
`;
Content.displayName = 'Content';

export const QuickOptionBtn = styled(Button)`
   text-align: left;
   ${(props) => props.$color !== undefined && `color: ${props.$color};`}
`;
QuickOptionBtn.displayName = 'QuickOptionBtn';

export const PickerTypeButton = styled.a`
   text-align: center;
   padding-top: 5px;
   padding-bottom: 5px;
   ${(props) => props.$isActive === true && 'color: #FFF !important;'}
   ${(props) => props.$isActive === true && 'background-color: #20a8d8 !important;'}
   ${(props) => (props.$isActive === true ? 'border: 1px solid #20a8d8;' : 'border: 1px solid rgb(230, 230, 230);')}
   &:hover {
      border-color: ${(props) => (props.$isActive === true ? '#20a8d8' : 'rgb(200, 200, 200)')};
   }
`;
PickerTypeButton.displayName = 'PickerTypeButton';

export const Link = styled.span`
   color: rgb(32, 168, 216);
   cursor: pointer;
`;
Link.displayName = 'Link';

export const DisplayLineWrapper = styled.div`
   height: ${(props) => props.$heightPx}px;
   ${(props) => props.$alignText !== undefined && `text-align: ${props.$alignText};`}
`;
DisplayLineWrapper.displayName = 'DisplayLineWrapper';

export const ErrorMsg = styled.em`
   font-size: 90%;
   cursor: pointer;
`;
ErrorMsg.displayName = 'ErrorMsg';

export const PickerWrapper = styled.div`
   ${(props) => (props.marginRight !== undefined ? `margin-right: ${props.marginRight}px;` : '')}
   ${(props) => (props.marginLeft !== undefined ? `margin-left: ${props.marginLeft}px;` : '')}
   ${(props) => props.$widthPx !== undefined && `width: ${props.$widthPx}px;`}
`;
PickerWrapper.displayName = 'PickerWrapper';

export const DatePickerWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: flex-end;
   flex-wrap: wrap;
`;
DatePickerWrapper.displayName = 'DatePickerWrapper';
