import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import React, { useEffect, useMemo, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import roundTo from 'round-to';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, Spinner } from '../../../common';

import { CardTitle, Col, Row } from 'reactstrap';

import { PropTypes } from 'prop-types';

import { chartjsColors, pieChartOptionsWithOnlyTooltipLabel } from '../../../../constants/chartjs';
import { COUNTRY } from '../../../../constants/dataGroup';
import { HOME_VIEW, USAGE_AGG_TYPE } from '../../../../constants/general';

import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';
import { format_time_Number, roundUsage } from '../../../../util/NumberFormatter';
import { getFullCountryNames } from '../../../../util/UtilFunctions';

import {
   cancelTokenSelector,
   countriesSelector,
   endTimespanSelector,
   hashSelector,
   startTimespanSelector,
   streamNamesSelector,
   tagsSelector,
   titleTimespanSelector,
   usageSwitchSelector,
} from '../../../../redux/selectors/selectors';
import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';

import useDataFetch from '../../../../hooks/useDataFetch';
import TrafficHint from '../../../common/Hints/TrafficHint';
import ErrorDisplay from '../../ipFiltering/ErrorDisplay';

const chartOptions = pieChartOptionsWithOnlyTooltipLabel();

const USAGE_SETTINGS = {
   bytes: {
      title: 'Countries ( Traffic )',
      resPropName: 'bytes',
      roundFn: roundUsage,
   },
   playtime: {
      title: 'Countries ( Playtime )',
      resPropName: 'seconds',
      roundFn: format_time_Number,
   },
};

function defaultChartObject(labels = [], backgroundColor = [], borderColor = [], data = []) {
   return {
      labels,
      datasets: [
         {
            backgroundColor,
            borderColor,
            borderWidth: 1,
            hidden: false,
            data,
         },
      ],
   };
}

function getChartData(usageData, usageSwitch) {
   const { resPropName: unitPropName, roundFn } = USAGE_SETTINGS[usageSwitch];
   let maxLength = usageData.length > 8 ? 9 : usageData.length;

   const countryData = usageData.map((countryUsage) => {
      const playout = countryUsage.playout ? countryUsage.playout[unitPropName] : 0;
      const ingest = countryUsage.ingest ? countryUsage.ingest[unitPropName] : 0;
      const newCountryUsage = { ...countryUsage, both: { [unitPropName]: playout + ingest } };
      return newCountryUsage;
   });

   countryData.sort((a, b) => b.both[unitPropName] - a.both[unitPropName]);

   const total = countryData.reduce((acc, country) => (acc += country.both[unitPropName]), 0);

   const backgroundColor = [];
   const borderColor = [];
   const result = [];
   const labels = [];

   let totalPercentage = 0;

   for (let i = 0; i < maxLength; i++) {
      const usageValue = countryData[i].both[unitPropName];
      backgroundColor.push(hexToRgba(chartjsColors[i], 25));
      borderColor.push(chartjsColors[i]);
      result.push(usageValue);

      const percentage = roundTo((usageValue / total) * 100, 2);
      const absoluteValue = roundFn(usageValue);
      const countryName = getFullCountryNames(countryData[i].country);
      labels.push(`${countryName}: ${percentage}% (${absoluteValue})`);

      totalPercentage += roundTo((usageValue / total) * 10000, 0);
   }

   if (totalPercentage < 10000) {
      backgroundColor[maxLength] = hexToRgba(chartjsColors[9], 25);
      borderColor[maxLength] = chartjsColors[9];
      result[maxLength] = (10000 - totalPercentage) / 100;
      labels[maxLength] = `Others (${(10000 - totalPercentage) / 100}%)`;
   }

   return { backgroundColor, borderColor, result, labels };
}

function Countries({ height }) {
   const usageSwitch = useSelector(usageSwitchSelector);

   const [chart, setChart] = useState(defaultChartObject());

   const [noData, setNoData] = useState(false);

   const hash = useSelector(hashSelector);
   const streamNames = useSelector(streamNamesSelector);
   const tags = useSelector(tagsSelector);
   const countries = useSelector(countriesSelector);

   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);

   const timespanTitle = useSelector(titleTimespanSelector);

   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, [], streamNames, HOME_VIEW);
   const url = `/api/v2/usage/${COUNTRY}/${
      USAGE_AGG_TYPE.combined
   }/${usageSwitch}?from=${start.format()}&to=${end.format()}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            const usage = data.data;
            setNoData(usage.length === 0);
            const { backgroundColor, borderColor, result, labels } = getChartData(usage, usageSwitch);
            setChart(defaultChartObject(labels, backgroundColor, borderColor, result));
         }
      }
      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, dispatch, usageSwitch]);

   const errorOrNoData = errorMessage || noData;

   const { title } = USAGE_SETTINGS[usageSwitch];

   return (
      <WidgetContainer>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow>
                     <CsvExportFromApiButton
                        url={url}
                        header={header}
                        cancelToken={cancelToken}
                        filename={'Usage_Countries'}
                     />
                  </SimpleFlexRow>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <TrafficHint term={'playout or stream'} hasTimeAxis={true} sizePx={16} />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer>
            <Row>
               <Col>
                  <CardTitle className='mb-0'>{title}</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </Col>
            </Row>
            <ChartWrapper className='chart-wrapper' marginTopPx={25} height={height}>
               <Spinner loading={isLoading} parentTopMarginPx={71}>
                  {errorOrNoData ? (
                     <CenteredMsgShowing height={height}>
                        {errorMessage ? (
                           <ErrorDisplay error={errorMessage} msgColor={'rgb(80,80,80)'}></ErrorDisplay>
                        ) : (
                           'No data available'
                        )}
                     </CenteredMsgShowing>
                  ) : (
                     <SetChartHeight height={height}>
                        <Pie options={chartOptions} data={chart} height={height} />
                     </SetChartHeight>
                  )}
               </Spinner>
            </ChartWrapper>
         </WidgetBodyContainer>
      </WidgetContainer>
   );
}

Countries.propTypes = {
   height: PropTypes.number,
};

export default Countries;
