import roundTo from 'round-to';
import { getWorldRegionsAggObject } from '../WorldMapConstants';
import { getOutput } from './General';

const country_alpha_id_mapper = require('../../../../assets/worldmap/compact_country_alpha_id_mapper.json');

export function processAbrSwitchesAdapter(countries) {
   const adaptedCountries = countries.map((countryBucket) => {
      const { country, alpha2, upSwitches, downSwitches, totalSwitches, switches } = countryBucket;
      const { coordinates, worldRegion, code } = country_alpha_id_mapper[alpha2];
      const coor = [coordinates.longitude, coordinates.latitude];
      const countryResult = {
         alpha: alpha2,
         coor,
         id: code.numeric,
         name: country,
         worldRegion,
         up: upSwitches.count,
         down: downSwitches.count,
         total: totalSwitches.count,
         switchMetrics: [],
      };

      switches.forEach((switchBucket) => {
         const { type, count } = switchBucket;
         const switchResult = {
            name: type,
            switches: count,
            percentage: roundTo((count * 100) / totalSwitches.count, 1),
         };
         countryResult.switchMetrics.push(switchResult);
      });

      return countryResult;
   });

   const worldRegions = createWorldRegionsAndTopData(adaptedCountries);

   return getOutput(adaptedCountries, worldRegions);
}

function createWorldRegionsAndTopData(countries) {
   const worldRegions = getWorldRegionsAggObject({
      up: 0,
      down: 0,
      total: 0,
   });

   countries.forEach((country) => {
      const { worldRegion, up, down, total } = country;
      worldRegions[worldRegion].up += up;
      worldRegions[worldRegion].down += down;
      worldRegions[worldRegion].total += total;
      worldRegions[worldRegion].countries.push(country);
   });

   return worldRegions;
}
