import {
   INGEST_TIME,
   PUBLISH_AUDIO_BITRATE,
   PUBLISH_COUNT,
   PUBLISH_VIDEO_BITRATE,
   STATUS_ERROR_CODES,
   WEBRTC_STOP_REASONS,
   WEBCASTER_VERSIONS,
   WEBRTC_IDX,
} from '../ZoomConstants';
import { format_time_Number, getIntegerWithDecimalSeparator } from '../../../util/NumberFormatter';

// NOTE: order of props e.g. in columnNames must be aligned with order of props in API response object array!
export const WEBRTC_SETTINGS = {
   // scheme:
   //    title = [function] title of the metric
   //    exIndexName = [string] used ES index (break down option availability)
   //    path = [string] metric specific API route path
   //    pathAddon = [string] additional params for path
   //    categoryLabels = [Array<string>] name of the categories (upper button bar)
   //    categoryLabelTransformFn = [function] transform category label (term)
   //    dataFieldNames = [Array<string>] props of the response
   //    columnNames = [object] key is metric prop of response, value is displayed column name in table
   //    formatters = [object] or [function]
   //             [object] key is metric prop of response, value is used formatter
   //             [function] one format fn is used for all columns/metrics
   [PUBLISH_COUNT]: {
      title: (term) => `Publishes for ${term}`,
      exIndexName: WEBRTC_IDX,
      path: 'webrtc/count',
      urlAddon: (term, group) => `&zoomWidget=${group}&zoomMetric=${term}`,
      categoryLabels: ['Publishes'],
      categoryLabelTransformFn: (label, term) => `${label} for '${term}'`,
      dataFieldNames: ['publishes'],
      columnNames: { publishes: 'Count' },
      formatters: getIntegerWithDecimalSeparator,
   },
   [STATUS_ERROR_CODES]: {
      title: (term) => `Status/Error code '${term}'`,
      exIndexName: WEBRTC_IDX,
      path: 'webrtc/errorcodes',
      urlAddon: (term) => `&zoomMetric=${term}`,
      categoryLabels: ['Status/Error code'],
      categoryLabelTransformFn: (label, term) => `${label} '${term}'`,
      dataFieldNames: ['occurrence'],
      columnNames: { occurrence: 'Count' },
      formatters: getIntegerWithDecimalSeparator,
   },
   [WEBRTC_STOP_REASONS]: {
      title: (term) => `'${term}' as the stop reason`,
      exIndexName: WEBRTC_IDX,
      path: 'webrtc/stopReasons',
      urlAddon: (term) => `&zoomMetric=${term}`,
      categoryLabels: ['Stop reason'],
      categoryLabelTransformFn: (label, term) => `${label} '${term}'`,
      dataFieldNames: ['occurrence'],
      columnNames: { occurrence: 'Count' },
      formatters: getIntegerWithDecimalSeparator,
   },
   [PUBLISH_AUDIO_BITRATE]: {
      title: () => 'Audio bitrate',
      exIndexName: WEBRTC_IDX,
      path: 'webrtc/bitrate/audio',
      categoryLabels: ['Audio bitrate'],
      dataFieldNames: ['medianBitrate', 'averageBitrate', 'totalBroadcastTime'],
      columnNames: { medianBitrate: 'Median', averageBitrate: 'Average', totalBroadcastTime: 'Total broadcast time' },
      formatters: {
         medianBitrate: (cell) => `${getIntegerWithDecimalSeparator(cell)} kBit/s`,
         averageBitrate: (cell) => `${getIntegerWithDecimalSeparator(cell)} kBit/s`,
         totalBroadcastTime: format_time_Number,
      },
   },
   [PUBLISH_VIDEO_BITRATE]: {
      title: () => 'Video bitrate',
      exIndexName: WEBRTC_IDX,
      path: 'webrtc/bitrate/video',
      categoryLabels: ['Video bitrate'],
      dataFieldNames: ['medianBitrate', 'averageBitrate', 'totalBroadcastTime'],
      columnNames: { medianBitrate: 'Median', averageBitrate: 'Average', totalBroadcastTime: 'Total broadcast time' },
      formatters: {
         medianBitrate: (cell) => `${getIntegerWithDecimalSeparator(cell)} kBit/s`,
         averageBitrate: (cell) => `${getIntegerWithDecimalSeparator(cell)} kBit/s`,
         totalBroadcastTime: format_time_Number,
      },
   },
   [INGEST_TIME]: {
      title: () => 'Broadcasting time',
      exIndexName: WEBRTC_IDX,
      path: 'webrtc/ingesttime',
      categoryLabels: ['Video bitrate'],
      dataFieldNames: ['medianBitrate', 'averageBitrate', 'totalBroadcastTime'],
      columnNames: {
         medianBroadcastTime: 'Median',
         averageBroadcastTime: 'Average',
         totalBroadcastTime: 'Total broadcast time',
      },
      formatters: format_time_Number,
   },
   [WEBCASTER_VERSIONS]: {
      title: (term) => `Webcaster version '${term}'`,
      exIndexName: WEBRTC_IDX,
      path: 'webrtc/usedVersions',
      urlAddon: (term) => `&zoomMetric=${term}`,
      categoryLabels: ['Webcaster version'],
      categoryLabelTransformFn: (label, term) => `${label} '${term}'`,
      dataFieldNames: ['publishes'],
      columnNames: { publishes: 'Count' },
      formatters: getIntegerWithDecimalSeparator,
   },
};
