import React from 'react';
import { PropTypes } from 'prop-types';

import { getHeatScale } from '../LegendScales';
import { HeadLine, Scale, ScaleField, ColoredValue } from '../LegendStyles';

export function HeatScale({
   headline,
   numberFormat,
   valueManipulation,
   scaleFieldWidth,
   maxHeatValue,
   total,
   scaleFieldsVisible,
}) {
   return (
      <>
         {scaleFieldsVisible ? (
            <>
               <HeadLine>{headline}</HeadLine>
               <Scale>
                  {getHeatScale({ numberFormat, valueManipulation, maxHeatValue }).map((field, idx) => (
                     <ScaleField key={`heatScaleField-${idx}`} bgColor={field.color} width={scaleFieldWidth}>
                        {field.value}
                     </ScaleField>
                  ))}
               </Scale>
               <HeadLine>
                  {'Total: '}
                  <ColoredValue color={'rgba(0, 0, 0, 1)'}>{total[1]}</ColoredValue>
               </HeadLine>
            </>
         ) : (
            <>
               <HeadLine>{total[0]}</HeadLine>
               <HeadLine>
                  <ColoredValue color={'rgba(0, 0, 0, 1)'}>{total[1]}</ColoredValue>
               </HeadLine>
            </>
         )}
      </>
   );
}

HeatScale.propTypes = {
   headline: PropTypes.string,
   numberFormat: PropTypes.func,
   valueManipulation: PropTypes.func,
   scaleFieldWidth: PropTypes.number,
   maxHeatValue: PropTypes.number,
   total: PropTypes.array,
   scaleFieldsVisible: PropTypes.bool,
};
