export const requestSettings = [
   {
      desc: 'Organizations',
      shortDesc: 'orgas',
      url: '/api/v2/organizations/',
   },
   {
      desc: 'H5live player metric ratios',
      shortDesc: 'ratios',
      url: '/api/v2/misc/clientServerRatio/organizations',
      start: (now) => {
         const startOfCurrentMonth = now.clone().startOf('month');
         const endOfFirstDay = startOfCurrentMonth.clone().endOf('day');
         const isFirstDayOfMonth = endOfFirstDay.diff(now, 'seconds') >= 0;
         return isFirstDayOfMonth ? startOfCurrentMonth.clone().subtract(1, 'day') : startOfCurrentMonth;
      },
      end: (now) => {
         const startOfCurrentMonth = now.clone().startOf('month');
         const endOfFirstDay = startOfCurrentMonth.clone().endOf('day');
         const isFirstDayOfMonth = endOfFirstDay.diff(now, 'seconds') >= 0;
         return isFirstDayOfMonth ? startOfCurrentMonth : now.clone().startOf('day');
      },
      isShown: true,
   },
   {
      desc: 'Start of this month to now',
      shortDesc: 'cmValues',
      url: '/api/v2/usage/organizations',
      start: (now) => now.clone().startOf('month'),
      end: (now) => now.clone().startOf('hours'),
      refresh: '1h',
      total: true,
      isShown: true,
      tableHeaderText: 'This month [GB]',
   },
   {
      desc: 'Start of last month to now one month ago',
      shortDesc: 'lmotValues',
      url: '/api/v2/usage/organizations',
      start: (now) => now.clone().startOf('month').subtract(1, 'month'),
      end: (now) => now.clone().startOf('hours').subtract(1, 'month'),
      refresh: '1h',
   },
   {
      desc: 'Last month',
      shortDesc: 'lmValues',
      url: '/api/v2/usage/organizations',
      start: (now) => now.clone().startOf('month').subtract(1, 'month'),
      end: (now) => now.clone().startOf('month'),
      total: true,
      isShown: true,
      tableHeaderText: 'Last month [GB]',
   },
   {
      desc: 'Last week',
      shortDesc: 'lwValues',
      url: '/api/v2/usage/organizations',
      start: (now) => now.clone().startOf('isoWeek').subtract(1, 'week'),
      end: (now) => now.clone().startOf('isoWeek'),
      total: true,
      isShown: true,
      tableHeaderText: 'Last week [GB]',
   },
   {
      desc: 'Yesterday',
      shortDesc: 'ydValues',
      url: '/api/v2/usage/organizations',
      start: (now) => now.clone().startOf('day').subtract(1, 'day'),
      end: (now) => now.clone().startOf('day'),
      total: true,
      isShown: true,
      tableHeaderText: 'Yesterday [GB]',
   },
   {
      desc: 'Last hour',
      shortDesc: 'lhValues',
      url: '/api/v2/usage/organizations',
      start: (now) => now.clone().startOf('hour').subtract(1, 'hour'),
      end: (now) => now.clone().startOf('hour'),
      total: true,
      isShown: true,
      tableHeaderText: 'Last hour [GB]',
   },
   {
      desc: 'Start of 2 months ago to start of last month',
      shortDesc: 'twoMAValues',
      url: '/api/v2/usage/history/organizations',
      start: (now) => now.clone().startOf('month').subtract(2, 'months'),
      end: (now) => now.clone().startOf('month').subtract(1, 'month'),
      total: true,
   },
   {
      desc: 'Start of 3 months ago to start of 2 months ago',
      shortDesc: 'threeMAValues',
      url: '/api/v2/usage/history/organizations',
      start: (now) => now.clone().startOf('month').subtract(3, 'months'),
      end: (now) => now.clone().startOf('month').subtract(2, 'month'),
      total: true,
   },
   {
      desc: 'Start of 4 months ago to start of 3 months ago',
      shortDesc: 'fourMAValues',
      url: '/api/v2/usage/history/organizations',
      start: (now) => now.clone().startOf('month').subtract(4, 'months'),
      end: (now) => now.clone().startOf('month').subtract(3, 'month'),
      total: true,
   },
   {
      desc: 'Start of 5 months ago to start of 4 months ago',
      shortDesc: 'fiveMAValues',
      url: '/api/v2/usage/history/organizations',
      start: (now) => now.clone().startOf('month').subtract(5, 'months'),
      end: (now) => now.clone().startOf('month').subtract(4, 'month'),
   },
];
