import {
   WORLDMAP_METRIC,
   BUFFER_RATIO_VALUE_GREEN,
   BUFFER_RATIO_VALUE_YELLOW,
   LATENCY_VALUE_GREEN,
   LATENCY_VALUE_YELLOW,
   TOTAL,
   RATIO,
   LATENCY,
   PLAYTIME,
   PLAYOUT,
   INGEST,
   SCORE,
} from '../WorldMapConstants';

import { getHeatMapColors, getTrafficLightColors, getGreenRedColors } from '../Common/tools/ValueColorizer';

import {
   roundUsage,
   formatCountNumber,
   format_0dot00_Number,
   format_time_Number,
   getIntegerWithDecimalSeparator,
} from '../../../../util/NumberFormatter';

export const INFO_BOX_SETTINGS = {
   [WORLDMAP_METRIC.PI]: [
      {
         colorFn: getHeatMapColors,
         valueFormatFn: formatCountNumber,
         indent: 'md',
      },
   ],
   [WORLDMAP_METRIC.BUFFER_RATIO]: [
      {
         propName: RATIO,
         colorFn: getTrafficLightColors,
         green: BUFFER_RATIO_VALUE_GREEN,
         yellow: BUFFER_RATIO_VALUE_YELLOW,
         valueFormatFn: (value) => `${format_0dot00_Number(value)} %`,
         indent: 'xl',
         description: 'Buffering Play Ratio',
      },
      {
         propName: TOTAL,
         colorFn: getTrafficLightColors,
         valueFormatFn: format_time_Number,
         indent: 'xl',
         description: 'Total Played Time',
         fontColor: 'rgba(255, 255, 255, 0.95)',
      },
   ],
   [WORLDMAP_METRIC.LATENCY]: [
      {
         propName: LATENCY,
         colorFn: getTrafficLightColors,
         green: LATENCY_VALUE_GREEN,
         yellow: LATENCY_VALUE_YELLOW,
         valueFormatFn: (value) => `${format_0dot00_Number(value)} s`,
         indent: 'lg',
         description: 'Latency',
      },
      {
         propName: TOTAL,
         colorFn: getTrafficLightColors,
         valueFormatFn: (value) => getIntegerWithDecimalSeparator(value),
         indent: 'lg',
         description: 'Unique Plays',
         fontColor: 'rgba(255, 255, 255, 0.95)',
      },
   ],
   [WORLDMAP_METRIC.PLAYTIME]: [
      {
         propName: PLAYTIME,
         colorFn: getHeatMapColors,
         valueFormatFn: format_time_Number,
         indent: 'xl',
         description: 'Average playtime',
      },
      {
         propName: TOTAL,
         colorFn: getHeatMapColors,
         valueFormatFn: (value) => getIntegerWithDecimalSeparator(value),
         indent: 'xl',
         description: 'Unique Plays',
         fontColor: 'rgba(255, 255, 255, 0.95)',
      },
   ],
   [WORLDMAP_METRIC.USAGE]: [
      {
         propName: INGEST,
         colorFn: getHeatMapColors,
         valueFormatFn: (value) => roundUsage(value),
         indent: 'md',
         description: 'Ingest',
      },
      {
         propName: PLAYOUT,
         colorFn: getHeatMapColors,
         valueFormatFn: (value) => roundUsage(value),
         indent: 'md',
         description: 'Playout',
      },
      {
         propName: TOTAL,
         colorFn: getHeatMapColors,
         valueFormatFn: (value) => roundUsage(value),
         indent: 'md',
         description: 'Total',
      },
   ],
};

export const COUNTRY_NAME_LINE_STYLES = {
   [WORLDMAP_METRIC.PI]: {},
   [WORLDMAP_METRIC.BUFFER_RATIO]: {},
   [WORLDMAP_METRIC.LATENCY]: {},
   [WORLDMAP_METRIC.PLAYTIME]: {},
   [WORLDMAP_METRIC.USAGE]: {},
   [WORLDMAP_METRIC.ABR_PLAYTIME]: {},
   [WORLDMAP_METRIC.ABR_VIEWER]: { marginBottom: '10px' },
   [WORLDMAP_METRIC.ABR_SWITCHES]: { marginBottom: '10px' },
   [WORLDMAP_METRIC.QOE]: { textAlign: 'center' },
};

export const CIRCLE_COLOR_SETTINGS = {
   [WORLDMAP_METRIC.PI]: {
      colorProp: TOTAL,
      colorFn: getHeatMapColors,
   },
   [WORLDMAP_METRIC.BUFFER_RATIO]: {
      colorProp: WORLDMAP_METRIC.BUFFER_RATIO,
      colorFn: getTrafficLightColors,
      green: BUFFER_RATIO_VALUE_GREEN,
      yellow: BUFFER_RATIO_VALUE_YELLOW,
   },
   [WORLDMAP_METRIC.LATENCY]: {
      colorProp: WORLDMAP_METRIC.LATENCY,
      colorFn: getTrafficLightColors,
      green: LATENCY_VALUE_GREEN,
      yellow: LATENCY_VALUE_YELLOW,
   },
   [WORLDMAP_METRIC.PLAYTIME]: {
      colorProp: WORLDMAP_METRIC.PLAYTIME,
      colorFn: getHeatMapColors,
   },
   [WORLDMAP_METRIC.USAGE]: {
      colorProp: TOTAL,
      colorFn: getHeatMapColors,
   },
   [WORLDMAP_METRIC.ABR_PLAYTIME]: {
      colorProp: TOTAL,
      colorFn: getHeatMapColors,
   },
   [WORLDMAP_METRIC.ABR_VIEWER]: {
      colorProp: TOTAL,
      colorFn: getHeatMapColors,
   },
   [WORLDMAP_METRIC.ABR_SWITCHES]: {
      colorProp: TOTAL,
      colorFn: getHeatMapColors,
   },
   [WORLDMAP_METRIC.QOE]: {
      colorProp: SCORE,
      colorFn: getGreenRedColors,
      min: 0,
      max: 100,
   },
};
