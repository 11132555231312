import React from 'react';
import useDataFetch from '../../../../../../hooks/useDataFetch';
import { PropTypes } from 'prop-types';
import storePropTypes from '../../../../../../redux/store/propTypes';

function SingleDataFetcher({ url, httpHeader, cancelToken, onResult, onRequestCompleted, method, body }) {
   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, {
      header: httpHeader,
      cancelToken,
      onRequestCompleted,
      method,
      body,
   });
   onResult({ successful, isLoading, errorMessage, data, httpStatus });
   return <></>;
}

SingleDataFetcher.propTypes = {
   url: PropTypes.string,
   httpHeader: PropTypes.object,
   cancelToken: storePropTypes.cancelToken,
   onResult: PropTypes.func,
   onRequestCompleted: PropTypes.func,
   method: PropTypes.string,
   body: PropTypes.object,
};

export default SingleDataFetcher;
