import { push } from 'connected-react-router';
import { syncURL } from '../../urlSync/urlSynchronizer';

export default (store) => (next) => (action) => {
   // console.log('pushToURL middleware :>> ', action);

   const { general: currentState, router } = store.getState();

   if (action.pushToUrl) {
      // console.log('action.pushToUrl :>> ', action.pushToUrl);
      const changeProp = action.pushToUrl || {};
      const newURL = syncURL(router.location.pathname, changeProp, currentState);
      // console.log('newURL :>> ', newURL);
      store.dispatch(push(newURL));
   }

   return next(action);
};
