import React from 'react';
import { PropTypes } from 'prop-types';

import { ABR_PLAYTIME_OPTIONS } from '../../WorldMapConstants';
import { getHeatScale } from '../LegendScales';
import {
   HeadLine,
   ButtonGroup,
   ButtonLine,
   SelectButton,
   Scale,
   ScaleField,
   ColoredValue,
   BIG_BUTTON_WIDTH_IN_PX,
} from '../LegendStyles';

export function AbrPlaytime({
   headline,
   numberFormat,
   valueManipulation,
   scaleFieldWidth,
   subSwitch,
   onSwitch,
   maxHeatValue,
   total,
   scaleFieldsVisible,
}) {
   return (
      <>
         <HeadLine>Choose between</HeadLine>
         <ButtonGroup widthInPx={BIG_BUTTON_WIDTH_IN_PX}>
            <ButtonLine>
               <SelectButton name={ABR_PLAYTIME_OPTIONS.DESKTOP} switchValue={subSwitch} onClick={onSwitch} size='sm'>
                  Desktop
               </SelectButton>
               <SelectButton name={ABR_PLAYTIME_OPTIONS.MOBILE} switchValue={subSwitch} onClick={onSwitch} size='sm'>
                  Mobile
               </SelectButton>
            </ButtonLine>
            <ButtonLine>
               <SelectButton name={ABR_PLAYTIME_OPTIONS.CHART} switchValue={subSwitch} onClick={onSwitch} size='lg'>
                  Comparison chart
               </SelectButton>
            </ButtonLine>
            <ButtonLine>
               <SelectButton name={ABR_PLAYTIME_OPTIONS.TABLE} switchValue={subSwitch} onClick={onSwitch} size='lg'>
                  Comparison table
               </SelectButton>
            </ButtonLine>
         </ButtonGroup>
         {scaleFieldsVisible ? (
            <>
               <HeadLine>{headline}</HeadLine>
               <Scale>
                  {getHeatScale({ numberFormat, valueManipulation, maxHeatValue }).map((field, idx) => (
                     <ScaleField key={`heatScaleField-${idx}`} bgColor={field.color} width={scaleFieldWidth}>
                        {field.value}
                     </ScaleField>
                  ))}
               </Scale>
               <HeadLine>
                  {'Total: '}
                  <ColoredValue color={'rgba(0, 0, 0, 1)'}>{total[1]}</ColoredValue>
               </HeadLine>
            </>
         ) : (
            <>
               <HeadLine>{total[0]}</HeadLine>
               <HeadLine>
                  <ColoredValue color={'rgba(0, 0, 0, 1)'}>{total[1]}</ColoredValue>
               </HeadLine>
            </>
         )}
      </>
   );
}

AbrPlaytime.propTypes = {
   headline: PropTypes.string,
   numberFormat: PropTypes.func,
   valueManipulation: PropTypes.func,
   scaleFieldWidth: PropTypes.number,
   subSwitch: PropTypes.string,
   onSwitch: PropTypes.func,
   maxHeatValue: PropTypes.number,
   total: PropTypes.array,
   scaleFieldsVisible: PropTypes.bool,
};
