import React, { useContext } from 'react';
import { getPath } from './GeoProjection';
import { useSelector, useDispatch } from 'react-redux';

import { PropTypes } from 'prop-types';
import { worldmapViewSelector, worldmapRegionZoomSelector } from '../../../../redux/selectors/selectors';
import { VIEW_MODE, WorldRegions, WORLD_REGIONS_SETTINGS, WorldZoomProjection } from './../WorldMapConstants';
import { HoveredRegionContext } from '../WorldContainer';
import { setWorldMapRegionZoom } from '../../../../redux/actions/general';

function Country({ d, idx, width }) {
   const [hoveredWorldRegion, setHoveredWorldRegion] = useContext(HoveredRegionContext);
   const viewSwitch = useSelector(worldmapViewSelector);
   const zoomedRegion = useSelector(worldmapRegionZoomSelector);

   const dispatch = useDispatch();

   const zoomProjection =
      zoomedRegion !== '' ? WORLD_REGIONS_SETTINGS[zoomedRegion].zoomProjection : WorldZoomProjection;

   const backgroundColorFn = WORLD_REGIONS_SETTINGS[d.worldRegion].backgroundColor;

   const staticRegionColor = viewSwitch === VIEW_MODE.REGION ? backgroundColorFn(0.4) : 'rgba(38,50,56,0.3)';
   const hoverModeColor = hoveredWorldRegion === d.worldRegion ? backgroundColorFn(0.6) : staticRegionColor;
   const dynamicRegionColor = zoomedRegion === d.worldRegion ? backgroundColorFn(0.6) : hoverModeColor;

   const isMouseHoveringActive = viewSwitch === VIEW_MODE.REGION && d.worldRegion !== WorldRegions.UNKNOWN;

   const onHovering = () => {
      if (zoomedRegion !== '') {
         if (zoomedRegion === d.worldRegion) {
            setHoveredWorldRegion(d.worldRegion);
         } else {
            setHoveredWorldRegion(VIEW_MODE.ZOOM_OUT);
         }
      } else {
         if (hoveredWorldRegion !== d.worldRegion) {
            setHoveredWorldRegion(d.worldRegion);
         }
      }
   };
   const onHoveringLeave = () => {
      setHoveredWorldRegion('');
   };

   const onHoverModeClick = (event) => {
      dispatch(setWorldMapRegionZoom(d.worldRegion, { worldmapRegionZoom: d.worldRegion }));
   };
   const hoverModeClick =
      viewSwitch === VIEW_MODE.REGION && d.worldRegion !== WorldRegions.UNKNOWN ? onHoverModeClick : () => {};

   const onZoomModeOutsideClick = (sameRegion) => {
      if (!sameRegion) {
         dispatch(setWorldMapRegionZoom('', { worldmapRegionZoom: '' }));
         setHoveredWorldRegion('');
      }
   };
   const onCountryClick =
      zoomedRegion !== '' ? () => onZoomModeOutsideClick(zoomedRegion === d.worldRegion) : hoverModeClick;

   return (
      <>
         <path
            key={`path-${idx}`}
            d={getPath(d, width, zoomProjection)}
            className={`country ${d.name}`}
            fill={dynamicRegionColor}
            stroke={'#FFFFFF'}
            strokeWidth={0.5}
            countrycode={d.id}
            onMouseOver={isMouseHoveringActive ? onHovering : () => {}}
            onMouseLeave={isMouseHoveringActive ? onHoveringLeave : () => {}}
            onClick={onCountryClick}
         />
      </>
   );
}

Country.propTypes = {
   width: PropTypes.number,
   idx: PropTypes.number,
   d: PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      geometry: PropTypes.object,
      worldRegion: PropTypes.string,
   }),
};

export default Country;
