import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AccessLevel } from '../../../../constants/AccessLevels';

import { Dashboard } from '../../../common';
import Widget from '../../Widget/Widget';

import { Errors, Ingests, IngestTimeAvgMedian, BitrateAvgMedian, StopReason } from '../../../widgets/webrtc';

import { PlayerVersion } from '../../../widgets/common';

import { Col, Row } from 'reactstrap';

import storePropTypes from './../../../../redux/store/propTypes';

import axios from 'axios';
import { setNewCancelToken, setViewTimespan } from './../../../../redux/actions/general';

import { PropTypes } from 'prop-types';
import { WEBRTC_VIEW } from '../../../../constants/general';
import { BROWSER, OS } from '../../../../constants/dataGroup';

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setNewCancelToken, setViewTimespan };

class WebRTC extends Component {
   componentWillUnmount() {
      // cancel token, added to old requests, will be activated --> old requests will be cancelled
      this.props.cancelToken.cancel('Operation canceled by the user.');
      this.props.setViewTimespan(WEBRTC_VIEW);
      this.props.setNewCancelToken(axios.CancelToken.source());
   }

   render() {
      return (
         <Dashboard location={this.props.location} history={this.props.history} view={WEBRTC_VIEW}>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget
                     type={Ingests}
                     requiredLevel={AccessLevel.FULL}
                     widgetAttributes={{ data: { grouping: OS }, height: 300 }}
                  />
               </Col>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget
                     type={Ingests}
                     requiredLevel={AccessLevel.FULL}
                     widgetAttributes={{ data: { grouping: BROWSER }, height: 300 }}
                  />
               </Col>
            </Row>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget type={Errors} requiredLevel={AccessLevel.PREMIUM} widgetAttributes={{ height: 300 }} />
               </Col>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget type={StopReason} requiredLevel={AccessLevel.PREMIUM} widgetAttributes={{ height: 300 }} />
               </Col>
            </Row>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget
                     type={BitrateAvgMedian}
                     requiredLevel={AccessLevel.PREMIUM}
                     widgetAttributes={{ data: { route: 'video' }, height: 300 }}
                  />
               </Col>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget
                     type={BitrateAvgMedian}
                     requiredLevel={AccessLevel.PREMIUM}
                     widgetAttributes={{ data: { route: 'audio' }, height: 300 }}
                  />
               </Col>
            </Row>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget
                     type={IngestTimeAvgMedian}
                     requiredLevel={AccessLevel.PREMIUM}
                     widgetAttributes={{ height: 300 }}
                  />
               </Col>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget
                     type={PlayerVersion}
                     requiredLevel={AccessLevel.PREMIUM}
                     widgetAttributes={{ usedApp: WEBRTC_VIEW, height: 300 }}
                  />
               </Col>
            </Row>
         </Dashboard>
      );
   }
}

WebRTC.propTypes = {
   cancelToken: storePropTypes.cancelToken,
   setNewCancelToken: PropTypes.func,
   setViewTimespan: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebRTC);
