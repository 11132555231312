import React, { useState } from 'react';

import { CardBody, Col, Row } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { setWorldMapMetric } from '../../../redux/actions/general';
import { worldmapMetricSelector } from '../../../redux/selectors/selectors';
import {
   HeightSetContainer,
   MinHeightContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../styledComponents/styledComponents';
import MetricsContainer from './Metrics/MetricsContainer';
import MenuControlBar from './OptionControls/MenuControlBar';

export const HoveredRegionContext = React.createContext([{}, () => {}]);
export const QueryParamContext = React.createContext([{}, () => {}]);
export const ClickOutsideContext = React.createContext(['', () => {}]);
export const ClearCountryContext = React.createContext(['', () => {}]);

export const ClickOutsideStates = {
   READY: 'ready',
   ACTIVATING: 'activating',
   ACTIVE: 'active',
   CLOSE: 'close',
};

function WorldContainer() {
   const selectedMetric = useSelector(worldmapMetricSelector);
   const dispatch = useDispatch();
   const [hoveredWorldRegion, setHoveredWorldRegion] = useState('');
   const [queryParams, setQueryParams] = useState({ url: '', header: {} });
   const [clickOutsideState, setClickOutsideState] = useState(ClickOutsideStates.READY);
   const [clearCountry, setClearCountry] = useState('');

   const clickOutsideHandler = () => {
      if (clickOutsideState === ClickOutsideStates.ACTIVATING) {
         setClickOutsideState(ClickOutsideStates.ACTIVE);
         if (clearCountry) {
            setClearCountry('');
         }
         return;
      }
      if (ClickOutsideStates.ACTIVE) {
         setClickOutsideState(ClickOutsideStates.CLOSE);
         return;
      }
   };

   return (
      <HoveredRegionContext.Provider value={[hoveredWorldRegion, setHoveredWorldRegion]}>
         <WidgetContainer onMouseDown={clickOutsideHandler}>
            <WidgetHeaderContainer>
               <HeightSetContainer $heightInPx={23}>
                  <MenuControlBar
                     selectedMetric={selectedMetric}
                     queryParams={queryParams}
                     onMetricSelection={(param, pushToUrl) => dispatch(setWorldMapMetric(param, pushToUrl))}
                  />
               </HeightSetContainer>
            </WidgetHeaderContainer>
            <CardBody>
               <Row>
                  <Col>
                     <MinHeightContainer $minHeightInPx={360}>
                        <QueryParamContext.Provider value={setQueryParams}>
                           <ClickOutsideContext.Provider value={[clickOutsideState, setClickOutsideState]}>
                              <ClearCountryContext.Provider value={[clearCountry, setClearCountry]}>
                                 <MetricsContainer selectedMetric={selectedMetric.value} />
                              </ClearCountryContext.Provider>
                           </ClickOutsideContext.Provider>
                        </QueryParamContext.Provider>
                     </MinHeightContainer>
                  </Col>
               </Row>
            </CardBody>
         </WidgetContainer>
      </HoveredRegionContext.Provider>
   );
}

export default WorldContainer;
