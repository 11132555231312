export function timespanChanged(oldState, newState) {
   return !newState.timespan.gte.isSame(oldState.timespan.gte) || !newState.timespan.lt.isSame(oldState.timespan.lt);
}

export function orgaHashChanged(oldState, newState) {
   return newState.filter.hash !== oldState.filter.hash;
}
export function filterDataNotYetFetched(state, propName, isBeingFetched) {
   return state[propName].length === 1 && state[propName][0].value === 'loading ...' && !isBeingFetched;
}

export function timespanOrIntervalChanged(oldState, newState) {
   return (
      !newState.timespan.gte.isSame(oldState.timespan.gte) ||
      !newState.timespan.lt.isSame(oldState.timespan.lt) ||
      newState.timespan.interval !== oldState.timespan.interval
   );
}

export function filtersChanged(oldState, newState) {
   return (
      oldState.filter.hash !== newState.filter.hash ||
      filterChanged(oldState.filter.countries, newState.filter.countries) ||
      filterChanged(oldState.filter.tags, newState.filter.tags) ||
      filterChanged(oldState.filter.events, newState.filter.events) ||
      filterChanged(oldState.filter.streamNames, newState.filter.streamNames)
   );
}

export function filterChanged(oldFilter, newFilter) {
   const newFilterSortedStr = sortAndReduceFilterArrayToString(newFilter);
   const oldFilterSortedStr = sortAndReduceFilterArrayToString(oldFilter);
   return newFilterSortedStr !== oldFilterSortedStr;
}

export function sortAndReduceFilterArrayToString(filterArray, separator = '') {
   return sortFilterArray(filterArray).join(separator);
}

export function sortFilterArray(filterArray) {
   return filterArray.sort((a, b) => a.value.localeCompare(b.value)).map((elem) => elem.value);
}
