import {
   SET_COUNTRIES,
   SET_TAGS,
   SET_TIMESPAN,
   SET_USERINFO,
   SET_FILTER,
   SET_ALARM,
   SET_INITIALSTATE,
   SET_STATE_BY_URL,
   SWITCH_USAGE,
   SET_EVENTS,
   SET_NEW_CANCELTOKEN,
   SET_WORLDMAP_METRIC,
   SET_WORLDMAP_SUBSWITCH,
   SET_WORLDMAP_OPTIONS,
   SET_VIEW_TIMESPAN,
   SET_WORLDMAP_VIEW,
   SET_WORLDMAP_REGION_ZOOM,
   SET_TROUBLESHOOTING_TIMESPAN,
   SET_TROUBLESHOOTING_MODE,
   SET_TROUBLESHOOTING_INGEST_INPUT,
   SET_TROUBLESHOOTING_SELECTED_PUBLISH,
   SET_TROUBLESHOOTING_PUBLISH_FINE_TIMESPAN,
   RESET_TROUBLESHOOTING,
   SET_TROUBLESHOOTING_PLAYOUT_INPUT,
   SET_TROUBLESHOOTING_PLAYOUT_STREAM,
   SET_TROUBLESHOOTING_SELECTED_PLAYOUT,
   SET_TROUBLESHOOTING_PLAYOUT_FINE_TIMESPAN,
   SYNC_TROUBLESHOOTING_INGEST,
   COMPLETE_SYNC_TROUBLESHOOTING_INGEST,
   SET_TROUBLESHOOTING_INGEST_STREAM,
   SET_IP_FILTERING_FILTER,
   SET_IP_FILTERING_TIMESPAN,
   SET_DEMO_MODE,
} from '../actions/action-types';

import moment from 'moment';
import axios from 'axios';
import {
   HOME_VIEW,
   TRAFFIC,
   HISTORY_VIEW,
   E2E,
   DEFAULT_SHORTCUT_ID,
   DEFAULT_LONGTERM_SHORTCUT_ID,
   DEFAULT_LAST_MONTH_SHORTCUT_ID,
   MAX_1_MONTH_TIME_SPAN_VIEWS,
} from './../../constants/general';

import { syncStore } from '../../urlSync/urlSynchronizer';
import { timespanChanged, orgaHashChanged, timespanOrIntervalChanged, filtersChanged } from './../selectors/comparing';
import { DROP_DOWN_METRICS, METRIC_COMPONENTS } from '../../components/widgets/world/Metrics/MetricsConstants';
import { WORLDMAP_METRIC, ALL_CDNS, VIEW_MODE } from '../../components/widgets/world/WorldMapConstants';
import { getIntervalEnd, getIntervalStart } from '../../components/widgets/troubleshooting/general/DataProcessing';
import { getShortcutTimespan } from '../../components/common/DateTimePicker/TimespanSetter';
import { TROUBLE_INTERVALS } from '../../components/widgets/troubleshooting/constants';
import { toast } from 'react-toastify';

const initialState = {
   alarm: {
      type: 'info',
      message: '',
   },
   filter: {
      hash: '',
      name: '',
      countries: [],
      tags: [],
      events: [],
      streamNames: [],
   },
   countries: [{ value: 'loading ...', label: 'loading ...' }],
   tags: [{ value: 'loading ...', label: 'loading ...' }],
   usageSwitch: TRAFFIC,
   events: [{ value: 'loading ...', label: 'loading ...' }],
   timespan: getShortcutTimespan(DEFAULT_SHORTCUT_ID, false),
   settings: {
      weekType: 'isoWeek',
   },
   userInfo: {
      token: undefined,
      expires: new Date(),
      user: {
         email: '',
      },
      organization: {
         name: '',
      },
   },
   currentView: HOME_VIEW,
   cancelToken: axios.CancelToken.source(),
   worldmapMetric: DROP_DOWN_METRICS[0],
   worldmapSubSwitch: {
      [WORLDMAP_METRIC.PI]: METRIC_COMPONENTS[WORLDMAP_METRIC.PI].initial.initialSubSwitch,
      [WORLDMAP_METRIC.ABR_PLAYTIME]: METRIC_COMPONENTS[WORLDMAP_METRIC.ABR_PLAYTIME].initial.initialSubSwitch,
   },
   worldmapOptions: {
      cdn: ALL_CDNS,
   },
   worldmapView: VIEW_MODE.COUNTRY,
   worldmapRegionZoom: '',
   troubleTimespan: {
      start: moment.utc().startOf('hour').add(1, 'hour').subtract(1, 'day'),
      end: moment.utc().startOf('hour').add(1, 'hour'),
      troubleInterval: TROUBLE_INTERVALS[0],
   },
   troubleMode: E2E,
   troubleIngestInput: { ingestInput: '' },
   troubleIngestStream: { ingestStream: '' },
   troubleSelectedPublish: {
      publishStart: '',
      publishEnd: '',
      publishFineStart: '',
      publishFineEnd: '',
   },
   troublePlayoutInput: { userId: '' },
   troublePlayoutStream: '',
   troubleSelectedPlayout: {
      playerId: '',
      playoutFineStart: '',
      playoutFineEnd: '',
   },
   troubleSyncSettings: {
      syncStart: '',
      syncEnd: '',
   },
   ipFilteringTimespan: {
      start: moment.utc().startOf('hour').add(1, 'hour').subtract(1, 'day'),
      end: moment.utc().startOf('hour').add(1, 'hour'),
   },
   ipFilteringFilter: {
      streamNames: [],
      tags: [],
   },
   demoMode: {
      isActive: false,
      demoUser: '',
   },
};

const generalReducer = (state = initialState, action) => {
   // console.log('ACTION :>> ', action);
   // console.log('ACTION state :>> ', JSON.stringify(state));

   switch (action.type) {
      // code to apply URL to store

      case SET_STATE_BY_URL: {
         const newView = action.payload.location.pathname.substr(1);

         // just updated URL after app action has been executed
         if (action.payload.action === 'PUSH') {
            const viewChangeEvent = state.currentView !== newView;
            return viewChangeEvent
               ? Object.assign({}, state, {
                    currentView: newView,
                    alarm: { type: 'info', message: '' },
                 })
               : state;
         }
         // console.log('set state by URL :>> ', state);

         // console.log('ACTION url update has been PULLED :>> ');
         const queryUrl = action.payload.location.search; // URL: everything after '?'
         const newState = syncStore(queryUrl, newView, state, action.payload.isFirstRendering, state.currentView);
         // console.log('newState :>> ', newState);

         if (timespanChanged(state, newState) || orgaHashChanged(state, newState)) {
            newState.countries = [{ value: 'loading ...', label: 'loading ...' }];
            newState.tags = [{ value: 'loading ...', label: 'loading ...' }];
            newState.events = [{ value: 'loading ...', label: 'loading ...' }];
         }

         if (timespanOrIntervalChanged(state, newState) || filtersChanged(state, newState)) {
            // cancel token, added to old requests, will be activated --> old requests will be cancelled
            state.cancelToken.cancel('Operation canceled by the user.');

            // new cancel token will be created for new requests
            newState.cancelToken = axios.CancelToken.source();
         }

         newState.currentView = newView;

         return newState;
      }

      case SET_INITIALSTATE:
         return initialState;

      case SET_ALARM: {
         toast.error(action.payload.message);
         return state;
      }

      case SET_COUNTRIES:
         return Object.assign({}, state, {
            countries: action.payload,
            fetchFilterData: { ...state.fetchFilterData, countries: false },
         });

      case SET_TAGS:
         return Object.assign({}, state, {
            tags: action.payload,
            fetchFilterData: { ...state.fetchFilterData, tags: false },
         });

      case SWITCH_USAGE:
         return Object.assign({}, state, {
            usageSwitch: action.payload.usageSwitch,
         });

      case SET_EVENTS:
         return Object.assign({}, state, {
            events: action.payload,
            fetchFilterData: { ...state.fetchFilterData, events: false },
         });

      case SET_FILTER: {
         if (!filtersChanged(state, { filter: action.payload })) {
            return state;
         }
         // cancel token, added to old requests, will be activated --> old requests will be cancelled
         state.cancelToken.cancel('Operation canceled by the user.');

         // Reset selected countries if the organization has changed because we don't
         // know if the selected countries are available for the new organization

         const orgHashChanged = action.payload.hash && state.filter.hash !== action.payload.hash;
         if (orgHashChanged) {
            action.payload.countries = [];
            action.payload.tags = [];
            action.payload.events = [];
            action.payload.streamNames = [];
         }

         const changedProps = {
            filter: action.payload,
            // new cancel token will be created for new requests
            cancelToken: axios.CancelToken.source(),
         };

         if (orgHashChanged) {
            changedProps.countries = [{ value: 'loading ...', label: 'loading ...' }];
            changedProps.tags = [{ value: 'loading ...', label: 'loading ...' }];
            changedProps.events = [{ value: 'loading ...', label: 'loading ...' }];
         }

         return Object.assign({}, state, changedProps);
      }

      case SET_TIMESPAN: {
         const timespanHasChanged = timespanChanged(state, { timespan: action.payload });

         const changedProps = {
            timespan: action.payload,
         };

         if (timespanOrIntervalChanged(state, { timespan: action.payload })) {
            // cancel token, added to old requests, will be activated --> old requests will be cancelled
            state.cancelToken.cancel('Operation canceled by the user.');
            // new cancel token will be created for new requests
            changedProps.cancelToken = axios.CancelToken.source();
         }

         if (timespanHasChanged) {
            changedProps.countries = [{ value: 'loading ...', label: 'loading ...' }];
            changedProps.tags = [{ value: 'loading ...', label: 'loading ...' }];
            changedProps.events = [{ value: 'loading ...', label: 'loading ...' }];
            // changedProps.filter = { ...state.filter, streamNames: [] };
         }
         // console.log('timespanHasChanged (start cancel):>> ', timespanHasChanged);
         // console.log('timespanOrIntervalChanged :>> ', timespanOrIntervalChanged(state, { timespan: action.payload }));

         return Object.assign({}, state, changedProps);
      }

      case SET_USERINFO:
         return Object.assign({}, state, {
            userInfo: action.payload,
         });

      case SET_NEW_CANCELTOKEN: {
         return Object.assign({}, state, {
            cancelToken: action.payload,
         });
      }

      case SET_VIEW_TIMESPAN: {
         const oldView = action.payload;
         const newView = state.currentView;
         const setCurrentMonthTimespan = oldView === HISTORY_VIEW;
         const setLastMonthTimespan = newView === HISTORY_VIEW;

         let newTimespan = state.timespan;

         if (state.timespan.id === 23 && MAX_1_MONTH_TIME_SPAN_VIEWS.includes(newView)) {
            newTimespan = getShortcutTimespan(DEFAULT_LAST_MONTH_SHORTCUT_ID, false);
         }

         if (setCurrentMonthTimespan) {
            newTimespan = getShortcutTimespan(DEFAULT_SHORTCUT_ID, false);
         }

         if (setLastMonthTimespan) {
            newTimespan = getShortcutTimespan(DEFAULT_LONGTERM_SHORTCUT_ID, true);
         }

         return Object.assign({}, state, {
            timespan: newTimespan,
         });
      }

      case SET_WORLDMAP_METRIC:
         return Object.assign({}, state, {
            worldmapMetric: action.payload,
         });

      case SET_WORLDMAP_SUBSWITCH:
         return Object.assign({}, state, {
            worldmapSubSwitch: {
               ...state.worldmapSubSwitch,
               [action.payload.metric]: action.payload.switchOption,
            },
         });

      case SET_WORLDMAP_OPTIONS:
         return Object.assign({}, state, {
            worldmapOptions: {
               ...state.worldmapOptions,
               [action.payload.worldmapOption]: action.payload.selectedOption,
            },
         });

      case SET_TROUBLESHOOTING_TIMESPAN: {
         // case: publish selected and interval changed -> need to adjust fine interval according selected interval
         // e.g. 30-seconds interval selected: publish start = 11:34:43 => fine interval start = 11:34:30
         if (state.troubleSelectedPublish.publishStart) {
            return Object.assign({}, state, {
               troubleTimespan: action.payload,
               troubleSelectedPublish: {
                  ...state.troubleSelectedPublish,
                  publishFineStart: getIntervalStart(
                     state.troubleSelectedPublish.publishStart,
                     action.payload.troubleInterval.value,
                  ),
                  publishFineEnd: getIntervalEnd(
                     state.troubleSelectedPublish.publishEnd,
                     action.payload.troubleInterval.value,
                  ),
               },
            });
         } else {
            return Object.assign({}, state, {
               troubleTimespan: action.payload,
            });
         }
      }

      case SET_TROUBLESHOOTING_MODE:
         return Object.assign({}, state, {
            troubleMode: action.payload,
         });

      case SET_TROUBLESHOOTING_INGEST_INPUT:
         return Object.assign({}, state, {
            troubleIngestInput: action.payload,
            troubleIngestStream: { ingestStream: '' },
            troubleSelectedPublish: {
               publishStart: '',
               publishEnd: '',
               publishFineStart: '',
               publishFineEnd: '',
            },
         });

      case SET_TROUBLESHOOTING_INGEST_STREAM:
         return Object.assign({}, state, {
            ...action.payload,
         });

      case SET_TROUBLESHOOTING_SELECTED_PUBLISH:
         return Object.assign({}, state, {
            troubleSelectedPublish: action.payload,
         });

      case SET_TROUBLESHOOTING_PUBLISH_FINE_TIMESPAN:
         return Object.assign({}, state, {
            troubleSelectedPublish: {
               ...state.troubleSelectedPublish,
               ...action.payload,
            },
         });

      case RESET_TROUBLESHOOTING: {
         state.cancelToken.cancel('Operation canceled by the user.');
         return Object.assign({}, state, {
            cancelToken: axios.CancelToken.source(),
            troubleIngestInput: { ingestInput: '' },
            troublePlayoutInput: { userId: '' },
            troubleIngestStream: { ingestStream: '' },
            troubleSelectedPublish: {
               publishStart: '',
               publishEnd: '',
               publishFineStart: '',
               publishFineEnd: '',
            },
            troublePlayoutStream: '',
            troubleSelectedPlayout: {
               playerId: '',
               playoutFineStart: '',
               playoutFineEnd: '',
            },
         });
      }

      case SET_TROUBLESHOOTING_PLAYOUT_INPUT:
         return Object.assign({}, state, {
            troublePlayoutInput: action.payload,
            troublePlayoutStream: '',
            troubleSelectedPlayout: {
               playerId: '',
               playoutFineStart: '',
               playoutFineEnd: '',
            },
         });

      case SET_TROUBLESHOOTING_PLAYOUT_STREAM:
         return Object.assign({}, state, {
            ...action.payload,
         });

      case SET_TROUBLESHOOTING_SELECTED_PLAYOUT:
         return Object.assign({}, state, {
            troubleSelectedPlayout: action.payload,
         });

      case SET_TROUBLESHOOTING_PLAYOUT_FINE_TIMESPAN:
         return Object.assign({}, state, {
            troubleSelectedPlayout: {
               ...state.troubleSelectedPlayout,
               ...action.payload,
            },
         });

      case SYNC_TROUBLESHOOTING_INGEST:
         return Object.assign({}, state, {
            troubleMode: E2E,
            troubleIngestInput: { ingestInput: state.troublePlayoutStream },
            troubleIngestStream: { ingestStream: state.troublePlayoutStream },
            troubleSyncSettings: {
               syncStart: state.troubleSelectedPlayout.playoutFineStart,
               syncEnd: state.troubleSelectedPlayout.playoutFineEnd,
            },
         });

      case COMPLETE_SYNC_TROUBLESHOOTING_INGEST:
         return Object.assign({}, state, {
            troubleSelectedPublish: action.payload,
            troubleSyncSettings: {
               syncStart: '',
               syncEnd: '',
            },
         });

      case SET_WORLDMAP_VIEW:
         return Object.assign({}, state, {
            worldmapView: action.payload,
         });

      case SET_WORLDMAP_REGION_ZOOM:
         return Object.assign({}, state, {
            worldmapRegionZoom: action.payload,
         });

      case SET_IP_FILTERING_FILTER:
         return Object.assign({}, state, {
            ipFilteringFilter: action.payload,
         });

      case SET_IP_FILTERING_TIMESPAN:
         return Object.assign({}, state, {
            ipFilteringTimespan: action.payload,
         });

      case SET_DEMO_MODE:
         return Object.assign({}, state, {
            demoMode: action.payload,
         });

      default:
         return state;
   }
};

export default generalReducer;
