import React, { useState } from 'react';

import { Col, Row } from 'reactstrap';

import { PropTypes } from 'prop-types';
import FilterHead from './FilterHead';
import SelectedOption from './SelectedOption';
import { useSelector } from 'react-redux';
import { demoModeSelector } from '../../../redux/selectors/selectors';
import FilterTable from './FilterTable';
import {
   WidgetBodyContainer,
   WidgetContainer,
   WidgetFooterContainer,
   WidgetHeaderContainer,
} from '../../../styledComponents/styledComponents';
import { DefaultGrey } from '../../../constants/colors';

function Filter(props) {
   const [selectedOrga, setSelectedOrga] = useState([]);
   const demoMode = useSelector(demoModeSelector);
   return (
      <div style={{ position: 'relative', zIndex: 11 }} className='animated fadeIn'>
         <Row>
            <Col>
               <WidgetContainer style={demoMode.isActive ? {} : { minHeight: '547px' }}>
                  <WidgetHeaderContainer $bgColor={DefaultGrey(1, 247)}>
                     <FilterHead demoMode={demoMode} selectedOrga={selectedOrga} {...props} />
                  </WidgetHeaderContainer>
                  {!demoMode.isActive && (
                     <>
                        <WidgetBodyContainer>
                           <FilterTable onOrgaSelection={setSelectedOrga} />
                        </WidgetBodyContainer>
                        <WidgetFooterContainer $padding={'10px 20px 2px 20px'}>
                           <SelectedOption selectedOrga={selectedOrga} />
                        </WidgetFooterContainer>
                     </>
                  )}
               </WidgetContainer>
            </Col>
         </Row>
      </div>
   );
}

Filter.propTypes = {
   location: PropTypes.object,
   history: PropTypes.object,
};

export default Filter;
