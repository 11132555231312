import { urlSyncSettings } from './urlSyncSettings';
import moment from 'moment';
import { TROUBLE_INTERVALS_MAP } from '../components/widgets/troubleshooting/constants';

let justReloaded = false;

export function syncURL(pathname, pushToUrl, currentState) {
   const viewPageName = pathname.substr(1);
   const isUserAdmin = isAdminUserLoggedIn();
   // console.log('pushToUrl :>> ', pushToUrl);

   let urlParams = `${pathname}?`;

   Object.keys(urlSyncSettings).forEach((param) => {
      const { urlFn, group, storeName, postProcessing, views } = urlSyncSettings[param];

      const isOriginRelatedParam = param === 'originHash' || param === 'originName';

      if (isOriginRelatedParam) {
         if (!isUserAdmin && isUserInfoAvailable()) {
            const orgaInfo = getUserOrga();
            urlParams += urlFn(param, orgaInfo[param]);
         }
         return;
      }

      const isOrgaRelatedParam = param === 'orgHash' || param === 'orgName';

      if (isOrgaRelatedParam && !isUserAdmin) {
         return;
      }
      const currentViewHasNotParam = views && views.every((viewName) => viewPageName !== viewName);
      if (currentViewHasNotParam) {
         return;
      }
      let currentStateValue = group !== '' ? currentState[group][storeName] : currentState[storeName];
      // console.log('currentStateValue :>> ', currentStateValue);
      currentStateValue = currentStateValue === undefined ? '' : currentStateValue;
      // console.log('currentStateValue 2:>> ', currentStateValue);

      let paramValue = pushToUrl[storeName] !== undefined ? pushToUrl[storeName] : currentStateValue;

      // if (pushToUrl[storeName] !== undefined) {
      //    console.log('group :>> ', group);
      //    console.log('storeName :>> ', storeName);
      //    console.log('pushToUrl[storeName] :>> ', pushToUrl[storeName]);
      //    console.log('currentStateValue :>> ', currentStateValue);
      // }
      // console.log('param :>> ', param);
      // console.log('paramValue :>> ', paramValue);
      // console.log('postProcessing :>> ', postProcessing);
      // console.log('isUserAdmin :>> ', isUserAdmin);
      if (postProcessing !== undefined) {
         if (postProcessing === 'format') {
            paramValue = paramValue.format(); //  moment.utc(timespan.gte.format());
         }
         if (postProcessing === 'selectInterval') {
            paramValue = paramValue.value;
         }
      }
      // console.log('param 2:>> ', param);
      // console.log('paramValue 2:>> ', paramValue);
      // console.log('paramValue :>> ', paramValue);
      urlParams += urlFn(param, paramValue);
   });

   urlParams = removeCertainLastChar(urlParams, '&');
   // console.log('urlParams :>> ', urlParams);
   return urlParams;
}

export function syncStore(queryURL, newView, store, firstRendering, currentView) {
   const newState = { ...store };
   const isUserAdmin = isAdminUserLoggedIn();
   // console.log('orgaHash :>> ', orgaHash);
   // create new refs one level deeper
   const groups = new Set();
   Object.keys(urlSyncSettings).forEach((urlParamName) => {
      const { group } = urlSyncSettings[urlParamName];
      if (group !== '') {
         groups.add(group);
      }
   });
   groups.forEach((groupName) => {
      newState[groupName] = { ...store[groupName] };
   });

   try {
      const splitUrlArray = queryURL.split('?');

      if (splitUrlArray.length > 2 || splitUrlArray.length === 0) {
         throw Error('"?" number of findings !== 1 || 2');
      }
      if (splitUrlArray.length === 1) {
         // console.log('splitUrlArray.length === 1 ');
         return newState;
      }

      const paramUrl = splitUrlArray[1];
      const params = paramUrl.split('&');
      // console.log('params :>> ', params);

      // when a copied troubleshooting URL is inserted into the browser URL input
      // while the troubleshooting page is already open (and has state)
      // a reload is needed to clear the page before the URL state can be applied
      if (newView === 'troubleshooting' && currentView === 'troubleshooting') {
         if (justReloaded === false) {
            window.location.reload();
            justReloaded = true;
         } else {
            justReloaded = false;
         }
      }

      const splitParamMap = {};
      params.forEach((param) => splitParamKeyValue(param, splitParamMap));
      // console.log('splitParamMap :>> ', splitParamMap);

      Object.keys(urlSyncSettings).forEach((urlParamName, idx) => {
         // console.log('urlParamName :>> ', urlParamName);
         const { parseFn, group, storeName, postProcessing, views, adminTargetProp } = urlSyncSettings[urlParamName];

         const isOriginRelatedParam = urlParamName === 'originHash' || urlParamName === 'originName';

         if (isOriginRelatedParam) {
            // origin information is not stored in store -> source is session storage / userInfo
            if (!isUserInfoAvailable()) {
               // non admin URL -> use with admin acccount: set orga origin to orga filter
               if (newState['filter'][adminTargetProp] === '') {
                  // only set orga filter via origin when orga filter has not been set before
                  newState['filter'][adminTargetProp] = parseFn(splitParamMap[urlParamName]);
               }
            }
            return;
         }

         const isOrgaRelatedParam = urlParamName === 'orgHash' || urlParamName === 'orgName';

         // console.log('isUserAdmin :>> ', isUserAdmin);
         // console.log('isUserInfoAvailable :>> ', isUserInfoAvailable());
         if (isUserInfoAvailable() && isOrgaRelatedParam && !isUserAdmin) return;

         const currentViewHasNotParam = views && views.every((viewName) => newView !== viewName);
         if (currentViewHasNotParam) {
            return;
         }

         const urlParamNotFound = !(urlParamName in splitParamMap);
         const isUserOrgaStillUnclear = isOrgaRelatedParam && !isUserInfoAvailable();

         if (urlParamNotFound && !isUserOrgaStillUnclear) {
            throw Error(`URL param ${urlParamName} unknown`);
         }

         // console.log('splitParamMap[urlParamName] :>> ', splitParamMap[urlParamName]);
         // console.log('urlParamName :>> ', urlParamName);
         let newValue = parseFn(splitParamMap[urlParamName]);

         if (postProcessing !== undefined) {
            if (postProcessing === 'format') {
               newValue = moment.utc(newValue); //  moment.utc(timespan.gte.format());
            }
            if (postProcessing === 'selectInterval') {
               newValue = TROUBLE_INTERVALS_MAP[newValue];
            }
         }

         // console.log('newValue :>> ', newValue);
         // console.log('group :>> ', group);
         // console.log('storeName :>> ', storeName);

         if (group === '') {
            newState[storeName] = newValue;
         } else {
            newState[group][storeName] = newValue;
         }
      });
   } catch (error) {
      // console.error('error:>> ', error);
      return Object.assign({}, store, {
         alarm: {
            type: 'danger',
            message: 'Unable to parse URL', // 'URL could not be parsed',
         },
      });
   }
   // console.log('URL ---> STORE: newState :>> ', newState);
   return newState;
}

function splitParamKeyValue(param, parsedParamMap) {
   if (param === 'refresh') {
      // param has no meaning, it is just used to make URL different to last one
      return;
   }
   const splitParamArray = param.split('=');
   // console.log('splitParamArray :>> ', splitParamArray);
   if (splitParamArray.length !== 2) {
      throw Error('Split Params !==2');
   }
   const [key, value] = splitParamArray;
   parsedParamMap[key] = value;
}

function removeCertainLastChar(str, lastChar) {
   const lastCharIdx = str.length - 1;
   if (str[lastCharIdx] === lastChar) {
      return str.substr(0, lastCharIdx);
   }
   return str;
}

function isAdminUserLoggedIn() {
   if (isUserInfoAvailable()) {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      return userInfo.isAdmin;
   }
   return false;
}

function getUserOrga() {
   if (isUserInfoAvailable()) {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      return { originHash: userInfo.organization.hash, originName: userInfo.organization.name };
   }
   return { originHash: 'unknown', originName: 'unknown' };
}

function isUserInfoAvailable() {
   return sessionStorage.getItem('userInfo') !== null;
}
