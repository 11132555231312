import { WorldRegions } from '../WorldMapConstants';

const country_id_alpha_mapper = require('../../../../assets/worldmap/compact_country_id_alpha_mapper.json');
const worldGeoJson = require('../../../../assets/worldmap/WorldGeoJson.json');

export const svgWorldData = getSVGWorldData();

function getSVGWorldData() {
   worldGeoJson.forEach((svgCountry) => {
      const countryInfo = country_id_alpha_mapper[svgCountry.id.toString()];
      if (countryInfo) {
         svgCountry.name = countryInfo.code.alpha2;
         svgCountry.worldRegion = countryInfo.worldRegion ? countryInfo.worldRegion : WorldRegions.UNKNOWN;
      } else {
         if (svgCountry.id.toString() === '-1') {
            svgCountry.name = 'Kosovo';
            svgCountry.worldRegion = WorldRegions.EUROPE;
         } else if (svgCountry.id.toString() === '-2') {
            svgCountry.name = 'Somalia defected';
            svgCountry.worldRegion = WorldRegions.AFRICA;
         } else {
            svgCountry.name = WorldRegions.UNKNOWN;
            svgCountry.worldRegion = WorldRegions.UNKNOWN;
         }
      }
   });
   return worldGeoJson;
}
