const country_alpha_id_mapper = require('../assets/worldmap/compact_country_alpha_id_mapper.json');

export function getOptions(defaultOptions, passedOptions) {
   const options = {};
   Object.keys(defaultOptions).forEach((optionName) => {
      options[optionName] =
         passedOptions[optionName] !== undefined ? passedOptions[optionName] : defaultOptions[optionName];
   });
   return options;
}

let lastId = 0;

export function uniqueIdFactory(prefix = 'id') {
   lastId++;
   return `${prefix}${lastId}`;
}

export function getSelectOption(label) {
   return { label, value: label };
}

export function getSelectOptionValues(selectOptionArray) {
   return selectOptionArray.map((option) => option.value);
}

export function getFullCountryNames(countryId) {
   // check if string has exactly 2 letters & must be both uppercase
   // = country code
   if (
      countryId.length === 2 &&
      countryId.charCodeAt(0) > 64 &&
      countryId.charCodeAt(0) < 91 &&
      countryId.charCodeAt(1) > 64 &&
      countryId.charCodeAt(1) < 91
   ) {
      if (country_alpha_id_mapper[countryId]) {
         return country_alpha_id_mapper[countryId].name;
      }
   }
   return countryId;
}

export function capitalizeFirstLetter(word) {
   const lowCaseWord = word.toLowerCase();
   return lowCaseWord.charAt(0).toUpperCase() + lowCaseWord.slice(1);
}

export function convertListToMap(list, keyProp, setValueOnlyTrue = false) {
   const newMap = {};
   list.forEach((elem) => {
      newMap[elem[keyProp]] = setValueOnlyTrue ? true : elem;
   });
   return newMap;
}

export function getDomainFromURL(url) {
   try {
      const domain = new URL(url);
      return domain.hostname.replace('www.', '');
   } catch (error) {
      return url;
   }
}

export function catchNull(value) {
   return value === null ? 0 : value;
}
