import React from 'react';

import styled from 'styled-components';

import { PropTypes } from 'prop-types';

import 'spinkit/css/spinkit.css';

const Spinner = styled.div`
   height: ${(props) => props.height};
   width: ${(props) => props.width};
   top: 0px;
   left: 0px;
   z-index: 1;
   position: absolute;
   background-color: rgba(255, 255, 255, ${(props) => props.opacity});
`;
Spinner.displayName = 'Spinner';

const BounceContainer = styled.div`
   margin-left: auto;
   margin-right: auto;
   position: absolute;
   top: ${(props) => props.top};
   width: 100%;
`;
BounceContainer.displayName = 'BounceContainer';

function TransparentSpinner({ height, width, opacity, top }) {
   return (
      <Spinner height={height} width={width} opacity={opacity || 0.7}>
         <BounceContainer className='sk-three-bounce' top={top || '30%'}>
            <div className='sk-child sk-bounce1' />
            <div className='sk-child sk-bounce2' />
            <div className='sk-child sk-bounce3' />
         </BounceContainer>
      </Spinner>
   );
}

TransparentSpinner.propTypes = {
   height: PropTypes.string,
   width: PropTypes.string,
   opacity: PropTypes.number,
   top: PropTypes.string,
};

export default TransparentSpinner;
