import React, { Component } from 'react';
import { Dashboard } from '../../../common';
import Widget from '../../Widget/Widget';
import { connect } from 'react-redux';
import { AccessLevel } from '../../../../constants/AccessLevels';

import { Col, Row } from 'reactstrap';

import storePropTypes from './../../../../redux/store/propTypes';
import WorldContainer from './../../../widgets/world/WorldContainer';

import axios from 'axios';
import { setNewCancelToken, setViewTimespan } from './../../../../redux/actions/general';

import { PropTypes } from 'prop-types';
import { WORLD_VIEW } from '../../../../constants/general';

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setNewCancelToken, setViewTimespan };

class World extends Component {
   componentWillUnmount() {
      // cancel token, added to old requests, will be activated --> old requests will be cancelled
      this.props.cancelToken.cancel('Operation canceled by the user.');
      this.props.setViewTimespan(WORLD_VIEW);
      this.props.setNewCancelToken(axios.CancelToken.source());
   }

   render() {
      return (
         <Dashboard
            location={this.props.location}
            history={this.props.history}
            worldMetric={this.props.worldmapMetric}
            view={WORLD_VIEW}
         >
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                  <Widget type={WorldContainer} requiredLevel={AccessLevel.FULL} />
               </Col>
            </Row>
         </Dashboard>
      );
   }
}

World.propTypes = {
   cancelToken: storePropTypes.cancelToken,
   setNewCancelToken: PropTypes.func,
   setViewTimespan: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(World);
