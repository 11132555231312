import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import { Bar } from 'react-chartjs-2';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, HorizontalSeparator, Spinner } from '../../../common';
import roundTo from 'round-to';

import { CardTitle } from 'reactstrap';

import { PropTypes } from 'prop-types';

import UsageModal from '../../../zoom/templates/UsageTemplate';
import { PI_USAGE } from '../../../zoom/ZoomConstants';
import { WIDGET_SETTINGS } from '../../../zoom/widgetSettings/HomeView';
import ZoomHint from '../../../zoom/ZoomHint';

import { HOME_VIEW, INGEST, PLAYOUT } from '../../../../constants/general';
import { barChartZoomOptions } from '../../../../constants/chartjs';

import { addSeparatorAndThreeDecimalPlaces } from '../../../../util/NumberFormatter';
import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   RightSide,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   TwoSideRow,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';

import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';
import { generateStackedBarChartData } from '../../../../util/ChartJsProcessing';
import {
   hashSelector,
   startTimespanSelector,
   endTimespanSelector,
   titleTimespanSelector,
   intervalTimespanSelector,
   cancelTokenSelector,
   streamNamesSelector,
   tagsSelector,
   countriesSelector,
   usageSwitchSelector,
   timespanSelector,
} from '../../../../redux/selectors/selectors';
import useDataFetch from '../../../../hooks/useDataFetch';
import ErrorDisplay from '../../ipFiltering/ErrorDisplay';
import TrafficHint from '../../../common/Hints/TrafficHint';
import { DefaultGrey } from '../../../../constants/colors';

const barChartOptions = barChartZoomOptions();

const stackedPropNames = [PLAYOUT, INGEST];

const USAGE_SETTINGS = {
   bytes: {
      unit: 'GB',
      chartLabelPlayout: 'Playout [GB]',
      chartLabelIngest: 'Ingest [GB]',
      unitConversion: (value) => roundTo(value / 1e9, 3),
      resUnitPropNames: 'bytes',
   },
   playtime: {
      unit: 'h',
      chartLabelPlayout: 'Playout [h]',
      chartLabelIngest: 'Ingest [h]',
      unitConversion: (value) => roundTo(value / 3600, 3),
      resUnitPropNames: 'seconds',
   },
};

const blue = getStyle('--info');
const red = getStyle('--danger');

const protocolNameLengthPx = 50;

function defaultChartObject(usageSwitch, labels = [], data1 = [], data2 = []) {
   return {
      labels,
      datasets: [
         {
            label: USAGE_SETTINGS[usageSwitch].chartLabelPlayout,
            backgroundColor: hexToRgba(red, 10),
            borderColor: red,
            borderWidth: 1,
            data: data1,
         },
         {
            label: USAGE_SETTINGS[usageSwitch].chartLabelIngest,
            backgroundColor: hexToRgba(blue, 10),
            borderColor: blue,
            borderWidth: 1,
            data: data2,
         },
      ],
   };
}

function addZoomClickHandler(chartOptions, barRef, setModal) {
   return {
      ...chartOptions,
      onClick: (event, item) => {
         if (item.length === 0) {
            return;
         }
         const activePoint = barRef.current.chartInstance.getElementAtEvent(event)[0];
         const datasetIndex = activePoint._datasetIndex;
         const data = activePoint._chart.data;
         const clickedLabel = data.datasets[datasetIndex].label;
         const index = item[0]._index;

         setModal({
            chosenIndex: index,
            toggleShow: true,
            selectedStackSubBar: clickedLabel.split(' [')[0],
         });
      },
   };
}

function totalToString(total) {
   return addSeparatorAndThreeDecimalPlaces(total).toString();
}

function Usage({ height }) {
   const usageSwitch = useSelector(usageSwitchSelector);

   const [totals, setTotals] = useState([0, 0]);
   const [chart, setChart] = useState(defaultChartObject(usageSwitch));

   const [noData, setNoData] = useState(false);

   const hash = useSelector(hashSelector);
   const streamNames = useSelector(streamNamesSelector);
   const tags = useSelector(tagsSelector);
   const countries = useSelector(countriesSelector);

   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);

   const timespanObj = useSelector(timespanSelector);
   const interval = useSelector(intervalTimespanSelector);
   const timespanTitle = useSelector(titleTimespanSelector);

   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const [modal, setModal] = useState({
      toggleShow: false,
      chosenIndex: 0,
      selectedStackSubBar: WIDGET_SETTINGS[PI_USAGE].categoryLabels[0],
   });

   const [timespansPerIndex, setTimespansPerIndex] = useState([
      {
         start: moment.utc(),
         end: moment.utc(),
      },
   ]);

   const barRef = useRef(null);

   const chartOptions = addZoomClickHandler(barChartOptions, barRef, setModal);

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, [], streamNames, HOME_VIEW);
   const url = `/api/v2/usage/${usageSwitch}/timeseries?from=${start.format()}&to=${end.format()}&interval=${interval}${urlFilterQuery}`;
   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            const usage = data.data;
            setNoData(data.statusCode === 2001);
            const { datasetsDatas, labels, totals, timespansPerIndex } = generateStackedBarChartData(
               usage,
               timespanObj,
               stackedPropNames,
               USAGE_SETTINGS[usageSwitch].resUnitPropNames,
               USAGE_SETTINGS[usageSwitch].unitConversion,
            );
            setTimespansPerIndex(timespansPerIndex);
            setChart(defaultChartObject(usageSwitch, labels, ...datasetsDatas));
            setTotals(totals);
         }
      }
      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, dispatch, usageSwitch, setTimespansPerIndex, timespanObj]);

   const errorOrNoData = errorMessage || noData;

   const { toggleShow, chosenIndex, selectedStackSubBar } = modal;
   const chosenTimespan = timespansPerIndex[chosenIndex];

   const { unit } = USAGE_SETTINGS[usageSwitch];
   const [playoutTotal, ingestTotal] = totals;

   return (
      <WidgetContainer>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <CsvExportFromApiButton
                           url={url}
                           header={header}
                           cancelToken={cancelToken}
                           filename={'Usage_PlayoutIngest'}
                        />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <TrafficHint term={'playout or stream'} hasTimeAxis={true} sizePx={16} />
                     </SimpleFlexItem>
                     <HorizontalSeparator
                        color={DefaultGrey(1, 180)}
                        widthInPx={1}
                        heightInPx={18}
                        marginX_InPx={12}
                        marginTopInPx={1}
                     />
                     <SimpleFlexItem>
                        <ZoomHint />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer>
            <TwoSideRow marginRightPx={15}>
               <div>
                  <CardTitle className='mb-0'>Usage Playout/Ingest</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </div>
               <RightSide minWidthPx={190}>
                  <TwoSideRow setNoWidth={true} marginLeftPx={15}>
                     <RightSide minWidthPx={protocolNameLengthPx}>Playout:</RightSide>
                     <div className='text-danger'>
                        {`${isLoading || errorOrNoData ? '0' : totalToString(playoutTotal)} ${unit}`}
                     </div>
                  </TwoSideRow>
                  <TwoSideRow setNoWidth={true} marginLeftPx={15}>
                     <RightSide minWidthPx={protocolNameLengthPx}>Ingest:</RightSide>
                     <div className='text-info'>
                        {`${isLoading || errorOrNoData ? '0' : totalToString(ingestTotal)} ${unit}`}
                     </div>
                  </TwoSideRow>
                  <TwoSideRow setNoWidth={true} marginLeftPx={15}>
                     <RightSide minWidthPx={protocolNameLengthPx}>Total:</RightSide>
                     <div>
                        {`${isLoading || errorOrNoData ? '0' : totalToString(playoutTotal + ingestTotal)} ${unit}`}
                     </div>
                  </TwoSideRow>
               </RightSide>
            </TwoSideRow>
            <ChartWrapper className='chart-wrapper' marginTopPx={10} height={height}>
               <Spinner loading={isLoading} parentTopMarginPx={71}>
                  {errorOrNoData ? (
                     <CenteredMsgShowing height={height}>
                        {errorMessage ? (
                           <ErrorDisplay error={errorMessage} msgColor={'rgb(80,80,80)'}></ErrorDisplay>
                        ) : (
                           'No data available'
                        )}
                     </CenteredMsgShowing>
                  ) : (
                     <SetChartHeight height={height}>
                        <Bar ref={barRef} data={chart} options={chartOptions} height={height} />
                     </SetChartHeight>
                  )}
               </Spinner>
            </ChartWrapper>
         </WidgetBodyContainer>
         {toggleShow && (
            <UsageModal
               timespan={chosenTimespan}
               metric={PI_USAGE}
               selectedStackSubBar={selectedStackSubBar}
               closeModal={() => setModal({ ...modal, toggleShow: false })}
            />
         )}
      </WidgetContainer>
   );
}

Usage.propTypes = {
   height: PropTypes.number,
};

export default Usage;
