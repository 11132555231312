import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, Spinner } from '../../../common';

import { CardTitle, Col, Row } from 'reactstrap';

import { filtersChanged, timespanOrIntervalChanged } from '../../../../redux/selectors/comparing';

import 'spinkit/css/spinkit.css';

import { PropTypes } from 'prop-types';
import storePropTypes from '../../../../redux/store/propTypes';

import { chartjsColors, lineChartZoomOptions } from '../../../../constants/chartjs';
import { H5LIVE_VIEW } from '../../../../constants/general';
import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';

import ComponentTools from '../../../../util/ComponentTools';
import { getUnifiedErrorObject } from '../../../../util/ErrorUnifier';
import { catchNull } from '../../../../util/UtilFunctions';
import { LATENCY } from '../../../zoom/ZoomConstants';
import ZoomHint from '../../../zoom/ZoomHint';
import Modal from '../../../zoom/templates/DefaultTemplate';
import { H5LIVE_SETTINGS } from '../../../zoom/widgetSettings/H5liveView';

const chartOptions = lineChartZoomOptions();

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setAlarm };

function getNewDataSet(label, color) {
   let description = label;
   if (label.includes('_')) {
      const country = label.split('_')[0];
      const agg = label.split('_')[1];
      description = agg === 'avg' ? 'Average ' + country : 'Median ' + country;
   }

   return {
      label: description,
      backgroundColor: hexToRgba(color, 10),
      borderColor: color,
      borderWidth: 2,
      data: [],
      hidden: false,
      fill: false,
   };
}

let requestForData = false;

class LatencyStats extends Component {
   constructor(props) {
      super(props);

      this.state = {
         loading: false,
         chart: {
            type: 'line',
            labels: [],
            datasets: [],
         },
         modal: {
            toggleShow: false,
            timespansPerIndex: [
               {
                  start: moment.utc(),
                  end: moment.utc(),
               },
            ],
            chosenIndex: 0,
         },
         error: '',
         noData: false,
         url: '',
         header: {},
         cancelToken: null,
      };
      this.chartRef = React.createRef();
   }

   getData(timespan, filter, cancelToken) {
      this.setState({
         ...this.state,
         loading: true,
         error: '',
         noData: false,
      });

      const self = this;

      const headerObj = ComponentTools.getHttpHeader(filter);
      const urlFilterQuery = ComponentTools.getFilterQueryParams(filter, H5LIVE_VIEW);

      let updated = {
         type: 'line',
         labels: [],
         datasets: [],
      };

      const labels = [];
      const timespansPerIndex = [];

      ComponentTools.setLineChartLabels(updated, labels, timespan, this.props.settings.weekType, timespansPerIndex);

      const url = `/api/v2/h5live/latency/timeseries?from=${timespan.gte.format()}&to=${timespan.lt.format()}&interval=${
         timespan.interval
      }${urlFilterQuery}`;

      axios
         .get(url, {
            headers: headerObj,
            cancelToken: cancelToken.token,
         })
         .then(function (response) {
            let noData = false;
            if (response.status !== 204) {
               const buckets = response.data.data;
               noData =
                  buckets.reduce(
                     (sum, dataPoint) =>
                        (sum += catchNull(dataPoint.median.seconds) + catchNull(dataPoint.average.seconds)),
                     0,
                  ) === 0;
               updated = self.parseData(labels, updated, buckets);
            }

            self.setState({
               chart: updated,
               noData,
               loading: false,
               modal: { ...self.state.modal, timespansPerIndex },
               url,
               header: headerObj,
               cancelToken,
            });
         })
         .catch(function (error) {
            if (error instanceof axios.Cancel) {
               return;
            }
            const errorObject = getUnifiedErrorObject(error);

            self.props.setAlarm('danger', errorObject);

            if (process.env.NODE_ENV === 'development') {
               console.error('error :', error);
            }

            self.setState({
               ...self.state,
               chart: updated,
               loading: false,
               error: errorObject.message,
               url,
               header: headerObj,
               cancelToken,
            });
         });
   }

   parseDataPerCountry(labels, updated, buckets) {
      // parse response data

      const countries = {};
      const bufferAvgs = [];

      labels.forEach((label) => {
         const start = label.start;
         const end = label.end;
         const interval = buckets.find((bucket) => bucket.key >= start && bucket.key < end);
         if (interval !== undefined) {
            interval.values = [];
            interval.country_code.buckets.forEach((country) => {
               const countryAvgData = {};
               countryAvgData.key = country.key + '_avg';
               countryAvgData.agg = 'Average';
               countryAvgData.country = country.key;
               countryAvgData.value = country.avg.value;
               interval.values.push(countryAvgData);
               if (!countries[countryAvgData.key]) {
                  countries[countryAvgData.key] = true;
               }
               const countryMedianData = {};
               countryMedianData.key = country.key + '_median';
               countryMedianData.agg = 'Median';
               countryMedianData.country = country.key;
               countryMedianData.value = country.median.values[0].value;
               interval.values.push(countryMedianData);
               if (!countries[countryMedianData.key]) {
                  countries[countryMedianData.key] = true;
               }
            });
            bufferAvgs.push(interval);
         } else bufferAvgs.push({ values: [] });
      });

      // create new datasets
      Object.keys(countries).forEach((countryKey, idx) => {
         updated.datasets.push(getNewDataSet(countryKey, chartjsColors[idx % chartjsColors.length]));
      });

      // populate datasets
      updated.datasets.forEach((dataset) => {
         bufferAvgs.forEach((intervalObj) => {
            const valueWasPushed = intervalObj.values.some((country) => {
               if (dataset.label.toString() === country.agg + ' ' + country.country) {
                  dataset.data.push(country.value);
                  return true;
               } else return false;
            });
            if (!valueWasPushed) dataset.data.push(0);
         });
      });

      return updated;
   }

   parseData(labels, updated, buckets) {
      // parse response data

      const bufferAvgs = [];

      labels.forEach((label) => {
         const start = label.start / 1000;
         const end = label.end / 1000;
         const interval = buckets.find((bucket) => bucket.timestamp >= start && bucket.timestamp < end);
         if (
            interval !== undefined &&
            catchNull(interval.median.seconds) !== 0 &&
            +catchNull(interval.average.seconds) !== 0
         ) {
            interval.values = [
               {
                  name: 'Median',
                  value: Math.round(interval.median.seconds * 100) / 100,
               },
               {
                  name: 'Average',
                  value: Math.round(interval.average.seconds * 100) / 100,
               },
            ];
            bufferAvgs.push(interval);
         } else bufferAvgs.push({ values: [] });
      });

      // create new datasets
      updated.datasets.push(getNewDataSet('Median', chartjsColors[0]));
      updated.datasets.push(getNewDataSet('Average', chartjsColors[1]));

      const lastIdxWithData = [-1, -1];

      // populate datasets
      updated.datasets.forEach((dataset, datasetIdx) => {
         bufferAvgs.forEach((intervalObj, idx) => {
            const valueWasPushed = intervalObj.values.some((medianOrAvg) => {
               if (dataset.label.toString() === medianOrAvg.name) {
                  dataset.data.push(medianOrAvg.value);
                  return true;
               } else {
                  return false;
               }
            });
            if (!valueWasPushed) {
               if (lastIdxWithData[datasetIdx] === -1) {
                  dataset.data.push(null);
               } else {
                  dataset.data.push(0);
               }
            } else {
               lastIdxWithData[datasetIdx] = idx;
            }
         });
      });

      updated.datasets.forEach((dataset, datasetIdx) => {
         for (let index = lastIdxWithData[datasetIdx] + 1; index < dataset.data.length; index++) {
            dataset.data[index] = null;
         }
      });

      return updated;
   }

   componentDidMount() {
      requestForData = true;
   }

   componentWillReceiveProps(nextProps, nextContext) {
      if (timespanOrIntervalChanged(this.props, nextProps) || filtersChanged(this.props, nextProps) || requestForData) {
         requestForData = false;
         this.getData(nextProps.timespan, nextProps.filter, nextProps.cancelToken);

         chartOptions['onClick'] = (event, item) => {
            if (item.length === 0) {
               return;
            }
            const index = item[0]._index;

            this.setState({
               modal: {
                  ...this.state.modal,
                  chosenIndex: index,
                  toggleShow: true,
               },
            });
         };
      }
   }

   render() {
      const errorOrNoData = this.state.error || this.state.noData;
      const issueMsg = this.state.error ? this.state.error : 'No data available';

      const { toggleShow, timespansPerIndex, chosenIndex } = this.state.modal;
      const chosenTimespan = timespansPerIndex[chosenIndex];
      const { url, header, cancelToken } = this.state;

      return (
         <WidgetContainer>
            <WidgetHeaderContainer>
               <HeightSetContainer $heightInPx={23}>
                  <TwoSideFlexContainer>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <CsvExportFromApiButton
                              url={url}
                              header={header}
                              cancelToken={cancelToken}
                              filename={'H5live_Latency'}
                           />
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <ZoomHint />
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                  </TwoSideFlexContainer>
               </HeightSetContainer>
            </WidgetHeaderContainer>
            <WidgetBodyContainer>
               <Row>
                  <Col>
                     <CardTitle className='mb-0'>Average and median player latency in seconds</CardTitle>
                     <div className='small text-muted'>
                        {`${this.props.timespan.title}\u00A0\u00A0\u00A0( UTC time )`}
                     </div>
                  </Col>
               </Row>
               <ChartWrapper className='chart-wrapper' marginTopPx={20} height={this.props.height}>
                  <Spinner loading={this.state.loading} parentTopMarginPx={60}>
                     {errorOrNoData ? (
                        <CenteredMsgShowing height={this.props.height}>{issueMsg}</CenteredMsgShowing>
                     ) : (
                        <SetChartHeight height={this.props.height}>
                           <Line ref={this.chartRef} data={this.state.chart} options={chartOptions} height={300} />
                        </SetChartHeight>
                     )}
                  </Spinner>
               </ChartWrapper>
            </WidgetBodyContainer>
            {toggleShow && (
               <Modal
                  timespan={chosenTimespan}
                  metric={LATENCY}
                  closeModal={() =>
                     this.setState({
                        modal: {
                           ...this.state.modal,
                           toggleShow: false,
                        },
                     })
                  }
                  settings={H5LIVE_SETTINGS}
               />
            )}
         </WidgetContainer>
      );
   }
}

LatencyStats.propTypes = {
   filter: storePropTypes.filter,
   timespan: storePropTypes.timespan,
   cancelToken: storePropTypes.cancelToken,
   settings: storePropTypes.settings,
   setAlarm: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(LatencyStats);
