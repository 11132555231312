import React, { useState } from 'react';
import {
   ModalHeader,
   ModalTitle,
   ModalTimeRange,
   ModalDateElement,
   ModalBody,
   Terms,
   Result,
} from '../../styledComponents/ModalStyledComponents';

import ZoomTable from './ZoomTable';
import { indexConstants } from './ZoomConstants';

import { PropTypes } from 'prop-types';
import SwitchButtonBar from '../common/SwitchButtonBar/SwitchButtonBar';

import styled from 'styled-components';
import SideBar from '../common/SideBar/SideBar';

export const ButtonBarContainer = styled.div`
   width: 100%;
   margin: 0px auto 25px auto;
   text-align: center;
   border-left: 1px solid white; // edge browser hovering artefacts workaround
`;
ButtonBarContainer.displayName = 'ButtonBarContainer';

function GeneralTemplate({
   title,
   urlAddon,
   pathParams,
   timespan,
   metric,
   categoryLabels,
   usageSwitch,
   initialCategoryIdx,
   isWorldmap,
   settings,
}) {
   const { exIndexName, shownTerms, buttonLabelFn } = settings;
   const { TERMS, DISPLAYED_NAME_OF_TERM, INITIAL_TERM } = indexConstants[exIndexName];

   const [activeTerm, setTermActive] = useState(shownTerms !== undefined ? shownTerms[0] : INITIAL_TERM);
   const [activeCategoryIdx, setActiveCategoryIdx] = useState(initialCategoryIdx);
   // console.log('activeCategoryIdx => ', activeCategoryIdx);

   return (
      <>
         <ModalHeader>
            <ModalTitle>{isWorldmap ? title : `Breakdown of ${title}`}</ModalTitle>
            <ModalTimeRange>
               <div>
                  <ModalDateElement width={'20%'}>From:</ModalDateElement>
                  <ModalDateElement width={'80%'}>{timespan.start.format('Do MMM YYYY HH:mm')}</ModalDateElement>
               </div>
               <div>
                  <ModalDateElement width={'20%'}>To:</ModalDateElement>
                  <ModalDateElement width={'80%'}>{timespan.end.format('Do MMM YYYY HH:mm')}</ModalDateElement>
               </div>
            </ModalTimeRange>
         </ModalHeader>
         <ModalBody>
            <Terms>
               <SideBar
                  options={shownTerms !== undefined ? shownTerms : TERMS(isWorldmap)}
                  activeOption={activeTerm}
                  handleClick={setTermActive}
                  optionLabelMap={DISPLAYED_NAME_OF_TERM}
               />
            </Terms>
            <Result>
               {categoryLabels && (
                  <ButtonBarContainer>
                     <SwitchButtonBar
                        activeIndex={activeCategoryIdx}
                        labels={categoryLabels}
                        labelsFn={buttonLabelFn}
                        handleSwitch={setActiveCategoryIdx}
                        buttonWidth={100}
                     />
                  </ButtonBarContainer>
               )}
               <ZoomTable
                  from={timespan.start}
                  to={timespan.end}
                  chosenTerm={activeTerm}
                  termText={DISPLAYED_NAME_OF_TERM[activeTerm]}
                  metric={metric}
                  urlAddon={urlAddon}
                  pathParams={pathParams !== undefined ? pathParams : ''}
                  usageSwitch={usageSwitch}
                  categoryIndex={activeCategoryIdx}
                  settings={settings}
               />
            </Result>
         </ModalBody>
      </>
   );
}

GeneralTemplate.propTypes = {
   title: PropTypes.string,
   urlAddon: PropTypes.string,
   pathParams: PropTypes.string,
   timespan: PropTypes.object,
   metric: PropTypes.string,
   usageSwitch: PropTypes.string,
   initialCategoryIdx: PropTypes.number,
   isWorldmap: PropTypes.bool,
   settings: PropTypes.object,
};

export default GeneralTemplate;
