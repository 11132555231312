export function getUnifiedErrorObject(error) {
   let errorBody = error.response && error.response.data ? error.response.data : undefined;
   let message = error && error.message ? error.message : 'Error occurred';
   message = errorBody && errorBody.error && errorBody.error.message ? errorBody.error.message : message;
   message = errorBody && errorBody.error && errorBody.error.summary ? errorBody.error.summary : message;
   const errorCode = (errorBody && errorBody.error.errorCode) || 104001;
   const errorObject = { message, errorCode };
   if (errorBody) errorObject.errorBody = errorBody;
   return errorObject;
}
