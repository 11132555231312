import React, { Component } from 'react';
import { Container } from 'reactstrap';

import { Redirect, Route, Switch } from 'react-router-dom';

import {
   AppBreadcrumb,
   AppFooter,
   AppHeader,
   AppSidebar,
   AppSidebarFooter,
   AppSidebarForm,
   AppSidebarHeader,
   AppSidebarMinimizer,
   AppSidebarNav,
} from '@coreui/react';

// routes config
import routes from '../routes';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

// sidebar nav config
import { navItems } from '../_nav';
import { HOME_VIEW } from '../../../constants/general';

const WhiteBgColor = { backgroundColor: 'white' };

class DefaultLayout extends Component {
   render() {
      let authorizedRoutes = [];
      let authorizedNavigation = { items: [] };

      const info = JSON.parse(sessionStorage.getItem('userInfo'));

      // Check subscription level for menu items
      for (let i = 0; i < navItems.length; i++) {
         if (info.accessLevel.code >= navItems[i].level) {
            authorizedNavigation.items.push(navItems[i]);
         }
      }

      // Check subscription level for routes
      for (let i = 0; i < routes.length; i++) {
         if (info.accessLevel.code >= routes[i].level) {
            authorizedRoutes.push(routes[i]);
         }
      }

      return (
         <div className='app'>
            <AppHeader fixed>
               <DefaultHeader />
            </AppHeader>
            <div className='app-body'>
               <AppSidebar fixed display='lg'>
                  <AppSidebarHeader />
                  <AppSidebarForm />
                  <AppSidebarNav navConfig={authorizedNavigation} {...this.props} />
                  <AppSidebarFooter />
                  <AppSidebarMinimizer />
               </AppSidebar>
               <main className='main' style={WhiteBgColor}>
                  <AppBreadcrumb appRoutes={routes} />
                  <Container fluid>
                     <Switch>
                        {authorizedRoutes.map((route, idx) => {
                           return route.component ? (
                              <Route
                                 key={idx}
                                 path={route.path}
                                 exact={route.exact}
                                 name={route.name}
                                 render={(props) => <route.component {...props} />}
                              />
                           ) : null;
                        })}
                        <Redirect from='/' to={`/${HOME_VIEW}`} />
                     </Switch>
                  </Container>
               </main>
            </div>
            <AppFooter>
               <DefaultFooter />
            </AppFooter>
         </div>
      );
   }
}

export default DefaultLayout;
