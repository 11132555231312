import styled from 'styled-components';

export const ModalBackground = styled.div`
   position: fixed;
   display: block;
   top: 0;
   left: 0;
   z-index: 999999;
   width: 100%;
   height: 100%;
   overflow: hidden;
   outline: 0;
   background-color: rgba(0, 0, 0, 0.5);
`;
ModalBackground.displayName = 'ModalBackground';

export const ModalDialog = styled.div`
   position: relative;
   width: auto;
   margin: 0.5rem;
   pointer-events: none;
   display: flex;
   flex-direction: column;
   @media (min-width: 576px) {
      margin: 15px 15px;
      max-height: calc(100% - 30px);
   }
   @media (min-width: 900px) {
      margin: 64px 15px;
      max-height: calc(100% - 128px);
   }
   @media (min-width: 1200px) {
      max-width: 80%;
      margin: 64px auto;
      max-height: calc(100% - 128px);
   }
`;
ModalDialog.displayName = 'ModalDialog';

export const ModalContent = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   width: 100%;
   pointer-events: auto;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid rgba(0, 0, 0, 0.2);
   border-radius: 0.3rem;
   outline: 0;
   max-height: calc(100vh - 128px);
   overflow: hidden;
`;
ModalContent.displayName = 'ModalContent';

export const ModalHeader = styled.div`
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   padding: 16px 24px;
   border-bottom: 1px solid #c8ced3;
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   flex-shrink: 0;
`;
ModalHeader.displayName = 'ModalHeader';

export const ModalBody = styled.div`
   position: relative;
   display: block;
   padding: 60px 60px;
   @media (min-width: 992px) {
      display: flex;
      flex: 1 1 auto;
      overflow-y: auto;
      flex-wrap: wrap;
   }
`;
ModalBody.displayName = 'ModalBody';

export const DetailTableBody = styled.div`
   position: relative;
   display: block;
   padding: 60px 60px;
   overflow-y: auto;
`;
ModalBody.displayName = 'DetailTableBody';

export const ModalFooter = styled.div`
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding: 16px 24px;
   border-top: 1px solid #c8ced3;
   border-bottom-right-radius: 0.3rem;
   border-bottom-left-radius: 0.3rem;
   flex-shrink: 0;
`;
ModalBody.displayName = 'ModalFooter';

export const ModalTitle = styled.h4`
   line-height: 44px;
   margin-bottom: 0px;
   color: #666;
   font-weight: 500;
`;
ModalTitle.displayName = 'ModalTitle';

export const ModalTimeRange = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   width: 200px;
   height: 44px;
`;
ModalTimeRange.displayName = 'ModalTimeRange';

export const ModalDateElement = styled.div`
   display: inline-block;
   text-align: right;
   width: ${(props) => props.width};
`;
ModalDateElement.displayName = 'ModalDateElement';

export const Terms = styled.div`
   @media (min-width: 992px) {
      flex: 0 0 150px;
      margin-right: 60px;
      display: flex;
      align-items: center;
      border-left: 1px solid white; // edge browser hovering artefacts workaround
   }
`;
Terms.displayName = 'Terms';

export const Result = styled.div`
   position: relative;
   flex: 1 1 400px;
   min-width: 400px;
   height: 600px;
   border-top: 1px solid white; // edge browser hovering artefacts workaround
   border-left: 1px solid white; // edge browser hovering artefacts workaround
`;
Result.displayName = 'Result';
