import React from 'react';
import { PropTypes } from 'prop-types';

import { getGreenRedScale } from '../LegendScales';
import { HeadLine, Scale, ScaleField, ColoredValue } from '../LegendStyles';

export function GreenRedScale({
   headline,
   numberFormat,
   valueManipulation,
   scaleFieldWidth,
   min,
   max,
   show,
   total,
   scaleFieldsVisible,
}) {
   return (
      <>
         {scaleFieldsVisible && (
            <>
               <HeadLine>{headline}</HeadLine>
               <Scale>
                  {getGreenRedScale({ numberFormat, valueManipulation, min, max, show }).map((field, idx) => (
                     <ScaleField key={`heatScaleField-${idx}`} bgColor={field.color} width={scaleFieldWidth}>
                        {field.value}
                     </ScaleField>
                  ))}
               </Scale>
            </>
         )}
         <HeadLine>{total[0]}</HeadLine>
         <HeadLine>
            <ColoredValue color={'rgba(0, 0, 0, 1)'}>{total[1]}</ColoredValue>
         </HeadLine>
      </>
   );
}

GreenRedScale.propTypes = {
   headline: PropTypes.string,
   numberFormat: PropTypes.func,
   valueManipulation: PropTypes.func,
   scaleFieldWidth: PropTypes.number,
   min: PropTypes.number,
   max: PropTypes.number,
   show: PropTypes.bool,
   total: PropTypes.array,
   scaleFieldsVisible: PropTypes.bool,
};
