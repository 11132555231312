import React, { useCallback, useEffect, useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import {
   BottomMargin,
   CertainWidthContainer,
   Notification,
   TwoSideRow,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../styledComponents/styledComponents';
import DateTimePicker from './DateTimePicker';
import { shortcuts, longTermShortcuts } from './ShortCutSettings';
import CustomButton from '../CustomButton/CustomButton';
import { intervalMap, intervals, monthlyModeintervals } from '../generalConstants';
import { setTimespan } from '../../../redux/actions/general';
import { useDispatch, useSelector } from 'react-redux';
import {
   startTimespanSelector,
   endTimespanSelector,
   intervalTimespanSelector,
   shortcutIdTimespanSelector,
   currentViewSelector,
} from '../../../redux/selectors/selectors';
import { HISTORY_VIEW } from '../../../constants/general';
import { getAbsoluteDateTimespan, getShortcutTimespan, getUrlSyncProps } from './TimespanSetter';
import {
   Content,
   DisplayLineWrapper,
   PickerContentPanel,
   PickerTypeButton,
   PickerTypePanel,
   StyledColumn,
} from './DatePickerStyles';

import { PropTypes } from 'prop-types';
import { DefaultGrey } from '../../../constants/colors';

const QUICK = 'quick';
const ABSOLUTE = 'absolute';

function DatePickerWidget({ showTimeIntervalSelect, maxInputTimeRange }) {
   const dispatch = useDispatch();
   const startMoment = useSelector(startTimespanSelector);
   const endMoment = useSelector(endTimespanSelector);
   const intervalValue = useSelector(intervalTimespanSelector);
   const shortcutId = useSelector(shortcutIdTimespanSelector);

   const [isInitialTriggerActive, triggerInitialization] = useState(false);

   const [quickOptionNeedToBeSetExplicitly, setQuickOptionNeedtoBeSetExplicitlyState] = useState(false);
   const [lastQuickId, setLastQuickId] = useState(0);

   const currentView = useSelector(currentViewSelector);
   const isLongTermShortcuts = currentView === HISTORY_VIEW;
   const availableShortcuts = isLongTermShortcuts ? longTermShortcuts : shortcuts(currentView);
   const selectableIntervals = isLongTermShortcuts ? monthlyModeintervals : intervals;

   const initialPickerType = shortcutId === 0 ? ABSOLUTE : QUICK;

   const [pickerType, setPickerType] = useState(initialPickerType);

   const setShortcutOption = useCallback(
      function (id) {
         const newTimespan = getShortcutTimespan(id, isLongTermShortcuts);
         dispatch(setTimespan(newTimespan, getUrlSyncProps(newTimespan)));
         setQuickOptionNeedtoBeSetExplicitlyState(false);
         setLastQuickId(id);
      },
      [dispatch, isLongTermShortcuts],
   );

   const setAbsoluteDate = useCallback(
      function (start, end, interval) {
         const newTimespan = getAbsoluteDateTimespan(start, end, interval);
         dispatch(setTimespan(newTimespan, getUrlSyncProps(newTimespan)));
      },
      [dispatch],
   );

   useEffect(() => {
      triggerInitialization(true);
   }, []);

   useEffect(() => {
      if (isInitialTriggerActive) {
         if (initialPickerType === QUICK) {
            setShortcutOption(shortcutId);
            setLastQuickId(shortcutId);
         } else {
            setAbsoluteDate(startMoment, endMoment, intervalMap.get(intervalValue));
            setQuickOptionNeedtoBeSetExplicitlyState(true);
         }
         triggerInitialization(false);
      }
   }, [
      isInitialTriggerActive,
      dispatch,
      shortcutId,
      isLongTermShortcuts,
      startMoment,
      endMoment,
      intervalValue,
      initialPickerType,
      setShortcutOption,
      setAbsoluteDate,
   ]);

   return (
      <div style={{ position: 'relative', zIndex: 12 }} className='animated fadeIn'>
         <Row>
            <Col>
               <WidgetContainer>
                  <WidgetHeaderContainer $bgColor={DefaultGrey(1, 247)}>
                     <strong>Time range ( UTC time )</strong>
                  </WidgetHeaderContainer>
                  <WidgetBodyContainer>
                     <TwoSideRow>
                        <PickerTypePanel
                           className='nav flex-column nav-pills'
                           id='v-pills-tab'
                           role='tablist'
                           aria-orientation='vertical'
                        >
                           <BottomMargin pxSize={3}>
                              <PickerTypeButton
                                 className={'nav-link'}
                                 $isActive={pickerType === QUICK}
                                 id='v-pills-quick-tab'
                                 role='tab'
                                 aria-controls='v-pills-quick'
                                 aria-selected='true'
                                 onClick={() => {
                                    setPickerType(QUICK);
                                    if (!quickOptionNeedToBeSetExplicitly) {
                                       setShortcutOption(lastQuickId);
                                    }
                                 }}
                              >
                                 Quick
                              </PickerTypeButton>
                           </BottomMargin>
                           <PickerTypeButton
                              className={'nav-link'}
                              $isActive={pickerType === ABSOLUTE}
                              id='v-pills-absolute-tab'
                              role='tab'
                              aria-controls='v-pills-absolute'
                              aria-selected='true'
                              onClick={() => {
                                 setPickerType(ABSOLUTE);
                                 setAbsoluteDate(startMoment, endMoment, intervalMap.get(intervalValue));
                              }}
                           >
                              Absolute
                           </PickerTypeButton>
                        </PickerTypePanel>
                        <PickerContentPanel>
                           {pickerType === QUICK && (
                              <CertainWidthContainer>
                                 <Content>
                                    {availableShortcuts.map((col, idx) => (
                                       <QuickOptionColumn
                                          key={idx}
                                          quickOptions={col}
                                          activeOption={shortcutId}
                                          setActiveQuickOption={setShortcutOption}
                                       />
                                    ))}
                                 </Content>
                                 <Collapse isOpen={quickOptionNeedToBeSetExplicitly}>
                                    <DisplayLineWrapper $heightPx={15} $alignText={'center'}>
                                       <Notification
                                          $fontSizePx={14}
                                          $fontWeight={500}
                                          $color={'rgba(115,0,0,0.7)'}
                                          className='mt-2'
                                       >
                                          {'Please select a quick option to apply a new time range'}
                                       </Notification>
                                    </DisplayLineWrapper>
                                 </Collapse>
                              </CertainWidthContainer>
                           )}
                           {pickerType === ABSOLUTE && (
                              <DateTimePicker
                                 intervals={showTimeIntervalSelect ? selectableIntervals : undefined}
                                 startDate={startMoment}
                                 endDate={endMoment}
                                 selectedInterval={intervalValue}
                                 onGoButtonPress={({ gte, lt, interval }) => {
                                    setAbsoluteDate(gte, lt, intervalMap.get(interval));
                                    setQuickOptionNeedtoBeSetExplicitlyState(true);
                                 }}
                                 monthlyMode={isLongTermShortcuts}
                                 maxInputTimeRange={maxInputTimeRange}
                                 showIntervalSwitchButtons={true}
                              />
                           )}
                        </PickerContentPanel>
                     </TwoSideRow>
                  </WidgetBodyContainer>
               </WidgetContainer>
            </Col>
         </Row>
      </div>
   );
}

DatePickerWidget.propTypes = {
   showTimeIntervalSelect: PropTypes.bool,
   /** Description of prop "maxInputTimeRange".
    * max duration between start and end (object is used for a moment duration object like 'moment.duration(3, 'days')')
    * for valid units see moment.js doc
    * note: don't use time intervals bigger than 'days' to avoid unexpected results
    */
   maxInputTimeRange: PropTypes.exact({ value: PropTypes.number, unit: PropTypes.string }),
};

function QuickOptionColumn({ quickOptions, activeOption, setActiveQuickOption }) {
   return (
      <StyledColumn>
         {quickOptions.map((option) => (
            <CustomButton
               key={option.name}
               label={option.name}
               name={option.id}
               buttonHeightPx={30}
               buttonWidthPx={150}
               textAlign={'left'}
               textPaddingRightPx={5}
               textPaddingLeftPx={15}
               marginBottomPx={1}
               fontColor={'rgb(32, 168, 216)'}
               borderColor={'rgb(255,255,255)'}
               backgroundColor={'rgb(255,255,255)'}
               fontWeight={400}
               active={option.id === activeOption}
               handleClick={(event) => setActiveQuickOption(+event.target.getAttribute('name'))}
            >
               {option.name}
            </CustomButton>
         ))}
      </StyledColumn>
   );
}

QuickOptionColumn.propTypes = {
   quickOptions: PropTypes.arrayOf(
      PropTypes.exact({
         id: PropTypes.number,
         name: PropTypes.string,
      }),
   ),
   activeOption: PropTypes.number,
   setActiveQuickOption: PropTypes.func,
};

export default DatePickerWidget;
