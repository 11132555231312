import React from 'react';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import BootstrapTable from 'react-bootstrap-table-next';

import { Col, Row } from 'reactstrap';

import { PropTypes } from 'prop-types';
import { requestSettings } from './RequestConstants';
import { format_2_digits_zero_sensitive, roundToGb } from '../../../util/NumberFormatter';

const getColumnsOptions = () => {
   const colSettings = [
      {
         text: 'Name',
         dataField: 'name',
         sort: true,
         align: 'right',
         headerClasses: 'headlessTable',
      },
      {
         text: 'Hash',
         dataField: 'hash',
         sort: true,
         headerClasses: 'headlessTable',
      },
   ];

   requestSettings.forEach((col) => {
      if (col.tableHeaderText) {
         colSettings.push({
            text: col.tableHeaderText,
            dataField: col.shortDesc,
            align: 'center',
            headerClasses: 'headlessTable',
            formatter: roundToGb,
         });
      }
   });

   colSettings.push({
      text: 'Ratio player/ server metrics current month',
      dataField: 'ratios',
      align: 'center',
      headerClasses: 'headlessTable',
      formatter: (col) => format_2_digits_zero_sensitive(col),
   });

   colSettings.push({
      text: 'Created at',
      dataField: 'createdAt',
      align: 'center',
      headerClasses: 'headlessTable',
   });

   colSettings.push({
      text: 'Access level',
      dataField: 'access',
      align: 'center',
      headerClasses: 'headlessTable',
   });

   return colSettings;
};

const columnSettings = getColumnsOptions();

function SelectedOption({ selectedOrga }) {
   return (
      <>
         <Row>
            <Col className='col-3' style={{ marginBottom: 10 }}>
               <strong>Selected Organisation</strong>
            </Col>
         </Row>
         <Row>
            <Col>
               <BootstrapTable
                  // classes='fixed-table'
                  bootstrap4
                  rowClasses={'bg-primary'}
                  bordered={true}
                  columns={columnSettings}
                  keyField='name'
                  data={selectedOrga}
                  id={'selectedOptionTable'}
               />
            </Col>
         </Row>
      </>
   );
}

SelectedOption.propTypes = {
   selectedOrga: PropTypes.array,
};

export default SelectedOption;
