import React, { useContext } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { horizentalBarChartOptions } from '../../../../../constants/chartjs';
import { HoveredRegionContext } from '../../WorldContainer';
import { WorldRegions } from '../../WorldMapConstants';
import { TOP10 } from '../Top10Constants';
import { PropTypes } from 'prop-types';

const colors = {
   [WorldRegions.NORTH_AMERICA]: {
      chartColors: (index, alpha) => (index === 0 ? `rgba(49, 49, 117, ${alpha})` : `rgba(106, 106, 176, ${alpha})`),
   },
   [WorldRegions.SOUTH_AMERICA]: {
      chartColors: (index, alpha) => (index === 0 ? `rgba(115, 115, 67, ${alpha})` : `rgba(209, 209, 142, ${alpha})`),
   },
   [WorldRegions.EUROPE]: {
      chartColors: (index, alpha) => (index === 0 ? `rgba(128, 128, 102, ${alpha})` : `rgba(179, 179, 136, ${alpha})`),
   },
   [WorldRegions.ASIA]: {
      chartColors: (index, alpha) => (index === 0 ? `rgba(64, 115, 115, ${alpha})` : `rgba(145, 204, 204, ${alpha})`),
   },
   [WorldRegions.AFRICA]: {
      chartColors: (index, alpha) => (index === 0 ? `rgba(126, 163, 126, ${alpha})` : `rgba(182, 227, 182, ${alpha})`),
   },
   [WorldRegions.OCEANIA]: {
      chartColors: (index, alpha) => (index === 0 ? `rgba(135, 95, 135, ${alpha})` : `rgba(196, 155, 196, ${alpha})`),
   },
   [WorldRegions.INDIA]: {
      chartColors: (index, alpha) => (index === 0 ? `rgba(156, 121, 87, ${alpha})` : `rgba(209, 175, 142, ${alpha})`),
   },
};

const getColor = (name, datasetIndex, alpha) => {
   return colors[name].chartColors(datasetIndex, alpha);
};

function BarChart(props) {
   const currentMetric = TOP10[props.metric];
   const [hoveredWorldRegion] = useContext(HoveredRegionContext);
   const regionsData = props.data;
   const state = {
      chartData: {
         labels: [],
         datasets: [
            {
               label: currentMetric.metricDesc,
               backgroundColor: [],
               hoverBackgroundColor: [],
               borderColor: '#000000',
               borderWidth: 1,
               data: [],
               xAxisID: 'x-axis-0',
            },
         ],
      },
      sidePropData: {},
   };

   currentMetric.sorting !== undefined
      ? currentMetric.sorting(regionsData)
      : regionsData.sort((a, b) => b.figure - a.figure);

   if (currentMetric.display === 'both') {
      state.chartData.datasets.push({
         label: currentMetric.countDesc,
         backgroundColor: [],
         hoverBackgroundColor: [],
         borderColor: '#000000',
         borderWidth: 1,
         data: [],
         xAxisID: 'x-axis-0',
      });
   }

   for (let i = 0; i < regionsData.length; i++) {
      state.chartData.labels.push(regionsData[i].name);
      state.chartData.datasets[0].data.push(regionsData[i].figure);

      let bgColor;
      bgColor = getColor(regionsData[i].name, 0, 1);

      if (!hoveredWorldRegion) {
         state.chartData.datasets[0].backgroundColor.push(bgColor);
         state.chartData.datasets[0].hoverBackgroundColor.push(bgColor);
      } else {
         if (regionsData[i].name === hoveredWorldRegion) {
            state.chartData.datasets[0].backgroundColor.push(bgColor);
            state.chartData.datasets[0].hoverBackgroundColor.push(bgColor);
         } else {
            state.chartData.datasets[0].backgroundColor.push('#b8b8b8');
            state.chartData.datasets[0].hoverBackgroundColor.push('#b8b8b8');
         }
      }

      // same steps if a second dataset is available

      if (currentMetric.display === 'both') {
         state.chartData.datasets[1].data.push(regionsData[i].count);

         bgColor = getColor(regionsData[i].name, 1, 1);

         if (!hoveredWorldRegion) {
            state.chartData.datasets[1].backgroundColor.push(bgColor);
            state.chartData.datasets[1].hoverBackgroundColor.push(bgColor);
         } else {
            if (regionsData[i].name === hoveredWorldRegion) {
               state.chartData.datasets[1].backgroundColor.push(bgColor);
               state.chartData.datasets[1].hoverBackgroundColor.push(bgColor);
            } else {
               state.chartData.datasets[1].backgroundColor.push('#b8b8b8');
               state.chartData.datasets[1].hoverBackgroundColor.push('#b8b8b8');
            }
         }

         // otherwise pass countProp data into separated var
      } else if (currentMetric.display === 'single') {
         state.sidePropData[regionsData[i].name] = regionsData[i].count;
      }
   }

   const options = horizentalBarChartOptions();
   options.legend = false;
   options.scales.yAxes[0] = {
      stacked: true,
   };
   options.scales.xAxes[0] = {
      type: 'linear',
      stacked: true,
      ticks: {
         beginAtZero: true,
         // callback for customized labels on x axis
         callback: function (label, index, labels) {
            return currentMetric.axisLabel(label);
         },
      },
      position: 'bottom',
      id: 'x-axis-0',
   };
   options.tooltips = {
      // callback for customized labels within the tooltip
      callbacks: {
         label: function (tooltipItem, data) {
            if (currentMetric.display === 'both') {
               if (tooltipItem.datasetIndex === 0)
                  return (
                     data.datasets[tooltipItem.datasetIndex].label + ': ' + currentMetric.metricUnit(tooltipItem.xLabel)
                  );
               else
                  return (
                     data.datasets[tooltipItem.datasetIndex].label + ': ' + currentMetric.countUnit(tooltipItem.xLabel)
                  );
            } else if (currentMetric.display === 'single') {
               return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  ': ' +
                  currentMetric.metricUnit(tooltipItem.xLabel) +
                  ' with ' +
                  currentMetric.countDesc +
                  ': ' +
                  currentMetric.countUnit(state.sidePropData[tooltipItem.label])
               );
            } else {
               return (
                  data.datasets[tooltipItem.datasetIndex].label + ': ' + currentMetric.metricUnit(tooltipItem.xLabel)
               );
            }
         },
      },
   };
   return <HorizontalBar data={state.chartData} options={options} />;
}

BarChart.propTypes = {
   data: PropTypes.arrayOf(
      PropTypes.shape({
         rank: PropTypes.number,
         name: PropTypes.string,
         figure: PropTypes.number,
         count: PropTypes.number,
      }),
   ),
   metric: PropTypes.string,
};

export default BarChart;
