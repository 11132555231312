import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import { Bar } from 'react-chartjs-2';
import { CsvDataExportButton, HorizontalSeparator, Spinner } from '../../../common';
import roundTo from 'round-to';
import numeral from 'numeral';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';

import { CardTitle, Col, Row } from 'reactstrap';

import { PropTypes } from 'prop-types';

import UsageTemplate from '../../../zoom/templates/UsageTemplate';
import { WIDGET_SETTINGS } from '../../../zoom/widgetSettings/HomeView';
import { MONTH2DATE } from '../../../zoom/ZoomConstants';
import ZoomHint from '../../../zoom/ZoomHint';

import { HOME_VIEW } from '../../../../constants/general';
import { barOptionsFactory } from '../../../../constants/chartjs';

import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   RemoveBottomMargin,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';
import styled from 'styled-components';

import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';

import {
   hashSelector,
   cancelTokenSelector,
   streamNamesSelector,
   tagsSelector,
   countriesSelector,
   usageSwitchSelector,
} from '../../../../redux/selectors/selectors';

import ErrorDisplay from '../../ipFiltering/ErrorDisplay';
import DataColumn from './DataColumn';
import { showOverflowTooltip } from '../../../common/OverflowTooltip/OverflowTooltip';
import TrafficHint from '../../../common/Hints/TrafficHint';
import { DefaultGrey } from '../../../../constants/colors';

const VariantHeightRow = styled(Row)`
   margin-bottom: 30px;
   @media (min-width: 1200px) {
      margin-bottom: 0px;
   }
`;

const VariantTopMargin = styled.div`
   margin-top: -70px;
   @media (min-width: 1200px) {
      margin-top: 0px;
   }
`;

const barChartOptions = barOptionsFactory({ legend: false, zoomHint: true });

const USAGE_SETTINGS = {
   bytes: {
      unit: 'GB',
      chartLabelPlayout: 'Playout [GB]',
      chartLabelIngest: 'Ingest [GB]',
      unitConversion: (value) => roundTo(value / 1e9, 0),
      resUnitPropNames: 'bytes',
   },
   playtime: {
      unit: 'h',
      chartLabelPlayout: 'Playout [h]',
      chartLabelIngest: 'Ingest [h]',
      unitConversion: (value) => roundTo(value / 3600, 0),
      resUnitPropNames: 'seconds',
   },
};

const blue = getStyle('--info');
const red = getStyle('--danger');
const gray = getStyle('--secondary');

const columnsSettings = {
   lastMonth: {
      start: (now) => now.clone().startOf('month').subtract(1, 'month'),
      end: (now) => now.clone().startOf('month'),
      label: (start) => `${start.format('MMMM')} (total)`,
      refresh: '',
   },
   lastMonthToDate: {
      start: (now) => now.clone().startOf('month').subtract(1, 'month'),
      end: (now) => now.clone().startOf('hour').subtract(1, 'month'),
      label: (start, end) => `${start.format('D MMM')} - ${end.format('D MMM')} at ${end.format('HH:mm')}`,
      refresh: '&refresh=1h',
   },
   currentMonth: {
      start: (now) => now.clone().startOf('month'),
      end: (now) => now.clone().startOf('hour'),
      label: (start, end) => `${start.format('D MMM')} - ${end.format('D MMM')} at ${end.format('HH:mm')}`,
      refresh: '&refresh=1h',
   },
};

function getTableColumns(usageSwitch) {
   return [
      {
         text: 'Date & Time',
         dataField: 'label',
         style: { verticalAlign: 'middle', textAlign: 'center' },
         headerAlign: 'center',
         formatter: (cell) => showOverflowTooltip(cell),
      },
      {
         text: `Playout [${USAGE_SETTINGS[usageSwitch].unit}]`,
         dataField: 'playout',
         style: { verticalAlign: 'middle', textAlign: 'center' },
         headerAlign: 'center',
         formatter: (cell) => numeral(USAGE_SETTINGS[usageSwitch].unitConversion(cell)).format('0,0'),
      },
      {
         text: `Ingest [${USAGE_SETTINGS[usageSwitch].unit}]`,
         dataField: 'ingest',
         style: { verticalAlign: 'middle', textAlign: 'center' },
         headerAlign: 'center',
         formatter: (cell) => numeral(USAGE_SETTINGS[usageSwitch].unitConversion(cell)).format('0,0'),
      },
      {
         text: `Total [${USAGE_SETTINGS[usageSwitch].unit}]`,
         dataField: 'total',
         style: { verticalAlign: 'middle', textAlign: 'center' },
         headerAlign: 'center',
         formatter: (cell) => numeral(USAGE_SETTINGS[usageSwitch].unitConversion(cell)).format('0,0'),
      },
   ];
}

function defaultChartObject(usageSwitch, labels = [], data1 = [], data2 = []) {
   return {
      labels,
      datasets: [
         {
            label: USAGE_SETTINGS[usageSwitch].chartLabelPlayout,
            backgroundColor: [hexToRgba(gray, 10), hexToRgba(gray, 20), hexToRgba(red, 10)],
            borderColor: [gray, gray, red],
            borderWidth: 1,
            data: data1,
            hidden: false,
         },
         {
            label: USAGE_SETTINGS[usageSwitch].chartLabelIngest,
            backgroundColor: [hexToRgba(gray, 10), hexToRgba(gray, 20), hexToRgba(blue, 10)],
            borderColor: [gray, gray, blue],
            borderWidth: 1,
            data: data2,
            hidden: false,
         },
      ],
   };
}

function addZoomClickHandler(chartOptions, barRef, setModal) {
   return {
      ...chartOptions,
      onClick: (event, item) => {
         if (item.length === 0) {
            return;
         }
         const activePoint = barRef.current.chartInstance.getElementAtEvent(event)[0];
         const datasetIndex = activePoint._datasetIndex;
         const data = activePoint._chart.data;
         const clickedLabel = data.datasets[datasetIndex].label;
         const index = item[0]._index;

         setModal({
            chosenIndex: index,
            toggleShow: true,
            selectedStackSubBar: clickedLabel.split(' ')[0],
         });
      },
   };
}

function getRequiredDates(now) {
   const lastMonthStart = columnsSettings['lastMonth'].start(now);
   const lastMonthToDateStart = columnsSettings['lastMonthToDate'].start(now);
   const currentMonthStart = columnsSettings['currentMonth'].start(now);

   const lastMonthEnd = columnsSettings['lastMonth'].end(now);
   const lastMonthToDateEnd = columnsSettings['lastMonthToDate'].end(now);
   const currentMonthEnd = columnsSettings['currentMonth'].end(now);

   const lastMonthLabel = columnsSettings['lastMonth'].label(lastMonthStart);
   const lastMonthToDateLabel = columnsSettings['lastMonthToDate'].label(lastMonthToDateStart, lastMonthToDateEnd);
   const currentMonthLabel = columnsSettings['currentMonth'].label(currentMonthStart, currentMonthEnd);

   return {
      start: {
         stringDates: {
            lastMonth: lastMonthStart.format(),
            lastMonthToDate: lastMonthToDateStart.format(),
            currentMonth: currentMonthStart.format(),
         },
         timestamps: {
            lastMonth: lastMonthStart.unix(),
            lastMonthToDate: lastMonthToDateStart.unix(),
            currentMonth: currentMonthStart.unix(),
         },
      },
      end: {
         stringDates: {
            lastMonth: lastMonthEnd.format(),
            lastMonthToDate: lastMonthToDateEnd.format(),
            currentMonth: currentMonthEnd.format(),
         },
         timestamps: {
            lastMonth: lastMonthEnd.unix(),
            lastMonthToDate: lastMonthToDateEnd.unix(),
            currentMonth: currentMonthEnd.unix(),
         },
      },
      labels: {
         lastMonthLabel,
         lastMonthToDateLabel,
         currentMonthLabel,
      },
      zoomTimeRanges: [
         { start: lastMonthStart, end: lastMonthEnd },
         { start: lastMonthToDateStart, end: lastMonthToDateEnd },
         { start: currentMonthStart, end: currentMonthEnd },
      ],
   };
}

function createChartData(data, labels, usageSwitch) {
   const unitPropName = USAGE_SETTINGS[usageSwitch].resUnitPropNames;
   const playoutData = [];
   const ingestData = [];
   const labelStrings = [];

   for (let index = 0; index < 3; index++) {
      const dataObj = data[index];
      const label = labels[index];
      const playout = USAGE_SETTINGS[usageSwitch].unitConversion(dataObj.playout[unitPropName]);
      const ingest = USAGE_SETTINGS[usageSwitch].unitConversion(dataObj.ingest[unitPropName]);
      playoutData.push(playout);
      ingestData.push(ingest);
      labelStrings.push(label);
   }
   return defaultChartObject(usageSwitch, labelStrings, playoutData, ingestData);
}

function createTableData(data, labels, usageSwitch) {
   const unitPropName = USAGE_SETTINGS[usageSwitch].resUnitPropNames;
   const tableData = [];
   for (let index = 0; index < 3; index++) {
      const dataObj = data[index];
      const label = labels[index];
      const playout = dataObj.playout[unitPropName];
      const ingest = dataObj.ingest[unitPropName];
      const total = playout + ingest;
      tableData.push({ label, playout, ingest, total });
   }
   return tableData;
}

function getInitialData(timestamp, usageSwitch) {
   return {
      statusCode: 1000,
      statusInfo: 'OK',
      data: [{ ingest: { [usageSwitch]: 0 }, playout: { [usageSwitch]: 0 } }],
   };
}

function MonthToDate({ height }) {
   const now = moment.utc();

   const { start, end, labels, zoomTimeRanges } = getRequiredDates(now);
   const { lastMonth, lastMonthToDate, currentMonth } = start.timestamps;
   const { lastMonthLabel, lastMonthToDateLabel, currentMonthLabel } = labels;

   const usageSwitch = useSelector(usageSwitchSelector);
   const usageSwitchRef = useRef(usageSwitch);
   usageSwitchRef.current = usageSwitch;

   const [dataLastMonth, setDataLastMonth] = useState(getInitialData(lastMonth, usageSwitch));
   const [dataLastMonthToDate, setDataLastMonthToDate] = useState(getInitialData(lastMonthToDate, usageSwitch));
   const [dataCurrentMonth, setDataCurrentMonth] = useState(getInitialData(currentMonth, usageSwitch));

   const [isLoadingLastMonth, setIsLoadingLastMonth] = useState(false);
   const [isLoadingLastMonthToDate, setIsLoadingLastMonthToDate] = useState(false);
   const [isLoadingCurrentMonth, setIsLoadingCurrentMonth] = useState(false);

   const [errorLastMonth, setErrorLastMonth] = useState('');
   const [errorLastMonthToDate, setErrorLastMonthToDate] = useState('');
   const [errorCurrentMonth, setErrorCurrentMonth] = useState('');

   const [chartData, setChartData] = useState(defaultChartObject(usageSwitch));
   const [tableData, setTableData] = useState([]);

   const [error, setError] = useState('');
   const [isLoading, setIsLoadingState] = useState(false);
   const [noData, setNoData] = useState(false);

   useEffect(() => {
      setIsLoadingState(isLoadingLastMonth && isLoadingLastMonthToDate && isLoadingCurrentMonth);
   }, [isLoadingLastMonth, isLoadingLastMonthToDate, isLoadingCurrentMonth]);

   useEffect(() => {
      setError(errorLastMonth && errorLastMonthToDate && errorCurrentMonth ? errorCurrentMonth : '');
   }, [errorLastMonth, errorLastMonthToDate, errorCurrentMonth]);

   useEffect(() => {
      setNoData(
         dataLastMonth.statusCode === 2001 &&
            dataLastMonthToDate.statusCode === 2001 &&
            dataCurrentMonth.statusCode === 2001,
      );
   }, [dataLastMonth, dataLastMonthToDate, dataCurrentMonth]);

   const hash = useSelector(hashSelector);
   const countries = useSelector(countriesSelector);
   const tags = useSelector(tagsSelector);
   const streamNames = useSelector(streamNamesSelector);

   const cancelToken = useSelector(cancelTokenSelector);

   const [modal, setModal] = useState({
      toggleShow: false,
      chosenIndex: 0,
      selectedStackSubBar: WIDGET_SETTINGS[MONTH2DATE].categoryLabels[0],
   });

   const barRef = useRef(null);

   const chartOptions = addZoomClickHandler(barChartOptions, barRef, setModal);

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, [], streamNames, HOME_VIEW);

   useEffect(() => {
      const lastMonthData = dataLastMonth.data[0];
      const lastMonthToDateData = dataLastMonthToDate.data[0];
      const currentMonthData = dataCurrentMonth.data[0];

      const data = [lastMonthData, lastMonthToDateData, currentMonthData];
      const labels = [lastMonthLabel, lastMonthToDateLabel, currentMonthLabel];
      const chartData = createChartData(data, labels, usageSwitchRef.current);
      const tableData = createTableData(data, labels, usageSwitchRef.current);

      setChartData(chartData);
      setTableData(tableData);
   }, [dataLastMonth, dataLastMonthToDate, dataCurrentMonth, lastMonthLabel, lastMonthToDateLabel, currentMonthLabel]);

   const errorOrNoData = error || noData;

   const { toggleShow, chosenIndex, selectedStackSubBar } = modal;
   const chosenTimespan = zoomTimeRanges[chosenIndex];

   const jsonCsvExportData = useMemo(() => {
      const unitPropName = USAGE_SETTINGS[usageSwitch].resUnitPropNames;

      return tableData.map((line, idx) => {
         return {
            start_timestamp: start.timestamps[Object.keys(start.timestamps)[idx]],
            end_timestamp: end.timestamps[Object.keys(end.timestamps)[idx]],
            [`playout_${unitPropName}`]: line.playout,
            [`ingest_${unitPropName}`]: line.ingest,
         };
      });
   }, [start.timestamps, end.timestamps, tableData, usageSwitch]);

   const options = useMemo(() => {
      return {
         start: start.timestamps['lastMonth'],
         end: end.timestamps['currentMonth'],
         usageMetric: usageSwitch,
         countries,
         tags,
         streamNames,
         hash,
      };
   }, [start.timestamps, end.timestamps, usageSwitch, countries, tags, streamNames, hash]);

   return (
      <WidgetContainer>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow>
                     <CsvDataExportButton jsonData={jsonCsvExportData} filename={'Month2Date'} options={options} />
                  </SimpleFlexRow>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <TrafficHint term={'playout or stream'} hasTimeAxis={true} sizePx={16} />
                     </SimpleFlexItem>
                     <HorizontalSeparator
                        color={DefaultGrey(1, 180)}
                        widthInPx={1}
                        heightInPx={18}
                        marginX_InPx={12}
                        marginTopInPx={1}
                     />
                     <SimpleFlexItem>
                        <ZoomHint />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='12' xl='6'>
                  <Row>
                     <Col>
                        <CardTitle className='mb-0'>Month to Date</CardTitle>
                        <div className='small text-muted'>{`${now.format(
                           'MMMM YYYY',
                        )}\u00A0\u00A0\u00A0( UTC time )`}</div>
                     </Col>
                  </Row>
                  <VariantHeightRow>
                     <Col>
                        <ChartWrapper className='chart-wrapper' marginTopPx={25} height={height}>
                           <Spinner loading={isLoading} parentTopMarginPx={70}>
                              {errorOrNoData ? (
                                 <CenteredMsgShowing height={height}>
                                    {error ? (
                                       <ErrorDisplay error={error} msgColor={'rgb(80,80,80)'}></ErrorDisplay>
                                    ) : (
                                       'No data available'
                                    )}
                                 </CenteredMsgShowing>
                              ) : (
                                 <SetChartHeight height={height}>
                                    <Bar ref={barRef} data={chartData} options={chartOptions} height={height} />
                                 </SetChartHeight>
                              )}
                           </Spinner>
                        </ChartWrapper>
                     </Col>
                  </VariantHeightRow>
               </Col>
               <Col xs='12' sm='12' md='12' lg='12' xl='6'>
                  <Row className='pt-3'>
                     <Col>
                        <VariantTopMargin>
                           <ChartWrapper
                              className='chart-wrapper'
                              marginTopPx={54}
                              height={isLoading ? height - 10 : undefined}
                           >
                              <Spinner loading={isLoading} parentTopMarginPx={71.3}>
                                 {errorOrNoData ? (
                                    <CenteredMsgShowing height={height} marginTopPx={-24}>
                                       {error ? (
                                          <ErrorDisplay error={error} msgColor={'rgb(80,80,80)'}></ErrorDisplay>
                                       ) : (
                                          'No data available'
                                       )}
                                    </CenteredMsgShowing>
                                 ) : (
                                    <RemoveBottomMargin>
                                       <BootstrapTable
                                          id={'month2date'}
                                          keyField='label'
                                          data={tableData}
                                          columns={getTableColumns(usageSwitch)}
                                          bootstrap4
                                          bordered={true}
                                          classes='fixed-table'
                                          striped
                                          hover
                                       />
                                    </RemoveBottomMargin>
                                 )}
                              </Spinner>
                           </ChartWrapper>
                        </VariantTopMargin>
                     </Col>
                  </Row>
               </Col>
            </Row>
         </WidgetBodyContainer>
         <DataColumn
            usageMetric={usageSwitch}
            cancelToken={cancelToken}
            header={header}
            urlFilterQuery={urlFilterQuery}
            start={start.stringDates.lastMonth}
            end={end.stringDates.lastMonth}
            refresh={columnsSettings['lastMonth'].refresh}
            notifyCompletedRequest={setDataLastMonth}
            notifyLoading={setIsLoadingLastMonth}
            notifyError={setErrorLastMonth}
         />
         <DataColumn
            usageMetric={usageSwitch}
            cancelToken={cancelToken}
            header={header}
            urlFilterQuery={urlFilterQuery}
            start={start.stringDates.lastMonthToDate}
            end={end.stringDates.lastMonthToDate}
            refresh={columnsSettings['lastMonthToDate'].refresh}
            notifyCompletedRequest={setDataLastMonthToDate}
            notifyLoading={setIsLoadingLastMonthToDate}
            notifyError={setErrorLastMonthToDate}
         />
         <DataColumn
            usageMetric={usageSwitch}
            cancelToken={cancelToken}
            header={header}
            urlFilterQuery={urlFilterQuery}
            start={start.stringDates.currentMonth}
            end={end.stringDates.currentMonth}
            refresh={columnsSettings['currentMonth'].refresh}
            notifyCompletedRequest={setDataCurrentMonth}
            notifyLoading={setIsLoadingCurrentMonth}
            notifyError={setErrorCurrentMonth}
         />
         {toggleShow && (
            <UsageTemplate
               timespan={chosenTimespan}
               metric={MONTH2DATE}
               selectedStackSubBar={selectedStackSubBar}
               closeModal={() => setModal({ ...modal, toggleShow: false })}
            />
         )}
      </WidgetContainer>
   );
}

MonthToDate.propTypes = {
   height: PropTypes.number,
};

export default MonthToDate;
