import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import generalReducer from './generalReducer';

const createRootReducer = (history) =>
   combineReducers({
      router: connectRouter(history),
      general: generalReducer,
   });
export default createRootReducer;
