import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PropTypes } from 'prop-types';

import { setTroubleshootingPublishFineTimespan } from '../../../../redux/actions/general';
import {
   troubleIntervalSelector,
   troubleModeSelector,
   troubleSelectedPublishSelector,
} from '../../../../redux/selectors/selectors';
import { getIntervalEnd, getIntervalStart, getStartEnd } from '../general/DataProcessing';
import DateTimeSlider from '../general/DateTimeSlider';

import { CenterContainer, DefaultInfo, SimpleHorizontalSeparator } from '../../../../styledComponents/styledComponents';
import SwitchButtons from '../../../common/SwitchButtons/SwitchButtons';
import RtmpMetrics from './widgets/RtmpMetrics';
import WebrtcMetrics from './widgets/WebrtcMetrics';
import { DefaultBlue, DefaultGrey } from '../../../../constants/colors';

const RTMP_METRICS = 'RTMP_METRICS';
const WEBRTC_METRICS = 'WEBRTC_METRICS';

function IngestMetrics({ streamName, webrtcMetricsAvailable, rtmpMetricsAvailable, protocol, drops }) {
   const dispatch = useDispatch();
   const mode = useSelector(troubleModeSelector);
   const { publishStart, publishEnd, publishFineStart, publishFineEnd } = useSelector(troubleSelectedPublishSelector);
   const interval = useSelector(troubleIntervalSelector);
   const { start, end } = getStartEnd(publishFineStart, publishFineEnd, interval);
   const setFineInterval = useCallback(
      (fineInterval) => {
         const newIngestFineInterval = {
            publishFineStart: fineInterval.fineStart,
            publishFineEnd: fineInterval.fineEnd,
         };
         dispatch(setTroubleshootingPublishFineTimespan(newIngestFineInterval, newIngestFineInterval));
      },
      [dispatch],
   );

   const [activeProtocol, setActiveProtocol] = useState(RTMP_METRICS);
   console.log('activeProtocol => ', activeProtocol);

   const showMetricSwitch = protocol === 'WebRTC' && webrtcMetricsAvailable;
   // console.log('webrtcMetricsAvailable => ', webrtcMetricsAvailable);
   // console.log('protocol => ', protocol);

   const setProtocol = (e) => {
      const selectedOption = e.target.getAttribute('name');
      if (selectedOption !== activeProtocol) {
         setActiveProtocol(selectedOption);
      }
   };

   return (
      <>
         {!webrtcMetricsAvailable && !rtmpMetricsAvailable ? (
            <DefaultInfo>No metrics available</DefaultInfo>
         ) : (
            <>
               {publishFineStart !== '' && publishFineEnd !== '' && (
                  <>
                     <SimpleHorizontalSeparator spacePx={20} border={'1px dotted  rgb(180,180,180)'} />
                     <DateTimeSlider
                        startDateString={getIntervalStart(publishStart, interval.value)}
                        endDateString={getIntervalEnd(publishEnd, interval.value)}
                        fineStartString={publishFineStart}
                        fineEndString={publishFineEnd}
                        setFineInterval={setFineInterval}
                        interval={interval}
                     />
                     {showMetricSwitch ? (
                        <>
                           <CenterContainer marginTopPx={20}>
                              <SwitchButtons
                                 leftActive={activeProtocol === RTMP_METRICS}
                                 leftLabel={'RTMP metrics'}
                                 rightLabel={'WebRTC metrics'}
                                 leftName={RTMP_METRICS}
                                 rightName={WEBRTC_METRICS}
                                 handleSwitch={setProtocol}
                                 activeBackgroundColor={DefaultBlue()}
                                 inactiveBackgroundColor={DefaultGrey()}
                                 activeFontColor={'#FFF'}
                                 inactiveFontColor={'#000'}
                                 buttonWidth={100}
                                 buttonHeight={25}
                                 fontSize={12}
                              />
                           </CenterContainer>
                           {activeProtocol === RTMP_METRICS ? (
                              <RtmpMetrics start={start} end={end} mode={mode} streamName={streamName} drops={drops} />
                           ) : (
                              <WebrtcMetrics
                                 start={start}
                                 end={end}
                                 mode={mode}
                                 streamName={streamName}
                                 drops={drops}
                              />
                           )}
                        </>
                     ) : (
                        <RtmpMetrics start={start} end={end} mode={mode} streamName={streamName} drops={drops} />
                     )}
                  </>
               )}
            </>
         )}
      </>
   );
}

IngestMetrics.propTypes = {
   streamName: PropTypes.string,
   webrtcMetricsAvailable: PropTypes.bool,
   rtmpMetricsAvailable: PropTypes.bool,
   protocol: PropTypes.string,
   drops: PropTypes.object,
};

export default IngestMetrics;
