import moment from 'moment';
import numeral from 'numeral';
import { getOptions } from '../util/UtilFunctions';

function addThousandsSeparator(value) {
   return numeral(value).format('0,0.[0000]');
}

const ticksThousandsSeparator = {
   beginAtZero: true,
   userCallback: addThousandsSeparator,
};

const defaultLineOptions = {
   xAxes: false,
   yAxesStacked: false,
   forRatio: false,
   decimalSeparator: true,
   zoomHint: false,
};

function lineOptionsFactory(settings = defaultLineOptions) {
   const { xAxes, yAxesStacked, forRatio, decimalSeparator, zoomHint } = getOptions(defaultLineOptions, settings);

   const lineOptions = {
      plugins: {
         datalabels: false,
      },
      maintainAspectRatio: false,
      legend: {
         position: 'bottom',
         labels: {
            boxWidth: 1,
         },
      },
      scales: {
         yAxes: [
            {
               ticks: ticksThousandsSeparator,
               stacked: yAxesStacked,
            },
         ],
      },
      elements: {
         point: {
            radius: 2,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
         },
      },
      tooltips: {
         mode: 'point',
      },
   };

   if (xAxes) {
      lineOptions.scales.xAxes = [{ stacked: true }];
   }

   if (forRatio) {
      lineOptions.scales.yAxes[0].ticks = {
         beginAtZero: true,
         steps: 10,
         stepValue: 5,
         max: 100,
      };
   }

   if (decimalSeparator) {
      if (lineOptions.tooltips.callbacks === undefined) lineOptions.tooltips.callbacks = {};
      lineOptions.tooltips.callbacks = {
         ...lineOptions.tooltips.callbacks,
         label: function (tooltipItem, data) {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${addThousandsSeparator(tooltipItem.yLabel)}`;
         },
      };
   }

   if (zoomHint) {
      if (lineOptions.tooltips.callbacks === undefined) lineOptions.tooltips.callbacks = {};
      lineOptions.tooltips.callbacks = {
         ...lineOptions.tooltips.callbacks,
         footer: function (tooltipItem, data) {
            return ' ';
         },
         afterFooter: function (tooltipItem, data) {
            return 'Click to get more details!';
         },
      };
   }

   return lineOptions;
}

export const lineChartOptions = () => lineOptionsFactory();
export const lineChartRatioZoomOptions = () => lineOptionsFactory({ forRatio: true, zoomHint: true });
export const lineChartWithXaxisYStackedOptions = () => lineOptionsFactory({ xAxes: true, yAxesStacked: true });
export const lineChartZoomOptions = () => lineOptionsFactory({ zoomHint: true });

const defaultPieOptions = {
   decimalSeparator: true,
   useOnlyTooltipLabel: false,
   zoomHint: false,
};

function pieOptionsFactory(settings = defaultPieOptions) {
   const { decimalSeparator, useOnlyTooltipLabel, zoomHint } = getOptions(defaultPieOptions, settings);
   const options = {
      plugins: {
         datalabels: false,
      },
      maintainAspectRatio: false,
      legend: {
         position: 'right',
         labels: {
            boxWidth: 6,
         },
      },
      tooltips: {
         mode: 'point',
      },
   };

   if (decimalSeparator || useOnlyTooltipLabel) {
      if (options.tooltips.callbacks === undefined) options.tooltips.callbacks = {};
      options.tooltips.callbacks = {
         ...options.tooltips.callbacks,
         label: function (tooltipItem, data) {
            const setIdx = tooltipItem.datasetIndex;
            const idx = tooltipItem.index;
            const value = useOnlyTooltipLabel ? `: ${addThousandsSeparator(data.datasets[setIdx].data[idx])}` : '';
            return `${data.labels[idx]}${value}`;
         },
      };
   }

   if (zoomHint) {
      if (options.tooltips.callbacks === undefined) options.tooltips.callbacks = {};
      options.tooltips.callbacks = {
         ...options.tooltips.callbacks,
         footer: function (tooltipItem, data) {
            return ' ';
         },
         afterFooter: function (tooltipItem, data) {
            return 'Click to get more details!';
         },
      };
   }

   return options;
}

export const pieChartOptions = (decimalSeparator = true) => pieOptionsFactory({ decimalSeparator });
export const pieChartOptionsWithOnlyTooltipLabel = () =>
   pieOptionsFactory({ decimalSeparator: false, useOnlyTooltipLabel: true });
export const pieZoomChartOptions = (decimalSeparator = true) => pieOptionsFactory({ decimalSeparator, zoomHint: true });

const defaultBarOptions = {
   switchAxis: false,
   stacked: true,
   zoomHint: false,
   legend: true,
   decimalSeparator: true,
   showPiTotal: false,
};

export function barOptionsFactory(settings = defaultBarOptions) {
   const { switchAxis, stacked, zoomHint, legend, decimalSeparator, showPiTotal } = getOptions(
      defaultBarOptions,
      settings,
   );

   const options = {
      plugins: {
         datalabels: false,
      },
      maintainAspectRatio: false,
      legend: {
         display: legend,
         position: 'bottom',
         labels: {
            boxWidth: 1,
         },
      },
      scales: {
         xAxes: [
            {
               stacked,
            },
         ],
         yAxes: [
            {
               stacked,
               ticks: {
                  beginAtZero: true,
               },
            },
         ],
      },
      elements: {
         point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
         },
      },
      tooltips: {
         mode: 'point',
      },
   };

   if (switchAxis) {
      options.scales.xAxes[0].ticks = ticksThousandsSeparator;
   } else {
      options.scales.yAxes[0].ticks = ticksThousandsSeparator;
   }

   if (zoomHint) {
      if (options.tooltips.callbacks === undefined) options.tooltips.callbacks = {};
      options.tooltips.callbacks = {
         ...options.tooltips.callbacks,
         footer: function (tooltipItem, data) {
            return ' ';
         },
         afterFooter: function (tooltipItem, data) {
            return 'Click to get more details!';
         },
      };
   }

   if (decimalSeparator) {
      if (options.tooltips.callbacks === undefined) options.tooltips.callbacks = {};
      options.tooltips.callbacks = {
         ...options.tooltips.callbacks,
         label: function (tooltipItem, data) {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${addThousandsSeparator(
               switchAxis ? tooltipItem.value : tooltipItem.yLabel,
            )}`;
         },
      };
   }

   if (showPiTotal) {
      if (options.tooltips.callbacks === undefined) options.tooltips.callbacks = {};
      options.tooltips.callbacks = {
         ...options.tooltips.callbacks,
         footer: function () {
            return ' ';
         },
         afterFooter: function (tooltipItem, data) {
            if (data.datasets.length !== 2) return '';
            const idx = tooltipItem[0].index;
            return `Total [GB]: ${addThousandsSeparator(data.datasets[0].data[idx] + data.datasets[1].data[idx])}`;
         },
      };
   }

   return options;
}

export const barChartOptions = () => barOptionsFactory();
export const barChartWithoutLegendOptions = () => barOptionsFactory({ legend: false });
export const horizentalBarChartOptions = () => barOptionsFactory({ switchAxis: true });
export const horizentalBarChartZoomOptions = () => barOptionsFactory({ switchAxis: true, zoomHint: true });
export const barChartZoomOptions = () => barOptionsFactory({ zoomHint: true });
export const unstackedHorizentalBarChartOptions = () => barOptionsFactory({ switchAxis: true, stacked: false });
export const historicalUsageBarChartOptions = () => barOptionsFactory({ showPiTotal: true });

const defaultTimeAxisOptions = {
   isBarChart: false,
   switchAxis: false,
   stacked: true,
   zoomHint: false,
   legend: true,
   decimalSeparator: true,
};

function timeAxisOptionsFactory(unit, format, settings = defaultTimeAxisOptions) {
   const { switchAxis, stacked, zoomHint, legend, decimalSeparator, isBarChart } = getOptions(
      defaultTimeAxisOptions,
      settings,
   );

   const options = {
      plugins: {
         datalabels: false,
      },
      maintainAspectRatio: false,
      legend: {
         display: legend,
         position: 'bottom',
         labels: {
            boxWidth: 1,
         },
      },
      scales: {
         xAxes: [
            {
               offset: isBarChart ? true : false,
               type: 'time',
               time: {
                  parser: utcToLocal,
                  isoWeekday: true,
                  unit,
                  displayFormats: {
                     minute: format,
                     hour: format,
                     day: format,
                     week: format,
                     month: format,
                     year: format,
                  },
                  tooltipFormat: timeFormat[unit],
               },
               gridLines: {
                  offsetGridLines: isBarChart ? true : false,
               },
            },
         ],
         yAxes: [
            {
               stacked,
               ticks: {
                  beginAtZero: true,
               },
            },
         ],
      },
      tooltips: {
         mode: 'point',
      },
   };

   if (switchAxis) {
      options.scales.xAxes[0].ticks = ticksThousandsSeparator;
   } else {
      options.scales.yAxes[0].ticks = ticksThousandsSeparator;
   }

   if (zoomHint) {
      if (options.tooltips.callbacks === undefined) options.tooltips.callbacks = {};
      options.tooltips.callbacks = {
         ...options.tooltips.callbacks,
         footer: function (tooltipItem, data) {
            return ' ';
         },
         afterFooter: function (tooltipItem, data) {
            return 'Click to get more details!';
         },
      };
   }

   if (decimalSeparator) {
      if (options.tooltips.callbacks === undefined) options.tooltips.callbacks = {};
      options.tooltips.callbacks = {
         ...options.tooltips.callbacks,
         label: function (tooltipItem, data) {
            return `${data.datasets[0].label}: ${addThousandsSeparator(tooltipItem.yLabel)}`;
         },
      };
   }

   return options;
}

export const timeAxisBarChartOptions = (unit, format) => timeAxisOptionsFactory(unit, format, { isBarChart: true });
export const timeAxisZoomBarChartOptions = (unit, format) =>
   timeAxisOptionsFactory(unit, format, { isBarChart: true, zoomHint: true });

export const AbrHorizontalBarChartOptions = {
   plugins: {
      datalabels: false,
   },
   responsive: true,
   maintainAspectRatio: true,
   legend: {
      position: 'right',
      labels: {
         boxWidth: 6,
         fontColor: 'rgba(255, 255, 255, 0.95)',
      },
   },
   scales: {
      xAxes: [
         {
            ticks: {
               beginAtZero: true,
               fontColor: 'rgba(255, 255, 255, 0.95)',
            },
            gridLines: {
               color: 'rgba(255, 255, 255, 0.95)',
            },
            scaleLabel: {
               fontColor: 'rgba(255, 255, 255, 0.95)',
               display: true,
            },
         },
      ],
      yAxes: [
         {
            ticks: {
               beginAtZero: true,
               fontColor: 'rgba(255, 255, 255, 0.95)',
            },
            gridLines: {
               color: 'rgba(255, 255, 255, 0.95)',
            },
            scaleLabel: {
               fontColor: 'rgba(255, 255, 255, 0.95)',
            },
         },
      ],
   },
   animation: {
      duration: 0,
   },
   hover: {
      animationDuration: 0,
   },
   responsiveAnimationDuration: 0,
};

export const AbrPieBarChartOptions = {
   plugins: {
      datalabels: false,
   },
   responsive: true,
   maintainAspectRatio: true,
   legend: {
      position: 'right',
      labels: {
         boxWidth: 6,
         fontColor: 'rgba(255, 255, 255, 0.95)',
      },
   },
   animation: {
      duration: 0,
   },
   hover: {
      animationDuration: 0,
   },
   responsiveAnimationDuration: 0,
};

export const QoePieBarChartOptions = {
   plugins: {
      datalabels: false,
   },
   responsive: true,
   maintainAspectRatio: false,
   legend: {
      display: 'false',
   },
   animation: {
      duration: 0,
   },
   events: [],
   responsiveAnimationDuration: 0,
   cutoutPercentage: 50,
};

export const chartjsColors = [
   '#20a8d8',
   '#f8cb00',
   '#e83e8c',
   '#20c997',
   '#6f42c1',
   '#FF123A',
   '#4dbd74',
   '#FF8F12',
   '#D1E81E',
   '#F500A0',
   '#69E854',
   '#17a2b8',
   '#f86c6b',
   '#E8D684',
   '#FF8A5B',
   '#DD5CFF',
   '#549FE8',
   '#FF3E2A',
   '#6940FF',
   '#E854B3',
   '#63c2de',
   '#6610f2',
   '#2161F5',
   '#ffc107',
   '#FF7D1C',
];

// Troubleshooting chart options

export function aggEventChartOptions(xMin, xMax, toolTipText, stepSize, displayDetailsFn) {
   const xAxes = getTimeXaxes(xMin, xMax, stepSize);
   const yAxes = defaultYaxes();
   const toolTip = eventExtendedToolTip(toolTipText, true, displayDetailsFn);
   return troubleShootingChartOptions(xAxes, yAxes, toolTip);
}

export function rawEventChartOptions(xMin, xMax, toolTipText, stepSize) {
   const xAxes = getTimeXaxes(xMin, xMax, stepSize);
   const yAxes = defaultYaxes();
   const toolTip = eventExtendedToolTip(toolTipText, false);
   return troubleShootingChartOptions(xAxes, yAxes, toolTip);
}

export function bufferingEventTimeChartOptions(xMin, xMax, toolTipText, stepSize) {
   const xAxes = getTimeXaxes(xMin, xMax, stepSize);
   const yAxes = binaryCategoryYaxes('No', 'Yes');
   const toolTip = bufferingToolTip();
   return troubleShootingChartOptions(xAxes, yAxes, toolTip);
}

function troubleShootingChartOptions(xAxes, yAxes, toolTipCallback) {
   return {
      maintainAspectRatio: false,
      legend: {
         position: 'bottom',
         labels: {
            boxWidth: 1,
         },
      },
      scales: {
         xAxes,
         yAxes,
      },
      elements: {
         point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
         },
      },
      tooltips: toolTipCallback,
   };
}

function getTimeXaxes(xMin, xMax, stepSize) {
   return [
      {
         ticks: {
            min: xMin,
            max: xMax,
         },
         type: 'time',
         time: {
            parser: (value) => utcToLocal(value),
            unit: 'second',
            stepSize,
            displayFormats: {
               second: 'HH:mm:ss',
            },
            tooltipFormat: 'MMM DD, YYYY HH:mm:ss',
         },
      },
   ];
}

function binaryCategoryYaxes(category1, category2) {
   return [
      {
         ticks: {
            beginAtZero: true,
            stepSize: 1,
            callback: (value) => (value === 0 ? category1 : category2),
         },
      },
   ];
}

function defaultYaxes() {
   return [
      {
         ticks: ticksThousandsSeparator,
         stacked: false,
      },
   ];
}

function bufferingToolTip() {
   return {
      callbacks: {
         label: function (tooltipItem) {
            const bufferingValue = tooltipItem.value;
            return bufferingValue === '0' ? 'No Buffering' : 'Buffering';
         },
      },
   };
}

function eventExtendedToolTip(toolTipText, showBeforeTitle, displayDetailsFn = (details) => details) {
   return {
      callbacks: {
         label: function (tooltipItem, data) {
            const currentIdx = tooltipItem.index;
            const datasetIdx = tooltipItem.datasetIndex;
            const details = data.datasets[datasetIdx].data[currentIdx].info;
            if (details !== undefined) {
               return displayDetailsFn(details);
            } else {
               return `${toolTipText}: ${tooltipItem.yLabel}`;
            }
         },
         beforeTitle: function (tooltipItems, data) {
            if (showBeforeTitle) {
               return tooltipItems && tooltipItems.length > 0 ? tooltipItems[0].label : '';
            } else {
               return '';
            }
         },
      },
   };
}

function utcToLocal(unixMilli) {
   // if (isNaN(unixMilli)) {
   //    console.log('Chartjs should get unix milli values but got: ', unixMilli);
   // }
   const localMoment = moment(unixMilli);
   const utcOffsetInMinutes = localMoment.utcOffset();
   let localDate = localMoment.clone();
   if (utcOffsetInMinutes !== 0) localDate = localMoment.clone().subtract(utcOffsetInMinutes, 'minutes');
   return moment(localDate);
}

export function getAnnotation(
   value,
   label,
   color,
   counter,
   scaleID,
   options = { backgroundColor: undefined, enterHandler: undefined, leaveHandler: undefined },
) {
   const annotation = {
      drawTime: 'afterDatasetsDraw',
      id: `marker-${counter}`,
      type: 'line',
      // mode: 'horizontal',
      scaleID,
      value,
      borderColor: color,
      borderWidth: 1,
      label: {
         backgroundColor: options.backgroundColor || 'rgba(0,0,0, 0.5)',
         content: label,
         enabled: true,
         yAdjust: getYposition(counter),
      },
   };

   // does not work, see function getOptionsWithAnnotations

   // if (options.enterHandler) {
   //    annotation.enter = options.enterHandler;
   // }
   // if (options.leaveHandler) {
   //    annotation.leave = options.leaveHandler;
   // }
   return annotation;
}

export function getYposition(counter) {
   const maxCount = 5;
   const modulo = counter % maxCount;
   return -25 * modulo + 50;
}

export function getOptionsWithAnnotations(options, annotations) {
   options.annotation = {
      // need to register events you want to use for annotations
      // but does not work with following versions:

      // "chart.js": "^2.8.0",
      // "chartjs-plugin-annotation": "^0.5.7",

      // events: ['click'],
      annotations,
   };
   return options;
}

const timeFormat = {
   second: 'MMM DD, YYYY HH:mm:ss',
   minute: 'MMM DD, YYYY HH:mm',
   hour: 'MMM DD, YYYY [hour] HH',
   day: 'MMM DD, YYYY',
   week: '[Week] WW, YYYY',
   month: 'MMM, YYYY',
   year: 'YYYY',
};
