import React from 'react';
import numeral from 'numeral';

import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { PropTypes } from 'prop-types';

const tableStyle = {
   verticalAlign: 'middle',
   textAlign: 'center',
   color: '#FFF',
   fontSize: '12px',
   borderColor: 'rgba(0,0,0,0)',
   padding: '0px',
};

const columnsHeader = [
   {
      dataField: 'profile',
      text: 'Profile',
      style: tableStyle,
      headerStyle: { ...tableStyle, width: '59px' },
   },
   {
      dataField: 'mobile',
      text: 'Mobile',
      headerStyle: { ...tableStyle },
   },
   {
      dataField: 'desktop',
      text: 'Desktop',
      headerStyle: { ...tableStyle },
   },
];

const columnsBody = [
   {
      dataField: 'profile',
      text: '',
      style: tableStyle,
      headerAlign: 'center',
      headerStyle: { ...tableStyle, width: '59px' },
   },
   {
      dataField: 'mobile',
      text: '',
      style: {
         ...tableStyle,
         textAlign: 'right',
         paddingRight: '5px',
         borderRight: '1px solid rgba(255, 255, 255, 0.95)',
      },
      formatter: (cell, row) => adaptValuesToChart(cell, row.max),
      headerAlign: 'right',
      headerStyle: { ...tableStyle, textAlign: 'right' },
   },
   {
      dataField: 'mobilePercent',
      text: '',
      style: { ...tableStyle, textAlign: 'left', paddingLeft: '4px' },
      formatter: (cell, row) => `${cell.toFixed(1)} %`,
      headerAlign: 'left',
      headerStyle: { ...tableStyle, textAlign: 'left' },
   },
   {
      dataField: 'desktop',
      text: '',
      style: {
         ...tableStyle,
         textAlign: 'right',
         paddingRight: '5px',
         borderRight: '1px solid rgba(255, 255, 255, 0.95)',
      },
      formatter: (cell, row) => adaptValuesToChart(cell, row.max),
      headerAlign: 'right',
      headerStyle: { ...tableStyle, textAlign: 'right' },
   },
   {
      dataField: 'desktopPercent',
      text: '',
      style: { ...tableStyle, textAlign: 'left', paddingLeft: '4px' },
      formatter: (cell, row) => `${cell.toFixed(1)} %`,
      headerAlign: 'left',
      headerStyle: { ...tableStyle, textAlign: 'left' },
   },
];

const MINIMUM_SHARE_IN_PERCENT = 1;

function adaptValuesToChart(value, maxValue) {
   let output = value;
   let unit = 's';

   if (maxValue > 36000) {
      output = Math.round(output / 3600);
      unit = 'h';
   } else if (maxValue > 3600) {
      output = Math.round(output / 60);
      unit = 'min';
   }

   return `${numeral(output).format('0,0')} ${unit}`;
}

function createTableData(data) {
   const { total, table } = data;
   return table.filter((tableRow) => ((tableRow.mobile + tableRow.desktop) * 100) / total > MINIMUM_SHARE_IN_PERCENT);
}

function AbrCompareTable({ data }) {
   return (
      <>
         <BootstrapTable keyField='profile' data={[]} columns={columnsHeader} classes='head-abr-table' bootstrap4 />
         <BootstrapTable
            keyField='profile'
            data={createTableData(data)}
            columns={columnsBody}
            classes='headless-abr-table'
            bootstrap4
         />
      </>
   );
}

AbrCompareTable.propTypes = {
   data: PropTypes.shape({
      alpha: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      geometry: PropTypes.object,
      coor: PropTypes.arrayOf(PropTypes.number),
      regionName: PropTypes.string,
      rid: PropTypes.number,
      tableRow: PropTypes.shape({
         mobile: PropTypes.number,
         desktop: PropTypes.number,
         mobilePercent: PropTypes.number,
         desktopPercent: PropTypes.number,
         profile: PropTypes.string,
         totalCount: PropTypes.number,
         maximumValue: PropTypes.number,
      }),
      count: PropTypes.any,
   }),
};

export default AbrCompareTable;
