import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { troubleModeSelector, troubleTimespanSelector } from './../../../redux/selectors/selectors';

import DateTimePicker from '../../common/DateTimePicker/DateTimePicker';

import SwitchButtonBar from './../../common/SwitchButtonBar/SwitchButtonBar';
import {
   E2E,
   INGEST,
   NORMAL_TIME_FORMAT,
   PLAYOUT,
   SIMPLE_DATE_FORMAT,
   TROUBLESHOOTING_VIEW,
} from '../../../constants/general';
import { setTroubleshootingTimespan, setTroubleshootingMode } from '../../../redux/actions/general';
import { TROUBLE_INTERVALS, TROUBLE_INTERVALS_MAP } from './constants';
import {
   StyledButton,
   BottomMargin,
   ButtonBarContainer,
   CenterContainer,
   CustomCardBody,
} from '../../../styledComponents/styledComponents';
import { MAX_DURATION_PER_VIEW } from '../../../constants/timeConstraints';

const modes = {
   labels: ['Playout', 'End to End', 'Ingest'],
   propNames: [PLAYOUT, E2E, INGEST],
};

const MAX_TIME_RANGE_FOR_INTERVAL = {}; // no restrictions

function MainInputs() {
   const { start, end, troubleInterval } = useSelector(troubleTimespanSelector);
   const mode = useSelector(troubleModeSelector);
   const modeRef = useRef(mode);
   const dispatch = useDispatch();

   const [showCopied, setShowCopiedState] = useState(false);

   // activates URL sync
   useEffect(() => {
      dispatch(setTroubleshootingMode(modeRef.current, { troubleMode: modeRef.current }));
   }, [dispatch]);

   return (
      <CustomCardBody $borderBottom={'1px solid rgb(220,220,220)'}>
         <BottomMargin pxSize={15} />
         <BottomMargin pxSize={10}>
            <DateTimePicker
               intervals={TROUBLE_INTERVALS}
               startDate={start}
               endDate={end}
               selectedInterval={troubleInterval.value}
               dateFormat={SIMPLE_DATE_FORMAT}
               timeFormat={NORMAL_TIME_FORMAT}
               onValidDateChange={({ gte, lt, interval }) => {
                  const newTimespan = {
                     start: gte,
                     end: lt,
                     troubleInterval: TROUBLE_INTERVALS_MAP[interval],
                  };
                  dispatch(setTroubleshootingTimespan(newTimespan, newTimespan));
               }}
               setCustomMaxTimeRangesForIntervals={MAX_TIME_RANGE_FOR_INTERVAL}
               maxInputTimeRange={MAX_DURATION_PER_VIEW[TROUBLESHOOTING_VIEW]}
            />
         </BottomMargin>
         <BottomMargin pxSize={25}>
            <ButtonBarContainer>
               <SwitchButtonBar
                  activePropName={mode}
                  propNames={modes.propNames}
                  labels={modes.labels}
                  handleSwitch={(activeMode) =>
                     dispatch(setTroubleshootingMode(activeMode, { troubleMode: activeMode }))
                  }
                  buttonWidth={100}
                  inactiveColor={'rgb(210, 210, 210)'}
               />
            </ButtonBarContainer>
         </BottomMargin>
         <BottomMargin pxSize={0}>
            <CenterContainer>
               <StyledButton
                  type='button'
                  fontSizePx={10}
                  className={'btn'}
                  fixMargin
                  name={'urlCopy'}
                  id={'urlCopy'}
                  heightPx={20}
                  widthPx={100}
                  bgColor={'rgb(120,120,120)'}
                  fontColor={'#FFF'}
                  bgColorHover={'rgb(100,100,100)'}
                  fontColorHover={'#FFF'}
                  clickBgColor={'rgb(20, 168, 107)'}
                  showClick={showCopied}
                  onClick={() => {
                     navigator.clipboard.writeText(window.location.href);
                     setShowCopiedState(true);
                     setTimeout(() => setShowCopiedState(false), 750);
                  }}
               >
                  {showCopied ? (
                     <>
                        <span>Copied </span>
                        <span>&#x2713;</span>
                     </>
                  ) : (
                     'Copy URL to share'
                  )}
               </StyledButton>
            </CenterContainer>
         </BottomMargin>
      </CustomCardBody>
   );
}

export default MainInputs;
