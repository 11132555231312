import { WORLDMAP_METRIC, PI_OPTIONS } from '../../widgets/world/WorldMapConstants';

import {
   format_time_Number,
   getIntegerWithDecimalSeparator,
   roundUsageWith5Digits,
} from '../../../util/NumberFormatter';
import { GREEN_TO_RED_10_STEPS_HSL } from '../../../constants/colors';
import { getSwitchLabel } from '../../widgets/world/DataCircle/ContentTypes/AbrSubComponents/AbrSwitchesLabels';
import numeral from 'numeral';
import { BINTU_ACCOUNTING, METRICS_PLAYER } from '../ZoomConstants';
import { STREAM } from '../../../constants/dataGroup';
import { getHeatMapColors } from '../../widgets/world/Common/tools/ValueColorizer';

const abrCellStyleFn = (scaleMaxValue) => {
   return function (cell) {
      const backgroundColor = getHeatMapColors(cell, scaleMaxValue, 0.5, true);
      return {
         verticalAlign: 'middle',
         textAlign: 'center',
         backgroundColor,
      };
   };
};

const qoeCellStyleFn = (cell) => {
   const cellValue = isNaN(cell) ? 9 : cell;
   const valueRangeMoved = cellValue === 0 ? 0 : cellValue - 1;
   const colorIdx = 9 - Math.floor(valueRangeMoved / 10);
   const [h, s, l] = GREEN_TO_RED_10_STEPS_HSL[colorIdx];
   const opacity = 0.3;
   return {
      verticalAlign: 'middle',
      textAlign: 'center',
      backgroundColor: `hsla(${h}, ${s}%, ${l}%,${opacity})`,
   };
};

const defaultStyle = { verticalAlign: 'middle', textAlign: 'center' };

// NOTE: order of props e.g. in columnNames must be aligned with order of props in API response object array!
export const WIDGET_SETTINGS = {
   // scheme:
   //    title = [function] provide name
   //    exIndexName = [string] used ES index (break down option availability)
   //    path = [string] metric specific API route path
   //    categoryLabels = [Array<string>] name of the categories (upper button bar)
   //    buttonLabelFn = [function] just for ABR switches metric, fn for transforming button bar names
   //    columnNames = [object] or [function]
   //             [object] key is metric prop of response, value is displayed column name in table
   //             [function] for ABR dynamic columns, fn for transforming propname to displayed column name
   //    columnNamesSortFn = [function] just for ABR dynamic columns, sort fn to sort ABR column names
   //    formatters = [object] or [function]
   //             [object] key is metric prop of response, value is format fn for column for respective metric
   //             [function] one format fn is used for all columns/metrics
   //    cellStyles = [function] one cell style fn is used for all columns/metrics,
   //    subSwitchInitialIdxMapping = [object], just for sub-switches metrics, key is propname, value is initial index
   //    shownTerms = [Array<string>] custom break term terms (not derived from ES index)
   [WORLDMAP_METRIC.PI]: {
      title: (country) => `${country} Playouts / Ingests`,
      exIndexName: BINTU_ACCOUNTING,
      path: 'world/playoutIngest',
      categoryLabels: ['Playout', 'Ingest'],
      columnNames: { usage: 'Count' },
      subSwitchInitialIdxMapping: { [PI_OPTIONS.BOTH]: 0, [PI_OPTIONS.PLAYOUTS]: 0, [PI_OPTIONS.INGESTS]: 1 },
      formatters: (cell) => getIntegerWithDecimalSeparator(cell),
   },
   [WORLDMAP_METRIC.BUFFER_RATIO]: {
      title: (country) => `${country} Buffering ratio`,
      exIndexName: METRICS_PLAYER,
      path: 'world/buffering/ratio',
      categoryLabels: ['Buffering ratio'],
      columnNames: { bufferingRatio: 'Buffering ratio', totalPlaytime: 'Total playtime' },
      formatters: { bufferingRatio: (cell) => cell.toFixed(2), totalPlaytime: (cell) => format_time_Number(cell) },
   },
   [WORLDMAP_METRIC.LATENCY]: {
      title: (country) => `${country} Latency`,
      exIndexName: METRICS_PLAYER,
      path: 'world/latency',
      categoryLabels: ['Latency'],
      columnNames: { latency: 'Latency', totalPlaytime: 'Total playtime', uniquePlayouts: 'Unique playouts' },
      formatters: {
         latency: (cell) => cell.toFixed(2),
         totalPlaytime: (cell) => format_time_Number(cell),
         uniquePlayouts: (cell) => numeral(cell).format('0,0'),
      },
   },
   [WORLDMAP_METRIC.PLAYTIME]: {
      title: (country) => `${country} Average playtime`,
      exIndexName: METRICS_PLAYER,
      path: 'world/playtime',
      categoryLabels: ['Playtime'],
      columnNames: { playtime: 'Average playtime', totalPlaytime: 'Total playtime', uniquePlayouts: 'Unique playouts' },
      formatters: {
         playtime: (cell) => format_time_Number(cell),
         totalPlaytime: (cell) => format_time_Number(cell),
         uniquePlayouts: (cell) => numeral(cell).format('0,0'),
      },
   },
   [WORLDMAP_METRIC.USAGE]: {
      title: (country) => `${country} usage playout / ingest`,
      exIndexName: BINTU_ACCOUNTING,
      path: 'world/usage',
      categoryLabels: ['Playout', 'Ingest'],
      columnNames: { usage: 'Usage' },
      formatters: (cell) => roundUsageWith5Digits(cell),
   },
   [WORLDMAP_METRIC.ABR_PLAYTIME]: {
      title: (country) => `${country} total playtime per device type / video height ABR profile`,
      exIndexName: METRICS_PLAYER,
      path: 'world/abr/playtime',
      categoryLabels: ['Mobile', 'Desktop'],
      columnNames: (propName) => `P ${propName.split(' ').slice(-1)}`,
      columnNamesSortFn: (a, b) =>
         Number.parseInt(a.metricName.split(' ').slice(-1)) - Number.parseInt(b.metricName.split(' ').slice(-1)),
      formatters: (cell) => format_time_Number(cell),
      cellStyles: abrCellStyleFn,
   },
   [WORLDMAP_METRIC.ABR_VIEWER]: {
      title: (country) => `${country} unique viewers per video height ABR profile`,
      exIndexName: METRICS_PLAYER,
      path: 'world/abr/viewer',
      categoryLabels: ['Unique viewers'],
      columnNames: (propName) => `P ${propName.split(' ').slice(-1)}`,
      columnNamesSortFn: (a, b) =>
         Number.parseInt(a.metricName.split(' ').slice(-1)) - Number.parseInt(b.metricName.split(' ').slice(-1)),
      formatters: (cell) => getIntegerWithDecimalSeparator(cell),
      cellStyles: abrCellStyleFn,
   },
   [WORLDMAP_METRIC.ABR_SWITCHES]: {
      title: (country) => `${country} Switches between ABR profiles`,
      exIndexName: METRICS_PLAYER,
      path: 'world/abr/switches',
      categoryLabels: ['high->mid', 'mid->low', 'low->mid', 'mid->high'],
      buttonLabelFn: (buttonLabel) => getSwitchLabel(buttonLabel, true),
      columnNames: { switches: 'Switches between ABR profiles' },
      formatters: (cell) => getIntegerWithDecimalSeparator(cell),
   },
   [WORLDMAP_METRIC.QOE]: {
      title: (country) => `${country} QoE score`,
      exIndexName: METRICS_PLAYER,
      path: 'world/qoe',
      categoryLabels: ['QoE score'],
      columnNames: { score: 'QoE Score', uniquePlayouts: 'Unique plays' },
      formatters: { score: (cell) => cell, uniquePlayouts: (cell) => numeral(cell).format('0,0') },
      shownTerms: [STREAM],
      cellStyles: { score: qoeCellStyleFn, uniquePlayouts: defaultStyle },
   },
};
