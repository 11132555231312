import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import roundTo from 'round-to';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, HorizontalSeparator, Spinner } from '../../../common';

import { CardTitle } from 'reactstrap';

import { PropTypes } from 'prop-types';

import ZoomHint from '../../../zoom/ZoomHint';
import { WIDGET_SETTINGS } from '../../../zoom/widgetSettings/HomeView';
import { INGEST } from './../../../zoom/ZoomConstants';
import UsageModal from './../../../zoom/templates/UsageTemplate';

import { barChartZoomOptions } from '../../../../constants/chartjs';
import { HOME_VIEW } from '../../../../constants/general';

import {
   CenteredMsgShowing,
   ChartWrapper,
   ColoredText,
   HeightSetContainer,
   RightSide,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   TwoSideRow,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';
import { addSeparatorAndThreeDecimalPlaces } from '../../../../util/NumberFormatter';

import { DefaultGrey } from '../../../../constants/colors';
import useDataFetch from '../../../../hooks/useDataFetch';
import {
   cancelTokenSelector,
   countriesSelector,
   endTimespanSelector,
   hashSelector,
   intervalTimespanSelector,
   startTimespanSelector,
   streamNamesSelector,
   tagsSelector,
   timespanSelector,
   titleTimespanSelector,
   usageSwitchSelector,
} from '../../../../redux/selectors/selectors';
import { generateStackedBarChartData } from '../../../../util/ChartJsProcessing';
import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';
import TrafficHint from '../../../common/Hints/TrafficHint';
import ErrorDisplay from '../../ipFiltering/ErrorDisplay';

const barChartOptions = barChartZoomOptions();

const stackedPropNames = ['rtmpUsage', 'webrtcUsage'];

const blue = getStyle('--info');
const dark = '#2B52AB';

const USAGE_SETTINGS = {
   bytes: {
      unit: 'GB',
      chartLabelRtmp: 'RTMP [GB]',
      chartLabelWebrtc: 'Webcaster [GB]',
      unitConversion: (value) => roundTo(value / 1e9, 3),
      resUnitPropNames: 'bytes',
   },
   playtime: {
      unit: 'h',
      chartLabelRtmp: 'RTMP [h]',
      chartLabelWebrtc: 'Webcaster [h]',
      unitConversion: (value) => roundTo(value / 3600, 3),
      resUnitPropNames: 'seconds',
   },
};

const protocolNameLengthPx = 70;

function defaultChartObject(usageSwitch, labels = [], data1 = [], data2 = []) {
   return {
      steppedLine: 'true',
      labels,
      datasets: [
         {
            label: USAGE_SETTINGS[usageSwitch].chartLabelRtmp,
            backgroundColor: hexToRgba(blue, 10),
            borderColor: blue,
            borderWidth: 1,
            data: data1,
         },
         {
            label: USAGE_SETTINGS[usageSwitch].chartLabelWebrtc,
            backgroundColor: hexToRgba(dark, 10),
            borderColor: dark,
            borderWidth: 1,
            data: data2,
         },
      ],
   };
}

function addZoomClickHandler(chartOptions, barRef, setModal) {
   return {
      ...chartOptions,
      onClick: (event, item) => {
         if (item.length === 0) {
            return;
         }
         const activePoint = barRef.current.chartInstance.getElementAtEvent(event)[0];
         const datasetIndex = activePoint._datasetIndex;
         const data = activePoint._chart.data;
         const clickedLabel = data.datasets[datasetIndex].label;
         const index = item[0]._index;

         setModal({
            chosenIndex: index,
            toggleShow: true,
            selectedStackSubBar: clickedLabel.split(' [')[0],
         });
      },
   };
}

function totalToString(total) {
   return addSeparatorAndThreeDecimalPlaces(total).toString();
}

function UsageIngest({ height }) {
   const usageSwitch = useSelector(usageSwitchSelector);

   const [totals, setTotals] = useState([0, 0]);
   const [chart, setChart] = useState(defaultChartObject(usageSwitch));

   const [noData, setNoData] = useState(false);

   const hash = useSelector(hashSelector);
   const streamNames = useSelector(streamNamesSelector);
   const tags = useSelector(tagsSelector);
   const countries = useSelector(countriesSelector);

   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);

   const timespanObj = useSelector(timespanSelector);
   const interval = useSelector(intervalTimespanSelector);
   const timespanTitle = useSelector(titleTimespanSelector);

   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const [modal, setModal] = useState({
      toggleShow: false,
      chosenIndex: 0,
      selectedStackSubBar: WIDGET_SETTINGS[INGEST].categoryLabels[0],
   });

   const [timespansPerIndex, setTimespansPerIndex] = useState([
      {
         start: moment.utc(),
         end: moment.utc(),
      },
   ]);

   const barRef = useRef(null);

   const chartOptions = addZoomClickHandler(barChartOptions, barRef, setModal);

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, [], streamNames, HOME_VIEW);
   const url = `/api/v2/usage/protocol/ingest/${usageSwitch}/timeseries?from=${start.format()}&to=${end.format()}&interval=${interval}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            const usage = data.data;
            setNoData(data.statusCode === 2001);
            const { datasetsDatas, labels, totals, timespansPerIndex } = generateStackedBarChartData(
               usage,
               timespanObj,
               stackedPropNames,
               USAGE_SETTINGS[usageSwitch].resUnitPropNames,
               USAGE_SETTINGS[usageSwitch].unitConversion,
            );
            setTimespansPerIndex(timespansPerIndex);
            setChart(defaultChartObject(usageSwitch, labels, ...datasetsDatas));
            setTotals(totals);
         }
      }
      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, dispatch, usageSwitch, setTimespansPerIndex, timespanObj]);

   const errorOrNoData = errorMessage || noData;

   const { toggleShow, chosenIndex, selectedStackSubBar } = modal;
   const chosenTimespan = timespansPerIndex[chosenIndex];

   const { unit } = USAGE_SETTINGS[usageSwitch];
   const [rtmpTotal, webrtcTotal] = totals;

   return (
      <WidgetContainer>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow>
                     <CsvExportFromApiButton
                        url={url}
                        header={header}
                        cancelToken={cancelToken}
                        filename={'Usage_Ingest'}
                     />
                  </SimpleFlexRow>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <TrafficHint term={'stream'} hasTimeAxis={true} sizePx={16} />
                     </SimpleFlexItem>
                     <HorizontalSeparator
                        color={DefaultGrey(1, 180)}
                        widthInPx={1}
                        heightInPx={18}
                        marginX_InPx={12}
                        marginTopInPx={1}
                     />
                     <SimpleFlexItem>
                        <ZoomHint />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer>
            <TwoSideRow marginRightPx={15}>
               <div>
                  <CardTitle className='mb-0'>Usage Ingest</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </div>
               <RightSide minWidthPx={200}>
                  <TwoSideRow setNoWidth={true} marginLeftPx={15}>
                     <RightSide minWidthPx={protocolNameLengthPx}>RTMP:</RightSide>
                     <ColoredText color={blue}>
                        {`${isLoading || errorOrNoData ? '0' : totalToString(rtmpTotal)} ${unit}`}
                     </ColoredText>
                  </TwoSideRow>
                  <TwoSideRow setNoWidth={true} marginLeftPx={15}>
                     <RightSide minWidthPx={protocolNameLengthPx}>Webcaster:</RightSide>
                     <ColoredText color={dark}>
                        {`${isLoading || errorOrNoData ? '0' : totalToString(webrtcTotal)} ${unit}`}
                     </ColoredText>
                  </TwoSideRow>
                  <TwoSideRow setNoWidth={true} marginLeftPx={15}>
                     <RightSide minWidthPx={protocolNameLengthPx}>Total:</RightSide>
                     <div>{`${isLoading || errorOrNoData ? '0' : totalToString(rtmpTotal + webrtcTotal)} ${unit}`}</div>
                  </TwoSideRow>
               </RightSide>
            </TwoSideRow>
            <ChartWrapper className='chart-wrapper' marginTopPx={51} height={height}>
               <Spinner loading={isLoading} parentTopMarginPx={65}>
                  {errorOrNoData ? (
                     <CenteredMsgShowing height={height}>
                        {errorMessage ? (
                           <ErrorDisplay error={errorMessage} msgColor={'rgb(80,80,80)'}></ErrorDisplay>
                        ) : (
                           'No data available'
                        )}
                     </CenteredMsgShowing>
                  ) : (
                     <SetChartHeight height={height}>
                        <Bar ref={barRef} data={chart} options={chartOptions} height={height} />
                     </SetChartHeight>
                  )}
               </Spinner>
            </ChartWrapper>
         </WidgetBodyContainer>
         {toggleShow && (
            <UsageModal
               timespan={chosenTimespan}
               metric={INGEST}
               selectedStackSubBar={selectedStackSubBar}
               closeModal={() => setModal({ ...modal, toggleShow: false })}
            />
         )}
      </WidgetContainer>
   );
}

UsageIngest.propTypes = {
   height: PropTypes.number,
};

export default UsageIngest;
