import {
   ABR_VIEW,
   BREAKDOWN_VIEW,
   DAY,
   GUARDIAN_VIEW,
   H5LIVE_VIEW,
   HISTORY_VIEW,
   HOME_VIEW,
   HOUR,
   QOE_VIEW,
   TROUBLESHOOTING_VIEW,
   WEBRTC_VIEW,
   WEEK,
   WORLD_VIEW,
} from './general';

export const MAX_DURATION_PER_VIEW = {
   [HOME_VIEW]: { value: 92, unit: 'days' },
   [BREAKDOWN_VIEW]: { value: 31, unit: 'days' },
   [H5LIVE_VIEW]: { value: 31, unit: 'days' },
   [WORLD_VIEW]: { value: 31, unit: 'days' },
   [ABR_VIEW]: { value: 31, unit: 'days' },
   [QOE_VIEW]: { value: 92, unit: 'days' },
   [WEBRTC_VIEW]: { value: 31, unit: 'days' },
   [HISTORY_VIEW]: { value: 10, unit: 'years' },
   [TROUBLESHOOTING_VIEW]: undefined,
   [GUARDIAN_VIEW]: { value: 3, unit: 'days' },
};

export const DEFAULT_MAX_DURATIONS_FOR_INTERVALS = {
   second: {
      value: 12 * HOUR,
      limitHint: { count: 12, unit: 'hours' },
   },
   minute: {
      value: 12 * HOUR,
      limitHint: { count: 12, unit: 'hours' },
   },
   hour: {
      value: 7 * DAY,
      limitHint: { count: 7, unit: 'days' },
   },
   day: {
      value: 92 * DAY,
      limitHint: { count: 3, unit: 'months' },
   },
   week: {
      value: 25 * WEEK,
      limitHint: { count: 25, unit: 'weeks' },
   },
   month: {
      value: 53 * WEEK,
      limitHint: { count: 1, unit: 'years' },
   },
   year: {
      value: 10 * 52 * WEEK,
      limitHint: { count: 10, unit: 'years' },
   },
};
