import React from 'react';
import PropTypes from 'prop-types';

import styled, { keyframes } from 'styled-components';

const threeBounces = keyframes`
   0%, 80%, 100% {
     -webkit-transform: scale(0);
             transform: scale(0); }
   40% {
     -webkit-transform: scale(1);
             transform: scale(1); }
`;

export const SpinnerContainer = styled.div`
   min-width: ${(props) => props.widthPx}px;
   margin: 0px auto;
   text-align: center;
   padding: ${(props) => `${props.paddingTopBottomPx}px 0px`};
   ${(props) => (props.heightPx ? `height: ${props.heightPx}px;` : '')}
`;
SpinnerContainer.displayName = 'SpinnerContainer';

export const SpinBall = styled.div`
   height: ${(props) => props.ballSizePx}px;
   width: ${(props) => props.ballSizePx}px;
   background-color: ${(props) => props.ballColor};
   border-radius: 100%;
   display: inline-block;
   animation: ${threeBounces} 1.4s ease-in-out 0s infinite both;
   animation-delay: ${(props) => props.animationDelaySecs}s;
`;
SpinBall.displayName = 'SpinBall';

function BlockSpinner({ ballSizePx, ballColor, paddingTopBottomPx, widthPx, heightPx }) {
   return (
      <SpinnerContainer paddingTopBottomPx={paddingTopBottomPx} widthPx={widthPx} heightPx={heightPx}>
         <SpinBall ballSizePx={ballSizePx} ballColor={ballColor} animationDelaySecs={-0.32} />
         <SpinBall ballSizePx={ballSizePx} ballColor={ballColor} animationDelaySecs={-0.16} />
         <SpinBall ballSizePx={ballSizePx} ballColor={ballColor} />
      </SpinnerContainer>
   );
}

BlockSpinner.propTypes = {
   ballSizePx: PropTypes.number,
   ballColor: PropTypes.string,
   paddingTopBottomPx: PropTypes.number,
   widthPx: PropTypes.number,
   heightPx: PropTypes.number,
};

BlockSpinner.defaultProps = {
   ballSizePx: 20,
   ballColor: '#333',
   paddingTopBottomPx: 0,
   widthPx: 0,
};

export default BlockSpinner;
