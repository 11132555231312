import React, { Component } from 'react';
import { connect } from 'react-redux';

import { switchUsage } from '../../../redux/actions/general';

import { Col, Row } from 'reactstrap';

import storePropTypes from '../../../redux/store/propTypes';
import { PropTypes } from 'prop-types';
import { TRAFFIC, PLAYTIME } from '../../../constants/general';

import {
   CenteredFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../styledComponents/styledComponents';
import SwitchButtons from '../../common/SwitchButtons/SwitchButtons';
import { DefaultBlue, DefaultGrey } from '../../../constants/colors';

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { switchUsage };

class TrafficPlaytimeSwitch extends Component {
   render() {
      const payload = { usageSwitch: this.props.usageSwitch === TRAFFIC ? PLAYTIME : TRAFFIC };
      const setSwitchOption = (e) => {
         const selectedOption = e.target.getAttribute('name');
         if (selectedOption !== this.props.usageSwitch) {
            this.props.switchUsage(payload, payload);
         }
      };
      return (
         <div style={{ position: 'relative', zIndex: 10 }} className='animated fadeIn'>
            <Row>
               <Col>
                  <WidgetContainer>
                     <WidgetHeaderContainer $bgColor={DefaultGrey(1, 247)}>
                        <strong>Usage switch</strong>
                     </WidgetHeaderContainer>
                     <WidgetBodyContainer>
                        <CenteredFlexContainer>
                           <SwitchButtons
                              leftActive={this.props.usageSwitch === TRAFFIC}
                              leftLabel={'Traffic'}
                              rightLabel={'Playtime'}
                              leftName={TRAFFIC}
                              rightName={PLAYTIME}
                              handleSwitch={setSwitchOption}
                              activeBackgroundColor={DefaultBlue()}
                              inactiveBackgroundColor={DefaultGrey()}
                              activeFontColor={'#FFF'}
                              inactiveFontColor={'#000'}
                              buttonWidth={100}
                              buttonHeight={25}
                           />
                        </CenteredFlexContainer>
                     </WidgetBodyContainer>
                  </WidgetContainer>
               </Col>
            </Row>
         </div>
      );
   }
}

TrafficPlaytimeSwitch.propTypes = {
   filter: storePropTypes.filter,
   timespan: storePropTypes.timespan,
   countries: storePropTypes.countries,
   cancelToken: storePropTypes.cancelToken,
   usageSwitch: PropTypes.string,
   switchUsage: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrafficPlaytimeSwitch);
