import React from 'react';
import { PropTypes } from 'prop-types';

export const ArrowDownSvg = ({ fill, transform, width, height, verticalAlign }) => {
   return (
      <svg
         fill={fill}
         transform={transform}
         version='1.1'
         id='Capa_1'
         xmlns='http://www.w3.org/2000/svg'
         x='0px'
         y='0px'
         width={width}
         height={height}
         viewBox='0 0 1000 600'
         className='ml-1'
         style={{ verticalAlign }}
      >
         <g id='Rounded_Rectangle_33_copy_4_1_'>
            <path d='M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z' />
         </g>
      </svg>
   );
};

ArrowDownSvg.propTypes = {
   fill: PropTypes.string,
   transform: PropTypes.string,
   width: PropTypes.string,
   height: PropTypes.string,
   verticalAlign: PropTypes.string,
};

ArrowDownSvg.defaultProps = {
   fill: '#777777',
   width: '24px',
   height: '14px',
   verticalAlign: 'middle',
};
