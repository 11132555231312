export function parseString(value) {
   if (value === undefined) return '';
   return decodeURIComponent(value);
}

export function parseObjectArray(value) {
   if (value === '') {
      return [];
   }
   const stringifiedObjs = decodeURIComponent(value).split(',');

   return stringifiedObjs.map((strObj) => parseObject(strObj));
}

export function parseObject(strObjEncoded) {
   const strObj = decodeURIComponent(strObjEncoded);
   const splitStrObj = strObj.split('~');
   if (splitStrObj.length !== 2) {
      throw Error('Split str obj !== 2');
   }
   let [value, label] = splitStrObj;
   value = decodeURIComponent(value);
   label = decodeURIComponent(label);

   return { value, label };
}

export function parseSimpleObjectArray(value) {
   if (value === '') {
      return [];
   }
   const stringifiedObjs = decodeURIComponent(value).split(',');

   return stringifiedObjs.map((strObj) => {
      const value = parseString(strObj);
      return { value, label: value };
   });
}

export function parseIntValue(value) {
   // console.log('parseInt :>> ', value);
   const parsedValue = parseInt(value, 10);
   if (isNaN(parsedValue)) {
      throw Error('Parsed Value is not a number');
   }
   return parsedValue;
}
