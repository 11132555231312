import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { Router, Route, Redirect, Switch } from 'react-router-dom';

// Styles
import './App.css';

// CoreUI Icons Set
import '@coreui/icons/css/free.min.css';
// Import Font Awesome Icons Set
// import 'font-awesome/css/font-awesome.min.css';
// Import Bootstrap Icons Set
import 'bootstrap-icons/font/bootstrap-icons.css';
// Import Main styles for this application
import './scss/style.css';
import 'react-tooltip/dist/react-tooltip.css';

// Containers
import DefaultLayout from './components/root/DefaultLayout/DefaultLayout';
import { Login, TokenLogin } from './components/root/contentArea';
import { LOGIN_VIEW, TOKEN_LOGIN_VIEW } from './constants/general';
import { history } from './redux/store/configureStore';
import { ANALYTICS_SERVICE_API_BASE_URL, environment } from './constants/env';
import moment from 'moment';

import { datadogRum } from '@datadog/browser-rum';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

datadogRum.init({
   applicationId: '3df511f5-b3f8-447c-b13b-73ff94d5c128',
   clientToken: 'puba4b774f508cef57dfabbfbcb4847b5b1',
   site: 'datadoghq.eu',
   service: 'metrics-dashboard',
   env: environment || 'local-dev',
   // Specify a version number to identify the deployed version of your application in Datadog
   version: '2.22.0',
   sessionSampleRate: 100,
   sessionReplaySampleRate: 100,
   trackUserInteractions: true,
   trackResources: true,
   trackLongTasks: true,
   defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

const mapStateToProps = (state) => {
   return state.general;
};

class App extends Component {
   constructor(props) {
      super(props);

      this.isAuthenticated = this.isAuthenticated.bind(this);
   }

   isAuthenticated(props) {
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));

      if (userInfo == null) {
         const { pathname, search } = props.location;
         // console.log('pathname => ', pathname);
         // console.log('search => ', search);
         sessionStorage.setItem('pathname', pathname);
         sessionStorage.setItem('search', search);
         return false;
      }

      return userInfo.token != null && moment.utc().isSameOrBefore(moment.utc(userInfo.expires));
   }

   render() {
      axios.defaults.baseURL = ANALYTICS_SERVICE_API_BASE_URL || '';

      return (
         <Router history={history}>
            <ToastContainer />
            <Switch>
               <Route exact path={`/${LOGIN_VIEW}`} name='Login' component={Login} />
               <Route exact path={`/${TOKEN_LOGIN_VIEW}`} name='TokenLogin' component={TokenLogin} />
               <Route
                  path='/'
                  name='Home'
                  render={(props) =>
                     this.isAuthenticated(props) ? <DefaultLayout {...props} /> : <Redirect to={`/${LOGIN_VIEW}`} />
                  }
               />
            </Switch>
         </Router>
      );
   }
}

export default connect(mapStateToProps)(App);
