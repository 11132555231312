import React from 'react';
import { PropTypes } from 'prop-types';
import { StyledButton } from '../../../styledComponents/styledComponents';

function BlockingIndicator({ isBlocked }) {
   return (
      <StyledButton
         type='button'
         fontSizePx={10}
         className={'btn'}
         name={'blockIndicator'}
         id={'blockIndicator'}
         heightPx={14}
         widthPx={45}
         bgColor={isBlocked ? '#dc3545' : 'rgb(105,105,105)'}
         bgColorHover={isBlocked ? '#dc3545' : 'rgb(105,105,105)'}
         fontColor={'#FFF'}
         fontColorHover={'#FFF'}
         $cursor={'default'}
      >
         {isBlocked ? 'YES' : 'NO'}
      </StyledButton>
   );
}

BlockingIndicator.propTypes = {
   isBlocked: PropTypes.bool,
};

export default BlockingIndicator;
