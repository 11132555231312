import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccessLevel } from '../../../../constants/AccessLevels';

import { Dashboard } from '../../../common';
import Widget from '../../Widget/Widget';

import { UsageByClient, UsageByIP, UsageByStreamName, UsageByToken } from '../../../widgets/cdn';

import { Col, Row } from 'reactstrap';

import axios from 'axios';
import { setNewCancelToken, setViewTimespan } from '../../../../redux/actions/general';
import { cancelTokenSelector } from '../../../../redux/selectors/selectors';
import { BREAKDOWN_VIEW } from '../../../../constants/general';
import TrafficPlaytimeSwitch from '../../../common/TrafficPlaytimeSwitch/TrafficPlaytimeSwitch';

function BreakDown({ location, history }) {
   const cancelToken = useSelector(cancelTokenSelector);
   const cancelTokenRef = useRef(cancelToken);
   cancelTokenRef.current = cancelToken;

   const dispatch = useDispatch();

   useEffect(() => {
      return () => {
         // cancel token, added to old requests, will be activated --> old requests will be cancelled
         cancelTokenRef.current.cancel('Operation canceled by the user.');
         dispatch(setViewTimespan(BREAKDOWN_VIEW));
         dispatch(setNewCancelToken(axios.CancelToken.source()));
      };
   }, [dispatch]);

   return (
      <Dashboard location={location} history={history} view={BREAKDOWN_VIEW}>
         <Row className='animated fadeIn'>
            <Col xs='12' sm='12' md='12' lg='12' xl='12'>
               <TrafficPlaytimeSwitch />
            </Col>
         </Row>
         <Row className='animated fadeIn'>
            <Col xs='12' sm='12' md='12' lg='12' xl='6'>
               <Widget type={UsageByIP} requiredLevel={AccessLevel.FULL} />
            </Col>
            <Col xs='12' sm='12' md='12' lg='12' xl='6'>
               <Widget type={UsageByClient} requiredLevel={AccessLevel.FULL} />
            </Col>
         </Row>
         <Row className='animated fadeIn'>
            <Col xs='12' sm='12' md='12' lg='12' xl='6'>
               <Widget type={UsageByStreamName} requiredLevel={AccessLevel.FULL} />
            </Col>
            <Col xs='12' sm='12' md='12' lg='12' xl='6'>
               <Widget type={UsageByToken} requiredLevel={AccessLevel.ADMIN} />
            </Col>
         </Row>
      </Dashboard>
   );
}

export default BreakDown;
