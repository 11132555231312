import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccessLevel } from '../../../../constants/AccessLevels';

import { Dashboard } from '../../../common';
import Widget from '../../Widget/Widget';

import { Usage } from '../../../widgets/usageHistory';
import { Col, Row } from 'reactstrap';

import axios from 'axios';
import { setNewCancelToken, setViewTimespan } from '../../../../redux/actions/general';
import { cancelTokenSelector } from '../../../../redux/selectors/selectors';
import { HISTORY_VIEW } from './../../../../constants/general';

function UsageHistory({ location, history }) {
   const cancelToken = useSelector(cancelTokenSelector);
   const cancelTokenRef = useRef(cancelToken);
   cancelTokenRef.current = cancelToken;

   const dispatch = useDispatch();

   useEffect(() => {
      return () => {
         // cancel token, added to old requests, will be activated --> old requests will be cancelled
         cancelTokenRef.current.cancel('Operation canceled by the user.');
         dispatch(setViewTimespan(HISTORY_VIEW));
         dispatch(setNewCancelToken(axios.CancelToken.source()));
      };
   }, [dispatch]);

   return (
      <Dashboard location={location} history={history} view={HISTORY_VIEW}>
         <Row className='animated fadeIn'>
            <Col xs='12' sm='12' md='12' lg='12' xl='12'>
               <Widget type={Usage} requiredLevel={AccessLevel.FULL} widgetAttributes={{ height: 300 }} />
            </Col>
         </Row>
      </Dashboard>
   );
}

export default UsageHistory;
