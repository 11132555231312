export const errorCodeTrafficLight = {
   critical: [
      1005,
      1008,
      1009,
      2001,
      2002,
      2003,
      2004,
      3001,
      3002,
      3003,
      3004,
      3100,
      3200,
      4001,
      4002,
      4003,
      4101,
      4110,
      4400,
      4403,
      4900,
      4901,
      4903,
      4904,
      5004,
      5103,
   ],
   notCritical: [4102, 4103, 4104, 4105, 4106, 4107, 4108, 4109, 4111, 4115, 4500, 4503, 5101],
   expected: [
      1001,
      1002,
      1003,
      1004,
      1006,
      1007,
      2011,
      2012,
      2013,
      2014,
      4004,
      5001,
      5002,
      5003,
      5005,
      5006,
      5007,
      5008,
      5009,
      5010,
      5102,
      5201,
      5202,
      5203,
      5204,
      5205,
      5206,
      5207,
      5208,
   ],
};

export const errorImportanceMap = new Map();

errorImportanceMap.set(1005, 'critical');
errorImportanceMap.set(1008, 'critical');
errorImportanceMap.set(1009, 'critical');
errorImportanceMap.set(2001, 'critical');
errorImportanceMap.set(2002, 'critical');
errorImportanceMap.set(2003, 'critical');
errorImportanceMap.set(2004, 'critical');
errorImportanceMap.set(3001, 'critical');
errorImportanceMap.set(3002, 'critical');
errorImportanceMap.set(3003, 'critical');
errorImportanceMap.set(3004, 'critical');
errorImportanceMap.set(3100, 'critical');
errorImportanceMap.set(4001, 'critical');
errorImportanceMap.set(4002, 'critical');
errorImportanceMap.set(4003, 'critical');
errorImportanceMap.set(4101, 'critical');
errorImportanceMap.set(4110, 'critical');
errorImportanceMap.set(4400, 'critical');
errorImportanceMap.set(4403, 'critical');
errorImportanceMap.set(4900, 'critical');
errorImportanceMap.set(4901, 'critical');
errorImportanceMap.set(4903, 'critical');
errorImportanceMap.set(4904, 'critical');
errorImportanceMap.set(5004, 'critical');
errorImportanceMap.set(5103, 'critical');

errorImportanceMap.set(4102, 'not critical');
errorImportanceMap.set(4103, 'not critical');
errorImportanceMap.set(4104, 'not critical');
errorImportanceMap.set(4105, 'not critical');
errorImportanceMap.set(4106, 'not critical');
errorImportanceMap.set(4107, 'not critical');
errorImportanceMap.set(4108, 'not critical');
errorImportanceMap.set(4109, 'not critical');
errorImportanceMap.set(4111, 'not critical');
errorImportanceMap.set(4115, 'not critical');
errorImportanceMap.set(4500, 'not critical');
errorImportanceMap.set(4503, 'not critical');
errorImportanceMap.set(5101, 'not critical');

errorImportanceMap.set(1001, 'expected');
errorImportanceMap.set(1002, 'expected');
errorImportanceMap.set(1003, 'expected');
errorImportanceMap.set(1004, 'expected');
errorImportanceMap.set(1006, 'expected');
errorImportanceMap.set(1007, 'expected');
errorImportanceMap.set(2011, 'expected');
errorImportanceMap.set(2012, 'expected');
errorImportanceMap.set(2013, 'expected');
errorImportanceMap.set(2014, 'expected');
errorImportanceMap.set(4004, 'expected');
errorImportanceMap.set(5001, 'expected');
errorImportanceMap.set(5002, 'expected');
errorImportanceMap.set(5003, 'expected');
errorImportanceMap.set(5005, 'expected');
errorImportanceMap.set(5006, 'expected');
errorImportanceMap.set(5007, 'expected');
errorImportanceMap.set(5008, 'expected');
errorImportanceMap.set(5009, 'expected');
errorImportanceMap.set(5010, 'expected');
errorImportanceMap.set(5102, 'expected');
errorImportanceMap.set(5201, 'expected');
errorImportanceMap.set(5202, 'expected');
errorImportanceMap.set(5203, 'expected');
errorImportanceMap.set(5204, 'expected');
errorImportanceMap.set(5205, 'expected');
errorImportanceMap.set(5206, 'expected');
errorImportanceMap.set(5207, 'expected');
errorImportanceMap.set(5208, 'expected');

export const stoppingReasonTrafficLight = {
   critical: ['connectionclose', 'servernotfound', 'playbackerror', 'notenoughdata'],
   notCritical: ['buffer', 'streamnotfound', 'interactionrequired', 'reconnectionimminent'],
   expected: ['normal', 'playbacksuspended', 'destroy', 'playbackrestart', 'visibilityhidden', 'sourcestreamstopped'],
};
