import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip/dist/react-tooltip.umd';
import ReactDOMServer from 'react-dom/server';
import { uniqueIdFactory } from '../../../util/UtilFunctions';
import { AlignedIconContainer } from '../../../styledComponents/styledComponents';

function TrafficHint({ term, hasTimeAxis, sizePx, hasMarginLeft, place }) {
   const [uid] = useState(uniqueIdFactory('_'));

   const id = `traffic${uid}`;

   const tipTextDefault = ReactDOMServer.renderToStaticMarkup(
      <>
         <div>Usage (data traffic or playback time)</div>
         <div>{`of a ${term} is always calculated`}</div>
         <div>{`and logged when the ${term}`}</div>
         <div>has been re-started or stopped.</div>
      </>,
   );
   const tipTextForTimeAxis = ReactDOMServer.renderToStaticMarkup(
      <>
         <div>Usage (data traffic or playback time) of a</div>
         <div>{`${term} is always calculated and logged`}</div>
         <div>{`when the ${term} has been re-started`}</div>
         <div>or stopped. The total usage is plotted</div>
         <div>in chart on the x-axis at the point of time</div>
         <div>{`the ${term} re-started or stopped, so you`}</div>
         <div>will not see any partial usage before this time.</div>
      </>,
   );

   const tipText = hasTimeAxis ? tipTextForTimeAxis : tipTextDefault;

   return (
      <>
         <AlignedIconContainer
            data-tooltip-id={id}
            data-tooltip-html={tipText}
            className={`bi bi-info-circle ${hasMarginLeft ? 'ml-1' : ''}`}
            $fontSizePx={sizePx}
         ></AlignedIconContainer>
         <ReactTooltip
            id={id}
            place={place}
            className={'reduced-line-height bring-on-top'}
            backgroundColor={'rgba(0,0,0,0.9)'}
            textColor={'rgb(240,240,240)'}
            style={{ textAlign: 'center' }}
         />
      </>
   );
}

TrafficHint.propTypes = {
   term: PropTypes.string,
   hasTimeAxis: PropTypes.bool,
   sizePx: PropTypes.number,
   hasMarginLeft: PropTypes.bool,
   place: PropTypes.string,
};

TrafficHint.defaultProps = {
   hasMarginLeft: true,
   place: 'bottom',
};

export default TrafficHint;
