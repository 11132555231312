import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button } from 'reactstrap';
import { Description, FlexRow, Notification, StaticCol } from '../../../../styledComponents/styledComponents';

import {
   hashSelector,
   cancelTokenSelector,
   troubleEndSelector,
   troubleStartSelector,
   troubleIngestInputSelector,
   troubleIngestStreamSelector,
} from '../../../../redux/selectors/selectors';

import BlockSpinner from '../../../common/BlockSpinner/BlockSpinner';

import PublishesTable from './PublishesTable';

import {
   setTroubleshootingIngestInput,
   resetTroubleshooting,
   setTroubleshootingIngestStream,
} from '../../../../redux/actions/general';
import { useNonInitialEffect } from '../../../../hooks/useNonInitialEffect';
import IngestService from './IngestService';

import { setAlarm } from '../../../../redux/actions/general';
import Axios from 'axios';
import { useResetSignal } from './../general/useResetSignal';
import StreamSelection from '../StreamSelection';

const emptyData = { inputType: '', issueMsg: '', streamsData: {} };
const emptyStreamsData = { valid: false, info: '', created: '' };

function StreamSearch() {
   const dispatch = useDispatch();

   const orgaHash = useSelector(hashSelector);
   const cancelToken = useSelector(cancelTokenSelector);

   const start = useSelector(troubleStartSelector);
   const end = useSelector(troubleEndSelector);
   // console.log('start :>> ', start);
   // console.log('end :>> ', end);
   const [inputValue, setInputValue] = useState('');
   const ingestInputObj = useSelector(troubleIngestInputSelector);

   const { ingestInput } = ingestInputObj;
   // console.log('>>>>> ingestInput :>> ', ingestInput);

   const selectedStreamObj = useSelector(troubleIngestStreamSelector);
   const { ingestStream: selectedStream } = selectedStreamObj;

   const [isStreamSelectionOpen, setOpenState] = useState(true);

   const [inputType, setInputType] = useState('');
   const [issueMsg, setIssueMsg] = useState('');
   const [streamsData, setStreamsData] = useState({});

   const [isLoading, setLoadingState] = useState(false);
   const [error, setError] = useState();

   const [url, setUrl] = useState(`/api/v2/troubleshooting/ingest?from=${start.format()}&to=${end.format()}`);

   const [isMounting, setMountingState] = useState(true);

   const componenthasBeenMounted = useRef(false);

   const inputValueRef = useRef(inputValue);
   inputValueRef.current = inputValue;

   function resetState() {
      setInputType('');
      setIssueMsg('');
      setStreamsData({});
      setLoadingState(false);
   }

   // changed date / interval / orga => reset
   useNonInitialEffect(() => {
      dispatch(
         resetTroubleshooting({
            ingestInput: '',
            troubleIngestStream: '',
            publishStart: '',
            publishEnd: '',
            publishFineStart: '',
            publishFineEnd: '',
            userId: '',
            troublePlayoutStream: '',
            playerId: '',
            playoutFineStart: '',
            playoutFineEnd: '',
         }),
      );
      resetState();
      setUrl(`/api/v2/troubleshooting/ingest?from=${start.format()}&to=${end.format()}`);
   }, [start, end, orgaHash]);

   const resetIsInProgress = useResetSignal(start, end, orgaHash, ingestInput, isMounting);

   // set input with store streamname (if available) at mounting
   if (isMounting) {
      if (ingestInput !== '' && inputValue !== ingestInput) {
         setInputValue(ingestInput);
      }
   }

   useEffect(() => {
      setMountingState(false);
      componenthasBeenMounted.current = true;
   }, []);

   // set input text
   useEffect(() => {
      if (ingestInput !== '' && inputValueRef.current !== ingestInput) {
         setInputValue(ingestInput);
      }
   }, [ingestInput]);

   // press ENTER handler
   function handleKeyDown(event) {
      if (event.key === 'Enter') {
         handleSearchClick();
      }
   }

   useEffect(() => {
      // either input was stream name or searched tag has just one stream -> no user selection needed
      if (Object.keys(streamsData).length === 1) {
         const streamName = Object.keys(streamsData)[0];
         // console.log('streamName :>> ', streamName);
         if (selectedStream !== streamName) {
            dispatch(setTroubleshootingIngestStream(getStreamPayload(streamName), getStreamPayload(streamName)));
         }
         setOpenState(false);
      }
      // searched tag has more than one stream
      if (Object.keys(streamsData).length > 1 && !Object.keys(streamsData).includes(selectedStream)) {
         dispatch(setTroubleshootingIngestStream(getStreamPayload(''), getStreamPayload('')));
         setOpenState(selectedStream === '');
      }
   }, [streamsData, dispatch, selectedStream]);

   // GO button handler
   function handleSearchClick() {
      const trimmedInputValue = inputValue.trim();

      if (trimmedInputValue !== '') {
         setInputValue(trimmedInputValue);
         resetState();
         dispatch(
            setTroubleshootingIngestInput(
               { ingestInput: trimmedInputValue },
               {
                  ingestInput: trimmedInputValue,
                  troubleIngestStream: '',
                  publishStart: '',
                  publishEnd: '',
                  publishFineStart: '',
                  publishFineEnd: '',
               },
            ),
         );
         setUrl(`/api/v2/troubleshooting/ingest?from=${start.format()}&to=${end.format()}`);
      }
   }

   useEffect(() => {
      if (error) {
         if (error instanceof Axios.Cancel) {
            return;
         }
         dispatch(setAlarm('danger', error));
         // set initialState
      }
   }, [error, dispatch]);

   function handleStreamNameResult(res) {
      const { data, isLoading, error } = res;
      // console.log('res f:>> ', res);
      const { inputType, issueMsg, streamsData } = data ? data : emptyData;
      setInputType(inputType);
      setIssueMsg(issueMsg);
      setStreamsData(streamsData);
      setLoadingState(isLoading);
      setError(error);
   }

   const { valid, info, created } =
      selectedStream !== '' && streamsData[selectedStream] !== undefined
         ? streamsData[selectedStream]
         : emptyStreamsData;
   const showInputNotification = issueMsg !== '' || error;
   const showStreamNotification = valid === false;
   const dataAvailable = Object.keys(streamsData).length > 0;
   const showStreamSelection = ingestInput !== '' && inputType === 'tag' && dataAvailable;

   return (
      <>
         <Description>Stream name | Tag</Description>
         <FlexRow>
            <StaticCol width={150}>
               <Input
                  value={inputValue}
                  placeholder={'Insert stream name'}
                  onChange={(event) => setInputValue(event.target.value)}
                  onKeyDown={handleKeyDown}
               />
            </StaticCol>
            <StaticCol>
               <Button className='ml-2' color='primary' onClick={handleSearchClick} disabled={inputValue === ''}>
                  {isLoading ? (
                     <BlockSpinner
                        ballSizePx={10}
                        ballColor={'rgb(230, 230, 230)'}
                        paddingTopBottomPx={0}
                        widthPx={41.15}
                     />
                  ) : (
                     <>{'Search'}</>
                  )}
               </Button>
            </StaticCol>
         </FlexRow>
         {showInputNotification ? (
            <Notification>{`${issueMsg ? issueMsg : error.message ? error.message : error}`}</Notification>
         ) : (
            <>
               {showStreamSelection && (
                  <StreamSelection
                     streamIsDetermined={Object.keys(streamsData).length === 1}
                     isStreamSelectionOpen={isStreamSelectionOpen}
                     setOpenState={setOpenState}
                     selectedStream={selectedStream}
                     userData={Object.keys(streamsData).map((stream) => streamsData[stream])}
                     setStream={(streamName) =>
                        dispatch(
                           setTroubleshootingIngestStream(getStreamPayload(streamName), getStreamPayload(streamName)),
                        )
                     }
                  />
               )}
               {selectedStream !== '' && (
                  <>
                     {showStreamNotification ? (
                        <Notification>{info}</Notification>
                     ) : (
                        <PublishesTable streamName={selectedStream} created={created} />
                     )}
                  </>
               )}
            </>
         )}

         {componenthasBeenMounted.current && ingestInput !== '' && !resetIsInProgress && (
            <IngestService
               ingestInput={ingestInputObj}
               url={url}
               orgaHash={orgaHash}
               cancelToken={cancelToken}
               onResult={handleStreamNameResult}
            />
         )}
      </>
   );
}

function getStreamPayload(streamName) {
   return {
      troubleIngestStream: { ingestStream: streamName },
      troubleSelectedPublish: {
         publishStart: '',
         publishEnd: '',
         publishFineStart: '',
         publishFineEnd: '',
      },
   };
}

export default StreamSearch;
