import React from 'react';

import { PropTypes } from 'prop-types';

import { MetricsContainer, MetricItem } from '../../Styles';

import { E2E } from '../../../../../constants/general';

import {
   INGEST_AUDIO_BITRATE,
   INGEST_VIDEO_BITRATE,
   INGEST_VIDEO_FRAME_ENCODE_TIME,
   INGEST_VIDEO_FRAME_RATE,
   INGEST_VIDEO_NACK,
   MAX_RAW_MODE_TIME_RANGE_IN_SECS,
} from '../../constants';

import IngestWidgets from './IngestWidgets';
import IngestWidgetsRaw from './IngestWidgetsRaw';
import DropTable from '../DropTable';

const METRIC_TYPE_CONFIG = {
   [INGEST_VIDEO_BITRATE]: {
      title: 'Video bitrate [kBits/s]',
      urlSegment: 'video/bitrate',
      resParseFn: (item) => item.bitrate.kbps,
      csvFilename: 'Webrtc_VideoBitrate',
   },
   [INGEST_AUDIO_BITRATE]: {
      title: 'Audio bitrate [kBits/s]',
      urlSegment: 'audio/bitrate',
      resParseFn: (item) => item.bitrate.kbps,
      csvFilename: 'Webrtc_AudioBitrate',
   },
   [INGEST_VIDEO_FRAME_RATE]: {
      title: 'Framerate [fps]',
      urlSegment: 'video/framerate',
      resParseFn: (item) => item.framerate.fps,
      csvFilename: 'Webrtc_VideoFramerate',
   },
   [INGEST_VIDEO_FRAME_ENCODE_TIME]: {
      title: 'Average frame encode time [ms]',
      urlSegment: 'video/frameEncodeTime',
      resParseFn: (item) => item.frameEncodeTime.ms,
      csvFilename: 'Webrtc_VideoFrameEncodeTime',
   },
   [INGEST_VIDEO_NACK]: {
      title: 'Count of packet loss detections',
      urlSegment: 'video/packetLoss',
      resParseFn: (item) => item.packetLoss,
      csvFilename: 'Webrtc_VideoPacketLoss',
   },
};

function WebrtcMetrics({ start, end, mode, streamName, drops }) {
   const isRawModeActive = end.unix() - start.unix() <= MAX_RAW_MODE_TIME_RANGE_IN_SECS;

   return (
      <MetricsContainer>
         {Object.keys(METRIC_TYPE_CONFIG).map((metric, index) => (
            <MetricItem key={index} isFullScreen={mode !== E2E}>
               {isRawModeActive ? (
                  <IngestWidgetsRaw
                     start={start}
                     end={end}
                     streamName={streamName}
                     protocol='webrtc'
                     drops={drops.data}
                     title={METRIC_TYPE_CONFIG[metric].title}
                     urlSegment={METRIC_TYPE_CONFIG[metric].urlSegment}
                     resParseFn={METRIC_TYPE_CONFIG[metric].resParseFn}
                     csvFilename={METRIC_TYPE_CONFIG[metric].csvFilename}
                  />
               ) : (
                  <IngestWidgets
                     start={start}
                     end={end}
                     streamName={streamName}
                     protocol='webrtc'
                     drops={drops.data}
                     title={METRIC_TYPE_CONFIG[metric].title}
                     urlSegment={METRIC_TYPE_CONFIG[metric].urlSegment}
                     resParseFn={METRIC_TYPE_CONFIG[metric].resParseFn}
                     csvFilename={METRIC_TYPE_CONFIG[metric].csvFilename}
                  />
               )}
            </MetricItem>
         ))}
         {drops.data && drops.data.length > 0 && (
            <MetricItem isFullScreen={mode !== E2E}>
               <DropTable drops={drops.data} start={start} end={end} />
            </MetricItem>
         )}
      </MetricsContainer>
   );
}

WebrtcMetrics.propTypes = {
   start: PropTypes.object,
   end: PropTypes.object,
   streamName: PropTypes.string,
   mode: PropTypes.string,
   drops: PropTypes.exact({
      data: PropTypes.array,
      status: PropTypes.bool,
   }),
};

export default WebrtcMetrics;
