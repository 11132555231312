import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Country from './Country';
import { svgWorldData } from './SvgWorldData';

import { worldmapRegionZoomSelector } from '../../../../redux/selectors/selectors';
import { setWorldMapRegionZoom } from '../../../../redux/actions/general';

import { PropTypes } from 'prop-types';
import { HoveredRegionContext } from '../WorldContainer';
import { VIEW_MODE } from '../WorldMapConstants';

function WorldMapSVG({ mapWidth, mapHeight }) {
   const setHoveredWorldRegion = useContext(HoveredRegionContext)[1];
   const currentRegion = useSelector(worldmapRegionZoomSelector);
   const dispatch = useDispatch();

   const onClickOutsideRegions = () => {
      dispatch(setWorldMapRegionZoom('', { worldmapRegionZoom: '' }));
      setHoveredWorldRegion('');
   };

   return (
      <svg width={mapWidth} height={mapHeight} viewBox={`0 0 ${mapWidth} ${mapHeight}`}>
         <rect
            width={mapWidth}
            height={mapHeight}
            fill={'white'}
            onClick={currentRegion !== '' ? onClickOutsideRegions : () => {}}
            onMouseOver={currentRegion !== '' ? () => setHoveredWorldRegion(VIEW_MODE.ZOOM_OUT) : () => {}}
         ></rect>
         <g>
            {svgWorldData.map((d, i) => (
               <Country key={`country-${i}`} d={d} width={mapWidth} idx={i} />
            ))}
         </g>
      </svg>
   );
}

WorldMapSVG.propTypes = {
   mapWidth: PropTypes.number,
   mapHeight: PropTypes.number,
};

export default WorldMapSVG;
