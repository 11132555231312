import { eventSubMetrics, eventViews, eventViewsWithSubMetrics } from '../constants/events';

export function getHttpHeader(hash) {
   let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
   let token = userInfo.token;
   let headerObj = {
      'x-access-token': token,
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
   };

   if (hash !== '') {
      headerObj['x-orga-hash'] = hash;
   }

   return headerObj;
}

export function getFilterQueryParams(countries, tags, events, streamNames, viewPage, subMetric = undefined) {
   let queryParamUrlStr = '';

   if (countries.length > 0) {
      queryParamUrlStr += countries.reduce((str, c) => (str += `&countries=${c}`), '');
   }
   if (tags.length > 0) {
      queryParamUrlStr += tags.reduce((str, c) => (str += `&tags=${c}`), '');
   }
   if (events.length > 0 && applyEventFilter(viewPage, subMetric)) {
      queryParamUrlStr += events.reduce((str, c) => (str += `&events=${c}`), '');
   }
   if (streamNames.length > 0) {
      queryParamUrlStr += streamNames.reduce((str, c) => (str += `&streams=${c}`), '');
   }

   return queryParamUrlStr;
}

function applyEventFilter(viewPage, subMetric) {
   if (eventViews.includes(`${viewPage}`)) {
      if (eventViewsWithSubMetrics.includes(`${viewPage}`)) {
         if (subMetric === undefined) return false;
         return eventSubMetrics.includes(`${subMetric}`);
      } else {
         return true;
      }
   }
}
