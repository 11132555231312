import axios from 'axios';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ANALYTICS_SERVICE_API_BASE_URL } from '../../../../constants/env';
import { Spinner } from '../../../common';

export const ScreenCenter = styled.div`
   position: absolute;
   top: 50%;
   left: 50%;
   margin-right: -50%;
   transform: translate(-50%, -50%);
`;
ScreenCenter.displayName = 'ScreenCenter';

export const Text = styled.h1`
   color: rgb(180, 180, 180);
`;
Text.displayName = 'Text';

function TokenLogin({ location, history }) {
   useEffect(() => {
      async function fetchAuthData() {
         try {
            const urlParams = new URLSearchParams(location.search);
            const singleUseToken = urlParams.get('token');
            const options = {
               method: 'GET',
               url: `${ANALYTICS_SERVICE_API_BASE_URL}/api/authenticate`,
               params: { singleUseToken },
            };

            const res = await axios.request(options);
            const data = res.data;
            const url = data.link;
            delete data.link;

            let userInfo = JSON.stringify(data);
            sessionStorage.setItem('userInfo', userInfo);
            window.location.href = url;
         } catch (error) {
            history.push('/login');
         }
      }
      fetchAuthData();
   }, [location, history]);

   //  const handleClick = () => {
   //      history.push("/path/to/push");
   //  }

   return (
      <ScreenCenter>
         <Text>{'Redirecting'}</Text>
         <Spinner loading={true} childColor={'rgb(180, 180, 180)'}></Spinner>
      </ScreenCenter>
   );
}

export default TokenLogin;
