import React, { useMemo } from 'react';

import { PropTypes } from 'prop-types';
import useDataFetch from '../../../../hooks/useDataFetch';
import storePropTypes from '../../../../redux/store/propTypes';
import { getHttpHeader } from '../../../../util/FetchTools';

function PlayoutService({ userId, url, orgaHash, cancelToken, onResult }) {
   const body = userId;
   const header = useMemo(() => getHttpHeader(orgaHash), [orgaHash]);

   const { successful, isLoading, errorMessage, httpStatus, data } = useDataFetch(url, {
      method: 'POST',
      body,
      header,
      cancelToken,
   });

   if (successful) {
      onResult({ isLoading, httpStatus, untouched: false, data: data.result });
   } else if (errorMessage) {
      onResult({ isLoading, error: errorMessage, untouched: false });
   } else {
      onResult({ untouched: true, isLoading });
   }

   return <></>;
}

PlayoutService.propTypes = {
   userId: PropTypes.object,
   url: PropTypes.string,
   orgaHash: PropTypes.string,
   cancelToken: storePropTypes.cancelToken,
   onResult: PropTypes.func,
};

export default PlayoutService;
