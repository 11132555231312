import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import {
   ModalBackground,
   ModalDialog,
   ModalFooter,
   ModalContent,
} from '../../../styledComponents/ModalStyledComponents';

import { PropTypes } from 'prop-types';

function Modal({ closeModal, children }) {
   const modalDomEl = document.getElementById('modal');
   const modalContent = useRef(null);

   const handleClickOutside = (event) => {
      if (!modalContent.current.contains(event.target)) {
         closeModal();
      }
   };

   if (!modalDomEl) return null;

   return ReactDOM.createPortal(
      <ModalBackground onClick={handleClickOutside}>
         <ModalDialog>
            <ModalContent ref={modalContent}>
               {children}
               <ModalFooter>
                  <button type='button' className='btn btn-danger' onClick={closeModal}>
                     Close
                  </button>
               </ModalFooter>
            </ModalContent>
         </ModalDialog>
      </ModalBackground>,
      modalDomEl,
   );
}

Modal.propTypes = {
   children: PropTypes.node,
   closeModal: PropTypes.func,
};

export default Modal;
