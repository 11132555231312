export const SET_INITIALSTATE = 'SET_INITIALSTATE';
export const SET_TIMESPAN = 'SET_TIMESPAN';
export const SET_USERINFO = 'SET_USERINFO';
export const SET_FILTER = 'SET_FILTER';
export const SET_ALARM = 'SET_ALARM';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_TAGS = 'SET_TAGS';
export const SWITCH_USAGE = 'SWITCH_USAGE';
export const SET_EVENTS = 'SET_EVENTS';
export const SET_STATE_BY_URL = '@@router/LOCATION_CHANGE';
export const SET_NEW_CANCELTOKEN = 'SET_NEW_CANCELTOKEN';
export const SET_VIEW_TIMESPAN = 'SET_VIEW_TIMESPAN';
export const SET_WORLDMAP_METRIC = 'SET_WORLDMAP_METRIC';
export const SET_WORLDMAP_SUBSWITCH = 'SET_WORLDMAP_SUBSWITCH';
export const SET_WORLDMAP_OPTIONS = 'SET_WORLDMAP_OPTIONS';
export const SET_WORLDMAP_VIEW = 'SET_WORLDMAP_VIEW';
export const SET_WORLDMAP_REGION_ZOOM = 'SET_WORLDMAP_REGION_ZOOM';

export const SET_TROUBLESHOOTING_TIMESPAN = 'SET_TROUBLESHOOTING_TIMESPAN';
export const SET_TROUBLESHOOTING_MODE = 'SET_TROUBLESHOOTING_MODE';
export const SET_TROUBLESHOOTING_INGEST_INPUT = 'SET_TROUBLESHOOTING_INGEST_INPUT';
export const SET_TROUBLESHOOTING_INGEST_STREAM = 'SET_TROUBLESHOOTING_INGEST_STREAM';
export const SET_TROUBLESHOOTING_SELECTED_PUBLISH = 'SET_TROUBLESHOOTING_SELECTED_PUBLISH';
export const SET_TROUBLESHOOTING_PUBLISH_FINE_TIMESPAN = 'SET_TROUBLESHOOTING_PUBLISH_FINE_TIMESPAN';
export const RESET_TROUBLESHOOTING = 'RESET_TROUBLESHOOTING';
export const SET_TROUBLESHOOTING_PLAYOUT_INPUT = 'SET_TROUBLESHOOTING_PLAYOUT_INPUT';
export const SET_TROUBLESHOOTING_PLAYOUT_STREAM = 'SET_TROUBLESHOOTING_PLAYOUT_STREAM';
export const SET_TROUBLESHOOTING_SELECTED_PLAYOUT = 'SET_TROUBLESHOOTING_SELECTED_PLAYOUT';
export const SET_TROUBLESHOOTING_PLAYOUT_FINE_TIMESPAN = 'SET_TROUBLESHOOTING_PLAYOUT_FINE_TIMESPAN';
export const SYNC_TROUBLESHOOTING_INGEST = 'SYNC_TROUBLESHOOTING_INGEST';
export const COMPLETE_SYNC_TROUBLESHOOTING_INGEST = 'COMPLETE_SYNC_TROUBLESHOOTING_INGEST';

export const SET_IP_FILTERING_TIMESPAN = 'SET_IP_FILTERING_TIMESPAN';
export const SET_IP_FILTERING_FILTER = 'SET_IP_FILTERING_FILTER';

export const SET_DEMO_MODE = 'SET_DEMO_MODE';
