import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFilterQueryParams, getHttpHeader } from '../../../../../util/FetchTools';

import useDataFetch from '../../../../../hooks/useDataFetch';

import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   PaddingContainer,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../../styledComponents/styledComponents';

import { Scatter } from 'react-chartjs-2';
import { CsvExportFromApiButton, Spinner } from '../../../../common';

import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';

import { CardTitle, Col, Row } from 'reactstrap';

import { cancelTokenSelector, hashSelector, troubleIntervalSelector } from '../../../../../redux/selectors/selectors';

import { PropTypes } from 'prop-types';

import 'spinkit/css/spinkit.css';

import { aggEventChartOptions, getOptionsWithAnnotations } from '../../../../../constants/chartjs';
import { TROUBLESHOOTING_VIEW } from '../../../../../constants/general';
import { setAlarm } from '../../../../../redux/actions/general';
import { TITLE_DATE_TIME_FORMAT } from '../../constants';
import { getEventAnnotatedScatterChartData, getUrlEndString, getUrlStartString } from '../../general/DataProcessing';

const blue = getStyle('--info');

function createChartData(chartData, chartLabels, title, interval, events) {
   const { scatterChartData, annotations } = getEventAnnotatedScatterChartData(
      chartData,
      chartLabels,
      interval,
      events,
   );

   return {
      chartDatasets: {
         datasets: [
            {
               label: title,
               backgroundColor: hexToRgba(blue, 10),
               borderColor: blue,
               borderWidth: 1,
               data: scatterChartData,
               fill: 'origin',
               showLine: true,
               cubicInterpolationMode: 'monotone',
            },
         ],
      },
      annotations,
   };
}

function displayEventDescription(event) {
   return event.desc !== '' ? `${event.type} (${event.desc})` : `${event.type}`;
}

function DefaultPlayoutMetric({
   height,
   start,
   end,
   playerId,
   playoutStart,
   playoutEnd,
   urlName,
   title,
   streamName,
   events,
   csvFilename,
}) {
   const startUnixMilli = start.valueOf();
   const endUnixMilli = end.valueOf();

   const hash = useSelector(hashSelector);
   const interval = useSelector(troubleIntervalSelector);
   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const [chart, setChart] = useState({
      labels: [],
      datasets: [],
   });

   const [chartOptions, setChartOptions] = useState(
      aggEventChartOptions(
         startUnixMilli,
         endUnixMilli,
         title,
         interval.stepsInMilliSec / 1000,
         displayEventDescription,
      ),
   );

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const streamFilterArray = useMemo(() => [streamName], [streamName]);
   const urlFilterQuery = getFilterQueryParams([], [], [], streamFilterArray, TROUBLESHOOTING_VIEW);

   const urlFrom = getUrlStartString(playoutStart, start);
   const urlEnd = getUrlEndString(playoutEnd, end);

   const url = `/api/v2/troubleshooting/${urlName}/agg/timeseries?from=${urlFrom}&to=${urlEnd}&interval=${interval.urlParamName}&playerId=${playerId}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            const { chartData, chartLabels } = data.result;
            if (chartLabels && chartLabels.length > 0) {
               const { chartDatasets, annotations } = createChartData(chartData, chartLabels, title, interval, events);
               // if (title === 'Bitrate [kBits/s]') {
               //    console.log('chartDatasets :>> ', chartDatasets);
               //    console.log('annotations :>> ', annotations);
               // }
               setChart(chartDatasets);
               const xStart = chartLabels[0];
               const xEnd = chartLabels[chartLabels.length - 1];
               setChartOptions(
                  getOptionsWithAnnotations(
                     aggEventChartOptions(
                        xStart,
                        xEnd,
                        title,
                        interval.stepsInMilliSec / 1000,
                        displayEventDescription,
                     ),
                     annotations,
                  ),
               );
            }
         }
      }

      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, interval, events, title, successful, errorMessage, httpStatus, dispatch]);

   const startFormatted = start.format(TITLE_DATE_TIME_FORMAT);
   const endFormatted = end.format(TITLE_DATE_TIME_FORMAT);

   return (
      <PaddingContainer $padding={'0px 8px'}>
         <WidgetContainer $margin={'0px -7px 6px -7px'}>
            <WidgetHeaderContainer>
               <HeightSetContainer $heightInPx={23}>
                  <TwoSideFlexContainer>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <CsvExportFromApiButton
                              url={url}
                              header={header}
                              cancelToken={cancelToken}
                              filename={csvFilename}
                           />
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                     <SimpleFlexRow></SimpleFlexRow>
                  </TwoSideFlexContainer>
               </HeightSetContainer>
            </WidgetHeaderContainer>
            <WidgetBodyContainer>
               <Row>
                  <Col>
                     <CardTitle className='mb-0'>{title}</CardTitle>
                     <div className='small text-muted'>{`From ${startFormatted} to ${endFormatted} \u00A0\u00A0( UTC time )`}</div>
                  </Col>
               </Row>
               <ChartWrapper className='chart-wrapper' height={height} marginTopPx={20}>
                  {errorMessage ? (
                     <CenteredMsgShowing height={height} marginTopPx={20}>
                        {errorMessage.message}
                     </CenteredMsgShowing>
                  ) : (
                     <Spinner loading={isLoading} parentTopMarginPx={60}>
                        <SetChartHeight height={height}>
                           <Scatter data={chart} options={chartOptions} />
                        </SetChartHeight>
                     </Spinner>
                  )}
               </ChartWrapper>
            </WidgetBodyContainer>
         </WidgetContainer>
      </PaddingContainer>
   );
}

DefaultPlayoutMetric.propTypes = {
   height: PropTypes.number,
   start: PropTypes.object,
   end: PropTypes.object,
   playerId: PropTypes.string,
   playoutStart: PropTypes.string,
   playoutEnd: PropTypes.string,
   urlName: PropTypes.string,
   title: PropTypes.string,
   streamName: PropTypes.string,
   events: PropTypes.array,
   csvFilename: PropTypes.string,
};

DefaultPlayoutMetric.defaultProps = {
   height: 300,
};

export default DefaultPlayoutMetric;
