import { AccessLevel } from '../../../constants/AccessLevels';

export const TOP_COUNTRIES_COUNT = 50;

export const WORLDMAP_METRIC = {
   PI: 'pi',
   BUFFER_RATIO: 'ratio',
   LATENCY: 'latency',
   PLAYTIME: 'playtime',
   USAGE: 'usage',
   ABR_PLAYTIME: 'abrPlaytime',
   ABR_VIEWER: 'abrViewer',
   ABR_SWITCHES: 'abrSwitches',
   QOE: 'qoe',
};

export const PLAYOUT = 'playout';
export const INGEST = 'ingest';
export const TOTAL = 'total';
export const RATIO = 'ratio';
export const LATENCY = 'latency';
export const PLAYTIME = 'playtime';
export const UP = 'up';
export const DOWN = 'down';
export const AVG_PLAYTIME = 'avgPlaytime';
export const SCORE = 'score';

export const PI_OPTIONS = {
   BOTH: 'both',
   PLAYOUTS: 'playout',
   INGESTS: 'ingest',
};

export const TrafficDirections = {
   PLAYOUT: 'playout',
   INGEST: 'ingest',
};

export const QOE = 'qoe';

export const ALL_CDNS = 'allCdns';
export const TOP4_CDN = 'top4Cdn';
export const NANO_CDN = 'nanoCdn';

export const ABR_PLAYTIME_OPTIONS = {
   MOBILE: 'mobile',
   DESKTOP: 'desktop',
   TABLE: 'table',
   CHART: 'chart',
};

export const BUFFER_RATIO_VALUE_GREEN = 5;
export const BUFFER_RATIO_VALUE_YELLOW = 10;

export let LATENCY_VALUE_GREEN = 2;
export let LATENCY_VALUE_YELLOW = 4;

export const COLOR_GREEN = (dark, opacity = 1) =>
   dark ? `rgba(0, 179, 0, ${opacity})` : `rgba(128, 255, 128, ${opacity})`;
export const COLOR_YELLOW = (dark, opacity = 1) =>
   dark ? `rgba(230, 184, 0, ${opacity})` : `rgba(255, 235, 153, ${opacity})`;
export const COLOR_RED = (dark, opacity = 1) =>
   dark ? `rgba(204, 0, 0, ${opacity})` : `rgba(255, 153, 153, ${opacity})`;

export const REQUIRED_LEVELS = {
   [WORLDMAP_METRIC.PI]: AccessLevel.FULL,
   [WORLDMAP_METRIC.BUFFER_RATIO]: AccessLevel.PREMIUM,
   [WORLDMAP_METRIC.LATENCY]: AccessLevel.PREMIUM,
   [WORLDMAP_METRIC.PLAYTIME]: AccessLevel.PREMIUM,
   [WORLDMAP_METRIC.USAGE]: AccessLevel.FULL,
   [WORLDMAP_METRIC.ABR_PLAYTIME]: AccessLevel.PREMIUM,
   [WORLDMAP_METRIC.ABR_VIEWER]: AccessLevel.PREMIUM,
   [WORLDMAP_METRIC.ABR_SWITCHES]: AccessLevel.PREMIUM,
   [WORLDMAP_METRIC.QOE]: AccessLevel.ADMIN,
};

export const usRegionNames = {
   US_EASTCOAST_NORTH: 'North of US East Coast',
   US_EASTCOAST_MIDDLE: 'Middle of US East Coast',
   US_EASTCOAST_SOUTH: 'South of US East Coast',
   US_INTERIOR_NORTH: 'North of US Interior',
   US_INTERIOR_SOUTH: 'South of US Interior',
   US_WESTCOAST_NORTH: 'North of US West Coast',
   US_WESTCOAST_SOUTH: 'South of US West Coast',
};

export const VIEW_MODE = {
   COUNTRY: 'country',
   REGION: 'region',
   ZOOM_OUT: 'zoomOut',
};

export const WorldRegions = {
   NORTH_AMERICA: 'North America',
   SOUTH_AMERICA: 'South America',
   EUROPE: 'Europe',
   ASIA: 'Asia',
   AFRICA: 'Africa',
   OCEANIA: 'Oceania',
   INDIA: 'India',
   UNKNOWN: 'unknown',
};

export const RealWorldRegions = {
   NORTH_AMERICA: 'North America',
   SOUTH_AMERICA: 'South America',
   EUROPE: 'Europe',
   ASIA: 'Asia',
   AFRICA: 'Africa',
   OCEANIA: 'Oceania',
   INDIA: 'India',
};

export const WORLD_REGIONS = Object.values(RealWorldRegions);

// prettier-ignore
const WORLD_REGIONS_READABLE = {
   'North America': ['AI', 'AG', 'BS', 'BB', 'BZ', 'BM', 'CA', 'KY', 'CR', 'CU', 'DM', 'DO', 'SV', 'GL', 'GD', 'GP', 'GT'],
   'North America2': ['HT', 'HN', 'JM', 'MQ', 'MX', 'MS', 'NI', 'PA', 'PR', 'BL', 'KN', 'LC', 'MF', 'PM', 'VC', 'SX'],
   'North America3': ['TC', 'US', 'VG', 'VI'],
   'South America': ['AR', 'AW', 'BO', 'BQ', 'BR', 'CL', 'CO', 'CW', 'EC', 'FK', 'GF', 'GY', 'PY', 'PE', 'GS', 'SR', 'TT'],
   'South America2': ['UY', 'VE'],
   'Europe': ['AX', 'AL', 'AD', 'AT', 'BY', 'BE', 'BA', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FO', 'FI', 'FR', 'DE', 'GI'],
   'Europe2': ['GR', 'GG', 'VA', 'HU', 'IS', 'IE', 'IM', 'IT', 'JE', 'LV', 'LI', 'LT', 'LU', 'MT', 'MD', 'MC', 'ME', 'NL'],
   'Europe3': ['MK', 'NO', 'PL', 'PT', 'RO', 'RU', 'SM', 'RS', 'SK', 'SI', 'ES', 'SE', 'CH', 'UA', 'GB'],
   'Asia': ['AF', 'AM', 'AZ', 'BH', 'BD', 'BT', 'KH', 'CN', 'GE', 'HK', 'IR', 'IQ', 'IL', 'JP', 'JO', 'KZ', 'KP', 'KR'],
   'Asia2': ['KW', 'KG', 'LA', 'LB', 'MO', 'MN', 'MM', 'NP', 'OM', 'PK', 'PS', 'PH', 'QA', 'SA', 'SG', 'SY', 'TW', 'TJ'],
   'Asia3': ['TH', 'TR', 'TM', 'AE', 'UZ', 'VN', 'YE', 'MY'],
   'Africa': ['DZ', 'AO', 'BJ', 'BW', 'BF', 'BI', 'CV', 'CM', 'CF', 'TD', 'KM', 'CG', 'CD', 'CI', 'DJ', 'EG', 'GQ'],
   'Africa2': ['ER', 'SZ', 'ET', 'GA', 'GM', 'GH', 'GN', 'GW', 'KE', 'LS', 'LR', 'LY', 'MG', 'MW', 'ML', 'MR', 'MU', 'YT'],
   'Africa3': ['MA', 'MZ', 'NA', 'NE', 'NG', 'RE', 'RW', 'SH', 'ST', 'SN', 'SC', 'SL', 'SO', 'ZA', 'SS', 'SD', 'TZ', 'TG'],
   'Africa4': ['TN', 'UG', 'EH', 'ZM', 'ZW'],
   'Oceania': ['AS', 'AU', 'BN', 'CX', 'CC', 'CK', 'FJ', 'PF', 'GU', 'ID', 'KI', 'MH', 'FM', 'NR', 'NC', 'NZ', 'NU'],
   'Oceania2': ['NF', 'MP', 'PW', 'PG', 'WS', 'SB', 'TL', 'TK', 'TO', 'TV', 'VU', 'WF'],
   'India': ['IN', 'MV', 'LK'],
};

export const WORLD_REGIONS_COUNTRIES = {
   [WorldRegions.NORTH_AMERICA]: getAllPartRegionsArray(WorldRegions.NORTH_AMERICA),
   [WorldRegions.SOUTH_AMERICA]: getAllPartRegionsArray(WorldRegions.SOUTH_AMERICA),
   [WorldRegions.EUROPE]: getAllPartRegionsArray(WorldRegions.EUROPE),
   [WorldRegions.ASIA]: getAllPartRegionsArray(WorldRegions.ASIA),
   [WorldRegions.AFRICA]: getAllPartRegionsArray(WorldRegions.AFRICA),
   [WorldRegions.OCEANIA]: getAllPartRegionsArray(WorldRegions.OCEANIA),
   [WorldRegions.INDIA]: getAllPartRegionsArray(WorldRegions.INDIA),
};

function getAllPartRegionsArray(region) {
   return Object.keys(WORLD_REGIONS_READABLE).reduce((acc, key) => {
      if (key.includes(region)) {
         return acc.concat(WORLD_REGIONS_READABLE[key]);
      }
      return acc;
   }, []);
}

export const COUNTRY_WORLD_REGION_MAP = getCountryWorldRegionMap();

function getCountryWorldRegionMap() {
   const cwrMap = {};
   Object.keys(WORLD_REGIONS_COUNTRIES).forEach((regionKey) => {
      const countries = WORLD_REGIONS_COUNTRIES[regionKey];
      countries.forEach((country) => {
         cwrMap[country] = regionKey;
      });
   });
   return cwrMap;
}

export const MAX_MAPWIDTH_HEIGHT_RATIO = 1.35;
export const MAP_HEIGHT_WIDTH_RATIO = 0.44;

export const WorldZoomProjection = { xTrans: 0, yTrans: -0.035, scaleFactor: 1.0 };

export const WORLD_REGIONS_SETTINGS = {
   [WorldRegions.NORTH_AMERICA]: {
      coor: [-95.37, 43.72],
      backgroundColor: (alpha) => `rgba(0, 0, 30, ${alpha})`,
      zoomProjection: {
         xTrans: 1,
         yTrans: 0.69,
         scaleFactor: 1.75,
      },
   },
   [WorldRegions.SOUTH_AMERICA]: {
      coor: [-58.16, -13.7],
      backgroundColor: (alpha) => `rgba(30, 30, 0, ${alpha})`,
      zoomProjection: {
         xTrans: 0.8,
         yTrans: -0.72,
         scaleFactor: 2.1,
      },
   },
   [WorldRegions.EUROPE]: {
      coor: [13.55, 52.29],
      backgroundColor: (alpha) => `rgba(90, 90, 60, ${alpha})`,
      zoomProjection: {
         xTrans: -0.25,
         yTrans: 1.76,
         scaleFactor: 3.35,
      },
   },
   [WorldRegions.ASIA]: {
      coor: [95.64, 32.81],
      backgroundColor: (alpha) => `rgba(30, 60, 60, ${alpha})`,
      zoomProjection: {
         xTrans: -0.93,
         yTrans: 0.35,
         scaleFactor: 2.2,
      },
   },
   [WorldRegions.AFRICA]: {
      coor: [18.56, 10.62],
      backgroundColor: (alpha) => `rgba(90, 120, 90, ${alpha})`,
      zoomProjection: {
         xTrans: -0.2,
         yTrans: -0.18,
         scaleFactor: 2.1,
      },
   },
   [WorldRegions.OCEANIA]: {
      coor: [142.22, -16.16],
      backgroundColor: (alpha) => `rgba(120, 90, 120, ${alpha})`,
      zoomProjection: {
         xTrans: -1.8,
         yTrans: -0.62,
         scaleFactor: 2.4,
      },
   },
   [WorldRegions.INDIA]: {
      coor: [77.62, 19.6],
      backgroundColor: (alpha) => `rgba(110, 80, 50, ${alpha})`,
      zoomProjection: {
         xTrans: -1.5,
         yTrans: 0.3,
         scaleFactor: 3.5,
      },
   },
   [WorldRegions.UNKNOWN]: {
      coor: [0, 0],
      backgroundColor: () => 'rgba(38,50,56,0.3)',
      zoomProjection: {
         xTrans: 0,
         yTrans: 0,
         scaleFactor: 1,
      },
   },
};

export function getWorldRegionsAggObject(propsToBeAdded = {}) {
   function getWorldObject() {
      return {
         countries: [],
         total: 0,
         ...propsToBeAdded,
      };
   }

   return {
      [WorldRegions.NORTH_AMERICA]: { ...getWorldObject() },
      [WorldRegions.SOUTH_AMERICA]: { ...getWorldObject() },
      [WorldRegions.EUROPE]: { ...getWorldObject() },
      [WorldRegions.ASIA]: { ...getWorldObject() },
      [WorldRegions.AFRICA]: { ...getWorldObject() },
      [WorldRegions.OCEANIA]: { ...getWorldObject() },
      [WorldRegions.INDIA]: { ...getWorldObject() },
   };
}
