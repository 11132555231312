import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import roundTo from 'round-to';

import useDataFetch from '../../../../hooks/useDataFetch';
import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';

import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';

import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';

import { Bar } from 'react-chartjs-2';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, Spinner } from '../../../common';

import { CardTitle, Col, Row } from 'reactstrap';

import { PropTypes } from 'prop-types';

import 'spinkit/css/spinkit.css';
import {
   cancelTokenSelector,
   countriesSelector,
   endTimespanSelector,
   eventsSelector,
   hashSelector,
   startTimespanSelector,
   streamNamesSelector,
   tagsSelector,
   timespanSelector,
   titleTimespanSelector,
} from './../../../../redux/selectors/selectors';

import Modal from '../../../zoom/templates/DefaultTemplate';
import { ABR_PLAYTIME } from './../../../zoom/ZoomConstants';

import { barChartZoomOptions, chartjsColors } from '../../../../constants/chartjs';
import { ABR_VIEW } from '../../../../constants/general';
import { ABR_SETTINGS } from '../../../zoom/widgetSettings/AbrView';
import ZoomHint from '../../../zoom/ZoomHint';

const zoomChartOptions = barChartZoomOptions();

const metricMap = {
   playtime: {
      title: 'Playtime per ABR profile (video height)',
      label: 'play time in ',
      unit: 'seconds',
   },
   viewers: {
      title: 'Viewers per ABR profile (video height)',
      label: 'viewer count',
      unit: '',
   },
};

function addZoomClickHandler(barRef, setModal) {
   return {
      ...zoomChartOptions,
      onClick: (event, item) => {
         if (item.length === 0) {
            return;
         }
         const activePoint = barRef.current.chartInstance.getElementAtEvent(event)[0];
         const data = activePoint._chart.data;
         const index = item[0]._index;
         const profileName = data.labels[index];

         setModal({ toggleShow: true, selectedProfile: profileName });
      },
   };
}

function adaptValuesToChart(value, maxValue, usedMetric) {
   const playtimeMetricIsUsed = metricMap[usedMetric].unit.length > 0;

   if (playtimeMetricIsUsed) {
      let output = value;

      if (maxValue > 36000) {
         output = roundTo(output / 3600, 1);
         metricMap[usedMetric].unit = 'hours';
      } else if (maxValue > 3600) {
         output = roundTo(output / 60, 1);
         metricMap[usedMetric].unit = 'minutes';
      }
      return output;
   }
   return value;
}

function createChartData(data, usedMetric) {
   const updatedChartData = {
      labels: [],
      datasets: [],
   };

   let profileData = data.aggregations.profiles.buckets;
   let profileNames = [];
   const extractedData = {};
   let max = 0;

   profileData.forEach((bucket) => {
      const profileName = bucket.key;
      profileNames.push(profileName);
      extractedData[profileName] = roundTo(bucket.aggMetric.value, 0);
      if (extractedData[profileName] > max) {
         max = extractedData[profileName];
      }
   });

   profileNames.sort((a, b) => a - b);

   const profilesDataset = profileNames.map((profileName) => {
      return adaptValuesToChart(extractedData[profileName], max, usedMetric);
   });

   const selectedColor = chartjsColors[0];
   const { label, unit } = metricMap[usedMetric];

   const datasets = [
      {
         label: label + unit,
         backgroundColor: hexToRgba(selectedColor, 10),
         borderColor: selectedColor,
         borderWidth: 1,
         data: profilesDataset,
         hidden: false,
         fill: false,
      },
   ];

   updatedChartData.labels = profileNames;
   updatedChartData.datasets = datasets;

   return updatedChartData;
}
function ProfileUse({ height, usedMetric }) {
   const [chart, setChart] = useState({
      labels: [],
      datasets: [{ data: [] }],
   });

   const [noData, setNoData] = useState(false);

   const { title } = metricMap[usedMetric];

   const hash = useSelector(hashSelector);
   const countries = useSelector(countriesSelector);
   const tags = useSelector(tagsSelector);
   const events = useSelector(eventsSelector);
   const streamNames = useSelector(streamNamesSelector);

   const timespan = useSelector(timespanSelector);
   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);
   const timespanTitle = useSelector(titleTimespanSelector);

   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const [modal, setModal] = useState({
      toggleShow: false,
      selectedProfile: '',
   });

   const [chartOptions, setChartOptions] = useState(zoomChartOptions);
   const barRef = useRef(null);

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, events, streamNames, ABR_VIEW);

   const url = `/api/v2/abr/profiles/use?from=${start.format()}&to=${end.format()}&groupby=${usedMetric}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            setChart(createChartData(data, usedMetric));
            setNoData(data.aggregations.profiles.buckets.length === 0);
            const newChartOptions = addZoomClickHandler(barRef, setModal);
            setChartOptions(newChartOptions);
         }
      }

      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, dispatch, usedMetric]);

   const { toggleShow, selectedProfile } = modal;

   const errorOrNoData = errorMessage || noData;
   const issueMsg = errorMessage ? errorMessage.message : 'No data available';

   return (
      <WidgetContainer>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <CsvExportFromApiButton
                           url={url}
                           header={header}
                           cancelToken={cancelToken}
                           filename={'Abr_ProfileUse'}
                        />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <ZoomHint />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer>
            <Row>
               <Col>
                  <CardTitle className='mb-0'>{title}</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </Col>
            </Row>
            <ChartWrapper height={height}>
               <Spinner loading={isLoading} parentTopMarginPx={60}>
                  {errorOrNoData ? (
                     <CenteredMsgShowing height={height} marginTopPx={20}>
                        {issueMsg}
                     </CenteredMsgShowing>
                  ) : (
                     <SetChartHeight height={height}>
                        <Bar data={chart} options={chartOptions} ref={barRef} />
                     </SetChartHeight>
                  )}
               </Spinner>
            </ChartWrapper>
         </WidgetBodyContainer>
         {toggleShow && (
            <Modal
               term={selectedProfile}
               timespan={{ start: timespan.gte, end: timespan.lt }}
               metric={ABR_PLAYTIME}
               closeModal={() =>
                  setModal({
                     ...modal,
                     toggleShow: false,
                  })
               }
               settings={ABR_SETTINGS}
            />
         )}
      </WidgetContainer>
   );
}

ProfileUse.propTypes = {
   height: PropTypes.number,
   usedMetric: PropTypes.string,
};

ProfileUse.defaultProps = {
   height: 300,
   usedMetric: '',
};

export default ProfileUse;
