import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';

import logo from '../../../assets/img/brand/logo.png';
import sygnet from '../../../assets/img/brand/sygnet.png';

class DefaultHeader extends Component {
   render() {
      // eslint-disable-next-line
      const { children, ...attributes } = this.props;

      return (
         <React.Fragment>
            <AppSidebarToggler className='d-lg-none' display='md' mobile />
            <AppNavbarBrand
               full={{ src: logo, height: 30, alt: 'Nanocosmos' }}
               minimized={{ src: sygnet, height: 30, alt: 'Nanocosmos' }}
            />
            <AppSidebarToggler className='d-md-down-none' display='lg' />
         </React.Fragment>
      );
   }
}

DefaultHeader.propTypes = {
   children: PropTypes.node,
};

DefaultHeader.defaultProps = {};

export default DefaultHeader;
