import React from 'react';

import { PropTypes } from 'prop-types';

import { HorizontalSeparatorPart, SimpleFlexRow } from '../../styledComponents/styledComponents';

function HorizontalSeparator({ color, widthInPx, heightInPx, marginX_InPx, marginTopInPx }) {
   return (
      <SimpleFlexRow $contentVerticalAlign={'flex-end'}>
         <HorizontalSeparatorPart
            $rightBorder={`${widthInPx}px solid ${color}`}
            $height={heightInPx}
            $marginX_InPx={marginX_InPx}
            $marginTopInPx={marginTopInPx}
         />
         <HorizontalSeparatorPart
            $leftBorder={`${widthInPx}px solid ${color}`}
            $height={heightInPx}
            $marginX_InPx={marginX_InPx}
            $marginTopInPx={marginTopInPx}
         />
      </SimpleFlexRow>
   );
}

HorizontalSeparator.propTypes = {
   color: PropTypes.string,
   widthInPx: PropTypes.number,
   heightInPx: PropTypes.number,
   marginX_InPx: PropTypes.number,
   marginTopInPx: PropTypes.number,
};

export default HorizontalSeparator;
