import React, { useState } from 'react';
import { Input, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
   CenteredFlexContainer,
   StaticCol,
   Description,
   ExtButton,
   StyledButton,
} from '../../../styledComponents/styledComponents';
import styled from 'styled-components';
import { getSelectOption, uniqueIdFactory } from '../../../util/UtilFunctions';
import { MatchSelectComponentColorStyles } from '../../../constants/styles';
import { Tooltip as ReactTooltip } from 'react-tooltip/dist/react-tooltip.umd';
import ReactDOMServer from 'react-dom/server';

export const VariableWidthPopover = styled(UncontrolledPopover)`
   max-width: 500px !important;
`;
VariableWidthPopover.displayName = 'VariableWidthPopover';

function StsTagFilter({ tags, setTags }) {
   const [uid] = useState(uniqueIdFactory('_'));

   const [input, setInput] = useState('');

   function addTag() {
      const tagAlreadyChosen = tags.some((tagOption) => tagOption.value === input);
      if (input && !tagAlreadyChosen) {
         if (tags === null) {
            setTags([getSelectOption(input)]);
         } else {
            setTags([...tags, getSelectOption(input)]);
         }
         setInput('');
      }
   }

   return (
      <>
         <CenteredFlexContainer vertical={'flex-end'}>
            <StaticCol width={270}>
               <Description>
                  {`Filter for secure token parameter 'tag'`}
                  <i
                     data-tooltip-id='sts'
                     data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        <div>
                           {'Click '}
                           <a
                              href='https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_feature_security_sts'
                              target='_blank'
                              rel='noopener noreferrer'
                           >
                              <StyledButton
                                 type='button'
                                 fontSizePx={10}
                                 className={'btn'}
                                 name={'stsBtn'}
                                 id={'stsBtn'}
                                 heightPx={14}
                                 widthPx={45}
                                 bgColor={'#20a8d8'}
                                 fontColor={'#FFF'}
                                 bgColorHover={'#26bcf3'}
                                 fontColorHover={'#111'}
                                 $cursor={'default'}
                                 $verticalAlign={'top'}
                              >
                                 here
                              </StyledButton>
                           </a>
                           {' to learn more about secure tokens.'}
                        </div>,
                     )}
                     className='bi bi-info-circle ml-1'
                  ></i>
                  <ReactTooltip
                     id='sts'
                     place={'top'}
                     className={'reduced-line-height bring-on-top'}
                     backgroundColor={'rgba(0,0,0,0.9)'}
                     textColor={'rgb(240,240,240)'}
                     style={{ textAlign: 'center' }}
                     clickable
                  />
               </Description>
               <Select
                  components={{
                     DropdownIndicator: null,
                  }}
                  styles={MatchSelectComponentColorStyles}
                  value={tags}
                  inputValue={''}
                  onChange={(tags) => setTags(tags === null ? [] : tags)}
                  menuIsOpen={false}
                  isClearable
                  isMulti
                  placeholder={'Click Add to open a dialog'}
                  isDisabled={tags === null || tags.length === 0}
               />
            </StaticCol>
            <ExtButton id={`Popover${uid}`} type='button' color='primary' className='ml-3' $paddingPx={'7.3px 0.75rem'}>
               Add
            </ExtButton>
            <VariableWidthPopover trigger='legacy' placement='bottom' target={`Popover${uid}`}>
               <PopoverHeader>{'Add tag'}</PopoverHeader>
               <PopoverBody>
                  <StaticCol width={285}>
                     <StaticCol width={285} $bottomMarginPx={10}>
                        <Input
                           type='search'
                           name='tagInput'
                           id='tagInput'
                           placeholder='Insert tag to be added'
                           value={input}
                           onChange={(e) => setInput(e.target.value)}
                        />
                     </StaticCol>
                     <CenteredFlexContainer>
                        <ExtButton
                           type='button'
                           $widthPx={150}
                           $bgColor={'rgb(255, 147, 63)'}
                           $color={' #FFF'}
                           $bgColorHover={'rgb(255, 147, 63)'}
                           $fontColorHover={' #FFF'}
                           onClick={addTag}
                           disabled={input.length === 0}
                        >
                           {'Add'}
                        </ExtButton>
                     </CenteredFlexContainer>
                  </StaticCol>
               </PopoverBody>
            </VariableWidthPopover>
         </CenteredFlexContainer>
      </>
   );
}

StsTagFilter.propTypes = {
   tags: PropTypes.arrayOf(
      PropTypes.exact({
         value: PropTypes.string,
         label: PropTypes.string,
      }),
   ),
   setTags: PropTypes.func,
};

export default StsTagFilter;
