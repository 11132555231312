import { TrafficDirections, getWorldRegionsAggObject } from '../WorldMapConstants';
import { getOutput } from './General';
const country_alpha_id_mapper = require('../../../../assets/worldmap/compact_country_alpha_id_mapper.json');

export function processPiAdapter(countries) {
   const adaptedCountries = countries.map((countryBucket) => {
      const { country, alpha2, ingest, playout, total } = countryBucket;
      const { coordinates, worldRegion, code } = country_alpha_id_mapper[alpha2];
      const coor = [coordinates.longitude, coordinates.latitude];
      return {
         alpha: alpha2,
         coor,
         id: code.numeric,
         name: country,
         worldRegion,
         total: total.count,
         ingest: ingest.count,
         playout: playout.count,
      };
   });
   const { both, playout, ingest } = createWorldRegionsAndTopData(adaptedCountries);
   return {
      playout: getOutput(playout.topCountries, playout.worldRegions),
      ingest: getOutput(ingest.topCountries, ingest.worldRegions),
      both: getOutput(both.topCountries, both.worldRegions),
   };
}

function createWorldRegionsAndTopData(countries) {
   const playoutCountries = [];
   const ingestCountries = [];

   const bothWorldRegions = getWorldRegionsAggObject({
      [TrafficDirections.INGEST]: 0,
      [TrafficDirections.PLAYOUT]: 0,
   });

   const playoutWorldRegions = getWorldRegionsAggObject({
      [TrafficDirections.INGEST]: 0,
      [TrafficDirections.PLAYOUT]: 0,
   });

   const ingestWorldRegions = getWorldRegionsAggObject({
      [TrafficDirections.INGEST]: 0,
      [TrafficDirections.PLAYOUT]: 0,
   });

   countries.forEach((country) => {
      const worldRegion = country.worldRegion;
      const playouts = country[TrafficDirections.PLAYOUT];
      const ingests = country[TrafficDirections.INGEST];
      if (ingests > 0) {
         const ingestCountry = { ...country, playout: 0, total: ingests };
         ingestWorldRegions[worldRegion].total += ingests;
         ingestWorldRegions[worldRegion][TrafficDirections.INGEST] += ingests;
         ingestWorldRegions[worldRegion].countries.push(ingestCountry);
         ingestCountries.push(ingestCountry);
      }
      if (playouts > 0) {
         const playoutCountry = { ...country, ingest: 0, total: playouts };
         playoutWorldRegions[worldRegion].total += playouts;
         playoutWorldRegions[worldRegion][TrafficDirections.PLAYOUT] += playouts;
         playoutWorldRegions[worldRegion].countries.push(playoutCountry);
         playoutCountries.push(playoutCountry);
      }

      bothWorldRegions[worldRegion].total += ingests + playouts;
      bothWorldRegions[worldRegion][TrafficDirections.INGEST] += ingests;
      bothWorldRegions[worldRegion][TrafficDirections.PLAYOUT] += playouts;
      bothWorldRegions[worldRegion].countries.push(country);
   });

   const both = { worldRegions: bothWorldRegions, topCountries: countries };
   const playout = { worldRegions: playoutWorldRegions, topCountries: playoutCountries };
   const ingest = { worldRegions: ingestWorldRegions, topCountries: ingestCountries };

   return { both, ingest, playout };
}
