import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import axios from 'axios';
import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, Spinner } from '../../../common';

import { CardTitle, Col, Row } from 'reactstrap';

import { filtersChanged, timespanOrIntervalChanged } from '../../../../redux/selectors/comparing';

import { PropTypes } from 'prop-types';
import storePropTypes from './../../../../redux/store/propTypes';

import { WEBRTC_VIEW } from '../../../../constants/general';
import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';
import { chartjsColors, pieZoomChartOptions } from './../../../../constants/chartjs';
import ComponentTools from './../../../../util/ComponentTools';

import { getUnifiedErrorObject } from '../../../../util/ErrorUnifier';
import { PUBLISH_COUNT } from '../../../zoom/ZoomConstants';
import ZoomHint from '../../../zoom/ZoomHint';
import Modal from '../../../zoom/templates/DefaultTemplate';
import { WEBRTC_SETTINGS } from '../../../zoom/widgetSettings/WebrtcView';

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setAlarm };

let requestForData = false;

class Ingests extends Component {
   constructor(props) {
      super(props);

      this.state = {
         loading: false,
         data: {
            grouping: 'OS',
            labels: [],
            datasets: [{}],
            rawTerms: [],
         },
         modal: {
            toggleShow: false,
            selectedTerm: '',
         },
         error: '',
         noData: false,
         chartOptions: pieZoomChartOptions(),
         url: '',
         header: {},
         cancelToken: null,
      };
      this.chartRef = React.createRef();
   }

   componentDidMount() {
      requestForData = true;
   }

   async componentWillReceiveProps(nextProps, nextContext) {
      if (
         timespanOrIntervalChanged(this.props, nextProps) ||
         filtersChanged(this.props, nextProps) ||
         nextProps.data.grouping !== this.state.data.grouping ||
         requestForData
      ) {
         requestForData = false;
         if (nextProps.data.grouping !== '') {
            await this.setState({
               ...this.state,
               data: {
                  ...this.data,
                  grouping: nextProps.data.grouping,
               },
            });
         }

         this.getData(nextProps.timespan, nextProps.filter, this.state.data.grouping, nextProps.cancelToken);

         const newChartOptions = { ...this.state.chartOptions };
         newChartOptions['onClick'] = (event, item) => {
            if (item.length === 0) {
               return;
            }
            const { _index } = item[0];
            const rawTermsLength = this.state.data.rawTerms.length;

            if (rawTermsLength <= _index) {
               return;
            }

            this.setState({
               modal: {
                  ...this.state.modal,
                  selectedTerm: this.state.data.rawTerms[_index],
                  toggleShow: true,
               },
            });
         };
         this.setState({
            ...this.state,
            chartOptions: newChartOptions,
         });
      }
   }

   getData(timespan, filter, group, cancelToken) {
      this.setState({
         ...this.state,
         loading: true,
         noData: false,
         error: '',
      });

      const self = this;

      const headerObj = ComponentTools.getHttpHeader(filter);
      const urlFilterQuery = ComponentTools.getFilterQueryParams(filter, WEBRTC_VIEW);

      const url = `/api/v2/webrtc/count?from=${timespan.gte.format()}&to=${timespan.lt.format()}&groupby=${group}${urlFilterQuery}`;

      axios
         .get(url, {
            headers: headerObj,
            cancelToken: cancelToken.token,
         })
         .then(function (response) {
            let datasets = [
               {
                  label: 'Publishes',
                  borderWidth: 1,
                  hidden: false,
                  backgroundColor: [],
                  borderColor: [],
                  data: [],
                  labels: [],
               },
            ];

            let noData = false;
            const rawTerms = [];

            if (response.status !== 204) {
               const buckets = response.data.data;
               let total = 0;

               noData = buckets.length === 0;

               let length = buckets.length > 5 ? 5 : buckets.length;
               let percentage = 0;

               buckets.map((c) => {
                  total += c.count;
                  return 0;
               });

               for (let i = 0; i < length; i++) {
                  datasets[0].backgroundColor.push(hexToRgba(chartjsColors[i], 25));
                  datasets[0].borderColor.push(chartjsColors[i]);

                  let pc = (buckets[i].count / total) * 100;
                  let pcr = Math.round(pc * 100) / 100;
                  let labelName = buckets[i].key;
                  rawTerms.push(labelName);

                  // cut off long label names
                  if (labelName.length > 30) {
                     // arbritary limit, at least allow string "Microsoft Internet Explorer"
                     labelName = labelName.substring(0, 27);
                     labelName += '...';
                  }

                  datasets[0].data.push(buckets[i].count);
                  datasets[0].labels.push(labelName + ' (' + pcr + '%)');

                  percentage += pc;
               }

               if (percentage < 100) {
                  datasets[0].backgroundColor[length] = hexToRgba(chartjsColors[10], 25);
                  datasets[0].borderColor[length] = chartjsColors[10];

                  datasets[0].data[length] = Math.round(100 - percentage);
                  datasets[0].labels[length] = 'Others (' + Math.round(100 - percentage) + '%)';
               }
            }

            self.setState({
               ...self.state,
               noData,
               loading: false,
               data: {
                  ...self.state.data,

                  labels: datasets[0].labels,
                  datasets,
                  rawTerms,
               },
               url,
               header: headerObj,
               cancelToken,
            });
         })
         .catch(function (error) {
            if (error instanceof axios.Cancel) {
               return;
            }
            const errorObject = getUnifiedErrorObject(error);
            self.props.setAlarm('danger', errorObject);

            self.setState({
               ...self.state,
               data: {
                  ...self.state.data,
                  labels: [],
                  datasets: [{}],
               },
               loading: false,
               error: errorObject.message,
            });

            if (process.env.NODE_ENV === 'development') {
               console.error('error :', error);
            }
         });
   }

   render() {
      const errorOrNoData = this.state.error || this.state.noData;
      const issueMsg = this.state.error ? this.state.error : 'No data available';

      const { toggleShow, selectedTerm } = this.state.modal;
      const { url, header, cancelToken } = this.state;

      return (
         <WidgetContainer>
            <WidgetHeaderContainer>
               <HeightSetContainer $heightInPx={23}>
                  <TwoSideFlexContainer>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <CsvExportFromApiButton
                              url={url}
                              header={header}
                              cancelToken={cancelToken}
                              filename={`Webrtc_IngestCountPer${this.state.data.grouping}`}
                           />
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <ZoomHint />
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                  </TwoSideFlexContainer>
               </HeightSetContainer>
            </WidgetHeaderContainer>
            <WidgetBodyContainer>
               <Row>
                  <Col>
                     <CardTitle className='mb-0'>Webcaster publish count per {this.state.data.grouping}</CardTitle>
                     <div className='small text-muted'>
                        {`${this.props.timespan.title}\u00A0\u00A0\u00A0( UTC time )`}
                     </div>
                  </Col>
               </Row>
               <ChartWrapper className='chart-wrapper' marginTopPx={20} height={this.props.height}>
                  <Spinner loading={this.state.loading} parentTopMarginPx={60}>
                     {errorOrNoData ? (
                        <CenteredMsgShowing height={this.props.height}>{issueMsg}</CenteredMsgShowing>
                     ) : (
                        <SetChartHeight height={this.props.height}>
                           <Pie
                              ref={this.chartRef}
                              options={this.state.chartOptions}
                              data={this.state.data}
                              height={this.props.height}
                           />
                        </SetChartHeight>
                     )}
                  </Spinner>
               </ChartWrapper>
            </WidgetBodyContainer>
            {toggleShow && (
               <Modal
                  group={this.props.data.grouping.toLowerCase()}
                  term={selectedTerm}
                  timespan={{ start: this.props.timespan.gte, end: this.props.timespan.lt }}
                  metric={PUBLISH_COUNT}
                  closeModal={() =>
                     this.setState({
                        modal: {
                           ...this.state.modal,
                           toggleShow: false,
                        },
                     })
                  }
                  settings={WEBRTC_SETTINGS}
               />
            )}
         </WidgetContainer>
      );
   }
}

Ingests.propTypes = {
   filter: storePropTypes.filter,
   timespan: storePropTypes.timespan,
   cancelToken: storePropTypes.cancelToken,
   data: PropTypes.exact({
      grouping: PropTypes.string,
   }),
   setAlarm: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ingests);
