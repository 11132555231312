import React from 'react';

import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { PropTypes } from 'prop-types';
import { getIndicatorLabel, getSwitchLabel } from './AbrSwitchesLabels';
import { getIntegerWithDecimalSeparator } from '../../../../../../util/NumberFormatter';

const tableStyle = {
   verticalAlign: 'middle',
   textAlign: 'center',
   color: '#FFF',
   fontSize: '12px',
   borderColor: 'rgba(0,0,0,0)',
   padding: '0px',
};

const labels = {
   'high->mid': {
      indicator: getIndicatorLabel('high->mid'),
      text: getSwitchLabel('high->mid'),
   },
   'mid->low': {
      indicator: getIndicatorLabel('mid->low'),
      text: getSwitchLabel('mid->low'),
   },
   'low->mid': {
      indicator: getIndicatorLabel('low->mid'),
      text: getSwitchLabel('low->mid'),
   },
   'mid->high': {
      indicator: getIndicatorLabel('mid->high'),
      text: getSwitchLabel('mid->high'),
   },
};

const columnsHeader = [
   {
      dataField: 'name',
      text: 'ABR switches',
      style: tableStyle,
      headerStyle: { ...tableStyle, width: '121px', textAlign: 'left' },
   },
   {
      dataField: 'switches',
      text: 'Switches',
      headerStyle: { ...tableStyle, width: '75px' },
   },
];

const columnsBody = [
   {
      dataField: 'name',
      text: '',
      style: {
         ...tableStyle,
         textAlign: 'left',
      },
      formatter: (cell, row) => labels[cell]['indicator'],
      headerAlign: 'left',
      headerStyle: { ...tableStyle, width: '50px', textAlign: 'left', paddingLeft: '4px' },
   },
   {
      dataField: 'copiedName',
      text: '',
      style: {
         ...tableStyle,
         textAlign: 'left',
      },
      formatter: (cell, row) => labels[cell]['text'],
      headerAlign: 'left',
      headerStyle: { ...tableStyle, width: '70px', textAlign: 'left', paddingLeft: '4px' },
   },
   {
      dataField: 'switches',
      text: '',
      style: {
         ...tableStyle,
         textAlign: 'right',
         paddingRight: '5px',
         borderRight: '1px solid rgba(255, 255, 255, 0.95)',
      },
      formatter: (cell, row) => getIntegerWithDecimalSeparator(cell),
      headerAlign: 'right',
      headerStyle: { ...tableStyle, textAlign: 'right', width: '50px' },
   },
   {
      dataField: 'percentage',
      text: '',
      style: { ...tableStyle, textAlign: 'left', paddingLeft: '4px' },
      formatter: (cell, row) => `${cell} %`,
      headerAlign: 'left',
      headerStyle: { ...tableStyle, textAlign: 'left', width: '40px' },
   },
];

function AbrSwitchesTable({ data }) {
   const dataWithCopiedName = data.map((country) => ({ ...country, copiedName: country.name }));
   return (
      <>
         <BootstrapTable keyField='name' data={[]} columns={columnsHeader} classes='head-abr-table' bootstrap4 />
         <BootstrapTable
            keyField='name'
            data={dataWithCopiedName}
            columns={columnsBody}
            classes='headless-abr-table'
            bootstrap4
         />
      </>
   );
}

AbrSwitchesTable.propTypes = {
   data: PropTypes.arrayOf(
      PropTypes.shape({
         name: PropTypes.string,
         switches: PropTypes.number,
         percentage: PropTypes.number,
      }),
   ),
};

export default AbrSwitchesTable;
