import React from 'react';
import styled from 'styled-components';

import AbrCompareTable from './AbrSubComponents/AbrCompareTable';
import AbrBarChart from './AbrSubComponents/AbrBarChart';
import AbrPieChart from './AbrSubComponents/AbrPieChart';

import { ABR_PLAYTIME_OPTIONS } from '../../WorldMapConstants';

const AbrDesc = styled.div`
   margin-bottom: 10px;
   font-size: 12px;
`;
AbrDesc.displayName = 'AbrDesc';

function AbrPlaytimeContent({ countryData, subSwitch, isHovered, isUnhoverTransition }) {
   return (
      <>
         {(isHovered || isUnhoverTransition) && (
            <>
               <AbrDesc>{'playtime per ABR profile (video height)'}</AbrDesc>
               {(subSwitch === ABR_PLAYTIME_OPTIONS.DESKTOP || subSwitch === ABR_PLAYTIME_OPTIONS.MOBILE) && (
                  <AbrPieChart data={countryData} height={300} />
               )}
               {subSwitch === ABR_PLAYTIME_OPTIONS.TABLE && <AbrCompareTable data={countryData} />}
               {subSwitch === ABR_PLAYTIME_OPTIONS.CHART && <AbrBarChart data={countryData} />}
            </>
         )}
      </>
   );
}

export default AbrPlaytimeContent;
