import { COLOR_GREEN, COLOR_YELLOW, COLOR_RED } from '../WorldMapConstants';
import { getHeatMapColors } from '../Common/tools/ValueColorizer';
import { GREEN_TO_RED_10_STEPS_LIGHT } from '../../../../constants/colors';

export function getHeatScale({ numberFormat, valueManipulation, maxHeatValue }) {
   if (maxHeatValue === 0) {
      return [];
   }

   return [
      { value: valueManipulation(numberFormat(0, maxHeatValue)), color: getHeatMapColors(0, maxHeatValue, 0.5) },
      {
         value: valueManipulation(numberFormat(Math.round(maxHeatValue * 0.33), maxHeatValue)),
         color: getHeatMapColors(maxHeatValue * 0.33, maxHeatValue, 0.5),
      },
      {
         value: valueManipulation(numberFormat(Math.round(maxHeatValue * 0.66), maxHeatValue)),
         color: getHeatMapColors(maxHeatValue * 0.66, maxHeatValue, 0.5),
      },
      {
         value: valueManipulation(numberFormat(maxHeatValue, maxHeatValue)),
         color: getHeatMapColors(maxHeatValue, maxHeatValue, 0.5),
      },
   ];
}

export function getTrafficLightScale({ greenValue, yellowValue, valueManipulation, show }) {
   if (!show) {
      return [];
   }
   return [
      { value: `< ${valueManipulation(greenValue)}`, color: COLOR_GREEN(true, 0.6) },
      { value: `< ${valueManipulation(yellowValue)}`, color: COLOR_YELLOW(true, 0.6) },
      { value: `\u{2265} ${valueManipulation(yellowValue)}`, color: COLOR_RED(true, 0.6) },
   ];
}

export function getGreenRedScale({ numberFormat, valueManipulation, min, max, show }) {
   if (!show) {
      return [];
   }
   return [
      { value: valueManipulation(numberFormat(max, max)), color: GREEN_TO_RED_10_STEPS_LIGHT[0] },
      { value: valueManipulation(numberFormat(Math.round(max * 0.8), max)), color: GREEN_TO_RED_10_STEPS_LIGHT[2] },
      {
         value: valueManipulation(numberFormat(Math.round(max * 0.6), max)),
         color: GREEN_TO_RED_10_STEPS_LIGHT[4],
      },
      {
         value: valueManipulation(numberFormat(Math.round(max * 0.4), max)),
         color: GREEN_TO_RED_10_STEPS_LIGHT[5],
      },
      {
         value: valueManipulation(numberFormat(Math.round(max * 0.2), max)),
         color: GREEN_TO_RED_10_STEPS_LIGHT[7],
      },
      {
         value: valueManipulation(numberFormat(min, max)),
         color: GREEN_TO_RED_10_STEPS_LIGHT[9],
      },
   ];
}
