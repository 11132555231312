import './polyfill';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './redux/store/configureStore';
import App from './App';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import 'chartjs-plugin-annotation';

const store = configureStore();
const queryClient = new QueryClient();

// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
   <Provider store={store}>
      <QueryClientProvider client={queryClient}>
         <ConnectedRouter history={history}>
            <App />
            {/* <ReactQueryDevtools /> */}
         </ConnectedRouter>
      </QueryClientProvider>
   </Provider>,
   document.getElementById('root'),
);

// disable ServiceWorker
// registerServiceWorker();
