import React, { Component } from 'react';
import { Dashboard } from '../../../common';
import Widget from '../../Widget/Widget';
import { connect } from 'react-redux';
import { AccessLevel } from '../../../../constants/AccessLevels';

import { Usage, Countries, MonthToDate, UsagePlayout, UsageIngest, ConcurrentViewer } from '../../../widgets/cdn';

import { Col, Row } from 'reactstrap';

import storePropTypes from './../../../../redux/store/propTypes';
import TrafficPlaytimeSwitch from '../../../common/TrafficPlaytimeSwitch/TrafficPlaytimeSwitch';

import axios from 'axios';
import { setNewCancelToken, setViewTimespan } from './../../../../redux/actions/general';

import { PropTypes } from 'prop-types';
import { HOME_VIEW } from './../../../../constants/general';

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setNewCancelToken, setViewTimespan };

class Home extends Component {
   componentWillUnmount() {
      // cancel token, added to old requests, will be activated --> old requests will be cancelled
      this.props.cancelToken.cancel('Operation canceled by the user.');
      this.props.setViewTimespan(HOME_VIEW);
      this.props.setNewCancelToken(axios.CancelToken.source());
   }

   render() {
      return (
         <Dashboard location={this.props.location} history={this.props.history} view={HOME_VIEW}>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                  <TrafficPlaytimeSwitch />
               </Col>
            </Row>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget type={Usage} widgetAttributes={{ height: 300 }} />
               </Col>
               <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                  <Widget type={ConcurrentViewer} widgetAttributes={{ height: 300 }} requiredLevel={AccessLevel.FULL} />
               </Col>
            </Row>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                  <Widget type={MonthToDate} widgetAttributes={{ height: 300 }} />
               </Col>
            </Row>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='12' xl='6'>
                  <Widget type={UsagePlayout} requiredLevel={AccessLevel.FULL} widgetAttributes={{ height: 300 }} />
               </Col>
               <Col xs='12' sm='12' md='12' lg='12' xl='6'>
                  <Widget type={UsageIngest} requiredLevel={AccessLevel.FULL} widgetAttributes={{ height: 300 }} />
               </Col>
            </Row>
            <Row className='animated fadeIn'>
               <Col xs='12' sm='12' md='12' lg='12' xl='6'>
                  <Widget type={Countries} requiredLevel={AccessLevel.FULL} widgetAttributes={{ height: 300 }} />
               </Col>
            </Row>
         </Dashboard>
      );
   }
}

Home.propTypes = {
   cancelToken: storePropTypes.cancelToken,
   setNewCancelToken: PropTypes.func,
   setViewTimespan: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
