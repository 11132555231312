import React from 'react';
import Loadable from 'react-loadable';
import { AccessLevel } from '../../constants/AccessLevels';

import DefaultLayout from './DefaultLayout';

import {
   ABR_VIEW,
   BREAKDOWN_VIEW,
   DOC_VIEW,
   H5LIVE_VIEW,
   HISTORY_VIEW,
   HOME_VIEW,
   QOE_VIEW,
   TROUBLESHOOTING_VIEW,
   WEBRTC_VIEW,
   WORLD_VIEW,
   IP_FILTERING_VIEW,
} from '../../constants/general';

function Loading() {
   return <div>Loading...</div>;
}

const World = Loadable({
   loader: () => import('./contentArea/World/World'),
   loading: Loading,
});

const Home = Loadable({
   loader: () => import('./contentArea/Home/Home'),
   loading: Loading,
});

const BreakDown = Loadable({
   loader: () => import('./contentArea/BreakDown/BreakDown'),
   loading: Loading,
});

const H5Live = Loadable({
   loader: () => import('./contentArea/H5Live/H5Live'),
   loading: Loading,
});

const ABR = Loadable({
   loader: () => import('./contentArea/ABR/ABR'),
   loading: Loading,
});

const QoE = Loadable({
   loader: () => import('./contentArea/QoE/QoE'),
   loading: Loading,
});

const WebRTC = Loadable({
   loader: () => import('./contentArea/WebRTC/WebRTC'),
   loading: Loading,
});

const UsageHistory = Loadable({
   loader: () => import('./contentArea/UsageHistory/UsageHistory'),
   loading: Loading,
});

const Troubleshooting = Loadable({
   loader: () => import('./contentArea/Troubleshooting/Troubleshooting'),
   loading: Loading,
});

const IpFiltering = Loadable({
   loader: () => import('./contentArea/IpFiltering/IpFiltering'),
   loading: Loading,
});

const Documentation = Loadable({
   loader: () => import('./contentArea/Documentation'),
   loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
   { path: '/', name: 'Portal', level: AccessLevel.BASIC, component: DefaultLayout, exact: true },
   { path: `/${HOME_VIEW}`, name: 'Home', level: AccessLevel.BASIC, component: Home },
   { path: `/${BREAKDOWN_VIEW}`, name: 'BreakDown', level: AccessLevel.BASIC, component: BreakDown },
   { path: `/${WORLD_VIEW}`, name: 'World', level: AccessLevel.BASIC, component: World },
   { path: `/${H5LIVE_VIEW}`, name: 'H5Live', level: AccessLevel.FULL, component: H5Live },
   { path: `/${ABR_VIEW}`, name: 'ABR', level: AccessLevel.PREMIUM, component: ABR },
   { path: `/${QOE_VIEW}`, name: 'QoE', level: AccessLevel.ADMIN, component: QoE },
   { path: `/${WEBRTC_VIEW}`, name: 'Webcaster', level: AccessLevel.FULL, component: WebRTC },
   { path: `/${HISTORY_VIEW}`, name: 'Usage History', level: AccessLevel.FULL, component: UsageHistory },
   { path: `/${TROUBLESHOOTING_VIEW}`, name: 'Troubleshooting', level: AccessLevel.FULL, component: Troubleshooting },
   { path: `/${IP_FILTERING_VIEW}`, name: 'Guardian', level: AccessLevel.BASIC, component: IpFiltering },
   { path: `/${DOC_VIEW}`, name: 'Documentation', level: AccessLevel.NONE, component: Documentation },
];

export default routes;
