import React from 'react';

import styled from 'styled-components';

import { PropTypes } from 'prop-types';

const FlexContainer = styled.div`
   display: flex;
`;
FlexContainer.displayName = 'FlexContainer';

const Item = styled.div`
   flex-grow: 1;
   display: flex;
   flex-direction: column;
`;
Item.displayName = 'Item';

const CenteredItem = styled.div`
   height: 30px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin: 0px 10px;
`;
CenteredItem.displayName = 'CenteredItem';

const UpperLineItem = styled.div`
   flex-grow: 1;
`;
UpperLineItem.displayName = 'UpperLineItem';

const BottomLineItem = styled.div`
   flex-grow: 1;
   ${(props) => (props.border === undefined ? 'border-top: 1px groove rgba(0, 0, 0, 0.2);' : '')};
   border-top-color: ${(props) => props.borderTopColor};
`;
BottomLineItem.displayName = 'BottomLineItem';

const darkGreyTextcolor = 'rgba(0, 0, 0, 0.7)';

const TextField = styled.div`
   color: ${(props) => (props.color ? props.color : darkGreyTextcolor)};
   ${(props) => (props.fontsize !== undefined ? `font-size: ${props.fontsize}px;` : '')};
`;
TextField.displayName = 'TextField';

function HeadLineSeparator({ text, color, secondLine, fontsize, onClickHandler = () => {} }) {
   return (
      <FlexContainer onClick={onClickHandler}>
         <Item>
            <UpperLineItem></UpperLineItem>
            <BottomLineItem borderTopColor={color}></BottomLineItem>
         </Item>
         <CenteredItem>
            {secondLine === undefined ? (
               <TextField color={color} fontsize={fontsize}>
                  {text}
               </TextField>
            ) : (
               <>
                  <UpperLineItem>
                     <TextField color={color} fontsize={fontsize}>
                        {text}
                     </TextField>
                  </UpperLineItem>
                  <BottomLineItem borderTopColor={'#FFF'} border={false}>
                     <TextField color={color} fontsize={fontsize}>
                        {secondLine}
                     </TextField>
                  </BottomLineItem>
               </>
            )}
         </CenteredItem>
         <Item>
            <UpperLineItem></UpperLineItem>
            <BottomLineItem borderTopColor={color}></BottomLineItem>
         </Item>
      </FlexContainer>
   );
}

HeadLineSeparator.propTypes = {
   text: PropTypes.string,
   color: PropTypes.string,
   fontsize: PropTypes.number,
   secondLine: PropTypes.string,
   onClickHandler: PropTypes.func,
};

export default HeadLineSeparator;
