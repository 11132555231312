import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';

import { setAlarm, setFilter, setCountries } from '../../../redux/actions/general';

import { Button, Col, Row } from 'reactstrap';

import storePropTypes from '../../../redux/store/propTypes';
import { PropTypes } from 'prop-types';

import {
   timespanChanged,
   orgaHashChanged,
   filterDataNotYetFetched,
   filterChanged,
} from '../../../redux/selectors/comparing';
import {
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../styledComponents/styledComponents';
import { DefaultGrey } from '../../../constants/colors';
import { HISTORY_VIEW } from '../../../constants/general';

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setCountries, setFilter, setAlarm };

let countriesIsBeingFetched = false;

class CountryFilter extends Component {
   constructor(props) {
      super(props);

      this.state = {
         countries: [],
         filter: {
            countries: props.filter.countries,
         },
      };
   }

   // collect user selection(s), but don't trigger a dispatch
   handleChange = (selectedOptions) => {
      const selectedCountries = selectedOptions === null ? [] : selectedOptions;
      this.setState({ filter: { countries: selectedCountries } });
   };

   applySelection = () => {
      this.props.setFilter(
         {
            ...this.props.filter,
            countries: this.state.filter.countries,
         },
         { countries: this.state.filter.countries },
      );
   };

   getCountries(timespan, filter, cancelToken, view) {
      const self = this;

      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      let token = userInfo.token;

      let headerObj = {
         'x-access-token': token,
         Accept: 'application/json',
         'Content-Type': 'application/json;charset=UTF-8',
      };

      if (filter.hash !== '') {
         headerObj['x-orga-hash'] = filter.hash;
      }

      axios
         .get(
            `/api/v2/filters/countries?from=${timespan.gte.format()}&to=${timespan.lt.format()}${
               view === HISTORY_VIEW ? '&storage=longterm' : ''
            }`,
            {
               headers: headerObj,
               cancelToken: cancelToken.token,
            },
         )
         .then(function (response) {
            const countries = response.data.data.map((bucket) => {
               return {
                  value: bucket.filterValue,
                  label: bucket.countryName,
               };
            });

            self.props.setCountries(countries);
            // countriesAlreadyCached = true;

            self.setState({ filter: { countries: filter.countries } });
         })
         .catch((error) => {
            if (error instanceof axios.Cancel) {
               return;
            }
            if (process.env.NODE_ENV === 'development') {
               console.error(error);
            }
         })
         .finally(function () {
            countriesIsBeingFetched = false;
         });
   }

   componentWillReceiveProps(nextProps) {
      if (
         timespanChanged(this.props, nextProps) ||
         orgaHashChanged(this.props, nextProps) ||
         filterDataNotYetFetched(nextProps, 'countries', countriesIsBeingFetched)
      ) {
         countriesIsBeingFetched = true;
         this.getCountries(nextProps.timespan, nextProps.filter, nextProps.cancelToken, nextProps.currentView);
      }

      // required for URL sync: if countries filter is set by URL (with no hash/timespan change)

      if (filterChanged(this.state.filter.countries, nextProps.filter.countries)) {
         this.setState({ filter: { countries: nextProps.filter.countries } });
      }
   }

   render() {
      return (
         <div style={{ position: 'relative', zIndex: 14 }} className='animated fadeIn'>
            <Row>
               <Col>
                  <WidgetContainer>
                     <WidgetHeaderContainer $bgColor={DefaultGrey(1, 247)}>
                        <strong>Country filter</strong>
                     </WidgetHeaderContainer>
                     <WidgetBodyContainer>
                        <div className='customRow'>
                           <div className='selectCol'>
                              <Select
                                 value={this.state.filter.countries}
                                 onChange={this.handleChange}
                                 options={this.props.countries}
                                 isMulti={true}
                                 closeMenuOnSelect={false}
                              />
                           </div>
                           <div className='buttonCol'>
                              <Button color='primary' onClick={this.applySelection}>
                                 Go
                              </Button>
                           </div>
                        </div>
                     </WidgetBodyContainer>
                  </WidgetContainer>
               </Col>
            </Row>
         </div>
      );
   }
}

CountryFilter.propTypes = {
   filter: storePropTypes.filter,
   timespan: storePropTypes.timespan,
   countries: storePropTypes.countries,
   cancelToken: storePropTypes.cancelToken,
   setCountries: PropTypes.func,
   setFilter: PropTypes.func,
   setAlarm: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryFilter);
