export const AccessLevel = {
   NONE: 0,
   BASIC: 1,
   FULL: 2,
   PREMIUM: 3,
   ADMIN: 4,
};

export const mapLevelCodeToName = {};
Object.entries(AccessLevel).forEach(([key, value]) => (mapLevelCodeToName[value] = key));

export const JwtAudienceOptions = {
   BINTU: 'bintu',
   GUARDIAN: 'guardian',
   METRICS: 'metrics',
};
