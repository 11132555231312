import { getFullCountryNames } from '../../util/UtilFunctions';
import { showOverflowTooltip, showAlwaysOverflowTooltip } from '../common/OverflowTooltip/OverflowTooltip';
import { WORLDMAP_METRIC } from '../widgets/world/WorldMapConstants';
const BitsPerSecond = 'Bits/s';

const hiddenColumn = {
   text: 'hiddenKey',
   dataField: 'hiddenKey',
   hidden: 'true',
};

const defaultStyle = { verticalAlign: 'middle', textAlign: 'center' };

const UnitConversion = {
   seconds: ' s',
   count: '',
   percent: ' %',
   points: '',
   [BitsPerSecond]: ` ${BitsPerSecond}`,
};

const lastCharIsLetterRegex = /[a-zA-Z]$/;

export function getColumnsSettings(
   metric,
   desc,
   columnNames,
   columnNamesSortFn,
   tableData,
   rawFormatters,
   rawStyles,
   usageSwitch,
) {
   const useDynamicColumns = typeof columnNames === 'function';
   const sortFn = useDynamicColumns ? columnNamesSortFn : undefined;

   const { dataFields, isOrgaAvailable } = getDataStructure(tableData, sortFn);

   const formatters = usageSwitch ? rawFormatters(usageSwitch) : rawFormatters;
   const scaleMaxValue = Math.max(...dataFields.map(({ value }) => value));

   let customStyles =
      metric === WORLDMAP_METRIC.ABR_PLAYTIME || metric === WORLDMAP_METRIC.ABR_VIEWER
         ? rawStyles(scaleMaxValue)
         : rawStyles;

   const columns = [
      hiddenColumn,
      {
         text: desc,
         dataField: 'key',
         sort: true,
         style: defaultStyle,
         headerAlign: 'center',
         formatter: (cell) => showOverflowTooltip(getFullCountryNames(cell)),
      },
   ];

   if (isOrgaAvailable) {
      columns.push({
         text: 'Organisation',
         dataField: 'orga',
         sort: true,
         style: defaultStyle,
         headerAlign: 'center',
         formatter: showOverflowTooltip,
         headerFormatter: (col) => showAlwaysOverflowTooltip(col.text),
      });
   }

   dataFields.forEach(({ metricName, unitName, dataField }) => {
      // console.log('columnNames(metricName) => ', columnNames(metricName));
      // console.log('columnNames => ', columnNames);
      // console.log('metricName => ', metricName);
      // console.log('columnNames[metricName] => ', columnNames[metricName]);

      columns.push({
         text: useDynamicColumns ? columnNames(metricName) : columnNames[metricName],
         dataField: dataField,
         sort: true,
         headerAlign: 'center',
         formatter: isFormatterForAllColumns(formatters)
            ? unitFormatter(formatters, unitName)
            : unitFormatter(formatters[metricName], unitName),
         headerFormatter: (col) => showAlwaysOverflowTooltip(col.text),
         style:
            customStyles === undefined
               ? defaultStyle
               : isStyleForAllColumns(customStyles)
               ? customStyles
               : customStyles[metricName],
      });
   });

   return columns;
}

function isFormatterForAllColumns(formatter) {
   return typeof formatter === 'function';
}

function isStyleForAllColumns(style) {
   return typeof style === 'function';
}

function unitFormatter(formatter, unit) {
   return function (cell) {
      const formattedValue = formatter(cell);
      const shownValue = formattedValueHasAlreadyUnit(formattedValue)
         ? formattedValue
         : `${formattedValue}${UnitConversion[unit]}`;
      return showOverflowTooltip(shownValue);
   };
}

function formattedValueHasAlreadyUnit(formattedValue) {
   return lastCharIsLetterRegex.test(formattedValue);
}

function getDataStructure(tableData, sortFn) {
   const dataStructure = {
      dataFields: [],
      isOrgaAvailable: false,
   };

   if (tableData && tableData.length > 0) {
      const firstRow = tableData[0];
      if (firstRow.orga) dataStructure.isOrgaAvailable = true;
      const rowProps = Object.keys(firstRow);
      // console.log('rowProps => ', rowProps);
      rowProps.forEach((propName) => {
         if (propName !== 'key' && propName !== 'orga') {
            const metricName = propName;
            const unitName = Object.keys(firstRow[metricName])[0];
            dataStructure.dataFields.push({
               metricName,
               unitName,
               dataField: `${metricName}.${unitName}`,
               value: firstRow[metricName][unitName],
            });
         }
      });
   }

   if (sortFn) dataStructure.dataFields.sort(sortFn);

   return dataStructure;
}
