import { processAbrPlaytimeAdapter } from '../ApiResponseAdapter/AbrPlaytime';
import { processAbrSwitchesAdapter } from '../ApiResponseAdapter/AbrSwitches';
import { processAbrViewersAdapter } from '../ApiResponseAdapter/AbrViewers';
import { processBufferingRatioAdapter } from '../ApiResponseAdapter/BufferingRatio';
import { processByteUsageAdapter } from '../ApiResponseAdapter/ByteUsage';
import { processLatencyAdapter } from '../ApiResponseAdapter/Latency';
import { processPiAdapter } from '../ApiResponseAdapter/PlayoutIngest';
import { processPlaytimeAdapter } from '../ApiResponseAdapter/Playtime';
import { processQoeAdapter } from '../ApiResponseAdapter/Qoe';
import {
   WORLDMAP_METRIC,
   ABR_PLAYTIME_OPTIONS,
   PI_OPTIONS,
   getWorldRegionsAggObject,
   PLAYOUT,
   INGEST,
   TOTAL,
   RATIO,
   LATENCY,
   PLAYTIME,
   DOWN,
   UP,
   AVG_PLAYTIME,
   SCORE,
} from '../WorldMapConstants';

// metricName: prop which defines the metric value e.g. latency
// worldRegionPropNames: props which are used by the world region mode
// countPropName: prop which defines count for ranking e.g. unique plays
// maxHeatPropName: prop which defines max value for heatmap legend/circle color e.g. unique plays
export const METRIC_PROCESSING = {
   [WORLDMAP_METRIC.PI]: {
      metricName: WORLDMAP_METRIC.PI,
      worldRegionPropNames: [PLAYOUT, INGEST, TOTAL],
      countPropName: TOTAL,
      maxHeatPropName: TOTAL,
   },
   [WORLDMAP_METRIC.BUFFER_RATIO]: {
      metricName: WORLDMAP_METRIC.BUFFER_RATIO,
      worldRegionPropNames: [RATIO, TOTAL],
      countPropName: TOTAL,
   },
   [WORLDMAP_METRIC.LATENCY]: {
      metricName: WORLDMAP_METRIC.LATENCY,
      worldRegionPropNames: [LATENCY, TOTAL],
      countPropName: TOTAL,
   },
   [WORLDMAP_METRIC.PLAYTIME]: {
      metricName: WORLDMAP_METRIC.PLAYTIME,
      worldRegionPropNames: [PLAYTIME, TOTAL],
      countPropName: TOTAL,
      maxHeatPropName: WORLDMAP_METRIC.PLAYTIME,
   },
   [WORLDMAP_METRIC.USAGE]: {
      metricName: WORLDMAP_METRIC.USAGE,
      worldRegionPropNames: [PLAYOUT, INGEST, TOTAL],
      countPropName: TOTAL,
      maxHeatPropName: TOTAL,
   },
   [WORLDMAP_METRIC.ABR_PLAYTIME]: {
      metricName: WORLDMAP_METRIC.ABR_PLAYTIME,
      worldRegionPropNames: [TOTAL],
      countPropName: TOTAL,
      maxHeatPropName: TOTAL,
   },
   [WORLDMAP_METRIC.ABR_VIEWER]: {
      metricName: WORLDMAP_METRIC.ABR_VIEWER,
      worldRegionPropNames: [TOTAL, AVG_PLAYTIME],
      countPropName: TOTAL,
      maxHeatPropName: TOTAL,
   },
   [WORLDMAP_METRIC.ABR_SWITCHES]: {
      metricName: WORLDMAP_METRIC.ABR_SWITCHES,
      worldRegionPropNames: [TOTAL, UP, DOWN],
      countPropName: TOTAL,
      maxHeatPropName: TOTAL,
   },
   [WORLDMAP_METRIC.QOE]: {
      metricName: WORLDMAP_METRIC.QOE,
      worldRegionPropNames: [TOTAL, SCORE],
      countPropName: TOTAL,
      additionalPropName: SCORE,
   },
};

const PI_Initial_Data = {
   [PI_OPTIONS.BOTH]: getInitialState(),
   [PI_OPTIONS.INGESTS]: getInitialState(),
   [PI_OPTIONS.PLAYOUTS]: getInitialState(),
};

const ABR_Initial_Data = {
   [ABR_PLAYTIME_OPTIONS.TABLE]: getInitialState(),
   [ABR_PLAYTIME_OPTIONS.CHART]: getInitialState(),
   [ABR_PLAYTIME_OPTIONS.MOBILE]: getInitialState(),
   [ABR_PLAYTIME_OPTIONS.DESKTOP]: getInitialState(),
};

export const METRIC_COMPONENTS = {
   [WORLDMAP_METRIC.PI]: {
      urlParams: { path: 'world/playoutIngest' },
      responseFn: (response) => (response.status !== 204 ? processPiAdapter(response.data.data) : PI_Initial_Data),
      initial: {
         initialState: PI_Initial_Data,
         initialSubSwitch: PI_OPTIONS.BOTH,
      },
   },
   [WORLDMAP_METRIC.BUFFER_RATIO]: {
      urlParams: { path: 'world/buffering/ratio' },
      responseFn: (response) =>
         response.status !== 204
            ? { [WORLDMAP_METRIC.BUFFER_RATIO]: processBufferingRatioAdapter(response.data.data) }
            : { [WORLDMAP_METRIC.BUFFER_RATIO]: getInitialState() },
      initial: {
         initialState: { [WORLDMAP_METRIC.BUFFER_RATIO]: getInitialState() },
      },
   },
   [WORLDMAP_METRIC.LATENCY]: {
      urlParams: { path: 'world/latency' },
      responseFn: (response) =>
         response.status !== 204
            ? { [WORLDMAP_METRIC.LATENCY]: processLatencyAdapter(response.data.data) }
            : { [WORLDMAP_METRIC.LATENCY]: getInitialState() },
      initial: {
         initialState: { [WORLDMAP_METRIC.LATENCY]: getInitialState() },
      },
   },
   [WORLDMAP_METRIC.PLAYTIME]: {
      urlParams: { path: 'world/playtime' },
      responseFn: (response) =>
         response.status !== 204
            ? { [WORLDMAP_METRIC.PLAYTIME]: processPlaytimeAdapter(response.data.data) }
            : { [WORLDMAP_METRIC.PLAYTIME]: getInitialState() },
      initial: {
         initialState: { [WORLDMAP_METRIC.PLAYTIME]: getInitialState() },
      },
   },
   [WORLDMAP_METRIC.USAGE]: {
      urlParams: { path: 'world/usage' },
      responseFn: (response) =>
         response.status !== 204
            ? { [WORLDMAP_METRIC.USAGE]: processByteUsageAdapter(response.data.data) }
            : { [WORLDMAP_METRIC.USAGE]: getInitialState() },
      initial: {
         initialState: { [WORLDMAP_METRIC.USAGE]: getInitialState() },
      },
   },
   [WORLDMAP_METRIC.ABR_PLAYTIME]: {
      urlParams: { path: 'world/abr/playtime' },
      responseFn: (response) =>
         response.status !== 204 ? spreadAbrData(processAbrPlaytimeAdapter(response.data.data)) : ABR_Initial_Data,
      initial: {
         initialState: ABR_Initial_Data,
         initialSubSwitch: ABR_PLAYTIME_OPTIONS.CHART,
      },
   },
   [WORLDMAP_METRIC.ABR_VIEWER]: {
      urlParams: { path: 'world/abr/viewer' },
      responseFn: (response) =>
         response.status !== 204
            ? { [WORLDMAP_METRIC.ABR_VIEWER]: processAbrViewersAdapter(response.data.data) }
            : { [WORLDMAP_METRIC.ABR_VIEWER]: getInitialState() },
      initial: {
         initialState: { [WORLDMAP_METRIC.ABR_VIEWER]: getInitialState() },
      },
   },
   [WORLDMAP_METRIC.ABR_SWITCHES]: {
      urlParams: { path: 'world/abr/switches' },
      responseFn: (response) =>
         response.status !== 204
            ? { [WORLDMAP_METRIC.ABR_SWITCHES]: processAbrSwitchesAdapter(response.data.data) }
            : { [WORLDMAP_METRIC.ABR_SWITCHES]: getInitialState() },
      initial: {
         initialState: { [WORLDMAP_METRIC.ABR_SWITCHES]: getInitialState() },
      },
   },
   [WORLDMAP_METRIC.QOE]: {
      urlParams: { path: 'world/qoe' },
      responseFn: (response) =>
         response.status !== 204
            ? { [WORLDMAP_METRIC.QOE]: processQoeAdapter(response.data.data) }
            : { [WORLDMAP_METRIC.QOE]: getInitialState() },
      initial: {
         initialState: { [WORLDMAP_METRIC.QOE]: getInitialState() },
      },
   },
};

function spreadAbrData(abrData) {
   return {
      [ABR_PLAYTIME_OPTIONS.TABLE]: abrData['chartTable'],
      [ABR_PLAYTIME_OPTIONS.CHART]: abrData['chartTable'],
      [ABR_PLAYTIME_OPTIONS.MOBILE]: abrData[ABR_PLAYTIME_OPTIONS.MOBILE],
      [ABR_PLAYTIME_OPTIONS.DESKTOP]: abrData[ABR_PLAYTIME_OPTIONS.DESKTOP],
   };
}

function getInitialState() {
   return {
      topCountries: { countries: [], total: 0 },
      worldRegions: getWorldRegionsAggObject(),
   };
}

export const DROP_DOWN_METRICS = [
   { value: WORLDMAP_METRIC.PI, label: 'Playout / Ingest', csv: 'PlayoutIngest' },
   { value: WORLDMAP_METRIC.BUFFER_RATIO, label: 'Buffering play ratio', csv: 'BufferingRatio' },
   { value: WORLDMAP_METRIC.LATENCY, label: 'Latency', csv: 'Latency' },
   { value: WORLDMAP_METRIC.PLAYTIME, label: 'Playtime average', csv: 'Playtime' },
   { value: WORLDMAP_METRIC.USAGE, label: 'Usage (Ingest / Playout)', csv: 'Usage' },
   { value: WORLDMAP_METRIC.ABR_PLAYTIME, label: 'ABR playtime', csv: 'AbrPlaytime' },
   { value: WORLDMAP_METRIC.ABR_VIEWER, label: 'ABR viewer', csv: 'AbrViewer' },
   { value: WORLDMAP_METRIC.ABR_SWITCHES, label: 'ABR switches', csv: 'AbrSwitches' },
   { value: WORLDMAP_METRIC.QOE, label: 'Quality of experience', csv: 'Qoe' },
];

export const WorldMetricCsvNameMap = {
   [WORLDMAP_METRIC.PI]: 'PlayoutIngest',
   [WORLDMAP_METRIC.BUFFER_RATIO]: 'BufferingRatio',
   [WORLDMAP_METRIC.LATENCY]: 'Latency',
   [WORLDMAP_METRIC.PLAYTIME]: 'Playtime',
   [WORLDMAP_METRIC.USAGE]: 'Usage',
   [WORLDMAP_METRIC.ABR_PLAYTIME]: 'AbrPlaytime',
   [WORLDMAP_METRIC.ABR_VIEWER]: 'AbrViewer',
   [WORLDMAP_METRIC.ABR_SWITCHES]: 'AbrSwitches',
   [WORLDMAP_METRIC.QOE]: 'Qoe',
};
