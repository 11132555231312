import { Row } from 'reactstrap';
import styled from 'styled-components';
import { BorderedCol, BottomMargin } from '../../../styledComponents/styledComponents';

export const WidthSensitiveRow = styled(Row)`
   /* display: flex; */
   @media (max-width: ${(props) => props.$breakPointWidthPx}px) {
      display: block;
   }
`;
WidthSensitiveRow.displayName = 'WidthSensitiveRow';

export const WidthSensitiveDisplayed = styled.div`
   /* display: block; */
   @media (max-width: ${(props) => props.$breakPointWidthPx}px) {
      display: none;
   }
`;
WidthSensitiveDisplayed.displayName = 'WidthSensitiveDisplayed';

export const WidthSensitiveBottomMargin = styled(BottomMargin)`
   @media (min-width: ${(props) => props.$breakPointWidthPx}px) {
      margin-bottom: ${(props) =>
         props.$wideDisplayBottomMarginPx !== undefined ? props.$wideDisplayBottomMarginPx : 0}px;
   }
`;
WidthSensitiveBottomMargin.displayName = 'WidthSensitiveBottomMargin';

export const WidthSensitiveBorderedCol = styled(BorderedCol)`
    ${(props) => props.$order !== undefined && `order: ${props.$order};`}
    @media (max-width: ${(props) => props.$breakPointWidthPx}px) {
        padding-right: 15px;
        padding-left: 15px;      
    }
`;
WidthSensitiveBorderedCol.displayName = 'WidthSensitiveBorderedCol';
