import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useDataFetch from '../../../../hooks/useDataFetch';
import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';

import { setAlarm } from '../../../../redux/actions/general';
import { Spinner } from '../../../common';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import { CardFooter, CardTitle, Col, Row } from 'reactstrap';

import { ScoreCircle } from './ScoreCircle';

import { PropTypes } from 'prop-types';

import { QOE_VIEW } from '../../../../constants/general';
import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   RemoveBottomMargin,
   ShowOnRightSide,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';
import { formatCountNumber } from '../../../../util/NumberFormatter';
import {
   cancelTokenSelector,
   countriesSelector,
   endTimespanSelector,
   eventsSelector,
   hashSelector,
   startTimespanSelector,
   streamNamesSelector,
   tagsSelector,
   titleTimespanSelector,
} from './../../../../redux/selectors/selectors';

import { showOverflowTooltip } from '../../../common/OverflowTooltip/OverflowTooltip';

const { SearchBar } = Search;

const columns = [
   {
      dataField: 'streamname',
      text: 'Streamname',
      sort: true,
      style: { verticalAlign: 'middle', whiteSpace: 'normal' },
      formatter: showOverflowTooltip,
   },
   {
      dataField: 'playoutCount',
      text: 'Playouts',
      sort: true,
      style: { verticalAlign: 'middle', textAlign: 'center' },
      formatter: (cell) => formatCountNumber(cell),
      headerAlign: 'center',
      headerStyle: { width: '99px' },
   },
   {
      dataField: 'totalScore',
      text: 'QoE Score',
      sort: true,
      style: { verticalAlign: 'middle', textAlign: 'center' },
      formatter: (cell, row) => <ScoreCircle score={cell} details={row} />,
      headerAlign: 'center',
      headerStyle: { width: '109px' },
   },
];

function TableQoE({ height }) {
   const [tableData, setTableData] = useState([]);
   const [resultCount, setResultCount] = useState(0);

   const hash = useSelector(hashSelector);
   const countries = useSelector(countriesSelector);
   const tags = useSelector(tagsSelector);
   const events = useSelector(eventsSelector);
   const streamNames = useSelector(streamNamesSelector);

   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);
   const timespanTitle = useSelector(titleTimespanSelector);

   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, events, streamNames, QOE_VIEW);
   const url = `/api/v2/qoe/streams?from=${start.format()}&to=${end.format()}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            setTableData(data);
            setResultCount(data.length);
         }
      }

      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, dispatch]);

   const errorOrNoData = errorMessage || resultCount === 0;
   const issueMsg = errorMessage ? errorMessage.message : 'No data available';

   return (
      <WidgetContainer $minHeightPx={height}>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow></SimpleFlexRow>
                  <SimpleFlexRow></SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer>
            <Row>
               <Col>
                  <CardTitle className='mb-0'>Quality of Experience score per stream</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </Col>
            </Row>
            <ChartWrapper className='chart-wrapper' height={height - 8} marginTopPx={15}>
               <Spinner loading={isLoading} parentTopMarginPx={48}>
                  {errorOrNoData ? (
                     <CenteredMsgShowing height={height - 8} marginTopPx={0}>
                        {issueMsg}
                     </CenteredMsgShowing>
                  ) : (
                     <RemoveBottomMargin $marginBottomPx={-5}>
                        <ToolkitProvider
                           keyField='streamname'
                           data={tableData}
                           columns={columns}
                           search={{ afterSearch: (newResult) => setResultCount(newResult.length) }}
                           bootstrap4
                        >
                           {(props) => (
                              <>
                                 <ShowOnRightSide>
                                    <SearchBar {...props.searchProps} tableId={'streamname'} />
                                 </ShowOnRightSide>

                                 <BootstrapTable
                                    {...props.baseProps}
                                    pagination={paginationFactory({
                                       sizePerPage: 5,
                                       hideSizePerPage: true,
                                    })}
                                    striped
                                    hover
                                 />
                              </>
                           )}
                        </ToolkitProvider>
                     </RemoveBottomMargin>
                  )}
               </Spinner>
            </ChartWrapper>
         </WidgetBodyContainer>
         <CardFooter>
            <Row>
               <Col className='text-right'>Found streams:</Col>
               <Col>{isLoading || errorMessage ? '0' : resultCount}</Col>
            </Row>
         </CardFooter>
      </WidgetContainer>
   );
}

TableQoE.propTypes = {
   height: PropTypes.number,
};

TableQoE.defaultProps = {
   height: 300,
};

export default TableQoE;
