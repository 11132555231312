import React from 'react';
import moment from 'moment';

import {
   BadgeListItem,
   BottomMargin,
   DescriptionSpan,
   LightHeadline,
   StartHeadline,
} from '../../../styledComponents/styledComponents';

import { PropTypes } from 'prop-types';

import { formatTimeNumberInt } from '../../../util/NumberFormatter';
import { SIMPLE_DATE_FORMAT, SIMPLE_TIME_FORMAT } from '../../../constants/general';

import BootstrapTable from 'react-bootstrap-table-next';
import storePropTypes from '../../../redux/store/propTypes';
import Modal from '../../common/Modal/Modal';
import { DetailTableBody } from '../../../styledComponents/ModalStyledComponents';

const columns = [
   {
      text: 'Stream',
      dataField: 'stream_name',
      sort: true,
      style: { verticalAlign: 'middle', textAlign: 'right' },
      headerAlign: 'right',
   },
   {
      text: 'Duration/Sum',
      dataField: 'duration_sum',
      sort: true,
      style: { verticalAlign: 'middle', textAlign: 'right' },
      headerAlign: 'right',
      formatter: formatTimeNumberInt,
   },
   {
      text: 'Duration/Avg',
      dataField: 'duration_avg',
      sort: true,
      style: { verticalAlign: 'middle', textAlign: 'right' },
      headerAlign: 'right',
      formatter: formatTimeNumberInt,
   },
   {
      text: 'Sessions',
      dataField: 'session_count',
      sort: true,
      style: { verticalAlign: 'middle', textAlign: 'right' },
      headerAlign: 'right',
   },
   {
      text: 'Tokens',
      dataField: 'token_count',
      sort: true,
      style: { verticalAlign: 'middle', textAlign: 'right' },
      headerAlign: 'right',
   },
   {
      text: 'First stream start',
      dataField: 'stream_start',
      sort: true,
      style: { verticalAlign: 'middle', textAlign: 'right' },
      headerAlign: 'right',
      formatter: (value) => moment.utc(value).format(`${SIMPLE_DATE_FORMAT} ${SIMPLE_TIME_FORMAT}`),
   },
   {
      text: 'Last stream end',
      dataField: 'stream_end',
      sort: true,
      style: { verticalAlign: 'middle', textAlign: 'right' },
      headerAlign: 'right',
      formatter: (value) => moment.utc(value).format(`${SIMPLE_DATE_FORMAT} ${SIMPLE_TIME_FORMAT}`),
   },
];

function RowModal({ rowData, closeModal }) {
   return (
      <Modal closeModal={closeModal}>
         <DetailTableBody>
            <BottomMargin pxSize={20}>
               <DescriptionSpan
                  fontSizePX={16}
                  marginLeftPx={0}
               >{`Additional details for IP '${rowData.ip}'`}</DescriptionSpan>
            </BottomMargin>
            <BottomMargin pxSize={15}>
               <StartHeadline>{`Used top STS tags:${rowData.top_tags.length === 0 ? ' -' : ''}`}</StartHeadline>
               {rowData.top_tags.map((tag) => (
                  <BadgeListItem key={tag} $fontSizePx={13} $cursor={'default'}>
                     {tag}
                  </BadgeListItem>
               ))}
            </BottomMargin>
            <div>
               <LightHeadline $fontSizePx={14} marginBottomPx={10}>
                  Used top streams statistics:
               </LightHeadline>
               <BootstrapTable
                  keyField='stream_name'
                  data={rowData.top_streams}
                  columns={columns}
                  classes='fixed-table remove-bottom-margin'
                  id='ExtendedIpFilteringTable'
                  striped
                  hover
                  bootstrap4
               />
            </div>
         </DetailTableBody>
      </Modal>
   );
}

RowModal.propTypes = {
   rowData: storePropTypes.ipFilteringResultRow,
   closeModal: PropTypes.func,
};

export default RowModal;
