import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';

import { setAlarm, setFilter, setTags } from '../../../redux/actions/general';

import { Button, Col, Row } from 'reactstrap';

import { PropTypes } from 'prop-types';
import storePropTypes from './../../../redux/store/propTypes';

import {
   timespanChanged,
   orgaHashChanged,
   filterDataNotYetFetched,
   filterChanged,
} from '../../../redux/selectors/comparing';
import {
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../styledComponents/styledComponents';
import { DefaultGrey } from '../../../constants/colors';

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setTags, setFilter, setAlarm };

let tagIsBeingFetched = false;

class TagFilter extends Component {
   constructor(props) {
      super(props);

      this.state = {
         tags: [],
         filter: {
            tags: props.filter.tags,
         },
      };
   }

   handleChange = (selectedOptions) => {
      const selectedTags = selectedOptions === null ? [] : selectedOptions;
      this.setState({ filter: { tags: selectedTags } });
   };

   applySelection = () => {
      this.props.setFilter(
         {
            ...this.props.filter,
            tags: this.state.filter.tags,
         },
         { tags: this.state.filter.tags },
      );
   };

   getTags(timespan, filter, cancelToken) {
      const self = this;

      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      let token = userInfo.token;

      let headerObj = {
         'x-access-token': token,
         Accept: 'application/json',
         'Content-Type': 'application/json;charset=UTF-8',
      };

      if (filter.hash !== '') {
         headerObj['x-orga-hash'] = filter.hash;
      }

      axios
         .get('api/v2/filters/tags/', {
            headers: headerObj,
            cancelToken: cancelToken.token,
         })
         .then((response) => {
            // // Caution: test -> limitation to webrtc when taking orga hash meAno
            // let tags = tagsArray.filter(tag => tag === 'webrtc');

            // Caution: test -> limitation to webrtc when taking orga hash yPleE
            // let tags = tagsArray.filter(tag => tag.startsWith("matti"));

            const tags = response.data.data.map((tag) => ({
               value: tag,
               label: tag,
            }));

            self.props.setTags(tags);
            // tagAlreadyCached = true;
            self.setState({
               filter: { tags: filter.tags },
            });
         })
         .catch(function (error) {
            if (error instanceof axios.Cancel) {
               return;
            }
            if (process.env.NODE_ENV === 'development') {
               console.error('error :', error);
            }
         })
         .finally(function () {
            tagIsBeingFetched = false;
         });
   }

   componentWillReceiveProps(nextProps) {
      if (
         timespanChanged(this.props, nextProps) ||
         orgaHashChanged(this.props, nextProps) ||
         filterDataNotYetFetched(nextProps, 'tags', tagIsBeingFetched)
      ) {
         tagIsBeingFetched = true;
         this.getTags(nextProps.timespan, nextProps.filter, nextProps.cancelToken);
      }

      // required for URL sync: if tags filter is set by URL (with no hash/timespan change)

      if (filterChanged(this.state.filter.tags, nextProps.filter.tags)) {
         this.setState({ filter: { tags: nextProps.filter.tags } });
      }
   }

   render() {
      return (
         <div style={{ position: 'relative', zIndex: 14 }} className='animated fadeIn'>
            <Row>
               <Col>
                  <WidgetContainer>
                     <WidgetHeaderContainer $bgColor={DefaultGrey(1, 247)}>
                        <strong>Tag filter</strong>
                     </WidgetHeaderContainer>
                     <WidgetBodyContainer>
                        <div className='customRow'>
                           <div className='selectCol'>
                              <Select
                                 value={this.state.filter.tags}
                                 onChange={this.handleChange}
                                 options={this.props.tags}
                                 isMulti={true}
                                 closeMenuOnSelect={false}
                              />
                           </div>
                           <div className='buttonCol'>
                              <Button color='primary' onClick={this.applySelection}>
                                 Go
                              </Button>
                           </div>
                        </div>
                     </WidgetBodyContainer>
                  </WidgetContainer>
               </Col>
            </Row>
         </div>
      );
   }
}

TagFilter.propTypes = {
   filter: storePropTypes.filter,
   timespan: storePropTypes.timespan,
   tags: storePropTypes.tags,
   cancelToken: storePropTypes.cancelToken,
   setAlarm: PropTypes.func,
   setFilter: PropTypes.func,
   setTags: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(TagFilter);
