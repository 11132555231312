import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

export const Option = styled.div`
   text-align: center;
   line-height: 30px;
   border: 1px solid;
   border-color: #f3f3f3;
   border-bottom: none;
   cursor: pointer;
   &:hover {
      background-color: ${(props) => (props.isActive ? 'rgba(32, 168, 216, 1)' : 'rgba(32, 168, 216, 0.8)')};
      color: #fff;
   }
   background-color: ${(props) => (props.isActive ? 'rgba(32, 168, 216, 1)' : '#D8DeE3')};
   color: ${(props) => (props.isActive ? '#FFF' : '#000')};

   box-shadow: 3px 3px 10px -8px #ccc;
   flex: 1;
`;
Option.displayName = 'Option';

export const ButtonContainer = styled.div`
   display: flex;
   justify-content: center;
   margin: 0px 20px 30px 20px;
   & ${Option}:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
   }
   & ${Option}:last-child {
      border-bottom: 1px solid #c8ced3;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
   }
   @media (min-width: 992px) {
      width: 100%;
      margin: 0px 0px;
      flex-direction: column;
      & ${Option}:first-child {
         border-top-left-radius: 4px;
         border-bottom-left-radius: 0px;
         border-top-right-radius: 4px;
      }
      & ${Option}:last-child {
         border-bottom: 1px solid #c8ced3;
         border-bottom-left-radius: 4px;
         border-top-right-radius: 0px;
         border-bottom-right-radius: 4px;
      }
   }
`;
ButtonContainer.displayName = 'ButtonContainer';

function SideBar({ options, activeOption, handleClick, optionLabelMap }) {
   return (
      <ButtonContainer>
         {options.map((option) => (
            <Option key={option} onClick={() => handleClick(option)} isActive={activeOption === option}>
               {optionLabelMap[option]}
            </Option>
         ))}
      </ButtonContainer>
   );
}

SideBar.propTypes = {
   options: PropTypes.array,
   activeOption: PropTypes.string,
   handleClick: PropTypes.func,
   optionLabelMap: PropTypes.object,
};

export default SideBar;
