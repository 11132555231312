import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useDataFetch from '../../hooks/useDataFetch';
import { getFilterQueryParams, getHttpHeader } from '../../util/FetchTools';

import { PropTypes } from 'prop-types';
import {
   BorderedCardBody,
   CenteredMsgShowing,
   ChartWrapper,
   RemoveBottomMargin,
} from '../../styledComponents/styledComponents';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import BootstrapTable from 'react-bootstrap-table-next';

import {
   hashSelector,
   countriesSelector,
   tagsSelector,
   eventsSelector,
   cancelTokenSelector,
   streamNamesSelector,
} from './../../redux/selectors/selectors';
import { Spinner } from '../common';
import { ZOOM_VIEW } from '../../constants/general';
import { getColumnsSettings } from './TableColumnsSettings';

function addHiddenKeyColumn(rows) {
   return rows.map((row, idx) => ({ ...row, hiddenKey: idx }));
}

function ZoomTable({
   from,
   to,
   chosenTerm,
   termText,
   metric,
   urlAddon,
   pathParams,
   usageSwitch,
   categoryIndex,
   settings,
}) {
   const { path, columnNames, columnNamesSortFn, formatters, cellStyles } = settings;

   const [zoomData, setZoomData] = useState([]);
   const [tableData, setTableData] = useState([]);

   useEffect(() => {
      if (zoomData.length > 0 && categoryIndex < zoomData.length) {
         setTableData(zoomData[categoryIndex].values);
      }
   }, [zoomData, categoryIndex]);

   const hash = useSelector(hashSelector);
   const countries = useSelector(countriesSelector);
   const tags = useSelector(tagsSelector);
   const events = useSelector(eventsSelector);
   const streamNames = useSelector(streamNamesSelector);

   const cancelToken = useSelector(cancelTokenSelector);

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, events, streamNames, ZOOM_VIEW, metric);
   const url = `/api/v2/${path}/${chosenTerm}${pathParams}/zoom?from=${from.format()}&to=${to.format()}${urlAddon}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            setZoomData(data.data);
         }
      }
   }, [data, successful, errorMessage, httpStatus, usageSwitch]);

   const columns = getColumnsSettings(
      metric,
      termText,
      columnNames,
      columnNamesSortFn,
      tableData,
      formatters,
      cellStyles,
      usageSwitch,
   );

   return (
      <BorderedCardBody
         $hasBorder={isLoading || errorMessage ? true : false}
         $showBackground={isLoading ? true : false}
         $minHeightPx={506.6}
         $swapPaddingToMargin={true}
         $position={'relative'}
      >
         <Spinner loading={isLoading} parentTopMarginPx={60}>
            {errorMessage ? (
               <CenteredMsgShowing height={506.6} marginTopPx={0}>
                  {errorMessage.message}
               </CenteredMsgShowing>
            ) : (
               <ChartWrapper
                  className='chart-wrapper'
                  marginTopPx={0}
                  $boxShadow={'0.2px 0.2px 2px 0px rgb(0 0 0 / 42%)'}
               >
                  <RemoveBottomMargin>
                     <BootstrapTable
                        keyField='hiddenKey'
                        data={addHiddenKeyColumn(tableData)}
                        columns={columns}
                        bodyClasses='table-wrap-word'
                        classes='fixed-table'
                        bootstrap4
                        striped
                        hover
                     />
                  </RemoveBottomMargin>
               </ChartWrapper>
            )}
         </Spinner>
      </BorderedCardBody>
   );
}

ZoomTable.propTypes = {
   from: PropTypes.object,
   to: PropTypes.object,
   chosenTerm: PropTypes.string,
   termText: PropTypes.string,
   metric: PropTypes.string,
   urlAddon: PropTypes.string,
   pathParams: PropTypes.string,
   usageSwitch: PropTypes.string,
   categoryIndex: PropTypes.number,
   settings: PropTypes.object,
};

export default ZoomTable;
