import React, { useCallback, useEffect, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { convertListToMap } from '../util/UtilFunctions';
import { setAlarm } from '../redux/actions/general';
import axios from 'axios';
import SingleDataFetcher from './SingleDataFetcher';
import { useDispatch, useSelector } from 'react-redux';
import { cancelTokenSelector } from '../redux/selectors/selectors';
import useComponentFetch from '../hooks/useComponentFetch';
import { useState } from 'react';
import { BLOCK_LIST_PROP_NAME_MAP } from '../constants/general';
import { STREAM_GUARDIAN_API_BASE_URL } from '../constants/env';

// type = 'ip' | 'referer'
function BlockListFetcher({ type, refreshTriggerFlag, setBlockedMap }) {
   const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
   const streamGuardToken = userInfo.token;

   const [triggerState, setTriggerState] = useState();

   const dispatch = useDispatch();

   const cancelToken = useSelector(cancelTokenSelector);

   const httpHeader = useMemo(
      () => ({
         'X-BINTU-TOKEN': streamGuardToken,
         'Content-Type': 'application/json',
      }),
      [streamGuardToken],
   );

   const { handleResult, successful, errorMessage, data } = useComponentFetch();

   useEffect(() => {
      if (successful) {
         setBlockedMap(convertListToMap(data.data.denied, BLOCK_LIST_PROP_NAME_MAP[type], true));
      }

      if (errorMessage) {
         if (errorMessage instanceof axios.Cancel) {
            return;
         }
         setBlockedMap(undefined);
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [successful, errorMessage, data, dispatch, setBlockedMap, type]);

   useEffect(() => {
      setTriggerState(true);
   }, [refreshTriggerFlag]);

   const handleBlockListRequestCompleted = useCallback(() => {
      setTriggerState(false);
   }, [setTriggerState]);

   return (
      <>
         {triggerState && (
            <SingleDataFetcher
               url={`${STREAM_GUARDIAN_API_BASE_URL}/${type}`}
               httpHeader={httpHeader}
               cancelToken={cancelToken}
               onResult={handleResult}
               onRequestCompleted={handleBlockListRequestCompleted}
            />
         )}
      </>
   );
}

BlockListFetcher.propTypes = {
   type: PropTypes.string,
   refreshTriggerFlag: PropTypes.object,
   setBlockedMap: PropTypes.func,
};

export default BlockListFetcher;
