import React from 'react';
import { Collapse } from 'reactstrap';
import { PropTypes } from 'prop-types';

import { Description, ShowValueElement, BadgeListItem } from '../../../styledComponents/styledComponents';

import { ArrowDownSvg } from '../../../assets/worldmap/ArrowDownSvg';

function StreamSelection({
   streamIsDetermined,
   isStreamSelectionOpen,
   setOpenState,
   selectedStream,
   userData,
   setStream,
}) {
   return (
      <>
         <Description
            marginLeftPx={streamIsDetermined ? 0 : -6}
            marginTopPx={15}
            alpha={0.6}
            fontWeight={700}
            fontSizePx={13}
            onClick={streamIsDetermined ? () => {} : () => setOpenState(!isStreamSelectionOpen)}
            cursor={streamIsDetermined ? 'default' : 'pointer'}
         >
            {!streamIsDetermined && (
               <ArrowDownSvg
                  transform={`rotate(${isStreamSelectionOpen ? 0 : -90})`}
                  fill={!isStreamSelectionOpen ? '#777777' : '#20a8d8'}
                  height={'15px'}
                  width={'15px'}
                  verticalAlign={'-15%'}
               />
            )}
            {isStreamSelectionOpen ? (
               'Select a stream'
            ) : selectedStream ? (
               <span>
                  Selected stream: <ShowValueElement>{selectedStream}</ShowValueElement>
               </span>
            ) : (
               'Select a stream'
            )}
         </Description>
         <Collapse isOpen={isStreamSelectionOpen}>
            {userData.map((stream) => (
               <BadgeListItem
                  key={stream.name}
                  $fontSizePx={13}
                  $color={stream.name === selectedStream ? '#FFF' : undefined}
                  $bgColor={stream.name === selectedStream ? '#20a8d8' : undefined}
                  onClick={(e) => {
                     const streamName = e.target.childNodes[0].nodeValue;
                     setStream(streamName);
                     setOpenState(false);
                  }}
               >
                  {stream.name}
               </BadgeListItem>
            ))}
         </Collapse>
      </>
   );
}

StreamSelection.propTypes = {
   streamIsDetermined: PropTypes.bool,
   isStreamSelectionOpen: PropTypes.bool,
   setOpenState: PropTypes.func,
   selectedStream: PropTypes.string,
   userData: PropTypes.array,
   setStream: PropTypes.func,
};

export default StreamSelection;
