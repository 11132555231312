/** @format */

import {
   ABR_VIEW,
   BREAKDOWN_VIEW,
   DOC_VIEW,
   H5LIVE_VIEW,
   HISTORY_VIEW,
   HOME_VIEW,
   LOGIN_VIEW,
   QOE_VIEW,
   TROUBLESHOOTING_VIEW,
   IP_FILTERING_VIEW,
   WEBRTC_VIEW,
   WORLD_VIEW,
} from '../../constants/general';
import { AccessLevel } from '../../constants/AccessLevels';

export const navItems = [
   {
      name: 'Home',
      url: `/${HOME_VIEW}`,
      icon: 'bi bi-speedometer adjust-i-size',
      level: AccessLevel.BASIC,
   },
   {
      name: 'Break down',
      url: `/${BREAKDOWN_VIEW}`,
      icon: 'bi bi-speedometer adjust-i-size',
      level: AccessLevel.BASIC,
   },
   {
      name: 'World map',
      url: `/${WORLD_VIEW}`,
      icon: 'bi bi-speedometer adjust-i-size',
      level: AccessLevel.BASIC,
   },
   {
      name: 'H5Live',
      url: `/${H5LIVE_VIEW}`,
      icon: 'bi bi-speedometer adjust-i-size',
      level: AccessLevel.FULL,
   },
   {
      name: 'Adaptive bitrate',
      url: `/${ABR_VIEW}`,
      icon: 'bi bi-speedometer adjust-i-size',
      level: AccessLevel.PREMIUM,
   },
   {
      name: 'Quality of experience',
      url: `/${QOE_VIEW}`,
      icon: 'bi bi-speedometer adjust-i-size',
      level: AccessLevel.ADMIN,
   },
   {
      name: 'Webcaster',
      url: `/${WEBRTC_VIEW}`,
      icon: 'bi bi-speedometer adjust-i-size',
      level: AccessLevel.FULL,
   },
   {
      name: 'Historical usage',
      url: `/${HISTORY_VIEW}`,
      icon: 'bi bi-clock-history adjust-i-size',
      level: AccessLevel.FULL,
   },
   {
      name: 'Troubleshooting',
      url: `/${TROUBLESHOOTING_VIEW}`,
      icon: 'bi bi-tools adjust-i-size',
      level: AccessLevel.FULL,
   },
   {
      name: 'Guardian',
      url: `/${IP_FILTERING_VIEW}`,
      icon: 'bi bi-tools adjust-i-size',
      level: AccessLevel.BASIC,
   },
   {
      name: 'Documentation',
      url: `/${DOC_VIEW}`,
      icon: 'bi bi-journal-text adjust-i-size',
      level: AccessLevel.NONE,
   },
   {
      name: 'Logout',
      url: `/${LOGIN_VIEW}`,
      icon: 'bi bi-box-arrow-right adjust-i-size',
      level: AccessLevel.NONE,
   },
];
