import React from 'react';
import { TitleBar, CustomCardBody } from '../../../../styledComponents/styledComponents';
import { Panel } from '../Styles';

import StreamSearch from './StreamSearch';

function IngestPanel() {
   return (
      <>
         <Panel>
            <CustomCardBody>
               <TitleBar>Ingest</TitleBar>
               <StreamSearch />
            </CustomCardBody>
         </Panel>
      </>
   );
}

export default IngestPanel;
