import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';

import { EXT_DATE_TIME_MILLISECS_FORMAT, TITLE_DATE_TIME_FORMAT } from '../constants';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import {
   ChartWrapper,
   HeightSetContainer,
   PaddingContainer,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';

import { CardTitle, Col, Row } from 'reactstrap';
import { errorImportanceMap } from '../../../../assets/trafficLightGrouping';
import { CsvDataExportButton } from '../../../common';
import { showAlwaysOverflowTooltip } from '../../../common/OverflowTooltip/OverflowTooltip';

const columns = [
   {
      text: 'hiddenKey',
      dataField: 'hiddenKey',
      hidden: 'true',
   },
   {
      text: 'Event',
      dataField: 'type',
      headerStyle: { width: '88px', fontSize: '13px' },
      formatter: (cell) => showAlwaysOverflowTooltip(cell),
   },
   {
      text: 'Date',
      dataField: 'date',
      formatter: (cell) => showAlwaysOverflowTooltip(moment.utc(cell).format(EXT_DATE_TIME_MILLISECS_FORMAT)),
      headerStyle: { fontSize: '13px' },
   },
   {
      text: 'Description',
      dataField: 'desc',
      headerStyle: { fontSize: '13px' },
      formatter: (cell, row) =>
         showAlwaysOverflowTooltip(row.type === 'ERROR' ? getErrorDescription(row.errorCode) : cell),
   },
];

function addHiddenKeyColumn(rows) {
   return rows.map((row, idx) => ({ ...row, hiddenKey: idx }));
}

function EventTable({ height, events, start, end, streamName }) {
   const startFormatted = start.format(TITLE_DATE_TIME_FORMAT);
   const endFormatted = end.format(TITLE_DATE_TIME_FORMAT);

   const unixMilliStart = start.valueOf();
   const unixMilliEnd = end.valueOf();
   const cutEvents = events.filter((event) => event.date >= unixMilliStart && event.date <= unixMilliEnd);

   const jsonCsvExportData = useMemo(() => {
      return cutEvents.map((line) => {
         return {
            timestamp: moment.utc(line.date).unix(),
            event: line.type,
            description: line.desc,
         };
      });
   }, [cutEvents]);

   const options = useMemo(() => {
      return {
         start: start.unix(),
         end: end.unix(),
         streamNames: [streamName],
      };
   }, [start, end, streamName]);

   return (
      <PaddingContainer $padding={'0px 8px'}>
         <WidgetContainer $margin={'0px -7px 6px -7px'}>
            <WidgetHeaderContainer>
               <HeightSetContainer $heightInPx={23}>
                  <TwoSideFlexContainer>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <CsvDataExportButton
                              jsonData={jsonCsvExportData}
                              filename={'Playout_Events'}
                              options={options}
                           />
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                     <SimpleFlexRow></SimpleFlexRow>
                  </TwoSideFlexContainer>
               </HeightSetContainer>
            </WidgetHeaderContainer>
            <WidgetBodyContainer>
               <Row>
                  <Col>
                     <CardTitle className='mb-0'>Events</CardTitle>
                     <div className='small text-muted'>{`From ${startFormatted} to ${endFormatted} \u00A0\u00A0( UTC time )`}</div>
                  </Col>
               </Row>
               <ChartWrapper
                  className='chart-wrapper'
                  height={height}
                  marginTopPx={20}
                  autoScroll={true}
                  paddingRightPx={4}
               >
                  <ToolkitProvider
                     keyField='hiddenKey'
                     data={addHiddenKeyColumn(cutEvents)}
                     columns={columns}
                     bootstrap4
                  >
                     {(props) => (
                        <>
                           <BootstrapTable
                              {...props.baseProps}
                              pagination={paginationFactory({
                                 sizePerPage: 5,
                                 hideSizePerPage: true,
                              })}
                              striped
                              hover
                              id={'CustomTable-events'}
                           />
                        </>
                     )}
                  </ToolkitProvider>
               </ChartWrapper>
            </WidgetBodyContainer>
         </WidgetContainer>
      </PaddingContainer>
   );
}

function getErrorDescription(errorCode) {
   return (
      <div>
         <span>{'error code: '}</span>
         <a
            href='https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_api_errors'
            target='_blank'
            rel='noopener noreferrer'
         >
            {` ${errorCode} `}
         </a>
         <span>{`(${errorImportanceMap.get(errorCode)})`}</span>
      </div>
   );
}

EventTable.propTypes = {
   events: PropTypes.array,
   height: PropTypes.number,
   start: PropTypes.object,
   end: PropTypes.object,
   streamName: PropTypes.string,
};

EventTable.defaultProps = {
   height: 300,
};
export default EventTable;
