import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Widget from '../../Widget/Widget';
import { AccessLevel } from '../../../../constants/AccessLevels';

import { Col, Row } from 'reactstrap';

import axios from 'axios';
import { setNewCancelToken, setViewTimespan } from '../../../../redux/actions/general';
import { cancelTokenSelector } from '../../../../redux/selectors/selectors';
import { TROUBLESHOOTING_VIEW } from './../../../../constants/general';
import TroubleshootingContrainer from './../../../widgets/troubleshooting/TroubleshootingContrainer';

function Troubleshooting({ location, history }) {
   const cancelToken = useSelector(cancelTokenSelector);
   const cancelTokenRef = useRef(cancelToken);
   cancelTokenRef.current = cancelToken;

   const dispatch = useDispatch();

   useEffect(() => {
      return () => {
         // cancel token, added to old requests, will be activated --> old requests will be cancelled
         cancelTokenRef.current.cancel('Operation canceled by the user.');
         dispatch(setNewCancelToken(axios.CancelToken.source()));
         dispatch(setViewTimespan(TROUBLESHOOTING_VIEW));
      };
   }, [dispatch]);

   return (
      <Row className='animated fadeIn'>
         <Col xs='12' sm='12' md='12' lg='12' xl='12'>
            <Widget
               type={TroubleshootingContrainer}
               requiredLevel={AccessLevel.FULL}
               widgetAttributes={{ location, history }}
            />
         </Col>
      </Row>
   );
}

export default Troubleshooting;
