import roundTo from 'round-to';
import { getWorldRegionsAggObject } from '../WorldMapConstants';
import { getOutput } from './General';

const country_alpha_id_mapper = require('../../../../assets/worldmap/compact_country_alpha_id_mapper.json');

export function processLatencyAdapter(countries) {
   const adaptedCountries = countries.map((countryBucket) => {
      const { country, alpha2, latency, totalUniquePlaybacks } = countryBucket;
      const { coordinates, worldRegion, code } = country_alpha_id_mapper[alpha2];
      const coor = [coordinates.longitude, coordinates.latitude];
      return {
         alpha: alpha2,
         coor,
         id: code.numeric,
         name: country,
         worldRegion,
         latency: latency.seconds,
         total: totalUniquePlaybacks.count,
      };
   });

   const worldRegions = createWorldRegionsAndTopData(adaptedCountries);

   return getOutput(adaptedCountries, worldRegions);
}

function createWorldRegionsAndTopData(countries) {
   const worldRegions = getWorldRegionsAggObject({
      uniquePlaybacksWeightedLatency: 0,
      totalUniquePlaybacks: 0,
   });

   countries.forEach((country) => {
      const { worldRegion, latency, total } = country;
      worldRegions[worldRegion].totalUniquePlaybacks += total;
      worldRegions[worldRegion].uniquePlaybacksWeightedLatency += total * latency;
      worldRegions[worldRegion].countries.push(country);
   });

   Object.keys(worldRegions).forEach((worldRegion) => {
      worldRegions[worldRegion].latency = roundTo(
         worldRegions[worldRegion].uniquePlaybacksWeightedLatency / worldRegions[worldRegion].totalUniquePlaybacks,
         1,
      );
      worldRegions[worldRegion].total = worldRegions[worldRegion].totalUniquePlaybacks;
      delete worldRegions[worldRegion].uniquePlaybacksWeightedLatency;
      delete worldRegions[worldRegion].totalUniquePlaybacks;
   });

   return worldRegions;
}
