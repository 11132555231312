import {
   SET_ALARM,
   SET_TAGS,
   SET_COUNTRIES,
   SET_INITIALSTATE,
   SET_TIMESPAN,
   SET_USERINFO,
   SET_FILTER,
   SWITCH_USAGE,
   SET_EVENTS,
   SET_NEW_CANCELTOKEN,
   SET_WORLDMAP_METRIC,
   SET_WORLDMAP_SUBSWITCH,
   SET_WORLDMAP_OPTIONS,
   SET_VIEW_TIMESPAN,
   SET_WORLDMAP_VIEW,
   SET_WORLDMAP_REGION_ZOOM,
   SET_TROUBLESHOOTING_TIMESPAN,
   SET_TROUBLESHOOTING_MODE,
   SET_TROUBLESHOOTING_INGEST_INPUT,
   SET_TROUBLESHOOTING_INGEST_STREAM,
   SET_TROUBLESHOOTING_SELECTED_PUBLISH,
   SET_TROUBLESHOOTING_PUBLISH_FINE_TIMESPAN,
   RESET_TROUBLESHOOTING,
   SET_TROUBLESHOOTING_PLAYOUT_INPUT,
   SET_TROUBLESHOOTING_PLAYOUT_STREAM,
   SET_TROUBLESHOOTING_SELECTED_PLAYOUT,
   SET_TROUBLESHOOTING_PLAYOUT_FINE_TIMESPAN,
   SYNC_TROUBLESHOOTING_INGEST,
   COMPLETE_SYNC_TROUBLESHOOTING_INGEST,
   SET_IP_FILTERING_TIMESPAN,
   SET_IP_FILTERING_FILTER,
   SET_DEMO_MODE,
} from './action-types';

export const setInitialState = () => ({
   type: SET_INITIALSTATE,
});

export const setAlarm = (type, error) => ({
   type: SET_ALARM,
   payload: {
      type: type,
      message: error.message,
   },
});

export const setTags = (param) => ({
   type: SET_TAGS,
   payload: param,
});

export const setCountries = (param) => ({
   type: SET_COUNTRIES,
   payload: param,
});

export const setEvents = (param) => ({
   type: SET_EVENTS,
   payload: param,
});

export const setFilter = (param, pushToUrl) => ({
   type: SET_FILTER,
   payload: param,
   pushToUrl,
});

export const setTimespan = (param, pushToUrl) => ({
   type: SET_TIMESPAN,
   payload: param,
   pushToUrl,
});

export const setUserInfo = (param) => ({
   type: SET_USERINFO,
   payload: param,
});

export const switchUsage = (param, pushToUrl) => ({
   type: SWITCH_USAGE,
   payload: param,
   pushToUrl,
});

export const setNewCancelToken = (param) => ({
   type: SET_NEW_CANCELTOKEN,
   payload: param,
});

export const setViewTimespan = (param) => ({
   type: SET_VIEW_TIMESPAN,
   payload: param,
});

export const setWorldMapMetric = (param, pushToUrl) => ({
   type: SET_WORLDMAP_METRIC,
   payload: param,
   pushToUrl,
});

export const setWorldMapSubSwitch = (param, pushToUrl) => ({
   type: SET_WORLDMAP_SUBSWITCH,
   payload: param,
   pushToUrl,
});

export const setWorldMapOptions = (param, pushToUrl) => ({
   type: SET_WORLDMAP_OPTIONS,
   payload: param,
   pushToUrl,
});

export const setWorldMapView = (param, pushToUrl) => ({
   type: SET_WORLDMAP_VIEW,
   payload: param,
   pushToUrl,
});

export const setWorldMapRegionZoom = (param, pushToUrl) => ({
   type: SET_WORLDMAP_REGION_ZOOM,
   payload: param,
   pushToUrl,
});

export const setTroubleshootingTimespan = (param, pushToUrl) => ({
   type: SET_TROUBLESHOOTING_TIMESPAN,
   payload: param,
   pushToUrl,
});

export const setTroubleshootingMode = (param, pushToUrl) => ({
   type: SET_TROUBLESHOOTING_MODE,
   payload: param,
   pushToUrl,
});

export const setTroubleshootingIngestInput = (param, pushToUrl) => ({
   type: SET_TROUBLESHOOTING_INGEST_INPUT,
   payload: param,
   pushToUrl,
});

export const setTroubleshootingIngestStream = (param, pushToUrl) => ({
   type: SET_TROUBLESHOOTING_INGEST_STREAM,
   payload: param,
   pushToUrl,
});

export const setTroubleshootingSelectedPublish = (param, pushToUrl) => ({
   type: SET_TROUBLESHOOTING_SELECTED_PUBLISH,
   payload: param,
   pushToUrl,
});

export const setTroubleshootingPublishFineTimespan = (param, pushToUrl) => ({
   type: SET_TROUBLESHOOTING_PUBLISH_FINE_TIMESPAN,
   payload: param,
   pushToUrl,
});

export const resetTroubleshooting = (pushToUrl) => ({
   type: RESET_TROUBLESHOOTING,
   pushToUrl,
});

export const setTroubleshootingPlayoutInput = (param, pushToUrl) => ({
   type: SET_TROUBLESHOOTING_PLAYOUT_INPUT,
   payload: param,
   pushToUrl,
});

export const setTroubleshootingPlayoutStream = (param, pushToUrl) => ({
   type: SET_TROUBLESHOOTING_PLAYOUT_STREAM,
   payload: param,
   pushToUrl,
});

export const setTroubleshootingSelectedPlayout = (param, pushToUrl) => ({
   type: SET_TROUBLESHOOTING_SELECTED_PLAYOUT,
   payload: param,
   pushToUrl,
});

export const setTroubleshootingPlayoutFineTimespan = (param, pushToUrl) => ({
   type: SET_TROUBLESHOOTING_PLAYOUT_FINE_TIMESPAN,
   payload: param,
   pushToUrl,
});

export const syncTroubleshootingIngest = (pushToUrl) => ({
   type: SYNC_TROUBLESHOOTING_INGEST,
   pushToUrl,
});

export const completeSyncTroubleshootingIngest = (param, pushToUrl) => ({
   type: COMPLETE_SYNC_TROUBLESHOOTING_INGEST,
   payload: param,
   pushToUrl,
});

export const setIpFilteringTimespan = (param, pushToUrl) => ({
   type: SET_IP_FILTERING_TIMESPAN,
   payload: param,
   pushToUrl,
});

export const setIpFilteringFilter = (param, pushToUrl) => ({
   type: SET_IP_FILTERING_FILTER,
   payload: param,
   pushToUrl,
});

export const setDemoMode = (param) => ({
   type: SET_DEMO_MODE,
   payload: param,
});
