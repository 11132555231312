import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useDataFetch from '../../../../hooks/useDataFetch';
import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';

import { chartjsColors, pieZoomChartOptions } from '../../../../constants/chartjs';

import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';

import { Pie } from 'react-chartjs-2';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, HorizontalSeparator, Spinner } from '../../../common';

import roundTo from 'round-to';

import { CardTitle, Col, Row } from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import { PropTypes } from 'prop-types';
import 'spinkit/css/spinkit.css';
import { H5LIVE_VIEW, WEBRTC_VIEW } from '../../../../constants/general';
import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   RemoveBottomMargin,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   SwitchDescription,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';
import {
   cancelTokenSelector,
   countriesSelector,
   endTimespanSelector,
   eventsSelector,
   hashSelector,
   startTimespanSelector,
   streamNamesSelector,
   tagsSelector,
   titleTimespanSelector,
} from './../../../../redux/selectors/selectors';

import { getIntegerWithDecimalSeparator } from '../../../../util/NumberFormatter';
import { showOverflowTooltip } from '../../../common/OverflowTooltip/OverflowTooltip';
import SwitchButtons from '../../../common/SwitchButtons/SwitchButtons';

import { H5LIVE_PLAYER_VERSIONS, WEBCASTER_VERSIONS } from '../../../zoom/ZoomConstants';
import ZoomHint from '../../../zoom/ZoomHint';
import Modal from '../../../zoom/templates/DefaultTemplate';

import { DefaultBlue, DefaultGrey } from '../../../../constants/colors';
import { H5LIVE_SETTINGS } from '../../../zoom/widgetSettings/H5liveView';
import { WEBRTC_SETTINGS } from '../../../zoom/widgetSettings/WebrtcView';

const pieChartOptions = pieZoomChartOptions();

const appMap = {
   h5live: {
      path: H5LIVE_VIEW,
      title: 'Used player versions',
   },
   webrtc: {
      path: WEBRTC_VIEW,
      title: 'Used webcaster versions',
   },
};

function getColumns(orderMapping) {
   return [
      {
         text: 'Version',
         dataField: 'version',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         headerStyle: { width: '50%' },
         headerAlign: 'right',
         formatter: (cell) => (orderMapping.has(cell) ? showOverflowTooltip(orderMapping.get(cell)) : ''),
      },
      {
         text: '%',
         dataField: 'percent',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         headerStyle: { width: '80px' },
         headerAlign: 'right',
         formatter: (cell) => cell.toFixed(2),
      },
      {
         text: 'Count',
         dataField: 'count',
         sort: true,
         style: { verticalAlign: 'middle', textAlign: 'right' },
         headerStyle: { width: '125px' },
         headerAlign: 'right',
         formatter: getIntegerWithDecimalSeparator,
      },
   ];
}

const PIE_CHART = 'PIE_CHART';
const TABLE = 'TABLE';

function createChartData(data) {
   const chartData = {
      labels: [],
      datasets: [
         {
            borderWidth: 1,
            hidden: false,
            backgroundColor: [],
            borderColor: [],
            data: [],
            labels: [],
         },
      ],
   };

   const rawVersions = [];

   const updatedChart = chartData.datasets[0];

   data.sort((a, b) => b.sortValue - a.sortValue);
   const total = data.reduce((acc, versionObject) => (acc += versionObject.count), 0);

   data.forEach((versionObject, idx) => {
      const selectedColor = chartjsColors[idx % chartjsColors.length];
      const version = versionObject.version;
      const count = versionObject.count;

      const percent = roundTo((count * 100) / total, 2);

      updatedChart.backgroundColor.push(hexToRgba(selectedColor, 25));
      updatedChart.borderColor.push(selectedColor);
      updatedChart.data.push(count);
      updatedChart.labels.push(`${version} (${percent}%)`);
      rawVersions.push(version);
   });

   chartData.labels = updatedChart.labels;
   chartData.rawVersions = rawVersions;

   return chartData;
}

function createTableData(data) {
   data.sort((a, b) => b.sortValue - a.sortValue);
   const total = data.reduce((acc, versionObject) => (acc += versionObject.count), 0);
   const orderMapping = new Map();

   const tableData = data.map((versionObject, idx) => {
      const version = versionObject.version;
      const count = versionObject.count;
      orderMapping.set(idx, version);
      const percent = roundTo((count * 100) / total, 2);
      return {
         version: idx,
         count,
         percent,
      };
   });
   return { orderMapping, tableData };
}

function addZoomChartClickHandler(chartOptions, setModal, chartData) {
   return {
      ...chartOptions,
      onClick: (event, item) => {
         if (item.length === 0) {
            return;
         }
         const { _index } = item[0];
         const rawVersionsLength = chartData.rawVersions.length;

         if (rawVersionsLength <= _index) {
            return;
         }

         setModal({ toggleShow: true, selectedVersion: chartData.rawVersions[_index] });
      },
   };
}

function getZoomTableClickHandler(orderMapping, setModal) {
   return {
      onClick: (e, row, rowIndex) => {
         setModal({ toggleShow: true, selectedVersion: orderMapping.get(row.version) });
      },
   };
}

function PlayerVersion({ height, usedApp }) {
   const [chart, setChart] = useState({
      labels: [],
      datasets: [],
      rawVersions: [],
   });

   const [visualizationMode, setVisualizationMode] = useState(PIE_CHART);

   const [tableData, setTableData] = useState([]);
   const [orderMapping, setOrderMapping] = useState(new Map());

   const [noData, setNoData] = useState(false);

   const { path, title } = appMap[usedApp];

   const hash = useSelector(hashSelector);
   const countries = useSelector(countriesSelector);
   const tags = useSelector(tagsSelector);
   const events = useSelector(eventsSelector);
   const streamNames = useSelector(streamNamesSelector);

   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);

   const timespanTitle = useSelector(titleTimespanSelector);
   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const [modal, setModal] = useState({
      toggleShow: false,
      selectedVersion: '',
   });

   const chartRef = useRef(null);

   const chartOptions = addZoomChartClickHandler(pieChartOptions, setModal, chart);
   const tableRowClickEvent = getZoomTableClickHandler(orderMapping, setModal);

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, events, streamNames, usedApp);
   const url = `/api/v2/${path}/usedVersions?from=${start.format()}&to=${end.format()}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            const { tableData, orderMapping } = createTableData(data);
            setTableData(tableData);
            setOrderMapping(orderMapping);
            setChart(createChartData(data));
            setNoData(data.length === 0);
         }
      }

      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, dispatch]);

   const errorOrNoData = errorMessage || noData;
   const issueMsg = errorMessage ? errorMessage.message : 'No data available';

   const { toggleShow, selectedVersion } = modal;

   const setVisualizationOption = (e) => {
      const selectedOption = e.target.getAttribute('name');
      if (selectedOption !== visualizationMode) {
         setVisualizationMode(selectedOption);
      }
   };

   return (
      <WidgetContainer>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow>
                     <SwitchDescription>{'Selected view'}</SwitchDescription>
                     <SimpleFlexItem>
                        <SwitchButtons
                           leftActive={visualizationMode === PIE_CHART}
                           leftLabel={'Pie chart'}
                           rightLabel={'Table'}
                           leftName={PIE_CHART}
                           rightName={TABLE}
                           handleSwitch={setVisualizationOption}
                           buttonWidth={60}
                           buttonHeight={19}
                           fontSize={11}
                           marginTop={0}
                           marginLeft={7}
                           activeBackgroundColor={DefaultBlue()}
                           inactiveBackgroundColor={DefaultGrey()}
                           activeFontColor={'#FFF'}
                           inactiveFontColor={'#000'}
                        />
                     </SimpleFlexItem>
                     <HorizontalSeparator
                        color={DefaultGrey(1, 180)}
                        widthInPx={1}
                        heightInPx={18}
                        marginX_InPx={12}
                        marginTopInPx={0}
                     />
                     <SimpleFlexItem>
                        <CsvExportFromApiButton
                           url={url}
                           header={header}
                           cancelToken={cancelToken}
                           filename={`${usedApp}_PlayerVersion`}
                        />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <ZoomHint />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer>
            <Row>
               <Col>
                  <CardTitle className='mb-0'>{title}</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </Col>
            </Row>
            <ChartWrapper className='chart-wrapper' marginTopPx={20} height={height}>
               <Spinner loading={isLoading} parentTopMarginPx={60}>
                  {errorOrNoData ? (
                     <CenteredMsgShowing height={height}>{issueMsg}</CenteredMsgShowing>
                  ) : (
                     <>
                        {visualizationMode === TABLE ? (
                           <RemoveBottomMargin>
                              <ToolkitProvider
                                 keyField='version'
                                 data={tableData}
                                 columns={getColumns(orderMapping)}
                                 bootstrap4
                              >
                                 {(props) => (
                                    <>
                                       <BootstrapTable
                                          {...props.baseProps}
                                          classes='fixed-table'
                                          pagination={paginationFactory({
                                             sizePerPage: 5,
                                             hideSizePerPage: true,
                                          })}
                                          rowEvents={tableRowClickEvent}
                                          striped
                                          hover
                                          id={'CustomTable-player-version'}
                                       />
                                    </>
                                 )}
                              </ToolkitProvider>
                           </RemoveBottomMargin>
                        ) : (
                           <SetChartHeight height={height}>
                              <Pie ref={chartRef} data={chart} options={chartOptions} height={height} />
                           </SetChartHeight>
                        )}
                     </>
                  )}
               </Spinner>
            </ChartWrapper>
         </WidgetBodyContainer>
         {toggleShow && (
            <Modal
               term={selectedVersion}
               timespan={{ start, end }}
               metric={usedApp === H5LIVE_VIEW ? H5LIVE_PLAYER_VERSIONS : WEBCASTER_VERSIONS}
               closeModal={() =>
                  setModal({
                     ...modal,
                     toggleShow: false,
                  })
               }
               settings={usedApp === H5LIVE_VIEW ? H5LIVE_SETTINGS : WEBRTC_SETTINGS}
            />
         )}
      </WidgetContainer>
   );
}

PlayerVersion.propTypes = {
   height: PropTypes.number,
   usedApp: PropTypes.string,
};

PlayerVersion.defaultProps = { height: 300, usedApp: 'h5Live' };

export default PlayerVersion;
