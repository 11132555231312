import roundTo from 'round-to';
import { getWorldRegionsAggObject } from '../WorldMapConstants';
import { getOutput } from './General';

const country_alpha_id_mapper = require('../../../../assets/worldmap/compact_country_alpha_id_mapper.json');

export function processAbrPlaytimeAdapter(responseData) {
   const topCountriesChartTable = createDataForChartAndTable(responseData);
   const worldRegionsChartTable = createWorldRegionsData(topCountriesChartTable);

   const topCountriesMobile = createDataForDeviceType(responseData, 'mobile');
   const worldRegionsMobile = createWorldRegionsData(topCountriesMobile);

   const topCountriesDesktop = createDataForDeviceType(responseData, 'desktop');
   const worldRegionsDesktop = createWorldRegionsData(topCountriesDesktop);
   // console.log('mobile => ', getOutput(topCountriesMobile, worldRegionsMobile));

   return {
      chartTable: getOutput(topCountriesChartTable, worldRegionsChartTable),
      mobile: getOutput(topCountriesMobile, worldRegionsMobile),
      desktop: getOutput(topCountriesDesktop, worldRegionsDesktop),
   };
}

function createWorldRegionsData(countries) {
   const worldRegions = getWorldRegionsAggObject({ mobileTotal: 0, desktopTotal: 0 });

   countries.forEach((country) => {
      worldRegions[country.worldRegion].total += country.total;
      worldRegions[country.worldRegion].mobileTotal += country.mobileTotal ? country.mobileTotal : 0;
      worldRegions[country.worldRegion].desktopTotal += country.desktopTotal ? country.desktopTotal : 0;
      worldRegions[country.worldRegion].countries.push(country);
   });
   return worldRegions;
}

function createDataForDeviceType(countries, deviceType) {
   const processedCountries = [];
   countries.forEach((countryBucket) => {
      const { country, alpha2, deviceTypes } = countryBucket;
      const { coordinates, worldRegion, code } = country_alpha_id_mapper[alpha2];
      const coor = [coordinates.longitude, coordinates.latitude];
      const total = deviceTypes[deviceType].totalPlaybackTime.seconds;

      if (total > 0) {
         const profiles = {};
         deviceTypes[deviceType].abrProfiles.forEach((profile) => {
            profiles[profile.abrProfile] = profile.totalPlaybackTime.seconds;
         });
         const newCountry = {
            alpha: alpha2,
            coor,
            id: code.numeric,
            name: country,
            worldRegion,
            total,
            profiles: profiles,
         };
         processedCountries.push(newCountry);
      }
   });
   return processedCountries;
}

function createDataForChartAndTable(countries) {
   return countries.map((countryBucket) => {
      // {"name":"Afghanistan","code":{"alpha2":"AF","numeric":"004"},"worldRegion":"Asia","coordinates":{"latitude":33,"longitude":65}}
      const { country, alpha2, deviceTypes } = countryBucket;
      const { mobile, desktop } = deviceTypes;
      const compactDeviceData = {};
      let mobileTotal = mobile.totalPlaybackTime.seconds;
      let desktopTotal = desktop.totalPlaybackTime.seconds;
      mobile.abrProfiles.forEach((mobileProfile, idx) => {
         const desktopProfiles = desktop.abrProfiles[idx];
         const profileName = mobileProfile.abrProfile;
         const mobilePlaytime = mobileProfile.totalPlaybackTime.seconds;
         const desktopPlaytime = desktopProfiles.totalPlaybackTime.seconds;
         compactDeviceData[profileName] = [mobilePlaytime, desktopPlaytime];
      });
      const total = mobileTotal + desktopTotal;
      const chart = createChartData(compactDeviceData);
      const table = createTableData(compactDeviceData, total);

      const { coordinates, worldRegion, code } = country_alpha_id_mapper[alpha2];
      const coor = [coordinates.longitude, coordinates.latitude];
      return {
         alpha: alpha2,
         coor,
         id: code.numeric,
         name: country,
         worldRegion,
         total,
         chart,
         table,
         mobileTotal,
         desktopTotal,
      };
   });
}

function createChartData(filledCompare) {
   const labels = [];
   const mobileSet = [];
   const desktopSet = [];
   let max = 0;

   Object.keys(filledCompare).forEach((profileKey) => {
      const [mobile, desktop] = filledCompare[profileKey];
      labels.push(profileKey);
      mobileSet.push(mobile);
      desktopSet.push(desktop);
      if (max < mobile) max = mobile;
      if (max < desktop) max = desktop;
   });

   return {
      labels,
      mobileSet,
      desktopSet,
      max,
   };
}

function createTableData(filledCompare, total) {
   let max = 0;
   const tableRows = Object.keys(filledCompare).map((profileKey) => {
      const [mobile, desktop] = filledCompare[profileKey];
      if (max < mobile) max = mobile;
      if (max < desktop) max = desktop;
      return {
         profile: profileKey,
         mobile,
         desktop,
         mobilePercent: roundTo((mobile * 100) / total, 1),
         desktopPercent: roundTo((desktop * 100) / total, 1),
         max,
      };
   });
   return tableRows;
}
