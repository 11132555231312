import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { TooNarrowMessage, WidgetContainer } from '../../../styledComponents/styledComponents';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
import PropTypes from 'prop-types';

import MainInputs from './MainInputs';
import TableView from './TableView';

import {
   ipFilteringTimespanSelector,
   ipFilteringStreamsSelector,
   ipFilteringTagsSelector,
} from '../../../redux/selectors/selectors';

import { setIpFilteringFilter, setIpFilteringTimespan } from '../../../redux/actions/general';
import { useNonInitialEffect } from '../../../hooks/useNonInitialEffect';
import { Filter } from '../../common';
import { DefaultGrey } from '../../../constants/colors';

function IpFilteringContainer({ location, history }) {
   const dispatch = useDispatch();
   const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
   const { width } = useWindowDimensions();

   const storeStreamNames = useSelector(ipFilteringStreamsSelector);
   const storeTags = useSelector(ipFilteringTagsSelector);

   const { start, end } = useSelector(ipFilteringTimespanSelector);
   const [streamNames, setStreamNames] = useState(storeStreamNames);
   const [tags, setTags] = useState(storeTags);
   const [localStart, setLocalStart] = useState(start);
   const [localEnd, setLocalEnd] = useState(end);

   const [isLoading, setLoadingState] = useState(false);
   const [requestTrigger, activateTriggerRequest] = useState(false);
   const [hasTableRequestStarted, activateTableRequestStart] = useState(false);

   const [concurrencyIsDesired, setConcurrencyState] = useState(true);

   const streamNamesRef = useRef();
   streamNamesRef.current = streamNames;
   const localStartRef = useRef();
   localStartRef.current = localStart;
   const localEndRef = useRef();
   localEndRef.current = localEnd;
   const tagsRef = useRef();
   tagsRef.current = tags;

   // activates URL sync
   useEffect(() => {
      const newTimespan = { start: localStartRef.current, end: localEndRef.current };
      dispatch(setIpFilteringTimespan(newTimespan, newTimespan));
   }, [dispatch]);

   // requestTrigger === true => inputs are being applied
   useEffect(() => {
      if (requestTrigger) {
         const newFilter = { tags: [...tagsRef.current], streamNames: [...streamNamesRef.current] };
         dispatch(setIpFilteringFilter(newFilter, newFilter));
         const newTimespan = { start: localStartRef.current, end: localEndRef.current };
         dispatch(setIpFilteringTimespan(newTimespan, newTimespan));
      }
   }, [requestTrigger, dispatch]);

   // changed inputs trigger IP filtering list & IP blocking list http requests
   useNonInitialEffect(() => {
      if (!hasTableRequestStarted) {
         activateTableRequestStart(true);
      }
   }, [storeStreamNames, storeTags, start, end]);

   const handleTableRequestCompleted = useCallback(() => {
      activateTriggerRequest(false);
      activateTableRequestStart(false);
   }, []);

   return width < 630 ? (
      <TooNarrowMessage>{'Please enlarge the window to use IP filtering'}</TooNarrowMessage>
   ) : (
      <>
         <Row>
            <Col>{userInfo.isAdmin && <Filter history={history} location={location} />}</Col>
         </Row>

         <WidgetContainer $bgColor={DefaultGrey(1, 247)}>
            <Row>
               <Col>
                  <MainInputs
                     streamNames={streamNames}
                     setStreamNames={setStreamNames}
                     start={localStart}
                     setStart={setLocalStart}
                     end={localEnd}
                     setEnd={setLocalEnd}
                     tags={tags}
                     setTags={setTags}
                     requestTrigger={requestTrigger}
                     activateTriggerRequest={activateTriggerRequest}
                     isLoading={isLoading}
                     setConcurrencyState={setConcurrencyState}
                  />
               </Col>
            </Row>
            <TableView
               start={start}
               end={end}
               streamNames={streamNames}
               tags={tags}
               hasRequestStarted={hasTableRequestStarted}
               handleRequestCompleted={handleTableRequestCompleted}
               isLoading={isLoading}
               setLoadingState={setLoadingState}
               concurrencyIsDesired={concurrencyIsDesired}
            />
         </WidgetContainer>
      </>
   );
}

export default IpFilteringContainer;

IpFilteringContainer.propTypes = {
   location: PropTypes.object,
   history: PropTypes.object,
};
