import moment from 'moment';
import { getAnnotation } from '../../../../constants/chartjs';
import { CHRONOLOGICAL_DATE_TIME_FORMAT, EXT_DATE_TIME_MILLISECS_FORMAT } from '../constants';
import { errorImportanceMap } from '../../../../assets/trafficLightGrouping';
import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import { TIME_INTERVALS } from '../ingest/widgets/constants';

const blue = getStyle('--info');

const SCATTER_X_AXIS_SCALE_ID = 'x-axis-1';

export function getFull30SecondsIntervals(startMoment, endMoment) {
   const start = getFull30SecondsStart(startMoment);
   const end = getFull30SecondsEnd(endMoment);
   return { start, end };
}

export function getFull30SecondsStart(startMoment) {
   let start = startMoment;
   const fullMinuteStart = startMoment.clone().startOf('minute');
   const startDiffInSecs = startMoment.diff(fullMinuteStart, 'seconds');
   if (!(startDiffInSecs === 0 || startDiffInSecs === 30)) {
      if (startDiffInSecs < 30) {
         start = fullMinuteStart;
      } else if (startDiffInSecs < 60) {
         start = fullMinuteStart.add(30, 'seconds');
      }
   }
   return start;
}

export function getFull30SecondsEnd(endMoment) {
   let end = endMoment;
   const fullMinuteEnd = endMoment.clone().startOf('minute').add(1, 'minute');
   const endDiffInSecs = fullMinuteEnd.diff(endMoment, 'seconds');
   if (!(endDiffInSecs === 0 || endDiffInSecs === 30)) {
      if (endDiffInSecs < 30) {
         end = fullMinuteEnd;
      } else if (endDiffInSecs < 60) {
         end = fullMinuteEnd.subtract(30, 'seconds');
      }
   }
   return end;
}

export function getStartEnd(fineStart, fineEnd, interval) {
   let start = moment.utc(fineStart, CHRONOLOGICAL_DATE_TIME_FORMAT);
   let end = moment.utc(fineEnd, CHRONOLOGICAL_DATE_TIME_FORMAT);
   if (interval.value === 'second') {
      const fullIntervals = getFull30SecondsIntervals(start, end);
      start = fullIntervals.start;
      end = fullIntervals.end;
   }
   return { start, end };
}

export function getIntervalStart(startString, intervalValue) {
   let start = moment.utc(startString, CHRONOLOGICAL_DATE_TIME_FORMAT);
   if (intervalValue === 'second') {
      return getFull30SecondsStart(start).format(CHRONOLOGICAL_DATE_TIME_FORMAT);
   } else {
      return start.startOf(intervalValue).format(CHRONOLOGICAL_DATE_TIME_FORMAT);
   }
}

export function getIntervalEnd(endString, intervalValue) {
   let end = moment.utc(endString, CHRONOLOGICAL_DATE_TIME_FORMAT);
   if (intervalValue === 'second') {
      return getFull30SecondsEnd(end).format(CHRONOLOGICAL_DATE_TIME_FORMAT);
   } else {
      const startOfEnd = end.clone().startOf(intervalValue);
      if (!end.isSame(startOfEnd)) {
         return startOfEnd.add(1, intervalValue).format(CHRONOLOGICAL_DATE_TIME_FORMAT);
      } else {
         return startOfEnd.format(CHRONOLOGICAL_DATE_TIME_FORMAT);
      }
   }
}

export function getUrlStartString(publishStartStr, fineStartMoment) {
   return fineStartMoment.format();
}

export function getUrlEndString(publishEndStr, fineEndMoment) {
   // if (fineEndMoment.isSameOrAfter(publishEndMoment)) {
   //    end = publishEndMoment.add(1, 'second');
   // } else {
   //    end = fineEndMoment;
   // }

   return fineEndMoment.format();
}

export function getDropAnnotatedScatterChartData(chartData, chartLabels, drops, interval) {
   const annotations = [];
   const dropIndices = {};

   if (drops && drops.length > 0) {
      let timerangeHasBeenExtended = false;
      drops.forEach((drop, idx) => {
         const unixMilli = moment.utc(drop.date, EXT_DATE_TIME_MILLISECS_FORMAT).valueOf();
         // console.log('drop => ', drop);
         // console.log('chartLabels[0] :>> ', chartLabels[0]);
         // console.log('chartLabels[chartLabels.length-1] => ', chartLabels[chartLabels.length - 1]);
         // console.log('unixMilli => ', unixMilli);
         // console.log(
         //    'chartLabels[chartLabels.length - 1] > unixMilli => ',
         //    chartLabels[chartLabels.length - 1] <= unixMilli,
         // );
         if (unixMilli >= chartLabels[0]) {
            const foundIndex = chartLabels.findIndex((label) => label > unixMilli);
            if (foundIndex > -1) {
               const nextYvalue = chartData[foundIndex];
               chartLabels.splice(foundIndex, 0, unixMilli);
               dropIndices[`drop-${foundIndex}`] = drop.type;
               if (foundIndex === 0) {
                  chartData.splice(foundIndex, 0, nextYvalue);
               } else {
                  const previousYvalue = chartData[foundIndex - 1];
                  chartData.splice(foundIndex, 0, (previousYvalue + nextYvalue) / 2);
               }
               annotations.push(getAnnotation(unixMilli, drop.type, '#6610f2', idx, SCATTER_X_AXIS_SCALE_ID));
            } else {
               const timeIntervalWidth = interval.stepsInMilliSec;
               const endOfLastTimeInterval = chartLabels[chartLabels.length - 1] + timeIntervalWidth;
               const lastValue = chartData[chartData.length - 1];
               if (endOfLastTimeInterval > unixMilli && !timerangeHasBeenExtended) {
                  timerangeHasBeenExtended = true;
                  chartLabels.push(unixMilli);
                  dropIndices[`drop-${chartLabels.length - 1}`] = drop.type;
                  chartLabels.push(endOfLastTimeInterval);
                  chartData.push(lastValue);
                  chartData.push(lastValue);
                  annotations.push(getAnnotation(unixMilli, drop.type, '#6610f2', idx, SCATTER_X_AXIS_SCALE_ID));
               }
            }
         }
      });
   }

   const scatterChartData = [];
   for (let index = 0; index < chartLabels.length; index++) {
      const x = chartLabels[index];
      const y = chartData[index];
      const xyPair = { x, y };
      if (dropIndices[`drop-${index}`]) {
         xyPair.info = dropIndices[`drop-${index}`];
      }
      scatterChartData.push(xyPair);
   }

   return { scatterChartData, annotations };
}

export function getDropAnnotatedLineChartData(lineChartData, lineChartLabels, drops, interval) {
   const annotations = [];
   const dropIndices = {};

   if (drops && drops.length > 0) {
      let timerangeHasBeenExtended = false;
      drops.forEach((drop, idx) => {
         const unixMilli = moment.utc(drop.date, EXT_DATE_TIME_MILLISECS_FORMAT).valueOf();
         if (unixMilli >= lineChartLabels[0]) {
            const foundIndex = lineChartLabels.findIndex((label) => label > unixMilli);
            if (foundIndex > -1) {
               const nextYvalue = lineChartData[foundIndex];
               lineChartLabels.splice(foundIndex, 0, unixMilli);
               dropIndices[`drop-${foundIndex}`] = drop.type;
               if (foundIndex === 0) {
                  lineChartData.splice(foundIndex, 0, nextYvalue);
               } else {
                  const previousYvalue = lineChartData[foundIndex - 1];
                  lineChartData.splice(foundIndex, 0, (previousYvalue + nextYvalue) / 2);
               }
               const annotation = getAnnotation(
                  unixMilli,
                  drop.type,
                  '#6610f2',
                  annotations.length, // Using annotations.length as a counter
                  'x-axis-0',
               );
               annotations.push(annotation);
            } else {
               const timeIntervalWidth = interval.stepsInMilliSec;
               const endOfLastTimeInterval = lineChartLabels[lineChartLabels.length - 1] + timeIntervalWidth;
               const lastValue = lineChartData[lineChartData.length - 1];
               if (endOfLastTimeInterval > unixMilli && !timerangeHasBeenExtended) {
                  timerangeHasBeenExtended = true;
                  lineChartLabels.push(unixMilli);
                  dropIndices[`drop-${lineChartLabels.length - 1}`] = drop.type;
                  lineChartLabels.push(endOfLastTimeInterval);
                  lineChartData.push(lastValue);
                  lineChartData.push(lastValue);
                  const annotation = getAnnotation(
                     unixMilli,
                     drop.type,
                     '#6610f2',
                     annotations.length, // Using annotations.length as a counter
                     'x-axis-0',
                  );
                  annotations.push(annotation);
               }
            }
         }
      });
   }

   return { lineChartData, lineChartLabels, annotations };
}

export function getScatterChartData(chartData, chartLabels) {
   const scatterChartData = [];
   for (let index = 0; index < chartLabels.length; index++) {
      const x = chartLabels[index];
      const y = chartData[index];
      scatterChartData.push({ x, y });
   }

   return scatterChartData;
}

export function getEventAnnotatedScatterChartData(chartData, chartLabels, interval, events) {
   const annotations = [];
   const eventIndices = {};

   if (events && events.length > 0) {
      let timerangeHasBeenExtended = false;
      events.forEach((event, idx) => {
         const unixMilli = event.date;
         if (unixMilli >= chartLabels[0]) {
            let backgroundColor = 'rgba(0,0,0, 0.5)';
            if (event.type === 'ERROR') {
               const errorLevel = errorImportanceMap.get(event.errorCode);
               event.desc = `error code: ${event.errorCode} [${errorLevel}]`;

               if (errorLevel === 'critical') backgroundColor = 'rgba(232, 62, 140, 0.5)';
               if (errorLevel === 'not critical') backgroundColor = 'rgba(248, 203, 0, 0.5)';
               if (errorLevel === 'expected') backgroundColor = 'rgba(32, 201, 151, 0.5)';
            }
            const foundIndex = chartLabels.findIndex((label) => label > unixMilli);
            if (foundIndex > -1) {
               const nextYvalue = chartData[foundIndex];
               chartLabels.splice(foundIndex, 0, unixMilli);
               eventIndices[`event-${foundIndex}`] = event;
               if (foundIndex === 0) {
                  chartData.splice(foundIndex, 0, nextYvalue);
               } else {
                  const previousYvalue = chartData[foundIndex - 1];
                  chartData.splice(foundIndex, 0, (previousYvalue + nextYvalue) / 2);
               }
               annotations.push(
                  getAnnotation(unixMilli, event.chartBadge, '#6610f2', idx, SCATTER_X_AXIS_SCALE_ID, {
                     backgroundColor,
                  }),
               );
            } else {
               const timeIntervalWidth = interval.stepsInMilliSec;
               const endOfLastTimeInterval = chartLabels[chartLabels.length - 1] + timeIntervalWidth;
               const lastValue = chartData[chartData.length - 1];
               if (endOfLastTimeInterval > unixMilli && !timerangeHasBeenExtended) {
                  timerangeHasBeenExtended = true;
                  chartLabels.push(unixMilli);
                  eventIndices[`event-${chartLabels.length - 1}`] = event;
                  chartLabels.push(endOfLastTimeInterval);
                  chartData.push(lastValue);
                  chartData.push(lastValue);
                  annotations.push(
                     getAnnotation(unixMilli, event.chartBadge, '#6610f2', idx, SCATTER_X_AXIS_SCALE_ID, {
                        backgroundColor,
                     }),
                  );
               }
            }
         }
      });
   }

   const scatterChartData = [];
   for (let index = 0; index < chartLabels.length; index++) {
      const x = chartLabels[index];
      const y = chartData[index];
      const xyPair = { x, y };
      if (eventIndices[`event-${index}`]) {
         xyPair.info = eventIndices[`event-${index}`];
      }
      scatterChartData.push(xyPair);
   }

   return { scatterChartData, annotations };
}

export function createLineChartData(chartData, chartLabels, title, drops, interval, color) {
   const minSize = Math.min(chartData.length, chartLabels.length);
   chartData = chartData.slice(0, minSize);
   chartLabels = chartLabels.slice(0, minSize);

   const mappedData = chartLabels.map((label, index) => ({
      x: label,
      y: chartData[index],
   }));

   const { lineChartData, annotations } = getDropAnnotatedLineChartData(mappedData, chartLabels, drops, interval);

   return {
      chartDatasets: {
         datasets: [
            {
               label: title,
               backgroundColor: hexToRgba(blue, 10),
               borderColor: color,
               borderWidth: 1,
               data: lineChartData,
               fill: 'origin',
            },
         ],
      },
      annotations,
   };
}

export function generateLineChartLabels(start, end, interval, isFullInterval = false) {
   start = moment.isMoment(start) ? start : moment(start);
   end = moment.isMoment(end) ? end : moment(end);

   // console.log('start => ', start);
   // console.log('end => ', end);
   // console.log('interval => ', interval);

   const chartLabels = [];

   const counts = TIME_INTERVALS[interval].counts;

   const lower = start.clone().startOf(interval);
   const upper = end.clone().startOf(interval);

   // isLastTimeIntervalFull answers whether the last interval is full
   // e.g. for full interval: end datetime is 12/24/2020 00:00 at time interval 'day'
   const isLastTimeIntervalFull = interval !== 'second' ? end.isSame(upper, 'minute') : false;
   const intervalCounts = Math.floor(upper.diff(lower, interval) / counts);
   const size = isLastTimeIntervalFull || isFullInterval ? intervalCounts : intervalCounts + 1;

   for (let i = 0; i < size; i++) {
      chartLabels.push(
         lower
            .clone()
            .add(i * counts, interval)
            .valueOf(),
      );
   }

   return chartLabels;
}
