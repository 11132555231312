import React from 'react';

import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { PropTypes } from 'prop-types';
import { getIntegerWithDecimalSeparator } from '../../../../../../util/NumberFormatter';

const tableStyle = {
   verticalAlign: 'middle',
   textAlign: 'center',
   color: '#FFF',
   fontSize: '12px',
   borderColor: 'rgba(0,0,0,0)',
   padding: '0px',
};

const columnsHeader = [
   {
      dataField: 'name',
      text: 'ABR Profile',
      style: tableStyle,
      headerStyle: { ...tableStyle, width: '59px' },
   },
   {
      dataField: 'viewer',
      text: 'Viewer',
      headerStyle: { ...tableStyle, width: '101px' },
   },
   {
      dataField: 'avgPlaytime',
      text: 'Avg. playtime',
      headerStyle: { ...tableStyle, width: '59px' },
   },
];

const columnsBody = [
   {
      dataField: 'name',
      text: '',
      style: tableStyle,
      headerAlign: 'center',
      headerStyle: { ...tableStyle, width: '69px' },
   },
   {
      dataField: 'viewer',
      text: '',
      style: {
         ...tableStyle,
         textAlign: 'right',
         paddingRight: '5px',
         borderRight: '1px solid rgba(255, 255, 255, 0.95)',
      },
      formatter: (cell, row) => getIntegerWithDecimalSeparator(cell),
      headerAlign: 'right',
      headerStyle: { ...tableStyle, textAlign: 'right', width: '53px' },
   },
   {
      dataField: 'viewerPercentage',
      text: '',
      style: { ...tableStyle, textAlign: 'left', paddingLeft: '4px' },
      formatter: (cell, row) => `${cell} %`,
      headerAlign: 'left',
      headerStyle: { ...tableStyle, textAlign: 'left', width: '47px' },
   },
   {
      dataField: 'avgPlaytime',
      text: '',
      style: tableStyle,
      formatter: (cell, row) => `${cell} s`,
      headerAlign: 'center',
      headerStyle: { ...tableStyle, width: '69px' },
   },
];

function AbrViewerTable({ data }) {
   return (
      <>
         <BootstrapTable keyField='name' data={[]} columns={columnsHeader} classes='head-abr-table' bootstrap4 />
         <BootstrapTable keyField='name' data={data} columns={columnsBody} classes='headless-abr-table' bootstrap4 />
      </>
   );
}

AbrViewerTable.propTypes = {
   data: PropTypes.arrayOf(
      PropTypes.shape({
         name: PropTypes.number,
         viewer: PropTypes.number,
         avgPlaytime: PropTypes.number,
         viewerPercentage: PropTypes.number,
      }),
   ),
};

export default AbrViewerTable;
