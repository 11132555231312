import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, HorizontalSeparator, Spinner } from '../../../common';

import { CardTitle, Col, Row } from 'reactstrap';

import { filtersChanged, timespanOrIntervalChanged } from '../../../../redux/selectors/comparing';

import 'spinkit/css/spinkit.css';

import { PropTypes } from 'prop-types';
import storePropTypes from './../../../../redux/store/propTypes';

import { WEBRTC_VIEW } from '../../../../constants/general';
import {
   CenteredMsgShowing,
   ChartWrapper,
   DescriptionLink,
   HeightSetContainer,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   StyledButton,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';
import { barChartZoomOptions } from './../../../../constants/chartjs';
import ComponentTools from './../../../../util/ComponentTools';

import { DefaultBlue, DefaultGrey } from '../../../../constants/colors';
import { getUnifiedErrorObject } from '../../../../util/ErrorUnifier';
import { STATUS_ERROR_CODES } from '../../../zoom/ZoomConstants';
import ZoomHint from '../../../zoom/ZoomHint';
import Modal from '../../../zoom/templates/DefaultTemplate';
import { WEBRTC_SETTINGS } from '../../../zoom/widgetSettings/WebrtcView';

const chartOptions = barChartZoomOptions();

// FIXME: we should probably rather use categories as listed in https://docs.nanocosmos.de/docs/webrtc/nanostream_webrtc_api/#ErrorCode

const errorCodeMap = new Map();

// General Errors
errorCodeMap.set('1000', 'expected');
errorCodeMap.set('1001', 'expected');
errorCodeMap.set('1002', 'expected');
errorCodeMap.set('1003', 'expected');
errorCodeMap.set('1004', 'expected');
errorCodeMap.set('1005', 'expected');
errorCodeMap.set('1006', 'expected');

// Request Errors
errorCodeMap.set('2000', 'expected');

// Critical Errors
errorCodeMap.set('7000', 'critical');
errorCodeMap.set('9000', 'critical');
errorCodeMap.set('106001', 'critical');
errorCodeMap.set('109000', 'critical');

// WebSocket Errors
errorCodeMap.set('3000', 'notCritical');
errorCodeMap.set('3001', 'notCritical');
errorCodeMap.set('3002', 'notCritical');
errorCodeMap.set('3003', 'notCritical');
errorCodeMap.set('3004', 'notCritical');

// Session Errors
errorCodeMap.set('4000', 'notCritical');
errorCodeMap.set('4001', 'notCritical');
errorCodeMap.set('4002', 'notCritical');
errorCodeMap.set('4003', 'notCritical');

// Broadcast Errors
errorCodeMap.set('5000', 'notCritical');
errorCodeMap.set('5001', 'notCritical');
errorCodeMap.set('5002', 'notCritical');
errorCodeMap.set('5003', 'notCritical');

// WebRTC Errors
errorCodeMap.set('6000', 'notCritical');
errorCodeMap.set('6001', 'notCritical');
errorCodeMap.set('6002', 'notCritical');
errorCodeMap.set('6003', 'notCritical');
errorCodeMap.set('6004', 'notCritical');
errorCodeMap.set('6005', 'notCritical');
errorCodeMap.set('6006', 'notCritical');
errorCodeMap.set('6008', 'notCritical');

// General Errors
errorCodeMap.set('101001', 'notCritical');
errorCodeMap.set('101002', 'notCritical');
errorCodeMap.set('101003', 'notCritical');

// Access Errors
errorCodeMap.set('102001', 'notCritical');

// Authentication Errors
errorCodeMap.set('103000', 'notCritical');
errorCodeMap.set('103001', 'notCritical');
errorCodeMap.set('103002', 'notCritical');
errorCodeMap.set('103004', 'notCritical');

// Bintu Errors
errorCodeMap.set('104001', 'notCritical');
errorCodeMap.set('104002', 'notCritical');
errorCodeMap.set('104003', 'notCritical');

// Session Errors
errorCodeMap.set('107001', 'notCritical');
errorCodeMap.set('107002', 'notCritical');

/* const errorCodeTrafficLight = {
   critical: [
      7000,
      106001,
      109000
   ],
   notCritical: [
      3000, 3001, 3002,
      4000, 4001,
      5000, 5001, 5002, 5003,
      6000, 6001, 6002, 6003, 6004, 6005, 6006, 6008,
      101001, 101002, 101003,
      102001,
      103000, 103001, 103002,
      104001, 104002,
      107001, 107002
   ],
   expected: [
      1000, 1001, 1002, 1003, 1004, 1005,
      2000
   ],
}; */

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setAlarm };

function getNewDataSet(label, color) {
   return {
      total: 0,
      label: 'Code ' + label,
      backgroundColor: hexToRgba(color, 10),
      borderColor: color,
      borderWidth: 1,
      data: [],
      hidden: false,
   };
}

function getTrafficLightColor(status) {
   if (status === 'expected') return '#20c997';
   if (status === 'notCritical') return '#f8cb00';
   return '#e83e8c';
}

let requestForData = false;

class Errors extends Component {
   constructor(props) {
      super(props);

      this.state = {
         loading: false,
         chart: {
            type: 'bar',
            labels: [],
            datasets: [],
         },
         modal: {
            toggleShow: false,
            timespansPerIndex: [
               {
                  start: moment.utc(),
                  end: moment.utc(),
               },
            ],
            chosenIndex: 0,
            selectedError: '',
         },
         error: '',
         noData: false,
         url: '',
         header: {},
         cancelToken: null,
      };
      this.chartRef = React.createRef();
   }

   getData(timespan, filter, cancelToken) {
      this.setState({
         ...this.state,
         loading: true,
         noData: false,
         error: '',
      });

      const self = this;

      const headerObj = ComponentTools.getHttpHeader(filter);
      const urlFilterQuery = ComponentTools.getFilterQueryParams(filter, WEBRTC_VIEW);

      const updated = {
         type: 'bar',
         labels: [],
         datasets: [],
      };

      const labels = [];
      const timespansPerIndex = [];

      ComponentTools.setBarChartLabels(updated, labels, timespan, this.props.settings.weekType, timespansPerIndex);

      const url = `/api/v2/webrtc/errorcodes/timeseries?from=${timespan.gte.format()}&to=${timespan.lt.format()}&interval=${
         timespan.interval
      }${urlFilterQuery}`;

      axios
         .get(url, {
            headers: headerObj,
            cancelToken: cancelToken.token,
         })
         .then(function (response) {
            let noData = false;
            if (response.status !== 204) {
               let buckets = response.data.aggregations.histogram.buckets;
               noData = buckets.reduce((sum, dataPoint) => (sum += dataPoint.errors.buckets.length), 0) === 0;

               // parse response data
               const errorCodes = {};
               const errors = [];

               labels.forEach((label) => {
                  const start = label.start;
                  const end = label.end;
                  const interval = buckets.find((bucket) => bucket.key >= start && bucket.key < end);
                  if (interval !== undefined && interval.errors.buckets.length !== 0) {
                     interval.values = [];
                     interval.errors.buckets.forEach((errorCode) => {
                        const error = {};
                        error.errorCode = errorCode.key;
                        error.count = errorCode.doc_count;
                        interval.values.push(error);
                        if (!errorCodes[errorCode.key]) {
                           errorCodes[errorCode.key] = true;
                        }
                     });
                     errors.push(interval);
                  } else errors.push({ values: [] });
               });

               // sort errorcode into traffic light buckets (red, yellow, green)
               const sortedErrorCodes = {
                  expected: [],
                  notCritical: [],
                  critical: [],
               };

               Object.keys(errorCodes).forEach((errorKey) => {
                  if (errorCodeMap.has(errorKey.toString())) {
                     // note: the use of the unary + operator is really not recommended, not self explanatory
                     // explanation: https://stackoverflow.com/a/6683020
                     sortedErrorCodes[errorCodeMap.get(errorKey.toString())].push(+errorKey);
                  } else if (process.env.NODE_ENV === 'development') {
                     console.log(`Error code ${errorKey} was not found`);
                  }
               });
               // console.log("sortedErrorCodes", sortedErrorCodes)

               // create new datasets with traffic light
               Object.keys(sortedErrorCodes).forEach((criticalLevel) => {
                  sortedErrorCodes[criticalLevel].forEach((errorKey) => {
                     updated.datasets.push(getNewDataSet(errorKey, getTrafficLightColor(criticalLevel)));
                  });
               });
               // console.log("dataset", updated.datasets)

               // populate data in datasets
               updated.datasets.forEach((dataset) => {
                  errors.forEach((intervalObj) => {
                     const valueWasPushed = intervalObj.values.some((errorObj) => {
                        if (dataset.label === 'Code ' + errorObj.errorCode) {
                           dataset.data.push(errorObj.count);
                           dataset.total += errorObj.count;
                           return true;
                        } else return false;
                     });
                     if (!valueWasPushed) dataset.data.push(0);
                  });
               });
            }

            self.setState({
               chart: updated,
               noData,
               loading: false,
               modal: { ...self.state.modal, timespansPerIndex },
               url,
               header: headerObj,
               cancelToken,
            });
         })
         .catch(function (error) {
            if (error instanceof axios.Cancel) {
               return;
            }
            const errorObject = getUnifiedErrorObject(error);
            self.props.setAlarm('danger', errorObject);

            if (process.env.NODE_ENV === 'development') {
               console.error('error :', error);
            }

            self.setState({
               ...self.state,
               chart: updated,
               loading: false,
               error: errorObject.message,
            });
         });
   }

   componentDidMount() {
      requestForData = true;
   }

   componentWillReceiveProps(nextProps, nextContext) {
      if (timespanOrIntervalChanged(this.props, nextProps) || filtersChanged(this.props, nextProps) || requestForData) {
         requestForData = false;
         this.getData(nextProps.timespan, nextProps.filter, nextProps.cancelToken);

         chartOptions['onClick'] = (event, item) => {
            if (item.length === 0) {
               return;
            }
            const activePoint = this.chartRef.current.chartInstance.getElementAtEvent(event)[0];
            const datasetIndex = activePoint._datasetIndex;
            const data = activePoint._chart.data;
            const clickedLabel = data.datasets[datasetIndex].label;
            const index = item[0]._index;

            this.setState({
               modal: {
                  ...this.state.modal,
                  chosenIndex: index,
                  selectedError: clickedLabel.substring(5),
                  toggleShow: true,
               },
            });
         };
      }
   }

   render() {
      const errorOrNoData = this.state.error || this.state.noData;
      const issueMsg = this.state.error ? this.state.error : 'No data available';

      const { toggleShow, selectedError, timespansPerIndex, chosenIndex } = this.state.modal;
      const chosenTimespan = timespansPerIndex[chosenIndex];
      const { url, header, cancelToken } = this.state;

      return (
         <WidgetContainer>
            <WidgetHeaderContainer>
               <HeightSetContainer $heightInPx={23}>
                  <TwoSideFlexContainer>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <StyledButton
                              type='button'
                              fontSizePx={10.5}
                              className={'btn'}
                              fixMargin
                              name={'csvBtn'}
                              id={'csvBtn'}
                              heightPx={20}
                              widthPx={120}
                              bgColor={DefaultBlue(0.55)}
                              fontColor={'black'}
                              bgColorHover={DefaultBlue(0.4)}
                              fontColorHover={'black'}
                              border={'1px solid rgba(0,0,0,0.2)'}
                              $buttonHeightPx={19}
                           >
                              <DescriptionLink
                                 cursor={'pointer'}
                                 fontSizePX={10.5}
                                 marginLeftPx={0}
                                 href='https://docs.nanocosmos.de/docs/webrtc/nanostream_webrtc_api#errorcode--number'
                                 target='_blank'
                                 rel='noopener noreferrer'
                                 $color={'black'}
                              >
                                 View error codes
                              </DescriptionLink>
                           </StyledButton>
                        </SimpleFlexItem>
                        <HorizontalSeparator
                           color={DefaultGrey(1, 180)}
                           widthInPx={1}
                           heightInPx={18}
                           marginX_InPx={12}
                           marginTopInPx={0}
                        />
                        <SimpleFlexItem>
                           <CsvExportFromApiButton
                              url={url}
                              header={header}
                              cancelToken={cancelToken}
                              filename={'Webrtc_ErrorCodes'}
                           />
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <ZoomHint />
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                  </TwoSideFlexContainer>
               </HeightSetContainer>
            </WidgetHeaderContainer>
            <WidgetBodyContainer>
               <Row>
                  <Col>
                     <CardTitle className='mb-0'>Status/Error Codes</CardTitle>
                     <div className='small text-muted'>
                        {`${this.props.timespan.title}\u00A0\u00A0\u00A0( UTC time )`}
                     </div>
                  </Col>
               </Row>
               <ChartWrapper className='chart-wrapper' marginTopPx={20} height={this.props.height}>
                  <Spinner loading={this.state.loading} parentTopMarginPx={60}>
                     {errorOrNoData ? (
                        <CenteredMsgShowing height={this.props.height}>{issueMsg}</CenteredMsgShowing>
                     ) : (
                        <SetChartHeight height={this.props.height}>
                           <Bar
                              ref={this.chartRef}
                              data={this.state.chart}
                              options={chartOptions}
                              height={this.props.height}
                           />
                        </SetChartHeight>
                     )}
                  </Spinner>
               </ChartWrapper>
            </WidgetBodyContainer>
            {toggleShow && (
               <Modal
                  term={selectedError}
                  timespan={chosenTimespan}
                  metric={STATUS_ERROR_CODES}
                  closeModal={() =>
                     this.setState({
                        modal: {
                           ...this.state.modal,
                           toggleShow: false,
                        },
                     })
                  }
                  settings={WEBRTC_SETTINGS}
               />
            )}
         </WidgetContainer>
      );
   }
}

Errors.propTypes = {
   filter: storePropTypes.filter,
   timespan: storePropTypes.timespan,
   cancelToken: storePropTypes.cancelToken,
   settings: storePropTypes.settings,
   setAlarm: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Errors);
