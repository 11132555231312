import { COLOR_GREEN, COLOR_YELLOW, COLOR_RED } from '../../WorldMapConstants';
import { GREEN_TO_RED_10_STEPS } from '../../../../../constants/colors';

export const getHeatMapColors = (
   input,
   maxValue,
   opacity = 1,
   light = false,
   options = { darkValue: 35, lightValue: 85 },
) => {
   const { darkValue, lightValue } = options;
   const value = (input * 1.0) / maxValue;
   const h = (1 - value) * 60;
   const hslColor = { h: Math.round(h * 100) / 100, s: 100, l: light ? lightValue : darkValue };
   return `hsla(${hslColor.h}, ${hslColor.s}%, ${hslColor.l}%,${opacity})`;
};

export const getTrafficLightColors = (value, green, yellow, dark = false) => {
   let thresholds = { greenValue: green, yellowValue: yellow };

   if (value < thresholds.greenValue) return COLOR_GREEN(dark);
   if (value < thresholds.yellowValue) return COLOR_YELLOW(dark);
   if (value >= thresholds.yellowValue) return COLOR_RED(dark);
};

export const getGreenRedColors = (value, min, max) => {
   if (value <= min) {
      return GREEN_TO_RED_10_STEPS[9];
   }
   if (value >= max) {
      return GREEN_TO_RED_10_STEPS[0];
   }
   const range = max - min;
   const rangedValue = value - min;
   const percentValue = (90 * rangedValue) / range;
   const colorArrayIndex = 9 - Math.round(percentValue / 10);
   return GREEN_TO_RED_10_STEPS[colorArrayIndex];
};
