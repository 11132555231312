import { ALL_CDNS, TOP4_CDN } from '../../components/widgets/world/WorldMapConstants';
import { sortFilterArray } from './comparing';

export const hashSelector = (state) => state.general.filter.hash;
export const countriesSelector = (state) => sortFilterArray(state.general.filter.countries);
export const tagsSelector = (state) => sortFilterArray(state.general.filter.tags);
export const eventsSelector = (state) => sortFilterArray(state.general.filter.events);
export const streamNamesSelector = (state) => sortFilterArray(state.general.filter.streamNames);

export const streamNamesArraySelector = (state) => state.general.filter.streamNames;
export const streamNameStringArraySelector = (state) => sortFilterArray(state.general.filter.streamNames);

export const startTimespanSelector = (state) => state.general.timespan.gte;
export const endTimespanSelector = (state) => state.general.timespan.lt;
export const intervalTimespanSelector = (state) => state.general.timespan.interval;
export const formatTimespanSelector = (state) => state.general.timespan.format;
export const titleTimespanSelector = (state) => state.general.timespan.title;
export const shortcutIdTimespanSelector = (state) => state.general.timespan.id;

export const currentViewSelector = (state) => state.general.currentView;

export const timespanSelector = (state) => state.general.timespan;
export const filterSelector = (state) => state.general.filter;
export const cancelTokenSelector = (state) => state.general.cancelToken;
export const settingsSelector = (state) => state.general.settings;

export const usageSwitchSelector = (state) => state.general.usageSwitch;

export const worldmapMetricSelector = (state) => state.general.worldmapMetric;
export const worldmapSubSwitchSelector = (state) => state.general.worldmapSubSwitch;
export const worldmapCdnOptionSelector = (state) => state.general.worldmapOptions.cdn;
export const top4CdnSelector = (state) => {
   const cdnOption = state.general.worldmapOptions.cdn;
   if (cdnOption === ALL_CDNS) return undefined;
   return cdnOption === TOP4_CDN ? true : false;
};
export const worldmapViewSelector = (state) => state.general.worldmapView;
export const worldmapRegionZoomSelector = (state) => state.general.worldmapRegionZoom;

export const troubleTimespanSelector = (state) => state.general.troubleTimespan;
export const troubleStartSelector = (state) => state.general.troubleTimespan.start;
export const troubleEndSelector = (state) => state.general.troubleTimespan.end;
export const troubleIntervalSelector = (state) => state.general.troubleTimespan.troubleInterval;
export const troubleModeSelector = (state) => state.general.troubleMode;
export const troubleIngestInputSelector = (state) => state.general.troubleIngestInput;
export const troubleIngestStreamSelector = (state) => state.general.troubleIngestStream;
export const troubleSelectedPublishSelector = (state) => state.general.troubleSelectedPublish;
export const troublePlayoutInputSelector = (state) => state.general.troublePlayoutInput;
export const troublePlayoutStreamSelector = (state) => state.general.troublePlayoutStream;
export const troubleSelectedPlayoutSelector = (state) => state.general.troubleSelectedPlayout;
export const troubleSyncSettingsSelector = (state) => state.general.troubleSyncSettings;

export const ipFilteringTimespanSelector = (state) => state.general.ipFilteringTimespan;
export const ipFilteringFilterSelector = (state) => state.general.ipFilteringFilter;
export const ipFilteringTagsSelector = (state) => state.general.ipFilteringFilter.tags;
export const ipFilteringStreamsSelector = (state) => state.general.ipFilteringFilter.streamNames;

export const demoModeSelector = (state) => state.general.demoMode;
