import roundTo from 'round-to';

import {
   formatTimeNumberInt,
   roundUsage,
   getIntegerWithDecimalSeparator,
   formatCountNumber,
} from '../../../../util/NumberFormatter';

import {
   WORLDMAP_METRIC,
   PI_OPTIONS,
   ABR_PLAYTIME_OPTIONS,
   TOTAL,
   RATIO,
   LATENCY,
   PLAYTIME,
   AVG_PLAYTIME,
   PLAYOUT,
   INGEST,
   UP,
   DOWN,
   SCORE,
} from '../WorldMapConstants';

export const TOP10 = {
   [WORLDMAP_METRIC.BUFFER_RATIO]: {
      metricDesc: 'Buffering ratio',
      metricProp: RATIO,
      countDesc: 'Total playtime',
      countProp: TOTAL,
      display: 'single',
      sorting: (list) => list.sort((a, b) => (a.figure > b.figure ? 1 : -1)),
      metricUnit: (value) => `${value.toFixed(1)} %`,
      countUnit: (value) => formatTimeNumberInt(value),
      axisLabel: (value) => `${value.toFixed(1)} %`,
   },
   [WORLDMAP_METRIC.LATENCY]: {
      metricDesc: 'Latency',
      metricProp: LATENCY,
      countDesc: 'Unique Playouts',
      countProp: TOTAL,
      display: 'single',
      sorting: (list) => list.sort((a, b) => (a.figure > b.figure ? 1 : -1)),
      metricUnit: (value) => `${roundTo(value, 1).toFixed(1)} s`,
      countUnit: (value) => getIntegerWithDecimalSeparator(value),
      axisLabel: (value) => `${roundTo(value, 1).toFixed(1)} s`,
   },
   [WORLDMAP_METRIC.PLAYTIME]: {
      metricDesc: 'Average playtime',
      metricProp: PLAYTIME,
      countDesc: 'Unique Playouts',
      countProp: TOTAL,
      display: 'single',
      metricUnit: (value) => `${roundTo(value, 0).toFixed(0)} s`,
      countUnit: (value) => getIntegerWithDecimalSeparator(value),
      axisLabel: (value) => `${roundTo(value, 0).toFixed(0)} s`,
   },
   [WORLDMAP_METRIC.USAGE]: {
      metricDesc: 'Playout',
      metricProp: PLAYOUT,
      countDesc: 'Ingest',
      countProp: INGEST,
      display: 'both',
      sorting: (list) => list.sort((a, b) => (a.figure + a.count > b.figure + b.count ? -1 : 1)),
      metricUnit: (value) => roundUsage(value),
      countUnit: (value) => roundUsage(value),
      axisLabel: (value) => formatCountNumber(value),
   },
   [WORLDMAP_METRIC.ABR_VIEWER]: {
      metricDesc: 'Average playtime',
      metricProp: AVG_PLAYTIME,
      countDesc: 'Viewer',
      countProp: TOTAL,
      display: 'single',
      metricUnit: (value) => `${roundTo(value, 0).toFixed(0)} s`,
      countUnit: (value) => getIntegerWithDecimalSeparator(value),
      axisLabel: (value) => formatCountNumber(value),
   },
   [WORLDMAP_METRIC.ABR_SWITCHES]: {
      metricDesc: 'Down',
      metricProp: DOWN,
      countDesc: 'Up',
      countProp: UP,
      display: 'both',
      sorting: (list) => list.sort((a, b) => (a.figure + a.count > b.figure + b.count ? -1 : 1)),
      metricUnit: (value) => getIntegerWithDecimalSeparator(value),
      countUnit: (value) => getIntegerWithDecimalSeparator(value),
      axisLabel: (value) => formatCountNumber(value),
   },
   [ABR_PLAYTIME_OPTIONS.MOBILE]: {
      metricDesc: 'Playtime',
      metricProp: TOTAL,
      metricUnit: (value) => formatTimeNumberInt(value),
      axisLabel: (value) => formatTimeNumberInt(value),
   },
   [ABR_PLAYTIME_OPTIONS.DESKTOP]: {
      metricDesc: 'Playtime',
      metricProp: TOTAL,
      metricUnit: (value) => formatTimeNumberInt(value),
      axisLabel: (value) => formatTimeNumberInt(value),
   },
   [ABR_PLAYTIME_OPTIONS.CHART]: {
      metricDesc: 'Desktop total',
      metricProp: 'desktopTotal',
      countDesc: 'Mobile total',
      countProp: 'mobileTotal',
      display: 'both',
      sorting: (list) => list.sort((a, b) => (a.figure + a.count > b.figure + b.count ? -1 : 1)),
      metricUnit: (value) => formatTimeNumberInt(value),
      countUnit: (value) => formatTimeNumberInt(value),
      axisLabel: (value) => formatTimeNumberInt(value),
   },
   [ABR_PLAYTIME_OPTIONS.TABLE]: {
      metricDesc: 'Desktop total',
      metricProp: 'desktopTotal',
      countDesc: 'Mobile total',
      countProp: 'mobileTotal',
      display: 'both',
      sorting: (list) => list.sort((a, b) => (a.figure + a.count > b.figure + b.count ? -1 : 1)),
      metricUnit: (value) => formatTimeNumberInt(value),
      countUnit: (value) => formatTimeNumberInt(value),
      axisLabel: (value) => formatTimeNumberInt(value),
   },
   [PI_OPTIONS.BOTH]: {
      metricDesc: 'Playouts',
      metricProp: PLAYOUT,
      countDesc: 'Ingests',
      countProp: INGEST,
      display: 'both',
      metricUnit: (value) => getIntegerWithDecimalSeparator(value),
      countUnit: (value) => getIntegerWithDecimalSeparator(value),
      axisLabel: (value) => formatCountNumber(value),
   },
   [PI_OPTIONS.PLAYOUTS]: {
      metricDesc: 'Playouts',
      metricProp: PLAYOUT,
      metricUnit: (value) => getIntegerWithDecimalSeparator(value),
      axisLabel: (value) => formatCountNumber(value),
   },
   [PI_OPTIONS.INGESTS]: {
      metricDesc: 'Ingests',
      metricProp: INGEST,
      metricUnit: (value) => getIntegerWithDecimalSeparator(value),
      axisLabel: (value) => formatCountNumber(value),
   },
   [WORLDMAP_METRIC.QOE]: {
      metricDesc: 'QoE Score',
      metricProp: SCORE,
      countDesc: 'Unique Playouts',
      countProp: TOTAL,
      display: 'single',
      metricUnit: (value) => `${Math.round(value)}`,
      countUnit: (value) => getIntegerWithDecimalSeparator(value),
      axisLabel: (value) => `${Math.round(value)}`,
   },
};
