import React, { useState, useEffect } from 'react';
import isEmptyOrNone from 'is-empty';

import { Chart } from 'react-google-charts';
import { GREEN_TO_RED_10_STEPS } from '../../../../constants/colors';
import numeral from 'numeral';

const tooltipStyle = `
   padding: 10px;
   width: 150px;
   background-color: rgba(60, 60, 60, 1);
   color: rgb(255, 255, 255);
   border-style: none;
   border-radius: 12px;
`;

const squareStyle = (bgColor) => `
   width: 16px;
   height: 16px;
   margin-right: 4px;
   background-color: ${bgColor};
   color: rgb(255, 255, 255);
   border: 2px solid rgba(0,0,0,0.4);
   border-radius: 1px;
`;
// div.google-visualization-tooltip in _custom.scss overrides
// parent tooltip container settings from google charts

function getOptions(width, chartHeight) {
   return {
      width,
      height: chartHeight,
      isStacked: true,
      chartArea: { left: '16%', top: 50, width: '75%', height: '75%' },
      bar: { groupWidth: '93%' },
      legend: { position: 'none' },
      tooltip: { isHtml: true },
      hAxis: {
         title: 'Quality score classes',
         titleTextStyle: {
            color: '#666',
            fontName: 'sans-serif',
            fontSize: 12,
            bold: false,
            italic: false,
         },
      },
      vAxis: {
         title: 'Playouts',
         titleTextStyle: {
            color: '#666',
            fontName: 'sans-serif',
            fontSize: 12,
            bold: false,
            italic: false,
         },
      },
   };
}

function getChartEvent(onStreamSelect) {
   return [
      {
         eventName: 'select',
         callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 1) {
               // just showcase implementation:

               // const [selectedItem] = selection;
               // const dataTable = chartWrapper.getDataTable();
               // let { row, column } = selectedItem;
               // column = (column - 1) / 3;

               onStreamSelect();
               // console.log(
               //    'You selected : ' +
               //       JSON.stringify({
               //          row,
               //          column,
               //          value: dataTable.getValue(row, column),
               //       }),
               // );
            }
         },
      },
   ];
}

const emptyData = [
   ['Quality', '0', { role: 'style' }],
   ['100', 1000, "color:'transparent'"],
   ['90', 0, ''],
   ['80', 0, ''],
   ['70', 0, ''],
   ['60', 0, ''],
   ['50', 0, ''],
   ['40', 0, ''],
   ['30', 0, ''],
   ['20', 0, ''],
   ['10', 0, ''],
];

function QoeHistograph({ qoeData, width, chartHeight, dataProvided, onStreamSelect }) {
   const [data, setData] = useState([]);

   const chartEvents = getChartEvent(onStreamSelect);
   const options = getOptions(width, chartHeight);

   useEffect(() => {
      if (isEmptyOrNone(qoeData)) {
         setData(emptyData);
         return;
      }

      const totalPlayouts = qoeData.reduce((acc, stream) => acc + stream.playoutCount, 0);
      const MIN_LIMIT_IN_PERCENT = 0.6;
      const PLAYOUT_MIN_LIMIT = (totalPlayouts / 100) * MIN_LIMIT_IN_PERCENT;

      const otherStreams = [];
      const POOLED_STREAMS_NAME = 'Others';

      for (let i = 0; i < 10; i++) {
         otherStreams.push({
            streamname: POOLED_STREAMS_NAME,
            sumTotalScore: 0,
            playoutCount: 0,
            counts: 0,
         });
      }

      const qSortedStreams = [[], [], [], [], [], [], [], [], [], []];
      const data = [['Quality'], ['100'], ['90'], ['80'], ['70'], ['60'], ['50'], ['40'], ['30'], ['20'], ['10']];

      const pushStreamToBucket = (stream, idx) => {
         const playouts = stream.playoutCount;
         if (playouts > PLAYOUT_MIN_LIMIT) {
            qSortedStreams[idx].push(stream);
         } else {
            otherStreams[idx].sumTotalScore += stream.totalScore;
            otherStreams[idx].playoutCount += stream.playoutCount;
            otherStreams[idx].counts++;
         }
      };

      qoeData.forEach((stream) => {
         const score = stream.totalScore;
         if (score > 90) pushStreamToBucket(stream, 0);
         else if (score > 80) pushStreamToBucket(stream, 1);
         else if (score > 70) pushStreamToBucket(stream, 2);
         else if (score > 60) pushStreamToBucket(stream, 3);
         else if (score > 50) pushStreamToBucket(stream, 4);
         else if (score > 40) pushStreamToBucket(stream, 5);
         else if (score > 30) pushStreamToBucket(stream, 6);
         else if (score > 20) pushStreamToBucket(stream, 7);
         else if (score > 10) pushStreamToBucket(stream, 8);
         else pushStreamToBucket(stream, 9);
      });

      otherStreams.forEach((otherStream, qIdx) => {
         const { sumTotalScore, counts } = otherStream;
         otherStream.totalScore = Math.round(sumTotalScore / counts);
         qSortedStreams[qIdx].push(otherStream);
      });

      const lengthArray = Object.keys(qSortedStreams).map((key) => qSortedStreams[key].length);
      const maxLength = Math.max(...lengthArray);

      const strokeWidth = 0.1;
      for (let streamCol = 0; streamCol < maxLength; streamCol++) {
         const head = data[0];
         head.push(streamCol.toString());
         head.push({ role: 'style' });
         head.push({ role: 'tooltip', p: { html: true } });
         for (let qCol = 1; qCol < 11; qCol++) {
            const qLevel = data[qCol];
            qSortedStreams[qCol - 1].sort(function (a, b) {
               return b.playoutCount - a.playoutCount;
            });
            const currentLength = qSortedStreams[qCol - 1].length;
            if (streamCol < currentLength) {
               const { playoutCount, streamname, totalScore, counts } = qSortedStreams[qCol - 1][streamCol];
               qLevel.push(playoutCount);
               qLevel.push(
                  `fill-color:${GREEN_TO_RED_10_STEPS[qCol - 1]}; stroke-width: ${strokeWidth}; stroke-color: #FFFFFF;`,
               );
               if (streamname === POOLED_STREAMS_NAME) {
                  qLevel.push(`<div style='${tooltipStyle}'>
                                    <div style="display: flex;">
                                        <div style="${squareStyle(GREEN_TO_RED_10_STEPS[qCol - 1])}"></div>
                                        <h6>${streamname}</h6>
                                    </div>
                                    <div> includes <strong>${counts}</strong> streams</div>
                                    <strong>Average QoE score:</strong> ${totalScore}<br>
                                    <strong>Playouts:</strong> ${numeral(playoutCount).format('0,0')}
                                </div>`);
               } else {
                  qLevel.push(`<div style='${tooltipStyle}'>
                                    <div style="display: flex;">
                                        <div style="${squareStyle(GREEN_TO_RED_10_STEPS[qCol - 1])}"></div>
                                        <h6>${streamname}</h6>
                                    </div>
                                    <strong>QoE Score:</strong> ${totalScore}<br>
                                    <strong>Playouts:</strong> ${numeral(playoutCount).format('0,0')}
                                </div>`);
               }
            } else {
               qLevel.push(0);
               qLevel.push('color:#FFFFF');
               qLevel.push('');
            }
         }
      }
      setData(data);
   }, [qoeData]);

   return (
      dataProvided && (
         <Chart
            chartType='ColumnChart'
            loader={<div>Loading Chart</div>}
            data={data}
            options={options}
            chartEvents={chartEvents}
         />
      )
   );
}

export default QoeHistograph;
