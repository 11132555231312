import React, { useRef, useState, useEffect } from 'react';

import styled from 'styled-components';

import { PropTypes } from 'prop-types';

import { WORLDMAP_METRIC } from '../WorldMapConstants';

import PiContent from './ContentTypes/PiContent';
import DefaultContent from './ContentTypes/DefaultContent';
import AbrPlaytimeContent from './ContentTypes/AbrPlaytimeContent';
import QoeContent from './ContentTypes/QoeContent';
import { COUNTRY_NAME_LINE_STYLES } from './DataCircleConstants';
import AbrViewerTable from './ContentTypes/AbrSubComponents/AbrViewerTable';
import AbrSwitchesTable from './ContentTypes/AbrSubComponents/AbrSwitchesTable';
import { BadgeListItem } from '../../../../styledComponents/styledComponents';
import { isMobileDevice } from '../../../../util/BrowserTools';

const StyledInfoBox = styled.div.attrs(({ top, left, show }) => ({
   style: {
      top: `${top}px`,
      left: `${left}px`,
      opacity: show ? 1 : 0,
      zIndex: show ? 999 : -1,
   },
}))`
   position: absolute;
   border: 1px solid rgba(255, 255, 255, 1);
   padding: 10px;
   background-color: rgba(0, 0, 0, 0.7);
   border-radius: 5px;
   color: rgba(255, 255, 255, 0.95);
`;
StyledInfoBox.displayName = 'StyledInfoBox';

const ZoomLine = styled.div`
   margin: 6px 0px 0px 0px;
   font-size: 12px;
`;
ZoomLine.displayName = 'ZoomLine';

const CountryNameLine = styled.div`
   margin: 0px 0px 3px 0px;
   font-weight: bold;
`;
CountryNameLine.displayName = 'CountryNameLine';

const isMobileDeviceUsed = isMobileDevice();

function InfoBox({
   countryData,
   position,
   mapHeight,
   circleTextDistance,
   isHovered,
   isUnhoverTransition,
   setUnhoverTransitionState,
   total,
   subSwitch,
   maxHeatValue,
   metric,
}) {
   const infoBoxRef = useRef(null);
   const [boxPosition, setBoxPosition] = useState({ left: 0, top: 0 });
   const [isBoxVisible, setBoxVisibility] = useState(false);

   useEffect(() => {
      if (isHovered) {
         const newBoxPosition = calculateInfoBoxPosition(infoBoxRef, position, mapHeight, circleTextDistance);
         const positionChanged = newBoxPosition.left !== boxPosition.left;
         // chart is displayed only when isHovered is true
         // -> changes infobox size
         // -> position need to be adjusted to align infobox centered below circle
         // when newPositionWasCalculated is true the position has been adjusted
         const newPositionWasCalculated = newBoxPosition.left <= boxPosition.left;

         if (positionChanged) {
            setBoxPosition(newBoxPosition);
         }

         if (newPositionWasCalculated) {
            setBoxVisibility(true);
         }
      } else {
         setBoxVisibility(false);
         setUnhoverTransitionState(false);
      }
   }, [infoBoxRef, position, mapHeight, circleTextDistance, isHovered, boxPosition, setUnhoverTransitionState]);

   return (
      <StyledInfoBox ref={infoBoxRef} top={boxPosition.top} left={boxPosition.left} show={isBoxVisible}>
         {<CountryNameLine style={COUNTRY_NAME_LINE_STYLES[metric]}>{countryData.name}</CountryNameLine>}
         {metric === WORLDMAP_METRIC.PI && (
            <PiContent
               metric={WORLDMAP_METRIC.PI}
               countryData={countryData}
               subSwitch={subSwitch}
               total={total}
               maxHeatValue={maxHeatValue}
               isHovered={isHovered}
            />
         )}
         {metric === WORLDMAP_METRIC.BUFFER_RATIO && (
            <DefaultContent
               metric={WORLDMAP_METRIC.BUFFER_RATIO}
               countryData={countryData}
               maxHeatValue={maxHeatValue}
            />
         )}
         {metric === WORLDMAP_METRIC.LATENCY && (
            <DefaultContent metric={WORLDMAP_METRIC.LATENCY} countryData={countryData} maxHeatValue={maxHeatValue} />
         )}
         {metric === WORLDMAP_METRIC.PLAYTIME && (
            <DefaultContent metric={WORLDMAP_METRIC.PLAYTIME} countryData={countryData} maxHeatValue={maxHeatValue} />
         )}
         {metric === WORLDMAP_METRIC.USAGE && (
            <DefaultContent metric={WORLDMAP_METRIC.USAGE} countryData={countryData} maxHeatValue={maxHeatValue} />
         )}
         {metric === WORLDMAP_METRIC.ABR_PLAYTIME && (
            <AbrPlaytimeContent
               countryData={countryData}
               subSwitch={subSwitch}
               isHovered={isHovered}
               isUnhoverTransition={isUnhoverTransition}
            />
         )}
         {metric === WORLDMAP_METRIC.ABR_VIEWER && <AbrViewerTable data={countryData.profilesMetrics} />}
         {metric === WORLDMAP_METRIC.ABR_SWITCHES && <AbrSwitchesTable data={countryData.switchMetrics} />}
         {metric === WORLDMAP_METRIC.QOE && (
            <QoeContent countryData={countryData} isHovered={isHovered} isUnhoverTransition={isUnhoverTransition} />
         )}
         {!isMobileDeviceUsed ? (
            <ZoomLine>
               <BadgeListItem
                  $verticalAlign={'text-bottom'}
                  $fontSizePx={9}
                  $marginLeftPx={0}
                  $marginRightPx={8}
                  $bgColor={'#ff923fc2'}
                  $color={'white'}
                  $cursor={'default'}
                  $lineHeight={0.8}
                  $heightPx={12}
               >
                  Zoom
               </BadgeListItem>
               {'Click to get more details'}
            </ZoomLine>
         ) : (
            ''
         )}
      </StyledInfoBox>
   );
}

function calculateInfoBoxPosition(infoBoxRef, position, mapHeight, circleTextDistance) {
   const boundingBox = infoBoxRef.current.getBoundingClientRect();
   const textFieldWidth = boundingBox.width;
   const textFieldHeight = boundingBox.height;
   const topPositionBelowCircle = position.y + circleTextDistance;
   const topPositionAboveCircle = position.y - (circleTextDistance + textFieldHeight);

   const left = position.x - textFieldWidth / 2;
   const top = mapHeight * 0.7 > position.y ? topPositionBelowCircle : topPositionAboveCircle;

   return { left, top };
}

InfoBox.propTypes = {
   isHovered: PropTypes.bool,
   isUnhoverTransition: PropTypes.bool,
   setUnhoverTransitionState: PropTypes.func,
   mapHeight: PropTypes.number,
   position: PropTypes.exact({
      x: PropTypes.number,
      y: PropTypes.number,
   }),
   maxHeatValue: PropTypes.number,
   total: PropTypes.number,
   circleTextDistance: PropTypes.number,
   metric: PropTypes.string,
   subSwitch: PropTypes.string,
   countryData: PropTypes.shape({
      alpha: PropTypes.string,
      id: PropTypes.string,
      coor: PropTypes.arrayOf(PropTypes.number),
      // country of the world
      countryName: PropTypes.string,
      // US region
      regionName: PropTypes.string,
      // can be both: US region or world country
      name: PropTypes.string,
      // Buffering ratio
      ratio: PropTypes.number,
      totalTime: PropTypes.number,
      // Latency
      latency: PropTypes.number,
      // Playtime
      playtime: PropTypes.number,
      // Usage
      sent: PropTypes.number,
      received: PropTypes.number,
      total: PropTypes.number,
      // ABR
      compare: PropTypes.shape({
         labels: PropTypes.array,
         mobileSet: PropTypes.array,
         desktopSet: PropTypes.array,
         maximumValue: PropTypes.number,
      }),
      profileData: PropTypes.object,
      profilesMetrics: PropTypes.array,
      tableRow: PropTypes.arrayOf(
         PropTypes.shape({
            mobile: PropTypes.number,
            desktop: PropTypes.number,
            mobilePercent: PropTypes.number,
            desktopPercent: PropTypes.number,
            profile: PropTypes.string,
            totalCount: PropTypes.number,
            maximumValue: PropTypes.number,
         }),
      ),
      // several metrics
      count: PropTypes.any,
   }),
};

export default InfoBox;
