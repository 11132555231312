import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PropTypes } from 'prop-types';
import {
   Notification,
   SimpleHorizontalSeparator,
   Description,
   LightHeadline,
} from '../../../../styledComponents/styledComponents';

import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ExpandedPlayoutCell from './ExpandedPlayoutCell';
import PlayoutMetrics from './PlayoutMetrics';
import {
   troubleIntervalSelector,
   troubleModeSelector,
   troubleSelectedPlayoutSelector,
} from '../../../../redux/selectors/selectors';
import { getIntervalEnd, getIntervalStart } from '../general/DataProcessing';
import { setTroubleshootingSelectedPlayout } from '../../../../redux/actions/general';
import { format_time_Number } from '../../../../util/NumberFormatter';

const columns = [
   {
      text: 'hiddenKey',
      dataField: 'hiddenKey',
      hidden: 'true',
   },
   {
      text: 'Start',
      dataField: 'start',
   },
   {
      text: 'End',
      dataField: 'end',
   },
   {
      text: 'Duration',
      dataField: 'duration',
      formatter: (cell) => format_time_Number(cell),
   },
];

function addHiddenKeyColumn(rows) {
   return rows.map((row, idx) => ({ ...row, hiddenKey: idx }));
}

function PlayoutsTable({ streamResponse, httpStatus }) {
   const { playerIds: data, info, name: streamName } = streamResponse;
   const dispatch = useDispatch();
   const [tableData, setTableData] = useState([]);
   const tableEmpty = tableData.length === 0;

   const { playerId } = useSelector(troubleSelectedPlayoutSelector);
   const interval = useSelector(troubleIntervalSelector);

   const selectedPlayout = findPlayout(tableData, playerId);
   const onePlayoutIsSelected = selectedPlayout.length > 0;

   const mode = useSelector(troubleModeSelector);

   useEffect(() => {
      if (httpStatus !== 204) {
         // console.log('data :>> ', data);
         setTableData(addHiddenKeyColumn(data));
      }
   }, [data, httpStatus]);

   const expandRow = {
      parentClassName: () => 'bg-primary',
      renderer: (row) => ExpandedPlayoutCell(row, mode),
      onlyOneExpanding: true,
      expanded: selectedPlayout,
      onExpand: (row) =>
         dispatch(
            setTroubleshootingSelectedPlayout(
               {
                  playerId: row.playerId,
                  playoutFineStart: getIntervalStart(row.start, interval.value),
                  playoutFineEnd: getIntervalEnd(row.end, interval.value),
               },
               {
                  playerId: row.playerId,
                  playoutFineStart: getIntervalStart(row.start, interval.value),
                  playoutFineEnd: getIntervalEnd(row.end, interval.value),
               },
            ),
         ),
   };

   return (
      <>
         {data !== undefined && (
            <>
               <SimpleHorizontalSeparator spacePx={20} border={'1px dotted  rgb(180,180,180)'} />
               <LightHeadline marginBottomPx={3}>{`Playouts of stream '${streamName}'`}</LightHeadline>
               <Description marginBottomPx={15} marginLeftPx={0} alpha={0.5}>
                  Select a playout to get metrics
               </Description>
               {!tableEmpty && (
                  <BootstrapTable
                     keyField='hiddenKey'
                     classes='remove-bottom-margin'
                     hover
                     striped
                     data={tableData}
                     columns={columns}
                     expandRow={expandRow}
                  />
               )}
               {info && <Notification indentPx={0}>{info}</Notification>}
               {onePlayoutIsSelected && !tableEmpty && (
                  <PlayoutMetrics
                     playoutId={playerId}
                     playoutStart={tableData[selectedPlayout[0]].start}
                     playoutEnd={tableData[selectedPlayout[0]].end}
                     streamName={streamName}
                     events={tableData[selectedPlayout[0]].events}
                  />
               )}
            </>
         )}
      </>
   );
}

function findPlayout(data, playerId) {
   if (data.length === 0) {
      return [];
   }
   const idx = data.findIndex((playout) => playout.playerId === playerId);
   return idx === -1 ? [] : [idx];
}

PlayoutsTable.propTypes = {
   streamResponse: PropTypes.exact({
      playerIds: PropTypes.array,
      info: PropTypes.string,
      name: PropTypes.string,
   }),
   httpStatus: PropTypes.number,
};

export default PlayoutsTable;
