import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';

import useDataFetch from '../../../../hooks/useDataFetch';

import {
   CenteredMsgShowing,
   ChartWrapper,
   DescriptionLink,
   HeightSetContainer,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   StyledButton,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';

import { barChartZoomOptions } from '../../../../constants/chartjs';

import { Bar } from 'react-chartjs-2';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, HorizontalSeparator, Spinner } from '../../../common';

import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';

import { CardTitle, Col, Row } from 'reactstrap';

import {
   cancelTokenSelector,
   countriesSelector,
   endTimespanSelector,
   hashSelector,
   intervalTimespanSelector,
   startTimespanSelector,
   streamNamesSelector,
   tagsSelector,
   timespanSelector,
   titleTimespanSelector,
} from '../../../../redux/selectors/selectors';

import Modal from '../../../zoom/templates/DefaultTemplate';

import { PropTypes } from 'prop-types';

import 'spinkit/css/spinkit.css';

import { DefaultBlue, DefaultGrey } from '../../../../constants/colors';
import { HOME_VIEW } from '../../../../constants/general';
import ComponentTools from '../../../../util/ComponentTools';
import { WEBRTC_STOP_REASONS } from '../../../zoom/ZoomConstants';
import ZoomHint from '../../../zoom/ZoomHint';
import { WEBRTC_SETTINGS } from '../../../zoom/widgetSettings/WebrtcView';
import ErrorDisplay from '../../ipFiltering/ErrorDisplay';

const barChartOptions = barChartZoomOptions();

function getNewDataSet(label = 'initial', color = '#FFF', data = []) {
   return {
      label,
      backgroundColor: hexToRgba(color, 10),
      borderColor: color,
      borderWidth: 1,
      data,
      total: 0,
   };
}

function getTrafficLightColor(code) {
   if (code === 0) return '#20c997';
   if (code === 1) return '#f8cb00';
   return '#e83e8c';
}

function createChartData(data, chartLabels, processingLabels) {
   const updatedChartData = {
      labels: [],
      datasets: [],
   };

   if (data.length === 0) return updatedChartData;

   const datasetInfos = [];
   const rawDataset = data[0].values.map(() => []);
   let infosHasBeenSet = false;

   processingLabels.forEach(({ start, end }) => {
      const foundInterval = data.find((interval) => interval.timestamp >= start && interval.timestamp < end);
      if (foundInterval && foundInterval.values.reduce((sum, stopReasons) => (sum += stopReasons.count), 0) !== 0) {
         const { values } = foundInterval;
         values.forEach((stopping, stoppingIdx) => {
            const { key: stoppingType, count, category } = stopping;
            if (!infosHasBeenSet) {
               datasetInfos.push({
                  label: stoppingType,
                  color: getTrafficLightColor(category.code),
               });
            }
            rawDataset[stoppingIdx].push(count);
         });
         infosHasBeenSet = true;
      } else {
         rawDataset.forEach((dataset) => {
            dataset.push(0);
         });
      }
   });

   const datasets = [];

   for (let index = 0; index < datasetInfos.length; index++) {
      const { label, color } = datasetInfos[index];
      datasets.push(getNewDataSet(label, color, rawDataset[index]));
   }

   updatedChartData.datasets = datasets;
   updatedChartData.labels = chartLabels;

   return updatedChartData;
}

function addZoomClickHandler(chartOptions, barRef, setModal) {
   return {
      ...chartOptions,
      onClick: (event, item) => {
         if (item.length === 0) {
            return;
         }
         const activePoint = barRef.current.chartInstance.getElementAtEvent(event)[0];
         const datasetIndex = activePoint._datasetIndex;
         const data = activePoint._chart.data;
         const clickedLabel = data.datasets[datasetIndex].label;
         const index = item[0]._index;

         setModal({ toggleShow: true, chosenIndex: index, selectedReason: clickedLabel });
      },
   };
}

function StopReason({ height }) {
   const [stoppingsChart, setStoppingsChart] = useState({
      labels: [],
      datasets: [],
   });

   const [noData, setNoData] = useState(false);

   const hash = useSelector(hashSelector);
   const countries = useSelector(countriesSelector);
   const streamNames = useSelector(streamNamesSelector);
   const tags = useSelector(tagsSelector);

   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);

   const interval = useSelector(intervalTimespanSelector);
   const timespanTitle = useSelector(titleTimespanSelector);
   const timespan = useSelector(timespanSelector);

   const cancelToken = useSelector(cancelTokenSelector);
   const dispatch = useDispatch();

   const [modal, setModal] = useState({
      toggleShow: false,
      selectedReason: '',
      chosenIndex: 0,
   });

   const [timespansPerIndex, setTimespanPerIndex] = useState([]);

   const barRef = useRef(null);
   const chartOptions = addZoomClickHandler(barChartOptions, barRef, setModal, interval);

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, [], streamNames, HOME_VIEW);
   const url = `/api/v2/webrtc/stopReasons/timeseries?from=${start.format()}&to=${end.format()}&interval=${interval}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            setNoData(
               data.data.reduce((sum, dataPoint) => {
                  const stopReasonsSum = dataPoint.values.reduce((srSum, stopReason) => (srSum += stopReason.count), 0);
                  return (sum += stopReasonsSum);
               }, 0) === 0,
            );
            const { chartLabels, processingLabels, timespansPerIndex } = ComponentTools.getBarChartLabels(timespan);
            setTimespanPerIndex(timespansPerIndex);
            setStoppingsChart(createChartData(data.data, chartLabels, processingLabels));
         }
      }

      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, timespan, modal, setModal, dispatch]);

   const { toggleShow, selectedReason, chosenIndex } = modal;
   const chosenTimespan = timespansPerIndex[chosenIndex];

   const errorOrNoData = errorMessage || noData;

   return (
      <WidgetContainer>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <StyledButton
                           type='button'
                           fontSizePx={10.5}
                           className={'btn'}
                           fixMargin
                           name={'csvBtn'}
                           id={'csvBtn'}
                           heightPx={20}
                           widthPx={120}
                           bgColor={DefaultBlue(0.55)}
                           fontColor={'black'}
                           bgColorHover={DefaultBlue(0.4)}
                           fontColorHover={'black'}
                           border={'1px solid rgba(0,0,0,0.2)'}
                           $buttonHeightPx={19}
                        >
                           <DescriptionLink
                              cursor={'pointer'}
                              fontSizePX={10.5}
                              marginLeftPx={0}
                              href='https://docs.nanocosmos.de/docs/webrtc/nanostream_webrtc_api#events'
                              target='_blank'
                              rel='noopener noreferrer'
                              $color={'black'}
                           >
                              View stop reasons
                           </DescriptionLink>
                        </StyledButton>
                     </SimpleFlexItem>
                     <HorizontalSeparator
                        color={DefaultGrey(1, 180)}
                        widthInPx={1}
                        heightInPx={18}
                        marginX_InPx={12}
                        marginTopInPx={0}
                     />
                     <SimpleFlexItem>
                        <CsvExportFromApiButton
                           url={url}
                           header={header}
                           cancelToken={cancelToken}
                           filename={'Webrtc_StopReasons'}
                        />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
                  <SimpleFlexRow>
                     <SimpleFlexItem>
                        <ZoomHint />
                     </SimpleFlexItem>
                  </SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer>
            <Row>
               <Col>
                  <CardTitle className='mb-0'>{'Stop reasons'}</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </Col>
            </Row>
            <ChartWrapper className='chart-wrapper' marginTopPx={20} height={height}>
               <Spinner loading={isLoading} parentTopMarginPx={71}>
                  {errorOrNoData ? (
                     <CenteredMsgShowing height={height}>
                        {errorMessage ? (
                           <ErrorDisplay error={errorMessage} msgColor={'rgb(80,80,80)'}></ErrorDisplay>
                        ) : (
                           'No data available'
                        )}
                     </CenteredMsgShowing>
                  ) : (
                     <SetChartHeight height={height}>
                        <Bar data={stoppingsChart} options={chartOptions} ref={barRef} />
                     </SetChartHeight>
                  )}
               </Spinner>
            </ChartWrapper>
         </WidgetBodyContainer>
         {toggleShow && (
            <Modal
               term={selectedReason}
               timespan={chosenTimespan}
               metric={WEBRTC_STOP_REASONS}
               settings={WEBRTC_SETTINGS}
               closeModal={() =>
                  setModal({
                     ...modal,
                     toggleShow: false,
                  })
               }
            />
         )}
      </WidgetContainer>
   );
}

StopReason.propTypes = {
   height: PropTypes.number,
};

StopReason.defaultProps = {
   height: 300,
};

export default StopReason;
