import { useRef, useEffect } from 'react';

// source: https://github.com/thivi/use-non-initial-effect-hook/blob/master/src/use-non-initial-effect-hook.tsx

export const useNonInitialEffect = (effect, deps) => {
   const initialRender = useRef(true);

   useEffect(() => {
      let effectReturns = () => {};

      if (initialRender.current) {
         initialRender.current = false;
      } else {
         effectReturns = effect();
      }

      if (effectReturns && typeof effectReturns === 'function') {
         return effectReturns;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, deps);
};
