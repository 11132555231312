import { GREEN_TO_RED_10_STEPS } from '../../../../constants/colors';

export const scoreRatingMapping = [
   {
      desc: 'excellent',
      limit: 80,
      color: GREEN_TO_RED_10_STEPS[0],
   },
   {
      desc: 'good',
      limit: 60,
      color: GREEN_TO_RED_10_STEPS[4],
   },
   {
      desc: 'poor',
      limit: 1,
      color: GREEN_TO_RED_10_STEPS[9],
   },
   {
      desc: 'poor',
      limit: 0,
      color: '#000',
   },
   {
      desc: 'N/A',
      limit: -1,
      color: '#333',
   },
];
