import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { setAlarm } from '../../../../redux/actions/general';
import { CsvExportFromApiButton, Spinner } from '../../../common';

import { CardTitle, Col, Row } from 'reactstrap';

import { filtersChanged, timespanOrIntervalChanged } from '../../../../redux/selectors/comparing';

import 'spinkit/css/spinkit.css';

import { PropTypes } from 'prop-types';
import storePropTypes from '../../../../redux/store/propTypes';

import { barChartZoomOptions } from '../../../../constants/chartjs';
import { H5LIVE_VIEW } from '../../../../constants/general';
import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   SetChartHeight,
   SimpleFlexItem,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';
import ComponentTools from '../../../../util/ComponentTools';

import { getUnifiedErrorObject } from '../../../../util/ErrorUnifier';
import { PLAYER_LOADING } from '../../../zoom/ZoomConstants';
import ZoomHint from '../../../zoom/ZoomHint';
import Modal from '../../../zoom/templates/DefaultTemplate';
import { H5LIVE_SETTINGS } from '../../../zoom/widgetSettings/H5liveView';

const chartOptions = barChartZoomOptions();

const mapStateToProps = (state) => {
   return state.general;
};

const mapDispatchToProps = { setAlarm };

const colors = [
   '#20a8d8',
   '#f8cb00',
   '#e83e8c',
   '#20c997',
   '#6f42c1',
   '#ffc107',
   '#63c2de',
   '#4dbd74',
   '#6610f2',
   '#17a2b8',
   '#f86c6b',
];

function getNewDataSet(label, color) {
   return {
      total: 0,
      label: label,
      backgroundColor: hexToRgba(color, 10),
      borderColor: color,
      borderWidth: 1,
      data: [],
      hidden: false,
   };
}

let requestForData = false;

class PlayerLoadings extends Component {
   constructor(props) {
      super(props);

      this.state = {
         loading: false,
         chart: {
            type: 'bar',
            labels: [],
            datasets: [],
         },
         modal: {
            toggleShow: false,
            timespansPerIndex: [
               {
                  start: moment.utc(),
                  end: moment.utc(),
               },
            ],
            chosenIndex: 0,
            selectedCountry: '',
         },
         error: '',
         noData: false,
         url: '',
         header: {},
         cancelToken: null,
      };
      this.chartRef = React.createRef();
   }

   getData(timespan, filter, cancelToken) {
      this.setState({
         ...this.state,
         loading: true,
         noData: false,
         error: '',
      });

      const self = this;

      const headerObj = ComponentTools.getHttpHeader(filter);
      const urlFilterQuery = ComponentTools.getFilterQueryParams(filter, H5LIVE_VIEW);

      const updated = {
         type: 'bar',
         labels: [],
         datasets: [],
      };

      const labels = [];
      const timespansPerIndex = [];

      ComponentTools.setBarChartLabels(updated, labels, timespan, this.props.settings.weekType, timespansPerIndex);

      const url = `/api/v2/h5live/playerloadings/timeseries?from=${timespan.gte.format()}&to=${timespan.lt.format()}&interval=${
         timespan.interval
      }${urlFilterQuery}`;

      axios
         .get(url, {
            headers: headerObj,
            cancelToken: cancelToken.token,
         })
         .then(function (response) {
            let noData = false;
            if (response.status !== 204) {
               let buckets = response.data.data;
               noData = buckets.reduce((sum, dataPoint) => (sum += dataPoint.countries.length), 0) === 0;

               // parse response data
               const countries = {};
               const loadings = [];

               labels.forEach((label) => {
                  const start = label.start;
                  const end = label.end;
                  const interval = buckets.find((bucket) => bucket.key >= start && bucket.key < end);
                  if (interval !== undefined && interval.countries.length !== 0) {
                     interval.values = [];
                     interval.countries.forEach((country) => {
                        interval.values.push(country);
                        const labelKey = `${country.country} (${country.alpha2})`;
                        if (!countries[labelKey]) {
                           countries[labelKey] = true;
                        }
                     });
                     loadings.push(interval);
                  } else loadings.push({ values: [] });
               });

               // create new datasets
               Object.keys(countries).forEach((countryKey, idx) => {
                  updated.datasets.push(getNewDataSet(countryKey, colors[idx % colors.length]));
               });

               // populate data in datasets
               updated.datasets.forEach((dataset) => {
                  loadings.forEach((intervalObj) => {
                     const valueWasPushed = intervalObj.values.some((countryEle) => {
                        if (dataset.label === `${countryEle.country} (${countryEle.alpha2})`) {
                           dataset.data.push(countryEle.count);
                           dataset.total += countryEle.count;
                           return true;
                        } else return false;
                     });
                     if (!valueWasPushed) dataset.data.push(0);
                  });
               });
            }

            self.setState({
               chart: updated,
               noData,
               loading: false,
               modal: { ...self.state.modal, timespansPerIndex },
               url,
               header: headerObj,
               cancelToken,
            });
         })
         .catch(function (error) {
            if (error instanceof axios.Cancel) {
               return;
            }
            const errorObject = getUnifiedErrorObject(error);

            self.props.setAlarm('danger', errorObject);

            if (process.env.NODE_ENV === 'development') {
               console.error('error :', error);
            }

            self.setState({
               ...self.state,
               chart: updated,
               loading: false,
               error: errorObject.message,
            });
         });
   }

   componentDidMount() {
      requestForData = true;
   }

   componentWillReceiveProps(nextProps, nextContext) {
      if (timespanOrIntervalChanged(this.props, nextProps) || filtersChanged(this.props, nextProps) || requestForData) {
         requestForData = false;
         this.getData(nextProps.timespan, nextProps.filter, nextProps.cancelToken);

         chartOptions['onClick'] = (event, item) => {
            if (item.length === 0) {
               return;
            }
            const activePoint = this.chartRef.current.chartInstance.getElementAtEvent(event)[0];
            const datasetIndex = activePoint._datasetIndex;
            const data = activePoint._chart.data;
            const clickedLabel = data.datasets[datasetIndex].label;
            const index = item[0]._index;

            this.setState({
               modal: {
                  ...this.state.modal,
                  chosenIndex: index,
                  selectedCountry: clickedLabel,
                  toggleShow: true,
               },
            });
         };
      }
   }

   render() {
      const errorOrNoData = this.state.error || this.state.noData;
      const issueMsg = this.state.error ? this.state.error : 'No data available';

      const { toggleShow, selectedCountry, timespansPerIndex, chosenIndex } = this.state.modal;
      const chosenTimespan = timespansPerIndex[chosenIndex];
      const { url, header, cancelToken } = this.state;

      return (
         <WidgetContainer>
            <WidgetHeaderContainer>
               <HeightSetContainer $heightInPx={23}>
                  <TwoSideFlexContainer>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <CsvExportFromApiButton
                              url={url}
                              header={header}
                              cancelToken={cancelToken}
                              filename={'H5live_Loadings'}
                           />
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                     <SimpleFlexRow>
                        <SimpleFlexItem>
                           <ZoomHint />
                        </SimpleFlexItem>
                     </SimpleFlexRow>
                  </TwoSideFlexContainer>
               </HeightSetContainer>
            </WidgetHeaderContainer>
            <WidgetBodyContainer>
               <Row>
                  <Col>
                     <CardTitle className='mb-0'>Player loading count per country</CardTitle>
                     <div className='small text-muted'>
                        {`${this.props.timespan.title}\u00A0\u00A0\u00A0( UTC time )`}
                     </div>
                  </Col>
               </Row>
               <ChartWrapper className='chart-wrapper' marginTopPx={20} height={this.props.height}>
                  <Spinner loading={this.state.loading} parentTopMarginPx={60}>
                     {errorOrNoData ? (
                        <CenteredMsgShowing height={this.props.height}>{issueMsg}</CenteredMsgShowing>
                     ) : (
                        <SetChartHeight height={this.props.height}>
                           <Bar ref={this.chartRef} data={this.state.chart} options={chartOptions} height={300} />
                        </SetChartHeight>
                     )}
                  </Spinner>
               </ChartWrapper>
            </WidgetBodyContainer>
            {toggleShow && (
               <Modal
                  term={selectedCountry}
                  timespan={chosenTimespan}
                  metric={PLAYER_LOADING}
                  closeModal={() =>
                     this.setState({
                        modal: {
                           ...this.state.modal,
                           toggleShow: false,
                        },
                     })
                  }
                  settings={H5LIVE_SETTINGS}
               />
            )}
         </WidgetContainer>
      );
   }
}

PlayerLoadings.propTypes = {
   filter: storePropTypes.filter,
   timespan: storePropTypes.timespan,
   cancelToken: storePropTypes.cancelToken,
   settings: storePropTypes.settings,
   setAlarm: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerLoadings);
