import React, { useEffect } from 'react';
import useDataFetch from '../../../../hooks/useDataFetch';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { setAlarm } from '../../../../redux/actions/general';
import axios from 'axios';
import useSignal from '../../../../hooks/useSignal';

function DataColumn({
   usageMetric,
   cancelToken,
   header,
   urlFilterQuery,
   start,
   end,
   refresh,
   notifyCompletedRequest,
   notifyLoading,
   notifyError,
}) {
   const dispatch = useDispatch();
   const [signal, triggerSignal] = useSignal();

   const url = `/api/v2/usage/${usageMetric}/timeseries?from=${start}&to=${end}&interval=month${refresh}${urlFilterQuery}`;

   const { isLoading, successful, errorMessage, data } = useDataFetch(url, {
      header,
      cancelToken,
      onRequestCompleted: triggerSignal,
   });

   useEffect(() => {
      if (successful) {
         notifyCompletedRequest(data);
      }

      if (errorMessage) {
         if (errorMessage instanceof axios.Cancel) {
            return;
         }
         dispatch(setAlarm('danger', errorMessage));
         notifyError(errorMessage);
      }
   }, [successful, errorMessage, data, dispatch, signal, notifyCompletedRequest, notifyError]);

   useEffect(() => {
      notifyLoading(isLoading);
   }, [isLoading, notifyLoading]);

   return <></>;
}

DataColumn.propTypes = {
   usageMetric: PropTypes.string,
   cancelToken: PropTypes.object,
   header: PropTypes.object,
   urlFilterQuery: PropTypes.string,
   start: PropTypes.string,
   end: PropTypes.string,
   refresh: PropTypes.string,
   notifyCompletedRequest: PropTypes.func,
   notifyLoading: PropTypes.func,
   notifyError: PropTypes.func,
};

export default DataColumn;
