import React from 'react';
import { PropTypes } from 'prop-types';

import { PI_OPTIONS } from '../../WorldMapConstants';

import { getHeatScale } from '../LegendScales';
import {
   HeadLine,
   ButtonGroup,
   ButtonLine,
   SelectButton,
   Scale,
   ScaleField,
   ColoredValue,
   BIG_BUTTON_WIDTH_IN_PX,
} from '../LegendStyles';

export function PlayoutIngest({
   numberFormat,
   valueManipulation,
   scaleFieldWidth,
   total,
   subSwitch,
   onSwitch,
   maxHeatValue,
   scaleFieldsVisible,
}) {
   return (
      <>
         <HeadLine>Choose between</HeadLine>
         <ButtonGroup widthInPx={BIG_BUTTON_WIDTH_IN_PX}>
            <ButtonLine>
               <SelectButton name={PI_OPTIONS.PLAYOUTS} switchValue={subSwitch} onClick={onSwitch} size='sm'>
                  Playout
               </SelectButton>
               <SelectButton name={PI_OPTIONS.INGESTS} switchValue={subSwitch} onClick={onSwitch} size='sm'>
                  Ingest
               </SelectButton>
            </ButtonLine>
            <ButtonLine>
               <SelectButton name={PI_OPTIONS.BOTH} switchValue={subSwitch} onClick={onSwitch} size='lg'>
                  Playout + Ingest
               </SelectButton>
            </ButtonLine>
         </ButtonGroup>
         {scaleFieldsVisible && (
            <Scale>
               {getHeatScale({ numberFormat, valueManipulation, maxHeatValue }).map((field, idx) => (
                  <ScaleField key={`piScaleField-${idx}`} bgColor={field.color} width={scaleFieldWidth}>
                     {field.value}
                  </ScaleField>
               ))}
            </Scale>
         )}
         <HeadLine>
            {total[0]}
            <ColoredValue color={'rgba(0, 0, 0, 1)'}>{total[1]}</ColoredValue>
         </HeadLine>
      </>
   );
}

PlayoutIngest.propTypes = {
   numberFormat: PropTypes.func,
   valueManipulation: PropTypes.func,
   scaleFieldWidth: PropTypes.number,
   subSwitch: PropTypes.string,
   onSwitch: PropTypes.func,
   maxHeatValue: PropTypes.number,
   total: PropTypes.array,
   scaleFieldsVisible: PropTypes.bool,
};
