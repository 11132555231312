import numeral from 'numeral';
import roundTo from 'round-to';

const exp = [1, 1e3, 1e6, 1e9];
const previousPrefixes = {
   k: '',
   m: 'k',
   b: 'm',
   t: 'b',
};

function numberHas1DigitBeforeComma(number) {
   let result;
   for (let i = 0; i < exp.length; i++) {
      result = number / exp[i];
      if (result < 1000) {
         return result < 10 ? true : false;
      }
   }
}

export function rounded_prefixed_Number(number) {
   if (number < 1000) {
      return number;
   }
   const oneDigitType = numberHas1DigitBeforeComma(number);
   const formatedNumber = numeral(number).format('0.000a');

   if (oneDigitType) {
      return formatedNumber[0] + formatedNumber.substring(2, 5) + previousPrefixes[formatedNumber[5]];
   } else {
      const commaPosition = formatedNumber.indexOf('.');
      return formatedNumber.substring(0, commaPosition) + formatedNumber[formatedNumber.length - 1];
   }
}

export function format_prefixed_Number(number) {
   return number < 1000 ? number : numeral(number).format('0.00a');
}

export function format_0dot00_Number(number) {
   const formattedValue = numeral(number).format('0.00');
   return formattedValue === '0.00' ? '<0.01' : formattedValue;
}

export function format_2_digits_zero_sensitive(number) {
   if (number === 0) return '0.00';
   return format_0dot00_Number(number);
}

export function format_time_Number(number, inputInMilliSecs = false) {
   const unitCoefficient = inputInMilliSecs ? 1000 : 1;
   const mins = number / unitCoefficient / 60.0;
   const hours = mins / 60.0;
   if (hours > 1) return `${numeral(hours).format('0,0.00')} h`;
   if (mins > 1) return `${numeral(mins).format('0.00')} min`;
   return `${numeral(number / unitCoefficient).format('0.00')} s`;
}

export function formatTimeNumberInt(number) {
   const mins = number / 60.0;
   const hours = mins / 60.0;
   if (hours > 4) return `${numeral(hours).format('0,0')} h`;
   if (hours > 1) return `${numeral(hours).format('0,0.0')} h`;
   if (mins > 4) return `${numeral(mins).format('0')} min`;
   if (mins > 1) return `${numeral(mins).format('0.0')} min`;
   return `${numeral(number).format('0')} s`;
}

export function formatTimeLegend(number, maxValue) {
   let output = number;
   let unit = 's';

   if (maxValue > 36000) {
      output = Math.round(output / 3600);
      unit = 'h';
   } else if (maxValue > 3600) {
      output = Math.round(output / 60);
      unit = 'min';
   }

   const digits = output.toString().length - 2;

   if (digits > 0) {
      output = roundTo(output, digits * -1);
   }

   if (unit === 'h') return `${numeral(output).format('0,0')} h`;
   if (unit === 'min') return `${numeral(output).format('0,0')} min`;
   return `${numeral(output).format('0,0')} s`;
}

// idea taken from https://stackoverflow.com/a/55987576
export function formatCountNumber(n, processThreshold = 0) {
   if (n < processThreshold) {
      return numeral(n).format('0,0');
   }
   if (n < 1e3) {
      if (n < 1e2) {
         return n.toString();
      } else {
         return (Math.round(n / 10) * 10).toFixed(0);
      }
   }
   if (n >= 1e3 && n < 1e4) return +((Math.round(n / 1e2) * 1e2) / 1e3).toFixed(1) + 'K';
   if (n >= 1e4 && n < 1e5) return +((Math.round(n / 1e3) * 1e3) / 1e3).toFixed(1) + 'K';
   if (n >= 1e5 && n < 1e6) return +((Math.round(n / 1e4) * 1e4) / 1e3).toFixed(1) + 'K';
   if (n >= 1e6 && n < 1e7) return +((Math.round(n / 1e5) * 1e5) / 1e6).toFixed(1) + 'M';
   if (n >= 1e7 && n < 1e8) return +((Math.round(n / 1e6) * 1e6) / 1e6).toFixed(1) + 'M';
   if (n >= 1e8 && n < 1e9) return +((Math.round(n / 1e7) * 1e7) / 1e6).toFixed(1) + 'M';
   if (n >= 1e9 && n < 1e10) return +((Math.round(n / 1e8) * 1e8) / 1e9).toFixed(1) + 'B';
   if (n >= 1e10 && n < 1e11) return +((Math.round(n / 1e9) * 1e9) / 1e9).toFixed(1) + 'B';
   if (n >= 1e11 && n < 1e12) return +((Math.round(n / 1e10) * 1e10) / 1e9).toFixed(1) + 'B';
   if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
}

export function roundToIntWithPrecision(number, precision) {
   if (number > Math.pow(10, -precision)) {
      return roundTo(number, precision);
   }
   return roundTo(number, 0);
}

export function roundUsage(number, maxValue = -1, fullPrecision = true) {
   let precision = 0;
   if (maxValue > 4e9 || number > 4e9) {
      if (number >= 1e11) {
         precision = -2;
      } else if (number >= 1e10) {
         precision = -1;
      }
      return `${numeral(roundTo(Math.round(number / 1000 / 1000) / 1000, fullPrecision ? 0 : precision)).format(
         '0,0',
      )} GB`;
   }
   if (maxValue > 4e6 || number > 4e6) {
      if (number >= 1e8) {
         precision = -2;
      } else if (number >= 1e7) {
         precision = -1;
      }
      return `${numeral(roundTo(Math.round(number / 1000 / 1000), fullPrecision ? 0 : precision)).format('0,0')} MB`;
   }
   if (maxValue > 4e3 || number > 4e3) {
      if (number >= 1e5) {
         precision = -2;
      } else if (number >= 1e4) {
         precision = -1;
      }
      return `${numeral(roundTo(Math.round(number / 1000), fullPrecision ? 0 : precision)).format('0,0')} KB`;
   }
   if (number >= 1e2) {
      precision = -2;
   } else if (number >= 1e1) {
      precision = -1;
   }
   return `${numeral(roundTo(Math.round(number), fullPrecision ? 0 : precision)).format('0,0')} B`;
}

export function roundUsageWith5Digits(number) {
   if (number >= 1e11) {
      return `${numeral(roundTo(Math.round(number / 1000 / 1000 / 1000), 0)).format('0,0')} GB`;
   }
   if (number >= 1e8) {
      return `${numeral(roundTo(Math.round(number / 1000 / 1000), 0)).format('0,0')} MB`;
   }
   if (number >= 1e5) {
      return `${numeral(roundTo(Math.round(number / 1000), 0)).format('0,0')} KB`;
   }
   return `${numeral(roundTo(Math.round(number), 0)).format('0,0')} B`;
}

export function getIntegerWithDecimalSeparator(value) {
   return numeral(value).format('0,0');
}

export function addSeparatorAndThreeDecimalPlaces(cell) {
   return numeral(cell).format('0,0.000');
}

export function roundToGb(cell) {
   return addSeparatorAndThreeDecimalPlaces(roundTo(cell / 1e9, 3));
}
