import { ABR_VIEW, H5LIVE_VIEW, HISTORY_VIEW, HOME_VIEW, WEBRTC_VIEW } from './general';

export const SHOW_FOR = {
   ALL: 'ALL',
   ADMIN_ONLY: 'ADMIN_ONLY',
};

export const ViewsWithShownTimeInterval = {
   [HOME_VIEW]: SHOW_FOR.ALL,
   [H5LIVE_VIEW]: SHOW_FOR.ALL,
   [ABR_VIEW]: SHOW_FOR.ADMIN_ONLY,
   [WEBRTC_VIEW]: SHOW_FOR.ALL,
   [HISTORY_VIEW]: SHOW_FOR.ALL,
};
