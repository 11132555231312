export const TimeUnits = {
   second: 'second',
   minute: 'minute',
   hour: 'hour',
   day: 'day',
   week: 'week',
   month: 'month',
   year: 'year',
};

export const TIME_INTERVALS = {
   [TimeUnits.second]: { name: 'Second', value: TimeUnits.second, format: 'HH:mm:ss', counts: 30 },
   [TimeUnits.minute]: { name: 'Minute', value: TimeUnits.minute, format: 'HH:mm', counts: 1 },
   [TimeUnits.hour]: { name: 'Hour', value: TimeUnits.hour, format: 'HH', counts: 1 },
   [TimeUnits.day]: { name: 'Day', value: TimeUnits.day, format: 'DD MMM', counts: 1 },
   [TimeUnits.week]: { name: 'Week', value: TimeUnits.week, format: 'WW', counts: 1 },
   [TimeUnits.month]: { name: 'Month', value: TimeUnits.month, format: 'MMM', counts: 1 },
   [TimeUnits.year]: { name: 'Year', value: TimeUnits.year, format: 'YYYY', counts: 1 },
};
