import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import roundTo from 'round-to';

import { getFilterQueryParams, getHttpHeader } from '../../../../util/FetchTools';

import useDataFetch from '../../../../hooks/useDataFetch';

import {
   CenteredMsgShowing,
   ChartWrapper,
   HeightSetContainer,
   SetChartHeight,
   SimpleFlexRow,
   TwoSideFlexContainer,
   WidgetBodyContainer,
   WidgetContainer,
   WidgetHeaderContainer,
} from '../../../../styledComponents/styledComponents';

import { Line } from 'react-chartjs-2';
import { setAlarm } from '../../../../redux/actions/general';
import { Spinner } from '../../../common';

import { getStyle, hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';

import { CardTitle, Col, Row } from 'reactstrap';

import {
   cancelTokenSelector,
   countriesSelector,
   endTimespanSelector,
   eventsSelector,
   hashSelector,
   intervalTimespanSelector,
   settingsSelector,
   startTimespanSelector,
   streamNamesSelector,
   tagsSelector,
   timespanSelector,
   titleTimespanSelector,
} from './../../../../redux/selectors/selectors';

import { PropTypes } from 'prop-types';

import 'spinkit/css/spinkit.css';

import ComponentTools from './../../../../util/ComponentTools';

import { lineChartWithXaxisYStackedOptions } from '../../../../constants/chartjs';
import { ABR_VIEW } from '../../../../constants/general';

const chartOptions = lineChartWithXaxisYStackedOptions();

const blue = getStyle('--info');
const red = getStyle('--danger');

function createChartData(data, timespan, settings) {
   const { abrUsed, abrNotUsed } = data;

   const updatedChartData = {
      labels: [],
      datasets: [
         {
            label: 'Playouts not using ABR',
            backgroundColor: hexToRgba(blue, 10),
            borderColor: blue,
            borderWidth: 1,
            data: [],
            fill: 'origin',
         },
         {
            label: 'Playouts using ABR',
            backgroundColor: hexToRgba(red, 10),
            borderColor: red,
            borderWidth: 1,
            data: [],
            fill: '-1',
         },
      ],
   };

   const labels = [];

   ComponentTools.setLineChartLabels(updatedChartData, labels, timespan, settings.weekType);

   const now = Date.now();

   let lastIdxWithDataUsed = -1;
   let lastIdxWithDataNotUsed = -1;

   labels.forEach((label, idx) => {
      const start = label.start;
      const end = label.end;
      if (start < now) {
         const abrNotUsedInterval = abrNotUsed.find((bucket) => bucket.key >= start && bucket.key < end);
         const abrUsedInterval = abrUsed.find((bucket) => bucket.key >= start && bucket.key < end);

         if (abrNotUsedInterval !== undefined) {
            updatedChartData.datasets[0].data.push(roundTo(abrNotUsedInterval.count, 4));
            lastIdxWithDataNotUsed = idx;
         } else {
            if (lastIdxWithDataNotUsed === -1) {
               updatedChartData.datasets[0].data.push(null);
            } else {
               updatedChartData.datasets[0].data.push(0);
            }
         }
         if (abrUsedInterval !== undefined) {
            updatedChartData.datasets[1].data.push(roundTo(abrUsedInterval.count, 4));
            lastIdxWithDataUsed = idx;
         } else {
            if (lastIdxWithDataUsed === -1) {
               updatedChartData.datasets[1].data.push(null);
            } else {
               updatedChartData.datasets[1].data.push(0);
            }
         }
      }
   });
   for (let index = lastIdxWithDataNotUsed + 1; index < updatedChartData.datasets[0].data.length; index++) {
      updatedChartData.datasets[0].data[index] = null;
   }
   for (let index = lastIdxWithDataUsed + 1; index < updatedChartData.datasets[1].data.length; index++) {
      updatedChartData.datasets[1].data[index] = null;
   }
   return updatedChartData;
}

function AbrUseRatio({ height }) {
   const [chart, setChart] = useState({
      labels: [],
      datasets: [],
   });

   const [noData, setNoData] = useState(false);

   const hash = useSelector(hashSelector);
   const countries = useSelector(countriesSelector);
   const tags = useSelector(tagsSelector);
   const events = useSelector(eventsSelector);
   const streamNames = useSelector(streamNamesSelector);

   const start = useSelector(startTimespanSelector);
   const end = useSelector(endTimespanSelector);
   const interval = useSelector(intervalTimespanSelector);
   const timespanTitle = useSelector(titleTimespanSelector);

   const timespan = useSelector(timespanSelector);
   const cancelToken = useSelector(cancelTokenSelector);
   const settings = useSelector(settingsSelector);
   const dispatch = useDispatch();

   const header = useMemo(() => getHttpHeader(hash), [hash]);
   const urlFilterQuery = getFilterQueryParams(countries, tags, events, streamNames, ABR_VIEW);

   const url = `/api/v2/abr/abrUseRatio/timeseries?from=${start.format()}&to=${end.format()}&interval=${interval}${urlFilterQuery}`;

   const { successful, isLoading, errorMessage, data, httpStatus } = useDataFetch(url, { header, cancelToken });

   useEffect(() => {
      if (successful) {
         if (httpStatus !== 204) {
            const sumOfAbrUsed = data.abrUsed.reduce((sum, dataPoint) => (sum += dataPoint.count), 0);
            const sumOfAbrNotUsed = data.abrNotUsed.reduce((sum, dataPoint) => (sum += dataPoint.count), 0);
            setNoData(sumOfAbrUsed === 0 && sumOfAbrNotUsed === 0);
            setChart(createChartData(data, timespan, settings));
         }
      }

      if (errorMessage) {
         dispatch(setAlarm('danger', errorMessage));
      }
   }, [data, successful, errorMessage, httpStatus, dispatch, timespan, settings]);

   const errorOrNoData = errorMessage || noData;
   const issueMsg = errorMessage ? errorMessage.message : 'No data available';

   return (
      <WidgetContainer>
         <WidgetHeaderContainer>
            <HeightSetContainer $heightInPx={23}>
               <TwoSideFlexContainer>
                  <SimpleFlexRow></SimpleFlexRow>
                  <SimpleFlexRow></SimpleFlexRow>
               </TwoSideFlexContainer>
            </HeightSetContainer>
         </WidgetHeaderContainer>
         <WidgetBodyContainer>
            <Row>
               <Col>
                  <CardTitle className='mb-0'>Playouts using / not using ABR</CardTitle>
                  <div className='small text-muted'>{`${timespanTitle}\u00A0\u00A0\u00A0( UTC time )`}</div>
               </Col>
            </Row>
            <ChartWrapper className='chart-wrapper' height={height}>
               <Spinner loading={isLoading} parentTopMarginPx={60}>
                  {errorOrNoData ? (
                     <CenteredMsgShowing height={height} marginTopPx={20}>
                        {issueMsg}
                     </CenteredMsgShowing>
                  ) : (
                     <SetChartHeight height={height}>
                        <Line data={chart} options={chartOptions} />
                     </SetChartHeight>
                  )}
               </Spinner>
            </ChartWrapper>
         </WidgetBodyContainer>
      </WidgetContainer>
   );
}

AbrUseRatio.propTypes = {
   height: PropTypes.number,
};

AbrUseRatio.defaultProps = {
   height: 300,
};

export default AbrUseRatio;
