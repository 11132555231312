import React from 'react';
import styled from 'styled-components';
import HeadLineSeparator from '../../world/Common/HeadLineSeparator';

const InfoWindow = styled.div`
   padding: 15px;
   width: 250px;
   background-color: rgba(60, 60, 60, 1);
   color: rgb(255, 255, 255);
   border-style: none;
   border-radius: 12px;
   position: absolute;
   top: 10px;
   left: -300px;
   z-index: 1000;
   display: ${(props) => (props.show ? 'block' : 'none')};
   font-size: 12px;
`;

function HoverBox({ latency, bRate, bRatio, playback, start, viewerSynchScore, playoutScore, show }) {
   return (
      <InfoWindow show={show}>
         <InfoLine metric={'Average playout score'} value={playoutScore} />
         <InfoLine metric={'Viewer synchronicity score'} value={viewerSynchScore} />
         <HeadLineSeparator text={'Playout sub metrics'} color={'#60E8fF'} />
         <InfoLine metric={'Startup score'} value={start} />
         <InfoLine metric={'Buffering rate score'} value={bRate} />
         <InfoLine metric={'Buffering ratio score'} value={bRatio} />
         <InfoLine metric={'Playback success score'} value={playback} />
         <InfoLine metric={'Latency score'} value={latency} />
      </InfoWindow>
   );
}

const FlexContainer = styled.div`
   display: flex;
   justify-content: space-between;
`;

function InfoLine({ metric, value }) {
   return value !== undefined ? (
      <FlexContainer>
         <div>{metric}</div>
         <div>{`${value} / 100`}</div>
      </FlexContainer>
   ) : (
      ''
   );
}

export default HoverBox;
