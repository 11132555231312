export const TRAFFIC = 'bytes';
export const PLAYTIME = 'playtime';

export const HOME_VIEW = 'home';
export const BREAKDOWN_VIEW = 'breakdown';
export const WORLD_VIEW = 'world';
export const H5LIVE_VIEW = 'h5live';
export const ABR_VIEW = 'abr';
export const QOE_VIEW = 'qoe';
export const WEBRTC_VIEW = 'webrtc';
export const HISTORY_VIEW = 'history';
export const TROUBLESHOOTING_VIEW = 'troubleshooting';
export const GUARDIAN_VIEW = 'guardian';
export const DOC_VIEW = 'doc';
export const LOGIN_VIEW = 'login';
export const TOKEN_LOGIN_VIEW = 'tokenLogin';
export const IP_FILTERING_VIEW = 'streamGuard';
export const ZOOM_VIEW = 'zoom';

export const MAX_1_MONTH_TIME_SPAN_VIEWS = [BREAKDOWN_VIEW, WORLD_VIEW, H5LIVE_VIEW, ABR_VIEW, QOE_VIEW, WEBRTC_VIEW];

export const PLAYOUT = 'playout';
export const E2E = 'e2e';
export const INGEST = 'ingest';

export const SIMPLE_DATE_FORMAT = 'YYYY/MM/DD';
export const SIMPLE_TIME_FORMAT = 'HH:mm';
export const NORMAL_TIME_FORMAT = 'HH:mm:SS';

export const SIMPLE_DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm';

export const DEFAULT_SHORTCUT_ID = 12;
export const DEFAULT_LONGTERM_SHORTCUT_ID = 52;
export const DEFAULT_LAST_MONTH_SHORTCUT_ID = 22;

export const ABSOLUTE_FORMAT = 'MMM DD, YYYY [at] HH:mm';

export const REFERRER_BLOCKLIST = 'referer';
export const IP_BLOCKLIST = 'ip';

export const USAGE_AGG_TYPE = {
   playout: 'playout',
   ingest: 'ingest',
   combined: 'combined',
};

export const USAGE_METRICS = {
   bytes: 'bytes',
   playtime: 'playtime',
};

export const BLOCK_LIST_PROP_NAME_MAP = {
   [REFERRER_BLOCKLIST]: 'domain',
   [IP_BLOCKLIST]: 'ip',
};

export const MINUTE = 60;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;
